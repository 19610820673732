import React from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
var styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
    alignItems: 'center',
};
export var Edit3DFilesApproval = observer(function (_a) {
    var record = _a.record, renderStepFile = _a.renderStepFile, setShowAddStepFile = _a.setShowAddStepFile;
    return (React.createElement("div", { className: 'flex flex-col w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles }, (record === null || record === void 0 ? void 0 : record.name_3d_file) ? (React.createElement(React.Fragment, null, (record === null || record === void 0 ? void 0 : record.name_3d_file) && (React.createElement(React.Fragment, null,
            React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: function () {
                    if (record.production_3d_file_id) {
                        renderStepFile(record.production_3d_file_id);
                    }
                } },
                React.createElement("a", null, record.name_3d_file)),
            ' ',
            "\u00A0",
            React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } }))))) : (React.createElement(React.Fragment, null, record.production_3d_file ? (React.createElement(React.Fragment, null,
            React.createElement("span", { title: 'Preview 3d file', className: 'link', onClick: function () {
                    var _a;
                    if ((_a = record === null || record === void 0 ? void 0 : record.production_3d_file) === null || _a === void 0 ? void 0 : _a.id) {
                        renderStepFile(record.production_3d_file.id);
                    }
                } },
                React.createElement("a", null,
                    " ",
                    record.production_3d_file.filename)),
            ' ',
            "\u00A0",
            React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } }))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { style: { color: 'red' } }, " Pending"),
            " \u00A0",
            React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: function () { return setShowAddStepFile(true); } }))))))));
});
