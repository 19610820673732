import React, { useState } from 'react';
import { patch, post } from "../../../shared";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, notification, Row, Col } from 'antd';
import { States, UploadFile } from '@partsbadger/library';

const TaxExemptFormComponent = ({ form, account, tax_form, callback }) => {
    const { getFieldDecorator } = form;

    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    account: account,
                    sales_tax_deduction: values?.sales_tax_deduction ? values?.sales_tax_deduction : null,
                    minimum_amount_applicable: values?.minimum_amount_applicable
                        ? values?.minimum_amount_applicable
                        : null,
                    maximum_amount_applicable: values?.maximum_amount_applicable
                        ? values?.maximum_amount_applicable
                        : null,
                    states: values.states.map(r => r.key),
                    files: values?.tax_exempt_files?.map(f => f.response.id),
                };

                setLoading(true);

                if (tax_form?.id) {
                    patch(`staff/accounts/${account}/tax-forms/${tax_form.id}/`, payload)
                        .then(() => {
                            form.resetFields();
                            setLoading(false);
                            callback();
                            notification.success({ message: 'Tax Exempt form added correctly!' });
                        })
                        .catch(error => {
                            notification.error({ message: 'Error' });
                            setLoading(false);
                        });
                } else {
                    post(`staff/accounts/${account}/tax-forms/`, payload)
                        .then(() => {
                            form.resetFields();
                            setLoading(false);
                            callback();
                            notification.success({ message: 'Tax Exempt form added correctly!' });
                        })
                        .catch(error => {
                            notification.error({ message: 'Error' });
                            setLoading(false);
                        });
                }
            }
        });
    };

    return (
        <div>
            <Row className={'back'}>
                <ArrowLeftOutlined
                    style={{
                        fontSize: '30px',
                        color: '#08c',
                        float: 'left',
                        padding: '5px',
                    }}
                    onClick={callback}
                />
            </Row>

            <Row>
                <Form
                    className={'tax-exempted-form'}
                    onSubmit={handleSubmit}
                    layout="vertical"
                    style={{
                        maxWidth: '800px',
                        margin: 'auto',
                        border: 'solid 1px #d4d4d4',
                        padding: '47px',
                    }}
                >
                    <Col xs={24}>
                        <Form.Item label={'States'} help={'Multiple selection allowed'}>
                            {getFieldDecorator('states', {
                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                                initialValue:
                                    tax_form?.states &&
                                    tax_form?.states.map(row => {
                                        return {
                                            key: row,
                                            label: row,
                                        };
                                    }),
                            })(<States mode={'multiple'} countryShortCode={'US'} />)}
                        </Form.Item>

                        <Form.Item label={'Tax Exempt files'}>
                            {getFieldDecorator('tax_exempt_files', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Files are required',
                                    },
                                ],
                                initialValue:
                                    tax_form?.files &&
                                    tax_form?.files.map(r => {
                                        return {
                                            uid: r.id,
                                            name: r.filename,
                                            status: 'done',
                                            response: {
                                                id: r.id,
                                            },
                                            url: r.file,
                                        };
                                    }),
                            })(<UploadFile multiple />)}
                        </Form.Item>

                        <Form.Item
                            label={'Partial Deduction Percent'}
                            help={'Please enter if specified. Leave empty otherwise.'}
                        >
                            {getFieldDecorator('sales_tax_deduction', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: tax_form?.sales_tax_deduction,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>

                        <Form.Item
                            label={'Minimum Order Total Until Deduction or Exempt'}
                            help={'Leave empty if the form does not require a minimum amount'}
                        >
                            {getFieldDecorator('minimum_amount_applicable', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: tax_form?.minimum_amount_applicable,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>

                        <Form.Item
                            label={'Maximum Order Total Until Taxable'}
                            help={'Leave empty if the form does not have a maximum amount'}
                        >
                            {getFieldDecorator('maximum_amount_applicable', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: tax_form?.maximum_amount_applicable,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>
                    </Col>

                    <br />

                    <Row style={{ textAlign: 'center' }}>
                        <Button type="default" loading={loading} onClick={callback}>
                            Cancel
                        </Button>

                        <Button htmlType={'submit'} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Row>
                </Form>
            </Row>
        </div>
    );
};

export const TaxExemptForm = Form.create()(TaxExemptFormComponent);
