import React from 'react';
import { Tabs } from 'antd';
import { FlexRow, CheckboxAntd, InputAntd } from './styles';
import { observer } from 'mobx-react';

interface ToleranceInputProps {
    label: string;
    checked: boolean;
    value: string;
    onCheckedChange: (checked: boolean) => void;
    onValueChange: (value: string) => void;
    classNameProps: string;
}

const ToleranceInput: React.FC<ToleranceInputProps> = ({
    label,
    checked,
    value,
    onCheckedChange,
    onValueChange,
    classNameProps,
}) => (
    <FlexRow>
        <CheckboxAntd tabIndex={-1} checked={checked} onChange={e => onCheckedChange(e.target.checked)} />
        {label}
        <InputAntd
            value={value}
            onChange={e => onValueChange(e.target.value)}
            size="small"
            classNameProps={classNameProps}
        />
    </FlexRow>
);

const Tolerance = (props: any) => {
    const { toleranceType, setToleranceType, tolProps } = props;

    const handleCheckedChange = (type: string, checked: boolean) => {
        tolProps[type].setChecked(checked);
    };

    const handleValueChange = (type: string, value: string) => {
        tolProps[type].setValue(value);
    };

    return (
        <Tabs defaultActiveKey={toleranceType} onTabClick={value => setToleranceType(value)} style={{ marginTop: -10 }}>
            <Tabs.TabPane tab="Standard" key="STANDARD" style={{ marginTop: -10 }}>
                <ToleranceInput
                    label="TolX"
                    {...tolProps.tolX}
                    onCheckedChange={checked => handleCheckedChange('tolX', checked)}
                    onValueChange={value => handleValueChange('tolX', value)}
                    classNameProps="ml-8"
                />
                <ToleranceInput
                    label="TolXX"
                    {...tolProps.tolXX}
                    onCheckedChange={checked => handleCheckedChange('tolXX', checked)}
                    onValueChange={value => handleValueChange('tolXX', value)}
                    classNameProps="ml-6"
                />
                <ToleranceInput
                    label="TolXXX"
                    {...tolProps.tolXXX}
                    onCheckedChange={checked => handleCheckedChange('tolXXX', checked)}
                    onValueChange={value => handleValueChange('tolXXX', value)}
                    classNameProps="ml-4"
                />
                <ToleranceInput
                    label="TolXXXX"
                    {...tolProps.tolXXXX}
                    onCheckedChange={checked => handleCheckedChange('tolXXXX', checked)}
                    onValueChange={value => handleValueChange('tolXXXX', value)}
                    classNameProps="ml-2"
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Singular" key="SINGULAR">
                <ToleranceInput
                    label="+/-"
                    {...tolProps.tolXS}
                    onCheckedChange={checked => handleCheckedChange('tolXS', checked)}
                    onValueChange={value => handleValueChange('tolXS', value)}
                    classNameProps="ml-2"
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ISO 2768-mK" key="ISO" style={{ marginTop: -10, height: '100px', overflowY: 'auto' }}>
                <ToleranceInput
                    label="0‑6"
                    {...tolProps.tol06}
                    onCheckedChange={checked => handleCheckedChange('tol06', checked)}
                    onValueChange={value => handleValueChange('tol06', value)}
                    classNameProps="ml-12"
                />
                <ToleranceInput
                    label="6‑30"
                    {...tolProps.tol630}
                    onCheckedChange={checked => handleCheckedChange('tol630', checked)}
                    onValueChange={value => handleValueChange('tol630', value)}
                    classNameProps="ml-10"
                />
                <ToleranceInput
                    label="30‑120"
                    {...tolProps.tol30120}
                    onCheckedChange={checked => handleCheckedChange('tol30120', checked)}
                    onValueChange={value => handleValueChange('tol30120', value)}
                    classNameProps="ml-6"
                />
                <ToleranceInput
                    label="120‑400"
                    {...tolProps.tol120400}
                    onCheckedChange={checked => handleCheckedChange('tol120400', checked)}
                    onValueChange={value => handleValueChange('tol120400', value)}
                    classNameProps="ml-4"
                />
                <ToleranceInput
                    label="400‑1000"
                    {...tolProps.tol4001000}
                    onCheckedChange={checked => handleCheckedChange('tol4001000', checked)}
                    onValueChange={value => handleValueChange('tol4001000', value)}
                    classNameProps="ml-2"
                />
                <ToleranceInput
                    label="1000‑2000"
                    {...tolProps.tol10002000}
                    onCheckedChange={checked => handleCheckedChange('tol10002000', checked)}
                    onValueChange={value => handleValueChange('tol10002000', value)}
                    classNameProps="ml-0"
                />
            </Tabs.TabPane>
        </Tabs>
    );
};

export default observer(Tolerance);
