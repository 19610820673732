import React, { useEffect, useState } from 'react';
import { Drawer, notification, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import { get } from '../../shared/api';

const FileDownload = require('js-file-download');

export const SalesOrderPreview = ({ order_id, handleClose }) => {
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState();

    useEffect(() => {
        setLoading(true);

        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then(response => {
                const data = response.data;

                if (isMobile) {
                    FileDownload(data, `order-${order_id}.pdf`);
                    handleClose();
                    notification.success({ message: 'Document downloaded' });
                } else {
                    const file = new Blob([data], { type: 'application/pdf' });

                    const fileURL = URL.createObjectURL(file);

                    setLoading(false);
                    setFileURL(fileURL);
                }
            })
            .catch(error => alert(error));
    }, [order_id]);

    if (isMobile) {
        return null;
    }

    return (
        <Drawer title="Preview" width={'80%'} closable visible placement={'left'} onClose={handleClose}>
            <Spin spinning={loading}>
                <iframe
                    title={'Quote Preview'}
                    src={fileURL}
                    frameBorder="0"
                    height={window.innerHeight}
                    width="100%"
                />
            </Spin>
        </Drawer>
    );
};
