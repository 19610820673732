import { notification } from 'antd';
export var addMessage = function (mgsTitle, msg) {
    notification.open({
        message: mgsTitle,
        description: msg,
        onClick: function () {
            console.log('Notification Clicked!');
        },
    });
};
export var openNotificationWithIcon = function (type, message, description, duration) {
    if (duration === void 0) { duration = 5; }
    notification[type]({
        message: message,
        description: description,
        duration: duration,
    });
};
