import React from 'react';
import { useHistory } from 'react-router';

interface Props {
    className?: string;
    style?: React.CSSProperties;
}

const Logo = ({ className, style }: Props) => {
    const logoSrc = 'https://partsbadger-static-files.s3.us-east-2.amazonaws.com/static/images/logo_full.svg';
    const history = useHistory();

    return (
        <img
            className={`${className ?? ''}`}
            style={style}
            src={logoSrc}
            alt="logo"
            onClick={() => history.push('/')}
        />
    );
};

export default Logo;
