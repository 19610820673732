var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
var moment = require('moment-business-days');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
var Holidays = require('date-holidays');
var hd = new Holidays();
hd.init('US');
moment.updateLocale('us', {
    holidays: hd
        .getHolidays(new Date())
        .filter(function (h) { return h.type === 'public'; })
        .map(function (h) {
        return h.date.split(' ')[0];
    }),
    holidayFormat: 'YY-MM-DD',
    workingWeekdays: [1, 2, 3, 4, 5],
});
export var TOTAL_PRICE_SHOW_CHECKLIST = 10000;
/**
 * Add business days to existing days
 * @param start_date : Date
 * @param lead_time : number
 */
export var addBusinessDays = function (start_date, lead_time) {
    return moment(start_date).businessAdd(lead_time);
};
/**
 * Subtract business days to existing days
 * @param start_date : Date
 * @param lead_time : number
 */
export var subtractBusinessDays = function (start_date, lead_time) {
    return moment(start_date).businessSubtract(lead_time);
};
export var buildJsonBigOrderChecklist = function (values, checklistQuestions) {
    var checklist = __spreadArray([], checklistQuestions, true);
    var array = Object.entries(values);
    if (array.length > 0) {
        var filter = array.filter(function (vec) { return vec[0].startsWith('confirm-'); });
        filter.map(function (item) {
            var _a;
            var question = item[0].split('confirm-')[1];
            var find = checklist.find(function (element) { return element.question === question; });
            if (find) {
                find['answer'] = {
                    confirm: String(item[1]),
                    mark: (_a = find.answer) === null || _a === void 0 ? void 0 : _a.mark,
                };
            }
        });
        var filterMark = array.filter(function (vec) { return vec[0].startsWith('mark-'); });
        filterMark.map(function (item) {
            var _a;
            var question = item[0].split('mark-')[1];
            var find = checklist.find(function (element) { return element.question === question; });
            if (find) {
                find.answer = {
                    confirm: (_a = find.answer) === null || _a === void 0 ? void 0 : _a.confirm,
                    mark: !!item[1],
                };
            }
        });
        return checklist;
    }
    return [];
};
var disabledDates = [
    '2024-05-27',
    '2024-07-04',
    '2024-09-02',
    '2024-11-28',
    '2024-11-29',
    '2024-12-24',
    '2024-12-25',
    '2025-01-01',
];
export default function disabledDate(current) {
    return disabledDates.includes(current.format('YYYY-MM-DD'));
}
