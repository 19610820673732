import React from 'react';
import { Select } from 'antd';
var QuadrantSelector = function (_a) {
    var selectedQuadrant = _a.selectedQuadrant, onQuadrantChange = _a.onQuadrantChange;
    return (React.createElement(Select, { showSearch: true, style: { width: 300 }, onChange: onQuadrantChange, placeholder: "Quadrant", optionFilterProp: "children", value: selectedQuadrant },
        React.createElement(Select.Option, { key: "A" }, "A (Hot - No Requirements)"),
        React.createElement(Select.Option, { key: "B" }, "B (Hot - w/ Requirements)"),
        React.createElement(Select.Option, { key: "C" }, "C (Complete - Early)"),
        React.createElement(Select.Option, { key: "D" }, "D (Late - Partial)"),
        React.createElement(Select.Option, { key: "E" }, "E (On Time - Partial)"),
        React.createElement(Select.Option, { key: "F" }, "F (Domestic Finish)"),
        React.createElement(Select.Option, { key: "G" }, "G ()"),
        React.createElement(Select.Option, { key: "H" }, "H (Hold)")));
};
export default QuadrantSelector;
