import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Divider, Form, InputNumber, Menu, Modal, notification, Row, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { get, post, useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import GenericSelect from '@partsbadger/library/lib/Select/Generic/GenericSelect';
import InvoiceFilter from '../../components/Invoices/forms/InvoiceFilter';
import { useHistory, useLocation } from 'react-router-dom';
import { InvoiceStore } from '../../stores/InvoiceStore';
import { CheckCircleOutlined, FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { OrderLineItemProps, SalesOrderProps } from '@partsbadger/library/lib/Types/TypesSalesOrder';
import { Price } from '@partsbadger/library';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import InvoiceStatus from '../../components/Invoices/InvoiceStatus';
import RenderPage from '../RenderPage';
import { IInvoiceDetailStaff } from '@partsbadger/types';

interface isSelectedItem {
    id: number;
    qty: number;
    selected: boolean;
    unit_price: number;
    discount: number;
    total: number;
    grand_total: string;
}

const Invoices: React.FC = () => {
    const FormItem = Form.Item;
    const location = useLocation();

    // const [invoiceData, setInvoiceData] = useState<IInvoiceDetailStaff[]>([]);
    const [newInvoiceModal, setNewInvoiceModal] = useState<boolean>(false);
    const [salesOrderId, setSalesOrderId] = useState<number | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [salesOrder, setSalesOrder] = useState<SalesOrderProps | null>(null);
    const [editedLineItems, setEditedLineItems] = useState<isSelectedItem[]>([]);

    const [lineItems, setLineItems] = useState<OrderLineItemProps[]>([]);
    const [grandTotalPreview, setGrandTotalPreview] = useState<number | null>(null);

    const history = useHistory();

    const searchParams = useQueryParameters();

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);

        const editedItems = [...editedLineItems];

        editedItems.map(item => {
            if (newSelectedRowKeys.includes(item.id.toString())) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        setEditedLineItems(editedItems);
        setGrandTotalPreview(
            editedLineItems
                .filter(item => item.selected)
                .map(item => Number(item.grand_total))
                .reduce((prev, curr) => prev + curr, 0)
        );
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
        };
        InvoiceStore.getAll(params);
    };

    // useEffect(() => {

    // }, [editedLineItems])

    useEffect(() => {
        loadData();
    }, [location.search]);

    useEffect(() => {
        const items: isSelectedItem[] = [];
        salesOrder?.line_items
            .filter(item => facturableQty(item) > 0)
            .map(obj => {
                items.push({
                    id: obj.id,
                    qty: facturableQty(obj),
                    selected: false,
                    unit_price: obj.unit_price,
                    discount: obj.discount ?? 0,
                    total: obj.unit_price * obj.quantity,
                    grand_total: (obj.unit_price * facturableQty(obj) - (obj.discount ?? 0)).toFixed(2),
                });
            });
        setEditedLineItems(items);
    }, [lineItems]);

    useEffect(() => {
        if (salesOrderId !== null) {
            getData();
        }
    }, [salesOrderId]);

    const getData = () => {
        setLoading(true);
        get(`/staff/sales-orders/${salesOrderId}/`)
            .then(data => {
                setSalesOrder(data);
                setLineItems(data.line_items.filter((item: OrderLineItemProps) => facturableQty(item) > 0));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const createInvoice = () => {
        setLoading(true);
        post(`/staff/sales-orders/${salesOrderId}/invoice/`, {
            line_items: editedLineItems.filter(item => item.selected),
        })
            .then(() => {
                loadData();
                notification.success({ message: 'Success', description: `Invoice created correctly.` });
            })
            .catch(() => {
                notification.error({ message: 'Error' });
            })
            .finally(() => {
                setLoading(false);
                setNewInvoiceModal(false);
            });
    };

    const facturableQty = (item: OrderLineItemProps) => {
        return (item.quantity_shipped ?? 0) - (item.invoiced_quantity ?? 0);
    };

    const onChangeQty = (id: number, qty: number) => {
        const copyLineItems = [...editedLineItems];
        const item = copyLineItems.find(obj => obj.id === id);
        if (item) {
            const subtotal_item = item.unit_price * qty;
            const discount = item.discount > 0.0 ? (item.discount / item.total) * subtotal_item : 0;
            item.qty = qty;
            item.grand_total = (item.unit_price * qty - discount).toFixed(2);
        }

        setEditedLineItems(copyLineItems);

        setGrandTotalPreview(
            editedLineItems
                .filter(item => item.selected)
                .map(item => Number(item.grand_total))
                .reduce((prev, curr) => prev + curr, 0)
        );
    };

    const SendToZohoButton = (props: { id: number }) => {
        const [sendingToZoho, setSendingToZoho] = useState(false);

        return (
            <Button
                className="mx-2"
                title={'Completed Invoices are sending automatically to zoho'}
                loading={sendingToZoho}
                onClick={() => {
                    setSendingToZoho(true);
                    post(`/staff/invoices/${props.id}/send-to-zoho/`)
                        .then(() => {
                            loadData();
                            setSendingToZoho(false);
                        })
                        .catch(error => {
                            notification.error({
                                message: 'Error sending to zoho',
                                description: error.toString(),
                            });
                            loadData();
                            setSendingToZoho(false);
                        });
                }}
            >
                Send to Zoho
            </Button>
        );
    };

    const SyncInvoiceButton = (props: { id: number }) => {
        const [loading, setLoading] = useState(false);

        return (
            <Button
                className="mx-2"
                title={'Sync an Invoice from Zoho Invoice to PB'}
                loading={loading}
                type={'link'}
                onClick={() => {
                    setLoading(true);
                    InvoiceStore.syncFromZohoById(props.id)
                        .then(() => {
                            setLoading(false);
                            loadData();
                        })
                        .catch(error => {
                            notification.error({
                                message: 'Error sending to zoho',
                                description: error.toString(),
                            });
                            setLoading(false);
                        });
                }}
            >
                Click to Sync from Zoho
            </Button>
        );
    };

    const lineItemsColumns: ColumnProps<OrderLineItemProps>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (v, record: OrderLineItemProps) => {
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                        <div style={{ fontWeight: 'bold' }}>{record.name}</div>
                        <div style={{ fontSize: '11px' }}>{record.product_description}</div>
                    </div>
                );
            },
        },
        {
            title: 'Qty ordered',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Qty shipped',
            dataIndex: 'quantity_shipped',
            key: 'quantity_shipped',
        },
        {
            title: 'Invoiced qty',
            dataIndex: 'invoiced_quantity',
            key: 'invoiced_quantity',
        },
        {
            title: 'Billable',
            dataIndex: 'billable',
            render: (v, record: OrderLineItemProps) => {
                // return <span>{facturableQty(record)}</span>;
                return (
                    <InputNumber
                        min={1}
                        max={facturableQty(record)}
                        defaultValue={facturableQty(record)}
                        onChange={value => {
                            onChangeQty(record.id, value || 0);
                        }}
                    />
                );
            },
        },
        {
            title: 'Shipped Grand Total',
            dataIndex: 'shipped_grand_total',
            render: (v, record: OrderLineItemProps) => {
                return `$${(record.unit_price * Number(record.quantity_shipped) - Number(record.discount ?? 0)).toFixed(
                    2
                )}`;
            },
        },
    ];

    const columns: ColumnProps<IInvoiceDetailStaff>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Invoice Number',
            dataIndex: 'name',
            key: 'name',
            // width: '10%',
        },
        {
            title: 'Status',
            dataIndex: 'invoice_status',
            key: 'invoice_status',
            render: (t, i) => {
                return (
                    <div>
                        <div>
                            {i.invoice_zoho_id && !i.invoice_data ? (
                                <SyncInvoiceButton id={i.id} />
                            ) : (
                                <InvoiceStatus status={i.invoice_data?.status ?? null} />
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            // align: 'right',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            // align: 'right',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            key: 'grand_total',
            // align: 'right',

            render: (t, i) => {
                return (
                    <div>
                        <div>{i.total_with_shipping}</div>
                        <div>
                            {i.invoice_data?.total && i.invoice_data.total != i.total_with_shipping ? (
                                <div
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    Error: The total is different than the Invoice App
                                </div>
                            ) : null}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Zoho',
            dataIndex: 'zoho_id',
            key: 'zoho_id',
            render: (t, r) => {
                return (
                    <div>
                        <div>
                            {r.zoho_id ? (
                                <a
                                    style={{
                                        fontSize: 12,
                                        marginLeft: 4,
                                    }}
                                    target={'_blank'}
                                    rel="noreferrer"
                                    href={`${ZOHO_BASE_URL}/Invoices/${r.zoho_id}`}
                                >
                                    View In CRM
                                </a>
                            ) : (
                                <SendToZohoButton id={r.id} />
                            )}
                        </div>

                        <div>
                            {r.invoice_zoho_id ? (
                                <a
                                    style={{
                                        fontSize: 12,
                                        marginLeft: 4,
                                    }}
                                    target={'_blank'}
                                    rel="noreferrer"
                                    href={`https://invoice.zoho.com/app/649611804#/invoices/${r.invoice_zoho_id}`}
                                >
                                    View In Invoice
                                </a>
                            ) : null}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (v, record) => {
                return (
                    <Menu>
                        <Menu.Item
                            onClick={() => {
                                history.push(`/invoices/${record.id}/`);
                            }}
                        >
                            <CheckCircleOutlined /> View
                        </Menu.Item>
                        {record.invoice_data?.invoice_url && record.invoice_data.status != 'draft' && (
                            <Menu.Item
                                onClick={() => {
                                    window.open(
                                        record.invoice_data?.invoice_url,
                                        '_blank',
                                        'width=800,height=800,left=200,top=200'
                                    );
                                }}
                            >
                                <FilePdfOutlined /> Print
                            </Menu.Item>
                        )}

                        {record.reference && !record.sent_to_customer && record.invoice_data?.status === 'sent' && (
                            <Menu.Item
                                onClick={() => {
                                    setLoading(true);
                                    InvoiceStore.sendPackingSlipInvoice(record.id)
                                        .then(() => {
                                            setLoading(false);
                                        })
                                        .catch(error => {
                                            notification.error({
                                                message: 'Error Sending Packing Slip',
                                                description: error.toString(),
                                            });
                                            loadData();
                                            setLoading(false);
                                        });
                                }}
                            >
                                <MailOutlined /> Re-send Packing Slip
                            </Menu.Item>
                        )}
                    </Menu>
                );
            },
        },
    ];

    const invoices = InvoiceStore.entities;

    const { current, defaultPageSize, pageSize, total } = InvoiceStore.pagination;

    return (
        <RenderPage
            title={'Invoices'}
            topActions={[
                <Button
                    key="print"
                    type={'primary'}
                    className={'ant-btn'}
                    onClick={() => {
                        setNewInvoiceModal(true);
                    }}
                >
                    New Invoice
                </Button>,
            ]}
        >
            <Row>
                <Col>
                    <InvoiceFilter />
                </Col>
            </Row>
            <Row>
                <Table<IInvoiceDetailStaff>
                    style={{ width: '100%' }}
                    loading={InvoiceStore.isLoading}
                    rowKey={record => record.id.toString()}
                    pagination={false}
                    columns={columns}
                    className=""
                    dataSource={invoices}
                    scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
                    footer={() => {
                        return (
                            <PaginationComponent
                                current={current}
                                defaultPageSize={defaultPageSize}
                                pageSize={pageSize}
                                total={total}
                            />
                        );
                    }}
                />
            </Row>

            <Modal
                title={'Line items'}
                width={'auto'}
                style={{ minWidth: 320, maxWidth: 1000 }}
                open={newInvoiceModal}
                onCancel={() => {
                    setNewInvoiceModal(false);
                }}
                closable={true}
                footer={null}
            >
                <div>
                    <Form>
                        <FormItem label="Sales Order">
                            <GenericSelect
                                disabled={loading}
                                onChange={value => {
                                    if (value) {
                                        setSalesOrderId(value.key);
                                    } else {
                                        setSalesOrderId(null);
                                    }
                                }}
                                recordType={'SalesOrders'}
                                size={'small'}
                            />
                            <div style={{ paddingTop: '15px' }}>
                                <Table
                                    loading={loading}
                                    rowKey={record => record.id.toString()}
                                    rowSelection={rowSelection}
                                    pagination={false}
                                    columns={lineItemsColumns}
                                    dataSource={salesOrder ? lineItems : []}
                                />
                            </div>
                        </FormItem>
                        <FormItem>
                            <Button
                                disabled={selectedRowKeys.length <= 0}
                                style={{ float: 'right' }}
                                type="primary"
                                onClick={() => {
                                    createInvoice();
                                }}
                            >
                                Create Invoice
                            </Button>
                        </FormItem>
                        <div
                            style={{
                                borderTop: 'solid 1px #d9d9d9',
                            }}
                        ></div>
                        <Row>
                            <Col offset={20} span={24}>
                                <div className="mt-2 pt-4 font-bold text-md">Selected Preview</div>
                                <div>
                                    <div className="flex ">
                                        <div className="font-bold text-md mr-2" style={{ fontSize: '15px' }}>
                                            Subtotal:
                                        </div>
                                        <div className="text-md">
                                            <Price value={grandTotalPreview ?? 0} />
                                        </div>
                                    </div>
                                    <div className="flex ">
                                        <div className="font-bold text-md mr-2" style={{ fontSize: '15px' }}>
                                            Grand Total:
                                        </div>
                                        <div className="text-md">
                                            <Price value={grandTotalPreview ?? 0} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </RenderPage>
    );
};

export default observer(Invoices);
