import React from 'react';
import { Badge, Collapse } from 'antd';
import { TooltipFlag } from '../TooltipFlag';
export var RfqInformation = function (props) {
    var _a;
    return (React.createElement("div", { className: "p-4" },
        React.createElement(Collapse, null,
            React.createElement(Collapse.Panel, { header: React.createElement("div", null,
                    React.createElement("div", { className: "flex justify-between" },
                        React.createElement("div", { className: "flex flex-row" },
                            React.createElement("div", { className: "font-bold" }, "RFQ Information"),
                            props.flags > 0 && (React.createElement("div", { className: "flex ml-4" },
                                React.createElement(Badge, { count: props.flags })))))), key: "1" },
                React.createElement("table", { className: "border-collapse border border-gray-500 w-full" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Account Name"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_account_name)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Batch Release / Blanket"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_has_batch_shipments ? 'Yes' : 'No')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Whole Quote Adjustment"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.quote_whole_quote_adjust)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Other Notes"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_other_notes)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Customer Statement"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement("div", { dangerouslySetInnerHTML: {
                                        __html: props.quote_customer_statement,
                                    } }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "ITAR"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_itar ? 'Yes' : 'No')),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Domestic Quoting"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement(Badge, { count: props.quote_domestic_quoting ? 'Yes' : 'No', style: {
                                        backgroundColor: "".concat(props.quote_domestic_quoting ? '#5cad0d' : 'red'),
                                    } }),
                                React.createElement(TooltipFlag, { field_name: 'domestic_quoting' }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Customer Supplied Material"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement(Badge, { count: props.quote_customer_supplied_material ? 'Yes' : 'No', style: {
                                        backgroundColor: "".concat(props.quote_customer_supplied_material ? '#5cad0d' : 'red'),
                                    } }),
                                React.createElement(TooltipFlag, { field_name: 'customer_supplied_material' }))),
                        props.quote_requote_changes.length > 0 && (React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Requote Changes"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement("ul", null, props.quote_requote_changes.map(function (c) { return (React.createElement("li", { key: c }, c)); }))))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "PB Creating 2D?"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                props.rfq_needs_to_create_2d_files ? 'Yes' : 'No',
                                React.createElement(TooltipFlag, { field_name: 'create_2d_files' }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "PB Creating 3D?"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                props.rfq_needs_to_create_3d_files ? 'Yes' : 'No',
                                React.createElement(TooltipFlag, { field_name: 'create_3d_files' }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Additional Requirements"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                React.createElement("ul", null, (_a = props.quote_custom_requirements) === null || _a === void 0 ? void 0 : _a.map(function (r) {
                                    var _a;
                                    return (React.createElement("li", { key: r.id },
                                        React.createElement("span", null, r.label),
                                        r.value && r.value, (_a = r.files) === null || _a === void 0 ? void 0 :
                                        _a.map(function (f) {
                                            return (React.createElement("a", { key: f.id, href: f.filename }, f.filename));
                                        })));
                                })),
                                React.createElement(TooltipFlag, { field_name: 'custom_requirements' }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Target Lead Time"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                props.rfq_target_lead_time,
                                React.createElement(TooltipFlag, { field_name: 'lead_time' }))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Custom Additional Requirements"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.rfq_custom_additional_requirements))))))));
};
