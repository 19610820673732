var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Divider, Input, InputNumber, message, Select, Spin } from 'antd';
import { Price } from '@partsbadger/library';
import { DeleteOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
import { post } from '@partsbadger/utils';
import _, { isNumber } from 'lodash';
import QuickbooksStore from '../stores/QuickbooksStore';
export var ItemsTable = observer(function (props) {
    var getFieldDecorator = props.form.getFieldDecorator;
    var _a = useState(false), error_item = _a[0], setErrorItem = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), sendingToQuickbooks = _c[0], setSendingToQuickbooks = _c[1];
    var _d = useState(false), calculateTax = _d[0], setCalculateTax = _d[1];
    useEffect(function () {
        if (props.line_items)
            PurchaseOrderStore.sync_line_items(props.line_items);
    }, []);
    var handleAddItem = function () {
        var _a, _b, _c;
        var amount = props.form.getFieldValue('item_list_price') * props.form.getFieldValue('item_quantity');
        var total = amount + PurchaseOrderStore.input_tax;
        var name = props.form.getFieldValue('item_description');
        var quickbooks_account = props.form.getFieldValue('item_quickbook_account');
        var filteredItems = [];
        if (((_a = props.form.getFieldValue('sales_order_line_items')) === null || _a === void 0 ? void 0 : _a.length) >= 1) {
            filteredItems = (_c = (_b = PurchaseOrderStore.products_by_item) === null || _b === void 0 ? void 0 : _b.filter(function (filterItem) {
                return props.form.getFieldValue('sales_order_line_items').includes(filterItem.id);
            })) === null || _c === void 0 ? void 0 : _c.map(function (item) {
                if (props.form.getFieldValue('sales_order_line_items').includes(item.id)) {
                    return { id: item.id, name: item.name, sales_order: item.sales_order };
                }
            });
        }
        var sales_order_text = null;
        if (props.form.getFieldValue('sales_order_text')) {
            sales_order_text = props.form.getFieldValue('sales_order_text');
        }
        var product_text = null;
        if (props.form.getFieldValue('product_text')) {
            product_text = props.form.getFieldValue('product_text');
        }
        if (name && quickbooks_account) {
            setErrorItem(false);
            var new_line_item = {
                id: 0,
                sales_order_text: sales_order_text,
                sales_order_line_items: filteredItems,
                product_text: product_text,
                name: name,
                description: props.form.getFieldValue('item_description'),
                quickbooks_account: quickbooks_account.key,
                quickbooks_account_related: { id: quickbooks_account.key, name: quickbooks_account.label },
                status: props.form.getFieldValue('item_status'),
                list_price: props.form.getFieldValue('item_list_price'),
                quantity: props.form.getFieldValue('item_quantity'),
                amount: parseFloat(amount.toFixed(2)),
                discount: 0,
                tax: PurchaseOrderStore.input_tax,
                total: parseFloat(total.toFixed(2)),
            };
            PurchaseOrderStore.add_line_item(new_line_item);
            setCalculateTax(false);
            props.form.resetFields([
                'sales_order_text',
                'sales_order_by_line_item',
                'product_text',
                'sales_order_line_items',
                'item_name',
                'item_description',
                'item_quantity',
                'item_quickbook_account',
                'item_status',
                'item_list_price',
            ]);
        }
        else {
            setErrorItem(true);
            message.error('You must add a name and account');
        }
    };
    var debounceSearchAccount = useCallback(_.debounce(function (search) { return searchAccountQB(search); }, 600), []);
    var debounceSalesOrderItem = useCallback(_.debounce(function (search) { return searchOrderLineItem(search); }, 600), []);
    var searchOrderLineItem = function (search) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, PurchaseOrderStore.getSalesOrderItems({
                            search: search,
                        }).finally(function () { return setLoading(false); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var searchAccountQB = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, QuickbooksStore.list({ search: value }).finally(function () { return setLoading(false); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var calculateTaxValue = function (amount) {
        var _tax = amount * 0.056;
        PurchaseOrderStore.update_input_tax(Number(_tax.toFixed(2)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null, "Line Items"),
        React.createElement("div", { className: "table w-full p-2" },
            error_item && (React.createElement("div", { className: "flex justify-center font-bold text-red-500 pb-2" },
                React.createElement("span", null, "To add an item be sure to enter the name and select an account."))),
            React.createElement("table", { className: "w-full border" },
                React.createElement("thead", null,
                    React.createElement("tr", { className: "border-b" },
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "#")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Sales Order Line Item")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Name / Description")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "List Price")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Quantity")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Amount")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Tax")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Total")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Status")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "In QB")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Account")),
                        React.createElement("th", { className: "p-2 border-r cursor-pointer text-sm" },
                            React.createElement("div", { className: "flex items-center justify-center" }, "Actions")))),
                React.createElement("tbody", null,
                    props.create && (React.createElement("tr", { className: "text-center" },
                        React.createElement("td", { className: "p-2 border" }),
                        React.createElement("td", { className: "p-2 border", style: { maxWidth: '440px' } },
                            React.createElement(Form.Item, null, getFieldDecorator('sales_order_line_items')(React.createElement(Select, { mode: "multiple", showSearch: true, allowClear: true, 
                                // labelInValue
                                filterOption: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSalesOrderItem, onSelect: function (e) {
                                    var _a, _b;
                                    var filteredProducts = PurchaseOrderStore.products_by_item.find(function (item) { return item.id == e; });
                                    if (filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) {
                                        PurchaseOrderStore.add_sales_order_and_line_item((_a = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) === null || _a === void 0 ? void 0 : _a.id, e, (_b = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) === null || _b === void 0 ? void 0 : _b.name);
                                    }
                                }, onDeselect: function (e) {
                                    PurchaseOrderStore.remove_sales_order_and_line_item(e);
                                } }, PurchaseOrderStore.products_by_item.map(function (item) {
                                var _a;
                                return (React.createElement(Select.Option, { key: item.id, value: item.id }, (_a = item === null || item === void 0 ? void 0 : item.sales_order) === null || _a === void 0 ? void 0 :
                                    _a.name,
                                    " - ",
                                    item.name));
                            }))))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_description')(React.createElement(Input.TextArea, { autoSize: true, placeholder: "Description" })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_list_price', {
                                initialValue: 0.0,
                            })(React.createElement(InputNumber, { onChange: function (value) {
                                    if (value != undefined && isNumber(value))
                                        PurchaseOrderStore.update_input_list_price(value);
                                } })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_quantity', {
                                initialValue: 0.0,
                            })(React.createElement(InputNumber, { onChange: function (value) {
                                    if (value != undefined && isNumber(value))
                                        PurchaseOrderStore.update_input_quantity(value);
                                } })))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_amount })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_tax }),
                            React.createElement(Checkbox, { className: 'mx-4', checked: calculateTax, onChange: function (event) {
                                    setCalculateTax(event.target.checked);
                                    if (event.target.checked) {
                                        calculateTaxValue(PurchaseOrderStore.input_amount);
                                    }
                                } })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Price, { value: PurchaseOrderStore.input_total })),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_status', { initialValue: 'Open' })(React.createElement(Select, { style: { minWidth: '50px' } },
                                React.createElement(Select.Option, { value: 'Open' }, "Open"),
                                React.createElement(Select.Option, { value: 'Received in Full' }, "Received in Full"),
                                React.createElement(Select.Option, { value: 'Received Partial' }, "Received Partial"),
                                React.createElement(Select.Option, { value: 'Canceled' }, "Canceled"),
                                React.createElement(Select.Option, { value: 'Paid' }, "Paid"))))),
                        React.createElement("td", { className: "p-2 border" }),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Form.Item, null, getFieldDecorator('item_quickbook_account')(React.createElement(Select, { showSearch: true, allowClear: true, labelInValue: true, filterOption: false, style: { width: '100%' }, dropdownMatchSelectWidth: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSearchAccount }, QuickbooksStore.quickbooks_account.map(function (quickbooks) {
                                return (React.createElement(Select.Option, { key: quickbooks.id, value: quickbooks.id }, quickbooks.name));
                            }))))),
                        React.createElement("td", { className: "p-2 border" },
                            React.createElement(Button, { type: "primary", onClick: handleAddItem }, "Add")))),
                    React.createElement(React.Fragment, null, PurchaseOrderStore.line_items.map(function (item, index) {
                        var _a, _b;
                        return (React.createElement("tr", { className: "text-center border-b text-sm", key: index },
                            React.createElement("td", { className: "p-2 border-r" }, item.line_number),
                            React.createElement("td", { className: "p-2 border", style: { maxWidth: '440px' } },
                                React.createElement(Select, { mode: "multiple", showSearch: true, allowClear: true, labelInValue: true, filterOption: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSalesOrderItem, defaultValue: (_a = item.sales_order_line_items) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                        var _a;
                                        return {
                                            key: item === null || item === void 0 ? void 0 : item.id,
                                            value: item === null || item === void 0 ? void 0 : item.id,
                                            label: "".concat((_a = item === null || item === void 0 ? void 0 : item.sales_order) === null || _a === void 0 ? void 0 : _a.name, " - ").concat(item === null || item === void 0 ? void 0 : item.name),
                                        };
                                    }), onSelect: function (e) {
                                        var _a, _b;
                                        var filteredProducts = PurchaseOrderStore.products_by_item.find(function (item) { return item.id == e.value; });
                                        PurchaseOrderStore.add_sales_order_line_item(item.id, filteredProducts);
                                        if (filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) {
                                            PurchaseOrderStore.add_sales_order_and_line_item((_a = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) === null || _a === void 0 ? void 0 : _a.id, e.value, (_b = filteredProducts === null || filteredProducts === void 0 ? void 0 : filteredProducts.sales_order) === null || _b === void 0 ? void 0 : _b.name);
                                        }
                                    }, onDeselect: function (e) {
                                        PurchaseOrderStore.remove_sales_order_and_line_item(e.value);
                                        PurchaseOrderStore.remove_sales_order_line_item(item.id, e.value);
                                    } }, PurchaseOrderStore.products_by_item.map(function (item) {
                                    var _a;
                                    return (React.createElement(Select.Option, { key: item.id, value: item.id }, (_a = item === null || item === void 0 ? void 0 : item.sales_order) === null || _a === void 0 ? void 0 :
                                        _a.name,
                                        " - ",
                                        item.name));
                                }))),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Input, { className: 'my-1', placeholder: 'Description', value: item.description, onChange: function (e) {
                                        var new_item = __assign(__assign({}, item), { description: e.target.value });
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(InputNumber, { value: item.list_price, onChange: function (value) {
                                        if (value) {
                                            var new_item = __assign(__assign({}, item), { list_price: value });
                                            PurchaseOrderStore.update_line_item(index, new_item);
                                        }
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(InputNumber, { value: item.quantity, onChange: function (value) {
                                        if (value) {
                                            var new_item = __assign(__assign({}, item), { quantity: value });
                                            PurchaseOrderStore.update_line_item(index, new_item);
                                        }
                                    } })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Price, { value: item.amount })),
                            React.createElement("td", { className: "p-2 border" },
                                React.createElement(InputNumber, { value: item.tax, onChange: function (value) {
                                        var new_item = __assign(__assign({}, item), { tax: value !== null && value !== void 0 ? value : 0 });
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    } })),
                            React.createElement("td", { className: "p-2 border" },
                                React.createElement(Price, { value: item.total })),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Select, { value: item.status, onSelect: function (value) {
                                        var new_item = __assign(__assign({}, item), { status: value });
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    }, style: { minWidth: '50px' } },
                                    React.createElement(Select.Option, { value: 'Open' }, "Open"),
                                    React.createElement(Select.Option, { value: 'Received in Full' }, "Received in Full"),
                                    React.createElement(Select.Option, { value: 'Received Partial' }, "Received Partial"),
                                    React.createElement(Select.Option, { value: 'Canceled' }, "Canceled"),
                                    React.createElement(Select.Option, { value: 'Paid' }, "Paid"))),
                            React.createElement("td", { className: "p-2 border-r", style: { width: '100px' } }, item.status === 'Received in Full' && ((_b = item.journal_entry) === null || _b === void 0 ? void 0 : _b.in_quickbooks) ? (React.createElement("a", { target: "_blank", href: "https://app.qbo.intuit.com/app/journal?txnId=".concat(item.journal_entry.qb_id), rel: "noreferrer" }, "In QB")) : (item.status === 'Received in Full' &&
                                item.journal_entry === null && (React.createElement(Button, { className: "mx-2 link", loading: sendingToQuickbooks, onClick: function () {
                                    setSendingToQuickbooks(true);
                                    post("/staff/purchase-orders/".concat(item.purchase_order, "/send-to-quickbooks/").concat(item.id, "/"))
                                        .then(function (response) {
                                        if (typeof item.purchase_order === 'number') {
                                            PurchaseOrderStore.sync_line_items(response);
                                        }
                                        setSendingToQuickbooks(false);
                                    })
                                        .catch(function (error) {
                                        console.log(error);
                                        setSendingToQuickbooks(false);
                                    });
                                } }, "Send to QB")))),
                            React.createElement("td", { className: "p-2 border-r" },
                                React.createElement(Select, { showSearch: true, allowClear: true, filterOption: false, style: { width: '100%' }, dropdownMatchSelectWidth: false, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, placeholder: 'Search', onSearch: debounceSearchAccount, onSelect: function (value) {
                                        var new_item = __assign(__assign({}, item), { quickbooks_account: value });
                                        PurchaseOrderStore.update_line_item(index, new_item);
                                    }, defaultValue: item.quickbooks_account_related &&
                                        item.quickbooks_account_related.id },
                                    item.quickbooks_account_related && (React.createElement(Select.Option, { key: item.quickbooks_account_related.id, value: item.quickbooks_account_related.id }, item.quickbooks_account_related.name)),
                                    QuickbooksStore.quickbooks_account.map(function (quickbooks) {
                                        return (React.createElement(Select.Option, { key: quickbooks.id, value: quickbooks.id }, quickbooks.name));
                                    }))),
                            React.createElement("td", null,
                                React.createElement(Button, { type: "link", onClick: function () { return PurchaseOrderStore.remove_line_item(index); } },
                                    React.createElement(DeleteOutlined, null)))));
                    }))),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 13 },
                            React.createElement(Button, { type: 'primary', style: { float: 'right', marginTop: '5px', marginBottom: '5px' }, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var new_line_item;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                new_line_item = {
                                                    id: 0,
                                                    line_number: PurchaseOrderStore.line_items.length + 1,
                                                    name: 'Shipping Cost',
                                                    description: 'Shipping Cost',
                                                    quickbooks_account: 192,
                                                    quickbooks_account_related: {
                                                        id: 192,
                                                        name: 'Shipping and delivery expense',
                                                    },
                                                    status: 'Open',
                                                    list_price: 0,
                                                    quantity: 1,
                                                    amount: 0,
                                                    discount: 0,
                                                    tax: 0,
                                                    total: 0,
                                                    sales_order_line_items: [], // TODO
                                                };
                                                if (!props.po_id) return [3 /*break*/, 2];
                                                return [4 /*yield*/, PurchaseOrderStore.add_item(new_line_item, props.po_id)];
                                            case 1:
                                                _a.sent();
                                                return [3 /*break*/, 4];
                                            case 2: return [4 /*yield*/, PurchaseOrderStore.add_line_item(new_line_item)];
                                            case 3:
                                                _a.sent();
                                                _a.label = 4;
                                            case 4: return [2 /*return*/];
                                        }
                                    });
                                }); } }, "Add Shipping Cost"))))))));
});
