import React, {useState} from 'react';
import { FileOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload } from 'antd';


const getName = (filename) => {
    const name = filename && filename.includes(".") ? filename.split('.').slice(0, -1).join('.').toLowerCase() : filename
    return name?.toLowerCase()
}

function compareNames(a, b) {
    a = getName(a);
    b = getName(b);
    return (a && b) && (a === b);
}

const ImportFromCsv = ({records, handleUpdate}) => {

    const [fileList, setFileList] = useState([]);

    const handleUpload = (file) => {

        const reader = new FileReader();
        reader.onload = e => {

            const csv_data = CSVToArray(e.target.result)

            records = records.map(record => {

                // Search for quantities

                const data = csv_data.filter(row => {

                    const name = row[0];

                    if (record.file3d) {
                        if (compareNames(record.file3d[0]?.name, name)) {
                            return true;
                        }
                    }

                    if (record.file2d) {
                        if (compareNames(record.file2d[0]?.name, name)) {
                            return true;
                        }
                    }
                    return false;
                });
                if (data.length) {

                    const quantities = data.map(row => {
                        return row[1]
                    });
                    const materials = data.map(row => row[2]);
                    const notes = data.map(row => row[3]);

                    record.quantities = quantities;

                    if (materials?.length) {
                        record.materials = materials[0]
                    }

                    if (notes?.length) {
                        record.notes = notes[0]
                    }

                }

                return record;
            });


            handleUpdate(records);

        };
        reader.readAsText(file);

        return false;

    };


    return (
        <Form.Item label="Update data from a csv File (This overwrites all the input fields)"
                           style={{maxWidth: 400, padding: 10}}
                           help={"Select a csv file with filename, quantity, materials and notes. It only work if the filename matches with the uploaded file"}>
            <Upload.Dragger
                beforeUpload={handleUpload}
                fileList={fileList}
                onChange={info => {
                    let fileList = info.fileList;
                    fileList = fileList.slice(-1);
                    setFileList(fileList);
                }}
                accept='.csv'

            >
                <FileOutlined />
            </Upload.Dragger>
        </Form.Item>
    );

};

function CSVToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = (strDelimiter || ",");

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
    );


    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;


    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while (arrMatches = objPattern.exec(strData)) {

        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
        ) {

            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);

        }

        var strMatchedValue;

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {

            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            strMatchedValue = arrMatches[2].replace(
                new RegExp("\"\"", "g"),
                "\""
            );

        } else {

            // We found a non-quoted value.
            strMatchedValue = arrMatches[3];

        }


        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return (arrData);
}

export default ImportFromCsv;