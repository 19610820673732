import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';

import { DateRangePicker } from '@partsbadger/library';
import { Row, Col, Button, Divider, Card, Table } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import moment from 'moment';
import { get } from '../../shared/api';

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const OrdersReadyToBeProcessedReport = (props: FormComponentProps) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [weekCount, setWeekCount] = useState<number>(0);
    const [filteredWeekCount, setFilteredWeek] = useState<number>(0);

    const { form } = props;

    const { getFieldDecorator } = form;

    useEffect(() => {
        getData2();
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                getData(params);
            }
        });
    };

    const getData = (params: { created_time_min: string; created_time_max: string }) => {
        setLoading(true);
        get(`staff/report-orders-ready-to-be-processed/`, params)
            .then((response: any) => {
                setFilteredWeek(response.data.count);
                setData(response.data.results);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const getData2 = () => {
        setLoading(true);

        const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
        const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

        const params = {
            created_time_min: startOfWeek,
            created_time_max: endOfWeek,
        };

        get(`staff/report-orders-ready-to-be-processed/`, params)
            .then((response: any) => {
                setWeekCount(response.data.count);
                setData(response.data.results);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Order name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Reported day',
            key: 'created_time',
            render: (_t: string, record: any) => {
                return moment(record.created_time).format('YYYY-MM-DD');
            },
        },
    ];

    return (
        <>
            <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Row>
                <Col {...cols}>
                    <div className={'font-bold ml-5'}>This week's count: {weekCount}</div>
                    <div className={'font-bold ml-5'}>Filtered week count: {filteredWeekCount}</div>
                </Col>
            </Row>

            <Divider>
                <div className={'title'}> Orders Ready to be Processed at EOD metric </div>
            </Divider>

            <Card>
                <div className={'w-full'}>
                    <Table key={'fastlane'} columns={columns} loading={loading} dataSource={data} pagination={false} />
                </div>
            </Card>
        </>
    );
};

export const OrdersReadyToBeProcessedForm = Form.create()(OrdersReadyToBeProcessedReport);
