//@ts-nocheck

import React, { useState } from "react";
import { Button, Input } from "antd";
import moment from 'moment';

const dateStrFmt = 'M/D/YYYY HH:mm:ss';

function trackTime(inputStr) {
    function parseRow(rowStr) {
        const splitRow = rowStr.split('\t');
        return {
            'time': splitRow[0],
            'who': splitRow[1],
            'what': splitRow[2],
        };
    }

    const input = inputStr.split('\n');

    const timesFor = {}
    const inTimes = {}
    const outTimes = {}

    input.forEach(rowStr => {
        const row = parseRow(rowStr);
        timesFor[row.who] = {};
    });

    const people = Object.keys(timesFor);

    people.forEach(person => {
        inTimes[person] = input.filter(rowStr => {
            const row = parseRow(rowStr);
            return row.what === 'In' && row.who === person;
        });

        outTimes[person] = input.filter(rowStr => {
            const row = parseRow(rowStr);
            return row.what === 'Out' && row.who === person;
        });
    });

    people.forEach(person => {
        try {
            inTimes[person].forEach((rowStr) => {
                const row = parseRow(rowStr);
                const dateStr = row.time.split(' ')[0];
                timesFor[person][dateStr] = 0;
            });

            inTimes[person].forEach((rowStr, idx) => {
                const row = parseRow(rowStr);
                const dateStr = row.time.split(' ')[0];

                const inTime = moment(row.time, dateStrFmt);
                let outTime = outTimes[person][idx];

                if (outTimes[person][idx] === undefined) {
                } else {
                    outTime = moment(outTime, dateStrFmt);
                    const delta = outTime.diff(inTime, 'seconds');
                    timesFor[person][dateStr] += delta / 60 / 60;
                }
            });
        } catch (e) {
            console.error(e);
        }
    });
    console.log(timesFor);
    return timesFor;
}

export const ShopTimeTracker = () => {
    const [textArea, setTextArea] = useState('');
    const [timeFor, setTimeFor] = useState({});

    return <div className="flex justify-between">
        <div className="w-1/2">
            <Input.TextArea rows={20} value={textArea} onChange={(e) => {
                setTextArea(e.target.value);
            }} />
        </div>

        <div className="w-1/2 p-8">
            <Button className="mb-4" onClick={() => setTimeFor(trackTime(textArea))}>Time Track</Button>

            {timeFor !== {} &&
            Object.keys(timeFor).map(person => <div key={person}>
                <h1>
                    {person}
                </h1>
                {Object.keys(timeFor[person]).map(date => <div key={date} className="flex justify-between w-64">
                    <div>{date}</div>
                    <div>{timeFor[person][date].toFixed(2)}</div>
                </div>)}
                <hr />
                <br />
            </div>)
            }
        </div>
    </div>;
};
