import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Table, Input, Pagination, PaginationProps } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/es/table';
import moment from 'moment';

import { IVendorTicket } from '../../../../../../packages/types/src/staff/list/ITicketChatStaff';
import { TicketStore } from '../../../stores/TicketStore';
import { ChatModal } from './ChatModal';

interface RequestParameter {
    search?: string;
    page?: number;
}

type Props = {
    zoho_id: string;
    message_type?: string | null;
};

export const ClosedTickets = observer(({ zoho_id, message_type }: Props) => {
    const [chatForProducts, showChatForProducts] = useState<IVendorTicket>();
    const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });

    const showTotal: PaginationProps['showTotal'] = total => `Total ${total} Tickets`;

    useEffect(() => {
        loadData();
    }, []);

    const [form] = Form.useForm();

    const loadData = (parameters?: RequestParameter) => {
        const params = {
            zoho_id,
            message_type: message_type ? message_type : 'sales-support',
            status: 'CLOSED',
            search: parameters?.search,
            page: parameters?.page,
        };
        TicketStore.getClosedTickets(params).catch(e => {});
    };

    const data = !message_type ? TicketStore.closedTicketList : TicketStore.closedticketsSalesRequest;

    const columns: ColumnProps<IVendorTicket>[] = [
        {
            title: 'Id',
            key: 'id',
            render: (record: IVendorTicket) => <div>{record.id}</div>,
            width: '70px',
        },
        {
            title: 'Part',
            width: '150px',
            render: (record: IVendorTicket) => <div>{record.products.map(p => p.product_name).toString()}</div>,
        },
        {
            title: 'Order',
            width: '70px',
            render: (record: IVendorTicket) => <div>{record.products[0]?.sales_order.subject}</div>,
        },
        {
            title: 'Date',
            width: '70px',
            key: 'created',
            render: (record: IVendorTicket) => <div>{moment(record.created).format('YYYY-MM-DD')}</div>,
        },
        {
            title: 'Actions',
            width: '70px',
            align: 'center',
            render: (record: IVendorTicket) => (
                <div>
                    <Button
                        type={'link'}
                        onClick={() => {
                            showChatForProducts(record);
                        }}
                    >
                        View
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="flex flex-col w-full space-y-2">
            <Form form={form} layout="inline" className="w-full" onFinish={values => {}}>
                <div className="md:flex md:flex-row w-full pb-2 md:text-left md:ml-2 text-center">
                    <span className="text-red-500 font-bold text-lg md:text-left text-center">
                        Closed / Support Tickets
                    </span>

                    <Form.Item name={'search'} className="w-full md:w-1/3 lg:w-1/4 lg:ml-16 md:mt-0 mt-2">
                        <Input.Search
                            placeholder="Search by order or part"
                            onSearch={value => {
                                loadData({ search: form.getFieldValue('search') });
                            }}
                            className="w-full"
                            maxLength={30}
                        />
                    </Form.Item>

                    <Button
                        className="md:w-24 w-full md:mt-0 mt-2"
                        onClick={() => {
                            loadData();
                            form.resetFields();
                        }}
                    >
                        Show All
                    </Button>
                </div>
            </Form>

            <div className="overflow-x-auto">
                <Table
                    rowKey={'id'}
                    columns={columns}
                    loading={TicketStore.lodingClosedTickets}
                    dataSource={data}
                    pagination={false}
                    scroll={{ y: '350px' }}
                    size="small"
                    sticky
                ></Table>
            </div>

            <Pagination
                className="flex flex-row lg:mt-0 mt-5 flex justify-center p-4"
                size="small"
                defaultCurrent={1}
                pageSize={TicketStore.paginationClosedTickets.pageSize}
                total={
                    !message_type
                        ? TicketStore.paginationClosedTickets.total
                        : TicketStore.paginationClosedTickets.totalSalesRequest
                }
                onChange={(currentPage, pageSize) => {
                    TicketStore.paginationClosedTickets.currentPage = currentPage;

                    setPagination({
                        ...pagination,
                        current: currentPage,
                    });
                    if (!pageSize) pageSize = TicketStore.paginationClosedTickets.pageSize;
                    loadData({ search: form.getFieldValue('search'), page: currentPage });
                }}
                current={pagination.current}
                showTotal={showTotal}
                pageSizeOptions={[TicketStore.paginationClosedTickets.pageSize]}
                showSizeChanger={true}
            />

            <div>
                {chatForProducts && (
                    <ChatModal
                        ticket={chatForProducts}
                        handleClose={() => {
                            showChatForProducts(undefined);
                        }}
                        chatIsReadOnly={true}
                    />
                )}
            </div>
        </div>
    );
});
