import * as ActionTypes from '../ActionTypes';

export const Finishes = (
    state = {
        isLoading: false,
        error: null,
        entities: []
    },
    action) => {
    switch (action.type) {
        case ActionTypes.FINISHES_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};

        case ActionTypes.FINISHES_LOADING:
            return {...state, isLoading: true}

        case ActionTypes.FINISHES_FAILED:
            return {...state, isLoading: false, error: action.error};

        default:
            return state;
    }
};