import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { IVendorListStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { Col, Row, Table, Typography } from 'antd';
import { DateRender, PaginationComponent } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { VendorStore } from '../../../stores/VendorStore';

const VendorsListPage = () => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
        };
        VendorStore.getAll(params).catch(e => {});
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IVendorListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return <div>{record.username}</div>;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return <div>{record.email}</div>;
            },
        },
    ];

    const data = VendorStore.vendors;
    const loading = VendorStore.isLoading;

    return (
        <div
            style={{
                maxWidth: 1800,
                margin: 'auto',
            }}
        >
            <Row>
                <Col span={24}>
                    <Typography.Title level={2}> Vendors</Typography.Title>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table<IVendorListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        // footer={() => {
                        // return (
                        //     <PaginationComponent
                        //         current={VendorStore.pagination.current}
                        //         defaultPageSize={VendorStore.pagination.defaultPageSize}
                        //         pageSize={VendorStore.pagination.pageSize}
                        //         total={VendorStore.pagination.total}
                        //         pageChange={page => {
                        //             searchParams.set('page', page.toString());
                        //             history.replace(`?${searchParams.toString()}`);
                        //         }}
                        //     />
                        // );
                        /*}}*/
                    ></Table>
                </Col>
            </Row>
        </div>
    );
};

export default observer(VendorsListPage);
