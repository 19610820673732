import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import { QuoteStore } from '../../Stores';
import { ErrorRender } from '@partsbadger/library';
import { SortAscendingOutlined } from '@ant-design/icons';
var ApplicationCostForm = function (props) {
    var item = props.item, handleSave = props.handleSave, form = props.form;
    var getFieldDecorator = form.getFieldDecorator;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var quote = QuoteStore.quote;
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                var payload = {
                    quote: quote === null || quote === void 0 ? void 0 : quote.id,
                    additional_requirement: values.additional_requirement,
                    price: values.price,
                };
                setLoading(true);
                handleSave(payload)
                    .then(function () {
                    form.resetFields();
                })
                    .finally(function () {
                    setLoading(false);
                });
            }
        });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(ErrorRender, { error: error }),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 12 },
                React.createElement(Form.Item, null, getFieldDecorator('additional_requirement', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: item === null || item === void 0 ? void 0 : item.additional_requirement,
                })(React.createElement(Input, { style: { width: '90%' }, placeholder: 'Application' })))),
            React.createElement(Col, { sm: 6 },
                React.createElement(Form.Item, null, getFieldDecorator('price', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: item === null || item === void 0 ? void 0 : item.price,
                })(React.createElement(Input, { style: { width: '90%' }, placeholder: '0.00' })))),
            React.createElement(Col, { sm: 6 },
                React.createElement(Form.Item, null,
                    React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save"),
                    props.setReorderingAppCost && (React.createElement("span", { className: 'px-2' },
                        React.createElement(Tooltip, { title: 'Sort App. Costs' },
                            React.createElement(Button, { onClick: function () { return props.setReorderingAppCost && props.setReorderingAppCost(true); } },
                                React.createElement(SortAscendingOutlined, { style: { fontSize: '20px' } }))))))))));
};
export default Form.create()(ApplicationCostForm);
