var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
export var InputQuantities = function (props) {
    return (React.createElement(Select, __assign({ mode: "tags", placeholder: '1,10,100,1000', tokenSeparators: [','], onInputKeyDown: function (evt) {
            var charCode = evt.keyCode;
            if (charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                (charCode < 96 || charCode > 105) &&
                charCode !== 188) {
                evt.preventDefault();
            }
        }, notFoundContent: 'Type a number' }, props)));
};
