var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import QuoteStore from '../../stores/QuoteStore';
var busy = null;
export var Accounts = observer(function (props) {
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, filterOption: false, optionKeyProp: 'value', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search account by name", notFoundContent: QuoteStore.loading_accounts ? React.createElement(Spin, { size: "small" }) : null, onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
            };
            busy = setTimeout(function () { return QuoteStore.getAccounts(params); }, 400);
        } }, props), QuoteStore.accounts.map(function (d) { return (React.createElement(Select.Option, { key: d.id }, d.name)); })));
});
