import React from 'react';
import { Card, Modal } from 'antd';
import { QuoteStore } from '../Stores';
import { observer } from 'mobx-react';
export var QuoteModalHistoryRequote = observer(function (props) {
    function normalizeFieldName(fieldName) {
        var words = fieldName.split('_');
        var capitalizedWords = words.map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); });
        return capitalizedWords.join(' ');
    }
    var get_global_fields = function () {
        var fields = [];
        for (var field in props.changed_fields) {
            if (isNaN(Number(field))) {
                var object = props.changed_fields[field];
                var previous_value = object['previous_value'];
                var current_value = object['current_value'];
                if (previous_value === null) {
                    previous_value = 'No previous value';
                }
                if (current_value === null) {
                    current_value = 'No current value';
                }
                if (Array.isArray(previous_value) && previous_value.every(function (element) { return true; })) {
                    previous_value = object['previous_value'].join(', ');
                }
                if (Array.isArray(current_value) && current_value.every(function (element) { return true; })) {
                    current_value = object['current_value'].join(', ');
                }
                if (previous_value === true) {
                    previous_value = 'Yes';
                }
                else if (previous_value === false) {
                    previous_value = 'No';
                }
                if (current_value === true) {
                    current_value = 'Yes';
                }
                else if (current_value === false) {
                    current_value = 'No';
                }
                fields.push(React.createElement("div", null,
                    React.createElement("span", { className: 'mr-2' }, normalizeFieldName(field)),
                    "was updated from",
                    React.createElement("span", { className: 'mx-2 bold' },
                        previous_value,
                        " "),
                    "to",
                    React.createElement("span", { className: 'mx-2 bold ' }, current_value)));
            }
        }
        return fields;
    };
    var get_specific_fields = function () {
        var items = [];
        for (var field in props.changed_fields) {
            if (!isNaN(Number(field))) {
                var object = props.changed_fields[field];
                object['part_id'] = Number(field);
                items.push(object);
            }
        }
        QuoteStore.lineItems.forEach(function (item) {
            var part = items.find(function (part) { return part.part_id === item.id; });
            if (part) {
                part.part_name = item.name;
            }
        });
        return items;
    };
    return (React.createElement(Modal, { open: true, title: "Changes requested for the Requote", width: 1200, onCancel: props.handleClose, footer: [], bodyStyle: {
            height: '80vh',
            overflowY: 'scroll',
        } },
        React.createElement(Card, { title: 'Quote - Global fields.' }, get_global_fields()),
        React.createElement(Card, { className: 'mt-4', title: 'Line Items - Specific fields.' }, get_specific_fields().map(function (obj, index) { return (React.createElement(Card, { title: obj.part_name, key: index, className: 'mt-4', size: 'small' }, Object.entries(obj).map(function (_a) {
            var field = _a[0], value = _a[1];
            return (React.createElement("div", { key: field }, field !== 'part_id' && field !== 'part_name' && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: 'mr-2' }, normalizeFieldName(field)),
                "was updated from",
                React.createElement("span", { className: 'mx-2 bold' },
                    value.previous_value,
                    " "),
                "to",
                React.createElement("span", { className: 'mx-2 bold ' }, value.current_value)))));
        }))); }))));
});
export default QuoteModalHistoryRequote;
