import React from 'react';
import { List, Comment, Image } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
var isImage = function (file) {
    var types = ['jpg', 'jpeg', 'png', 'gif'];
    var attachmentName = file.attachment_name;
    var array = attachmentName.split('.');
    if (array.length > 0) {
        var type_1 = array[array.length - 1];
        var exits = types.filter(function (extension) { return extension === type_1; });
        return exits.length > 0 ? true : false;
    }
    return false;
};
export default function TicketView(_a) {
    var arrayMessages = _a.arrayMessages;
    return (React.createElement(List, { className: "comment-list shadow-2xl bg-gray-100 px-4 border-2 mb-4 rounded-md", itemLayout: "horizontal", dataSource: arrayMessages, locale: {
            emptyText: 'No messages',
        }, renderItem: function (item) {
            var _a, _b, _c;
            return (React.createElement("li", null,
                React.createElement(Comment, { author: item.author.username, content: React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'mt-2' }, !!(item === null || item === void 0 ? void 0 : item.body) && (item === null || item === void 0 ? void 0 : item.body)),
                        isImage(item) && (React.createElement(Image, { width: 400, preview: {
                                src: item.attachment,
                            }, src: item.attachment })),
                        !isImage(item) && item.attachment && (React.createElement("div", { className: 'mt-2' },
                            React.createElement("a", { target: '_blank', onClick: function () {
                                    window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                                } }, (_c = (_b = (_a = item.attachment) === null || _a === void 0 ? void 0 : _a.split('?')[0]) === null || _b === void 0 ? void 0 : _b.split('/')) === null || _c === void 0 ? void 0 :
                                _c.pop(),
                                React.createElement(PaperClipOutlined, null))))), datetime: dayjs(item.created).format('YYYY-MM-DD HH:mm:ss') })));
        } }));
}
