var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import React from 'react';
import { GOOGLE_MAPS_API_KEY } from '@partsbadger/utils';
/**
 * Get the value for a given key in address_components
 *
 * @param {Array} components address_components returned from Google maps autocomplete
 * @param type key for desired address component
 * @returns {String} value, if found, for given type (key)
 */
function extractFromAddress(components, type) {
    return (components
        .filter(function (component) { return component.types.indexOf(type) === 0; })
        .map(function (item) { return item; })
        .pop() || null);
}
var AutoCompleteAddressInput = function (props) {
    var handleSelectAddress = function (selected) { return __awaiter(void 0, void 0, void 0, function () {
        var value, results, postCode, street_number, street, full_street, town, state, country;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    value = selected.value;
                    return [4 /*yield*/, geocodeByPlaceId(value.place_id)];
                case 1:
                    results = _d.sent();
                    if (results) {
                        postCode = extractFromAddress(results[0].address_components, 'postal_code');
                        street_number = extractFromAddress(results[0].address_components, 'street_number');
                        street = extractFromAddress(results[0].address_components, 'route');
                        full_street = "".concat((_a = street_number === null || street_number === void 0 ? void 0 : street_number.long_name) !== null && _a !== void 0 ? _a : '', " ").concat((_b = street === null || street === void 0 ? void 0 : street.long_name) !== null && _b !== void 0 ? _b : '');
                        town = extractFromAddress(results[0].address_components, 'locality');
                        state = extractFromAddress(results[0].address_components, 'administrative_area_level_1');
                        country = extractFromAddress(results[0].address_components, 'country');
                        if (props.onChange) {
                            props.onChange(full_street);
                        }
                        if (props.onAutoFill) {
                            props.onAutoFill({
                                street: full_street,
                                city: (_c = town === null || town === void 0 ? void 0 : town.long_name) !== null && _c !== void 0 ? _c : '',
                                state: {
                                    key: state === null || state === void 0 ? void 0 : state.short_name,
                                    label: state === null || state === void 0 ? void 0 : state.long_name,
                                    value: state === null || state === void 0 ? void 0 : state.short_name,
                                },
                                country: {
                                    key: country === null || country === void 0 ? void 0 : country.short_name,
                                    label: country === null || country === void 0 ? void 0 : country.long_name,
                                    value: state === null || state === void 0 ? void 0 : state.short_name
                                },
                                zip_code: postCode === null || postCode === void 0 ? void 0 : postCode.long_name,
                            });
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(GooglePlacesAutocomplete, { apiKey: GOOGLE_MAPS_API_KEY, selectProps: {
            placeholder: 'Type Street or P.O. Box',
            onChange: handleSelectAddress,
        } }));
};
export default AutoCompleteAddressInput;
