import React, { useEffect } from 'react';
import { Modal } from 'antd';

import { observer } from 'mobx-react';
import { IVendorTicket, ITicketMessage } from '@partsbadger/types/src/staff/list/ITicketChatStaff';
import TicketChat from './TicketChat';
import moment from 'moment/moment';
import { TicketStore } from '../../../stores/TicketStore';

type Props = {
    ticket: IVendorTicket;
    handleClose: () => void;
    chatIsReadOnly?: boolean;
};

export const ChatModal = observer(({ ticket, handleClose, chatIsReadOnly }: Props) => {
    useEffect(() => {
        buildMessagesToChat(ticket.messages);
    }, []);

    // Messages come from vendor app, this component has different fields
    const buildMessagesToChat = (messages: ITicketMessage[]) => {
        const result = messages.map(message => {
            const newMessage = {
                id: message.id,
                body: message.body,
                attachment: message.attachment,
                attachments: [],
                author: {
                    fullname: message.author,
                },
                notify_owner: false,
                created_time: moment(message.created).toDate(),
            };
            return newMessage;
        });
        TicketStore.ticketMessages = result.reverse();
    };

    return (
        <Modal
            width={900}
            title={<div className={'flex flex-col item-center'}></div>}
            visible={true}
            footer={[]}
            onCancel={() => handleClose()}
            bodyStyle={{
                height: chatIsReadOnly === undefined || chatIsReadOnly === false ? '650px' : '510px',
                overflowY: 'auto',
            }}
        >
            <TicketChat height={460} ticket={ticket.id} chatIsReadOnly={chatIsReadOnly} />
        </Modal>
    );
});
