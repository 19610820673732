import React from 'react';
import { SortAscendingOutlined } from '@ant-design/icons';
import PartStore from '../stores/PartStore';
import { observer } from 'mobx-react';
export var SortFiles = observer(function (_a) {
    var fileType = _a.fileType;
    var isSortedBy = PartStore.sortedBy === fileType;
    return (React.createElement("div", { className: "flex m-auto items-center justify-center", style: {
            maxWidth: 200,
        } },
        React.createElement("div", null,
            fileType,
            "s"),
        React.createElement("div", { className: "flex flex-col ml-2" },
            React.createElement(SortAscendingOutlined, { className: "text-lg cursor-pointer", onClick: function () { return PartStore.sortBy(fileType); }, style: {
                    color: isSortedBy ? 'green' : '#c00',
                } }))));
});
