import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Upload } from 'antd';
import React, { useEffect, useState } from 'react';

import { API_ROOT as BASE_URL } from '@partsbadger/utils';

const FILE_URL = `${BASE_URL}/customer/files/`;
const { TextArea } = Input;

export const ExtraInput = ({ value, handleChange }) => {
    return (
        <Input
            size={'small'}
            style={{ maxWidth: '100px', height: '20px', marginLeft: '5px' }}
            value={value}
            required
            onChange={event => {
                handleChange(event.target.value);
            }}
        />
    );
};

export const ExtraInputText = ({ value, handleChange }) => {
    return (
        <TextArea
            size={'small'}
            style={{ maxWidth: '150px', height: '60px', marginLeft: '5px' }}
            value={value}
            required
            onChange={event => {
                handleChange(event.target.value);
            }}
        />
    );
};

export const ExtraFile = ({ value, handleChange }) => {
    const [fileList, setFileList] = useState([]);

    const defaultFileList = value
        ?.filter(f => f)
        .map(file => {
            return {
                uid: file.id,
                name: file.filename,
                status: 'done',
                url: file.file,
                response: {
                    id: file.id,
                },
            };
        });

    useEffect(() => {
        const data = fileList?.map(file => {
            if (file.status === 'done') {
                return {
                    id: file.response.id,
                    filename: file.response.filename,
                    file: file.response.file,
                };
            }
        });

        if (data.length) {
            handleChange(data);
        }
    }, [fileList]);

    return (
        <>
            <Upload.Dragger
                name="file"
                action={FILE_URL}
                headers={{
                    Authorization: `Token ${localStorage.getItem('token')}`,
                }}
                defaultFileList={defaultFileList}
                onChange={info => {
                    // const {status, response} = info.file;

                    setFileList([...info.fileList]);

                    // if (status === 'done') {
                    //     handleChange(response.id)
                    // }
                }}
                multiple
            >
                <Button>
                    <UploadOutlined /> Click to upload
                </Button>
            </Upload.Dragger>
        </>
    );
};
