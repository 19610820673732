import React from 'react';
export var AccountInfo = function (_a) {
    var account = _a.account;
    return (React.createElement("div", { className: 'quoting-notes flex flex-col' },
        account.custom_terms_and_conditions && (React.createElement("div", { className: 'terms-and-conditions' },
            React.createElement("strong", null, "Custom Terms. and Conditions:"),
            " ",
            account.custom_terms_and_conditions)),
        account.quoting_notes && (React.createElement("div", { className: "account-quoting-notes" },
            React.createElement("strong", null, "Quoting Notes:"),
            " ",
            account.quoting_notes))));
};
