import React, {useState} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Row } from "antd";
import {Contacts} from "@partsbadger/library";
import {AddressForm, AddressList} from "../../../components/Contact/address";

export const ManageAddresses = () => {

    const [contact, setContact] = useState();
    const [addNew, setAddNew] = useState();
    const [address, setAddress] = useState({});


    const editAddress = (record) => {
        setAddress(record);
        setAddNew(false);
    };


    return (
        <div style={{maxWidth: "800px", margin: "auto"}}>
            <Divider>
                <div className={"title"}> Addresses</div>
            </Divider>

            <div style={{marginTop: 10}}>
                <Form.Item label={"Contact"}>
                    <Contacts onSelect={contact => setContact(contact)} />
                </Form.Item>
            </div>


            {contact?.key && (
                <>
                    {addNew || address?.id ? (
                        <div style={{marginTop: 10}}>
                            <AddressForm address={address} contact={contact?.key} callback={() => {
                                setAddNew(false);
                                setAddress({})
                            }} />
                        </div>
                    ) : (

                        <div style={{marginTop: 10}}>
                            <Row>
                                <AddressList contact={contact?.key} editAddress={editAddress} />
                            </Row>

                            <Row style={{textAlign: "right"}}>
                                <Button type={"default"} onClick={() => setAddNew(true)}>Add New </Button>
                            </Row>
                        </div>
                    )}

                </>
            )}
        </div>
    )
}




