var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Col, Input, message, notification, Row, Select } from 'antd';
import React, { useState } from 'react';
import { normalize_number, patch } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import ThumbnailFile from '../../components/MasterProduct/ThumbnailFile';
var Option = Select.Option;
var formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
    },
};
var cols = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
};
var InputCalculator = function (props) {
    var _a;
    var _b = useState((_a = props.value) !== null && _a !== void 0 ? _a : ''), value = _b[0], setValue = _b[1];
    var calculate = function (a, b, operation) {
        if (a && b) {
            if (operation === '+') {
                return parseFloat(a) + parseFloat(b);
            }
            if (operation === '-') {
                return parseFloat(a) - parseFloat(b);
            }
            if (operation === '*') {
                return parseFloat(a) * parseFloat(b);
            }
        }
        message.error('Wrong input');
        return 0;
    };
    return (React.createElement(Input, __assign({}, props, { value: value, onChange: function (e) {
            setValue(e.target.value);
            if (props.onChange) {
                props.onChange(parseFloat(e.target.value));
            }
        }, onKeyPress: function (event) {
            if (event.key === 'Enter' && value) {
                var total = value;
                if (value === null || value === void 0 ? void 0 : value.includes('+')) {
                    var a = value.split('+');
                    total = calculate(a[0], a[1], '+');
                    event.preventDefault();
                }
                else if (value === null || value === void 0 ? void 0 : value.includes('-')) {
                    var a = value.split('-');
                    total = calculate(a[0], a[1], '-');
                    event.preventDefault();
                }
                else if (value === null || value === void 0 ? void 0 : value.includes('*')) {
                    var a = value.split('*');
                    total = calculate(a[0], a[1], '*');
                    event.preventDefault();
                }
                if (total > 0) {
                    setValue(total.toString());
                    if (props.onChange) {
                        props.onChange(total);
                    }
                }
                else if (isNaN(total)) {
                    setValue('0');
                    if (props.onChange) {
                        props.onChange(0);
                    }
                }
            }
        } })));
};
var MasterProductForm = function (props) {
    var _a;
    var form = props.form, _b = props.record, record = _b === void 0 ? {} : _b, handleChange = props.handleChange, handleCreate = props.handleCreate;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue, setFieldsValue = form.setFieldsValue;
    var renderFile = function () {
        var url = "/3d-viewer/".concat(record.id, "/");
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                setLoading(true);
                if (record === null || record === void 0 ? void 0 : record.id) {
                    var payload = __assign({}, values);
                    patch('/staff/master-products/' + record.id + '/', payload)
                        .then(function (response) {
                        setLoading(false);
                        if (handleChange) {
                            handleChange(response.id);
                        }
                    })
                        .catch(function (error) {
                        notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        setLoading(false);
                    });
                }
                else {
                    var payload = {
                        master_product: __assign(__assign({}, values), { volume: values.volume ? values.volume : 0, part_volume_percentage: values.part_volume_percentage ? values.part_volume_percentage : 0 }),
                        sides: values.sides,
                        taps: values.taps,
                    };
                    setLoading(false);
                    if (handleCreate) {
                        handleCreate(payload);
                    }
                }
            }
        });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, style: {
            padding: '20px',
            borderRadius: '6px',
        } },
        React.createElement(Row, null,
            record.id && React.createElement(ThumbnailFile, { master_product: record, handleClick: renderFile }),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'Name' }, formItemLayout), getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Please input a value!' }],
                    initialValue: record.name,
                })(React.createElement(Input, null)))),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'shape' }, formItemLayout), getFieldDecorator('shape', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: record.shape,
                })(React.createElement(Select, { showSearch: true },
                    React.createElement(Option, { value: "REC" }, "REC"),
                    React.createElement(Option, { value: "ROD" }, "ROD"),
                    React.createElement(Option, { value: "TUBE" }, "TUBE"))))),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'Unit' }, formItemLayout), getFieldDecorator('unit', {
                    rules: [{ required: true, message: 'Please select a value!' }],
                    initialValue: record.unit || 'IN',
                })(React.createElement(Select, { showSearch: true },
                    React.createElement(Option, { value: "IN" }, "IN"),
                    React.createElement(Option, { value: "MM" }, "MM"))))),
            React.createElement(React.Fragment, null,
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Length' }, formItemLayout), getFieldDecorator('length', {
                        rules: [{ required: true, message: 'Required!' }],
                        initialValue: normalize_number(record.length),
                    })(React.createElement(InputCalculator, null)))),
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Width' }, formItemLayout), getFieldDecorator('width', {
                        rules: [
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ],
                        initialValue: normalize_number(record.width),
                    })(React.createElement(InputCalculator, null)))),
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Height' }, formItemLayout), getFieldDecorator('height', {
                        rules: [
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ],
                        initialValue: normalize_number(record.height),
                    })(React.createElement(InputCalculator, null))))),
            getFieldValue('shape') === 'TUBE' && (React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'Inner Diam.' }, formItemLayout), getFieldDecorator('inner_diameter', {
                    rules: [
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ],
                    initialValue: normalize_number(record.inner_diameter),
                })(React.createElement(InputCalculator, null))))),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'Vol.' }, formItemLayout), getFieldDecorator('volume', {
                    rules: [
                        {
                            required: false,
                        },
                    ],
                    initialValue: normalize_number(record.volume),
                })(React.createElement(InputCalculator, null)))),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: '(%) Vol.' }, formItemLayout, { help: '% of Bounding Box volume' }), getFieldDecorator('part_volume_percentage', {
                    rules: [
                        {
                            required: !getFieldValue('volume'),
                            message: 'Required!',
                        },
                    ],
                    initialValue: record.part_volume_percentage,
                })(React.createElement(InputCalculator, null)))),
            React.createElement(Col, __assign({}, cols),
                React.createElement(Form.Item, __assign({ label: 'Itar' }, formItemLayout), getFieldDecorator('itar', {
                    initialValue: record.itar,
                    valuePropName: 'checked',
                })(React.createElement(Checkbox, null)))),
            (record === null || record === void 0 ? void 0 : record.id) ? (React.createElement(React.Fragment, null,
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Quotable' }, formItemLayout), getFieldDecorator('quotable', {
                        valuePropName: 'checked',
                        initialValue: record.quotable,
                    })(React.createElement(Checkbox, null)))),
                !getFieldValue('quotable') && (React.createElement(Col, __assign({}, cols, { lg: 8 }),
                    React.createElement(Form.Item, __assign({ label: 'Notes' }, formItemLayout, { help: 'Why this product is not quotable?' }), getFieldDecorator('notes', {
                        initialValue: record.notes,
                    })(React.createElement(Input.TextArea, { rows: 2 }))))),
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'MC Master SKU' }, formItemLayout), getFieldDecorator('mcmaster_sku', {
                        rules: [{ required: false, message: 'Required!' }],
                        initialValue: record.mcmaster_sku,
                    })(React.createElement(Input, { placeholder: "" })))),
                React.createElement(Col, { md: 24, style: { textAlign: 'center', padding: 10 } },
                    record.editable && (React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'check' }), loading: loading }, "Update")),
                    !record.editable && ((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.editable) && (React.createElement(React.Fragment, null,
                        React.createElement(Alert, { style: { margin: 5 }, type: 'warning', message: 'This master product is used in other quotes' }),
                        React.createElement(Button, { danger: true, type: 'primary', icon: React.createElement(LegacyIcon, { type: 'check' }), onClick: function () {
                                QuoteStore.duplicateMasterProduct(record.id);
                            }, loading: loading }, "Duplicate")))))) : (React.createElement(React.Fragment, null,
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Taps' }, formItemLayout), getFieldDecorator('taps', {
                        rules: [{ required: true, message: 'Required!' }],
                        initialValue: 0,
                    })(React.createElement(Input, { placeholder: "", type: 'number' })))),
                React.createElement(Col, __assign({}, cols),
                    React.createElement(Form.Item, __assign({ label: 'Sides' }, formItemLayout), getFieldDecorator('sides', {
                        rules: [{ required: true, message: 'Required!' }],
                    })(React.createElement(Input, { placeholder: "", type: 'number' })))),
                React.createElement(Col, { md: 24, style: { textAlign: 'center', padding: 10 } },
                    React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'check' }), loading: loading }, "Save as New")))))));
};
export default Form.create()(MasterProductForm);
