var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Form, Row, Upload, Input, Checkbox, notification, message, Table, Tag, } from 'antd';
import { Price } from '@partsbadger/library';
import SalesOrderStore from './Stores/SalesOrderStore';
import { UploadOutlined, NumberOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
var OrderShopToolForm = function () {
    var _a, _b;
    var form = Form.useForm()[0];
    var _c = useState(0), total = _c[0], setTotal = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(), orderItems = _e[0], setOrderItems = _e[1];
    var history = useHistory();
    var params = useParams();
    var _f = useState([]), keyLineItems = _f[0], setKeyLineItems = _f[1];
    var _g = useState([]), hasTravelerFile = _g[0], setHasTravelerFile = _g[1];
    function getSalesOrderDetails(id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4 /*yield*/, SalesOrderStore.getOrderById(toNumber(id))
                                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                                var _a, _b;
                                return __generator(this, function (_c) {
                                    if (SalesOrderStore.order && ((_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 : _a.line_items.length) > 0) {
                                        setOrderItems(SalesOrderStore.order.line_items);
                                        SalesOrderStore.getHasTravelerFileSalesOrder(toNumber(id)).then(function () {
                                            setHasTravelerFile(SalesOrderStore.has_traveler_file);
                                        });
                                        form.setFieldsValue({
                                            customer_supplied_material: (_b = SalesOrderStore.order) === null || _b === void 0 ? void 0 : _b.has_customer_supplied_material,
                                        });
                                    }
                                    return [2 /*return*/];
                                });
                            }); })
                                .catch(function (error) {
                                message.error("Error loading tracking codes: ".concat(error));
                            })];
                    case 1:
                        _a.sent();
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        if (params.id) {
            getSalesOrderDetails(params.id);
        }
    }, [params.id]);
    var rowSelection = {
        onChange: function (selectedRowKeys, selectedRows) {
            setKeyLineItems(selectedRowKeys);
            try {
                var sum_1 = 0;
                selectedRows.forEach(function (value) {
                    sum_1 += toNumber(value.total);
                });
                setTotal(sum_1);
            }
            catch (e) {
                setTotal(0);
            }
        },
    };
    var handleSubmit = function (values) {
        var _a, _b;
        if (keyLineItems.length > 0) {
            var payload = {
                id: params.id,
                line_items: keyLineItems,
                customer_supplied_material: values.customer_supplied_material,
                notes: values.notes,
                files: (values === null || values === void 0 ? void 0 : values.files) ? (_b = (_a = values === null || values === void 0 ? void 0 : values.files) === null || _a === void 0 ? void 0 : _a.fileList) === null || _b === void 0 ? void 0 : _b.map(function (f) { return f.originFileObj; }) : [],
            };
            setLoading(true);
            SalesOrderStore.sendToShop(payload)
                .then(function (response) {
                response;
                openNotificationWithIcon('success');
                setLoading(false);
            })
                .catch(function (error) { return error; });
            onReset();
            form.setFieldsValue({ customer_supplied_material: values.customer_supplied_material });
        }
        else {
            notification['error']({
                message: 'Order to Shop',
                description: 'Please select a line item.',
            });
        }
    };
    var onFinishFailed = function (errorInfo) {
        openNotificationWithIcon('error');
    };
    var onReset = function () {
        form.setFieldsValue({ files: [] });
        setKeyLineItems([]);
        // setHasTravelerFile([]);
        setTotal(0);
        form.resetFields();
    };
    var openNotificationWithIcon = function (type) {
        if (type === 'success') {
            notification[type]({
                message: 'The order was sent to the shop successfully.',
                description: 'SUCCESS.',
            });
        }
        else {
            notification[type]({
                message: 'Order to Shop.',
                description: 'Please review the red-colored field.',
            });
        }
    };
    var columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: function (t, r) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", null, t !== 'CNC Part' ? t : '')),
                    React.createElement("div", { className: 'text-md' }, r.product_description)));
            },
            sorter: function (a, b) { return a.name.length - b.name.length; },
        },
        {
            title: 'Has traveler file?',
            dataIndex: 'has_traveler_file',
            key: 'has_traveler_file',
            align: 'center',
            render: function (text, record) {
                return hasTravelerFile.includes(record.id) ? React.createElement(Tag, { color: "green" }, "YES ") : React.createElement(Tag, { color: "red" }, "NO ");
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },
        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            align: 'center',
            render: function (text, record) { return React.createElement(Price, { value: record.list_price }); },
        },
        {
            title: 'Total Price',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: function (text, record) { return React.createElement(Price, { value: record.total }); },
        },
    ];
    return (React.createElement("div", { style: { maxWidth: '800px', margin: 'auto' } },
        React.createElement(Divider, null),
        React.createElement("div", { className: "flex flex-col justify-center" },
            React.createElement("p", { className: 'label flex items-center m-0 text-base font-serif' },
                React.createElement(NumberOutlined, { className: "mr-1" }),
                " Order Number:"),
            React.createElement("p", { className: 'font-bold text-base ml-1', style: { width: '7vw' } }, (_b = (_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '--')),
        React.createElement(React.Fragment, null,
            React.createElement(Form, { form: form, name: "Order to the Shop", onFinish: handleSubmit, onFinishFailed: onFinishFailed },
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, { name: "line_items", className: "mb-0" },
                                React.createElement(Table, { rowKey: function (record) { return record.id.toString(); }, pagination: false, loading: loading, 
                                    // rowSelection={rowSelection}
                                    rowSelection: __assign({ type: 'checkbox' }, rowSelection), columns: columns, dataSource: orderItems, className: "border", footer: function () { return (React.createElement(Row, { style: { marginTop: 15 } },
                                        React.createElement(Col, { sm: 24, md: 12 }),
                                        React.createElement(Col, { sm: 24, md: 12 },
                                            React.createElement("div", { className: 'flex flex-col justify-between' },
                                                React.createElement("div", { className: "flex flex-row justify-between  mb-1" },
                                                    React.createElement("div", { className: "summary-label" }, "Subtotal"),
                                                    React.createElement("div", { className: "summary-value" },
                                                        React.createElement(Price, { value: total }))))))); }, scroll: window.innerWidth <= 600 ? { x: 600 } : undefined })))),
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { md: 24, span: 24 },
                            React.createElement(Form.Item, { label: 'Customer Supplied Material', name: "customer_supplied_material", className: "mb-0", valuePropName: "checked" },
                                React.createElement(Checkbox, null)))),
                    React.createElement(Row, { className: 'row-order mt-5 text-left' },
                        React.createElement(Col, { md: 24, span: 24 })),
                    React.createElement(Col, null,
                        React.createElement(Form.Item, { name: 'files', label: "Upload Files", rules: [{ required: false }] },
                            React.createElement(Upload, { multiple: true, accept: ".pdf", beforeUpload: function () { return false; } },
                                React.createElement(Button, { icon: React.createElement(UploadOutlined, null) }, "Click to Upload"))),
                        React.createElement(Form.Item, { name: 'notes', label: "Notes" },
                            React.createElement(Input.TextArea, null))),
                    React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                        React.createElement(Form.Item, null,
                            React.createElement(Button, { type: "primary", htmlType: "submit", loading: loading }, "Submit"))))))));
};
export default observer(OrderShopToolForm);
