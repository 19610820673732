import React from 'react';

import { HistoryChanges } from '@partsbadger/library';

import { Modal } from 'antd';

interface Props {
    id: number;
    handleClose: () => void;
}

export const OrderLineItemModalHistory = ({ id, handleClose }: Props) => {
    return (
        <Modal
            open
            title="Order Line Item History"
            width={1200}
            onCancel={handleClose}
            footer={[]}
            bodyStyle={{
                height: '80vh',
                overflowY: 'scroll',
            }}
        >
            <HistoryChanges model_name="sales-order-line-items" pk={id} />
        </Modal>
    );
};

export default OrderLineItemModalHistory;
