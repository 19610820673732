import React from 'react';
import { Button, notification } from 'antd';
import { get } from '../../shared/api';
import { ErrorRender } from '@partsbadger/library';

class NextRfqButton extends React.Component {
    render() {
        return (
            <Button
                type="primary"
                className="font-bold"
                onClick={() => {
                    get('staff/rfq/ticker/')
                        .then(resp => {
                            if (resp.data.next_rfq_url) {
                                window.open(resp.data.next_rfq_url);
                            }
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: 'Next RFQ not found!',
                                placement: 'topRight',
                            });
                        })
                        .catch(err => {
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: <ErrorRender error={err} />,
                                placement: 'topRight',
                            });
                        });
                }}
            >
                Take Next Quote
            </Button>
        );
    }
}

export default NextRfqButton;
