import * as ActionTypes from "../ActionTypes";
import {showNotification} from "./notificationsActions";
import {get, post, put} from '../../shared/api'


export const fetchLeads = (params) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.LEADS_LOADING});
        return get("staff/noho-leads/", params).then(response => {
            dispatch({type: ActionTypes.LEADS_LOADED, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.LEADS_FAILED, error: error}));
    }
}


export const fetchContacts = (params) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.CONTACTS_LOADING});
        return get("staff/contacts/", params).then(response => {
            dispatch({type: ActionTypes.CONTACTS_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.CONTACTS_FAILED, error: error}));
    }
}


/**
 * Get contact by Id and save in the state
 */
export const fetchContactById = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.CONTACTS_LOADING});
        return get("staff/contacts/" + id + "/").then(response => {
            dispatch({type: ActionTypes.SET_SELECTED_CONTACT, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.CONTACTS_FAILED, error: error}));
    }
}

/**
 * Clear the selected contact on the store
 */
export const clearContact = () => ({
    type: ActionTypes.SET_SELECTED_CONTACT, payload: {}
})

export const postContact = (payload, callback = null) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.CONTACTS_LOADING});
        return post("staff/contacts/", payload).then(response => {
            dispatch({type: ActionTypes.CONTACT_CREATED, payload: response.data})
            dispatch({type: ActionTypes.HIDE_FORM_CONTACT});
            dispatch(showNotification("Contact was created successfully"))

            if (callback) {
                callback();
            }

        }).catch(error => dispatch({type: ActionTypes.CONTACTS_FAILED, error: error}));
    }
}


export const updateContact = (id, payload, callback) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.CONTACTS_LOADING});
        return put("staff/contacts/" + id + "/", payload).then(response => {
            dispatch(hideContactForm());

            if (callback) {
                callback();
            }
        }).catch(error => dispatch({type: ActionTypes.CONTACTS_FAILED, error: error}));

    }
}


export const fetchAccounts = (params) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.ACCOUNTS_LOADING});
        return get("staff/accounts/", params).then(response => {
            dispatch({type: ActionTypes.ACCOUNTS_LOADED, payload: response.data.results});

        }).catch(error => dispatch({type: ActionTypes.ACCOUNTS_FAILED, error: error}));
    }
}


export const fetchAccountById = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.ACCOUNTS_LOADING});
        return get("staff/accounts/" + id + "/").then(response => {
            if (response.status === 200)
                dispatch({type: ActionTypes.ACCOUNT_LOADED, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.ACCOUNTS_FAILED, error: error}));
    }
}

/**
 * Clear the selected contact on the store
 */

export const clearAccount = () => ({
    type: ActionTypes.ACCOUNT_LOADED, payload: {}
})

export const postAccount = (payload, callback = null) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.ACCOUNTS_LOADING});
        return post("staff/accounts/", payload).then(response => {
            dispatch({type: ActionTypes.ACCOUNT_CREATED, payload: response.data})
            dispatch({type: ActionTypes.HIDE_FORM_ACCOUNT});
            if (callback) {
                callback()
            }

        }).catch(error => dispatch({type: ActionTypes.ACCOUNTS_FAILED, error: error}));
    }
}

export const showContactForm = (defaultContact = {}) => ({
    type: ActionTypes.SHOW_FORM_CONTACT,
    payload: defaultContact,
});


export const hideContactForm = () => ({
    type: ActionTypes.HIDE_FORM_CONTACT
});


export const showEditContact = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.CONTACTS_LOADING});
        return get("staff/contacts/" + id + "/").then(response => {
            dispatch(showContactForm( response.data));
        }).catch(error => dispatch({type: ActionTypes.CONTACTS_FAILED, error: error}));
    }
};


export const showAccountForm = (defaultAccount = {}) => ({
    type: ActionTypes.SHOW_FORM_ACCCOUNT,
    payload: defaultAccount,

});


export const hideAccountForm = () => ({
    type: ActionTypes.HIDE_FORM_ACCOUNT
});


export const fetchSites = (params) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.SITES_LOADING});
        return get("staff/sites/", params).then(response => {
            dispatch({type: ActionTypes.SITES_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.SITE_FAILED, error: error}));
    }
}


