import React, { useRef, useState, useEffect } from 'react';

import { BellOutlined, FileImageOutlined } from '@ant-design/icons';

import { Badge, List, Avatar, Button, Skeleton, ConfigProvider } from 'antd';
import { INotification } from '../../../utils/types';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

declare module 'dayjs' {
    interface Dayjs {
        fromNow(withoutSuffix?: boolean): string;
    }
}

interface IProps {
    data: INotification[];
    loading: boolean;
    makeRead: (id: number) => void;
}

const Notifications: React.FC<IProps> = ({ data, loading, makeRead }) => {
    const [visible, setVisible] = useState<boolean>(false);

    const wrapperRef = useRef<any>(null);

    const unReadNotifications = data.filter(item => {
        return item.read != true;
    });

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setVisible(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const emptyTable = () => {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span style={{ color: '#000' }}>No notifications yet!</span>}
            />
        );
    };

    return (
        <div ref={wrapperRef}>
            <Button
                onClick={() => {
                    setVisible(!visible);
                }}
                style={{ border: 0 }}
            >
                <Badge count={unReadNotifications.length} overflowCount={100}>
                    <BellOutlined className={'step-notifications'} />
                </Badge>
            </Button>
            <ConfigProvider renderEmpty={(data.length === 0 ? 'customize' : 'default') && emptyTable}>
                <List
                    style={{
                        display: visible ? 'block' : 'none',
                        // position: 'absolute',
                        padding: '15px 5px',
                        borderRadius: '4px',
                        // width: '450px',
                        height: '50vh',
                        // left: 'calc(80% - 6vw)',
                        top: 'calc(2% + 30px)',
                        background: 'rgb(252, 252, 252)',
                        // overflowY: 'auto',
                        // overflowX: 'auto',
                    }}
                    className="absolute mt-2 sm:mt-0 right-0 left-4 w-98 xl:w-1/4 sm:mr-5 md:mr-10 xl:mr-15 overflow-y-auto"
                    header={
                        <>
                            <p className="text-xl font-bold pl-2 m-0">Notifications</p>
                            <p className="text-sm pl-2 m-0 text-red-400">
                                You have {unReadNotifications.length} unread notifications.
                            </p>
                        </>
                    }
                    itemLayout="horizontal"
                    dataSource={data}
                    size="small"
                    rowKey={(record: any) => record.id}
                    bordered
                    renderItem={item => (
                        <List.Item
                            style={{
                                backgroundColor: !item.read ? '#d6ebf775' : 'transparent',
                                borderRadius: 5,
                            }}
                            className="mt-1"
                        >
                            <Skeleton avatar title={false} loading={loading} active>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar style={{ backgroundColor: '#026de728' }} icon={<FileImageOutlined />} />
                                    }
                                    title={
                                        <div className="flex justify-between">
                                            <a
                                                style={{
                                                    color: item.read ? '#999' : '#000000c1',
                                                    width: '12.3vw',
                                                }}
                                                href={item.url ? item.url : ''}
                                                onClick={() => makeRead(item.id)}
                                                className="font-bold"
                                            >
                                                {item.title}
                                            </a>
                                            <span style={{ color: '#999', width: '5vw' }}>
                                                {dayjs(item.created_time).fromNow()}
                                            </span>
                                        </div>
                                    }
                                    description={
                                        <div className="flex flex-col">
                                            <a
                                                style={{ color: item.read ? '#999' : '#000000c1' }}
                                                href={item.url ? item.url : ''}
                                                onClick={() => makeRead(item.id)}
                                            >
                                                {item.content}
                                            </a>
                                            {!item.read && (
                                                <Button
                                                    className="flex justify-end text-red-400 mt-2"
                                                    key={item.id}
                                                    style={{
                                                        border: 0,
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    onClick={() => makeRead(item.id)}
                                                >
                                                    mark as read
                                                </Button>
                                            )}
                                        </div>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </ConfigProvider>
        </div>
    );
};

export default Notifications;
