import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, notification, PageHeader, Row, Skeleton, Table } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { IInventory, IInventoryLineItem } from '@partsbadger/types';
import { get, post } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import dayjs from 'dayjs';
import moment from 'moment/moment';

const columns: ColumnProps<IInventoryLineItem>[] = [
    {
        title: 'Part',
        dataIndex: 'sales_order_line_item',
        key: 'sales_order_line_item',
        width: '20%',
        render: (t, r: IInventoryLineItem) => {
            return (
                <div>
                    <div>{typeof r.sales_order_line_item === 'object' && r.sales_order_line_item.name}</div>
                </div>
            );
        },
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
    },
    {
        title: 'Quantity Remaining',
        dataIndex: 'remaining_quantity',
        key: 'remaining_quantity',
        align: 'center',
    },
    {
        title: 'Reason for Conforming Spare',
        dataIndex: 'reason_for_conforming_spare',
        key: 'reason_for_conforming_spare',
        align: 'center',
    },
    {
        title: 'Storage Location',
        dataIndex: 'storage_location',
        key: 'storage_location',
        align: 'center',
    },
    {
        title: 'Created Time',
        dataIndex: 'created_time',
        key: 'created_time',
        align: 'center',
        render: created_time => {
            return <div>{moment(created_time).format('MM/DD/YYYY H:mm:ss')}</div>;
        },
    },
    {
        title: 'To Be Scrapped Schedule',
        dataIndex: 'to_be_scrapped_schedule',
        key: 'to_be_scrapped_schedule',
        align: 'center',
    },
];

const InventoryDetails = () => {
    const history = useHistory();
    const params = useParams<{ id: string }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [inventory, setInventory] = useState<IInventory>();

    const getInventoryDetails = (id: string | number) => {
        get(`/staff/inventory/${id}/`)
            .then(data => {
                setInventory(data);
            })
            .finally(() => setLoading(false));
    };

    const scrapeParts = (id: number | string) => {
        setLoading(true);

        post(`/staff/inventory/${id}/scrap-parts/`)
            .then(() => {
                notification.success({
                    message: 'Scrapped Parts Done!',
                });
                getInventoryDetails(id);
            })
            .catch(e => {
                notification.error({
                    message: e.message,
                    description: <ErrorRender error={e} />,
                });
            })
            .finally(() => setLoading(false));
    };

    const fixParts = (id: number | string) => {
        setLoading(true);

        post(`/staff/inventory/${id}/fix-parts/`)
            .then(data => {
                notification.success({
                    message: 'Sales Order created!',
                });
                history.push(`/sales-orders/`);
            })
            .catch(e => {
                notification.error({
                    message: e.message,
                    description: <ErrorRender error={e} />,
                });
            })
            .finally(() => setLoading(false));
    };

    const remakeParts = (id: number | string) => {
        setLoading(true);

        post(`/staff/inventory/${id}/remake-parts/`)
            .then(data => {
                notification.success({
                    message: 'Sales Order created!',
                });
                history.push(`/sales-orders/`);
            })
            .catch(e => {
                notification.error({
                    message: e.message,
                    description: <ErrorRender error={e} />,
                });
            })
            .finally(() => setLoading(false));
    };

    const redoParts = (id: number | string) => {
        setLoading(true);
        post(`/staff/inventory/${id}/redo-parts/`)
            .then(data => {
                notification.success({
                    message: 'New Sales Order created!',
                });
                history.push(`/sales-orders/`);
            })
            .catch(e => {
                notification.error({
                    message: e.message,
                    description: <ErrorRender error={e} />,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        getInventoryDetails(params.id);
    }, []);

    if (!inventory && !loading) {
        return (
            <Card bordered={false}>
                <Row className="detail-top-row">
                    <Col>
                        <ArrowLeftOutlined onClick={history.goBack} />
                        <span> Inventory</span>
                    </Col>
                </Row>
            </Card>
        );
    }

    return (
        <Card bodyStyle={{ padding: 0 }} bordered={false}>
            {loading && (
                <div className="w-full m-4 py-8 text-center">
                    <Skeleton paragraph={{ rows: 4 }} />
                </div>
            )}
            {!loading && inventory && (
                <>
                    <div>
                        <PageHeader
                            onBack={() => history.goBack()}
                            title={inventory.name}
                            subTitle={inventory.status}
                        />
                    </div>
                    <div
                        style={{
                            maxWidth: 1200,
                            margin: 'auto',
                        }}
                    >
                        <div className={'flex flex-row items-center justify-center'}>
                            <div className={'ml-2'}>
                                <Button
                                    type={'primary'}
                                    danger
                                    loading={loading}
                                    onClick={() => inventory.id && scrapeParts(inventory.id)}
                                >
                                    Scrap parts
                                </Button>
                            </div>
                            <div className={'ml-2'}>
                                <Button
                                    type={'primary'}
                                    loading={loading}
                                    onClick={() => inventory.id && fixParts(inventory.id)}
                                >
                                    Fix Parts
                                </Button>
                            </div>
                            <div className={'ml-2'}>
                                <Button
                                    type={'default'}
                                    loading={loading}
                                    onClick={() => inventory.id && remakeParts(inventory.id)}
                                >
                                    Remake Parts
                                </Button>
                            </div>
                            <div className={'ml-2'}>
                                <Button
                                    type={'default'}
                                    loading={loading}
                                    onClick={() => inventory.id && redoParts(inventory.id)}
                                >
                                    Redo Parts
                                </Button>
                            </div>
                        </div>
                        <div className={'flex flex-row justify-between my-4'}>
                            <div>
                                <p className={'label'}>Account</p>
                                <h3 className={'value'}>{inventory.account_related?.name}</h3>
                            </div>
                            <div>
                                <p className={'label'}>Complete Date</p>
                                <h3 className={'value'}>
                                    {inventory.complete_date ? dayjs(inventory.complete_date).format('LL') : '--'}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <Divider className={'spacing'} />

                    <div>
                        <div>
                            <p className={'label'}>LINE ITEMS</p>
                        </div>
                    </div>

                    <Table<IInventoryLineItem>
                        rowKey={'id'}
                        pagination={false}
                        columns={columns}
                        dataSource={inventory.line_items}
                        scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
                    />
                </>
            )}
        </Card>
    );
};

export default InventoryDetails;
