import React from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import InputField from './InputFields'


const Item = ({children, dataIndex, record, handleChange, handleDelete}) => {

    return (
        <td style={{width: '600px'}}>
            <DndProvider backend={HTML5Backend}>
                {dataIndex ? <InputField dataIndex={dataIndex} record={record}
                                         handleChange={handleChange}
                                         handleDelete={handleDelete}
                /> : children}
            </DndProvider>
        </td>
    );
};


export default Item;
