import React, { useState } from 'react';
import { RetweetOutlined } from '@ant-design/icons';
import { post } from '@partsbadger/utils';

import { Worker, Viewer } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Space, Switch } from 'antd';

interface Props {
    url: string;
}

export const DrawingFileViewer = (props: Props) => {
    const [enabled_grid, setEnabledGrid] = useState(false);

    const [fileUrl, setFileUrl] = useState(props.url);

    const [angleRotation, setAngleRotation] = useState(90);

    const [loading, setLoading] = useState(false);

    const [useNativeViwer, setUseNativeViwer] = useState(true);

    const rotateDrawingFile = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const drawing_file_id = queryParams.get('drawing_file_id') ? queryParams.get('drawing_file_id') : '';
        setAngleRotation(angleRotation < 180 ? angleRotation + 90 : 0);

        setLoading(true);
        post('/staff/drawing-files/' + drawing_file_id + '/rotate-file/', {
            angle: angleRotation,
        })
            .then(response => {
                setFileUrl(response.url);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: 67,
                    bottom: 10,
                    left: 10,
                    zIndex: 99,
                }}
            >
                <button
                    style={{
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(217, 41, 35)',
                        borderColor: 'rgb(217, 41, 35)',
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}
                    onClick={() => {
                        setEnabledGrid(!enabled_grid);
                    }}
                >
                    {enabled_grid ? 'Hide' : 'Show'} Grid
                </button>
                <button
                    style={{
                        color: 'rgb(255, 255, 255)',
                        backgroundColor: 'rgb(217, 41, 35)',
                        borderColor: 'rgb(217, 41, 35)',
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginTop: 5,
                    }}
                    onClick={() => {
                        rotateDrawingFile();
                    }}
                >
                    <RetweetOutlined /> Rotate
                </button>
                <Space
                    style={{
                        marginTop: 5,
                    }}
                    direction="vertical"
                >
                    <Switch
                        checked={useNativeViwer}
                        onChange={checked => setUseNativeViwer(checked)}
                        checkedChildren="Native"
                        unCheckedChildren="APP"
                    />
                </Space>
            </div>

            <div
                style={{
                    height: '100vh',
                }}
            >
                {enabled_grid && (
                    <div
                        style={{
                            position: 'absolute',
                            backgroundImage: `url("https://quotes.parts-badger.com/images/grid.png")`,
                            backgroundRepeat: 'repeat',
                            backgroundSize: '800px 800px',
                            right: 20,
                            top: 60,
                            width: '90vw',
                            zIndex: 1,
                            height: '80%', //Cant be 100% of height because this will blocks the scroll of the pdf viewer
                        }}
                    ></div>
                )}
                {useNativeViwer && <iframe src={`${fileUrl}#view=fitH`} title="testPdf" height="100%" width="100%" />}
                {!useNativeViwer && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.2.146/build/pdf.worker.min.js">
                        <Viewer theme="dark" fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]}></Viewer>
                    </Worker>
                )}
            </div>
        </>
    );
};
