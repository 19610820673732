var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Alert, Badge, Button, Card, Checkbox, Divider, Drawer, Form, message, Modal, notification, Popconfirm, Popover, Progress, Spin, Table, Tag, Tooltip, Typography, } from 'antd';
import { get, patch, post } from '@partsbadger/utils';
import DynamicQuantities from '../LineItems/DynamicQuantities';
import LineItems from '../LineItems/LineItems';
import SortDrag from '../LineItems/SortDrag';
import { ErrorRender, UploadFile } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { DrawingFiles, InternalFiles, OtherFiles, PartDetails, StepFile, ThumbnailFile, VendorQuote } from './index';
import ThumbnailFileMcMaster from './ThumbnailFileMcMaster';
import { LineItemInfo } from '../LineItems/LineItemInfo';
import DynamicQuantitiesSwitcher from '../DynamicQuantitiesSwitcher';
import _ from 'lodash';
import { HistoryOutlined, LoadingOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { BatchReleasesSwitcher } from '../index';
import { AddLeadTime } from '../AddLeadTime';
import RecuoteChanges from './RecuoteChanges';
import { ModalERPNotes } from '../ModalERPNotes';
var Paragraph = Typography.Paragraph;
var MasterProductDetail = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var master_product = props.master_product;
    var _r = useState(false), editing = _r[0], setEditing = _r[1];
    var _s = useState(false), loading = _s[0], setLoading = _s[1];
    var _t = useState(false), loading2D = _t[0], setLoading2D = _t[1];
    var _u = useState(false), isSwitchingDynamicQuantities = _u[0], setIsSwitchingDynamicQuantities = _u[1];
    var _v = useState(false), showAddStepFile = _v[0], setShowAddStepFile = _v[1];
    var _w = useState(false), showAddDrawingFile = _w[0], setShowAddDrawingFile = _w[1];
    var _x = useState(false), showAddOtherFile = _x[0], setShowAddOtherFile = _x[1];
    var _y = useState(), status_analyzer = _y[0], setStatusAnalyzer = _y[1];
    var _z = useState(), error = _z[0], setError = _z[1];
    var _0 = useState(false), already_quote = _0[0], setAlreadyQuote = _0[1];
    var _1 = useState(), item_already = _1[0], setItemAlready = _1[1];
    var _2 = useState(''), deviationNote = _2[0], setDeviationNote = _2[1];
    var _3 = useState(false), editNotes = _3[0], setEditNotes = _3[1];
    var _4 = useState(false), adjustmentLeadTime = _4[0], setAdjustmentLeadTime = _4[1];
    var _5 = useState(false), sortModal = _5[0], setSortModal = _5[1];
    var _6 = useState(false), viewRecuoteChanges = _6[0], setViewRecuoteChanges = _6[1];
    var _7 = useState(false), showModalERPNote = _7[0], setShowModalERPNote = _7[1];
    var _8 = useState(), dataERPNote = _8[0], setDataERPNote = _8[1];
    var form = Form.useForm()[0];
    var pollingInterval = null;
    var quote_id = ((_a = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id) || 0;
    var quote = QuoteStore.quote;
    var isAnEditableQuote = QuoteStore.inProgress;
    /**
     * Enable polling if the part is being analyzed
     */
    useEffect(function () {
        if (!master_product.analysis_status.file_3d.completed) {
            setLoading(true);
            startPolling(master_product.id);
        }
        else {
            setLoading(false);
        }
        return function () {
            clearInterval(pollingInterval);
        };
    }, [master_product.analysis_status.file_3d.completed]);
    var startPolling = function (id) {
        setLoading(true);
        var url = "/staff/master-products/".concat(id, "/status/");
        pollingInterval = setInterval(function () {
            get(url)
                .then(function (data) {
                var _a;
                if ((_a = data.file_3d.message) === null || _a === void 0 ? void 0 : _a.msg) {
                    setStatusAnalyzer(data.file_3d.message);
                }
                if (data.file_3d.completed) {
                    loadMasterProduct(id);
                    clearInterval(pollingInterval);
                    setLoading(false);
                }
            })
                .catch(function () {
                setLoading(false);
                clearInterval(pollingInterval);
            });
        }, 5000);
    };
    var loadMasterProduct = function (id) {
        get("/staff/master-products/".concat(id, "/"))
            .then(function (response) {
            setLoading(!response.analysis_status.file_3d.completed);
            QuoteStore.getMasterProducts(quote_id).finally(function () {
                if (props.item) {
                    props.item(QuoteStore.masterProductsByPosition);
                }
            });
            if (!response.analysis_status.file_3d.completed) {
                startPolling(id);
            }
        })
            .catch(function (error) {
            setLoading(false);
            setError(error);
        });
    };
    var reanalyzeMasterProduct = function (id) {
        post('/staff/master-products/' + id + '/analyze/')
            .then(function () {
            startPolling(id);
        })
            .catch(function () { return message.error('Error'); });
    };
    var toggleEditable = function () {
        setEditing(!editing);
    };
    var renderFile = function () {
        var url = "/3d-viewer/".concat(master_product.id, "/");
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    var updateStepFile = function (file_id) {
        post('/staff/master-products/' + master_product.id + '/upload-3d-file/', {
            step_file: file_id,
        })
            .then(function () {
            if (file_id) {
                startPolling(master_product.id);
            }
            else {
                loadMasterProduct(master_product.id);
            }
            setShowAddStepFile(false);
        })
            .catch(function () { return message.error('Error, try again'); });
    };
    var updateDrawingFile = function (file_id) {
        post('/staff/master-products/' + master_product.id + '/upload-2d-file/', {
            drawing_file: file_id,
        })
            .then(function () {
            loadMasterProduct(master_product.id);
            setShowAddDrawingFile(false);
        })
            .catch(function () {
            message.error('Error, try again');
        });
    };
    var onChangeDynamicQuantities = function (enabled, items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSwitchingDynamicQuantities(true);
                    return [4 /*yield*/, patch("/staff/quotes/".concat(quote_id, "/update-multiple/"), {
                            quoted_products: items,
                            data: { has_dynamic_quantities: enabled },
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.recalculatePrices({ quoted_products: items })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.getLineItems(quote_id)];
                case 3:
                    _a.sent();
                    setIsSwitchingDynamicQuantities(false);
                    QuoteStore.getQuote(quote_id);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeBatchReleases = function (enabled, items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, patch("/staff/quotes/".concat(quote_id, "/update-multiple/"), {
                        quoted_products: items,
                        data: { batch_shipment: enabled },
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.getLineItems(quote_id)];
                case 2:
                    _a.sent();
                    QuoteStore.getQuote(quote_id);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadProductAlreadyQuote = function (row_id, already_quote_product) {
        setLoading(true);
        setAlreadyQuote(true);
        get("/staff/quoted-products/".concat(already_quote_product))
            .then(function (response) {
            var product = response;
            get("/staff/master-products/".concat(product.product.master_product)).then(function (master_product) {
                var data = {
                    product: product,
                    master_product: master_product,
                    mp_replace_id: row_id,
                };
                setItemAlready(data);
            });
        })
            .finally(function () { return setLoading(false); });
    };
    var updateTighterTolerance = function (e) {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { tighter_tolerances: e.target.checked })
            .then(function (response) {
            loadMasterProduct(response.id);
            setLoading(false);
        })
            .catch(function (error) {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    var updateDeviation = function (e) {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { deviation: e.target.checked })
            .then(function (response) {
            loadMasterProduct(response.id);
            setLoading(false);
        })
            .catch(function (error) {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    var handleUpdateDeviationNote = _.debounce(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setDeviationNote(value);
            setLoading(true);
            QuoteStore.updateMasterProduct(master_product.id, { deviation_note: value })
                .then(function (response) {
                loadMasterProduct(response.id);
                setLoading(false);
            })
                .catch(function (error) {
                notification.error({ message: React.createElement(ErrorRender, { error: error }) });
            });
            return [2 /*return*/];
        });
    }); }, 500);
    var update3dFileCreation = function (e) {
        setLoading(true);
        QuoteStore.updateMasterProduct(master_product.id, { creation_file_3d: e.target.checked })
            .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadMasterProduct(response.id);
                        setLoading(false);
                        return [4 /*yield*/, QuoteStore.getQuote(quote_id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function (error) {
            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
        });
    };
    if (!(master_product === null || master_product === void 0 ? void 0 : master_product.id)) {
        return null;
    }
    var line_items_by_master_product = QuoteStore.lineItems.filter(function (entity) { return entity.product.master_product === master_product.id; });
    var line_items_dynamic_quote = line_items_by_master_product.filter(function (item) { return item.has_dynamic_quantities; });
    var line_items_no_dynamic_quote = line_items_by_master_product.filter(function (item) { return !item.has_dynamic_quantities; });
    var initialQuantities = [];
    if (line_items_by_master_product.length > 0) {
        if (line_items_by_master_product[0].quantities !== null &&
            line_items_by_master_product[0].quantities !== undefined) {
            initialQuantities =
                line_items_by_master_product[0].quantities.length > 0
                    ? line_items_by_master_product[0].quantities
                    : [line_items_by_master_product[0].quantity];
        }
        else {
            initialQuantities = [line_items_by_master_product[0].quantity];
        }
    }
    var handleAddERPQuotingNotes = function (quote_line_item_id, notes) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setDataERPNote({
                id: quote_line_item_id,
                partsbadger_production_quoting_notes: notes,
            });
            setShowModalERPNote(true);
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: "master-product-".concat(master_product.id), key: "master-product-".concat(master_product.id) },
        React.createElement(ErrorRender, { error: error }),
        React.createElement(Card, { className: "overflow-auto m-0", style: { padding: '10px', cursor: 'pointer' } },
            React.createElement(Spin, { spinning: loading, tip: (React.createElement(Tooltip, { title: status_analyzer === null || status_analyzer === void 0 ? void 0 : status_analyzer.msg },
                    React.createElement(Progress, { style: { maxWidth: 200, margin: 'auto' }, percent: (status_analyzer === null || status_analyzer === void 0 ? void 0 : status_analyzer.current) || 0 }))) },
                ((_b = master_product === null || master_product === void 0 ? void 0 : master_product.quickturn_summary) === null || _b === void 0 ? void 0 : _b.is_quickturn_part) ? (React.createElement(Alert, { type: "success", showIcon: true, message: React.createElement("span", { style: { color: 'darkgreen', fontWeight: 'bold' } }, "QuickTurn Alert"), style: { marginBottom: '1rem' }, description: React.createElement(React.Fragment, null,
                        React.createElement("span", { style: { color: 'darkgreen' } },
                            "This product was automatically flagged as a potential QuickTurn part.",
                            ' ',
                            React.createElement("a", { href: "https://portal.parts-badger.com/quick-turn/design", rel: "noreferrer", target: "_blank", style: {
                                    color: 'darkgreen',
                                    textDecoration: 'underline',
                                    fontWeight: 500,
                                } }, "Check the QuickTurn Portal"))) })) : null,
                React.createElement("div", { className: "flex flex-col xl:flex-row justify-evenly" },
                    !editing && (React.createElement("div", { className: 'flex flex-row justify-start items-center mr-5' },
                        React.createElement("span", { className: "mr-4" },
                            "#",
                            props.count + 1),
                        React.createElement("div", null,
                            React.createElement(ThumbnailFile, { master_product: master_product, handleClick: renderFile })))),
                    React.createElement("div", { className: 'flex flex-row items-center flex-wrap m-0' },
                        React.createElement(PartDetails, { row: master_product, editable: setEditing, editNotes: editNotes, closedNotes: setEditNotes, handleChange: function (id) {
                                QuoteStore.getQuote(quote_id);
                                loadMasterProduct(id);
                                toggleEditable();
                            } }),
                        React.createElement("div", { className: "lg:w-full xl:w-auto" },
                            React.createElement(StepFile, { master_product: master_product, renderFile: renderFile, removeFile: function () {
                                    updateStepFile(null);
                                }, showAddStepFile: function () {
                                    setShowAddStepFile(true);
                                } })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-drawing-files" },
                            React.createElement(DrawingFiles, { master_product: master_product, removeFile: function () {
                                    updateDrawingFile(null);
                                }, showAddDrawingFile: function () {
                                    setShowAddDrawingFile(true);
                                } }),
                            loading2D && (React.createElement(React.Fragment, null,
                                React.createElement("br", null),
                                React.createElement("div", { className: 'text-center' },
                                    React.createElement("span", { className: 'text-red-600' },
                                        ' ',
                                        React.createElement(LoadingOutlined, null),
                                        "\u2003 Analyzing..."))))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: 'col-other-files' },
                            React.createElement(OtherFiles, { master_product: master_product, showAddOtherFile: function () {
                                    setShowAddOtherFile(true);
                                }, quote_id: quote_id })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(InternalFiles, { master_product: master_product, quote_id: quote_id })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !((_c = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _c === void 0 ? void 0 : _c.editable), onChange: updateTighterTolerance, defaultChecked: master_product.tighter_tolerances },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Tighter Tolerance"))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !((_d = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _d === void 0 ? void 0 : _d.editable), onChange: updateDeviation, defaultChecked: master_product.deviation },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Deviation"))),
                        master_product.deviation && (React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "Note Deviation: "),
                                master_product.editable ? (React.createElement(Paragraph, { style: { width: '100%' }, disabled: !master_product.editable || !((_e = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _e === void 0 ? void 0 : _e.editable), editable: {
                                        onChange: handleUpdateDeviationNote,
                                    } }, master_product.deviation_note)) : (React.createElement("div", null, master_product.deviation_note))))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Checkbox, { disabled: !master_product.editable || !((_f = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _f === void 0 ? void 0 : _f.editable), onChange: update3dFileCreation, defaultChecked: master_product.creation_file_3d },
                                React.createElement("span", { style: { fontWeight: 'bold' } }, "3D File Creation"))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !master_product.editable || !((_g = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _g === void 0 ? void 0 : _g.editable), title: 'Adjust lead time', className: 'analyze link', onClick: function () {
                                    setAdjustmentLeadTime(true);
                                } }, "Adjust lead time")),
                        React.createElement(Divider, { type: 'vertical' }),
                        master_product.itar && (React.createElement("div", { className: 'col-itar' },
                            React.createElement(Badge, { count: 'ITAR', style: { backgroundColor: '#4b5320' } }),
                            React.createElement(Divider, { type: 'vertical' }))),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !master_product.editable || !((_h = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _h === void 0 ? void 0 : _h.editable), title: 'Analyze again', className: 'analyze link', onClick: function () {
                                    setLoading(true);
                                    reanalyzeMasterProduct(master_product.id);
                                } },
                                "Analyze again ",
                                React.createElement(LegacyIcon, { type: 'sync' }))),
                        React.createElement(Divider, { type: 'vertical' }),
                        ((_j = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _j === void 0 ? void 0 : _j.editable) && master_product.already_quote_product && (React.createElement("div", null,
                            React.createElement(Tag, { style: { cursor: 'pointer' }, color: "magenta", onClick: function () {
                                    return loadProductAlreadyQuote(master_product.id, master_product.already_quote_product);
                                } }, "Product already quoted"),
                            React.createElement(Divider, { type: 'vertical' }))),
                        (master_product === null || master_product === void 0 ? void 0 : master_product.previously_ordered) && (React.createElement("div", null,
                            React.createElement(Tag, { style: { cursor: 'default' }, color: "green" }, "Previously ordered"),
                            React.createElement(Divider, { type: 'vertical' }))),
                        master_product.is_new && (React.createElement("div", null,
                            React.createElement(Tag, { color: "orange", style: { cursor: 'default' } }, "New item"))),
                        master_product.vendor_quote_information && (React.createElement("div", null,
                            React.createElement(Popover, { title: "Vendor Quote", content: React.createElement(VendorQuote, { quotes: master_product.vendor_quote_information }) },
                                React.createElement(Tag, { color: 'blue' },
                                    "Quoted by a Vendor: ",
                                    master_product.vendor_name)),
                            React.createElement(Divider, { type: 'vertical' }))),
                        master_product.mcmaster_product ? (React.createElement("div", { className: 'flex flex-row items-center' },
                            React.createElement(ThumbnailFileMcMaster, { mcmaster_product: master_product.mcmaster_product }),
                            React.createElement(Divider, { type: 'vertical' }))) : (React.createElement("div", null)),
                        React.createElement("div", { className: 'delete' },
                            React.createElement(Popconfirm, { disabled: !((_k = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _k === void 0 ? void 0 : _k.editable), okType: 'danger', className: 'link', title: "Do you want to delete this product?", onConfirm: function () {
                                    QuoteStore.deleteMasterProduct(master_product.id);
                                } },
                                React.createElement(Button, { type: "link", disabled: !((_l = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _l === void 0 ? void 0 : _l.editable), title: 'Delete  Master Product from this quote', className: 'link' },
                                    "Delete ",
                                    React.createElement(LegacyIcon, { type: 'delete' })))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !((_m = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _m === void 0 ? void 0 : _m.editable), title: 'Sort', className: 'analyze link', onClick: function () {
                                    setSortModal(true);
                                } },
                                "Sort ",
                                React.createElement(SortAscendingOutlined, { className: "" }))),
                        QuoteStore.similarProducts &&
                            QuoteStore.similarProducts[master_product.id.toString()] &&
                            QuoteStore.similarProducts[master_product.id.toString()].length > 0 && (React.createElement(React.Fragment, null,
                            React.createElement(Divider, { type: 'vertical' }),
                            React.createElement(Popover, { title: "Similar Parts", content: React.createElement(React.Fragment, null,
                                    React.createElement(Table, { columns: [
                                            {
                                                dataIndex: 'product__master_product__name',
                                                title: 'Product',
                                            },
                                            {
                                                dataIndex: 'quantity',
                                                title: 'QTY',
                                            },
                                            {
                                                dataIndex: 'manual_adjustment',
                                                title: 'DE Adj',
                                            },
                                            {
                                                dataIndex: 'formula',
                                                title: 'Formula',
                                            },
                                            {
                                                dataIndex: 'product__material__name',
                                                title: 'Material',
                                            },
                                            {
                                                dataIndex: 'coating',
                                                title: 'Coating',
                                            },
                                            {
                                                dataIndex: 'finish',
                                                title: 'Finish',
                                            },
                                            {
                                                dataIndex: 'unit_price',
                                                title: 'Unit Price',
                                            },
                                        ], key: "pk", dataSource: QuoteStore.similarProducts[master_product.id.toString()] })) },
                                React.createElement(Tag, { color: 'blue' }, "Similar Parts")))),
                        !master_product.editable && (quote === null || quote === void 0 ? void 0 : quote.editable) && (React.createElement("div", { className: "col-duplicate" },
                            React.createElement("div", { title: 'This master product is related to other quotes, please duplicate to make changes', className: 'link', onClick: function () {
                                    setLoading(false);
                                    QuoteStore.duplicateMasterProduct(master_product.id);
                                } },
                                "Duplicate ",
                                React.createElement(LegacyIcon, { type: 'copy' })))),
                        master_product.changed_fields && (React.createElement("div", { className: "col-reanalyze" },
                            React.createElement(Button, { type: 'link', disabled: !((_o = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _o === void 0 ? void 0 : _o.editable), title: 'View Requote Changes', className: 'analyze link', onClick: function () {
                                    setViewRecuoteChanges(true);
                                } },
                                "Requote Changes ",
                                React.createElement(HistoryOutlined, null))))))),
            loading && (React.createElement("div", { className: 'link', style: { textAlign: 'center' }, onClick: function () {
                    setLoading(false);
                    var url = "/staff/master-products/".concat(master_product.id, "/cancel-analyzer/");
                    post(url);
                } }, "Cancel"))),
        React.createElement("div", { className: 'quote-master-product-variations' }, master_product.quotable ? (React.createElement(React.Fragment, null,
            line_items_dynamic_quote.length > 0 && (React.createElement(Spin, { spinning: loading },
                React.createElement("div", { className: 'flex flex-row' },
                    (quote === null || quote === void 0 ? void 0 : quote.editable) && (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                            React.createElement(DynamicQuantitiesSwitcher, { initialValue: true, handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                                    var items;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                items = line_items_dynamic_quote.map(function (item) { return item.id; });
                                                return [4 /*yield*/, onChangeDynamicQuantities(enabled, items)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })),
                        React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                            React.createElement(BatchReleasesSwitcher, { initialValue: line_items_dynamic_quote[0].batch_shipment, handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                                    var items;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                items = line_items_dynamic_quote.map(function (item) { return item.id; });
                                                return [4 /*yield*/, onChangeBatchReleases(enabled, items)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })))),
                    (quote === null || quote === void 0 ? void 0 : quote.rfq) !== null && (React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                        React.createElement("b", null, "Customer Notes: "),
                        " ",
                        master_product.customer_notes))),
                line_items_dynamic_quote.map(function (line_item) {
                    return (React.createElement(DynamicQuantities, { key: line_item.id, editable: isAnEditableQuote, item: line_item, partVol: master_product.part_volume_inches, totalVol: master_product.total_volume_inches }));
                }))),
            line_items_no_dynamic_quote.length > 0 && (React.createElement("div", { className: 'line-items-standard' },
                React.createElement("div", { className: 'flex flex-row' },
                    (quote === null || quote === void 0 ? void 0 : quote.editable) && (React.createElement("div", { className: 'flex flex-row' },
                        React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                            React.createElement(DynamicQuantitiesSwitcher, { initialValue: false, handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                                    var items, e_1;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                items = line_items_no_dynamic_quote.map(function (item) { return item.id; });
                                                return [4 /*yield*/, onChangeDynamicQuantities(enabled, items)];
                                            case 1:
                                                _a.sent();
                                                if (!(initialQuantities.length > 0 &&
                                                    line_items_by_master_product[0].quote_item_quantities
                                                        .length == 0)) return [3 /*break*/, 5];
                                                _a.label = 2;
                                            case 2:
                                                _a.trys.push([2, 4, , 5]);
                                                return [4 /*yield*/, QuoteStore.updateRequiredQuantities(line_items_by_master_product[0].id, {
                                                        quantities: initialQuantities,
                                                    })];
                                            case 3:
                                                _a.sent();
                                                return [3 /*break*/, 5];
                                            case 4:
                                                e_1 = _a.sent();
                                                notification.error({
                                                    key: 'form-',
                                                    duration: 8,
                                                    description: 'One of the line items has an error',
                                                    message: 'Error',
                                                    placement: 'top',
                                                    maxCount: 1,
                                                });
                                                return [3 /*break*/, 5];
                                            case 5: return [2 /*return*/];
                                        }
                                    });
                                }); } })),
                        React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                            React.createElement(BatchReleasesSwitcher, { initialValue: line_items_no_dynamic_quote[0].batch_shipment, handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                                    var items;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                items = line_items_no_dynamic_quote.map(function (item) { return item.id; });
                                                return [4 /*yield*/, onChangeBatchReleases(enabled, items)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })))),
                    master_product.customer_notes && (React.createElement("div", { className: "col-dynamic-quantities my-1 ml-4" },
                        React.createElement("b", null, "Customer Notes: "),
                        " ",
                        master_product.customer_notes))),
                React.createElement(LineItems, __assign({ editable: true, handleRowSelections: props.setSelectedLineItems, selectedQuotedProducts: props.selectedLineItems, master_product_id: master_product.id, line_items: line_items_no_dynamic_quote, addPartsbadgerProductionQuotingNotes: handleAddERPQuotingNotes }, props)))))) : (React.createElement("div", { style: { margin: 'auto', maxWidth: 400, padding: 20 } }, master_product.editable ? (React.createElement(Tooltip, { title: 'Click to edit.' },
            React.createElement("div", { onClick: function () {
                    setEditNotes(!editNotes);
                }, style: { cursor: 'pointer' } },
                React.createElement(Alert, { style: { padding: 20 }, message: 'No Quotable', description: React.createElement("div", null,
                        "Notes: ",
                        master_product.notes,
                        " ",
                        React.createElement("br", null),
                        "Category: ", (_p = master_product.no_quote_category) !== null && _p !== void 0 ? _p : '--'), type: "info", className: "mb-3" })))) : (React.createElement(Alert, { style: { padding: 20 }, message: 'No Quotable', description: React.createElement("div", null,
                "Notes: ",
                master_product.notes,
                " ",
                React.createElement("br", null),
                "Category: ", (_q = master_product.no_quote_category) !== null && _q !== void 0 ? _q : '--'), type: "info", className: "mb-3" }))))),
        React.createElement(Modal, { visible: showAddStepFile, centered: true, title: "Add Step File", onCancel: function () { return setShowAddStepFile(false); }, footer: [] },
            React.createElement(UploadFile, { fileType: '3D', onUpload: function (file) {
                    updateStepFile(file.response.id);
                } })),
        React.createElement(Modal, { visible: showAddDrawingFile, centered: true, title: "Add Drawing File", onCancel: function () { return setShowAddDrawingFile(false); }, footer: [] },
            React.createElement(UploadFile, { fileType: '2D', onUpload: function (file) {
                    updateDrawingFile(file.response.id);
                } })),
        React.createElement(Modal, { visible: showAddOtherFile, centered: true, title: "Other Files", onCancel: function () { return setShowAddOtherFile(false); }, footer: [] },
            React.createElement(UploadFile, { onUpload: function (file) {
                    var current_files = master_product === null || master_product === void 0 ? void 0 : master_product.other_files.map(function (r) { return r.id; });
                    post('/staff/master-products/' + master_product.id + '/upload-other-file/', {
                        other_files: __spreadArray(__spreadArray([], current_files, true), [file.response.id], false),
                    })
                        .then(function (response) {
                        setShowAddDrawingFile(false);
                        QuoteStore.getMasterProducts(quote_id);
                    })
                        .catch(function () { return message.error('Error, try again'); });
                } })),
        React.createElement(Modal, { visible: adjustmentLeadTime, centered: true, title: "", onCancel: function () { return setAdjustmentLeadTime(false); }, footer: false, style: { maxWidth: '700px', borderRadius: '10px' }, width: 700 },
            React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
                React.createElement("div", null,
                    React.createElement("div", { className: 'flex justify-center font-bold text-lg' },
                        "Add Lead Time Adjustment for ",
                        master_product.name),
                    React.createElement(AddLeadTime, { setAddLeadTime: setAdjustmentLeadTime, master_product: master_product.id })))),
        React.createElement(Modal, { visible: viewRecuoteChanges, centered: true, title: "", onCancel: function () { return setViewRecuoteChanges(false); }, footer: false, style: { maxWidth: '700px', borderRadius: '10px' }, width: 700 },
            React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
                React.createElement("div", null,
                    React.createElement(RecuoteChanges, { master_product: master_product })))),
        React.createElement(SortDrag, { quoteId: quote_id, open: sortModal, onCancel: function () {
                setSortModal(false);
            }, masterProductId: master_product.id, lineItems: line_items_by_master_product }),
        React.createElement(Drawer, { title: "Product already quoted", width: "80%", closable: true, visible: already_quote, placement: 'left', onClose: function () {
                QuoteStore.getLineItems(quote_id);
                setAlreadyQuote(false);
            } },
            React.createElement(Spin, { spinning: loading },
                React.createElement(LineItemInfo, { line_item: item_already }))),
        dataERPNote && (React.createElement(ModalERPNotes, { quote_id: quote_id, showModalERPNote: showModalERPNote, quote_line_item_id: dataERPNote.id, notes: dataERPNote.partsbadger_production_quoting_notes, setShowModalERPNote: setShowModalERPNote, setDataERPNote: setDataERPNote }))));
};
export default observer(MasterProductDetail);
