import React, { useEffect, useRef, useState } from 'react';
import { InputTextAntd } from './styles';

interface IProductData {
    PART: string;
    QTY: number;
    REQUIRED_SHIP_DATE: string;
    MATERIAL_CERT_REQUIRED: boolean;
    MATERIAL: string;
    UNS: string;
    FINISH: string;
    COATING: string;
    DWG_UNITS: string;
    TOLERANCE: 'UNLESS OTHERWISE STATED' | 'None';
}

interface ITolDataProps {
    tolXS: { show: boolean; tol: number | string };
    tolX: { show: boolean; tol: number | string };
    tolXX: { show: boolean; tol: number | string };
    tolXXX: { show: boolean; tol: number | string };
    tolXXXX: { show: boolean; tol: number | string };
}

interface ITolDataISOProps {
    tol06: { show: boolean; tol: number | string };
    tol630: { show: boolean; tol: number | string };
    tol30120: { show: boolean; tol: number | string };
    tol120400: { show: boolean; tol: number | string };
    tol4001000: { show: boolean; tol: number | string };
    tol10002000: { show: boolean; tol: number | string };
}

interface ProductTemplateProps {
    order: string;
    product_data: IProductData;
    tolerances_data: ITolDataProps | null;
    tolerances_data_ISO: ITolDataISOProps | null;
    unit: string;
    toleranceType: string;
    textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

const ProductTemplate: React.FC<ProductTemplateProps> = ({
    order,
    product_data,
    tolerances_data,
    tolerances_data_ISO,
    unit,
    toleranceType,
    textAreaRef,
}) => {
    const [textArea, setTextArea] = useState<string>();

    const rsdToDDMMYY = (rsd: string) => {
        const rsdSplit = rsd.split('-');
        return `${rsdSplit[2]}/${rsdSplit[1]}/${rsdSplit[0]}`;
    };

    useEffect(() => {
        setTextArea(rendered_str);
    }, [order, product_data, tolerances_data, tolerances_data_ISO, unit, toleranceType]);

    let rendered_str = `ORDER: ${order}
PART: ${product_data.PART} 
QTY: ${product_data.QTY}
MATERIAL: ${product_data.MATERIAL} ${product_data.UNS ? `(UNS ${product_data.UNS})` : ''}`;
    // Add Material Certicate
    if (product_data.MATERIAL_CERT_REQUIRED) {
        rendered_str = `${rendered_str}
MATERIAL CERT REQUIRED`;
    }

    rendered_str = `${rendered_str}
FINISH: ${product_data.FINISH} UNLESS OTHERWISE STATED`;

    if (product_data.COATING != 'N/A' && product_data.COATING != 'None' && product_data.COATING != 'NA') {
        if (product_data.COATING.includes('Heat Treat')) {
            rendered_str = `${rendered_str}
HEAT TREAT: ${''}`;
        }
        product_data.COATING = product_data.COATING.replace(' & Heat Treat & ', ' & ');
        product_data.COATING = product_data.COATING.replace('Heat Treat & ', '');
        product_data.COATING = product_data.COATING.replace(' & Heat Treat', '');
        product_data.COATING = product_data.COATING.replace('Heat Treat', '');
    }

    if (
        product_data.COATING != 'N/A' &&
        product_data.COATING != 'None' &&
        product_data.COATING != 'NA' &&
        product_data.COATING != ''
    ) {
        rendered_str = `${rendered_str}
COATING: ${product_data.COATING}`;
    }

    rendered_str = `${rendered_str}
DWG UNITS: ${unit === 'in' ? 'INCHES' : 'mm'}`;

    if (toleranceType === 'STANDARD' && tolerances_data) {
        `TOLERANCE: UNLESS OTHERWISE STATED`;
        if (tolerances_data.tolX.show) {
            rendered_str = `${rendered_str}
X.X ± ${tolerances_data.tolX.tol}`;
        }
        if (tolerances_data.tolXX.show) {
            rendered_str = `${rendered_str}
X.XX ± ${tolerances_data.tolXX.tol}`;
        }

        if (tolerances_data.tolXXX.show) {
            rendered_str = `${rendered_str}
X.XXX ± ${tolerances_data.tolXXX.tol}`;
        }
        if (tolerances_data.tolXXXX.show) {
            rendered_str = `${rendered_str}
X.XXXX ± ${tolerances_data.tolXXXX.tol}`;
        }
    } else if (toleranceType === 'SINGULAR' && tolerances_data) {
        rendered_str = `${rendered_str}
TOLERANCE: +/- ${tolerances_data.tolXS.tol} UNLESS OTHERWISE STATED`;
    } else if (toleranceType === 'ISO' && tolerances_data_ISO) {
        if (tolerances_data_ISO.tol06.show) {
            rendered_str = `${rendered_str}
0-6: ± ${tolerances_data_ISO.tol06.tol}`;
        }
        if (tolerances_data_ISO.tol630.show) {
            rendered_str = `${rendered_str}
6-30: ± ${tolerances_data_ISO.tol630.tol}`;
        }
        if (tolerances_data_ISO.tol30120.show) {
            rendered_str = `${rendered_str}
30-120: ± ${tolerances_data_ISO.tol30120.tol}`;
        }
        if (tolerances_data_ISO.tol120400.show) {
            rendered_str = `${rendered_str}
120-400: ± ${tolerances_data_ISO.tol120400.tol}`;
        }
        if (tolerances_data_ISO.tol4001000.show) {
            rendered_str = `${rendered_str}
400-1000: ± ${tolerances_data_ISO.tol4001000.tol}`;
        }
        if (tolerances_data_ISO.tol10002000.show) {
            rendered_str = `${rendered_str}
1000-2000: ± ${tolerances_data_ISO.tol10002000.tol}`;
        }
    }

    rendered_str = `${rendered_str}
REQUIRED SHIP DATE ${rsdToDDMMYY(product_data.REQUIRED_SHIP_DATE)}`;

    return (
        <InputTextAntd
            ref={textAreaRef}
            autoSize={{ minRows: 5, maxRows: 10 }}
            value={textArea}
            onChange={e => {
                setTextArea(e.target.value);
            }}
        />
    );
};

export default ProductTemplate;
