import React, { useState } from 'react';
import { Button } from 'antd';
var ButtonApplyToAll = function (_a) {
    var handleClick = _a.handleClick, _b = _a.className, className = _b === void 0 ? '' : _b;
    var _c = useState(false), isUpdatingLineItem = _c[0], setIsUpdatingLineItem = _c[1];
    return (React.createElement(Button, { loading: isUpdatingLineItem, type: 'link', className: className, onClick: function () {
            setIsUpdatingLineItem(true);
            handleClick().finally(function () {
                setIsUpdatingLineItem(false);
            });
        } }, "Apply to All"));
};
export default ButtonApplyToAll;
