var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import QuoteStore from '../../stores/QuoteStore';
var busy = null;
export var Users = observer(function (props) {
    var searchOwners = function (search) {
        if (search === void 0) { search = ''; }
        QuoteStore.getUsers({
            search: search,
            is_staff: true,
        });
    };
    useEffect(function () {
        searchOwners('');
    }, []);
    var users = QuoteStore.users;
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, placeholder: "Search user by name or email", filterOption: false, optionKeyProp: 'value', dropdownMatchSelectWidth: false, style: { width: '100%' }, onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            busy = setTimeout(function () { return searchOwners(name); }, 500);
        } }, props), users.map(function (d) { return (React.createElement(Select.Option, { key: d.id }, d.fullname)); })));
});
