import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
var Option = Select.Option;
var DomesticHardwareModal = function (props) {
    var _a = useState([]), selectedItems = _a[0], setSelectedItems = _a[1];
    var _b = useState([]), mytags = _b[0], setMyTags = _b[1];
    useEffect(function () {
        setMyTags(props.tags);
    }, [props.tags]);
    return (React.createElement(Modal, { title: props.type === 'domestic' ? 'Domestic' : 'Hardware', visible: props.isVisible, onOk: function () {
            props.handleOk(mytags);
        }, onCancel: function () {
            props.handleCancel();
        } },
        React.createElement(Select, { mode: "tags", style: { width: '100%' }, placeholder: "Tags Mode", onChange: function (value) {
                setMyTags(value);
            }, value: mytags }, mytags.map(function (item) {
            React.createElement(Option, { key: item }, item);
        }))));
};
export default DomesticHardwareModal;
