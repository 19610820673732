import React, {useState} from "react";
import {post, put} from "../../../shared/api";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, notification, Radio, Row } from "antd";
import {Countries, States} from "@partsbadger/library";


const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};


const AddressFormComponent = (props) => {


    const [loading, setLoading] = useState(false)

    const {form} = props;
    const {getFieldDecorator, getFieldValue} = form;


    const handleSubmit = (e) => {

        form.validateFields((err, values) => {
            if (!err) {

                if (!err) {

                    const payload = {
                        name: values.name,
                        type: values.type,
                        street: values.street,
                        city: values.city,
                        state: values.state?.key,
                        country: values.country?.key,
                        zip_code: values.zip_code,
                        primary: values.primary,
                    };


                    setLoading(true);

                    if (props.address?.id) {
                        put(`staff/contacts/${props.contact}/addresses/${props.address.id}/`, payload).then(() => {

                            setLoading(false);
                            form.resetFields();
                            props.callback();
                            notification.success({message: "Contact Address was updated correctly!"})

                        }).catch((error) => {
                            notification.error({message: "Error,try again"})
                            setLoading(false);
                        });

                    } else {
                        post(`staff/contacts/${props.contact}/addresses/`, payload).then(() => {

                            setLoading(false);
                            form.resetFields();
                            props.callback();

                            notification.success({message: "Contact Address was added correctly!"})

                        }).catch((error) => {
                            notification.error({message: "Error,try again"})
                            setLoading(false);
                        });
                    }
                }
            }
        });
    };


    return (
        <Form layout="horizontal">

            <Row>
                <Col md={12}>
                    <Form.Item label={"Name"}   {...formItemLayout}>
                        {getFieldDecorator('name', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.name
                        })(
                            <Input size={"small"} />
                        )}
                    </Form.Item>
                </Col>

                <Col md={12}>
                    <Form.Item label={"Type"}   {...formItemLayout}>
                        {getFieldDecorator('type', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.type
                        })(
                            <Radio.Group size={"small"}>
                                <Radio value={"Billing"}>Billing</Radio>
                                <Radio value={"Shipping"}>Shipping</Radio>
                                <Radio value={"Billing and Shipping"}>Billing and Shipping</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Col>


            </Row>
            <Row>
                <Col md={12}>
                    <Form.Item label={"Street"}   {...formItemLayout} s>
                        {getFieldDecorator('street', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.street
                        })(
                            <Input size={"small"} />
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Form.Item label={"City"}   {...formItemLayout}>
                        {getFieldDecorator('city', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.city

                        })(
                            <Input size={"small"} />
                        )}
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label={"Country"}   {...formItemLayout}>
                        {getFieldDecorator('country', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.country ? {
                                key: props.address?.country.code,
                                label: props.address?.country.name,
                            } : {
                                key: "US",
                                label: "United States",
                            }

                        })(
                            <Countries />
                        )}
                    </Form.Item>
                </Col>
            </Row>


            <Row>
                <Col md={12}>
                    <Form.Item label={"State"}   {...formItemLayout}>
                        {getFieldDecorator('state', {
                            rules: [{
                                required: true,
                            }],
                            initialValue: props.address?.state ? {
                                key: props?.address.state,
                                label: props?.address.state
                            } : {}

                        })(
                            <States countryShortCode={getFieldValue('country')?.key} />
                        )}
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label={"Zip Code"}   {...formItemLayout}>
                        {getFieldDecorator('zip_code', {
                            rules: [{
                                required: true,
                                min: 5
                            }],
                            initialValue: props.address?.zip_code
                        })(
                            <Input size={"small"} style={{maxWidth: 200}} />
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Form.Item label={"Primary"}   {...formItemLayout}>
                        {getFieldDecorator('primary', {
                            rules: [{
                                required: false,
                            }],
                            valuePropName: 'checked',
                            initialValue: props.address?.primary

                        })(
                            <Checkbox size={"small"} />
                        )}
                    </Form.Item>
                </Col>
            </Row>


            <br />


            <div style={{textAlign: 'center'}}>
                <Button.Group>

                    <Button type="default"
                            loading={loading} onClick={() => props.callback()}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} type="primary"
                            loading={loading}>
                        {props.address?.id > 0 ? ("Update Address") : ("Create New Address")}
                    </Button>

                </Button.Group>
            </div>


        </Form>
    )
}


export const AddressForm = Form.create()(AddressFormComponent);

