import React from 'react';
import { Divider } from 'antd';

import { OrderShopTool } from '@partsbadger/sales_order_tool';

const OrderShop = () => {
    return (
        <div style={{ margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Welcome PartsBadger Representatives.</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>
                Use the form below to send an order to shop. This form ensures that we have all of the necessary
                information in one place to process your RFQs timely and efficiently. Thank you.
            </div>
            <OrderShopTool />
            <br />
        </div>
    );
};

export default OrderShop;
