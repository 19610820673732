import React from 'react';
import { lazy, Suspense } from 'react';

const DarkTheme = lazy(() => import('./DarkTheme'));
const LightTheme = lazy(() => import('./LightTheme'));

const DARK_MODE = 'dark-mode';

export const ThemeProvider = ({ children }: any) => {
    const darkMode = window.localStorage.getItem(DARK_MODE) == 'yes';

    return (
        <>
            <Suspense fallback={<span />}>{darkMode ? <DarkTheme /> : <LightTheme />}</Suspense>
            {children}
        </>
    );
};
