import React from 'react';
import { observer } from 'mobx-react';
import { ShippingAndReceivingApp } from '@partsbadger/shipping_receiving_v2';
import { UserStore } from 'stores/UserStore';

export const ShippingReceiving2 = observer(() => {
    return (
        <div>
            <div style={{ marginTop: 10 }}>
                <ShippingAndReceivingApp isTester={UserStore.user?.is_tester} />
            </div>
        </div>
    );
});
