import React, { useEffect, useState } from 'react';
import StorageLocationStore from '../../stores/StorageLocationStore';
import { Button, Col, Divider, Modal, notification, Row } from 'antd';
import { ErrorRender } from '@partsbadger/library';
import { StorageLocationForm, StorageLocationTable } from '../../components/StorageLocation';
import { observer } from 'mobx-react';
import { IStorageLocation } from '@partsbadger/types';

export const StorageListPage = observer(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(false);
    const [storage, setStorage] = useState<IStorageLocation | null>(null);

    async function handleListBaptisms() {
        setLoading(true);
        try {
            await StorageLocationStore.list();
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleViewStorage(id: number) {
        setLoading(true);
        try {
            await StorageLocationStore.get(id);
            setStorage(StorageLocationStore.storage);
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleListBaptisms();
    }, []);

    async function handleCreate(payload: IStorageLocation) {
        setLoading(true);
        try {
            await StorageLocationStore.create(payload);
            await StorageLocationStore.list();
            setCreate(false);
            setLoading(false);
            notification.success({ message: 'Storage created!' });
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleUpdate(payload: IStorageLocation) {
        setLoading(true);
        try {
            if (payload.id) {
                await StorageLocationStore.patch(payload, payload.id);
                notification.success({ message: 'Storage updated!' });
                setUpdate(false);
            }
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    async function handleRemove(id: number) {
        setLoading(true);
        try {
            await StorageLocationStore.remove(id);
            notification.success({ message: 'Storage eliminated!' });
            setLoading(false);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
            setLoading(false);
        }
    }

    return (
        <>
            <div className={'right'}>
                <Button
                    type={'primary'}
                    className={'ant-btn'}
                    onClick={() => {
                        setCreate(true);
                    }}
                >
                    New Storage Location
                </Button>
            </div>
            <Divider>
                <div className={'title'}> Storage Location</div>
            </Divider>
            <Row>
                <Col span={24}>
                    <StorageLocationTable
                        storages={StorageLocationStore.storages}
                        loading={loading}
                        onUpdate={async (id: number) => {
                            await handleViewStorage(id);
                            setUpdate(true);
                        }}
                        handleRemove={handleRemove}
                    />
                </Col>
            </Row>

            {create && (
                <Modal
                    title="New Storage Location"
                    open={create}
                    closable={true}
                    footer={false}
                    onOk={() => setCreate(false)}
                    onCancel={() => setCreate(false)}
                >
                    <StorageLocationForm type={'create'} handleCreate={handleCreate} />
                </Modal>
            )}

            {update && storage && (
                <Modal
                    title={`Update ${storage?.name}`}
                    open={update}
                    closable={true}
                    footer={false}
                    onOk={() => setUpdate(false)}
                    onCancel={() => setUpdate(false)}
                >
                    <StorageLocationForm type={'update'} handleUpdate={handleUpdate} storage={storage} />
                </Modal>
            )}
        </>
    );
});
