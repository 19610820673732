var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button, Modal, notification, Table } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { EditBox } from './EditBox';
import { CreateBox } from './CreateBox';
import { IntakesList, OrderPreview } from './IntakeOuttake';
import { ErrorRender } from '@partsbadger/library';
import InventoryStore from '../Stores/InventoryStore';
import Shipment from '../Form/Shipment/';
export var PreviousIntakeOuttakeHistory = observer(function (_a) {
    var dataSource = _a.dataSource, historyType = _a.historyType, handleRefresh = _a.handleRefresh, props = __rest(_a, ["dataSource", "historyType", "handleRefresh"]);
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), ButtonPacket = _c[0], setButtonPacket = _c[1];
    var _d = useState(false), ButtonPrintOut = _d[0], setButtonPrintOut = _d[1];
    var templateName = historyType === 'intakes' ? 'intake-printout.html' : 'packing-slip.html';
    // Create a shipment for a specific outtake
    var _e = useState(null), showShipmentForm = _e[0], setShowShipmentForm = _e[1];
    var columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Created',
            dataIndex: 'created_time',
            render: function (text, record) { return React.createElement("span", null, moment(record.created_time).format('MMM Do hh:mm a')); },
        },
        {
            title: '',
            render: function (record) { return (React.createElement(Button, { onClick: function () {
                    IntakeStore.receiveOrShip === 'receive'
                        ? IntakeStore.deleteIntakeSalesOrder(record.id)
                        : IntakeStore.deleteOuttakeSalesOrder(record.id);
                }, danger: true, type: "primary" }, "Delete")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { type: "primary", onClick: function () {
                    var _a;
                    IntakeStore.printSalesOrder(intake.id, historyType, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    ((_a = intake === null || intake === void 0 ? void 0 : intake.box) === null || _a === void 0 ? void 0 : _a.boxes_included) > 0 ? 'packing-slip-boxes.html' : templateName);
                } }, "Print")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { onClick: function () {
                    return IntakeStore.setActivePreview("/staff/".concat(historyType, "/").concat(intake.id, "/html/?template=").concat(templateName));
                } }, "View Printout")); },
        },
        {
            title: '',
            render: function (text, intake) { return (React.createElement(Button, { loading: ButtonPrintOut, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                setButtonPrintOut(true);
                                return [4 /*yield*/, IntakeStore.downloadPdf("/staff/".concat(historyType, "/").concat(intake.id, "/pdf/?template=").concat(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    //@ts-ignore
                                    ((_a = intake === null || intake === void 0 ? void 0 : intake.box) === null || _a === void 0 ? void 0 : _a.boxes_included) > 0 ? 'packing-slip-boxes.html' : templateName), 'PrintOut.pdf').finally(function () { return setButtonPrintOut(false); })];
                            case 1:
                                _b.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }, "Download PDF")); },
        },
        {
            title: 'Inbound Destination',
            dataIndex: 'inbound_destination',
            render: function (text, intake) {
                if (text == 'SZ') {
                    return React.createElement("div", null, "Foreign");
                }
                return React.createElement("div", null,
                    " ",
                    text);
            },
        },
    ];
    if (historyType !== 'outtakes') {
        columns.splice(3, 0, {
            title: '',
            render: function (text, intake) { return (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var err_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setLoading(true);
                                    _a.label = 1;
                                case 1:
                                    _a.trys.push([1, 3, , 4]);
                                    return [4 /*yield*/, IntakeStore.setActiveViewLineItems("/staff/intakes/".concat(intake.id))];
                                case 2:
                                    _a.sent();
                                    setLoading(false);
                                    return [3 /*break*/, 4];
                                case 3:
                                    err_1 = _a.sent();
                                    notification.error({
                                        message: React.createElement(ErrorRender, { error: err_1 }),
                                        placement: 'topRight',
                                    });
                                    setLoading(false);
                                    return [3 /*break*/, 4];
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }, "View Line Items"))); },
        });
    }
    // if (historyType === 'outtakes') {
    //     columns.push({
    //         title: 'Submit and Acknowledge',
    //         align: 'center' as const,
    //         dataIndex: '',
    //         render: (text, record) => (
    //             <Tooltip placement="right" title={record.created_by?.first_name}>
    //                 {record.acknowledge ? (
    //                     <CheckSquareOutlined style={{ color: 'green' }} />
    //                 ) : (
    //                     <CloseSquareOutlined style={{ color: 'red' }} />
    //                 )}
    //             </Tooltip>
    //         ),
    //     });
    // }
    if (historyType !== 'outtakes') {
        columns.push({
            title: 'Inbound Destination',
            dataIndex: 'inbound_destination',
            render: function (text, intake) { return React.createElement("div", null,
                " ",
                text); },
        });
    }
    if (historyType === 'outtakes') {
        columns.push({
            title: 'Type',
            dataIndex: 'z_processes_name',
            render: function (text, intake) { return React.createElement("div", null,
                " ",
                text); },
        }, {
            title: 'Outbound destination',
            dataIndex: 'outbound_destination',
            render: function (text, intake) { return React.createElement("div", null,
                " ",
                text); },
        }, {
            title: '',
            render: function (record) { return (React.createElement(Button, { loading: ButtonPacket, type: "default", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setButtonPacket(true);
                                return [4 /*yield*/, IntakeStore.downloadPdf("/staff/sales-orders/".concat(IntakeStore.salesOrder.id, "/download-document-pack/?outtake=").concat(record.id), 'Document Packet.pdf').finally(function () { return setButtonPacket(false); })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }, "Download Document Packet")); },
        }, {
            title: '',
            render: function (text, intakeOrOuttake) {
                var _a, _b, _c;
                var actions = (intakeOrOuttake === null || intakeOrOuttake === void 0 ? void 0 : intakeOrOuttake.box) ? (React.createElement("div", { className: "flex flex-col" },
                    React.createElement(Button, { type: "primary", onClick: function () {
                            IntakeStore.setEditBoxVisible(true, intakeOrOuttake.id);
                        } }, "Edit Box"),
                    ((_a = intakeOrOuttake.box) === null || _a === void 0 ? void 0 : _a.packages.filter(function (p) { var _a; return (_a = p.label) === null || _a === void 0 ? void 0 : _a.id; }).length) > 0 && (React.createElement(Button, { className: 'mt-2', onClick: function () {
                            IntakeStore.printLabel(intakeOrOuttake.id)
                                .then(function () {
                                notification.success({
                                    message: 'Printing Label..',
                                    description: '',
                                });
                            })
                                .catch(function (err) {
                                notification.error({
                                    message: 'Error printing label',
                                    description: React.createElement(ErrorRender, { error: err }),
                                });
                            });
                        } }, "Print Label")))) : (React.createElement(Button, { type: "primary", 
                    // disabled={props.disabledBox}
                    onClick: function () { return IntakeStore.setCreateBoxVisible(true, intakeOrOuttake.id); } }, "Create Box"));
                return (React.createElement("div", { className: 'flex flex-col' },
                    actions,
                    React.createElement("div", { className: 'mt-2 w-full', id: 'box-' + ((_b = intakeOrOuttake.box) === null || _b === void 0 ? void 0 : _b.id) },
                        React.createElement(Button, { type: "default", onClick: function () { return setShowShipmentForm(intakeOrOuttake.id); } }, ((_c = intakeOrOuttake.box) === null || _c === void 0 ? void 0 : _c.status) == 'Shipped' ? 'Change Shipment' : 'Create Shipment'))));
            },
        });
    }
    return (React.createElement(React.Fragment, null,
        historyType === 'outtakes' ? (React.createElement("div", null,
            React.createElement(EditBox, null),
            React.createElement(CreateBox, { isTester: props.isTester }),
            showShipmentForm && (React.createElement(Modal, { open: true, width: 1200, footer: [], onCancel: function () {
                    setShowShipmentForm(null);
                    handleRefresh();
                } },
                React.createElement(Shipment, { isTester: props.isTester, outtakeId: showShipmentForm, handleClose: function () {
                        setShowShipmentForm(null);
                        handleRefresh();
                    } }))))) : null,
        React.createElement("div", { className: "flex justify-around" },
            React.createElement("div", { className: "bg-white" },
                React.createElement(Table, { rowKey: "id", columns: columns, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    dataSource: dataSource, pagination: false })),
            IntakeStore.activePreviewLineItems && IntakeStore.receiveOrShip === 'receive' && (React.createElement(IntakesList, { loading: loading, intake: IntakeStore.activePreviewLineItems, storage_locations: InventoryStore.storages, updateIntakeItem: IntakeStore.updateLineItemRemote, updateLocalQuantity: IntakeStore.setLocalNewQuantityIntakeLineItem })),
            IntakeStore.activePreview && React.createElement(OrderPreview, { html: IntakeStore.activePreview }))));
});
