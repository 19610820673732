import React, { useState } from 'react';
import { Input, Modal, ModalProps, Spin, Button } from 'antd';

interface Props extends ModalProps {
    visible: boolean;
    isLoading: boolean;
    onDownload: (typeable_fields: {
        revision: string;
        quotedNote: string;
        orderNote: string;
        otherInformation: string;
    }) => void;
    onCancel: () => void;
    onSave: (typeable_fields: {
        revision: string;
        quotedNote: string;
        orderNote: string;
        otherInformation: string;
    }) => void;
}

export const TravelerModal = (props: Props) => {
    const [revision, setRevision] = useState('');
    const [quotedNote, setQuotedNote] = useState('');
    const [orderNote, setOrderNote] = useState('');
    const [otherInformation, setOtherInformation] = useState('');

    return (
        <Modal
            title={<strong>Please enter information for typeable fields</strong>}
            open={props.visible}
            {...props}
            footer={[
                <Button key="Cancel" onClick={props.onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="Save"
                    onClick={(): void => {
                        props.onSave({ revision, quotedNote, orderNote, otherInformation });
                    }}
                >
                    Save Traveler
                </Button>,
                <Button
                    key="Download"
                    type="primary"
                    onClick={(): void => {
                        props.onDownload({ revision, quotedNote, orderNote, otherInformation });
                    }}
                >
                    Download Traveler
                </Button>,
            ]}
        >
            <Spin spinning={props.isLoading} tip={'Downloading please wait...'}>
                <div>
                    <label htmlFor="typeable_revision"># Revision:</label>
                    <Input
                        onBlur={e => {
                            setRevision(e.target.value);
                        }}
                        name={'typeable_revision'}
                    />
                </div>
                <div className={'mt-4'}>
                    <label htmlFor="typeable_quoted_note">Note for Quoted Job Time:</label>
                    <Input
                        onBlur={e => {
                            setQuotedNote(e.target.value);
                        }}
                        name={'typeable_quoted_note'}
                    />
                </div>
                <div className={'mt-4'}>
                    <label htmlFor="typeable_order_note">Order Notes (Including Batch Release Dates):</label>
                    <Input.TextArea
                        onBlur={e => {
                            setOrderNote(e.target.value);
                        }}
                        name={'typeable_order_note'}
                        autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                </div>
                <div className={'mt-4'}>
                    <label htmlFor="typeable_other_information">Other information:</label>
                    <Input.TextArea
                        onBlur={e => {
                            setOtherInformation(e.target.value);
                        }}
                        name={'typeable_other_information'}
                        autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                </div>
            </Spin>
        </Modal>
    );
};
