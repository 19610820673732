var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
import countries from 'country-region-data';
export var States = function (props) {
    var data;
    if (props === null || props === void 0 ? void 0 : props.countryShortCode) {
        data = countries.find(function (country) { return country.countryShortCode === (props === null || props === void 0 ? void 0 : props.countryShortCode); });
    }
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, 
        // size={'small'}
        // style={{ width: '100%' }}
        dropdownMatchSelectWidth: false, placeholder: "Search", filterOption: function (input, option) { return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0; } }, props), data === null || data === void 0 ? void 0 : data.regions.map(function (row) { return (React.createElement(Select.Option, { key: row.shortCode, value: row.shortCode }, row.name)); })));
};
