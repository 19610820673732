import React, {useState} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import {post} from "shared/api";


const formItemLayout = {
    labelCol: {span: 12},
    wrapperCol: {span: 12},
};

export const AccountForm = Form.create()((props) => {


    const {form, handleCreated} = props;
    const {getFieldDecorator} = form;
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    ...values,
                };
                setLoading(true);

                post('staff/accounts/', payload).then(response => {

                    if (handleCreated) {
                        handleCreated(response.data);
                    }
                    setLoading(false);

                }).catch(error => {
                    setLoading(false);
                })

            }
        });
    }


    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit}>
                <Form.Item label={"Name"} {...formItemLayout} >
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: 'Please select a value!'}],
                    })(
                        <Input autoComplete={"off"}/>
                    )}
                </Form.Item>

                <Form.Item label={"Invoice Email"} {...formItemLayout} >
                    {getFieldDecorator('email', {
                        rules: [{required: false, message: 'Please select a value!'}],

                    })(
                        <Input autoComplete={"off"} type={"email"}/>
                    )}
                </Form.Item>

                <div style={{textAlign: 'center'}}>
                    <Button type="primary" htmlType="submit" icon={<LegacyIcon type={"plus"} />}
                            loading={loading}>
                        Save
                    </Button>
                </div>
            </Form>
        </React.Fragment>
    );
})

