var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Drawer, Input, message, notification, Row, Select, Spin, Tabs, Tag, Tooltip, } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import LineItems from './LineItems';
import { QuotedLineItems } from '../../QuotedLineItems';
import { AdditionalRequirements, AddressEdit, Contacts, ErrorRender, FormErrorParser, Industries, QuestionYesOrNo, Quotes, ShippingCarriers, ShippingMethods, ShippingRequirements, UploadFile, Users, } from '@partsbadger/library';
import ApplicationCostItems from '../../ApplicationCostItems';
import AddItemsButton from './AddItemsButton';
import { SalesOrderModalBatches } from '../../components/SalesOrderModalBatches';
import { SalesOrderListBatches } from '../../components/SalesOrderListBatches';
import { buildJsonBigOrderChecklist, TOTAL_PRICE_SHOW_CHECKLIST } from '../../utils/utils';
import moment from 'moment';
import SalesOrderBatchStore from '../../Stores/SalesOrderBatchStore';
import { ALLOWED_FILES } from '@partsbadger/utils';
import SalesRepChecklist from '../SalesOrderChecklist/SalesRepChecklist';
import { QuoteStore } from 'quotes-frontend/src/stores';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { parseInt } from 'lodash';
import { OrderTotals } from '../../OrderTotals';
import NewSampleRequest from '../../components/SalesOrderCreateForm/NewSampleRequest';
import _ from 'lodash';
var TabPane = Tabs.TabPane;
var formItemLayout = {
    labelCol: { md: 4, xs: 24 },
    wrapperCol: { md: 20, xs: 24 },
};
var formItemCheckBoxLayout = {
    labelCol: { md: 4, xs: 6 },
    wrapperCol: { md: 8, xs: 8 },
};
export var styleBody = {
    padding: '3px 0',
    textAlign: 'center',
    color: 'black',
};
var SalesOrderCreateForm = observer(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    var queryParams = new URLSearchParams(window.location.search);
    var contactUrl = queryParams.get('contact');
    var quoteUrl = queryParams.get('quote');
    //Contact Email
    var param_email = queryParams.get('email');
    var param_id = queryParams.get('DraftOrderID');
    var _21 = useState('Needs Review'), orderStatus = _21[0], setOrderStatus = _21[1];
    var _22 = useState(false), draftOrder = _22[0], setDraftOrder = _22[1];
    var _23 = useState(false), showAddSpecificItems = _23[0], setShowAddSpecificItems = _23[1];
    var _24 = useState(false), showAddNewAddress = _24[0], setShowAddNewAddress = _24[1];
    var _25 = useState({}), customRequirements = _25[0], setCustomRequirements = _25[1];
    var _26 = useState(null), orderItem = _26[0], setOrderItem = _26[1];
    var _27 = useState(false), showModalBatches = _27[0], setShowModalBatches = _27[1];
    var _28 = useState(false), isCreatingARequote = _28[0], setIsCreatingARequote = _28[1];
    var _29 = useState([]), salesRepChecklistQuestions = _29[0], setSalesRepChecklistQuestions = _29[1];
    var _30 = useState([]), aditionalFilesFromQuote = _30[0], setAditionalFilesFromquote = _30[1];
    var _31 = useState(false), openQuotes = _31[0], setOpenQuotes = _31[1];
    var _32 = useState([]), sampleRequestList = _32[0], setSampleRequestList = _32[1];
    var history = useHistory();
    var _33 = props.form, getFieldDecorator = _33.getFieldDecorator, getFieldValue = _33.getFieldValue, setFieldsValue = _33.setFieldsValue;
    var loading = SalesOrderStore.loading, account = SalesOrderStore.account, contact = SalesOrderStore.contact, order_items = SalesOrderStore.order_items, addresses = SalesOrderStore.addresses, industry_use = SalesOrderStore.industry_use, users = SalesOrderStore.users, default_shipping_address = SalesOrderStore.default_shipping_address, default_billing_address = SalesOrderStore.default_billing_address;
    var current_user = props.current_user;
    var _34 = useState(true), isLoadingInitialData = _34[0], setIsLoadingInitialData = _34[1];
    // Sections answer/reveal
    var _35 = useState(true), pricingCorrect = _35[0], setPricingCorrect = _35[1];
    var _36 = useState(true), dueDateMatch = _36[0], setDueDateMatch = _36[1];
    var order = SalesOrderStore.order;
    var mergeAditionalFiles = function (first, second) {
        return __spreadArray(__spreadArray([], (first !== undefined ? first : []), true), second, true);
    };
    var getPayload = function (values) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var shipping = values.shipping_address;
        var billing = values.billing_address;
        var shipping_requirements = null;
        if ((order === null || order === void 0 ? void 0 : order.split_into_batch_shipments) && orderStatus === 'Needs Review') {
            if (!values.shipping_requirements.includes('Batch/Blanket Releases')) {
                shipping_requirements = ['Batch/Blanket Releases'];
            }
        }
        var partial_shipments = values.shipping_requirements ? values.shipping_requirements : [];
        if (shipping_requirements != null && Array.isArray(shipping_requirements)) {
            shipping_requirements = shipping_requirements.concat(partial_shipments);
        }
        else {
            shipping_requirements = partial_shipments.length > 0 ? partial_shipments : null;
        }
        var payload = {
            industry_use: null,
            end_use: null,
            owner: null,
            contact: values.contact.id,
            billing_name: billing && billing.name ? billing.name : order === null || order === void 0 ? void 0 : order.billing_name,
            billing_street: billing && billing.street ? billing.street : order === null || order === void 0 ? void 0 : order.billing_street,
            billing_city: billing && billing.city ? billing.city : order === null || order === void 0 ? void 0 : order.billing_city,
            billing_state: billing && billing.state ? billing.state : order === null || order === void 0 ? void 0 : order.billing_state,
            billing_zip_code: billing && billing.zip_code ? billing.zip_code : order === null || order === void 0 ? void 0 : order.billing_zip_code,
            billing_country: billing && ((_a = billing.country) === null || _a === void 0 ? void 0 : _a.code) ? billing.country.code : order === null || order === void 0 ? void 0 : order.billing_country,
            shipping_name: shipping && shipping.name ? shipping.name : order === null || order === void 0 ? void 0 : order.shipping_name,
            shipping_street: shipping && shipping.street ? shipping.street : order === null || order === void 0 ? void 0 : order.shipping_street,
            shipping_city: shipping && shipping.city ? shipping.city : order === null || order === void 0 ? void 0 : order.shipping_city,
            shipping_state: shipping && shipping.state ? shipping.state : order === null || order === void 0 ? void 0 : order.shipping_state,
            shipping_zip_code: shipping && shipping.state ? shipping.zip_code : order === null || order === void 0 ? void 0 : order.shipping_zip_code,
            shipping_country: shipping && ((_b = shipping.country) === null || _b === void 0 ? void 0 : _b.code) ? shipping.country.code : order === null || order === void 0 ? void 0 : order.shipping_country,
            shipping_requirements: shipping_requirements,
            create_2d_files: values.create_2d_files,
            create_3d_files: values.create_3d_files,
            additional_files: mergeAditionalFiles((_c = values.additional_files) === null || _c === void 0 ? void 0 : _c.map(function (po) {
                return po.response.id;
            }), aditionalFilesFromQuote.map(function (f) { return f.id; })),
            po_files: (_d = values.po_files) === null || _d === void 0 ? void 0 : _d.map(function (po) {
                return po.response.id;
            }),
            additional_requirements: (_e = values.additional_requirements) === null || _e === void 0 ? void 0 : _e.map(function (r) {
                return r.value ? "".concat(r.label, " : ").concat(r.value) : r.label;
            }),
            add_application_cost: values.add_application_cost,
            recovered_account: values.recovered_account,
            source: 'Sales Order Tool - Staff',
            notes: values.notes,
            add_shipping_notification_address: values.add_shipping_notification_address,
            hot_order: values.hot_order,
            hot_order_notes: values.hot_order_notes,
            shipping_account_number: values.shipping_account_number,
            shipping_method: values.shipping_method,
            carrier: values.carrier,
            export_control: values.export_control,
            status: orderStatus,
            purchase_order: values.purchase_order,
            payment_terms: values.payment_terms,
            samples_our_quality: order === null || order === void 0 ? void 0 : order.samples_our_quality,
            samples_design_customer: order === null || order === void 0 ? void 0 : order.samples_design_customer,
        };
        if ((_g = (_f = values.industry_use) === null || _f === void 0 ? void 0 : _f.industry_use) === null || _g === void 0 ? void 0 : _g.key) {
            payload['industry_use'] = (_j = (_h = values.industry_use) === null || _h === void 0 ? void 0 : _h.industry_use) === null || _j === void 0 ? void 0 : _j.key;
            payload['end_use'] = values.industry_use.end_use.label;
        }
        if ((_k = values.owner) === null || _k === void 0 ? void 0 : _k.key) {
            payload['owner'] = values.owner.key;
        }
        if (values.invoice_email_address) {
            payload['invoice_email_address'] = values.invoice_email_address;
        }
        if (values.in_house_requirements) {
            payload['in_house_requirements'] = values.in_house_requirements;
        }
        if (values.hot_ship_date) {
            var hsd = values.hot_ship_date;
            if (hsd) {
                payload['hot_ship_date'] = hsd.format('YYYY-MM-DD');
            }
        }
        if (pricingCorrect !== undefined) {
            payload['pricing_correct'] = pricingCorrect;
            if (values.reason_pricing_correct) {
                payload['reason_pricing_correct'] = values.reason_pricing_correct;
            }
        }
        if (dueDateMatch !== undefined) {
            payload['due_date_match'] = dueDateMatch;
            if (values.reason_due_date_match) {
                payload['reason_due_date_match'] = values.reason_due_date_match;
            }
        }
        // build json Checklist Sales Rep
        if (SalesOrderStore.getTotalPriceOrder() >= TOTAL_PRICE_SHOW_CHECKLIST) {
            payload['sales_rep_checklist'] = buildJsonBigOrderChecklist(values, salesRepChecklistQuestions);
        }
        return payload;
    };
    var handleSubmit = function (action) {
        if (checkFilesItemQuotes()) {
            message.error('One or more order items have file types that are not allowed to send the sales order. Please check again.', 5);
        }
        else {
            props.form.validateFields(function (err, values) { return __awaiter(void 0, void 0, void 0, function () {
                var payload, error_1, payload, error_2;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!(action == 'Send to Review')) return [3 /*break*/, 7];
                            if (!!err) return [3 /*break*/, 5];
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, , 4]);
                            if (!contact) {
                                message.error('No Contact Selected');
                                return [2 /*return*/];
                            }
                            if (!account) {
                                message.error('No Account Selected');
                                return [2 /*return*/];
                            }
                            if (!order_items.length) {
                                message.error('Line Items are required!');
                                return [2 /*return*/];
                            }
                            if (doItNeedARequote) {
                                message.error('Please Validate Big Order CheckList!', 3);
                                return [2 /*return*/];
                            }
                            if (ffaBeforeProduction) {
                                if (partsSelectedForFAA.length === 0) {
                                    message.error('The Parts for FAA is required!');
                                    return [2 /*return*/];
                                }
                                if (sampleQuantity === null || sampleQuantity === 0) {
                                    message.error('Sample Quantities is required!');
                                    return [2 /*return*/];
                                }
                            }
                            payload = getPayload(values);
                            return [4 /*yield*/, SalesOrderStore.sendToReviewStaff(SalesOrderStore.order_id, __assign(__assign({}, payload), { account: account.id, contact: (_a = SalesOrderStore.contact) === null || _a === void 0 ? void 0 : _a.id, status: 'Needs Review' }))];
                        case 2:
                            _b.sent();
                            notification.success({
                                message: 'The order was sent for review',
                            });
                            SalesOrderStore.loading = false;
                            SalesOrderStore.order_items = [];
                            SalesOrderStore.quoted_line_items = [];
                            SalesOrderStore.addresses = [];
                            SalesOrderStore.contact = undefined;
                            cleanStore();
                            props.onSubmitted();
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _b.sent();
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: error_1 }),
                                placement: 'bottomRight',
                            });
                            return [3 /*break*/, 4];
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            notification.error({
                                key: 'form-errors',
                                duration: 20,
                                message: 'Please input all required fields',
                                description: React.createElement(FormErrorParser, { errors: err }),
                                placement: 'bottomRight',
                                maxCount: 1,
                            });
                            _b.label = 6;
                        case 6: return [3 /*break*/, 10];
                        case 7:
                            _b.trys.push([7, 9, , 10]);
                            payload = getPayload(values);
                            return [4 /*yield*/, SalesOrderStore.saveOrCompleteSalesOrder(SalesOrderStore.order_id, __assign(__assign({}, payload), { status: 'Draft', more_line_items: moreLineItems, split_into_batch_shipments: splitIntoBatchShipments, ffa_before_production: ffaBeforeProduction }))];
                        case 8:
                            _b.sent();
                            notification.info({
                                message: 'The order was saved as draft',
                            });
                            SalesOrderStore.loading = false;
                            SalesOrderStore.order_items = [];
                            SalesOrderStore.quoted_line_items = [];
                            SalesOrderStore.addresses = [];
                            SalesOrderStore.contact = undefined;
                            cleanStore();
                            props.onSubmitted();
                            return [3 /*break*/, 10];
                        case 9:
                            error_2 = _b.sent();
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: error_2 }),
                                placement: 'bottomRight',
                            });
                            return [3 /*break*/, 10];
                        case 10:
                            SalesOrderStore.loading = false;
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    var checkFilesItemQuotes = function () {
        // Verify that the files uploaded by quotes comply with the allowed extensions.
        var allowed_files_3d = ALLOWED_FILES.Files3D;
        var allowed_files_2d = ALLOWED_FILES.Files2D;
        var files_not_allowed = [];
        order_items.map(function (item) {
            var _a, _b;
            if (item.production_3d_file_id === undefined && item.production_3d_file) {
                var t_1 = (_a = item.production_3d_file.filename.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
                var file_type = allowed_files_3d.filter(function (f) { return f === t_1; });
                if (t_1 !== file_type[0]) {
                    files_not_allowed.push(item.id);
                }
            }
            if (item.production_2d_file_id === undefined && item.production_2d_file) {
                var t_2 = (_b = item.production_2d_file.filename.split('.').pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
                var file_type = allowed_files_2d.filter(function (f) { return f === t_2; });
                if (t_2 !== file_type[0]) {
                    files_not_allowed.push(item.id);
                }
            }
        });
        return files_not_allowed.length > 0;
    };
    var cleanStore = function () {
        // Cleaning selected quotes
        order = null;
        SalesOrderStore.setOrder(undefined);
        setFieldsValue({
            quotes: [],
            shipping_address: null,
            billing_address: null,
        });
        SalesOrderStore.contact = null;
        SalesOrderStore.account = null;
        SalesOrderStore.setQuotedLineItems([]);
        SalesOrderStore.setApplicationCostItems([]);
        SalesOrderStore.setOrderItems([]);
        SalesOrderStore.setQuote();
        SalesOrderBatchStore.resetBatches();
        SalesOrderStore.poUnacceptableKeywords = [];
        SalesOrderStore.afUnacceptableKeywords = [];
        setOpenQuotes(false);
        setPricingCorrect(true);
        setDueDateMatch(true);
    };
    var loadLocalStateData = function () {
        var _a;
        if (order) {
            (_a = order === null || order === void 0 ? void 0 : order.line_items) === null || _a === void 0 ? void 0 : _a.map(function (batch) { var _a; return SalesOrderBatchStore.setBatches((_a = batch.batch_shipments) !== null && _a !== void 0 ? _a : []); });
            if (order.due_date_match !== undefined) {
                setDueDateMatch(order.due_date_match);
            }
            if (order.pricing_correct !== undefined) {
                setPricingCorrect(order.pricing_correct);
            }
            setDraftOrder(true);
        }
    };
    var parseRequirements = function () {
        var v = _.values(customRequirements);
        var req = [];
        _.forEach(v).map(function (i) {
            req = __spreadArray(__spreadArray([], req, true), i, true);
        });
        // Remove duplicates
        return _.uniqBy(req, function (e) {
            return e.key;
        });
    };
    /**
     * Get and order by ID and intialize the form with the data
     * @param id
     */
    var getOrderByID = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SalesOrderStore.getOrderById(id)];
                case 1:
                    _a.sent();
                    loadLocalStateData();
                    return [2 /*return*/, true];
            }
        });
    }); };
    /**
     * Get a Draft order using the Contact ID, it will return an updated order with the default values
     * like the default shipping and billing address, the additional requirements, etc.
     * @param contact_id: number
     */
    var getDraftOrderByContactID = function (contact_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SalesOrderStore.getContactById(contact_id)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, SalesOrderStore.getOrCreateSalesOrder(contact_id)];
                case 2:
                    _a.sent();
                    loadLocalStateData();
                    return [2 /*return*/, true];
            }
        });
    }); };
    /**
     * Get an contact with the email provided, if exists, it will get a draft order for that contact
     * @param contact_email
     */
    var create_order_by_email = function (contact_email) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SalesOrderStore.getContactByEmail(contact_email)];
                case 1:
                    _a.sent();
                    if (!SalesOrderStore.contact) return [3 /*break*/, 3];
                    return [4 /*yield*/, getDraftOrderByContactID(SalesOrderStore.contact.id)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getInitialRemoteData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!param_id) return [3 /*break*/, 2];
                    return [4 /*yield*/, getOrderByID(parseInt(param_id))];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    if (!param_email) return [3 /*break*/, 4];
                    return [4 /*yield*/, create_order_by_email(param_email)];
                case 3: return [2 /*return*/, _b.sent()];
                case 4:
                    if (!contactUrl) return [3 /*break*/, 6];
                    return [4 /*yield*/, getDraftOrderByContactID(parseInt(contactUrl))];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    if (!quoteUrl) return [3 /*break*/, 10];
                    return [4 /*yield*/, SalesOrderStore.getQuotes('account=' + ((_a = SalesOrderStore.contact) === null || _a === void 0 ? void 0 : _a.account) + '&is_purchasable=true')];
                case 7:
                    _b.sent();
                    return [4 /*yield*/, SalesOrderStore.getQuoteLineItems(quoteUrl)];
                case 8:
                    _b.sent();
                    return [4 /*yield*/, SalesOrderStore.getQuote(parseInt(quoteUrl))];
                case 9:
                    _b.sent();
                    _b.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Clean Store and load remote data based on the url params
     */
    useEffect(function () {
        cleanStore();
        setIsLoadingInitialData(true);
        getInitialRemoteData().finally(function () {
            setIsLoadingInitialData(false);
            getSalesRepChecklist();
        });
    }, []);
    /**
     * Local Contact Info
     */
    useEffect(function () {
        var _a;
        if ((_a = order === null || order === void 0 ? void 0 : order.contact) === null || _a === void 0 ? void 0 : _a.id) {
            SalesOrderStore.quoted_line_items = [];
            SalesOrderStore.applicationCostItems = [];
            if (order)
                SalesOrderStore.getContactById(order.contact.id);
        }
    }, [(_a = order === null || order === void 0 ? void 0 : order.contact) === null || _a === void 0 ? void 0 : _a.id]);
    // useEffect(() => {
    //     if (!completeAllSteps) {
    //         openNotification();
    //     }
    // }, []);
    /**
     * Set additional requirements when custom requirements change
     */
    useEffect(function () {
        setFieldsValue({
            additional_requirements: parseRequirements(),
        });
    }, [customRequirements]);
    var _37 = useState(), sales_order_requirements = _37[0], setSalesOrderRequirements = _37[1];
    useMemo(function () {
        if (Object.keys(customRequirements).length > 0) {
            setSalesOrderRequirements(parseRequirements());
        }
    }, [customRequirements]);
    var getQuoteInformation = function (quote_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, SalesOrderStore.getQuote(quote_id).then(function (r) {
                        var newAdditionaFiles = __spreadArray([], aditionalFilesFromQuote, true);
                        newAdditionaFiles = newAdditionaFiles.concat(r.additional_files);
                        setAditionalFilesFromquote(newAdditionaFiles.filter(function (v, i, a) { return a.findIndex(function (v2) { return v2.id === v.id; }) === i; }));
                        customRequirements[quote_id] = r.custom_requirements;
                        setCustomRequirements(__assign({}, customRequirements));
                    })];
                case 1:
                    _a.sent();
                    SalesOrderStore.getQuoteLineItems(quote_id);
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Get all quote information of all selected quotes in the sales order on the start page
     */
    useEffect(function () {
        if (order && order.quotes) {
            order.quotes.map(function (quote) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    getQuoteInformation(quote.id);
                    return [2 /*return*/];
                });
            }); });
        }
    }, [order === null || order === void 0 ? void 0 : order.id]);
    var doItNeedARequote = getFieldValue('confirm-Does pricing match?') == 'No' || getFieldValue('confirm-Do the revs match?') == 'No';
    var getSalesRepChecklist = function () {
        // sales_order_id = 0 when create new sales order
        SalesOrderStore.getChecklistType(0, 'Sales Representatives Checklist')
            .then(function (response) {
            setSalesRepChecklistQuestions(response.checklist);
        })
            .catch(function (error) {
            message.error(error.message);
        });
    };
    if (isLoadingInitialData) {
        return (React.createElement("div", { style: {
                maxWidth: 1500,
                margin: '0 auto',
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                } },
                React.createElement(Spin, { size: "large" }))));
    }
    var parts = order_items.filter(function (i) { return i.name !== 'CNC Part'; });
    // const application_cost =  order_items.filter(i => i.name != 'CNC Part');
    var partsSelectedForFAA = parts.filter(function (i) { return i.quantity_sample_required; }).map(function (i) { return i.id.toString(); });
    var all_sample_quantities = (_b = parts.filter(function (i) { return i.quantity_sample_required != null; }).map(function (i) { return i.quantity_sample_required; })) !== null && _b !== void 0 ? _b : [];
    var sampleQuantity = null;
    if (all_sample_quantities.length > 0) {
        sampleQuantity = (_c = all_sample_quantities[0]) !== null && _c !== void 0 ? _c : null;
    }
    var moreLineItems = order === null || order === void 0 ? void 0 : order.more_line_items;
    var splitIntoBatchShipments = order === null || order === void 0 ? void 0 : order.split_into_batch_shipments;
    var ffaBeforeProduction = order === null || order === void 0 ? void 0 : order.ffa_before_production;
    var needsToCompleteFAA = function () {
        return !!((order === null || order === void 0 ? void 0 : order.ffa_before_production) && (!sampleQuantity || all_sample_quantities.length == 0));
    };
    var section1st = parts.length > 0;
    var section2nd = section1st && (order === null || order === void 0 ? void 0 : order.more_line_items) == false;
    var section3rd = section2nd && (order === null || order === void 0 ? void 0 : order.split_into_batch_shipments) != undefined;
    var section4th = section2nd && section3rd && (order === null || order === void 0 ? void 0 : order.ffa_before_production) != undefined && !needsToCompleteFAA();
    var completeAllSteps = section1st && section2nd && section2nd && section3rd && section4th;
    return (React.createElement("div", { style: {
            maxWidth: 1500,
            margin: '0 auto',
        } },
        React.createElement(Form, { className: 'sales-order-form' },
            React.createElement("div", { className: 'row-order mt-5 m-auto' },
                React.createElement(Col, { sm: 24 }, !param_email ? (React.createElement(Form.Item, __assign({ label: 'Contact' }, formItemLayout), getFieldDecorator('contact', {
                    initialValue: (SalesOrderStore === null || SalesOrderStore === void 0 ? void 0 : SalesOrderStore.contact)
                        ? ((_d = SalesOrderStore.contact) === null || _d === void 0 ? void 0 : _d.fullname) + ' || ' + ((_e = SalesOrderStore.contact) === null || _e === void 0 ? void 0 : _e.email)
                        : null,
                    rules: [
                        {
                            required: true,
                            message: 'Please select a contact to start',
                        },
                    ],
                })(React.createElement(Contacts, { account: (_f = SalesOrderStore === null || SalesOrderStore === void 0 ? void 0 : SalesOrderStore.account) === null || _f === void 0 ? void 0 : _f.id, onSelect: function (item) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            getDraftOrderByContactID(item.key);
                            cleanStore();
                            return [2 /*return*/];
                        });
                    }); } })))) : (React.createElement(Form.Item, __assign({ label: 'Contact' }, formItemLayout), getFieldDecorator('contact', {
                    initialValue: (contact === null || contact === void 0 ? void 0 : contact.fullname) + ' || ' + param_email,
                })(React.createElement(Input, { disabled: true })))))),
            (contact === null || contact === void 0 ? void 0 : contact.id) && !(account === null || account === void 0 ? void 0 : account.id) && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: "This contact doesn't have an account related, please fix this before to continue!" }))),
            draftOrder && (React.createElement("div", { className: 'flex justify-center' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        "A draft order already exists for the contact:",
                        ' ',
                        React.createElement("b", null,
                            ((_g = SalesOrderStore.contact) === null || _g === void 0 ? void 0 : _g.fullname) + ' | ' + ((_h = SalesOrderStore.contact) === null || _h === void 0 ? void 0 : _h.email),
                            " "),
                        "You can continue editing the draft order and then submit it for review."), type: "info", showIcon: true, closable: true, onClose: function () { return setDraftOrder(false); } }))),
            param_email && !(contact === null || contact === void 0 ? void 0 : contact.id) && !(account === null || account === void 0 ? void 0 : account.id) && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: "This email contact is payable ".concat(param_email, "; try another contact from zoho.") }))),
            (contact === null || contact === void 0 ? void 0 : contact.id) && (account === null || account === void 0 ? void 0 : account.id) && (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: 'row-order mt-5 text-center m-auto', style: {
                        maxWidth: '57%',
                    } },
                    React.createElement(Col, { sm: 12 },
                        React.createElement(Form.Item, { label: 'Billing' }, getFieldDecorator('billing_address', __assign({ rules: [
                                {
                                    required: true,
                                    message: 'Billing Address is required',
                                },
                            ], validateTrigger: 'onSelectAddress' }, ((order === null || order === void 0 ? void 0 : order.billing_street)
                            ? { initialValue: order === null || order === void 0 ? void 0 : order.billing_street }
                            : { initialValue: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.street })))(React.createElement(AddressEdit, { account_name: (account === null || account === void 0 ? void 0 : account.name)
                                ? account === null || account === void 0 ? void 0 : account.name
                                : "".concat(contact.first_name, " ").concat(contact.last_name), contact_id: contact.id, addresses: ((_j = SalesOrderStore.contact) === null || _j === void 0 ? void 0 : _j.addresses)
                                ? (_l = (_k = SalesOrderStore.contact) === null || _k === void 0 ? void 0 : _k.addresses) === null || _l === void 0 ? void 0 : _l.filter(function (billing) {
                                    return (billing.type === 'Billing' ||
                                        billing.type === 'Billing and Shipping');
                                })
                                : [], type: 'Billing', currentAddress: (order === null || order === void 0 ? void 0 : order.billing_street)
                                ? {
                                    name: order === null || order === void 0 ? void 0 : order.billing_name,
                                    type: 'Billing',
                                    street: order === null || order === void 0 ? void 0 : order.billing_street,
                                    city: order === null || order === void 0 ? void 0 : order.billing_city,
                                    state: order === null || order === void 0 ? void 0 : order.billing_state,
                                    country: {
                                        alpha3: order === null || order === void 0 ? void 0 : order.billing_country,
                                        code: order === null || order === void 0 ? void 0 : order.billing_country,
                                        name: order === null || order === void 0 ? void 0 : order.billing_country,
                                    },
                                    zip_code: order === null || order === void 0 ? void 0 : order.billing_zip_code,
                                }
                                : default_billing_address
                                    ? {
                                        name: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.name,
                                        type: 'Billing',
                                        street: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.street,
                                        city: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.city,
                                        state: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.state,
                                        country: {
                                            alpha3: '',
                                            code: (_m = default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.country) === null || _m === void 0 ? void 0 : _m.code,
                                            name: (_o = default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.country) === null || _o === void 0 ? void 0 : _o.name,
                                        },
                                        zip_code: default_billing_address === null || default_billing_address === void 0 ? void 0 : default_billing_address.zip_code,
                                    }
                                    : null, onSelectAddress: function (_address) {
                                var _a, _b, _c, _d, _e, _f;
                                var name = _address.name, street = _address.street, city = _address.city, state = _address.state, country = _address.country, zip_code = _address.zip_code, type = _address.type;
                                var payload = {};
                                if (type == 'Billing and Shipping') {
                                    payload = {
                                        billing_name: name,
                                        billing_street: street,
                                        billing_city: city,
                                        billing_state: state,
                                        billing_country: country,
                                        billing_zip_code: zip_code,
                                        shipping_name: (_a = getFieldValue('shipping_address')) === null || _a === void 0 ? void 0 : _a.name,
                                        shipping_street: (_b = getFieldValue('shipping_address')) === null || _b === void 0 ? void 0 : _b.street,
                                        shipping_city: (_c = getFieldValue('shipping_address')) === null || _c === void 0 ? void 0 : _c.city,
                                        shipping_state: (_d = getFieldValue('shipping_address')) === null || _d === void 0 ? void 0 : _d.state,
                                        shipping_country: (_e = getFieldValue('shipping_address')) === null || _e === void 0 ? void 0 : _e.country,
                                        shipping_zip_code: (_f = getFieldValue('shipping_address')) === null || _f === void 0 ? void 0 : _f.zip_code,
                                    };
                                }
                                else {
                                    payload = {
                                        billing_name: name,
                                        billing_street: street,
                                        billing_city: city,
                                        billing_state: state,
                                        billing_country: country,
                                        billing_zip_code: zip_code,
                                    };
                                }
                                if (order === null || order === void 0 ? void 0 : order.id) {
                                    SalesOrderStore.patchOrderDraft(order === null || order === void 0 ? void 0 : order.id, payload).finally(function () {
                                        if (order)
                                            SalesOrderStore.getOrderById(order === null || order === void 0 ? void 0 : order.id);
                                    });
                                }
                            } })))),
                    React.createElement(Col, { sm: 12 },
                        React.createElement(Form.Item, { label: 'Shipping' }, getFieldDecorator('shipping_address', __assign({ rules: [
                                {
                                    required: true,
                                    message: 'Shipping Address is required',
                                },
                            ], validateTrigger: 'onSelectAddress' }, ((order === null || order === void 0 ? void 0 : order.shipping_street)
                            ? { initialValue: order === null || order === void 0 ? void 0 : order.shipping_street }
                            : { initialValue: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.street })))(React.createElement(AddressEdit, { account_name: (account === null || account === void 0 ? void 0 : account.name)
                                ? account === null || account === void 0 ? void 0 : account.name
                                : "".concat(contact.first_name, " ").concat(contact.last_name), contact_id: contact.id, addresses: ((_p = SalesOrderStore.contact) === null || _p === void 0 ? void 0 : _p.addresses)
                                ? (_r = (_q = SalesOrderStore.contact) === null || _q === void 0 ? void 0 : _q.addresses) === null || _r === void 0 ? void 0 : _r.filter(function (shipping) {
                                    return (shipping.type === 'Shipping' ||
                                        shipping.type === 'Billing and Shipping');
                                })
                                : [], type: 'Shipping', currentAddress: (order === null || order === void 0 ? void 0 : order.shipping_street)
                                ? {
                                    name: order === null || order === void 0 ? void 0 : order.shipping_name,
                                    type: 'Shipping',
                                    street: order === null || order === void 0 ? void 0 : order.shipping_street,
                                    city: order === null || order === void 0 ? void 0 : order.shipping_city,
                                    state: order === null || order === void 0 ? void 0 : order.shipping_state,
                                    country: {
                                        alpha3: order === null || order === void 0 ? void 0 : order.shipping_country,
                                        code: order === null || order === void 0 ? void 0 : order.shipping_country,
                                        name: order === null || order === void 0 ? void 0 : order.shipping_country,
                                    },
                                    zip_code: order === null || order === void 0 ? void 0 : order.shipping_zip_code,
                                }
                                : default_shipping_address
                                    ? {
                                        name: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.name,
                                        type: 'Shipping',
                                        street: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.street,
                                        city: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.city,
                                        state: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.state,
                                        country: {
                                            alpha3: '',
                                            code: (_s = default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.country) === null || _s === void 0 ? void 0 : _s.code,
                                            name: (_t = default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.country) === null || _t === void 0 ? void 0 : _t.name,
                                        },
                                        zip_code: default_shipping_address === null || default_shipping_address === void 0 ? void 0 : default_shipping_address.zip_code,
                                    }
                                    : null, onSelectAddress: function (_address) {
                                var _a, _b, _c, _d, _e, _f;
                                var name = _address.name, street = _address.street, city = _address.city, state = _address.state, country = _address.country, zip_code = _address.zip_code, type = _address.type;
                                var payload = {};
                                if (type == 'Billing and Shipping') {
                                    payload = {
                                        billing_name: (_a = getFieldValue('billing_address')) === null || _a === void 0 ? void 0 : _a.name,
                                        billing_street: (_b = getFieldValue('billing_address')) === null || _b === void 0 ? void 0 : _b.street,
                                        billing_city: (_c = getFieldValue('billing_address')) === null || _c === void 0 ? void 0 : _c.city,
                                        billing_state: (_d = getFieldValue('billing_address')) === null || _d === void 0 ? void 0 : _d.state,
                                        billing_country: (_e = getFieldValue('billing_address')) === null || _e === void 0 ? void 0 : _e.country,
                                        billing_zip_code: (_f = getFieldValue('billing_address')) === null || _f === void 0 ? void 0 : _f.zip_code,
                                        shipping_name: name,
                                        shipping_street: street,
                                        shipping_city: city,
                                        shipping_state: state,
                                        shipping_country: country,
                                        shipping_zip_code: zip_code,
                                    };
                                }
                                else {
                                    payload = {
                                        shipping_name: name,
                                        shipping_street: street,
                                        shipping_city: city,
                                        shipping_state: state,
                                        shipping_country: country,
                                        shipping_zip_code: zip_code,
                                    };
                                }
                                if (order === null || order === void 0 ? void 0 : order.id) {
                                    SalesOrderStore.patchOrderDraft(order === null || order === void 0 ? void 0 : order.id, payload).finally(function () {
                                        if (order)
                                            SalesOrderStore.getOrderById(order === null || order === void 0 ? void 0 : order.id);
                                    });
                                }
                            } }))))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, __assign({ label: 'Quotes' }, formItemLayout), getFieldDecorator('quotes', {
                            initialValue: (_u = order === null || order === void 0 ? void 0 : order.quotes) === null || _u === void 0 ? void 0 : _u.map(function (q) {
                                return {
                                    key: q.id,
                                    label: q.name,
                                };
                            }),
                            rules: [{ required: false, message: 'Please select a Quote!' }],
                        })(React.createElement(Quotes, { mode: 'multiple', account: account === null || account === void 0 ? void 0 : account.id, filters: {
                                is_purchasable: true,
                            }, onSelect: function (item) {
                                getQuoteInformation(parseInt(item.key));
                                // SalesOrderStore.getQuoteLineItems(item.key);
                                // SalesOrderStore.getQuote(parseInt(item.key))
                                //     .then((response: QuoteProps) => {
                                //         if (
                                //             response.custom_requirements !== null &&
                                //             response.custom_requirements.length > 0
                                //         ) {
                                //             setCustomRequirements([
                                //                 ...customRequirements,
                                //                 response.custom_requirements,
                                //             ]);
                                //         } else {
                                //             setCustomRequirements([
                                //                 ...customRequirements,
                                //                 SalesOrderStore.default_requirements,
                                //             ]);
                                //         }
                                //     })
                                //     .then(() => {
                                //         if (SalesOrderStore.quote) {
                                //             for (
                                //                 let i = 0;
                                //                 i < SalesOrderStore.quote.additional_files.length;
                                //                 i++
                                //             ) {
                                //                 const obj = {
                                //                     ...SalesOrderStore.quote.additional_files[i],
                                //                     quoteId: SalesOrderStore.quote.id.toString(),
                                //                 };
                                //
                                //                 setAditionalFilesFromquote(aditionalFilesFromQuote => [
                                //                     ...aditionalFilesFromQuote,
                                //                     obj,
                                //                 ]);
                                //             }
                                //         }
                                //     });
                            }, onDeselect: function (item) {
                                delete customRequirements[item.key];
                                setCustomRequirements(customRequirements);
                                SalesOrderStore.RemoveQuoteLineItemsFromSpecificQuote(parseInt(item.key));
                                setAditionalFilesFromquote(aditionalFilesFromQuote.filter(function (obj) { return obj.quoteId !== item.key; }));
                            }, onChange: function (quotes) { return __awaiter(void 0, void 0, void 0, function () {
                                var payload;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            payload = {
                                                quotes: quotes.map(function (quote) { return Number(quote.key); }),
                                            };
                                            return [4 /*yield*/, SalesOrderStore.saveOrCompleteSalesOrder(SalesOrderStore.order_id, payload).finally(function () {
                                                    SalesOrderStore.loading = false;
                                                })];
                                        case 1:
                                            _a.sent();
                                            if (order === null || order === void 0 ? void 0 : order.id) {
                                                SalesOrderStore.getOrderById(order === null || order === void 0 ? void 0 : order.id);
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, open: openQuotes, onClick: function () { return setOpenQuotes(undefined); } })))),
                    order && SalesOrderStore.availableItemsCount > 0 && (React.createElement(Col, { sm: 24 },
                        React.createElement("div", { style: {
                                margin: 'auto',
                                marginTop: 10,
                            } },
                            React.createElement(AddItemsButton, { setShowAddSpecificItems: setShowAddSpecificItems }))))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, " Line Items")),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(LineItems, null)),
                    order && (React.createElement(Col, { md: 24 },
                        React.createElement(OrderTotals, { subtotal: parseFloat(order.subtotal), discount: parseFloat(order.discount), taxes_rate: parseFloat(order.taxes_rate), adjustment: parseFloat(order.adjustment) })))),
                React.createElement("div", { className: 'border border-grey-600' },
                    React.createElement(Form.Item, null, getFieldDecorator('moreLineItems', {
                        validateTrigger: 'setAnswer',
                        initialValue: order === null || order === void 0 ? void 0 : order.more_line_items,
                        // rules: [{ required: true, message: 'Please select a Quote!' }],
                    })(React.createElement(QuestionYesOrNo, { default: order === null || order === void 0 ? void 0 : order.more_line_items, setAnswer: function (answer) {
                            SalesOrderStore.update_sales_order_field('more_line_items', answer);
                            setOpenQuotes(answer);
                            if (!answer && SalesOrderStore.order_items.length == 0) {
                                notification.error({
                                    duration: 10,
                                    message: 'Error',
                                    description: 'You must first add products to the order.',
                                    placement: 'bottomRight',
                                });
                            }
                        }, question: 'Do you have add more line items..?' })))),
                section2nd && (React.createElement("div", { className: 'border border-grey-600' },
                    React.createElement(Form.Item, null, getFieldDecorator('splitIntoBatchShipments', {
                        validateTrigger: 'setAnswer',
                        initialValue: order === null || order === void 0 ? void 0 : order.split_into_batch_shipments,
                        // rules: [{ required: true, message: 'Please select a Quote!' }],
                    })(React.createElement(QuestionYesOrNo, { default: order === null || order === void 0 ? void 0 : order.split_into_batch_shipments, setAnswer: function (answer) {
                            SalesOrderStore.update_sales_order_field('split_into_batch_shipments', answer);
                        }, question: 'Do the quantities above have to be split into batch shipments?' }))),
                    React.createElement("div", { className: 'row-order' }, (order === null || order === void 0 ? void 0 : order.split_into_batch_shipments) && (React.createElement("div", { className: 'pb-4' },
                        React.createElement(SalesOrderListBatches, { setOrderItem: setOrderItem, setShowModalBatches: setShowModalBatches, showModalBatches: showModalBatches })))))),
                section3rd && (React.createElement(React.Fragment, null,
                    React.createElement(QuestionYesOrNo, { default: ffaBeforeProduction !== null ? ffaBeforeProduction : undefined, setAnswer: function (answer) {
                            SalesOrderStore.update_sales_order_field('ffa_before_production', answer);
                        }, question: 'Will we need to complete an FAA (First Article Approval) or receive sample approval from the customer before continuing production?' }), order_items === null || order_items === void 0 ? void 0 :
                    order_items.map(function (item) {
                        if (item.quantity_sample_required) {
                            return (React.createElement("div", { key: item.id },
                                React.createElement(NewSampleRequest, { form: props.form, orderLineItem: item })));
                        }
                    }),
                    (order === null || order === void 0 ? void 0 : order.ffa_before_production) && (React.createElement(React.Fragment, null,
                        React.createElement(NewSampleRequest, { form: props.form }),
                        React.createElement(Row, { className: 'row-order mt-5 text-center' },
                            React.createElement(Col, { sm: 12 },
                                React.createElement(QuestionYesOrNo, { default: order.samples_our_quality, question: 'Is the customer requiring samples to verify our quality?', setAnswer: function () {
                                        SalesOrderStore.update_sales_order_field('samples_our_quality', !(order === null || order === void 0 ? void 0 : order.samples_our_quality));
                                    } })),
                            React.createElement(Col, { sm: 12 },
                                React.createElement(QuestionYesOrNo, { default: order.samples_design_customer, question: 'Is the customer requiring samples to verify their design', setAnswer: function () {
                                        SalesOrderStore.update_sales_order_field('samples_design_customer', !(order === null || order === void 0 ? void 0 : order.samples_design_customer));
                                    } }))))))),
                section4th && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, null,
                        React.createElement("div", { className: 'title' }, " Files")),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        SalesOrderStore.doWeNeed2DFiles && (React.createElement(Col, { sm: 12 },
                            React.createElement(Form.Item, __assign({ label: 'Creating 2D Files?' }, formItemLayout, { help: 'PartsBadger creating 2D Files?' }), getFieldDecorator('create_2d_files', {
                                valuePropName: 'checked',
                                initialValue: ((_v = SalesOrderStore.order) === null || _v === void 0 ? void 0 : _v.create_2d_files) || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please upload a 2d files or select the option to create 2d files',
                                    },
                                ],
                            })(React.createElement(Checkbox, { onChange: function () {
                                    var _a;
                                    SalesOrderStore.update_sales_order_field('create_2d_files', !((_a = SalesOrderStore.order) === null || _a === void 0 ? void 0 : _a.create_2d_files));
                                } }))))),
                        SalesOrderStore.doWeNeed3DFiles && (React.createElement(Col, { sm: 12 },
                            React.createElement(Form.Item, __assign({ label: 'Creating 3D Files?' }, formItemLayout, { help: 'PartsBadger creating 3D Files?' }), getFieldDecorator('create_3d_files', {
                                valuePropName: 'checked',
                                initialValue: ((_w = SalesOrderStore.order) === null || _w === void 0 ? void 0 : _w.create_3d_files) || undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please upload a 3d files or select the option to create 3d files',
                                    },
                                ],
                            })(React.createElement(Checkbox, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Purchase Order Files' }, formItemLayout),
                                getFieldDecorator('po_files', {
                                    initialValue: (_x = order === null || order === void 0 ? void 0 : order.po_files) === null || _x === void 0 ? void 0 : _x.map(function (r) {
                                        return {
                                            uid: r.id,
                                            name: r.filename,
                                            status: 'done',
                                            response: {
                                                id: r.id,
                                            },
                                            url: r.file,
                                        };
                                    }),
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please upload a Purchase Order File',
                                        },
                                    ],
                                })(React.createElement(UploadFile, { multiple: true, onUpload: function (data) {
                                        SalesOrderStore.getUnacceptableKeywords(data.response.id, SalesOrderStore.poUnacceptableKeywords).catch(function (e) {
                                            console.error(e);
                                        });
                                    } })), (_y = SalesOrderStore.poUnacceptableKeywords) === null || _y === void 0 ? void 0 :
                                _y.map(function (item, index) {
                                    var key = "".concat(index);
                                    return (React.createElement(Tooltip, { key: key, placement: "topLeft", title: 'Non-accepted keywords found in the document' },
                                        React.createElement(Tag, { color: "red" }, item)));
                                })))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 12 },
                            React.createElement(QuestionYesOrNo, { default: pricingCorrect, question: 'Is the pricing correct?', setAnswer: setPricingCorrect }),
                            !pricingCorrect && (React.createElement(Form.Item, { label: 'Are we accepting the PO? Why?', className: 'pl-8' }, getFieldDecorator('reason_pricing_correct', {
                                initialValue: (_z = SalesOrderStore.order) === null || _z === void 0 ? void 0 : _z.reason_pricing_correct,
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field reason of pricing incorrect is required',
                                    },
                                ],
                            })(React.createElement(Input, null))))),
                        React.createElement(Col, { sm: 12 },
                            React.createElement(QuestionYesOrNo, { default: dueDateMatch, question: 'If applicable, does the due date match?', setAnswer: setDueDateMatch }),
                            !dueDateMatch && (React.createElement(Form.Item, { label: 'Are we accepting the PO? Why?', className: 'pl-8' }, getFieldDecorator('reason_due_date_match', {
                                initialValue: (_0 = SalesOrderStore.order) === null || _0 === void 0 ? void 0 : _0.reason_due_date_match,
                                rules: [
                                    {
                                        required: true,
                                        message: 'This field of reason due date match is required',
                                    },
                                ],
                            })(React.createElement(Input, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Purchase Order Number' }, formItemLayout), getFieldDecorator('purchase_order', {
                                initialValue: (_1 = SalesOrderStore.order) === null || _1 === void 0 ? void 0 : _1.purchase_order,
                                rules: [{ required: true }],
                            })(React.createElement(Input, { placeholder: 'Enter the PO Number or enter “PrePaid” if you’re uploading a credit card invoice' })))),
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Payment Terms' }, formItemLayout), getFieldDecorator('payment_terms', {
                                rules: [{ required: true, message: 'Please select a value!' }],
                                initialValue: ((_2 = SalesOrderStore.order) === null || _2 === void 0 ? void 0 : _2.payment_terms)
                                    ? SalesOrderStore.order.payment_terms
                                    : !((_3 = SalesOrderStore.order) === null || _3 === void 0 ? void 0 : _3.payment_terms) && (account === null || account === void 0 ? void 0 : account.payment_terms)
                                        ? account === null || account === void 0 ? void 0 : account.payment_terms
                                        : 'NET30',
                            })(React.createElement(Select, { showSearch: true },
                                React.createElement(Select.Option, { value: "PrePaid" }, "PrePaid"),
                                React.createElement(Select.Option, { value: "NET15" }, "NET15"),
                                React.createElement(Select.Option, { value: "NET30" }, "NET30"),
                                React.createElement(Select.Option, { value: "NET45" }, "NET45"),
                                React.createElement(Select.Option, { value: "NET60" }, "NET60"),
                                React.createElement(Select.Option, { value: "NET75" }, "NET75"),
                                React.createElement(Select.Option, { value: "NET90" }, "NET90"),
                                React.createElement(Select.Option, { value: "CC Upon Shipment" }, "CC Upon Shipment"),
                                React.createElement(Select.Option, { value: "Cash on Delivery - NET00" }, "Cash on Delivery - NET00")))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Additional Files' }, formItemLayout),
                                getFieldDecorator('additional_files', {
                                    initialValue: (_4 = order === null || order === void 0 ? void 0 : order.additional_files) === null || _4 === void 0 ? void 0 : _4.map(function (r) {
                                        return {
                                            uid: r.id,
                                            name: r.filename,
                                            status: 'done',
                                            response: {
                                                id: r.id,
                                            },
                                            url: r.file,
                                        };
                                    }),
                                })(React.createElement(UploadFile, { multiple: true, onUpload: function (data) {
                                        SalesOrderStore.getUnacceptableKeywords(data.response.id, SalesOrderStore.afUnacceptableKeywords);
                                    } })), (_5 = SalesOrderStore.afUnacceptableKeywords) === null || _5 === void 0 ? void 0 :
                                _5.map(function (item, index) {
                                    var key = "".concat(index);
                                    return (React.createElement(Tooltip, { key: key, placement: "topLeft", title: 'Non-accepted keywords found in the document' },
                                        React.createElement(Tag, { color: "red" }, item)));
                                }))),
                        aditionalFilesFromQuote.length > 0 && (React.createElement(Col, { sm: 24 },
                            React.createElement("div", { className: 'mt-4' },
                                React.createElement("div", null,
                                    React.createElement("h2", null, "Additional files from RFQ:")),
                                aditionalFilesFromQuote.map(function (item) {
                                    return (React.createElement("div", { key: item.id },
                                        React.createElement("a", { onClick: function () {
                                                window.open(item.file, '__blank', 'width=800,height=800,left=200,top=200');
                                            } }, item.filename),
                                        React.createElement(DeleteOutlined, { onClick: function () {
                                                setAditionalFilesFromquote(aditionalFilesFromQuote.filter(function (obj) { return obj.id !== item.id; }));
                                            } })));
                                }))))),
                    React.createElement(Divider, null,
                        React.createElement("div", { className: 'title' }, " Additional Information")),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Add Application Cost?' }, formItemCheckBoxLayout), getFieldDecorator('add_application_cost', {
                                valuePropName: 'checked',
                                initialValue: ((_6 = SalesOrderStore.order) === null || _6 === void 0 ? void 0 : _6.add_application_cost) || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Recovered Account?' }, formItemCheckBoxLayout), getFieldDecorator('recovered_account', {
                                valuePropName: 'checked',
                                initialValue: ((_7 = SalesOrderStore.order) === null || _7 === void 0 ? void 0 : _7.recovered_account) || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Export Control?' }, formItemCheckBoxLayout), getFieldDecorator('export_control', {
                                valuePropName: 'checked',
                                initialValue: ((_8 = SalesOrderStore.order) === null || _8 === void 0 ? void 0 : _8.export_control) || false,
                            })(React.createElement(Checkbox, null)))),
                        !getFieldValue('export_control') && (React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Industry' }, formItemLayout), getFieldDecorator('industry_use', {
                                initialValue: industry_use,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Industry, Use and End Use are required',
                                    },
                                ],
                            })(React.createElement(Industries, null)))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Hot Order?' }, formItemCheckBoxLayout), getFieldDecorator('hot_order', {
                                valuePropName: 'checked',
                                initialValue: ((_9 = SalesOrderStore.order) === null || _9 === void 0 ? void 0 : _9.hot_order) || false,
                            })(React.createElement(Checkbox, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Hot Ship Date' }, formItemLayout), getFieldDecorator('hot_ship_date', {
                                initialValue: SalesOrderStore.order && SalesOrderStore.order.hot_ship_date
                                    ? moment(SalesOrderStore.order.hot_ship_date)
                                    : null,
                            })(React.createElement(DatePicker, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Hot Order Notes' }, formItemLayout), getFieldDecorator('hot_order_notes', {
                                initialValue: (_10 = SalesOrderStore.order) === null || _10 === void 0 ? void 0 : _10.hot_order_notes,
                            })(React.createElement(Input.TextArea, { autoComplete: 'off', maxLength: 3000 }))))),
                    React.createElement(Row, { className: 'text-center' },
                        React.createElement(Col, { sm: 24, className: 'row-order mt-5' },
                            React.createElement(Form.Item, __assign({ label: 'Shipping Requirements?' }, formItemLayout), getFieldDecorator('shipping_requirements', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: ((_11 = SalesOrderStore.order) === null || _11 === void 0 ? void 0 : _11.shipping_requirements)
                                    ? (_12 = SalesOrderStore.order) === null || _12 === void 0 ? void 0 : _12.shipping_requirements
                                    : SalesOrderStore.default_requirements
                                        ? (_13 = SalesOrderStore.default_requirements) === null || _13 === void 0 ? void 0 : _13.filter(function (r) { return (r === null || r === void 0 ? void 0 : r.type) === 'Shipping Requirements'; }).map(function (r) { return r.label; })
                                        : [],
                            })(React.createElement(ShippingRequirements, null))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Invoice Email Address' }, formItemLayout), getFieldDecorator('invoice_email_address', {
                                initialValue: account === null || account === void 0 ? void 0 : account.invoice_email_address,
                                rules: [
                                    { type: 'email' },
                                    { required: !(account === null || account === void 0 ? void 0 : account.invoice_email_address) },
                                ],
                            })(React.createElement(Input, { placeholder: (account === null || account === void 0 ? void 0 : account.invoice_email_address) || 'Insert an email' }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'In House Requirements' }, formItemLayout), getFieldDecorator('in_house_requirements', {
                                initialValue: order === null || order === void 0 ? void 0 : order.in_house_requirements,
                            })(React.createElement(Input.TextArea, { placeholder: (account === null || account === void 0 ? void 0 : account.in_house_requirements) ||
                                    'Insert In House Requirements.' }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Shipping Account' }, formItemLayout), getFieldDecorator('shipping_account_number', {
                                initialValue: ((_14 = SalesOrderStore.order) === null || _14 === void 0 ? void 0 : _14.shipping_account_number)
                                    ? SalesOrderStore.order.shipping_account_number
                                    : (account === null || account === void 0 ? void 0 : account.shipping_account_number)
                                        ? account.shipping_account_number
                                        : '555692285',
                            })(React.createElement(Input, { placeholder: (account === null || account === void 0 ? void 0 : account.shipping_account_number) ||
                                    'Insert Shipping Account Number, Please.' }))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Shipping Method' }, formItemLayout), getFieldDecorator('shipping_method', {
                                initialValue: ((_15 = SalesOrderStore.order) === null || _15 === void 0 ? void 0 : _15.shipping_method)
                                    ? SalesOrderStore.order.shipping_method
                                    : (account === null || account === void 0 ? void 0 : account.shipping_method)
                                        ? account.shipping_method
                                        : 'Ground',
                            })(React.createElement(ShippingMethods, { size: 'middle' }))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Carrier' }, formItemLayout), getFieldDecorator('carrier', {
                                initialValue: ((_16 = SalesOrderStore.order) === null || _16 === void 0 ? void 0 : _16.carrier)
                                    ? SalesOrderStore.order.carrier
                                    : account.carrier
                                        ? account.carrier
                                        : 'FedEX',
                            })(React.createElement(ShippingCarriers, { size: "middle" }))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Additional Shipping Notification' }, formItemLayout), getFieldDecorator('add_shipping_notification_address', {
                                rules: [{ type: 'email' }],
                                initialValue: ((_17 = SalesOrderStore.order) === null || _17 === void 0 ? void 0 : _17.add_shipping_notification_address)
                                    ? SalesOrderStore.order.add_shipping_notification_address
                                    : (_18 = SalesOrderStore.quote) === null || _18 === void 0 ? void 0 : _18.related_contact,
                            })(React.createElement(Input, { placeholder: ((_19 = SalesOrderStore.quote) === null || _19 === void 0 ? void 0 : _19.related_contact) || 'Insert an email' }))))),
                    sales_order_requirements !== undefined && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Sales Order Requirements' }, formItemLayout), getFieldDecorator('additional_requirements', {
                                rules: [
                                    {
                                        required: false,
                                    },
                                ],
                                initialValue: sales_order_requirements !== undefined
                                    ? sales_order_requirements
                                    : [],
                            })(React.createElement(AdditionalRequirements, { requirements: SalesOrderStore.requirements_list })))))),
                    React.createElement(Row, { className: 'row-order mt-5 text-center' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Notes' }, formItemLayout), getFieldDecorator('notes', {
                                initialValue: ((_20 = SalesOrderStore.order) === null || _20 === void 0 ? void 0 : _20.notes) && SalesOrderStore.order.notes,
                            })(React.createElement(Input.TextArea, null))))),
                    React.createElement(Row, { className: 'row-order mt-5' },
                        React.createElement(Col, { sm: 24 },
                            React.createElement(Form.Item, __assign({ label: 'Owner', help: 'If empty the account owner will be used' }, formItemLayout), getFieldDecorator('owner', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please select a user!',
                                    },
                                ],
                                initialValue: (current_user === null || current_user === void 0 ? void 0 : current_user.id)
                                    ? {
                                        key: current_user.id,
                                        label: current_user.fullname,
                                    }
                                    : {},
                            })(React.createElement(Users, { users: users }))))),
                    SalesOrderStore.getTotalPriceOrder() >= TOTAL_PRICE_SHOW_CHECKLIST && (React.createElement(SalesRepChecklist, { getFieldDecorator: getFieldDecorator, checklistEdit: {}, form: props.form, checklistQuestions: salesRepChecklistQuestions, setChecklistQuestions: setSalesRepChecklistQuestions })))),
                doItNeedARequote && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Alert, { type: 'error', message: React.createElement("div", null,
                            "Looks like something is not matching, please submit a Requote to match the customer requirements",
                            getFieldValue('quotes').map(function (q) { return (React.createElement("div", { key: q.key },
                                React.createElement(Button, { type: 'link', loading: isCreatingARequote, key: q.key, onClick: function () {
                                        setIsCreatingARequote(true);
                                        QuoteStore.getRequote(q.key)
                                            .then(function (r) {
                                            history.push('/get-quote/?uid=' + r.data.uid);
                                        })
                                            .catch(function () { return setIsCreatingARequote(false); });
                                    } },
                                    "Click here to send (",
                                    q.label,
                                    ")"))); })) }))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                        React.createElement(Button, { className: 'mr-4', type: "default", onClick: function () {
                                setOrderStatus('Draft');
                                handleSubmit('Save to Later');
                            }, loading: loading }, "Save for later"),
                        !completeAllSteps && (React.createElement("span", { style: { color: 'red', fontWeight: 900 } }, "Answer the question above to continue")),
                        completeAllSteps && (React.createElement(Button, { type: "primary", loading: loading, className: 'mr-4', onClick: function () {
                                handleSubmit('Send to Review');
                            } }, "Submit to Review"))))))),
        orderItem !== null && showModalBatches && (React.createElement(SalesOrderModalBatches, { batches: SalesOrderBatchStore.batches, order_item: orderItem, setShowModalBatches: setShowModalBatches, showModalBatches: showModalBatches })),
        React.createElement(Drawer, { closable: true, title: "Quoted Parts and Additional Requirements", placement: "left", onClose: function () { return setShowAddSpecificItems(false); }, visible: showAddSpecificItems, width: 800 },
            React.createElement(Tabs, { defaultActiveKey: "1" },
                React.createElement(TabPane, { tab: "Parts (".concat(SalesOrderStore.quoted_line_items.length, ")"), key: "1" },
                    React.createElement(QuotedLineItems, { handleAddItem: function (item) { return __awaiter(void 0, void 0, void 0, function () {
                            var order_item;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        order_item = {
                                            id: item.id,
                                            name: item.quote_has_product.name,
                                            product_description: '',
                                            quote_has_product: item.quote_has_product,
                                            quantity: item.quantity,
                                            list_price: item.unit_price,
                                            unit_price: item.unit_price,
                                            total: item.total_price,
                                            discount: 0,
                                            total_after_discount: item.total_price,
                                            taxes: 0,
                                        };
                                        return [4 /*yield*/, SalesOrderStore.addOrderItem(order_item)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })),
                React.createElement(TabPane, { tab: "Additional Requirements (".concat(SalesOrderStore.applicationCostItems.length, ")"), key: "2" },
                    React.createElement(ApplicationCostItems, { handleAdd: function (item) { return __awaiter(void 0, void 0, void 0, function () {
                            var order_item;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        order_item = {
                                            id: item.id,
                                            name: 'CNC Part',
                                            product_description: item.name,
                                            quote_has_product: undefined,
                                            quantity: 1,
                                            list_price: parseFloat(item.price),
                                            unit_price: parseFloat(item.price),
                                            total: parseFloat(item.price),
                                            discount: 0,
                                            total_after_discount: parseFloat(item.price),
                                            taxes: 0,
                                        };
                                        return [4 /*yield*/, SalesOrderStore.addOrderItem(order_item)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }))))));
});
export default Form.create()(SalesOrderCreateForm);
