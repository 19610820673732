import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Row, Table } from 'antd';
import { fetchQuotesByAuthorGrouped } from '../../redux/actions/quoteActions';

import { connect } from 'react-redux';
import { DateRangePicker, ErrorRender } from '@partsbadger/library';
import moment from 'moment';
import { get } from '../../shared/api';
import dayjs from 'dayjs';

const { Column } = Table;
const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

class ReportFilter extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = {};

                if (values.is_itar) {
                    params['is_itar'] = values.is_itar;
                }

                if (values.stages) {
                    params['stages'] = values.stages;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                this.props.getData(params);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row className={'row-filters'}>
                <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>
        );
    }
}

const ReportFilterForm = Form.create()(ReportFilter);

const ListReport = ({ requotes, summarize, requote_changes, loading }) => {
    const aggregated_line_items = summarize.reduce((total, row) => total + row.line_items, 0);
    const aggregated_quoted_products = summarize.reduce((total, row) => total + row.quoted_products, 0);

    const {
        quotes_count,
        requote_by_quantity_count,
        requote_by_lead_time_count,
        requote_by_material_count,
        requote_by_coating_or_finishing_count,
        requote_by_new_file_version_count,
        requote_by_add_or_remove_lines_count,
        requote_by_pricing_lines_count,
        requote_by_additional_requirements_lines_count,
    } = requote_changes || {};

    return (
        <div>
            <Table dataSource={requotes} loading={loading} pagination={false}>
                <Column title="#" dataIndex="name" key="name" />
                <Column
                    title="Completed Time"
                    dataIndex="completed_at"
                    key="completed_at"
                    render={(t, r) => dayjs(r.completed_at).format('YYYY-MM-DD')}
                />

                <Column title="Account" dataIndex="account" key="account" render={(t, r) => r.account?.name} />

                <Column title="Sales Rep" dataIndex="owner" key="owner" render={(t, r) => r.owner.fullname} />
                <Column
                    title="Requote Change"
                    dataIndex="requote_changes"
                    key="requote_changes"
                    render={(t, r) => {
                        console.log();
                        return (
                            <div>
                                {r.requote_changes?.map(item => (
                                    <span>{item}, </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </Table>

            <div>
                <strong>#Quotes:</strong> {quotes_count}
            </div>
            <div>
                <strong>#Line Items:</strong> {aggregated_line_items}
            </div>
            <div>
                <strong>#Quoted Products:</strong> {aggregated_quoted_products}
            </div>

            <Divider>Requote Changes</Divider>

            <div>
                <strong>#By Quantity:</strong> {requote_by_quantity_count}
            </div>
            <div>
                <strong>#By Lead Time:</strong> {requote_by_lead_time_count}
            </div>
            <div>
                <strong>#By Material:</strong> {requote_by_material_count}
            </div>
            <div>
                <strong>#By Coating or Finish:</strong> {requote_by_coating_or_finishing_count}
            </div>
            <div>
                <strong>#By New File Version:</strong> {requote_by_new_file_version_count}
            </div>
            <div>
                <strong>#By Add / Remove lines :</strong> {requote_by_add_or_remove_lines_count}
            </div>
            <div>
                <strong>#By Pricing:</strong> {requote_by_pricing_lines_count}
            </div>
            <div>
                <strong>#By Additional Requirements :</strong> {requote_by_additional_requirements_lines_count}
            </div>
        </div>
    );
};

class ReportRequotesByAuthor extends React.Component {
    state = {
        summarize: [],
        requotes: [],
        requote_changes: {},
        loading: false,
    };

    getData = params => {
        this.setState({
            loading: true,
        });

        get(`staff/quoted-products/report-for-requotes/`, params)
            .then(response => {
                this.setState({
                    summarize: response.data.summary,
                    requotes: response.data.requotes,
                    requote_changes: response.data.requote_changes,
                    loading: false,
                });
            })
            .catch(error => console.error(error));
    };

    componentDidMount() {
        this.getData({});
    }

    render() {
        return (
            <Fragment>
                <ErrorRender error={this.props.quotes.error} />

                <ReportFilterForm getData={this.getData} />

                <Divider>
                    <div className={'title'}> Requotes Report</div>
                </Divider>

                <Row>
                    <Col span={24}>
                        <ListReport
                            requotes={this.state.requotes}
                            summarize={this.state.summarize}
                            requote_changes={this.state.requote_changes}
                            loading={this.state.loading}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.quotes,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchQuotes: params => dispatch(fetchQuotesByAuthorGrouped(params)),
    };
};

export const RequoteReport = connect(mapStateToProps, mapDispatchToProps)(ReportRequotesByAuthor);
