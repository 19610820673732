import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, Row, Select } from 'antd';
import { Accounts, Contacts, DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const QuoteFilter = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const params: any = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.account?.key) {
                    params['account'] = values.account.key;
                }

                if (values.contact?.key) {
                    params['contact'] = values.contact.key;
                }

                if (values.created_by?.key) {
                    params['created_by'] = values.created_by.key;
                }

                if (values.design_engineer?.key) {
                    params['design_engineer'] = values.design_engineer.key;
                }

                if (values.owner?.key) {
                    params['owner'] = values.owner.key;
                }

                if (values.rfq?.key) {
                    params['rfq'] = values.rfq.key;
                }

                if (values.is_dynamic != undefined) {
                    params['is_dynamic'] = values.is_dynamic;
                }

                if (values.stage) {
                    params['stage'] = values.stage;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                if (values.unassigned) {
                    params['design_engineer'] = 'Unassigned';
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');

                history.replace(`/quotes/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Row>
                    <Col {...cols}>
                        <FormItem label="Search" {...formItemLayout}>
                            {getFieldDecorator('search', {
                                initialValue: query.get('search'),
                            })(<Search placeholder="Search" style={{ width: '100%' }} allowClear size={'small'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Account" {...formItemLayout}>
                            {getFieldDecorator('account', {
                                initialValue: query.get('account')
                                    ? {
                                          key: query.get('account'),
                                      }
                                    : {},
                            })(<Accounts setAccount={() => {}} />)}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Contact" {...formItemLayout}>
                            {getFieldDecorator('contact', {
                                initialValue: query.get('contact')
                                    ? {
                                          key: query.get('contact'),
                                      }
                                    : {},
                            })(<Contacts />)}
                        </FormItem>
                    </Col>

                    {/*<Col {...cols}>*/}

                    {/*    <FormItem label="Created By"{...formItemLayout}>*/}
                    {/*        {getFieldDecorator('created_by', {*/}
                    {/*            initialValue: query.get('created_by') ? {*/}
                    {/*                'key': query.get('created_by')*/}
                    {/*            } : {}*/}
                    {/*        })(*/}
                    {/*            <Users/>*/}
                    {/*        )}*/}

                    {/*    </FormItem>*/}
                    {/*</Col>*/}
                    <Col {...cols}>
                        <FormItem label="Design Engineer" {...formItemLayout}>
                            {getFieldDecorator('design_engineer', {
                                initialValue: query.get('design_engineer')
                                    ? {
                                          key: query.get('design_engineer'),
                                      }
                                    : {},
                            })(
                                <GenericSelect
                                    recordType={'User'}
                                    filters={{
                                        is_staff: true,
                                        is_active: true,
                                    }}
                                    size="small"
                                />
                            )}
                        </FormItem>

                        <FormItem label="Unassigned" {...formItemLayout}>
                            {getFieldDecorator('unassigned', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(<Checkbox />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Sales Rep" {...formItemLayout}>
                            {getFieldDecorator('owner', {
                                initialValue: query.get('owner')
                                    ? {
                                          key: query.get('owner'),
                                      }
                                    : {},
                            })(
                                <GenericSelect
                                    recordType={'User'}
                                    filters={{
                                        is_staff: true,
                                        is_active: true,
                                    }}
                                    size="small"
                                />
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="RFQ" {...formItemLayout}>
                            {getFieldDecorator('rfq', {
                                initialValue: query.get('rfq')
                                    ? {
                                          key: query.get('rfq'),
                                      }
                                    : {},
                            })(<GenericSelect recordType={'RFQ'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Status" {...formItemLayout}>
                            {getFieldDecorator('stage', {
                                initialValue: query.get('stage'),
                            })(
                                <Select allowClear size="small">
                                    <Select.Option value="In Progress">In Progress</Select.Option>
                                    <Select.Option value="Vendor Quoting">Vendor Quoting</Select.Option>
                                    <Select.Option value="Needs Review">Needs Review</Select.Option>
                                    <Select.Option value="Completed">Completed</Select.Option>
                                    <Select.Option value="Closed Won">Closed Won</Select.Option>
                                    <Select.Option value="Closed Lost">Closed Lost</Select.Option>
                                    <Select.Option value="Cancelled">Cancelled</Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Dynamic" {...formItemLayout}>
                            {getFieldDecorator('is_dynamic', {
                                initialValue: query.get('is_dynamic'),
                            })(
                                <Select allowClear size="small">
                                    <Select.Option value={1}>Yes</Select.Option>
                                    <Select.Option value={0}>No</Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Date" {...formItemLayout}>
                            {getFieldDecorator('created_time', {
                                initialValue:
                                    query.get('created_time_min') && query.get('created_time_max')
                                        ? [
                                              moment(query.get('created_time_min'), 'YYYY-MM-DD'),
                                              moment(query.get('created_time_max'), 'YYYY-MM-DD'),
                                          ]
                                        : null,
                            })(<DateRangePicker size="small" />)}
                        </FormItem>
                    </Col>
                    <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    history.push(`/quotes/`);
                                    form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export const QuoteFilterForm = Form.create()(QuoteFilter);
