import React from 'react';
import { Divider, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { SalesOrderTool } from '@partsbadger/sales_order_tool';
import { getCurrentUser } from 'components/User';

const NewSalesOrder = () => {
    const history = useHistory();
    const current_user: any = getCurrentUser()?.data;

    return (
        <div style={{ margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Welcome PartsBadger Representatives.</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>
                Use the form below to submit an Sales Order. This form ensures that we have all of the necessary
                information in one place to process your Order timely and efficiently. Thank you.
            </div>

            <SalesOrderTool
                current_user={current_user}
                onSubmitted={() => {
                    history.push('/sales-orders/');
                }}
            />

            <br />
        </div>
    );
};

export default NewSalesOrder;
