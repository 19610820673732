var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Select } from 'antd';
var ShippingDestination = function (props) {
    return (React.createElement(Select, __assign({ showSearch: true, style: {
            width: '100%',
        }, size: 'small' }, props),
        React.createElement(Select.Option, { value: "E" }, "Express (E)"),
        React.createElement(Select.Option, { value: "G" }, "Ground (G)"),
        React.createElement(Select.Option, { value: "U" }, "UPS (U)")));
};
export default ShippingDestination;
