// @ts-nocheck

import axios from 'axios';
import { BASE_URL } from '../constants/app';
import { getToken } from '../components/User';

const getHeaders = (hasFiles = false) => {
    return hasFiles
        ? {
              'Content-Type': 'multipart/form-data',
              Authorization: `Token ${getToken()}`,
          }
        : {
              'Content-Type': 'application/json',
              Authorization: `Token ${getToken()}`,
          };
};

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
/**
 * @deprecated Use @partsbadger/utils instead
 */
export const get = async (url, params = {}, rest?: {}) => {
    const n = 10;

    try {
        return await axios.get(BASE_URL + '/' + url, {
            params: params,
            headers: getHeaders(),
            ...rest,
        });
    } catch (err) {
        if (n === 1 || err?.response?.status) throw err;
        await timeout(3000);
        return await get(url, params, rest, n - 1);
    }
};

/**
 * @deprecated Use @partsbadger/utils instead
 */
export const post = async (url, payload = {}, n = 1, hasFiles = false) => {
    try {
        return await axios.post(BASE_URL + '/' + url, payload, {
            headers: getHeaders(hasFiles),
        });
    } catch (err) {
        if (n === 1 || err?.response?.status) throw err;
        await timeout(3000);
        return await post(url, payload, n - 1);
    }
};

/**
 * @deprecated Use @partsbadger/utils instead
 */
export const put = async (url, payload = {}, n = 1) => {
    try {
        return await axios.put(BASE_URL + '/' + url, payload, {
            headers: getHeaders(),
        });
    } catch (err) {
        if (n === 1 || err?.response?.status) throw err;
        await timeout(3000);
        return await put(url, payload, n - 1);
    }
};

/**
 * @deprecated Use @partsbadger/utils instead
 */
export const patch = async (url, payload = {}, n = 1) => {
    try {
        return await axios.patch(BASE_URL + '/' + url, payload, {
            headers: getHeaders(),
        });
    } catch (err) {
        if (n === 1 || err?.response?.status) throw err;
        await timeout(3000);
        return await patch(url, payload, n - 1);
    }
};

/**
 * @deprecated Use @partsbadger/utils instead
 */
export const remove = async (url, n = 1) => {
    try {
        return await axios.delete(BASE_URL + '/' + url, {
            headers: getHeaders(),
        });
    } catch (err) {
        if (n === 1 || err?.response?.status) throw err;
        await timeout(3000);
        return await remove(url, n - 1);
    }
};
