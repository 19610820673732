import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { get } from "../../shared";
import { DrawingFileViewer } from "../../components/DrawingFileViewer";

export const DrawingViewer = () => {

    const params = useParams();

    const drawing_file_id = params?.drawing_file_id;

    const [url, setUrl] = useState(null);

    useEffect(() => {
        get(`staff/drawing-files/${drawing_file_id}/signed-url/`).then(response => {
            setUrl(response.data.url);
        }).catch((e) => {
            alert(e)
        })
    }, [drawing_file_id]);


    return <div className="text-center">
        {url && <DrawingFileViewer url={url}/>}
    </div>;
};
