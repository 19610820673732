import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, remove } from '@partsbadger/utils';
import { IContactListStaff, IContactDetailStaff } from '@partsbadger/types';

class Contact {
    isLoading = true;
    contacts: IContactListStaff[] = [];
    contact: IContactDetailStaff | null = null;

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    documents: any[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            contacts: observable,
            documents: observable,
            pagination: observable,

            contact: observable,

            // Actions
            getAll: action,
            getById: action,
            getDocuments: action,
            remove_contact_document: action,
        });
    }

    getAll(params: any) {
        this.isLoading = true;

        return get(`/staff/contacts/`, {
            params: params,
        })
            .then(data => {
                runInAction(() => {
                    this.contacts = data.results;
                    this.pagination.current = params.page;
                    this.pagination.total = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    async getById(id: number) {
        this.isLoading = true;
        this.contact = await get(`/staff/contacts/${id}/`);
        this.isLoading = false;
    }

    getDocuments(contact_id: string) {
        this.isLoading = true;
        return get(`/staff/contacts/${contact_id}/`)
            .then(data => {
                runInAction(() => {
                    this.documents = data.contact_documents;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    remove_contact_document = async (index: number, document_id: number, contact_id: number) => {
        this.documents = this.documents.filter((d, i: number) => i !== index);
        await remove(`/staff/contacts/${contact_id}/remove-document/${document_id}`);
    };
}

export const ContactStore = new Contact();
