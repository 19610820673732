import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { observer } from 'mobx-react';
var styles = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
    alignItems: 'center',
};
export var ItemsEditForm = observer(function (_a) {
    var _b;
    var record = _a.record, renderStepFile = _a.renderStepFile, renderDrawingFile = _a.renderDrawingFile, setShowAddStepFile = _a.setShowAddStepFile, setShowAddDrawingFile = _a.setShowAddDrawingFile;
    return (React.createElement("div", { className: 'flex flex-col w-full' },
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "3D File:"),
            " \u00A0",
            (record === null || record === void 0 ? void 0 : record.name_3d_file) ? (React.createElement(React.Fragment, null, (record === null || record === void 0 ? void 0 : record.name_3d_file) && (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: function () {
                        if (record.production_3d_file_id) {
                            renderStepFile(record.production_3d_file_id);
                        }
                    } },
                    React.createElement("a", null, record.name_3d_file)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } }))))) : (React.createElement(React.Fragment, null, record.production_3d_file ? (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3d file', className: 'link', onClick: function () {
                        var _a;
                        if ((_a = record === null || record === void 0 ? void 0 : record.production_3d_file) === null || _a === void 0 ? void 0 : _a.id) {
                            renderStepFile(record.production_3d_file.id);
                        }
                    } },
                    React.createElement("a", null,
                        " ",
                        record.production_3d_file.filename)),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 3D file', className: 'link', type: 'edit', onClick: function () { return setShowAddStepFile(true); } }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 3D file', className: 'link', type: 'plus', onClick: function () { return setShowAddStepFile(true); } })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "2D File:"),
            " \u00A0",
            (record === null || record === void 0 ? void 0 : record.name_2d_file) ? (React.createElement("div", null, (record === null || record === void 0 ? void 0 : record.name_2d_file) && (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 3D file', className: 'link', onClick: function () {
                        if (record.production_2d_file_id) {
                            renderDrawingFile(record.production_2d_file_id);
                        }
                    } }, record.name_2d_file),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: function () { return setShowAddDrawingFile(true); } }))))) : (React.createElement("div", null, record.production_2d_file ? (React.createElement(React.Fragment, null,
                React.createElement("span", { title: 'Preview 2D file', className: 'link', onClick: function () {
                        var _a;
                        if ((_a = record.production_2d_file) === null || _a === void 0 ? void 0 : _a.id) {
                            renderDrawingFile(record.production_2d_file.id);
                        }
                    } }, record.production_2d_file.filename),
                ' ',
                "\u00A0",
                React.createElement(LegacyIcon, { title: 'Change 2D file', className: 'link', type: 'edit', onClick: function () { return setShowAddDrawingFile(true); } }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: 'red' } }, " Pending"),
                " \u00A0",
                React.createElement(LegacyIcon, { title: 'Add 2D file', className: 'link', type: 'plus', onClick: function () { return setShowAddDrawingFile(true); } })))))),
        React.createElement("div", { className: 'file-wrapper', style: styles },
            React.createElement("strong", null, "Other Files:"),
            React.createElement("div", { className: 'flex flex-row' }, (_b = record === null || record === void 0 ? void 0 : record.production_other_files) === null || _b === void 0 ? void 0 : _b.map(function (f) { return (React.createElement("div", { key: f.id, className: 'flex flex-row link', title: 'Preview file', onClick: function () { return window.open(f.file, '', "width=960,height=1200,left=0"); } }, f === null || f === void 0 ? void 0 :
                f.filename,
                ",")); })))));
});
