var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Checkbox } from 'antd';
import { Select } from 'antd/es';
export var CreateInventoryForm = function (props) {
    var form = Form.useForm()[0];
    var _a = useState(props.extra_order_item), orderItem = _a[0], setOrderItem = _a[1];
    useEffect(function () {
        setOrderItem(props.extra_order_item);
    }, [props.extra_order_item]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, initialValues: {
                reason: 'General Extra Parts',
            }, name: "add-update-storages", layout: "horizontal" },
            React.createElement(Row, null,
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: 'Quantity Remaining', name: "quantity_remaining", rules: [{ required: false }], className: 'mx-2' },
                        React.createElement("span", null, orderItem.quantity_remaining))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: 'Quantity Received', name: "quantity_received", rules: [{ required: true }], className: 'mx-2' },
                        React.createElement("span", null, orderItem.quantity_received))),
                React.createElement(Col, { span: 8 },
                    React.createElement(Form.Item, { label: 'Quantity Extra', name: "extra_quantity", rules: [{ required: false }], className: 'mx-2' },
                        React.createElement("span", null, orderItem.extra_quantity)))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: 'Reason', name: "reason", rules: [{ required: true }], className: 'mx-2' },
                        React.createElement(Select, { placeholder: 'Select a reason', onSelect: function (reason) {
                                var new_order_item = __assign(__assign({}, orderItem), { reason: reason });
                                props.handleUpdateItem(new_order_item);
                            } },
                            React.createElement(Select.Option, { value: "General Extra Parts" }, "General Extra Parts"),
                            React.createElement(Select.Option, { value: "Dual Source" }, "Dual Source"),
                            React.createElement(Select.Option, { value: "Order Was Canceled, Parts Still Made" }, "Order Was Canceled, Parts Still Made")))),
                React.createElement(Col, { span: 12 },
                    React.createElement(Form.Item, { label: 'Storage Location', name: "storage_location", rules: [{ required: true }], className: 'mx-2', initialValue: orderItem.storage ? orderItem.storage : undefined },
                        React.createElement(Select, { placeholder: 'Select a storage', onSelect: function (storage) {
                                var new_order_item = __assign(__assign({}, orderItem), { storage: storage });
                                props.handleUpdateItem(new_order_item);
                            } }, props.storage_locations.map(function (storageLocation, index) {
                            return (React.createElement(React.Fragment, null, storageLocation.numbers.map(function (number) {
                                return (React.createElement(Select.Option, { key: index, value: storageLocation.initial +
                                        '-' +
                                        number +
                                        ': ' +
                                        storageLocation.name },
                                    storageLocation.initial + '-' + number,
                                    ": ",
                                    storageLocation.name));
                            })));
                        }))))),
            React.createElement(Row, null,
                React.createElement(Col, { span: 10 },
                    React.createElement(Form.Item, { name: "save_in_inventory" },
                        React.createElement(Checkbox, { checked: orderItem.save_in_inventory, disabled: !orderItem.save_in_inventory, onChange: function (event) {
                                var new_order_item = __assign(__assign({}, orderItem), { save_in_inventory: event.target.checked });
                                props.handleUpdateItem(new_order_item);
                            } }, "Save in Inventory"))),
                React.createElement(Col, { span: 14 },
                    React.createElement(Form.Item, null,
                        React.createElement(Button, { type: "default", onClick: function () {
                                var new_order_item = __assign(__assign({}, orderItem), { quantity_remaining: orderItem.quantity_remaining, quantity_received: orderItem.quantity_received, extra_quantity: 0, storage: undefined, save_in_inventory: false });
                                props.handleUpdateItem(new_order_item);
                            } }, "Cancel, this was a mistake, revert only to the max quantity on the order.")))))));
};
