var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Card, Col, Row } from 'antd';
import SalesRepChecklistQuestion from './SalesRepChecklistQuestion';
var cols = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 6,
};
var SalesRepChecklist = function (_a) {
    var getFieldDecorator = _a.getFieldDecorator, checklistEdit = _a.checklistEdit, form = _a.form, checklistQuestions = _a.checklistQuestions, setChecklistQuestions = _a.setChecklistQuestions;
    return (React.createElement(Card, { title: 'Big Order Checklist', size: "small", className: "mt-2" },
        React.createElement(Row, { gutter: 4 },
            React.createElement(Col, __assign({}, cols),
                React.createElement("h1", { className: "font-bold" }, "Topic")),
            React.createElement(Col, __assign({}, cols),
                React.createElement("h1", { className: "font-bold" }, "Answer"))),
        checklistQuestions.map(function (question) {
            var key = "".concat(question.question_id);
            return (React.createElement(SalesRepChecklistQuestion, { key: key, getFieldDecorator: getFieldDecorator, cols: cols, question: question, value: '', onChange: function (e) { }, checklistEdit: checklistEdit, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions }));
        })));
};
export default SalesRepChecklist;
