import React from 'react';
import { Divider, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { CreditCardSubmissionTool } from '@partsbadger/sales_order_tool';
import { useQueryParameters } from '@partsbadger/utils';
import { getCurrentUser } from 'components/User';

const CreditCardSubmission = () => {
    return (
        <div style={{ margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Welcome PartsBadger Representatives.</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>
                Use the form below to submit a Credit Card. This form ensures that we have all of the necessary
                information in one place to process your RFQs timely and efficiently. Thank you.
            </div>

            <CreditCardSubmissionTool />

            <br />
        </div>
    );
};

export default CreditCardSubmission;
