import React, { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, List, Upload } from 'antd';
export var ChatLibrary = observer(function (_a) {
    var messages = _a.messages, onSendMessage = _a.onSendMessage, form = _a.form, height = _a.height, onlyOneAttachmentPerMessage = _a.onlyOneAttachmentPerMessage, chatIsReadOnly = _a.chatIsReadOnly, useCheckboxNotifyOwner = _a.useCheckboxNotifyOwner;
    var ref = useRef();
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue;
    var onSend = function () {
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                var payload = {
                    body: values.body,
                    attachments: values.attachment ? values.attachment.map(function (f) { return f.originFileObj; }) : [],
                    notify_owner: values.notify_owner,
                };
                if (onSendMessage) {
                    onSendMessage(payload);
                    form.resetFields();
                }
            }
        });
    };
    useEffect(function () {
        // @ts-ignore
        ref.current.scrollTop = ref.current.scrollHeight;
    }, [messages.length]);
    var normFile = function (e) {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    var renderUploadFile = function () {
        if (onlyOneAttachmentPerMessage === true) {
            return (React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                rules: [
                    {
                        required: false,
                        message: 'Please input a value!',
                    },
                ],
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
            })(React.createElement(Upload, { maxCount: 1, name: "avatar", showUploadList: true, beforeUpload: function () { return false; } },
                React.createElement(Button, null, "Add File")))));
        }
        else {
            return (React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                rules: [
                    {
                        required: false,
                        message: 'Please input a value!',
                    },
                ],
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
            })(React.createElement(Upload, { multiple: true, name: "avatar", showUploadList: true, beforeUpload: function () { return false; } },
                React.createElement(Button, null, "Add File")))));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { 
            //@ts-ignore
            ref: ref, id: "messages", className: 'chat-list', style: {
                maxHeight: height || 600,
                height: height || 600,
                overflowY: 'auto',
                border: 'solid 1px #d4d4d4',
            } },
            React.createElement(List, { className: "comment-list px-4", itemLayout: "horizontal", dataSource: messages.slice().reverse(), renderItem: function (item) {
                    var _a, _b, _c;
                    return (React.createElement("li", { className: "m-4 bg-gray-100 shadow p-4" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "mb-2 text-lg" }, !!(item === null || item === void 0 ? void 0 : item.body) && (React.createElement("div", { dangerouslySetInnerHTML: {
                                    __html: item === null || item === void 0 ? void 0 : item.body,
                                } }))),
                            item.attachment && (React.createElement("div", { className: 'mt-2' },
                                React.createElement("a", { target: '_blank', onClick: function () {
                                        window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                                    } }, (_b = (_a = item.attachment.split('?')[0]) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 :
                                    _b.pop(),
                                    React.createElement(LegacyIcon, { type: 'paper-clip' })))),
                            item.attachments.map(function (attachment) {
                                return (React.createElement("div", { key: attachment.id, className: 'mt-2' },
                                    React.createElement("a", { target: '_blank', onClick: function () {
                                            window.open(attachment.file, '', 'width=800,height=800,left=200,top=200');
                                        } },
                                        attachment.filename,
                                        React.createElement(LegacyIcon, { type: 'paper-clip' }))));
                            })),
                        React.createElement("div", null, (_c = item.author) === null || _c === void 0 ? void 0 :
                            _c.fullname,
                            " at ",
                            dayjs(item.created_time).format('LLLL'),
                            item.notify_owner && React.createElement(LegacyIcon, { style: { marginLeft: '1rem' }, type: 'mail' }))));
                } })),
        (chatIsReadOnly === undefined || chatIsReadOnly === false) && (React.createElement("div", { className: 'chat-form  bottom-0 w-11/12 mb-2 mt-4', style: {
                left: 5,
            } },
            React.createElement(Form, { className: '' },
                React.createElement("div", { className: '' },
                    React.createElement(Form.Item, { className: 'm-0' }, getFieldDecorator('body', {
                        rules: [
                            {
                                required: !getFieldValue('attachment'),
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Input.TextArea, { onKeyPress: function (e) {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                onSend();
                            }
                        } }))),
                    React.createElement("div", { className: "flex items-center justify-around" },
                        renderUploadFile(),
                        (useCheckboxNotifyOwner === undefined || useCheckboxNotifyOwner === true) && (React.createElement(Form.Item, { help: 'Notify Owner', className: 'm-0 text-center' }, getFieldDecorator('notify_owner', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'checked',
                            initialValue: true,
                        })(React.createElement(Checkbox, null)))),
                        React.createElement(Button, { onClick: onSend, type: "primary", htmlType: "submit" }, "Send"))))))));
});
