import * as ActionTypes from '../ActionTypes';

export const Contacts = (
    state = {
        isLoading: false,
        entity: {},
        defaultContact: {},
        error: null,
        showAddDealForm: false,
        entities: [],
        showContactForm: false,
        defaultAccount: {},
        defaultFirstName: "",
        defaultLastName: "",
        defaultEmail: "",
        defaultOwner: {},
        showEditForm: false

    },
    action) => {
    switch (action.type) {
        case ActionTypes.CONTACTS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};

        case ActionTypes.CONTACTS_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.SET_SELECTED_CONTACT:
            return {...state, isLoading: false, showAddDealForm: false, entity: action.payload}

        case ActionTypes.CONTACTS_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.CONTACT_CREATED:
            return {...state, isLoading: false, error: null, entity: action.payload};

        case ActionTypes.SHOW_FORM_CONTACT:
            return {
                ...state,
                isLoading: false,
                defaultContact: action.payload,
                showContactForm: true
            };

        case ActionTypes.HIDE_FORM_CONTACT:
            return {
                ...state,
                showContactForm: false,
                defaultContact: {},
                isLoading: false
            };


        default:
            return state;
    }
};