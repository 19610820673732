import React from 'react';
import { Button, Col, PageHeader, Popconfirm, Row, Typography } from 'antd';
import { observer } from 'mobx-react';

import BusinessRuleForm from '../../components/BusinessRules/BusinessRuleForm';
import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import { IRule } from '../../components/BusinessRules/types';
import { useHistory } from 'react-router-dom';
import { ContentTypeStore } from '../../stores';
import TemplateVariablesView from '../../components/Templates/VariablesView';

const BusinessRuleNewPage = () => {
    const history = useHistory();

    React.useEffect(() => {
        ContentTypeStore.getAll();
    }, []);
    React.useEffect(() => {
        const new_rule: IRule = {
            id: null,
            name: '',
            model: '',
            model_name: '',
            configuration: {
                conditions: {
                    all: [
                        {
                            name: '',
                            operator: '',
                            value: '',
                        },
                    ],
                },
                actions: [{ name: '', params: [] }],
            },
        };
        BusinessRuleStore.setRule(new_rule);
    }, []);

    const rule = BusinessRuleStore.rule;

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    onBack={() => {
                        history.push('/business-rules/');
                    }}
                    title="New Rule"
                />
            </Col>
            <Col span={24}>{BusinessRuleStore.rule && <BusinessRuleForm rule={BusinessRuleStore.rule} />}</Col>

            <Col md={24} className={'mt-5'}>
                {rule?.model_name && (
                    <>
                        <Typography.Title level={2}>Variables</Typography.Title>
                        <TemplateVariablesView model_name={rule?.model_name ?? ''} />
                    </>
                )}
            </Col>
        </Row>
    );
};

export default observer(BusinessRuleNewPage);
