import { observable, runInAction, makeObservable } from 'mobx';
import { get, post } from '@partsbadger/utils';
import { FormulaProps, OrderQuotedProductProps } from '@partsbadger/types';
import FileDownload from 'js-file-download';
import { IPartsBadgerProductionListStaff } from '@partsbadger/types/lib/staff/list/IPartsBadgerProductionListStaff';

class QuotedProduct {
    isLoading = true;
    formulas: Array<FormulaProps> = [];
    partsbadgerProductionItems: Array<IPartsBadgerProductionListStaff> = [];
    quotedProducts: Array<OrderQuotedProductProps> = [];
    quotedProduct: OrderQuotedProductProps | null = null;
    orderItemIds: Array<number> = [];
    total_results = 0;
    pagination: { defaultPageSize: number; total: number } = {
        defaultPageSize: 5,
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            formulas: observable,
            partsbadgerProductionItems: observable,
            quotedProducts: observable,
            quotedProduct: observable,
            orderItemIds: observable,
            pagination: observable,
            total_results: observable,
        });
        this.pagination = {
            defaultPageSize: 30,
            total: 0,
        };
    }

    async getFormulas(all_formulas?: boolean): Promise<void> {
        this.isLoading = true;
        await get(`/formulas/?all_formulas=${all_formulas}`).then(response => {
            runInAction((): void => {
                this.formulas = response;
                this.isLoading = false;
            });
        });
    }

    async setOrderItemIds(): Promise<void> {
        if (this.quotedProducts) {
            runInAction((): void => {
                this.orderItemIds = this.quotedProducts.map(quotedProduct => quotedProduct.id);
            });
        }
    }

    async getPartsBadgerProductionItems(params = {}): Promise<void> {
        this.isLoading = true;
        await get('/staff/quoted-products/partsbadger-production', { params })
            .then(response => {
                runInAction((): void => {
                    this.partsbadgerProductionItems = response.results;
                    this.total_results = response.count;
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getVendorQuotedProducts(params = {}): Promise<void> {
        this.isLoading = true;
        await get('/staff/sales-order-line-items/vendor-quoted-products/', { params })
            .then(response => {
                runInAction((): void => {
                    this.quotedProducts = response.results;
                    this.pagination = {
                        defaultPageSize: 30,
                        total: response.count,
                    };
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async getVendorQuotedProduct(order_item_id: number): Promise<void> {
        this.isLoading = true;
        await get(`/staff/sales-order-line-items/${order_item_id}/vendor-quoted-product/`)
            .then(response => {
                runInAction((): void => {
                    this.quotedProduct = response;
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async downloadAllFiles(order_item_id: number, part_name: string): Promise<void> {
        return await get(`/staff/sales-order-line-items/${order_item_id}/download-files/`, {
            responseType: 'blob',
        }).then(data => {
            FileDownload(data, `Product-${part_name}-files.zip`);
        });
    }

    async createNotification(payload = {}): Promise<void> {
        this.isLoading = true;
        await post('/staff/notification/new/', payload);
        this.isLoading = false;
    }

    async saveUserItemVendor(order_item_id: number, payload = {}): Promise<void> {
        this.isLoading = true;
        await post(`/staff/sales-order-line-items/${order_item_id}/user-item-vendor/`, payload);
        this.isLoading = false;
    }
}

export const QuotedProductStore: QuotedProduct = new QuotedProduct();
