import { action, observable, makeObservable } from 'mobx';

export interface IPoint {
    key: number;
    name: string;
    x0: number;
    y0: number;
    x1: number;
    y1: number;
    redaction_type: string;
    text?: string;
    font_size: number;
    font_color?: string;
    font_bold?: boolean;
    font_italic?: boolean;
    page: number;
    scale: number;
}

class RedactionTool {
    isLoading = true;
    redactionType: string | null = null;
    redaction: IPoint[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            redactionType: observable,
            redaction: observable,

            // Actions
            setRedactionType: action,
            setRedaction: action,
            cleanUp: action,
        });
    }

    setRedactionType = (redactionType: string | null) => {
        this.redactionType = redactionType;
    };
    setRedaction = (redaction: IPoint[]) => {
        this.redaction = redaction;
    };

    cleanUp = () => {
        this.redaction = [];
        this.redactionType = null;
    };
}

export const RedactionToolStore = new RedactionTool();
