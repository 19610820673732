var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { message, Table, Button } from 'antd';
import { Price } from '../Price';
export var TablePrices = function (_a) {
    var loading = _a.loading, prices = _a.prices, handleSelect = _a.handleSelect, props = __rest(_a, ["loading", "prices", "handleSelect"]);
    var columns = [
        {
            title: 'Qty',
            dataIndex: 'quantity',
            align: 'center',
            className: 'one-unit',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            align: 'center',
            render: function (t, r) {
                return React.createElement(Price, { value: r.unit_price });
            },
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            align: 'center',
            render: function (t, r) {
                return React.createElement(Price, { value: r.total_price });
            },
        },
        {
            title: 'Lead Time',
            dataIndex: 'lead_time',
        },
        {
            title: 'Actions',
            align: 'center',
            render: function (t, r) {
                if (handleSelect) {
                    return (React.createElement(Button, { type: "primary", onClick: function () {
                            handleSelect(r.quantity).then(function (r) { return message.success('New Quantity Added'); });
                        } }, "Select"));
                }
            },
        },
    ];
    var rendered_columns = handleSelect ? columns : columns.filter(function (c) { return c.title !== 'Actions'; });
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { rowKey: 'quantity', loading: loading, bordered: true, columns: rendered_columns, dataSource: prices, size: "middle", pagination: {
                pageSize: props.pageSize,
                current: props.current,
                total: props.count,
                onChange: function (page) { return props.handlePageChange(page); },
            } })));
};
