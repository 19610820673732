var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { ClockCircleOutlined, PlayCircleFilled } from '@ant-design/icons';
import { get } from '@partsbadger/utils';
import { Skeleton, Timeline, Typography, Tabs } from 'antd';
import dayjs from 'dayjs';
export var Type;
(function (Type) {
    Type["Empty"] = "~";
})(Type || (Type = {}));
/***
 * Returns a normalized field name capitalized and with spaces
 */
function normalizeFieldName(fieldName) {
    var words = fieldName.split('_');
    var capitalizedWords = words.map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); });
    return capitalizedWords.join(' ');
}
var HistoryRules = function (props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var _b = React.useState([]), historyRules = _b[0], setHistoryRules = _b[1];
    var loadHistoryRules = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, get("/staff/".concat(props.model_name, "/").concat(props.pk, "/history-rules/"))];
                case 1:
                    data = _a.sent();
                    setHistoryRules(data.results);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        loadHistoryRules();
    }, []);
    if (loading) {
        return React.createElement(Skeleton, { active: true });
    }
    return (React.createElement("div", { style: {
            width: '100%',
        } },
        React.createElement(Timeline, { mode: "left", className: 'mt-2' }, historyRules.map(function (item) {
            return (React.createElement(Timeline.Item, { key: item.id, dot: React.createElement(PlayCircleFilled, { style: { fontSize: '16px' } }), label: React.createElement("div", null,
                    React.createElement("div", null, dayjs(item.created_time).format('MM/DD/YYYY hh:mm A')),
                    React.createElement("div", null, item.rule_name)) },
                React.createElement("div", { style: {
                        minHeight: '100px',
                    } },
                    React.createElement(Typography.Text, { ellipsis: true },
                        React.createElement("div", { className: 'my-2' }, Object.keys(item.data).map(function (key) {
                            return (React.createElement("div", { key: key },
                                React.createElement("span", { className: 'mx-2' },
                                    key,
                                    ":"),
                                React.createElement("span", { className: 'bold ml-2' }, item.data[key])));
                        }))))));
        }))));
};
var HistoryChanges = function (props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var _b = React.useState([]), history = _b[0], setHistory = _b[1];
    var loadHistory = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, get("/staff/".concat(props.model_name, "/").concat(props.pk, "/history/"))];
                case 1:
                    data = _a.sent();
                    setHistory(data.history);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        loadHistory();
    }, []);
    if (loading) {
        return React.createElement(Skeleton, { active: true });
    }
    return (React.createElement("div", { style: {
            width: '100%',
        } },
        React.createElement(Timeline, { mode: "left", className: 'mt-2' }, history.map(function (item) {
            var _a;
            return (React.createElement(Timeline.Item, { key: item.history_id, dot: React.createElement(ClockCircleOutlined, { style: { fontSize: '16px' } }), label: React.createElement("div", null,
                    React.createElement("div", null, dayjs(item.date).format('MM/DD/YYYY hh:mm A')),
                    React.createElement("div", { className: 'text-primary' },
                        React.createElement(Typography.Text, null, item.changed_by)),
                    React.createElement("div", null,
                        "Reason: ", (_a = item.change_reason) !== null && _a !== void 0 ? _a : '-')) },
                React.createElement("div", { style: {
                        minHeight: '100px',
                    } },
                    React.createElement(Typography.Text, null,
                        React.createElement("div", { className: 'my-2' }, item.changes.map(function (c) {
                            return (React.createElement("div", { key: c.field },
                                React.createElement("span", { className: 'mx-2' }, normalizeFieldName(c.field)),
                                "was updated from",
                                React.createElement("span", { className: 'bold ml-2' },
                                    c.old,
                                    " "),
                                "to",
                                React.createElement("span", { className: 'bold ml-2' }, c.new)));
                        }))))));
        }))));
};
var History = function (props) {
    var items = [
        { label: 'Changes', key: 'item-1', children: React.createElement(HistoryChanges, __assign({}, props)) },
        { label: 'Rules', key: 'item-2', children: React.createElement(HistoryRules, __assign({}, props)) },
    ];
    return (React.createElement("div", null,
        React.createElement(Tabs, { items: items })));
};
export default History;
