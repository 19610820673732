import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Empty, Table } from "antd";
import React, {useEffect, useState} from "react";
import {get, patch, remove} from "../../../shared/api";


const AddressListComponent = (props) => {


    const [addresses, setAddresses] = useState([])
    const [loading, setLoading] = useState(false)

    const getAddressesByContactId = (contact_id) => {
        get(`staff/contacts/${contact_id}/addresses/`).then((response) => {
            setAddresses(response.data.results)
        }).catch((error) => {
            setAddresses([])
        });
    }

    const setPrimary = (id) => {

        setLoading(true);

        const payload = {
            'primary': true
        }
        patch(`staff/contacts/${props.contact}/addresses/${id}/`, payload).then(() => {
            setLoading(false);
            getAddressesByContactId(props.contact);

        })
    }

    const removeAddress = (id) => {

        setLoading(true);

        remove(`staff/contacts/${props.contact}/addresses/${id}`).then(() => {
            setLoading(false);
            getAddressesByContactId(props.contact);
        })
    }

    useEffect(() => {
        getAddressesByContactId(props.contact)
    }, [props.contact])


    if (!addresses.length) {
        return (
            <Empty>
                <span>There are no registered addresses yet</span>
            </Empty>
        )
    }

    return (
        <div>
            <Divider>
            </Divider>
            <Table dataSource={addresses} rowKey={"id"}
                   loading={loading}
            >

                <Table.Column
                    title="Type"
                    dataIndex="type"
                    key="type"
                />

                <Table.Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                />

                <Table.Column
                    title="Address"
                    dataIndex="street"
                    key="street"
                    render={(t, record) => {
                        return (
                            <address>
                                <span>{record.street} </span> <br/>
                                <span> {record.city}, {record.state} {record.zip_code}</span> <br/>
                                <span> {record.country.name}</span>
                            </address>
                        )
                    }}
                />


                <Table.Column
                    title="Primary"
                    dataIndex="primary"
                    key="primary"
                    render={(t, record) => {
                        if (record.primary) {
                            return <LegacyIcon type={"check"}/>;
                        }
                        return <span className={"link"} onClick={() => {
                            setPrimary(record.id)
                        }}>Set as Primary</span>
                    }}
                />

                <Table.Column
                    title="Action"
                    render={(t, record) => {
                        return (
                            <div>
                                <span className={"link"} onClick={() => removeAddress(record.id)}> <LegacyIcon
                                    type={"close"}/>  </span>
                                <Divider type={'vertical'}/>
                                <span className={"link"} onClick={() => props.editAddress(record)}> <LegacyIcon
                                    type={"edit"}/>  </span>
                            </div>
                        );

                    }}
                />


            </Table>
        </div>
    );
}


export const AddressList = Form.create()(AddressListComponent);
