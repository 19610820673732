var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useEffect } from 'react';
import { TrixEditor } from 'react-trix';
import 'trix/dist/trix.css';
export var RichTextEditor = function (props) {
    var _a;
    useEffect(function () {
        document.addEventListener('trix-file-accept', function (event) {
            event.preventDefault();
        });
    }, []);
    var handleEditorReady = function (editor) {
        if (props.initialValue) {
            editor.loadHTML(props.initialValue);
        }
    };
    var handleChange = function (html, text) {
        var _a, _b;
        if (props === null || props === void 0 ? void 0 : props.onChange) {
            var new_html = (_b = (_a = html === null || html === void 0 ? void 0 : html.replace('<a', '<div')) === null || _a === void 0 ? void 0 : _a.replace('</a', '</div')) === null || _b === void 0 ? void 0 : _b.replace('href', 'rel');
            props.onChange(new_html, text);
        }
    };
    return (React.createElement(TrixEditor, __assign({ onChange: handleChange, onEditorReady: handleEditorReady }, props, { value: props.value && props.value, mergeTags: (_a = props.mergeTags) !== null && _a !== void 0 ? _a : [] })));
};
