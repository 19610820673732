import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { message, Spin } from 'antd';
import { get } from '@partsbadger/utils';
//@ts-ignore
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
var EMPTY_DATA_VALUE = ' - ';
export var RFQView = observer(function (_a) {
    var _b, _c;
    var rfq_id = _a.rfq_id;
    var _d = useState(), rfq = _d[0], setRFQ = _d[1];
    useEffect(function () {
        get("/staff/rfq/".concat(rfq_id, "/"))
            .then(function (response) {
            setRFQ(response);
        })
            .catch(function (error) { return message.error(error); });
    }, [rfq_id]);
    if (!(rfq === null || rfq === void 0 ? void 0 : rfq.id)) {
        return React.createElement(Spin, { spinning: true, tip: 'Loading RFQ' });
    }
    return (React.createElement("div", { className: 'w-full' },
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Products")),
        rfq.rfq_items.map(function (r, index) {
            var _a, _b, _c;
            return (React.createElement("div", { key: "p-".concat(index + 1), className: "product-container", style: {
                    border: '1px solid #dee2e6',
                    borderRadius: '10px',
                    margin: '0px 4px 5px',
                    padding: '5px',
                    display: 'flex',
                    flexWrap: 'wrap',
                } }, [
                { label: '3D File:', value: ((_a = r.file3d) === null || _a === void 0 ? void 0 : _a.filename) || r.file3d_name || EMPTY_DATA_VALUE },
                { label: '2D File:', value: ((_b = r.file2d) === null || _b === void 0 ? void 0 : _b.filename) || r.file2d_name || EMPTY_DATA_VALUE },
                { label: 'Other Files:', value: r.other_files_name || EMPTY_DATA_VALUE },
                { label: 'Quantities:', value: ((_c = r.quantities) === null || _c === void 0 ? void 0 : _c.toString()) || EMPTY_DATA_VALUE },
                { label: 'Materials:', value: r.materials || EMPTY_DATA_VALUE },
                { label: 'Coating:', value: r.coating || EMPTY_DATA_VALUE },
                { label: 'Finish:', value: r.finish || EMPTY_DATA_VALUE },
                { label: 'Notes:', value: r.notes || EMPTY_DATA_VALUE },
                { label: 'Lead Time:', value: r.lead_time || EMPTY_DATA_VALUE },
                { label: 'Target Price:', value: r.target_price || EMPTY_DATA_VALUE },
                { label: 'Unit Price:', value: r.unit_price || EMPTY_DATA_VALUE },
            ].map(function (_a, index) {
                var label = _a.label, value = _a.value;
                return (React.createElement("div", { key: "pi-".concat(index + 1), className: "product-item", style: {
                        margin: 2,
                        flex: '1 1 140px',
                        fontSize: '12px',
                    } },
                    React.createElement("strong", { className: "product-item-label", style: {
                            marginRight: 2,
                        } }, label),
                    React.createElement("span", null, value)));
            })));
        }),
        React.createElement("div", { className: 'py-4' },
            React.createElement("h1", { className: 'font-bold' }, "Additional Files")),
        React.createElement("ul", null,
            React.createElement("li", null, ((_b = rfq.quote.additional_files) === null || _b === void 0 ? void 0 : _b.length) ? ((_c = rfq.quote.additional_files) === null || _c === void 0 ? void 0 : _c.map(function (f) { return (React.createElement("div", { key: f.id },
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: f.file }, f.filename))); })) : (React.createElement("div", null, "None"))))));
});
