import { BASE_URL_WS } from '@partsbadger/utils';
import React, { useEffect } from 'react';
import QuoteStore from '../stores/QuoteStore';
import { observer } from 'mobx-react';
import ReconnectingWebSocket from 'reconnecting-websocket';
var SocketConnection = function () {
    var quote = QuoteStore.quote, token = QuoteStore.token, isConnectedToSocket = QuoteStore.isConnectedToSocket;
    useEffect(function () {
        // Send the token on the cookie hackie option
        document.cookie = "X-Authorization=".concat(token, "; path=/");
        var url = "".concat(BASE_URL_WS, "/ws/instant-quote/").concat(quote === null || quote === void 0 ? void 0 : quote.uid, "/");
        var ws = new ReconnectingWebSocket(url, [], {
            maxRetries: 50,
        });
        ws.onopen = function () {
            QuoteStore.setConnectedSocket(true);
        };
        ws.onmessage = function (message) {
            try {
                var data = JSON.parse(message.data);
                if (data.type === 'updated.quote_line_item') {
                    QuoteStore.setLineItem(JSON.parse(data.message));
                }
                if (data.type == 'updated_part') {
                    QuoteStore.setProduct(JSON.parse(data.message));
                }
            }
            catch (err) {
                console.error('SocketConnection Error - Message.data content:', message === null || message === void 0 ? void 0 : message.data, 'Origin Error:', err);
            }
        };
        ws.onclose = function () {
            QuoteStore.setConnectedSocket(false);
        };
        ws.onerror = function (error) {
            ws.close();
        };
        return function () {
            if (ws) {
                ws.close();
            }
        };
    }, []);
    return (React.createElement("div", { style: {
            fontSize: 18,
            marginRight: 10,
            color: isConnectedToSocket ? '#32CD32' : 'black',
        } }, "\u2022"));
};
export default observer(SocketConnection);
