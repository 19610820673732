import { action, makeObservable, observable, runInAction } from 'mobx';
import { get } from '@partsbadger/utils';

class ContentTypeStore {
    isLoading = true;
    contentTypes: {
        id: number;
        model: string;
    }[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            contentTypes: observable,

            // Actions
            getAll: action,
        });
    }

    getAll() {
        this.isLoading = true;

        return get(`/staff/business-rules/content-types/`)
            .then(data => {
                runInAction(() => {
                    this.contentTypes = data;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }
}

export default new ContentTypeStore();
