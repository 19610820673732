import { QuoteStore } from '../../Stores';
import { SAFE_MATERIALS } from '../Select/Materials';
var formula = false;
var Formula26Safe = function (formula_val, master_product_id, product_material_label, totalVol, partVol) {
    if (formula_val == '2.6' && product_material_label) {
        if (totalVol && partVol) {
            formula = totalVol < 90 && partVol < 30 && SAFE_MATERIALS.includes(product_material_label);
        }
        else if (master_product_id) {
            var mp = QuoteStore.masterProductsByPosition.find(function (mp) { return mp.master_product.id === master_product_id; });
            if (mp && mp.master_product) {
                var totalVol_1 = mp.master_product.total_volume_inches;
                var partVol_1 = mp.master_product.part_volume_inches;
                if (totalVol_1 && partVol_1) {
                    formula = totalVol_1 < 90 && partVol_1 < 30 && SAFE_MATERIALS.includes(product_material_label);
                }
            }
        }
    }
    return formula_val == '2.6' && formula ? ' ✓' : '';
};
export default Formula26Safe;
