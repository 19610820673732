var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import { Progress } from 'antd';
var DroppableZone = function (props) {
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(TargetBox, __assign({}, props))));
};
export var TargetBox = function (props) {
    var _a = useState(false), isUploading = _a[0], setIsUploading = _a[1];
    var onDrop = props.onDrop;
    var _b = useDrop(function () { return ({
        accept: [NativeTypes.FILE],
        drop: function (item) {
            if (onDrop) {
                setIsUploading(true);
                item.files.forEach(function (f) {
                    onDrop(f).finally(function () { return setIsUploading(false); });
                });
            }
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }); },
    }); }, [props]), _c = _b[0], canDrop = _c.canDrop, isOver = _c.isOver, drop = _b[1];
    var isActive = canDrop && isOver;
    var style = {
        border: '1px dashed gray',
        display: 'inline',
        padding: isActive ? 40 : 10,
        borderColor: isActive ? 'green' : '#d4d4d4',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: drop, style: style },
            isActive ? 'Release to drop' : props.children,
            isUploading === true && React.createElement(Progress, { style: { width: 100 }, percent: 30, size: "small" }))));
};
export default DroppableZone;
