var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useState } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Alert, Spin, Tabs } from 'antd';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SortFiles } from '@partsbadger/one-true-form/lib/components/SortFiles';
import { PartSlot } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
var TabPane = Tabs.TabPane;
var style = {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    height: 'auto',
    border: '1px #000 dashed',
};
var ItemInfo = function (_a) {
    var index = _a.index, line_item = _a.line_item, type = _a.type, isDropped = _a.isDropped;
    var _b = useDrag(function () { return ({
        type: type,
        item: { index: index, line_item: line_item, type: type },
    }); }, [line_item]), collected = _b[0], drag = _b[1];
    return (React.createElement("div", { className: 'draggable-item', ref: drag, style: __assign({}, style) }, isDropped ? (React.createElement("span", null)) : (React.createElement("div", { className: "flex flex-row" },
        React.createElement(DragOutlined, { className: "ml-auto" }),
        type === '3D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === '2D File' && React.createElement("div", { className: "mx-2" }, line_item.filename),
        type === 'Additional File' && React.createElement("div", { className: "mx-2" }, line_item.filename)))));
};
export var ReArrangeFiles = observer(function (_a) {
    var quote_id = _a.quote_id;
    var _b = useState([]), records = _b[0], setRecords = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        setRecords(QuoteStore.master_products_with_replace_files);
    }, [QuoteStore.masterProductsByPosition]);
    var moveStepFiles = useCallback(function (dragIndex, hoverIndex) {
        setLoading(true);
        var clonedRecords = Array.from(records);
        var item_drag = clonedRecords[dragIndex].step_file;
        var item_hover = clonedRecords[hoverIndex].step_file;
        var data_drag = clonedRecords[dragIndex];
        var data_hover = clonedRecords[hoverIndex];
        clonedRecords[dragIndex].step_file = item_hover;
        clonedRecords[hoverIndex].step_file = item_drag;
        clonedRecords[dragIndex].replace_3d_data = {
            shape: data_hover.shape,
            unit: data_hover.unit,
            length: data_hover.length,
            width: data_hover.width,
            height: data_hover.height,
            inner_diameter: data_hover.inner_diameter,
            volume: data_hover.volume,
            volume_3d_printing: data_hover.volume_3d_printing,
            part_volume_percentage: data_hover.part_volume_percentage,
            itar: data_hover.itar,
        };
        clonedRecords[hoverIndex].replace_3d_data = {
            shape: data_drag.shape,
            unit: data_drag.unit,
            length: data_drag.length,
            width: data_drag.width,
            height: data_drag.height,
            inner_diameter: data_drag.inner_diameter,
            volume: data_drag.volume,
            volume_3d_printing: data_drag.volume_3d_printing,
            part_volume_percentage: data_drag.part_volume_percentage,
            itar: data_drag.itar,
        };
        // const drag = clonedRecords[dragIndex];
        // const hover = clonedRecords[hoverIndex];
        // setRecords(
        //     update(records, {
        //         $splice: [
        //             [hoverIndex, 1, hover],
        //             [dragIndex, 1, drag],
        //         ],
        //     })
        // );
        QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(function () { return QuoteStore.getMasterProducts(quote_id); })
            .finally(function () { return setLoading(false); });
    }, [records]);
    var moveDrawingFiles = useCallback(function (dragIndex, hoverIndex) {
        setLoading(true);
        var clonedRecords = Array.from(records);
        var item_drag = clonedRecords[dragIndex].drawing_file;
        var item_hover = clonedRecords[hoverIndex].drawing_file;
        clonedRecords[dragIndex].drawing_file = item_hover;
        clonedRecords[hoverIndex].drawing_file = item_drag;
        QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
        QuoteStore.replaceFilesMasterProduct(quote_id)
            .then(function () { return QuoteStore.getMasterProducts(quote_id); })
            .finally(function () { return setLoading(false); });
    }, [records]);
    var moveAdditionalFiles = function (dragIndex, hoverIndex, item_moved) { return __awaiter(void 0, void 0, void 0, function () {
        var clonedRecords, clonedRecords, index_file, add_hover;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    if (!(item_moved.type == '2D File')) return [3 /*break*/, 2];
                    clonedRecords = Array.from(records);
                    clonedRecords[hoverIndex].other_files.push({
                        id: item_moved.line_item.id,
                        filename: item_moved.line_item.filename,
                        type: item_moved.type,
                    });
                    clonedRecords[dragIndex].drawing_file = null;
                    QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
                    // setRecords([]);
                    return [4 /*yield*/, QuoteStore.replaceFilesMasterProduct(quote_id)
                            .then(function () {
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .finally(function () { return setLoading(false); })];
                case 1:
                    // setRecords([]);
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    clonedRecords = Array.from(records);
                    index_file = clonedRecords[dragIndex].other_files
                        .map(function (file) {
                        return file.id;
                    })
                        .indexOf(item_moved.line_item.id);
                    clonedRecords[dragIndex].other_files.splice(index_file, 1);
                    add_hover = clonedRecords[hoverIndex].other_files.findIndex(function (file) { return file.id === item_moved.line_item.id; });
                    add_hover === -1 &&
                        clonedRecords[hoverIndex].other_files.push({
                            id: item_moved.line_item.id,
                            filename: item_moved.line_item.filename,
                        });
                    // setRecords([]);
                    QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
                    QuoteStore.replaceFilesMasterProduct(quote_id)
                        .then(function () { return QuoteStore.getMasterProducts(quote_id); })
                        .finally(function () { return setLoading(false); });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var moveAdditionalFilesToDrawingFile = function (dragIndex, hoverIndex, item_moved) { return __awaiter(void 0, void 0, void 0, function () {
        var clonedRecords, index_file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    clonedRecords = Array.from(records);
                    index_file = clonedRecords[dragIndex].other_files
                        .map(function (file) {
                        return file.id;
                    })
                        .indexOf(item_moved.line_item.id);
                    clonedRecords[hoverIndex].drawing_file = __assign(__assign({}, clonedRecords[dragIndex].other_files.splice(index_file, 1)[0]), { type: 'Additional File' });
                    QuoteStore.syncDataByReplaceMasterProductFiles(clonedRecords);
                    // setRecords([]);
                    return [4 /*yield*/, QuoteStore.replaceFilesMasterProduct(quote_id)
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, QuoteStore.getMasterProducts(quote_id)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .finally(function () { return setLoading(false); })];
                case 1:
                    // setRecords([]);
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var parts = records.map(function (part_item, index) {
        var file3d = part_item.step_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.step_file, type: '3D File' })) : null;
        var file2d = part_item.drawing_file ? (React.createElement(ItemInfo, { index: index, line_item: part_item.drawing_file, type: '2D File' })) : null;
        var fileOthers = part_item.other_files.length > 0
            ? part_item.other_files.map(function (item) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(ItemInfo, { index: index, line_item: item, type: 'Additional File' }),
                    React.createElement("br", null)));
            })
            : null;
        return (React.createElement(React.Fragment, null,
            React.createElement(Tabs, null,
                React.createElement(TabPane, { tab: [React.createElement("span", { key: "1" },
                            "Line item: ",
                            part_item.name)], key: "1" }, part_item.editable ? (React.createElement("div", { className: 'row-associate-parts', key: index, style: {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(217, 217, 217, 0.19)',
                        marginBottom: '5px',
                        padding: '10px',
                        position: 'relative',
                    } },
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: file3d, onDrop: function (item) {
                            moveStepFiles(item.index, index);
                        }, onDropNew: function () {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: file2d, onDrop: function (item) {
                            if (item.type === 'Additional File') {
                                moveAdditionalFilesToDrawingFile(item.index, index, item);
                            }
                            else {
                                moveDrawingFiles(item.index, index);
                            }
                        }, onDropNew: function () {
                            console.log('on drop new');
                        } }),
                    React.createElement(PartSlot, { index: index, accept: ['3D File', '2D File', 'Additional File'], newRecords: records, lastDroppedItem: fileOthers, onDrop: function (item) {
                            moveAdditionalFiles(item.index, index, item);
                        }, onDropNew: function () {
                            console.log('todo');
                        } }))) : (React.createElement("div", { className: 'flex justify-center' },
                    React.createElement(Alert, { style: { margin: 5, width: '350px' }, type: 'warning', className: "text-center", message: 'This master product is used in other quotes.' })))))));
    });
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Spin, { spinning: loading, tip: 'Moving file...' },
            React.createElement("div", null,
                React.createElement("div", { className: 'text-sm' }, "1. It is allowed to move files between the same type 3D, 2D and Additional."),
                React.createElement("div", { className: 'text-sm' }, "2. You can move files between 2D and Additional types. "),
                React.createElement("div", { className: 'text-sm' }, "3. You cannot move files from other types to the 3D type.")),
            React.createElement("br", null),
            React.createElement("div", { className: "flex flex-row", style: {
                    background: '#fafafa',
                } },
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: '3D File' })),
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: '2D File' })),
                React.createElement("div", { className: "flex-1 mr-auto text-center" },
                    React.createElement(SortFiles, { fileType: 'Additional File' }))),
            parts)));
});
