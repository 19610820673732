var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Col, Divider, Form, Row, Upload, Input, Radio, InputNumber, notification } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Contacts, Quotes } from '@partsbadger/library';
import { UploadOutlined } from '@ant-design/icons';
var formItemLayout = {
    labelCol: { md: 4, xs: 24 },
    wrapperCol: { md: 20, xs: 24 },
};
export var styleBody = {
    padding: '3px 0',
    textAlign: 'center',
    color: 'black',
};
var CreditCardSubmissionToolForm = function () {
    var _a, _b, _c, _d;
    var form = Form.useForm()[0];
    var _e = useState(0), total = _e[0], setTotal = _e[1];
    var account = SalesOrderStore.account, contact = SalesOrderStore.contact;
    var handleSubmit = function (values) {
        var _a, _b;
        var payload = {
            amount: values.amount,
            customer_email: values.customer_email,
            representative_email: values.representative_email,
            notes: values.notes,
            ship_cost: values.ship_cost,
            tax_cost: values.tax_cost,
            zip_code: values.zip_code,
            quotes: values.quotes,
            files: (values === null || values === void 0 ? void 0 : values.files) ? (_b = (_a = values === null || values === void 0 ? void 0 : values.files) === null || _a === void 0 ? void 0 : _a.fileList) === null || _b === void 0 ? void 0 : _b.map(function (f) { return f.originFileObj; }) : [],
        };
        SalesOrderStore.sendCreditCard(payload);
        openNotificationWithIcon('success');
        onReset();
    };
    var onFinishFailed = function (errorInfo) {
        openNotificationWithIcon('error');
    };
    var onReset = function () {
        form.setFieldsValue({
            quotes: [],
        });
        form.resetFields();
    };
    var onChangeContact = function () {
        setTotal(0);
        form.setFieldsValue({
            quotes: [],
            amount: 0,
            customer_email: '',
            representative_email: '',
            zip_code: '',
            files: [],
            notes: '',
        });
    };
    var openNotificationWithIcon = function (type) {
        if (type === 'success') {
            notification[type]({
                message: 'Credit Card Submission.',
                description: 'SUCCESS.',
            });
        }
        else {
            notification[type]({
                message: 'Credit Card Submission.',
                description: 'Please review the red-colored field.',
            });
        }
    };
    var validateNumberGreaterThan0 = function (rule, value, callback) {
        if (isNaN(value) || value == 0 || !value) {
            callback('Required');
        }
        callback();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, name: "Credit Card Form", className: 'sales-order-form', onFinish: handleSubmit, onFinishFailed: onFinishFailed },
            React.createElement("div", { className: 'row-order mt-5 m-auto' },
                React.createElement(Col, { sm: 24 },
                    React.createElement(Form.Item, __assign({ label: 'Contact', name: "contact" }, formItemLayout, { rules: [
                            {
                                required: true,
                                message: 'Please select a contact to start',
                            },
                        ] }),
                        React.createElement(Contacts, { account: (_a = SalesOrderStore === null || SalesOrderStore === void 0 ? void 0 : SalesOrderStore.account) === null || _a === void 0 ? void 0 : _a.id, onSelect: function (item) {
                                SalesOrderStore.getContactById(item === null || item === void 0 ? void 0 : item.key);
                            }, onChange: function () {
                                onChangeContact();
                            }, onDeselect: function () {
                                onChangeContact();
                            } })))),
            (contact === null || contact === void 0 ? void 0 : contact.id) && !(account === null || account === void 0 ? void 0 : account.id) && (React.createElement(Row, { className: 'row-order mt-5 text-center' },
                React.createElement(Alert, { type: 'error', showIcon: true, message: "This contact doesn't have an account related, please fix this before to continue!" }))),
            (contact === null || contact === void 0 ? void 0 : contact.id) && (account === null || account === void 0 ? void 0 : account.id) && (React.createElement(React.Fragment, null,
                form.setFieldsValue({
                    amount: 0,
                    customer_email: contact === null || contact === void 0 ? void 0 : contact.email,
                    representative_email: (_b = contact === null || contact === void 0 ? void 0 : contact.owner) === null || _b === void 0 ? void 0 : _b.email,
                    zip_code: (_d = (_c = contact === null || contact === void 0 ? void 0 : contact.default_addresses) === null || _c === void 0 ? void 0 : _c.shipping_address) === null || _d === void 0 ? void 0 : _d.zip_code,
                    files: [],
                    notes: '',
                    tax_cost: 1,
                    ship_cost: 1,
                }),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, __assign({ name: "quotes", label: 'Quote Number(s)' }, formItemLayout, { rules: [{ required: true, message: 'Please select a value!' }] }),
                            React.createElement(Quotes, { mode: 'multiple', account: account === null || account === void 0 ? void 0 : account.id, filters: {
                                    is_purchasable: true,
                                }, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                onSelect: function (item) {
                                    SalesOrderStore.getQuote(parseInt(item.key)).then(function (response) {
                                        var current_value = response.grand_total + total;
                                        setTotal(current_value);
                                        form.setFieldsValue({
                                            amount: current_value.toFixed(2),
                                        });
                                    });
                                }, 
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                onDeselect: function (item) {
                                    form.setFieldsValue({ amount: 0 });
                                    SalesOrderStore.getQuote(parseInt(item.key)).then(function (response) {
                                        var current_value = total - response.grand_total;
                                        setTotal(current_value);
                                        form.setFieldsValue({
                                            amount: current_value.toFixed(2),
                                        });
                                    });
                                } })))),
                React.createElement(Row, { className: 'row-order mt-5 text-left' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, __assign({ name: 'amount', label: "Confirm Amount to be charged", rules: [{ required: true }, { validator: validateNumberGreaterThan0 }] }, formItemLayout),
                            React.createElement(InputNumber, null)))),
                React.createElement(Row, { className: 'row-order mt-5 text-center' },
                    React.createElement(Col, { sm: 24 },
                        React.createElement(Form.Item, __assign({ name: 'customer_email', label: "Customer's Email Address", 
                            // initialValue={contact?.email}
                            rules: [{ required: true, type: 'email' }] }, formItemLayout),
                            React.createElement(Input, null)),
                        React.createElement(Form.Item, __assign({ name: 'representative_email', label: "Representative Email", 
                            // initialValue={contact?.owner?.email}
                            rules: [{ required: true, type: 'email' }] }, formItemLayout),
                            React.createElement(Input, null)))),
                React.createElement(Divider, null,
                    React.createElement("div", { className: 'title' }, "This needs to be THE Shipping information from the Customer for the invoice to have the correct Sales Tax on the invoice.")),
                React.createElement(Col, { sm: 24 },
                    React.createElement(Form.Item, __assign({ name: 'zip_code', label: "Shipping Zip Code", rules: [{ required: true }] }, formItemLayout),
                        React.createElement(Input, null)),
                    React.createElement(Form.Item, __assign({ name: 'ship_cost', label: "Add shipping cost?" }, formItemLayout),
                        React.createElement(Radio.Group, null,
                            React.createElement(Radio, { value: 1 }, "Yes"),
                            React.createElement(Radio, { value: 0 }, "No"))),
                    React.createElement(Form.Item, __assign({ name: 'tax_cost', label: "Add Tax cost?" }, formItemLayout),
                        React.createElement(Radio.Group, null,
                            React.createElement(Radio, { value: 1 }, "Yes"),
                            React.createElement(Radio, { value: 0 }, "No"))),
                    React.createElement(Form.Item, __assign({ name: 'files', label: "Upload Files" }, formItemLayout, { rules: [{ required: true }] }),
                        React.createElement(Upload, { multiple: true, accept: ".pdf", beforeUpload: function () { return false; } },
                            React.createElement(Button, { icon: React.createElement(UploadOutlined, null) }, "Click to Upload"))),
                    React.createElement(Form.Item, __assign({ name: 'notes', label: "Notes" }, formItemLayout),
                        React.createElement(Input.TextArea, null))),
                React.createElement(Col, { sm: 24, style: { textAlign: 'center' } },
                    React.createElement(Form.Item, __assign({}, formItemLayout),
                        React.createElement(Button, { type: "primary", htmlType: "submit" }, "Submit"))))))));
};
export default observer(CreditCardSubmissionToolForm);
