import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import Options from '../../OrderRequirements/Options';
import { get, post } from '../../../shared';
import { DocumentsAdditional } from './DocumentsAdditional';

const AccountRequirementForm = ({ form, account_id }) => {
    const [loading, setLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [defaultValues, setDefaultValues] = useState([]);

    const { getFieldDecorator } = form;

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);

                const data = values.options
                    .filter(item => item.requirement)
                    .map(item => {
                        return {
                            requirement: item.requirement,
                            value: item.value,
                            files: item.files ? item.files.map(f => f.id) : [],
                        };
                    });

                setIsSaved(false);

                post(`staff/accounts/${account_id}/create-additional-requirements/`, data)
                    .then(response => {
                        setLoading(false);
                        setIsSaved(true);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        });
    };

    useEffect(() => {
        get(`staff/accounts/${account_id}/additional-requirements/`)
            .then(response => {
                setDefaultValues(response.data);
            })
            .catch(e => console.error(e));
    }, [account_id]);

    const initialValue = defaultValues.map(item => {
        return {
            requirement: item.requirement?.id,
            value: item.value,
            files: item.files,
        };
    });

    const success = isSaved ? (
        <span>
            <CheckCircleOutlined style={{ color: '#52c41a' }} /> Saved!
        </span>
    ) : null;

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item md={12} style={{ padding: '3px 0' }}>
                {getFieldDecorator('options', {
                    initialValue: initialValue,
                })(
                    <Options
                        onChange={() => {
                            setIsSaved(false);
                        }}
                    />
                )}
            </Form.Item>
            <DocumentsAdditional account_id={account_id} />
            <Button htmlType={'submit'} type={'primary'} loading={loading}>
                Save
            </Button>{' '}
            {success}
        </Form>
    );
};

export default Form.create()(AccountRequirementForm);
