var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row, message, Spin } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
var ChecklistsView = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), salesRepChecklist = _b[0], setSalesRepChecklist = _b[1];
    var _c = useState([]), designEngineerChecklist = _c[0], setDesignEngineerChecklist = _c[1];
    var _d = useState([]), productionChecklist = _d[0], setProductionChecklist = _d[1];
    var _e = useState([]), pointPersonChecklist = _e[0], setPointPersonChecklist = _e[1];
    var _f = useState(), salesOrderName = _f[0], setSalesOrderName = _f[1];
    var params = useParams();
    var cols = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 6,
    };
    useEffect(function () {
        getChecklistsOrder();
    }, []);
    var getChecklistsOrder = function () {
        setLoading(true);
        SalesOrderStore.getChecklistsOrder(params.id)
            .then(function (response) {
            setSalesRepChecklist(response.sales_rep_checklist);
            setDesignEngineerChecklist(response.design_engineer_checklist);
            setProductionChecklist(response.production_checklist);
            setPointPersonChecklist(response.point_person_checklist);
            setSalesOrderName(response.name);
        })
            .catch(function (error) {
            message.error(error.message);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    return (React.createElement(Spin, { spinning: loading },
        React.createElement("h1", { className: "font-bold text-lg text-center" },
            "Checklists Big Order (",
            salesOrderName,
            ")"),
        salesRepChecklist && Array.isArray(salesRepChecklist) && (React.createElement(Card, { title: React.createElement("strong", null, "Sales Rep Checklist"), size: "small", className: "mt-2" },
            React.createElement(Row, { gutter: 4 },
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Topic")),
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Answer"))),
            salesRepChecklist.map(function (question) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, __assign({}, cols), question.question),
                        React.createElement(Col, __assign({}, cols), question.answer.confirm))));
            }))),
        designEngineerChecklist && Array.isArray(designEngineerChecklist) && (React.createElement(Card, { title: React.createElement("strong", null, "Design Engineer Checklist"), size: "small", className: "mt-2" },
            React.createElement(Row, { gutter: 4 },
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Topic")),
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Confirm Reviewed")),
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Mark for Additional Production Review?"))),
            designEngineerChecklist.map(function (question) {
                var _a;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, __assign({}, cols), question.question),
                        React.createElement(Col, __assign({}, cols), question.answer.confirm),
                        React.createElement(Col, __assign({}, cols), question.answer.mark === true
                            ? 'Yes'
                            : ((_a = question.answer) === null || _a === void 0 ? void 0 : _a.mark) === false
                                ? 'No'
                                : 'No'))));
            }))),
        pointPersonChecklist && Array.isArray(pointPersonChecklist) && (React.createElement(Card, { title: React.createElement("strong", null, "Point Person Checklist"), size: "small", className: "mt-2" },
            React.createElement(Row, { gutter: 4 },
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Topic")),
                React.createElement(Col, __assign({}, cols),
                    React.createElement("h1", { className: "font-bold" }, "Answer"))),
            pointPersonChecklist.map(function (question) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, __assign({}, cols), question.question),
                        React.createElement(Col, __assign({}, cols), question.answer.confirm))));
            })))));
};
export default ChecklistsView;
