import { useEffect } from 'react';
import { QuoteStore } from '../Stores';
import useSocketQuoteConnection from './useSocketQuoteConnection';
import { notification } from 'antd';
var QuoteSocket = function (props) {
    var token = localStorage.getItem('token') || '';
    var new_notification = useSocketQuoteConnection(props.quote_id, token).new_notification;
    useEffect(function () {
        var _a;
        if (new_notification) {
            var payload = new_notification.payload;
            if (new_notification.type === 'QUOTE') {
                QuoteStore.setQuote(payload);
                QuoteStore.setQuoteEditable(!payload.completed);
            }
            if (new_notification.type == 'MASTER_PRODUCT') {
                QuoteStore.setUpdatedMasterProduct(payload);
            }
            if (payload.modified_by) {
                if ((payload === null || payload === void 0 ? void 0 : payload.modified_by.id) !== props.user.id) {
                    notification.info({
                        key: 'quote-updated',
                        message: 'Updated quote by another user.',
                        description: "".concat((_a = payload.modified_by) === null || _a === void 0 ? void 0 : _a.fullname, " made a change to this quote"),
                        placement: 'bottomRight',
                        maxCount: 1,
                    });
                    QuoteStore.getMasterProducts(props.quote_id);
                    QuoteStore.getLineItems(props.quote_id);
                }
            }
        }
    }, [new_notification]);
    return null;
};
export default QuoteSocket;
