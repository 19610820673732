var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, observable, makeObservable } from 'mobx';
import { get, getFile, post, printSalesOrder, remove, update_domestic_order_status, patch } from '../utils/api';
import fileDownload from 'js-file-download';
import { openNotificationWithIcon } from './UIStore';
import { buildVendorBoxDataStructure } from '../utils/vendorBox';
var IntakeStore = /** @class */ (function () {
    function IntakeStore() {
        var _this = this;
        this.loading = false;
        this.location = 'PB';
        this.defaultValue = 0;
        this.loadingShippment = false;
        this.salesOrderDocuments = null;
        this.salesOrder = null;
        this.salesOrderLineItems = [];
        this.lineItemQuantities = {};
        this.refactorLineItemsQuantities = [];
        this.receiveOrShip = 'receive';
        this.contact = null;
        this.previousIntakes = [];
        this.previousOuttakes = [];
        this.activePreview = null;
        this.activePreviewLineItems = null;
        this.createBoxVisible = false;
        this.editBoxVisible = false;
        this.boxInfo = {};
        this.newQuadrant = null;
        this.domesticFinish = false;
        this.hardwareNeeded = false;
        this.pdSalesOrder = null;
        this.printIntakeOutake = false;
        this.vendorSalesOrders = [];
        this.outtake = null;
        this.rmaList = null;
        this.purchase_orders = [];
        this.vendorType = 'sales-order';
        this.setVendorType = function (vendorType) {
            _this.vendorType = vendorType;
        };
        this.setLocalNewQuantityIntakeLineItem = function (intakeId, lineItemId, quantity) {
            var _a;
            var newPreviousIntakes = _this.activePreviewLineItems ? __assign({}, _this.activePreviewLineItems) : null;
            var lineItemIndex = (_a = newPreviousIntakes === null || newPreviousIntakes === void 0 ? void 0 : newPreviousIntakes.lineitem_intakes) === null || _a === void 0 ? void 0 : _a.findIndex(function (obj) { return obj.id === lineItemId; });
            if (lineItemIndex !== undefined) {
                if (lineItemIndex < 0)
                    return;
                if (newPreviousIntakes === null || newPreviousIntakes === void 0 ? void 0 : newPreviousIntakes.lineitem_intakes) {
                    newPreviousIntakes.lineitem_intakes[lineItemIndex].quantity = quantity;
                    _this.activePreviewLineItems = newPreviousIntakes;
                }
            }
        };
        this.setLocation = function (loc) { return (_this.location = loc); };
        this.setDefaultValue = function () { return (_this.defaultValue = 0); };
        this.updateVendorQuantity = function (orderId, itemId, value) {
            var newVendorSalesOrders = _this.vendorSalesOrders ? __spreadArray([], _this.vendorSalesOrders, true) : [];
            var salesOrderIndex = newVendorSalesOrders.findIndex(function (obj) { return obj.order === orderId; });
            if (salesOrderIndex < 0)
                return;
            var lineItemIndex = newVendorSalesOrders[salesOrderIndex].items.findIndex(function (obj) { return obj.id === itemId; });
            if (lineItemIndex < 0)
                return;
            newVendorSalesOrders[salesOrderIndex].items[lineItemIndex].quantity = value;
            _this.vendorSalesOrders = newVendorSalesOrders;
        };
        this.setReceiveOrShip = function (val) {
            _this.activePreview = null;
            _this.lineItemQuantities = {};
            _this.receiveOrShip = val;
        };
        this.setCreateBoxVisible = function (yesOrNo, outtakeId) {
            _this.loadBoxInfo();
            _this.createBoxVisible = yesOrNo;
            _this.boxInfo['outtakeId'] = outtakeId;
        };
        this.setEditBoxVisible = function (yesOrNo, outtakeId) {
            var _a;
            if (outtakeId) {
                _this.outtake = (_a = _this.previousOuttakes) === null || _a === void 0 ? void 0 : _a.find(function (outtake) { return outtake.id === outtakeId; });
            }
            else {
                _this.outtake = undefined;
            }
            _this.editBoxVisible = yesOrNo;
        };
        this.setBoxInfo = function (key, val) {
            _this.boxInfo[key] = val;
        };
        this.getRMAList = function (params) { return __awaiter(_this, void 0, void 0, function () {
            var res, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, get('/staff/inventory/', params)];
                    case 1:
                        res = _b.sent();
                        _a = this;
                        return [4 /*yield*/, res.data.results];
                    case 2:
                        _a.rmaList = _b.sent();
                        return [2 /*return*/, res.data.results];
                }
            });
        }); };
        makeObservable(this, {
            // Observables
            loading: observable,
            location: observable,
            defaultValue: observable,
            loadingShippment: observable,
            salesOrderDocuments: observable,
            salesOrder: observable,
            salesOrderLineItems: observable,
            lineItemQuantities: observable,
            refactorLineItemsQuantities: observable,
            receiveOrShip: observable,
            contact: observable,
            previousIntakes: observable,
            previousOuttakes: observable,
            activePreview: observable,
            activePreviewLineItems: observable,
            createBoxVisible: observable,
            editBoxVisible: observable,
            boxInfo: observable,
            newQuadrant: observable,
            domesticFinish: observable,
            hardwareNeeded: observable,
            pdSalesOrder: observable,
            printIntakeOutake: observable,
            vendorSalesOrders: observable,
            outtake: observable,
            rmaList: observable,
            purchase_orders: observable,
            vendorType: observable,
            // Actions
            setVendorType: action,
            setLocation: action,
            setDefaultValue: action,
            updateVendorQuantity: action.bound,
            setReceiveOrShip: action,
            setPrintIntakeOuttake: action,
            setHardwareNeeded: action,
            setSalesOrderDocuments: action,
            updateDomesticOrderStatus: action,
            getOuttakeLineItems: action,
            getSalesOrder: action,
            loadSalesOrder: action,
            intakeSalesOrderVendorBox: action,
            refactorLineItems: action,
            intakeSalesOrder: action,
            outtakeSalesOrder: action,
            deleteIntakeSalesOrder: action,
            deleteOuttakeSalesOrder: action,
            setCreateBoxVisible: action,
            setEditBoxVisible: action,
            updateLineItemQuantity: action,
            updateLineItemRemote: action,
            saveStorageForAllItems: action,
            saveStorageForIDItem: action,
            IntakeOrOuttakeAllItems: action,
            OuttakeAllIntakeItems: action,
            setBoxInfo: action,
            createBox: action,
            printLabel: action,
            cancelShipment: action,
            editBox: action,
            setQuadrant: action.bound,
            printSalesOrder: action,
            setActivePreview: action,
            setActiveViewLineItems: action,
            downloadPdf: action,
            downloadKPI: action,
            downloadBoxes: action,
            loadBoxInfo: action,
            getRMAList: action,
            clearData: action.bound,
            getDocumentsPacketStatus: action,
            getPurchaseOrderForSalesOrder: action,
            setLocalNewQuantityIntakeLineItem: action,
        });
    }
    IntakeStore.prototype.setPrintIntakeOuttake = function (value) {
        this.printIntakeOutake = value;
    };
    IntakeStore.prototype.setHardwareNeeded = function (value) {
        this.hardwareNeeded = value;
    };
    IntakeStore.prototype.setSalesOrderDocuments = function (value) {
        this.salesOrderDocuments = value;
    };
    IntakeStore.prototype.updateDomesticOrderStatus = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        openNotificationWithIcon('info', 'Intaking Meow', '');
                        return [4 /*yield*/, update_domestic_order_status("/staff/sales-orders/".concat(id, "/update-domestic-order-status/"))];
                    case 1:
                        _a.sent();
                        openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
                        return [3 /*break*/, 3];
                    case 2:
                        _error_1 = _a.sent();
                        console.log(_error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.getOuttakeLineItems = function (outtakeId) {
        return __awaiter(this, void 0, void 0, function () {
            var results, _error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.vendorSalesOrders = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, get("/staff/lineitem-outtakes/?outtake_id=".concat(outtakeId))];
                    case 2:
                        results = (_a.sent()).data.results;
                        this.vendorSalesOrders = buildVendorBoxDataStructure(results);
                        return [3 /*break*/, 4];
                    case 3:
                        _error_2 = _a.sent();
                        console.log(_error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.getSalesOrder = function (soID, receiverOrShip) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.activePreview = null;
                        this.activePreviewLineItems = null;
                        return [4 /*yield*/, get("/staff/sales-orders/".concat(soID, "/")).then(function (response) {
                                var data = response.data, _a = response.data, line_items = _a.line_items, po_files = _a.po_files, domestic_process_needed = _a.domestic_process_needed, hardware_required = _a.hardware_required;
                                _this.salesOrder = data;
                                _this.lineItemQuantities = {};
                                _this.salesOrderLineItems = line_items;
                                _this.pdSalesOrder = data;
                                _this.salesOrderDocuments = po_files;
                                _this.domesticFinish = domestic_process_needed;
                                _this.hardwareNeeded = hardware_required;
                            })];
                    case 1:
                        _a.sent();
                        if (!(this.salesOrder.contact != null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, get("/staff/contacts/".concat(this.salesOrder.contact.id, "/")).then(function (response) {
                                _this.contact = response.data;
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [4 /*yield*/, get("/staff/intakes/?salesorder_id=".concat(this.salesOrder.id)).then(function (response) {
                            _this.previousIntakes = response.data.results;
                            _this.loading = false;
                        })];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, get("/staff/outtakes/?salesorder_id=".concat(this.salesOrder.id)).then(function (response) {
                                _this.previousOuttakes = response.data.results;
                                _this.loading = false;
                            })];
                    case 5:
                        _a.sent();
                        this.loadBoxInfo();
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.getPurchaseOrderForSalesOrder = function (soID) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/sales-orders/purchase_orders/?salesorder_id=".concat(soID)).then(function (response) {
                            _this.purchase_orders = response.data;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.loadSalesOrder = function (soID) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/salesorder-lineitems/by_so/?id=".concat(soID)).then(function (response) {
                            _this.lineItemQuantities = {};
                            _this.salesOrderLineItems = response.data.results;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.intakeSalesOrderVendorBox = function (_a, callback, print) {
        var quantities = _a.quantities, salesOrderId = _a.salesOrderId, customerReturn = _a.customerReturn, domestic_process_needed = _a.domestic_process_needed, quadrant = _a.quadrant, vendorOrderId = _a.vendorOrderId;
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        openNotificationWithIcon('info', 'Intaking Meow', '');
                        return [4 /*yield*/, post("/staff/sales-orders/".concat(salesOrderId, "/intake/"), {
                                order: salesOrderId,
                                quadrant: quadrant,
                                line_items: quantities,
                                location: customerReturn ? 'PB-CUSTOMER-RETURN' : 'PB',
                                domestic: domestic_process_needed,
                                print: print,
                            })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.getOuttakeLineItems(vendorOrderId)];
                    case 2:
                        _b.sent();
                        callback && callback();
                        openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.refactorLineItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var new_line_items;
            var _this = this;
            return __generator(this, function (_a) {
                new_line_items = [];
                Object.entries(this.lineItemQuantities).forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    _this.salesOrderLineItems.map(function (order_item) {
                        if (Number(order_item.id) === Number(key)) {
                            new_line_items.push({
                                id: order_item.id,
                                name: order_item.name,
                                quantity: Number(order_item.quantity_received),
                                quantity_remaining: Number(order_item.quantity_remaining_to_receive),
                                quantity_received: Number(value),
                                extra_quantity: Number(value) - Number(order_item.quantity_remaining_to_receive),
                                storage: order_item.storage_location,
                                save_in_inventory: true,
                                reason: 'General Extra Parts',
                            });
                        }
                    });
                });
                this.refactorLineItemsQuantities = new_line_items;
                return [2 /*return*/, new_line_items];
            });
        });
    };
    IntakeStore.prototype.intakeSalesOrder = function (excludeDomesticFinish, zIntake, inventory_order_items) {
        return __awaiter(this, void 0, void 0, function () {
            var quadrant, itemsWithFAA_1, new_line_items_1, payload, error_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        quadrant = this.salesOrder.quadrant;
                        if (!this.salesOrder) return [3 /*break*/, 6];
                        itemsWithFAA_1 = [];
                        new_line_items_1 = [];
                        Object.entries(this.lineItemQuantities).forEach(function (_a) {
                            var key = _a[0], value = _a[1];
                            _this.salesOrderLineItems.map(function (order_item) {
                                if (Number(order_item.id) === Number(key)) {
                                    new_line_items_1.push({
                                        id: order_item.id,
                                        quantity: Number(value) > order_item.quantity ? order_item.quantity : Number(value),
                                        storage: order_item.storage_location,
                                        extra_quantities: 0,
                                    });
                                    if (order_item.first_article_approval)
                                        itemsWithFAA_1.push(order_item.name);
                                }
                            });
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        payload = {
                            order: this.salesOrder.id,
                            quadrant: quadrant,
                            line_items: this.lineItemQuantities,
                            location: this.location,
                            domestic: excludeDomesticFinish ? false : this.domesticFinish,
                            print: this.printIntakeOutake,
                            inbound_destination: zIntake ? 'Z' : 'SZ',
                            inventory_order_items: inventory_order_items,
                        };
                        new_line_items_1.map(function (item) {
                            return inventory_order_items === null || inventory_order_items === void 0 ? void 0 : inventory_order_items.find(function (inventory_item) {
                                if (inventory_item.id === item.id) {
                                    item.extra_quantities = inventory_item.extra_quantity;
                                }
                            });
                        });
                        payload.line_items = new_line_items_1;
                        // const url_name = zIntake ? 'z-intake' : 'intake';
                        return [4 /*yield*/, post("/staff/sales-orders/".concat(this.salesOrder.id, "/intake/"), payload)];
                    case 2:
                        // const url_name = zIntake ? 'z-intake' : 'intake';
                        _a.sent();
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'receive')];
                    case 3:
                        _a.sent();
                        openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
                        if (itemsWithFAA_1.length) {
                            openNotificationWithIcon('warning', 'Warning', 'This product is marked for an FAA and may require shipment ASAP. Please review In House Requirements and the Required Ship Date', 0);
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 6];
                    case 5:
                        this.setPrintIntakeOuttake(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.outtakeSalesOrder = function (zOuttake, payload, poId) {
        return __awaiter(this, void 0, void 0, function () {
            var url_name, lastPayload, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.salesOrder) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        url_name = zOuttake ? 'z-outtake' : 'outtake';
                        lastPayload = __assign({ order: this.salesOrder.id, line_items: this.lineItemQuantities, print: this.printIntakeOutake }, payload);
                        if (poId && url_name === 'z-outtake') {
                            lastPayload['po_id'] = poId;
                        }
                        return [4 /*yield*/, post("/staff/sales-orders/".concat(this.salesOrder.id, "/").concat(url_name, "/"), lastPayload)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'receive')];
                    case 3:
                        _a.sent();
                        openNotificationWithIcon('success', 'Successfully', "Outtoke Some Parts Meow'");
                        return [3 /*break*/, 6];
                    case 4:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [3 /*break*/, 6];
                    case 5:
                        this.setPrintIntakeOuttake(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.deleteIntakeSalesOrder = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.salesOrder) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, remove("/staff/intakes/".concat(id, "/"))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'receive')];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_4 = _a.sent();
                        console.log(error_4);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.deleteOuttakeSalesOrder = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.salesOrder) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, remove("/staff/outtakes/".concat(id, "/"))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'receive')];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_5 = _a.sent();
                        console.log(error_5);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.updateLineItemQuantity = function (id, quantity) {
        var _a;
        this.lineItemQuantities = __assign(__assign({}, this.lineItemQuantities), (_a = {}, _a[id] = quantity, _a));
    };
    IntakeStore.prototype.updateLineItemRemote = function (payload, id) {
        if (payload === void 0) { payload = {}; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, patch("/staff/lineitem-intakes/".concat(id, "/"), payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.saveStorageForAllItems = function (storage) {
        this.salesOrderLineItems = this.salesOrderLineItems.map(function (order_item) {
            order_item.storage_location = storage;
            return order_item;
        });
    };
    IntakeStore.prototype.saveStorageForIDItem = function (id, storage) {
        this.salesOrderLineItems = this.salesOrderLineItems.map(function (order_item) {
            if (order_item.id === id) {
                order_item.storage_location = storage;
            }
            return order_item;
        });
    };
    IntakeStore.prototype.IntakeOrOuttakeAllItems = function () {
        var _this = this;
        this.lineItemQuantities = {};
        var items = this.salesOrderLineItems.map(function (item) {
            var _a;
            var remaining = _this.receiveOrShip === 'receive' ? 'quantity_remaining_to_receive' : 'quantity_remaining_to_ship';
            _this.lineItemQuantities = __assign(__assign({}, _this.lineItemQuantities), (_a = {}, _a[item.id] = item[remaining], _a));
        });
    };
    IntakeStore.prototype.OuttakeAllIntakeItems = function () {
        var _this = this;
        this.lineItemQuantities = {};
        var items = this.salesOrderLineItems.map(function (item) {
            var _a;
            var quantity_remaining_to_receive = item['quantity_remaining_to_receive'];
            var quantity_remaining_to_ship = item['quantity_remaining_to_ship'];
            var result = quantity_remaining_to_ship - quantity_remaining_to_receive;
            _this.lineItemQuantities = __assign(__assign({}, _this.lineItemQuantities), (_a = {}, _a[item.id] = result, _a));
        });
    };
    IntakeStore.prototype.createBox = function (boxInfo) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/outtakes/".concat(this.boxInfo['outtakeId'], "/create_box/"), __assign({}, boxInfo))];
                    case 1:
                        _a.sent();
                        this.setCreateBoxVisible(false, this.boxInfo['outtakeId']);
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'ship')];
                    case 2:
                        _a.sent();
                        openNotificationWithIcon('success', 'Successfully', 'Created box');
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.printLabel = function (outtake_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/outtakes/".concat(outtake_id, "/print-label/"))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.cancelShipment = function (outtake_id, tracking_number) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingShippment = true;
                        return [4 /*yield*/, post("/staff/outtakes/".concat(outtake_id, "/cancel-shipment/"), {
                                tracking_number: tracking_number,
                            })];
                    case 1:
                        _a.sent();
                        this.getSalesOrder(this.salesOrder.id, 'ship');
                        openNotificationWithIcon('success', 'Successfully', 'Shipment canceled');
                        this.loadingShippment = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.editBox = function (outtake_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, post("/staff/outtakes/".concat(outtake_id, "/edit_box/"), payload)];
                    case 1:
                        _a.sent();
                        this.setEditBoxVisible(false);
                        return [4 /*yield*/, this.getSalesOrder(this.salesOrder.id, 'ship')];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.setQuadrant = function (quadrant) {
        this.salesOrder.quadrant = quadrant;
    };
    IntakeStore.prototype.printSalesOrder = function (instanceID, historyType, template) {
        if (template === void 0) { template = 'packing-slip.html'; }
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        openNotificationWithIcon('info', 'Printing Meow', '');
                        if (!(template == 'packing-slip-boxes.html')) return [3 /*break*/, 2];
                        return [4 /*yield*/, printSalesOrder("/staff/".concat(historyType, "/").concat(instanceID, "/printout_boxes/"))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, printSalesOrder("/staff/".concat(historyType, "/").concat(instanceID, "/printout/"))];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
                        return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        console.log(err_2);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        openNotificationWithIcon('danger', 'Error', "".concat(err_2.status, " - ").concat(err_2.statusText));
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.setActivePreview = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.activePreviewLineItems = null;
                        return [4 /*yield*/, get(url)];
                    case 1:
                        response = _a.sent();
                        this.activePreview = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.setActiveViewLineItems = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.activePreview = null;
                        return [4 /*yield*/, get(url)];
                    case 1:
                        response = _a.sent();
                        this.activePreviewLineItems = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.downloadPdf = function (url, name_file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                openNotificationWithIcon('info', 'Generating file', 'Be patient');
                getFile(url)
                    .then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var myBlob, myJson;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response.ok) return [3 /*break*/, 2];
                                return [4 /*yield*/, response.blob()];
                            case 1:
                                myBlob = _a.sent();
                                return [2 /*return*/, {
                                        blob: myBlob,
                                    }];
                            case 2: return [4 /*yield*/, response.json()];
                            case 3:
                                myJson = _a.sent();
                                return [2 /*return*/, {
                                        json: myJson,
                                    }];
                        }
                    });
                }); })
                    .then(function (data) {
                    if (data.blob) {
                        fileDownload(data.blob, 'PrintOut.pdf');
                        openNotificationWithIcon('success', 'Downloaded', 'PDF file');
                    }
                    else {
                        openNotificationWithIcon('error', "There's no documents approved", '');
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    IntakeStore.prototype.downloadKPI = function () {
        return __awaiter(this, void 0, void 0, function () {
            var csv, bytes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        openNotificationWithIcon('info', 'Generating report', 'Be patient');
                        return [4 /*yield*/, getFile('/staff/reports/fullfillment_kpi/')];
                    case 1:
                        csv = _a.sent();
                        return [4 /*yield*/, csv.blob()];
                    case 2:
                        bytes = _a.sent();
                        fileDownload(bytes, 'fullfillment-kpi.csv');
                        openNotificationWithIcon('success', 'Downloaded', 'KPI CSV file');
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.downloadBoxes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var csv, bytes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        openNotificationWithIcon('info', 'Generating report', 'Be patient');
                        return [4 /*yield*/, getFile('/staff/reports/boxes/')];
                    case 1:
                        csv = _a.sent();
                        return [4 /*yield*/, csv.blob()];
                    case 2:
                        bytes = _a.sent();
                        fileDownload(bytes, 'boxes.csv');
                        openNotificationWithIcon('success', 'Downloaded', 'Boxes CSV file');
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.loadBoxInfo = function () {
        this.boxInfo = {};
        this.boxInfo = {
            carrier: this.salesOrder.carrier != null ? this.salesOrder.carrier : 'FedEX',
            shipping_account: this.salesOrder.shipping_account_number != null ? this.salesOrder.shipping_account_number : 'PB',
            shipping_method: this.salesOrder.shipping_method != null ? this.salesOrder.shipping_method : 'Ground',
            // shipping_cost: this.salesOrder.shipping_cost != null ? this.salesOrder.shipping_cost : 0,
            shipping_prepaid: this.salesOrder.shipping_prepaid != null ? this.salesOrder.shipping_prepaid : false,
            bill_to_customer: this.salesOrder.bill_to_customer != null ? this.salesOrder.bill_to_customer : false,
            shipping_cost: 0,
            // tracking_number: '',
            // weight: '0',
            // size: '0',
            label: '',
            notes: '',
        };
    };
    IntakeStore.prototype.clearData = function () {
        this.salesOrderLineItems = [];
        this.salesOrderDocuments = null;
        this.domesticFinish = false;
        this.hardwareNeeded = false;
        this.pdSalesOrder = null;
        this.salesOrder = null;
        this.previousIntakes = null;
        this.previousOuttakes = null;
        this.rmaList = null;
    };
    IntakeStore.prototype.getDocumentsPacketStatus = function (sales_order_id) {
        return get("/staff/sales-orders/".concat(sales_order_id, "/documents-packet-status/"));
    };
    IntakeStore.prototype.printIventory = function (sales_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        openNotificationWithIcon('info', 'Printing Meow', '');
                        return [4 /*yield*/, get("/staff/sales-orders/".concat(sales_order_id, "/print-inventory/"))];
                    case 1:
                        _a.sent();
                        openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
                        return [2 /*return*/];
                }
            });
        });
    };
    IntakeStore.prototype.multipleOrdersIntake = function (payload, print) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/sales-orders/multiple-intake/", { data: payload, print: print })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return IntakeStore;
}());
export default new IntakeStore();
