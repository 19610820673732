import React, { useEffect, useState } from 'react';
import { get, post } from '../../shared';
import { Button, Card, DatePicker, message, Table } from 'antd';
import moment, { Moment } from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

const OrderReportsToday = () => {
    const [loading, setLoading] = useState(false);

    const [todayIntakes, setTodayIntakes] = useState<Array<any>>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();

    useEffect(() => {
        setLoading(true);
        loadData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (selectedDate) {
            // getSalesOrderMusToShip().finally(() => setLoading(false));
            // getSalesOrdersBatchShipment().finally(() => setLoading(false));
        }
    }, [selectedDate]);

    const loadData = async () => {
        await getSalesOrderIntakesToday();
    };

    const getSalesOrderIntakesToday = async () => {
        const response = await get(`staff/report-intakes-today/`);
        setTodayIntakes(response.data.results);
    };

    const notify_shipping_team = async (record: number) => {
        const response = await post(`staff/sales-orders/${record}/notify_shipping_team/`, {}).finally(() =>
            message.success('Notification sent')
        );
    };
    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_ship_date',
            key: 'required_ship_date',
        },
        {
            title: 'Hot Order',
            dataIndex: 'hot_order',
            key: 'hot_order',
            render: (t: string, r: any) => {
                return r.hot_order ? <CheckCircleOutlined /> : '';
            },
        },
        {
            title: 'Today Intakes',
            dataIndex: 'hot_order',
            key: 'hot_order',
            render: (t: string, r: any) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            notify_shipping_team(r.id);
                        }}
                    >
                        Request Ship today
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Card>
                <div className={'flex flex-row justify-between items-center'}>
                    <div className={'w-full'}>
                        <Card title={'Today Intakes'}>
                            <Table
                                key={'today-intakes'}
                                loading={loading}
                                columns={columns}
                                dataSource={todayIntakes}
                                pagination={false}
                            />
                        </Card>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default OrderReportsToday;
