import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { QuoteStore } from '../Stores';
import { UploadFile } from '@partsbadger/library';
import { isNumber } from 'lodash';
export var AddFrom2dFile = function () {
    var _a = useState(1), number = _a[0], setNumber = _a[1];
    return (React.createElement("div", { style: { minWidth: '200px', padding: 20 } },
        React.createElement("div", { className: 'mb-2' },
            React.createElement("label", { className: 'mx-2' }, "Number of products to be created:"),
            React.createElement(InputNumber, { autoFocus: true, onChange: function (number) {
                    if (isNumber(number)) {
                        setNumber(number);
                    }
                    else {
                        setNumber(1);
                    }
                }, style: { width: '60px' }, size: "small", defaultValue: number, min: 1 })),
        React.createElement(UploadFile, { multiple: true, fileType: '2D', onUpload: function (file) {
                if (number >= 1) {
                    for (var i = 1; i <= number; i++) {
                        QuoteStore.createLineItemFrom2dFile(file.response.id);
                    }
                    setNumber(1);
                }
            } })));
};
