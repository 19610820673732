var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Collapse } from 'antd';
import React from 'react';
import PillSelect from '../../common/PillSelect';
var Panel = Collapse.Panel;
export var VendorQualifications = function (props) {
    var groupingQualifications = Object.values(props.data.reduce(function (acc, current) {
        var _a;
        acc[current.category_name] = (_a = acc[current.category_name]) !== null && _a !== void 0 ? _a : [];
        acc[current.category_name].push(current);
        return acc;
    }, {}));
    return (React.createElement(Collapse, null, groupingQualifications.map(function (group, index) { return (React.createElement(Panel, { key: index, header: group[0].category_name },
        React.createElement(PillSelect, __assign({}, props, { data: group })))); })));
};
