import React, { useEffect, useState } from 'react';
import { get } from '../../shared';
import { SalesOrderProps } from '@partsbadger/sales_order_tool/lib/types/types';
import { Card, DatePicker, Table, Row, Col, Button, Divider, Tag, Checkbox } from 'antd';
import { Form } from '@ant-design/compatible';
import { DateRangePicker } from '@partsbadger/library';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import moment, { Moment } from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const OrderReports = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const { form } = props;
    const [salesOrdersMustToShip, setSalesOrdersMustToShip] = useState<Array<SalesOrderProps>>([]);
    const [yesterdayIntakes, setYesterdayIntakes] = useState<Array<any>>([]);
    const [yesterdayOuttakes, setYesterdayOuttakes] = useState<Array<any>>([]);
    const [salesordersBatchShipment, setSalesOrdersBatchShipment] = useState<Array<SalesOrderProps>>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();

    const { getFieldDecorator } = form;

    useEffect(() => {
        setLoading(true);
        setSelectedDate(moment());
        loadData().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (selectedDate) {
            getSalesOrderMusToShip().finally(() => setLoading(false));
            getSalesOrdersBatchShipment().finally(() => setLoading(false));
        }
    }, [selectedDate]);

    const loadData = async () => {
        if (selectedDate) {
            await getSalesOrderMusToShip();
        }
    };

    const getSalesOrderMusToShip = async () => {
        const response = await get(`staff/report-must-to-ship/`, {
            created_time_min: selectedDate?.format('YYYY-MM-DD'),
            created_time_max: selectedDate?.format('YYYY-MM-DD'),
        });
        setSalesOrdersMustToShip(response.data.results);
    };

    const getSalesOrdersBatchShipment = async () => {
        const response = await get(`staff/batch-shipment/`, {
            created_time_min: selectedDate?.format('YYYY-MM-DD'),
            created_time_max: selectedDate?.format('YYYY-MM-DD'),
        });
        setSalesOrdersBatchShipment(response.data.results);
    };

    const columns = [
        {
            title: 'Sales Order',
            // dataIndex: 'name',
            key: 'name',
            render: (t: string, r: any) => {
                const shipping_url = `https://quotes.parts-badger.com/shipping-receiving/?sales-order=${r.sales_order}`;

                return (
                    // Cambiar el link a prod
                    <a href={shipping_url} target="_blank" rel="noreferrer">
                        {r.sales_order_name}
                    </a>
                );
            },
        },
        {
            title: 'Account name',
            dataIndex: 'account_name',
            key: 'account_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Required Part Ship Date',
            dataIndex: 'required_part_ship_date',
            key: 'required_part_ship_date',
        },
        {
            title: 'Part Tags',
            key: 'part_tags',
            render: (t: string, r: any) => {
                return (
                    <>
                        {r.hardware_required && <Tag>Hardware Required</Tag>}
                        {r.domestic_process_needed && <Tag>Domestic Process needed</Tag>}
                    </>
                );
            },
        },
        {
            title: 'In Z',
            dataIndex: 'in_z',
            key: 'in_z',
        },
        {
            title: 'Hot Order',
            dataIndex: 'hot_order',
            key: 'hot_order',
            render: (t: string, r: any) => {
                return r.hot_order ? <CheckCircleOutlined /> : '';
            },
        },
    ];

    const columnsShipment = [
        {
            title: 'Subject',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Next Batch Ship Date',
            dataIndex: 'revised_ship_date',
            key: 'revised_ship_date',
        },
        {
            title: 'Order Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const getData = async (params: { created_time_min: string; created_time_max: string; report_type: string }) => {
        setLoading(true);
        try {
            const response = await get(`staff/report-must-to-ship/`, params);
            setSalesOrdersMustToShip(response.data.results);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getDataBatch = async (params: { created_time_min: string; created_time_max: string }) => {
        setLoading(true);
        try {
            const response = await get(`staff/batch-shipment/`, params);
            setSalesOrdersMustToShip(response.data.results);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                    report_type: 'json',
                    required_part_ship_date: false,
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                if (values.required_part_ship_date) {
                    params['required_part_ship_date'] = true;
                }
                getData(params);
                getDataBatch(params);
            }
        });
    };

    const handleDownload = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                    required_part_ship_date: false,
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                if (values.required_part_ship_date) {
                    params['required_part_ship_date'] = true;
                }

                downloadCsv(params);
            }
        });
    };

    const downloadCsv = async (params: { created_time_min: string; created_time_max: string }) => {
        setLoading(true);
        try {
            const response = await get(`staff/report-must-to-ship/download-report/`, params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'z-outtakes.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>
                        <Col style={{ paddingLeft: 10 }}>
                            <FormItem>
                                {getFieldDecorator('required_part_ship_date', {})(<Checkbox>Past due</Checkbox>)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>

                        <Col>
                            <Button onClick={handleDownload}>Download CSV</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Divider></Divider>

            <Card title={'Must Ship Today'}>
                <div className={'w-full'}>
                    <Table
                        key={'today'}
                        columns={[...columns]}
                        loading={loading}
                        dataSource={salesOrdersMustToShip}
                        pagination={false}
                    />
                </div>
            </Card>
            <>
                <Card title={'Batch Shipment'}>
                    <div className={'w-full'}>
                        <Table
                            key={'must-ship-today'}
                            columns={columnsShipment}
                            loading={loading}
                            dataSource={salesordersBatchShipment}
                            pagination={false}
                        />
                    </div>
                </Card>
            </>
        </>
    );
};

export const OrderReportsForm = Form.create()(OrderReports);
