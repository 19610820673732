import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message } from 'antd';
import { Price } from '@partsbadger/library';
var QuotedQuantity = function (props) {
    var _a = useState(false), addingItem = _a[0], setAddingItem = _a[1];
    var quantity = props.quantity, unit_price = props.unit_price, total_price = props.total_price;
    return (React.createElement(React.Fragment, null,
        React.createElement("td", { className: "ant-table-cell" }, quantity),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Price, { value: unit_price })),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Price, { value: total_price })),
        React.createElement("td", { className: "ant-table-cell" },
            React.createElement(Button, { size: 'small', style: {
                    color: 'green',
                }, loading: addingItem, onClick: function () {
                    setAddingItem(true);
                    props
                        .onClick()
                        .then(function () {
                        message.success('Item Added!');
                    })
                        .finally(function () { return setAddingItem(false); })
                        .catch(function () {
                        message.error('Something went wrong!');
                    });
                } },
                React.createElement(LegacyIcon, { type: 'plus' }),
                " Add To Order"))));
};
export default QuotedQuantity;
