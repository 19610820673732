import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Row, Switch } from 'antd';
import { UploadFile } from '@partsbadger/library';
import { NDAForm } from '../../../../stores/types';
import { FormComponentProps } from '@ant-design/compatible/es/form';

interface Props extends FormComponentProps {
    record?: NDAForm;
    handleSave: (payload: any) => Promise<void>;
}

const AddNDAForm = ({ form, record, handleSave }: Props) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const { getFieldDecorator } = form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const handleSubmit = () => {
        form.validateFields((err: null | string, values) => {
            if (!err) {
                setLoading(true);

                const payload = {
                    id: record?.id ?? undefined,
                    name: values.name,
                    company_name: values.company_name,
                    email: values.email,
                    signed: values.signed,
                    files: values.files?.map((f: any) => f.response.id),
                };

                handleSave(payload).finally(() => {
                    setLoading(false);
                    setVisible(false);
                });
            }
        });
    };

    return (
        <>
            <Row>
                <Form.Item>
                    <Button style={{ borderRadius: '5px' }} onClick={() => setVisible(!visible)} loading={loading}>
                        {record ? 'Edit' : 'Add NDA Form'}
                    </Button>
                </Form.Item>
            </Row>
            <Modal
                visible={visible}
                title="Add/Edit NDA"
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Close
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
            >
                {visible && (
                    <Form {...formItemLayout} layout="horizontal">
                        <Form.Item label="Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input your Name!' }],
                                initialValue: record?.name,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>

                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your Email!' }],
                                initialValue: record?.email,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>

                        <Form.Item label="Company name">
                            {getFieldDecorator('company_name', {
                                rules: [{ required: true, message: 'Please input your company name!' }],
                                initialValue: record?.company_name,
                            })(<Input style={{ width: 300 }} />)}
                        </Form.Item>

                        {record ? (
                            <Form.Item label="Signed">
                                {getFieldDecorator('signed', {
                                    rules: [{ required: true, message: 'Please input your company name!' }],
                                    initialValue: record?.signed || false,
                                    valuePropName: 'checked',
                                })(<Switch defaultChecked />)}
                            </Form.Item>
                        ) : (
                            <></>
                        )}

                        <Form.Item label="Files">
                            {getFieldDecorator('files', {
                                rules: [{ required: true, message: 'Please input a file' }],
                                initialValue: record?.related_files.map(r => {
                                    return {
                                        uid: r.id,
                                        name: r.filename,
                                        status: 'done',
                                        response: {
                                            id: r.id,
                                        },
                                        url: r.file,
                                    };
                                }),
                            })(<UploadFile renderAs={'button'} />)}
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default Form.create<Props>()(AddNDAForm);
