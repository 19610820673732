var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Badge } from 'antd';
var Stages = /** @class */ (function (_super) {
    __extends(Stages, _super);
    function Stages() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Stages.prototype.render = function () {
        if (this.props.stage === 'Needs Review') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'red' } });
        }
        if (this.props.stage === 'Pending' || this.props.stage === 'Quoting' || this.props.stage === 'In Queue') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'grey' } });
        }
        if (this.props.stage === 'In Progress') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'blue' } });
        }
        if (this.props.stage === 'Cancelled') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: '#000' } });
        }
        if (this.props.stage === 'Unquoted') {
            return React.createElement(Badge, { count: 'No Quoted', style: { backgroundColor: '#000' } });
        }
        if (this.props.stage === 'Reviewed') {
            return (React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'yellow', color: 'rgba(0, 0, 0, 0.45)' } }));
        }
        if (this.props.stage === 'Completed') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'green' } });
        }
        if (this.props.stage === 'Closed Won') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'green' } });
        }
        if (this.props.stage === 'Closed Lost') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'black' } });
        }
        if (this.props.stage === 'Vendor Quoting') {
            return React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'rgb(220, 108, 126)' } });
        }
        if (this.props.stage === 'Vendor Quote Received') {
            return (React.createElement(Badge, { count: this.props.stage, style: { backgroundColor: 'yellow', color: 'rgba(0, 0, 0, 0.45)' } }));
        }
        return this.props.stage;
    };
    return Stages;
}(React.Component));
export default Stages;
