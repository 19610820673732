import { makeAutoObservable, runInAction } from 'mobx';
import { get, post } from '@partsbadger/utils';
import { IInvoiceDetailStaff } from '@partsbadger/types';

class Invoice {
    isLoading = true;
    entities: IInvoiceDetailStaff[] = [];
    entity: IInvoiceDetailStaff | null = null;

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    constructor() {
        makeAutoObservable(this);
        // makeObservable(this, {
        //     // Observables
        //     isLoading: observable,
        //     entities: observable,
        //     entity: observable,
        //     pagination: observable,
        //
        //     // Common Actions
        //     getAll: action,
        //     getById: action,
        //
        //     // Additional Actions
        //     syncFromZohoById: action,
        //
        //     // Local Actions
        //     setInvoice: action,
        // });
    }

    async getAll(params: { page: number; search: string }) {
        this.isLoading = true;
        const data = await get(`/staff/invoices/`, {
            params: params,
        });
        runInAction(() => {
            this.entities = data.results;

            this.pagination.current = params.page;
            this.isLoading = false;
            this.pagination.total = data.count;
        });
    }

    async getById(id: number) {
        this.isLoading = true;
        this.entity = await get(`/staff/invoices/${id}/`);
        this.isLoading = false;
    }

    async syncFromZohoById(id: number) {
        runInAction(() => {
            this.isLoading = true;
        });
        const data = await post(`/staff/invoices/${id}/sync-from-zoho/`);

        this.setInvoice(data);

        runInAction(() => {
            this.isLoading = false;
        });
    }

    async sendPackingSlipInvoice(id: number) {
        runInAction(() => {
            this.isLoading = true;
        });
        const data = await post(`/staff/invoices/${id}/send-packing-slip/`);
        this.setInvoice(data);
        runInAction(() => {
            this.isLoading = false;
        });
    }

    setInvoice(data: IInvoiceDetailStaff) {
        runInAction(() => {
            this.entities = this.entities.map(e => {
                if (e.id == data.id) {
                    return {
                        ...data,
                    };
                }
                return e;
            });
        });
    }
}

export const InvoiceStore = new Invoice();
