import { Card, Col, Form, FormItemProps, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { observer } from 'mobx-react';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { IActionParam, IOption } from './types';

const Option = Select.Option;

interface IBusinessActionsForm {
    row: number;
    name: string;
    currentParams: object;
    handleChange: (values: { name?: string; params?: object }) => void;
    handleClickDelete: () => void;
}

const BusinessActionsForm = (props: IBusinessActionsForm) => {
    const { name, currentParams } = props;
    const [paramsOptions, setParamOptions] = useState<IActionParam[]>([]);
    const { actions } = BusinessRuleStore;

    useEffect(() => {
        const variable = actions.find(v => v.name == props.name);
        if (variable) {
            setParamOptions(variable.params);
        }
    }, [props.name, actions]);

    return (
        <Row>
            <Card
                style={{
                    width: '100%',
                    marginBottom: '10px',
                }}
                extra={[
                    <div key={'delete'}>
                        <DeleteOutlined
                            style={{
                                fontSize: 20,
                                color: 'red',
                            }}
                            onClick={props.handleClickDelete}
                        />
                    </div>,
                ]}
            >
                <Form.Item
                    label="Action"
                    name={`action-name-${props.row}`}
                    initialValue={name}
                    rules={[{ required: true, message: 'Please select an action' }]}
                >
                    <Select
                        placeholder="Select a option"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                        onChange={value => {
                            props.handleChange({
                                name: value,
                                params: {},
                            });
                        }}
                    >
                        {actions.map(action => (
                            <Option key={action.label} value={action.name}>
                                {action.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Col span={24}>
                    {paramsOptions.map((paramOption, index) => {
                        const defaultValue = _.get(currentParams, paramOption.name, '');

                        return (
                            <>
                                {paramOption.fieldType == 'text' && (
                                    <FormItemTextType
                                        key={`param-${paramOption.name}-${props.row}-${index}`}
                                        label={paramOption.label}
                                        name={`param-${paramOption.name}-${props.row}-${index}`}
                                        initialValue={defaultValue}
                                        rules={[{ required: true, message: `${paramOption.label} is required` }]}
                                        help={paramOption.help_text ?? ''}
                                        handleChange={value => {
                                            props.handleChange({
                                                params: {
                                                    ...currentParams,
                                                    [paramOption.name]: value,
                                                },
                                            });
                                        }}
                                    />
                                )}

                                {paramOption.fieldType == 'select' && (
                                    <FormItemSelectType
                                        key={`param-${paramOption.name}-${props.row}-${index}`}
                                        label={paramOption.label}
                                        name={`param-${paramOption.name}-${props.row}-${index}`}
                                        initialValue={defaultValue}
                                        rules={[{ required: true, message: `${paramOption.label} is required` }]}
                                        help={paramOption.help_text ?? ''}
                                        options={paramOption.options ?? []}
                                        handleChange={value => {
                                            props.handleChange({
                                                params: {
                                                    ...currentParams,
                                                    [paramOption.name]: value,
                                                },
                                            });
                                        }}
                                    />
                                )}
                            </>
                        );
                    })}
                </Col>
            </Card>
        </Row>
    );
};

interface FormItemSelectTypeProps extends FormItemProps {
    options: IOption[];
    handleChange: (v: string) => void;
}

const FormItemSelectType = (props: FormItemSelectTypeProps) => {
    return (
        <Form.Item {...props}>
            <Select
                key={'select'}
                placeholder="Select a option"
                allowClear
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
                options={props.options.map(o => ({ label: o.label, value: o.name }))}
                onChange={value => {
                    props.handleChange(value);
                }}
            />
        </Form.Item>
    );
};

interface FormItemTextTypeProps extends FormItemProps {
    handleChange: (v: string) => void;
}

const FormItemTextType = (props: FormItemTextTypeProps) => {
    return (
        <Form.Item {...props}>
            <Input
                key={'text'}
                onChange={e => {
                    props.handleChange(e.target.value);
                }}
            />
        </Form.Item>
    );
};

export default observer(BusinessActionsForm);
