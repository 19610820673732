var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification, Popconfirm, Row, Col, message, Select, Card, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { QuoteStore } from '../Stores';
import { RichTextEditor } from '@partsbadger/library';
import { API_ROOT as BASE_URL, DownloadFile, get, post } from '@partsbadger/utils';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
export var QuoteActions = function (_a) {
    var _b, _c;
    var loading = _a.loading, quote = _a.quote, handlePreviewQuote = _a.handlePreviewQuote;
    var history = useHistory();
    var form = Form.useForm()[0];
    var _d = useState(false), downloading = _d[0], setDownloading = _d[1];
    var _e = useState(false), downloadingDynamic = _e[0], setDownloadingDynamic = _e[1];
    var _f = useState(false), sending = _f[0], setSending = _f[1];
    var _g = useState(false), send = _g[0], sendQuote = _g[1];
    var _h = useState(''), notes = _h[0], setNotes = _h[1];
    var _j = useState(false), sendingToZoho = _j[0], setSendingToZoho = _j[1];
    var _k = useState(false), loadingCsv = _k[0], setLoadingCsv = _k[1];
    var _l = useState(null), selectedTemplate = _l[0], setSelectedTemplate = _l[1];
    var _m = useState(false), showRichText = _m[0], setShowRichText = _m[1];
    var _o = useState([]), partsWithReason = _o[0], setPartsWithReason = _o[1];
    var download = function () {
        var url = '/staff/quotes/' + (quote === null || quote === void 0 ? void 0 : quote.id) + '/export';
        setDownloading(true);
        get(url, { responseType: 'blob' }).then(function (response) {
            DownloadFile(response, quote.name + '.pdf');
            setDownloading(false);
        });
    };
    var downloadDynamic = function () {
        var url = '/staff/quotes/' + (quote === null || quote === void 0 ? void 0 : quote.id) + '/export-dynamic';
        setDownloadingDynamic(true);
        get(url, { responseType: 'blob' }).then(function (response) {
            DownloadFile(response, quote.name + '.pdf');
            setDownloadingDynamic(false);
        });
    };
    var templates = [
        {
            id: 1,
            title: 'Standard, attached is your quote',
            template: "<p> Hi ".concat(quote.owner.first_name, ", attached is your ").concat((quote === null || quote === void 0 ? void 0 : quote.type) === 'Requote' ? 'requote' : 'quote', ". ").concat(((_b = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quoteAccountOverview) === null || _b === void 0 ? void 0 : _b.is_first_quote_for_contact)
                ? ' 🏆<b>This is the customers first quote</b>🏆'
                : '', "</p>\n            <p style=\"font-weight: bold;\">Quote Notes:</->\n            ").concat((quote === null || quote === void 0 ? void 0 : quote.notes) ? quote.notes : '', "\n            <br />\n            - ").concat((_c = quote.design_engineer) === null || _c === void 0 ? void 0 : _c.first_name, "\n            </p>"),
        },
        {
            id: 2,
            title: 'No Quote - Domestic Acapbility (including PBP)',
            template: "<p>Hi ".concat(quote.owner.first_name, ", this is being no quoted due to the\n            following:\n            We do not offer (capability) domestically at this\n            time. If you are open to us quoting within our\n            international partner network, contact your\n            rep.</p>"),
        },
        {
            id: 3,
            title: 'No Quote - ITAR',
            template: "<p>\n            Hi ".concat(quote.owner.first_name, ", this is being no quoted due to the\n            following:\n            Due to unprecedented demand and capacity\n            constraints within our ITAR facility\n            </P"),
        },
        {
            id: 4,
            title: 'No Quote - Select by Product',
            template: "<p>\n                Hi ".concat(quote.owner.first_name, ", attached is your quote.\n                Please note the following product(s) is/are\n                being no quoted due to the following: </br>                \n                Parts: </br>\n            </p>\n            "),
        },
        {
            id: 5,
            title: 'No Quote - Whole Quote (Including lack of vendor quote)',
            template: "\n            <p>\n                Hi ".concat(quote.owner.first_name, ", attached is your quote.\n                This is being no quoted due to the following:\n                We do not offer (capability) at this time.\n                OR\n                Part is too short/long. Our\n                (minimum/maximum) is (x)\n                OR\n                Walls are too thin. Our (minimum) is (x)\n                OR\n                Freefill\n            </p>\n            "),
        },
        {
            id: 6,
            title: 'Freefill',
            template: "\n                <p>Custom message</P\n            ",
        },
    ];
    useEffect(function () {
        setShowRichText(true);
    }, [selectedTemplate, notes]);
    return (React.createElement(Row, { className: 'quote-actions', style: { textAlign: 'center', padding: 5 } },
        React.createElement(Col, { style: { margin: 'auto' } },
            !quote.completed && (React.createElement(Popconfirm, { title: "Do you want to delete this quote?", onConfirm: function () { return QuoteStore.deleteQuote(quote.id, history); } },
                React.createElement(Button, { className: "mx-2", type: 'primary', danger: true },
                    "Delete ",
                    React.createElement(LegacyIcon, { type: 'delete' })))),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    handlePreviewQuote();
                }, loading: loading },
                React.createElement(FilePdfOutlined, null),
                " Preview"),
            React.createElement(Popconfirm, { title: "Do you want to duplicate this quote?", onConfirm: function () {
                    QuoteStore.duplicateQuote(quote.id).then(function (response) {
                        window.location.href = "/quotes/".concat(response.id);
                    });
                } },
                React.createElement(Button, { className: "mx-2" },
                    "Duplicate ",
                    React.createElement(LegacyIcon, { type: 'file-pdf' }))),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: download, loading: downloading },
                React.createElement(FilePdfOutlined, null),
                " Download")),
            quote.completed && (React.createElement(Button, { className: "mx-2", onClick: downloadDynamic, loading: downloadingDynamic },
                React.createElement(FilePdfOutlined, null),
                " Download Dynamic PDF")),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    setLoadingCsv(true);
                    QuoteStore.exportQuotedProductsAsCSV(quote.id)
                        .then(function (response) {
                        if (response.message) {
                            message.warning(response.message, 5);
                        }
                        else {
                            FileDownload(response, "products.csv");
                        }
                    })
                        .catch(function (error) {
                        message.error('There was an error downloading the csv file.', 6);
                    })
                        .finally(function () {
                        setLoadingCsv(false);
                    });
                }, loading: loadingCsv },
                React.createElement(DownloadOutlined, null),
                " CSV Quoted Products"),
            React.createElement(Button, { className: "mx-2", onClick: function () {
                    window.open("".concat(BASE_URL, "/redirect?to=").concat(BASE_URL, "/staff/quotes/").concat(quote.id, "/download-all/"));
                }, loading: loading },
                React.createElement(DownloadOutlined, null),
                " Download All Files"),
            quote.completed && (React.createElement(React.Fragment, null,
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'file-pdf' }), className: "mx-2", onClick: function () { return sendQuote(true); } }, "Send Quote to Rep"),
                React.createElement(Popconfirm, { placement: "topLeft", title: "Do you want to convert this quote to an order?", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, SalesOrderStore.getOrCreateSalesOrder(quote.contact.id).then(function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, SalesOrderStore.update(SalesOrderStore.order_id, {
                                                        quotes: [],
                                                    })];
                                                case 1:
                                                    _a.sent();
                                                    return [4 /*yield*/, SalesOrderStore.update(SalesOrderStore.order_id, {
                                                            contact: quote.contact.id,
                                                            quotes: [quote.id],
                                                        })];
                                                case 2:
                                                    _a.sent();
                                                    history.push('/sales-order/new/');
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); })];
                                case 1:
                                    _a.sent();
                                    history.push('/sales-order/new');
                                    return [2 /*return*/];
                            }
                        });
                    }); }, okText: "Yes", cancelText: "No" },
                    React.createElement(Button, { className: "mx-2", type: "primary" }, "Convert to Sales Order")),
                !quote.zoho_id && quote.stage === 'Completed' && (React.createElement(Button, { className: "mx-2", title: 'Completed quotes are sending automatically to zoho', loading: sendingToZoho, onClick: function () {
                        setSendingToZoho(true);
                        post("/staff/quotes/".concat(quote.id, "/send-to-zoho/"))
                            .then(function (response) {
                            QuoteStore.setQuote(response);
                            setSendingToZoho(false);
                        })
                            .catch(function (error) {
                            // console.log(error);
                            setSendingToZoho(false);
                        });
                    } }, "Send to Zoho")))),
            send && (React.createElement(Modal, { width: 800, visible: true, centered: true, onCancel: function () { return sendQuote(false); }, footer: [
                    React.createElement(Button, { key: "1", className: "mx-2", loading: sending, onClick: function () {
                            if ((selectedTemplate === 4 && partsWithReason === null) ||
                                (selectedTemplate === 4 && (partsWithReason === null || partsWithReason === void 0 ? void 0 : partsWithReason.length) === 0)) {
                                notification.error({
                                    message: 'Please select one or more Parts.',
                                });
                                return;
                            }
                            setSending(true);
                            QuoteStore.sendToSalesRepresentative(quote.id, {
                                notes: notes,
                            }).then(function () {
                                notification.success({
                                    message: 'Quote was sent to the sales representative',
                                });
                                sendQuote(false);
                                setSending(false);
                            });
                            QuoteStore.getNotifications();
                        } }, "Submit"),
                ] },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    "Select template",
                    React.createElement(Select, { value: selectedTemplate, options: templates.map(function (template) { return ({ label: template.title, value: template.id }); }), onChange: function (value) {
                            var item = templates.find(function (item) { return item.id === value; });
                            if (item) {
                                setPartsWithReason([]);
                                setSelectedTemplate(value);
                                setNotes(item.template);
                                setShowRichText(false);
                            }
                        } })),
                selectedTemplate === 4 && QuoteStore.lineItems.length > 0 && (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(Card, { title: 'Select a part and enter a reason for not being quoted', size: 'small' },
                        React.createElement(Form, { form: form, name: "add-part-to-no-quote", onFinishFailed: function (errorInfo) {
                                console.log('Failed:', errorInfo);
                            }, layout: "vertical", onFinish: function (values) {
                                setPartsWithReason(__spreadArray([
                                    { part_name: values.part, due_to: values.due_to }
                                ], partsWithReason, true));
                                setShowRichText(false);
                                var renderedParts = partsWithReason.map(function (part) {
                                    return "<b>".concat(part.part_name, "</b> - ").concat(part.due_to);
                                });
                                var result = renderedParts.join('<br>');
                                setNotes(" Hi ".concat(quote.owner.first_name, ", attached is your quote.\n                                                        Please note the following product(s) is/are\n                                                        being no quoted due to the following: </br>\n\n                                                        Parts: </br>\n                                                        ").concat(result, " <br>\n                                                        <b>").concat(values.part, "</b> - ").concat(values.due_to, " </br>\n                                                     "));
                                setShowRichText(true);
                                form.resetFields();
                            } },
                            React.createElement("div", { className: "flex" },
                                React.createElement("div", { className: "w-1/2 p-4" },
                                    React.createElement(Form.Item, { label: "Parts", name: 'part', rules: [{ required: true, message: 'Please select a part!' }] },
                                        React.createElement(Select, { onSelect: function (value) {
                                                var _a;
                                                var match = value.match(/#(\d+)-/);
                                                if (match) {
                                                    var part_id_1 = parseInt(match[1], 10);
                                                    var selectedPart = QuoteStore.lineItems.find(function (part) { return part.id === part_id_1; });
                                                    if (selectedPart) {
                                                        form.setFieldValue('due_to', (_a = selectedPart.product.master_product_detail) === null || _a === void 0 ? void 0 : _a.notes);
                                                    }
                                                }
                                            }, options: QuoteStore.lineItems.map(function (part) { return ({
                                                label: '#' + part.id + '-' + part.name,
                                                value: '#' + part.id + '-' + part.name,
                                            }); }) }))),
                                React.createElement("div", { className: "w-1/2 p-4" },
                                    React.createElement(Form.Item, { label: "Due to", name: 'due_to', rules: [{ required: true, message: 'Please enter a reason!' }] },
                                        React.createElement(Input, null)))),
                            React.createElement("div", { className: "flex justify-end" },
                                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Add")))))),
                showRichText && React.createElement(RichTextEditor, { value: notes, onChange: function (html, text) { return setNotes(html); } })))),
        React.createElement("br", null)));
};
