import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Badge } from 'antd';
import { useHistory } from 'react-router-dom';
import { TicketStore } from '../../../stores/TicketStore';

interface Props {
    zoho_id: string;
}

const TotalUnreadMessagesAlert = observer(({ zoho_id }: Props) => {
    const history = useHistory();

    useEffect(() => {
        TicketStore.getTotalUnreadMessages(zoho_id, 'sales-support');
    }, []);

    return TicketStore.totalUnreadMessages > 0 ? (
        <div className="flex flex-row justify-center xl:mt-0 mt-1">
            <Alert
                className="w-full xl:w-1/2"
                message={<span className="font-bold">Ticket System Unresponded Messages</span>}
                description={
                    <>
                        <span>
                            This Sales Order has unresponded messages from the Ticket System feed. Please answer them to
                            provide updates to the Production Team.
                        </span>
                        &nbsp;
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                history.push(`/new-sales-order-detail/${zoho_id}/ticket-system/`);
                            }}
                        >
                            Open Support Ticket System &nbsp;
                            <Badge count={TicketStore.totalUnreadMessages} size="default"></Badge>{' '}
                        </Button>
                    </>
                }
                type="warning"
                showIcon
            />
        </div>
    ) : (
        <></>
    );
});

export default TotalUnreadMessagesAlert;
