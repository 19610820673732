import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { message, Badge, Button, Form, Table, Input } from 'antd';
import { ColumnProps } from 'antd/es/table';
import moment from 'moment';

import { IVendorTicket } from '../../../../../../packages/types/src/staff/list/ITicketChatStaff';
import { TicketStore } from '../../../stores/TicketStore';

import { ChatModal } from './ChatModal';

type Props = {
    zoho_id: string;
    message_type?: string | null;
};

export const OpenTickets = observer(({ zoho_id, message_type }: Props) => {
    const [chatForProducts, showChatForProducts] = useState<IVendorTicket>();

    const [form] = Form.useForm();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = (search = '') => {
        const params = {
            zoho_id,
            message_type: message_type ? message_type : 'sales-support',
            status: 'OPEN',
            search,
        };
        TicketStore.getAll(params).catch(e => {});
    };

    const data = !message_type ? TicketStore.ticketList : TicketStore.ticketsSalesRequest;

    const columns: ColumnProps<IVendorTicket>[] = [
        {
            title: 'Id',
            key: 'id',
            render: (record: IVendorTicket) => <div>{record.id}</div>,
            width: '70px',
        },
        {
            title: 'Part',
            width: '150px',
            render: (record: IVendorTicket) => <div>{record.products.map(p => p.product_name).toString()}</div>,
        },
        {
            title: 'Order',
            width: '70px',
            render: (record: IVendorTicket) => <div>{record.products[0]?.sales_order.subject}</div>,
        },
        {
            title: 'Date',
            width: '70px',
            key: 'created',
            render: (record: IVendorTicket) => <div>{moment(record.created).format('YYYY-MM-DD')}</div>,
        },
        {
            title: 'Messages',
            width: '40px',
            align: 'center',
            render: (record: IVendorTicket) => (
                <div>
                    {record.messages_unread_count > 0 ? <Badge count={record.messages_unread_count} /> : <span>0</span>}{' '}
                    / <span>{record.messages_total_count}</span>
                </div>
            ),
        },
        {
            title: 'Actions',
            width: '70px',
            align: 'center',
            render: (record: IVendorTicket) => (
                <div>
                    <Button
                        type={'link'}
                        onClick={() => {
                            TicketStore.getTicket(record.id).then(() => {
                                const ticket = TicketStore.ticket;
                                // error handled by api
                                if (ticket?.status === 'OPEN') {
                                    showChatForProducts(record);
                                    TicketStore.markAsRead(record.id).then(() => {
                                        loadData();
                                    });
                                } else {
                                    message.info('Message was closed.', 5);
                                }
                            });
                        }}
                    >
                        View / Respond
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="flex flex-col w-full space-y-2">
            <Form form={form} layout="inline" className="w-full" onFinish={values => {}}>
                <div className="md:flex md:flex-row w-full pb-2 md:text-left md:ml-2 text-center">
                    <span className="text-red-500 font-bold text-lg md:text-left text-center">
                        Open / Support Tickets
                    </span>

                    <Form.Item name={'search'} className="w-full md:w-1/3 lg:w-1/4 lg:ml-16 md:mt-0 mt-2">
                        <Input.Search
                            placeholder="Search by order or part"
                            onSearch={value => {
                                loadData(value);
                            }}
                            className="w-full"
                            maxLength={30}
                        />
                    </Form.Item>

                    <Button
                        className="md:w-24 w-full md:mt-0 mt-2"
                        onClick={() => {
                            loadData();
                            form.resetFields();
                        }}
                    >
                        Show All
                    </Button>
                </div>
            </Form>
            <div className="overflow-x-auto">
                <Table
                    rowKey={'id'}
                    columns={columns}
                    loading={TicketStore.isLoading}
                    dataSource={data}
                    pagination={false}
                    scroll={{ y: '350px' }}
                    size="small"
                    //style={{ height: "100px"}}
                    sticky
                ></Table>
            </div>

            <div>
                {chatForProducts && (
                    <ChatModal
                        ticket={chatForProducts}
                        handleClose={() => {
                            showChatForProducts(undefined);
                            // reload tickets only if user sent message
                            if (TicketStore.sentMessage) {
                                loadData();
                                TicketStore.sentMessage = false;
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
});
