import React from 'react';
import { IStorageLocation } from '@partsbadger/types';
import { Dropdown, Menu, Popconfirm, Table } from 'antd';
import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';

const { Column } = Table;
interface IProps {
    storages: IStorageLocation[];
    loading: boolean;
    onUpdate: (id: number) => void;
    handleRemove: (id: number) => void;
}

export const StorageLocationTable = (props: IProps) => {
    return (
        <Table<IStorageLocation>
            rowKey={'id'}
            dataSource={props.storages}
            loading={props.loading}
            pagination={false}
            scroll={{ x: 'auto' }}
        >
            <Column title="ID" dataIndex="id" key="id" />
            <Column title="Name" dataIndex="name" key="name" />
            <Column title="Initial" dataIndex="initial" key="initial" />
            <Column<IStorageLocation>
                title="Numbers"
                dataIndex="numbers"
                key="numbers"
                render={(text, record) => {
                    return (
                        <div className={'flex flex-row'}>
                            {record.numbers.map(number => {
                                return <div key={record.id}>&nbsp;{number},</div>;
                            })}
                        </div>
                    );
                }}
            />
            <Column<IStorageLocation>
                title="Sample Structure"
                dataIndex="numbers"
                key="numbers"
                render={(text, record) => {
                    return (
                        <div className={'flex flex-col'}>
                            {record.numbers.map(number => {
                                return <div key={record.id}>&nbsp;{record.initial + '-' + number}</div>;
                            })}
                        </div>
                    );
                }}
            />

            <Column<IStorageLocation>
                title={'Actions'}
                key={'actions'}
                render={(text, record: IStorageLocation) => {
                    const menu = (
                        <Menu>
                            <Menu.Item onClick={() => record.id && props.onUpdate(record.id)}>
                                <EditOutlined /> Edit
                            </Menu.Item>
                            <Menu.Item>
                                <Popconfirm
                                    placement="topLeft"
                                    title={'Do you want to remove this item?'}
                                    onConfirm={() => record.id && props.handleRemove(record.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <CloseOutlined /> Delete
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    );

                    return (
                        <Dropdown overlay={menu}>
                            <span className={'link'}>
                                Actions <DownOutlined />
                            </span>
                        </Dropdown>
                    );
                }}
            />
        </Table>
    );
};
