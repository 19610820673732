import React from 'react';
import { ChecklistsView } from '@partsbadger/sales_order_tool';

const ChecklistReviewPage = () => {
    return (
        <>
            <ChecklistsView />
        </>
    );
};

export default ChecklistReviewPage;
