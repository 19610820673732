/**
 * Base page component for rendering a page.
 */

import React from 'react';
import { Breadcrumb, Col, PageHeader, Row } from 'antd';

interface Props {
    title?: string | React.ReactNode;
    breadcrumbs?: { title: string; link?: string }[];
    topActions?: React.ReactNode[];
    handleBack?: () => void;
}

const RenderPage: React.FC<Props> = props => {
    const breadcrumb = (
        <Breadcrumb>
            {props.breadcrumbs?.map((item, index) => {
                return (
                    <Breadcrumb.Item key={index} href={item.link}>
                        {item.title}
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    className="site-page-header"
                    onBack={props.handleBack}
                    title={props.title}
                    subTitle={breadcrumb}
                    extra={props.topActions}
                    style={{
                        borderBottom: '1px solid #C7C7C7',
                    }}
                />
            </Col>
            <Col
                span={24}
                style={{
                    marginTop: 10,
                }}
            >
                {props.children}
            </Col>
        </Row>
    );
};

export default RenderPage;
