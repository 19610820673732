import React from "react";
import moment from "moment";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, Row } from "antd";
import { Accounts, Contacts } from "@partsbadger/library";
import { useHistory, useLocation } from 'react-router-dom';
import {GenericSelect} from "@partsbadger/library";

const Search = Input.Search;
const {RangePicker,} = DatePicker;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};


const FormItem = Form.Item;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const RfoFilter = (props) => {

    const query = useQuery();
    const history = useHistory();

    const {form} = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {

                const params = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.account?.key) {
                    params['account'] = values.account.key
                }

                if (values.contact?.key) {
                    params['contact'] = values.contact.key
                }

                if (values.created_by?.key) {
                    params['created_by'] = values.created_by.key
                }

                if (values.owner?.key) {
                    params['owner'] = values.owner.key
                }

                if (values.rfq?.key) {
                    params['rfq'] = values.rfq.key
                }

                if (values.is_dynamic?.key) {
                    params['is_dynamic'] = values.is_dynamic
                }

                if (values.stage) {
                    params['stage'] = values.stage
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format("YYYY-MM-DD")
                    params['created_time_max'] = moment(values.created_time[1]).format("YYYY-MM-DD")
                }

                const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

                history.replace(`/rfo/?${queryString}`)
            }

        });
    };


    const {getFieldDecorator} = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Col {...cols}>
                    <FormItem
                        label="Search"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('search', {
                            initialValue: query.get('search')
                        })(
                            <Search placeholder="Search" style={{width: "100%"}} allowClear size={"small"}/>)}
                    </FormItem>
                </Col>


                <Col {...cols}>

                    <FormItem
                        label="Account"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('account', {
                            initialValue: query.get('account') ? {
                                'key': query.get('account')
                            } : {}
                        })(
                            <Accounts/>
                        )}
                    </FormItem>
                </Col>
                <Col {...cols}>

                    <FormItem label="Contact"{...formItemLayout}>
                        {getFieldDecorator('contact', {
                            initialValue: query.get('contact') ? {
                                'key': query.get('contact')
                            } : {}
                        })(
                            <Contacts/>
                        )}
                    </FormItem>
                </Col>

                <Col {...cols}>

                    <FormItem label="Sales Rep"{...formItemLayout}>
                        {getFieldDecorator('owner', {
                            initialValue: query.get('owner') ? {
                                'key': query.get('owner')
                            } : {}
                        })(
                            <GenericSelect recordType={"User"} filters={{
                                is_staff: true,
                                is_active: true
                            }} size="small" />
                        )}


                    </FormItem>
                </Col>

                <Col sm={24} style={{textAlign: "center", marginTop: '5px'}}>
                    <Form.Item>
                        <Button onClick={() => {
                            history.push(`/rfo/`);
                            form.resetFields();
                        }}>Reset</Button>
                        <Button type="primary" htmlType={'submit'} style={{marginLeft: 8}}>
                            Filter
                        </Button>

                    </Form.Item>

                </Col>
            </Form>
        </Row>

    )
}


export const RfoFilterForm = Form.create()(RfoFilter)
