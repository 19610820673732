import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Alert, Button, Card, Col, Form, Row, Skeleton, Table } from 'antd';
import { IInvoiceDetailStaff, IInvoiceLineItemDetailStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceStore } from '../../stores/InvoiceStore';
import { Price } from '@partsbadger/library';
import RenderPage from '../RenderPage';
import { FilePdfOutlined } from '@ant-design/icons';
import InvoiceStatus from '../../components/Invoices/InvoiceStatus';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';

const InvoiceDetailPage: React.FC = (props, l) => {
    const history = useHistory();

    const { id } = useParams<{
        id: string;
    }>();

    const loadData = () => {
        if (id) {
            InvoiceStore.getById(parseInt(id));
        }
    };

    useEffect(() => {
        loadData();
    }, [id]);

    const columns: ColumnProps<IInvoiceLineItemDetailStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (t, i) => {
                return (
                    <>
                        <div>{i.name}</div>
                        <div>{i.product_description ?? ''}</div>
                    </>
                );
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },
        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            render: (text: string) => parseFloat(text).toFixed(2),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            className: 'text-right',
            render: (text: string) => parseFloat(text).toFixed(2),
        },
    ];

    const handlePrint = (invoice: IInvoiceDetailStaff) => {
        window.open(invoice.invoice_data?.invoice_url, '_blank', 'width=800,height=800,left=200,top=200');
    };

    const invoice = InvoiceStore.entity;

    if (!invoice) {
        return <Skeleton />;
    }

    return (
        <RenderPage
            title={
                <div>
                    {invoice.name} &nbsp;
                    <InvoiceStatus status={invoice.invoice_data?.status ?? null} />
                </div>
            }
            handleBack={() => {
                history.goBack();
            }}
            topActions={[
                invoice.invoice_data?.invoice_url ? (
                    <Button
                        key={'new'}
                        type={'primary'}
                        onClick={() => {
                            handlePrint(invoice);
                        }}
                    >
                        <FilePdfOutlined /> Print
                    </Button>
                ) : null,

                <Button
                    type={'primary'}
                    key={'SalesOrder'}
                    onClick={() => {
                        history.push(`/new-sales-order-detail/${invoice?.sales_order}`);
                    }}
                >
                    View In Order
                </Button>,

                <Button key="CRM">
                    {invoice.zoho_id ? (
                        <a
                            style={{
                                fontSize: 12,
                                marginLeft: 4,
                            }}
                            target={'_blank'}
                            rel="noreferrer"
                            href={`${ZOHO_BASE_URL}/Invoices/${invoice.zoho_id}`}
                        >
                            View In CRM
                        </a>
                    ) : null}
                </Button>,

                <Button key={'InvoiceApp'}>
                    {invoice.invoice_zoho_id ? (
                        <a
                            style={{
                                fontSize: 12,
                                marginLeft: 4,
                            }}
                            target={'_blank'}
                            rel="noreferrer"
                            href={`https://invoice.zoho.com/app/649611804#/invoices/${invoice.invoice_zoho_id}`}
                        >
                            View In Invoice
                        </a>
                    ) : null}
                </Button>,
                <SendToZohoButton
                    key={'send-to-crm'}
                    title={'Send To CRM'}
                    module={'invoices'}
                    id={invoice.id}
                    onFinish={() => {
                        loadData();
                    }}
                />,
            ]}
        >
            <Card bodyStyle={{ padding: 0 }} bordered={false}>
                <div style={{ margin: 'auto', maxWidth: '98%' }}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={'Bill To'}>
                                <div>{invoice.billing_name} </div>
                                <div>
                                    {invoice.billing_city}, {invoice.billing_state} {invoice.billing_zip_code}
                                </div>
                            </Form.Item>

                            <Form.Item label={'Ship To'}>
                                <div>{invoice.shipping_name} </div>
                                <div>
                                    {invoice.shipping_street}, {invoice.shipping_state} {invoice.shipping_zip_code}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Invoice Date'}> {invoice.invoice_date}</Form.Item>
                            <Form.Item label={'Terms'}> {invoice.payment_terms_text}</Form.Item>
                            <Form.Item label={'Due Date'}> {invoice.due_date}</Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {invoice.invoice_data?.total &&
                                invoice.invoice_data.total != invoice.total_with_shipping ? (
                                <Alert
                                    message={`  Error: The total ${invoice.total_with_shipping} is different than the Invoice App ${invoice.invoice_data.total}`}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table<IInvoiceLineItemDetailStaff>
                                rowKey={'id'}
                                pagination={false}
                                columns={columns}
                                dataSource={invoice.line_items}
                                scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={12} span={12}>
                            <div className={'flex flex-col justify-between mt-2'} style={{ fontSize: '16px' }}>
                                <div className="flex flex-row justify-between  mb-1">
                                    <div className="summary-label font-bold">Subtotal</div>
                                    <div className="summary-value">
                                        <Price value={invoice?.subtotal ?? 0} />
                                    </div>
                                </div>

                                {invoice?.tax && parseFloat(invoice.tax) > 0 && (
                                    <div className="flex flex-row justify-between  mt-2">
                                        <div className="summary-label font-bold">
                                            <div className={'font-bold'} style={{ fontSize: '16px' }}>
                                                Estimated Sales Tax:
                                            </div>

                                            <div className={'text-xs'}>
                                                (Zip Code: {invoice?.shipping_zip_code}; Percentage: {invoice?.tax}%)
                                            </div>
                                        </div>

                                        <div className="summary-value">
                                            <Price value={invoice?.tax ?? 0} />
                                        </div>
                                    </div>
                                )}
                                {/*<div className="flex flex-row justify-between mt-1">*/}
                                {/*    <div className="summary-label font-bold">Adjustment</div>*/}
                                {/*    <div className="summary-value">*/}
                                {/*        <Price value={invoice?.adjustment ?? 0} />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="flex flex-row justify-between  mb-1">
                                    <div className="summary-label">Shipping Charge:</div>
                                    <div className="summary-value">
                                        (+) <Price value={invoice?.shipping_cost ?? 0} />
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between mt-1">
                                    <div className="summary-label">Discount</div>
                                    <div className="summary-value">
                                        (-) <Price value={invoice?.discount ?? 0} />
                                    </div>
                                </div>

                                <div
                                    className="flex flex-row justify-between  mt-2 pt-4"
                                    style={{
                                        borderTop: 'solid 1px #d9d9d9',
                                    }}
                                >
                                    <div className="font-bold text-xl" style={{ fontSize: '20px' }}>
                                        Total + Shipping
                                    </div>
                                    <div className="text-xl">
                                        <Price value={invoice.total_with_shipping ?? 0} />
                                    </div>
                                </div>

                                <div className="flex flex-row justify-between mt-1">
                                    <div className="summary-label">Payments Made:</div>
                                    <div
                                        className="summary-value"
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        (-){' '}
                                        <Price
                                            value={invoice?.invoice_data?.payment_made ?? 0}
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    </div>
                                </div>

                                {invoice?.credits_applied && parseFloat(invoice.credits_applied) > 0 ? (
                                    <div className="flex flex-row justify-between mt-1">
                                        <div className="summary-label">Credits Applied</div>
                                        <div
                                            className="summary-value"
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            (-){' '}
                                            <Price
                                                value={invoice?.credits_applied ?? 0}
                                                style={{
                                                    color: 'red',
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                <div className="flex flex-row justify-between mt-1">
                                    <div className="summary-label font-bold">Balance Due</div>
                                    <div className="summary-value">
                                        (-) <Price value={invoice?.invoice_data?.balance ?? 0} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
        </RenderPage>
    );
};

export default observer(InvoiceDetailPage);
