var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CaretRightOutlined, CloseOutlined, CloudDownloadOutlined, EditOutlined, FileOutlined, FilePdfOutlined, PlayCircleOutlined, SearchOutlined, SortAscendingOutlined, } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card, notification, Popconfirm, Row, Spin, Tabs, Tooltip } from 'antd';
import { AddFrom3dFile } from './AddFromStepFile';
import { AddFrom2dFile } from './AddFromDrawingFile';
import AddExistingQuotedProduct from './AddExistingQuotedProduct';
import { MasterProductForm } from '../Forms/index';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { QuoteStore } from '../Stores';
import { DownloadFile, get } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { AddLeadTime } from './AddLeadTime';
export var QuoteToolBar = observer(function (props) {
    var quote = props.quote, selectedLineItems = props.selectedLineItems, handleSelectAll = props.handleSelectAll, handleSelectNone = props.handleSelectNone, handleShowBulkEdit = props.handleShowBulkEdit, handleShowPreview = props.handleShowPreview, handleShowReorder = props.handleShowReorder, handleShowEdit = props.handleShowEdit, handleShowReArrangeFiles = props.handleShowReArrangeFiles, handleShowMessages = props.handleShowMessages;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(false), addLeadTine = _b[0], setAddedLeadTime = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var showError = function (error) {
        notification.error({
            message: 'Error',
            description: React.createElement(ErrorRender, { error: error }),
        });
    };
    return (React.createElement(React.Fragment, null, visible ? (React.createElement(Card, { className: 'actions', style: {
            top: '10%',
            right: 0,
            zIndex: 99,
            position: 'fixed',
        } },
        React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
            React.createElement(Row, { className: 'remove' },
                React.createElement(CloseOutlined, { style: {
                        fontSize: '30px',
                        color: '#08c',
                        float: 'left',
                        padding: '5px',
                    }, onClick: function () { return setVisible(false); } })),
            React.createElement(Tabs, { defaultActiveKey: "1", className: 'mx-4' },
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(FileOutlined, null),
                        "With Step File"), key: "1" },
                    React.createElement(AddFrom3dFile, null)),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(FilePdfOutlined, null),
                        "With Drawing File"), key: "2" },
                    React.createElement(AddFrom2dFile, null)),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(SearchOutlined, null),
                        "Existing Product"), key: "3" },
                    React.createElement("div", { style: { maxWidth: '90%', margin: 'auto' } },
                        React.createElement(AddExistingQuotedProduct, null))),
                React.createElement(Tabs.TabPane, { tab: React.createElement("span", null,
                        React.createElement(EditOutlined, null),
                        "Manual Input"), key: "4" },
                    React.createElement(MasterProductForm, { handleCreate: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                            var e_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, QuoteStore.createLineItemWithDimensions(payload)];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _a.sent();
                                        notification.error({
                                            message: 'Error',
                                            description: React.createElement(ErrorRender, { error: e_1 }),
                                        });
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); } })))))) : addLeadTine ? (React.createElement(Card, { className: 'actions', style: {
            top: '10%',
            right: 0,
            zIndex: 99,
            position: 'fixed',
        } },
        React.createElement("div", { style: { maxWidth: '700px', borderRadius: '10px' } },
            React.createElement(Row, { className: 'remove' },
                React.createElement(CloseOutlined, { style: {
                        fontSize: '30px',
                        color: '#08c',
                        float: 'left',
                        padding: '5px',
                    }, onClick: function () { return setAddedLeadTime(false); } })),
            React.createElement("div", null,
                React.createElement("div", { className: 'flex justify-center font-bold text-lg' }, "Bulk Lead Time Adjustment'"),
                React.createElement(AddLeadTime, { setAddLeadTime: setAddedLeadTime }))))) : (React.createElement("div", { className: 'quote-floating-bar' },
        React.createElement("div", { style: { backgroundColor: '#cc0000' }, className: 'tool-bar' },
            React.createElement(Tooltip, { placement: 'left', title: 'Add Product' },
                React.createElement("button", { style: { backgroundColor: '#CC0000' }, disabled: !quote.editable, className: "tool-button", onClick: function () { return setVisible(true); } },
                    React.createElement(LegacyIcon, { type: 'plus' }))),
            !selectedLineItems.length ? (React.createElement(Tooltip, { placement: 'left', title: 'Select All' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleSelectAll },
                    React.createElement(LegacyIcon, { type: 'select' })))) : (React.createElement(Tooltip, { placement: 'left', title: 'Clear Selection' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleSelectNone },
                    React.createElement(LegacyIcon, { type: 'minus-square' })))),
            React.createElement(Tooltip, { placement: 'left', title: 'Bulk Edit' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowBulkEdit },
                    React.createElement(LegacyIcon, { type: 'form' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Recalculate Prices' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: function () {
                        var payload = {
                            quoted_products: QuoteStore.lineItems.map(function (p) { return p.id; }),
                        };
                        QuoteStore.recalculatePrices(payload).catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'One of the line items has an error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        });
                    } },
                    React.createElement(LegacyIcon, { type: 'file-sync' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Preview' },
                React.createElement("button", { className: "tool-button", onClick: handleShowPreview },
                    React.createElement(LegacyIcon, { type: 'file-pdf' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Edit Quote' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowEdit },
                    React.createElement(LegacyIcon, { type: 'edit' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Sort Quote Items' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: handleShowReorder },
                    React.createElement(LegacyIcon, { type: 'unordered-list' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Re-arrange files' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button", onClick: function () { return handleShowReArrangeFiles(true); } },
                    React.createElement(SortAscendingOutlined, null))),
            React.createElement(Tooltip, { placement: 'left', title: 'Messages' },
                React.createElement("button", { className: "tool-button", onClick: handleShowMessages },
                    React.createElement(LegacyIcon, { type: 'message' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Download all Files' },
                React.createElement("button", { className: "tool-button", onClick: function () {
                        get("/staff/quotes/".concat(quote.id, "/download-all/"), { responseType: 'blob' }).then(function (response) {
                            DownloadFile(response, "".concat(quote === null || quote === void 0 ? void 0 : quote.name, "-files.zip"));
                        });
                    } },
                    React.createElement(CloudDownloadOutlined, null))),
            React.createElement(Spin, { spinning: loading },
                React.createElement(Tooltip, { placement: 'left', title: 'Calculate lead time for all items.' },
                    React.createElement(Popconfirm, { title: "Make sure that all products have been stored?", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var err_1;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        setLoading(true);
                                        if (!((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 5];
                                        _b.label = 1;
                                    case 1:
                                        _b.trys.push([1, 4, , 5]);
                                        return [4 /*yield*/, QuoteStore.updateAllLeadTime()];
                                    case 2:
                                        _b.sent();
                                        return [4 /*yield*/, QuoteStore.getLineItems(QuoteStore.quote.id)];
                                    case 3:
                                        _b.sent();
                                        notification.success({
                                            message: 'Files are being analyzed, please wait!',
                                        });
                                        setLoading(false);
                                        return [3 /*break*/, 5];
                                    case 4:
                                        err_1 = _b.sent();
                                        notification.error({
                                            duration: 10,
                                            message: 'Error',
                                            description: React.createElement(ErrorRender, { error: err_1 }),
                                        });
                                        setLoading(false);
                                        return [3 /*break*/, 5];
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); }, className: "tool-button", okText: "Yes", cancelText: "No" },
                        React.createElement("button", { className: "tool-button", disabled: !quote.editable },
                            React.createElement(CaretRightOutlined, { type: 'vertical-align-bottom' }))))),
            React.createElement(Tooltip, { placement: 'left', title: 'Bulk Lead Time Adjustment' },
                React.createElement("button", { className: "tool-button", onClick: function () { return setAddedLeadTime(true); }, disabled: !quote.editable },
                    React.createElement(PlayCircleOutlined, { type: 'vertical-align-bottom' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'Analyze all 3D and 2D files.' },
                React.createElement("button", { disabled: !quote.editable, className: "tool-button step-reanalyze-all-files", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            if (((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id) && QuoteStore.masterProductsByPosition) {
                                QuoteStore.masterProductsByPosition.map(function (r) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, QuoteStore.analyzeAllItemsFiles(r.master_product.id)];
                                            case 1:
                                                _a.sent();
                                                if (QuoteStore.error) {
                                                    showError(QuoteStore.error);
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }
                            return [2 /*return*/];
                        });
                    }); } },
                    React.createElement(LegacyIcon, { type: 'sync' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'To Top' },
                React.createElement("button", { className: "tool-button", onClick: function () {
                        window.scrollTo(0, 0);
                    } },
                    React.createElement(LegacyIcon, { type: 'vertical-align-top' }))),
            React.createElement(Tooltip, { placement: 'left', title: 'To Bottom' },
                React.createElement("button", { className: "tool-button", onClick: function () {
                        window.scrollTo(0, document.body.scrollHeight);
                    } },
                    React.createElement(LegacyIcon, { type: 'vertical-align-bottom' }))),
            React.createElement("div", { style: { height: '30px' } },
                React.createElement(Spin, { spinning: QuoteStore.loading })))))));
});
