//Dependencies
import React from 'react';
import { Button, Form, Input, message, notification, Select } from 'antd';
import { observer } from 'mobx-react';

//Interfaces
import { ITemplate } from './types';

//Components
import { ErrorRender, RichTextEditor } from '@partsbadger/library';

// Stores
import { ContentTypeStore } from '../../stores';

interface Props {
    record?: ITemplate;
    handleSave(record: ITemplate): Promise<void>;
}

const TemplateForm = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const initialValues = props.record
        ? {
              name: props.record.name,
              content: props.record.content,
              model: {
                  key: props.record.model,
                  label: props.record.model_name,
              },
              theme: props.record.theme,
          }
        : undefined;

    const onFinish = (values: any) => {
        setIsLoading(true);

        props
            .handleSave({
                id: props.record?.id || null,
                name: values.name,
                content: values.content,
                model: values.model.key,
                model_name: values.model_name,
                theme: values.theme,
                template_variables: [],
                model_variables: [],
            })
            .then(() => {
                message.success('Template saved successfully');
                // notification.success({
                //     message: 'Success',
                //     description: 'Template saved successfully',
                // });
            })
            .catch(e => {
                notification.error({
                    message: 'Error',
                    description: <ErrorRender error={e} />,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const tags =
        props.record?.model_variables?.map(variable => {
            return {
                name: `${variable.verbose_name}`,
                tag: `{{${variable.name}}}`,
            };
        }) || [];

    const mergeTags = [
        {
            trigger: '{',
            tags: tags,
        },
    ];

    return (
        <Form initialValues={initialValues} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
                <Input disabled={props.record?.name ? true : false}/>
            </Form.Item>

            <Form.Item label="Module" name="model" rules={[{ required: true, message: 'Please input content!' }]}>
                <Select showSearch optionFilterProp="children" labelInValue>
                    {ContentTypeStore.contentTypes.map(contentType => (
                        <Select.Option key={contentType.id} value={contentType.id}>
                            {contentType.model}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label="Theme Base" name="theme" help={'Changing the model could affect the content'}>
                <Select showSearch optionFilterProp="children">
                    <Select.Option>Default</Select.Option>
                    <Select.Option value={'1.html'}>1</Select.Option>
                </Select>
            </Form.Item>

            {props.record?.id && (
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[{ required: true, message: 'Please input content!' }]}
                >
                    <RichTextEditor mergeTags={mergeTags} />
                </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    {props.record ? 'Update' : 'Next'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default observer(TemplateForm);
