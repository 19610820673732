import React, { useState } from 'react';
import { Col, Form, Input, notification, Row, Select, Spin } from 'antd';
import { AccountStore } from '../../../stores/AccountStore';
import { LeadStore } from '@partsbadger/one-true-form/lib/stores/LeadStore';
import { ErrorRender } from '@partsbadger/library';
import { IZohoLeadListStaff as IZohoLead } from '@partsbadger/types';
import { LeadProps } from '@partsbadger/types';

type Props = {
    onSelectLead?: (value: LeadProps) => void;
};

export const Leads = ({ onSelectLead }: Props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    // Stores the lead selected from found_leads
    const [lead_selected, setLeadSelected] = useState<IZohoLead | undefined>(undefined);

    // Stores the leads found when searching by email
    const [found_leads, setFoundLeads] = useState<IZohoLead[] | []>([]);

    // Stores a message when there is already a contact with the same email of the lead
    const [sms_warning, setSmsWarning] = useState<string | null>(null);

    const verifyAccountAndContact = async (new_lead: IZohoLead): Promise<void> => {
        // Check if the account and contact already exist in local database
        await AccountStore.existingAccount(new_lead.Company, new_lead.Email)
            .then((response: any): void => {
                if (response.data.account.zoho_id !== null) {
                    LeadStore.setAccount({
                        account_type: `Existing Account - ${response.data.account.name}`,
                        account_owner: response.data.account.account_owner,
                        email_lead_gen_name: new_lead?.First_Name + ' ' + new_lead?.Last_Name,
                        email_lead_gen_email: new_lead?.Email,
                        contact_owner: response.data.account.account_owner,
                        name: response.data.account.name,
                        account_zoho_id: response.data.account.zoho_id,
                        id: response.data.account.id,
                        owner_zoho_id: response.data.account.owner_zoho_id,
                    });
                }

                if (response.data.contact.exists === true) {
                    setSmsWarning(
                        'This lead already exists in Zoho as a contact. Please opt out the lead by clicking the following link:'
                    );
                }

                if (response.data.account.zoho_id === null && response.data.contact.zoho_id === null) {
                    LeadStore.setAccount({
                        account_type: 'New Account',
                        account_owner: 'PartsBadger',
                        email_lead_gen_name: new_lead?.First_Name + ' ' + new_lead?.Last_Name,
                        email_lead_gen_email: new_lead?.Email,
                        contact_owner: 'PartsBadger',
                        account_zoho_id: null,
                    });
                }
            })
            .catch((error): void => {
                notification.error({
                    message: <ErrorRender error={error} />,
                    placement: 'topRight',
                });
            });
    };

    const SearchLeadInput = () => {
        const [loading, setLoading] = useState<boolean>(false);
        return (
            <Spin spinning={loading} tip={'Searching Leads'}>
                <Form.Item
                    label="Lead To Convert"
                    name={'search_email'}
                    help={'Input an email and press enter to search for a lead'}
                >
                    <Input
                        type={'email'}
                        width={400}
                        placeholder={'Input an email and press enter'}
                        onPressEnter={e => {
                            setLoading(true);
                            const email = e.currentTarget.value;
                            setSmsWarning(null);
                            setLeadSelected(undefined);
                            LeadStore.clearLead();
                            LeadStore.searchLeads({ search: email })
                                .then(() => {
                                    setFoundLeads(LeadStore.zoholead ?? []);
                                    if (LeadStore.zoholead && LeadStore.zoholead.length === 0) {
                                        notification.warning({
                                            message: 'Warning!',
                                            description: 'No leads found with the email: ' + email,
                                            placement: 'top',
                                        });
                                    }
                                })
                                .catch(error => {
                                    LeadStore.clearLead();
                                    notification.error({
                                        message: <ErrorRender error={error} />,
                                        placement: 'bottom',
                                    });
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                    />
                </Form.Item>
            </Spin>
        );
    };

    return (
        <div>
            <div className="flex justify-center items-center">
                <Form layout={'horizontal'} form={form}>
                    <Row style={{ maxWidth: '800px', margin: 'auto' }}>
                        <Col span={24}>
                            <SearchLeadInput />
                        </Col>
                        {found_leads.length > 0 && (
                            <Col span={24}>
                                <Form.Item label="Select Lead To Convert" name={'lead'}>
                                    <Select
                                        showSearch
                                        allowClear
                                        filterOption={false}
                                        style={{ width: '100%' }}
                                        notFoundContent={loading ? <Spin size="small" /> : null}
                                        onSelect={async value => {
                                            const new_lead: IZohoLead | undefined = found_leads.find(
                                                (lead: IZohoLead): boolean => lead.id.toString() === value.toString()
                                            );

                                            if (new_lead) {
                                                await verifyAccountAndContact(new_lead);

                                                const leadSelected: LeadProps = {
                                                    first_name: new_lead?.First_Name,
                                                    last_name: new_lead?.Last_Name,
                                                    email: new_lead?.Email,
                                                    lead_zoho_id: new_lead?.id,
                                                    acquisition_source: new_lead?.Acquisition_Source,
                                                    company: new_lead?.Company,
                                                    phone: new_lead?.Phone,
                                                    mobile: new_lead?.Mobile,
                                                    title: new_lead?.Designation,
                                                    referral: false,
                                                    accountLead: LeadStore?.account,
                                                };
                                                if (onSelectLead) {
                                                    onSelectLead(leadSelected);
                                                }
                                            }
                                        }}
                                    >
                                        {found_leads.map((lead: IZohoLead) => (
                                            <Select.Option key={lead.id} value={lead.id}>
                                                <div>
                                                    {lead.Company} - {lead.First_Name} {lead.Last_Name}
                                                </div>
                                                <div className={'text-xs'}>{lead.Email}</div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </Form>
            </div>
        </div>
    );
};
