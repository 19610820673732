import React, { useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Table } from "antd";
import { Price } from '@partsbadger/library';
import { PartDescription } from "components/SalesOrder";


export const QuotableLineItems = Form.create()(({line_items, onSelect, onSelectMultiple, onSearch}) => {

        const [selectedLineItems, setSelectedLineItems] = useState([]);

        const columns = [
            {
                title: 'Part Information',
                dataIndex: 'name',
                onFilter: (value, record) => record.name.indexOf(value) === 0,
                sorter: (a, b) => a.name.length - b.name.length,
                render: (text, record) =>
                    (
                        <PartDescription quote_has_product={record}/>
                    )
            },
            {
                title: 'Qty',
                dataIndex: 'quantity',
                sorter: (a, b) => a.quantity - b.quantity,
                align: 'center',
                render: (text, record) => {
                    return (
                        <Price value={record.quantity}/>
                    )
                },
            },

            {
                title: 'Unit Price',
                dataIndex: 'unit_price',
                sorter: (a, b) => a.unit_price - b.unit_price,
                align: 'center',
                render: (text, record) => {
                    return (
                        <Price value={record.unit_price}/>
                    )
                },
            },
            {
                title: 'Total Price',
                dataIndex: 'total_price',
                render: (text, record) => {
                    return <Price value={record.total_price}/>
                },
                align: 'right'
            },
            {
                title: 'Action',
                dataIndex: 'actions',
                render: (text, record) => {
                    return <Button size={"small"} onClick={() => {
                        if (onSelect) {
                            onSelect(record)
                        }

                    }}>Add</Button>
                },
                align: 'right'
            }
        ];


        return (<div>
            <Row>
                <Col md={12}>
                    <Input.Search placeholder={"Search"} onPressEnter={event => {
                        if (onSearch) {
                            onSearch(event.target.value)
                        }
                    }}/>
                </Col>
                <Col md={12}>
                    {selectedLineItems.length > 0 && <Button onClick={() => {
                        onSelectMultiple(selectedLineItems)
                    }}>Add Multiple</Button>}
                </Col>
            </Row>
            <Row>
                <Table
                    rowSelection={{
                        onChange: (keys, selectedRows) => {
                            setSelectedLineItems(selectedRows);
                        }
                    }}
                    columns={columns}
                    rowKey={"id"}
                    dataSource={line_items}
                    pagination={false}
                    scroll={window.innerWidth <= 600 ? {x: 600} : undefined}
                />
            </Row>
        </div>)

    }
);

