import { Card, Form, Input, Row, Select, InputNumber } from 'antd';
import React, { useState } from 'react';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { observer } from 'mobx-react';
import { DeleteOutlined } from '@ant-design/icons';
import { IDataType } from './types';

const Option = Select.Option;

interface IVariableForm {
    row: number;
    name: string;
    operator: string;
    value: string | number;
    handleChange: (values: { name?: string; operator?: string; value?: string | number }) => void;
    handleClickDelete: () => void;
}

const VariablesForm = (props: IVariableForm) => {
    const { variables, variable_type_operators } = BusinessRuleStore;

    const [fieldType, setFieldType] = useState<IDataType>('string');
    const [options, setOptions] = useState<string[]>([]);

    // Get operator type based on the current variable
    React.useEffect(() => {
        const fieldType = variables.find(v => v.name == props.name);
        if (fieldType) {
            setFieldType(fieldType.field_type);
            setOptions(fieldType.options);
        }
    }, [props.name, variables]);

    return (
        <Row>
            <Card
                style={{
                    width: '100%',
                    marginBottom: '10px',
                }}
                extra={[
                    <DeleteOutlined
                        key={'delete'}
                        style={{
                            fontSize: 20,
                            color: 'red',
                        }}
                        onClick={props.handleClickDelete}
                    />,
                ]}
            >
                <Form.Item
                    label="Event"
                    name={`variable-name-${props.row}`}
                    initialValue={props.name}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a option ..."
                        allowClear
                        dropdownMatchSelectWidth={false}
                        onChange={value => {
                            props.handleChange({
                                name: value,
                            });
                        }}
                    >
                        {variables.map(v => (
                            <Option key={v.name} value={v.name} type={v.field_type}>
                                {v.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="operator"
                    name={`variable-operator-${props.row}`}
                    initialValue={props.operator}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a option"
                        allowClear
                        dropdownMatchSelectWidth={false}
                        onChange={value => {
                            props.handleChange({
                                operator: value,
                            });
                        }}
                    >
                        {variable_type_operators &&
                            fieldType &&
                            variable_type_operators[fieldType].map(operator => (
                                <Option key={operator.name} value={operator.name}>
                                    {operator.label}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                {fieldType !== 'boolean' && (
                    <Form.Item
                        label="value"
                        name={`variable-value-${props.row}`}
                        initialValue={props.value}
                        rules={[{ required: true }]}
                    >
                        {options.length === 0 ? (
                            fieldType === 'numeric' ? (
                                <InputNumber
                                    placeholder="Input a Value"
                                    defaultValue={0}
                                    onChange={(e: any) => props.handleChange({ value: e })}
                                />
                            ) : (
                                <Input
                                    placeholder="Input a Value"
                                    onChange={e => props.handleChange({ value: e.target.value })}
                                />
                            )
                        ) : (
                            <Select
                                placeholder="Select an option"
                                allowClear
                                dropdownMatchSelectWidth={false}
                                onChange={value => props.handleChange({ value })}
                            >
                                {options.map((option, index) => (
                                    <Option key={index} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                )}
            </Card>
        </Row>
    );
};

export default observer(VariablesForm);
