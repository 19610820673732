import React from "react";
import {Input, Select} from "antd";
import {UploadFile} from '../../../Inputs/UploadFile'

const {TextArea} = Input;

const InputField = ({dataIndex, record, handleChange, handleDelete}) => {

    const offset = 1;

    switch (dataIndex) {

        case 'file3d':
            return (
                <UploadFile
                    type={"3D"}
                    value={record.file3d}
                    onChange={fileList => {
                        handleChange({
                            ...record,
                            'file3d': [...fileList]
                        })
                    }}
                />
            );

        case 'file2d':
            return (
                <UploadFile
                    type={"2D"}
                    value={record.file2d}
                    onChange={fileList => {
                        handleChange({
                            ...record,
                            'file2d': [...fileList]
                        })
                    }}
                />

            );

        case 'other_files':
            return (
                <UploadFile
                    multiple
                    value={record.other_files}
                    onChange={fileList => {
                        handleChange({
                            ...record,
                            'other_files': [...fileList]
                        })

                    }}
                />
            );

        case 'quantities':
            return (<Select required={false} mode="tags"
                            placeholder={"Insert numbers separated by comma"}
                            help={"Insert numbers separated by comma"}
                            style={{maxWidth: '200px'}}
                            value={record.quantities}
                            onChange={(values) => {
                                handleChange({
                                    ...record,
                                    'quantities': values
                                })

                            }}
                            tokenSeparators={[',']}
                            valuepropname={'value'}
                            tabIndex={offset + 1}
                            onInputKeyDown={(evt) => {
                                const charCode = evt.keyCode;
                                if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && charCode !== 188) {
                                    evt.preventDefault();
                                }
                            }}
            />)

        case 'materials':
            return (
                <TextArea defaultValue={record.materials} placeholder={"Not required"} rows={4} required={false}
                          onChange={event => {
                              handleChange({
                                  ...record,
                                  'materials': event.target.value
                              })
                          }}
                          style={{maxWidth: '200px'}}
                          tabIndex={offset + 2}
                />
            );

        case 'notes':
            return (
                <TextArea defaultValue={record.notes} rows={4} required={false}
                          onChange={event => {
                              handleChange({
                                  ...record,
                                  'notes': event.target.value
                              })
                          }}
                          style={{maxWidth: '200px'}}
                          tabIndex={offset + 3}
                />
            );
        case 'operation':

            return (
                <span style={{maxWidth: '80px'}} onClick={() => handleDelete(record.id)}>
                            <p className={'link'}>Delete</p>
                        </span>

            );

        default :
            return null


    }
}

export default InputField;