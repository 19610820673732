import React, {useEffect, useState} from 'react'
import {Button, Table} from 'antd';
import {v4} from 'uuid'
import BulkCreate from '../BulkCreate';
import Item from './Item';
import UpdateMultipleLineItems from '../UpdateMultipleLineItems'
import ImportFromCsv from './ImportFromCsv';

const help_text = "No value";

const LineItems = ({value = [], onChange}) => {


    const [selectedItems, setSelectedItems] = useState([]);
    const [records, setRecords] = useState(value);


    useEffect(() => {
        document.addEventListener("keydown", (event) => {
            if (event.code === 'Escape') {
                setSelectedItems([]);
            }
        });
        document.addEventListener("dragover", function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener("drop", function (e) {
            e.preventDefault();
        }, false);
    }, []);


    useEffect(() => {
        if (value) {
            setRecords(value);
        }
    }, [value]);


    const handleChange = (record) => {
        if (onChange) {
            onChange(records.map(r => r.id === record.id ? record : r))
        } else {
            setRecords(records.map(r => r.id === record.id ? record : r));
        }
    };
    const handleUpdate = (records) => {
        if (onChange) {
            onChange(records)
        } else {
            setRecords(records);
        }
    };

    const handleDelete = (id) => {
        if (onChange) {
            onChange(records.filter(r => r.id !== id));
        } else {
            setRecords(records.filter(r => r.id !== id));
        }
    };

    const handleNew = () => {
        setRecords([...records, {
            id: v4(),
            file3d: [],
            file2d: [],
            other_files: [],
            quantities: [],
            materials: "",
            notes: "",
        }])
    };

    const components = {
        body: {
            cell: Item,
        },
    };

    const columns = [
        {
            title: '3D File',
            dataIndex: 'file3d',
            editable: true,
            width: '400px',
            sorter: (a, b) => {

                a = a.file3d ? a.file3d[0].name : ""
                b = b.file3d ? b.file3d[0].name : ""

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }

                return 0;
            },
            render: (text, record) => {
                if (record.file3d) {
                    return record.file3d.filename
                }
                return help_text
            }
        },
        {
            title: '2D File',
            dataIndex: 'file2d',
            editable: true,
            width: '400px',
            sorter: (a, b) => {

                a = a.file2d ? a.file2d[0].name : ""
                b = b.file2d ? b.file2d[0].name : ""

                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }

                return 0;
            },
            render: (text, record) => {
                if (record.file2d) {
                    return record.file2d.filename
                }

                return help_text
            }
        },

        {
            title: 'Other Files (Multiple)',
            dataIndex: 'other_files',
            editable: true,
            width: '300px',
            render: (text, record) => {
                if (record?.other_files) {
                    return record.other_files?.map(f => f.filename)
                }

                return help_text
            }
        },
        {
            title: 'Quantities',
            dataIndex: 'quantities',
            editable: true,
            width: '150px',
            render: (text, record) => {
                if (record.quantities?.length) {
                    return record.quantities.map(r => <span key={r}>{r},</span>)
                }
                return help_text
            }
        },

        {
            title: 'Materials',
            dataIndex: 'materials',
            editable: true,
            width: '200px',
            render: (text, record) => {
                if (record.materials) {
                    return record.materials
                }
                return help_text
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            editable: true,
            width: '200px',
            render: (text, record) => {
                if (record.notes) {
                    return record.notes
                }
                return help_text
            }
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: '100px',
            render: (text, record) =>
                <span onClick={() => handleDelete(record?.id)}>
                            <p className={'link'}>Delete</p>
                        </span>
        },
    ];

    const cols = columns?.map(col => {
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleChange: handleChange,
                handleDelete: handleDelete
            }),
        };
    });


    return (
        <>
            <BulkCreate records={records} handleUpdate={handleUpdate}/>


            <Table
                rowKey={'id'}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={records}
                columns={cols}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedItems.map(r => r.id),
                    onChange: (keys, selectedRows) => {
                        setSelectedItems(selectedRows);
                    }
                }}
                footer={() => {
                    return (<div className={'center'}>
                        <Button onClick={handleNew} type="primary">
                            Add a row
                        </Button>
                    </div>)
                }}
                scroll={{x: 1500}}
            />

            {selectedItems.length > 0 && (
                <div style={{margin: '10px 0'}}>
                    <h4>Bulk Edit</h4>
                    <UpdateMultipleLineItems records={selectedItems} handleChange={handleChange}/>
                </div>
            )}

            <ImportFromCsv records={records} handleUpdate={handleUpdate}/>


        </>

    )

}

export default LineItems;

