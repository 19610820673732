import React from 'react';
import RadioChecklist from './RadioChecklist';
import CheckboxChecklist from './CheckboxChecklist';
import InputChecklist from './InputChecklist';
var ChecklistQuestion = function (props) {
    var renderComponent = function () {
        if (props.question.response_type_component === 'radio') {
            return (React.createElement(RadioChecklist, { form: props.form, question: props.question, checklistQuestions: props.checklistQuestions, setChecklistQuestions: props.setChecklistQuestions, checklistEdit: props.checklistEdit, getFieldDecorator: props.getFieldDecorator }));
        }
        else if (props.question.response_type_component === 'checkbox') {
            return (React.createElement(CheckboxChecklist, { question: props.question, value: props.value, onChange: props.onChange, form: props.form, checklistQuestions: props.checklistQuestions, checklistEdit: props.checklistEdit, getFieldDecorator: props.getFieldDecorator }));
        }
        else if (props.question.response_type_component === 'input') {
            return (React.createElement(InputChecklist, { question: props.question, value: props.value, onChange: props.onChange, form: props.form, checklistQuestions: props.checklistQuestions, checklistEdit: props.checklistEdit, getFieldDecorator: props.getFieldDecorator }));
        }
        else {
            return React.createElement("h1", null);
        }
    };
    return renderComponent();
};
export default ChecklistQuestion;
