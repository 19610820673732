import React, {useState} from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Upload } from "antd";
import {v4} from "uuid";
import {BASE_URL} from "constants/app";
import {allowed2d, allowed3d} from "../../Inputs/UploadFile/FileExtensions";

const {TextArea} = Input;

const token = localStorage.getItem('token');

/**
 * Get the name of the file
 * @param file
 * @returns {string|any}
 */
const get_name = (file) => {

    if (file) {
        const filename = file ? file[0]?.name : ""
        return filename ? filename.split('.').slice(0, -1).join('.').replace(/[^0-9a-z]/gi, '') : null;
    }

    return 'undefined'

}


const BulkCreate = ({records, handleUpdate}) => {


    const [quantities, setQuantities] = useState([]);
    const [materials, setMaterials] = useState();
    const [notes, setNotes] = useState();
    const [fileList, setFileList] = useState([]);


    const handleAdd = (record) => {

        let overridden = false;

        if (record.file3d) {

            // Searching if there is a 2d file with the same name
            overridden = false;
            const rows_updated_3d = records.map(r => {

                if (r.file2d && record.file3d && get_name(r.file2d) === get_name(record.file3d)) {
                    overridden = true;
                    return {...r, file3d: record.file3d}
                }
                return r
            });

            if (overridden) {
                handleUpdate(rows_updated_3d)
                return;
            }
        }

        if (record.file2d) {
            // Searching if there is a 3d file with the same name
            overridden = false;
            const rows_updated_2d = records.map(r => {
                    if (r.file3d && record.file2d && get_name(r.file3d) === get_name(record.file2d)) {
                        overridden = true;
                        return {...r, file2d: record.file2d}
                    }
                    return r
                }
            );
            if (overridden) {
                handleUpdate(rows_updated_2d);
                return;
            }
        }

        // No match names found just append  new line

        handleUpdate([...records, record])
    }

    return (
        <div style={{maxWidth: "400px", margin: '30px auto', padding: '20px', border: 'solid 1px #000'}}>

            <h4 style={{color: 'red'}}>Bulk Upload</h4>


            <Form.Item
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                label="Quantities"
                help={"(Optional)This quantities will be applied for all the uploaded files"}
            >
                <Select required={false} mode="tags"
                        style={{minWidth: '200px'}}
                        tokenSeparators={[',']}
                        onInputKeyDown={(evt) => {
                            const charCode = evt.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105) && charCode !== 188) {
                                evt.preventDefault();
                            }
                        }}
                        onChange={event => setQuantities(event)}
                        value={quantities}
                >
                </Select>
            </Form.Item>

            <Form.Item
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                label="Material"
                help={"(Optional)This material will be applied for all the uploaded files"}
            >

                <Input
                    style={{minWidth: '200px'}}
                    onChange={event => {
                        setMaterials(event.target.value)
                    }}
                    value={materials}
                >
                </Input>
            </Form.Item>

            <Form.Item
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                label="Notes">
                <TextArea
                    style={{minWidth: '200px'}}
                    onChange={event => {
                        setNotes(event.target.value);
                    }}
                    value={notes}
                >
                </TextArea>
            </Form.Item>


            <Form.Item
                labelCol={{span: 6}}
                wrapperCol={{span: 16}}
                label="Files"
                help={"If the names matches with the current uploaded files it will associate automatically"}

            >
                <Upload.Dragger
                    name="file"
                    headers={{
                        'Authorization': `Token ${token}`
                    }}
                    multiple={true}
                    action={`${BASE_URL}/staff/rfq/upload-file/`}
                    fileList={fileList}
                    onChange={(info) => {
                        let fileList = info.fileList;

                        const {status} = info.file;

                        if (status === 'done') {

                            const ext = info.file.name.split('.').pop().toLowerCase();

                            const payload = {
                                'id': v4(),
                                'quantities': quantities,
                                'notes': notes,
                                'materials': materials,
                            };

                            if (allowed2d.indexOf(ext) >= 0) {
                                payload['file2d'] = [info.file]
                            } else if (allowed3d.indexOf(ext) >= 0) {
                                payload['file3d'] = [info.file]
                            } else {
                                payload['other_files'] = [info.file]
                            }

                            handleAdd(payload);

                        }

                        setFileList(fileList);
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag multiple files to this
                        area
                        to
                        upload, and we generate rows automatically </p>
                </Upload.Dragger>
            </Form.Item>
        </div>
    );
}


export default BulkCreate


