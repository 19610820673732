var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FileUnknownOutlined, LoadingOutlined, } from '@ant-design/icons';
import { Button, Checkbox, Modal, notification, Radio, Menu, Spin, Table, Alert, Dropdown, Divider, Typography, Badge, } from 'antd';
import { injectStores } from '@mobx-devtools/tools';
import _ from 'lodash';
import 'animate.css';
import { CheckoutForm } from './components/Checkout';
import BulkUpdateItems from './components/BulkUpdateItems';
import { UploadPartFilesMultiple } from './components/UploadParts';
import QuoteStore from './stores/QuoteStore';
import PartStore from './stores/PartStore';
import SocketConnection from './utils/socket_connection';
import { ErrorRender, Price, Quotes } from '@partsbadger/library';
import Search from 'antd/lib/input/Search';
import { MasterProduct } from './components/MasterProduct';
import '../style/styles.css';
if (process.env.NODE_ENV !== 'production') {
    // MobX devtools
    injectStores({ QuoteStore: QuoteStore, PartStore: PartStore });
}
var Title = Typography.Title;
export var Loading = function () {
    var antIcon = React.createElement(LoadingOutlined, { style: { fontSize: 24 }, spin: true });
    return (React.createElement("div", { className: 'loader', style: { margin: '0 auto' } },
        React.createElement(Spin, { indicator: antIcon, style: { display: 'block' } })));
};
var pollingQuoteInterval = null;
var QuoteOneTrueForm = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var quote = _a.quote, _l = _a.token, token = _l === void 0 ? '' : _l, acquisition_source = _a.acquisition_source, signed_uid = _a.signed_uid, _m = _a.is_new_contact, is_new_contact = _m === void 0 ? false : _m, current_user = _a.current_user, _o = _a.for_staff, for_staff = _o === void 0 ? false : _o, onQuoteSubmitted = _a.onQuoteSubmitted, isReceiveSms = _a.isReceiveSms, onRequestOrder = _a.onRequestOrder;
    // const [previewQuote, setPreviewQuote] = useState(false);
    var _p = useState(false), bulkUpdate = _p[0], showBulkUpdate = _p[1];
    // Save the selected items for bulk updates
    var _q = useState([]), selectedItems = _q[0], setSelectedItems = _q[1];
    var _r = useState(false), applyBatch = _r[0], setApplyBatch = _r[1];
    var _s = useState(false), modalPreviousParts = _s[0], setModalPreviousParts = _s[1];
    var _t = useState(false), deletePartsModalVisible = _t[0], setDeletePartsModalVisible = _t[1];
    var _u = useState('quote'), typeSearch = _u[0], setTypeSearch = _u[1];
    var _v = useState([]), selectedRowKeys = _v[0], setRowKeys = _v[1];
    useEffect(function () {
        if (signed_uid) {
            QuoteStore.setSignedUID(signed_uid);
        }
        QuoteStore.getAdditionalRequirements();
        QuoteStore.setQuote(quote);
        QuoteStore.setForStaff(for_staff);
        if (current_user) {
            QuoteStore.setCurrentUser(current_user);
        }
        QuoteStore.setToken(token);
        QuoteStore.setIsNewContact(is_new_contact);
    }, []);
    useEffect(function () {
        if (pollingQuoteInterval) {
            clearInterval(pollingQuoteInterval);
        }
        // Check if there are pending files to be analyzed
        if (QuoteStore.needsToUsePolling && !QuoteStore.isAllFileAnalyzingCompleted) {
            pollingQuoteInterval = setInterval(function () {
                QuoteStore.getQuote();
                if (QuoteStore.isAllFileAnalyzingCompleted) {
                    clearInterval(pollingQuoteInterval);
                }
            }, 30000);
        }
        return function () {
            if (pollingQuoteInterval) {
                clearInterval(pollingQuoteInterval);
            }
        };
    }, [QuoteStore.needsToUsePolling, QuoteStore.isAllFileAnalyzingCompleted]);
    useEffect(function () {
        QuoteStore.getMaterials();
        QuoteStore.getFinishes();
        QuoteStore.getCoatings();
    }, []);
    useEffect(function () {
        var _a;
        // Only authenticated users can access to contact details
        if (((_a = quote.contact) === null || _a === void 0 ? void 0 : _a.id) && QuoteStore.token) {
            QuoteStore.getContactDetails(quote.contact.id);
            QuoteStore.getAdditionalRequirementsContactAccount(quote.contact.id);
        }
    }, [(_b = quote.contact) === null || _b === void 0 ? void 0 : _b.id]);
    useEffect(function () {
        if (acquisition_source !== 'Portal') {
            QuoteStore.setAcquisitionSource(acquisition_source);
        }
    }, [acquisition_source]);
    /**
     * Add or remove items from the selected array list
     */
    var handleSelect = function (key, selected) {
        if (selected) {
            setSelectedItems(__spreadArray(__spreadArray([], selectedItems, true), [key], false));
        }
        else {
            setSelectedItems(selectedItems.filter(function (id) { return id !== key; }));
        }
    };
    /**
     * Select all for bulk update
     */
    var handleSelectAll = function (e) {
        if (e.target.checked) {
            // Get all master products
            var selected = _.map(master_products).map(function (line_items) { return line_items[0].product.master_product; });
            setSelectedItems(selected);
        }
        else {
            setSelectedItems([]);
        }
    };
    if (!QuoteStore.quote) {
        return null;
    }
    var master_products = _.groupBy(QuoteStore.quote.line_items, function (item) {
        return item.product.master_product;
    });
    var isIndeterminate = selectedItems.length > 0 && selectedItems.length < _.size(master_products);
    /**
     * Add existing products from other quotes to the current quote in process.
     */
    var add_existing_items = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, QuoteStore.createLineItemFromExistingLineItems(selectedRowKeys)];
                case 1:
                    _a.sent();
                    reloadTableRows();
                    notification.success({ message: 'Success', description: "Items were added correctly." });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    notification.error({ message: 'Error', description: React.createElement(ErrorRender, { error: error_1 }) });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Search for existing products in other quotes, related to the account of the quote in process in modal-previous-parts.
     * @param value
     */
    var onSearchPreviousPart = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, QuoteStore.searchPreviousParts({
                        name: value,
                        account: (_a = QuoteStore.contact) === null || _a === void 0 ? void 0 : _a.account.id,
                    }).then(function () { })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Function that runs when you stop typing in the search field of a part in modal-previous-parts.
     */
    var handleSearchPreviousPart = _.debounce(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!value) return [3 /*break*/, 2];
                    return [4 /*yield*/, onSearchPreviousPart(value)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    QuoteStore.clean_previous_items();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, 500);
    /**
     * Reset the selectedRowKeys in table-previous-parts.
     */
    var reloadTableRows = function () {
        setRowKeys([]);
    };
    /**
     * Captures the keys of the selected rows in table-previous-parts.
     * @param selectedRowKeys
     */
    var onSelectChange = function (selectedRowKeys) {
        setRowKeys(selectedRowKeys);
    };
    /**
     * Renders the checkboxes in the table-previous-parts.
     */
    var rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };
    if (((_c = QuoteStore.quote.line_items) === null || _c === void 0 ? void 0 : _c.length) === 0 && (PartStore === null || PartStore === void 0 ? void 0 : PartStore.fileParts.length) > 0) {
        React.createElement(LoadingOutlined, null);
    }
    return (React.createElement("div", null,
        QuoteStore.isRequote && (React.createElement(Alert, { className: "flex flex-row mb-4", type: "warning", showIcon: true, message: 'Please Note', description: React.createElement(React.Fragment, null, "This is a Requote") })),
        React.createElement(React.Fragment, null,
            ((_d = QuoteStore.quote.line_items) === null || _d === void 0 ? void 0 : _d.length) > 0 ? (React.createElement(React.Fragment, null,
                bulkUpdate && (React.createElement(Modal, { title: 'Bulk Update', open: bulkUpdate, onCancel: function () { return showBulkUpdate(false); }, footer: null, centered: true },
                    React.createElement(BulkUpdateItems, { master_products: selectedItems, handleClose: function () { return showBulkUpdate(false); }, for_staff: for_staff }))),
                React.createElement("div", { className: "d-flex flex-row align-items-center w-100 justify-content-between" },
                    React.createElement("h2", { className: "my-2 font-bold", style: { fontSize: '1.5rem' } },
                        "Parts & Specifications",
                        React.createElement("div", { className: "d-block" },
                            React.createElement(Button, { type: "link", className: "m-0 p-0 text-muted", onClick: function () { return QuoteStore.collapseAllLineItems(); } }, "Collapse All"),
                            React.createElement(Divider, { type: "vertical", className: "mx-1" }),
                            React.createElement(Button, { type: "link", className: "m-0 p-0 text-muted", onClick: function () { return QuoteStore.expandAllLineItems(); } }, "Expand All"))),
                    React.createElement("div", { className: "ml-auto text-right flex-grow-1" },
                        ((_e = QuoteStore.quote.line_items) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(Checkbox, { checked: selectedItems.length > 0, indeterminate: isIndeterminate, onChange: handleSelectAll }, "Select All")),
                        React.createElement(Dropdown, { disabled: selectedItems.length === 0 || QuoteStore.isRequote, trigger: ['click'], placement: "bottomRight", overlay: React.createElement(Menu, { items: [
                                    {
                                        label: 'Bulk Update',
                                        key: '1',
                                        onClick: function () { return showBulkUpdate(true); },
                                    },
                                    {
                                        label: 'Delete Selected Parts',
                                        key: '2',
                                        onClick: function () { return setDeletePartsModalVisible(true); },
                                    },
                                ] }) },
                            React.createElement(Button, { size: "small" }, "Actions"))),
                    React.createElement("div", { className: 'ml-auto' }, (current_user === null || current_user === void 0 ? void 0 : current_user.is_staff) && ((_f = QuoteStore.contact) === null || _f === void 0 ? void 0 : _f.account) && (React.createElement("div", { style: {
                            textAlign: 'right',
                        } },
                        React.createElement(Button, { size: 'small', type: 'primary', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    setModalPreviousParts(true);
                                    return [2 /*return*/];
                                });
                            }); } }, "Add a previously quoted product"))))),
                React.createElement("div", null, _.chain(master_products)
                    .map(function (line_items, key) {
                    return { key: key, line_items: line_items };
                })
                    .sortBy(function (_a) {
                    var line_items = _a.line_items;
                    var line_item = line_items[0];
                    return line_item.position;
                })
                    .map(function (_a) {
                    var line_items = _a.line_items, key = _a.key;
                    // Send one item to display the master product data
                    var line_item = line_items[0];
                    var isSelected = selectedItems.includes(line_item.product.master_product);
                    return (React.createElement("div", { key: key, className: "mb-3" },
                        React.createElement(MasterProduct, { for_staff: for_staff, item: line_item, line_items: line_items, isSelected: isSelected, handleSelect: function (selected) {
                                handleSelect(line_item.product.master_product, selected);
                            } })));
                })
                    .value()))) : null,
            React.createElement(UploadPartFilesMultiple, { is_staff: current_user === null || current_user === void 0 ? void 0 : current_user.is_staff, showUploadTypeTabs: false, showExtraUploadInfo: false, showITARNotice: QuoteStore.quote.line_items.length === 0 }),
            QuoteStore.quote.uid && (React.createElement("div", { style: { display: 'block' } },
                React.createElement(SocketConnection, null))),
            ((_g = QuoteStore.quote.line_items) === null || _g === void 0 ? void 0 : _g.length) > 0 ? (React.createElement(React.Fragment, null,
                React.createElement(CheckoutForm, { onQuoteSubmitted: onQuoteSubmitted, isReceiveSms: isReceiveSms, onRequestOrder: onRequestOrder, is_tester: current_user === null || current_user === void 0 ? void 0 : current_user.is_tester }),
                modalPreviousParts && (current_user === null || current_user === void 0 ? void 0 : current_user.is_staff) && ((_h = QuoteStore.contact) === null || _h === void 0 ? void 0 : _h.account) && (React.createElement(Modal, { className: 'modal-previous-parts', title: React.createElement(React.Fragment, null,
                        React.createElement("span", null, "Search by: "),
                        React.createElement(Radio.Group, { buttonStyle: "solid", onChange: function (checked) {
                                QuoteStore.clean_previous_items();
                                setTypeSearch(checked.target.value);
                            }, value: typeSearch },
                            React.createElement(Radio.Button, { value: 'quote' }, "Quote"),
                            React.createElement(Radio.Button, { value: 'parts' }, "Parts"))), open: modalPreviousParts, closable: true, width: 1250, onCancel: function () {
                        reloadTableRows();
                        QuoteStore.clean_previous_items();
                        setModalPreviousParts(false);
                    }, footer: false },
                    React.createElement("div", { className: 'flex flex-row pb-4' },
                        typeSearch === 'quote' ? (React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Quotes, { show_in_zoho_alert: false, className: 'input-field', onSelect: function (item) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, QuoteStore.getQuoteLineItems(item.key)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, filters: {
                                    stage: 'Completed',
                                }, account: (_k = (_j = QuoteStore.contact) === null || _j === void 0 ? void 0 : _j.account) === null || _k === void 0 ? void 0 : _k.id }))) : (React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Search, { allowClear: true, onSearch: onSearchPreviousPart, onChange: function (data) {
                                    return handleSearchPreviousPart(data.target.value);
                                }, placeholder: "Search by part name...", size: "small" }))),
                        React.createElement("div", { className: 'w-1/2 mx-4' },
                            React.createElement(Button, { type: 'primary', size: 'small', disabled: selectedRowKeys.length <= 0, onClick: function () { return add_existing_items(); } },
                                "Add (",
                                selectedRowKeys.length,
                                ") Parts"),
                            React.createElement(Button, { className: 'mx-4', size: 'small', type: "default", onClick: reloadTableRows }, "Remove selected"))),
                    React.createElement("div", null,
                        React.createElement(Table, { className: 'table-previous-parts', rowKey: function (record) { return String(record.id); }, rowSelection: rowSelection, columns: [
                                {
                                    title: 'Preview',
                                    dataIndex: 'step_file',
                                    render: function (t, record) {
                                        var _a;
                                        if (record.product.attachments.file3d &&
                                            record.product.attachments.file3d.image) {
                                            return (React.createElement("div", null,
                                                React.createElement("img", { style: { width: 60, cursor: 'pointer' }, src: (_a = record.product.attachments.file3d) === null || _a === void 0 ? void 0 : _a.image })));
                                        }
                                        return React.createElement(FileUnknownOutlined, { style: { fontSize: 40 } });
                                    },
                                },
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    render: function (name, record) {
                                        var _a, _b;
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement("div", null, name),
                                            React.createElement("div", { style: { display: 'flex' } },
                                                React.createElement("span", { style: { fontSize: 12 } },
                                                    record.product.dimensions_dict.length.toFixed(3),
                                                    record.product.dimensions_dict.unit,
                                                    " x",
                                                    ' ', (_a = record.product.dimensions_dict) === null || _a === void 0 ? void 0 :
                                                    _a.width.toFixed(3),
                                                    record.product.dimensions_dict.unit,
                                                    " x",
                                                    ' ', (_b = record.product.dimensions_dict) === null || _b === void 0 ? void 0 :
                                                    _b.height.toFixed(3),
                                                    record.product.dimensions_dict.unit))));
                                    },
                                },
                                {
                                    title: 'Source',
                                    dataIndex: 'source',
                                    render: function (_, record) {
                                        var _a, _b, _c;
                                        return (React.createElement("div", null,
                                            React.createElement("div", null, (_a = record.quote_related) === null || _a === void 0 ? void 0 : _a.source),
                                            React.createElement("div", null,
                                                ((_b = record.quote_related) === null || _b === void 0 ? void 0 : _b.is_dynamic) && (React.createElement(Badge, { count: 'Dynamic', style: {
                                                        fontSize: 10,
                                                        height: 20,
                                                        backgroundColor: '#7576c4',
                                                    } })),
                                                ((_c = record.quote_related) === null || _c === void 0 ? void 0 : _c.type) === 'Requote' && (React.createElement(Badge, { count: 'Requote', style: { backgroundColor: '#8E44AD' } })))));
                                    },
                                },
                                {
                                    title: 'Information',
                                    dataIndex: 'information',
                                    render: function (_, record) { return (React.createElement(React.Fragment, null,
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Material: "),
                                            record.product.material
                                                ? record.product.material
                                                : record.product.custom_material),
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Coating: "),
                                            record.product.coatings
                                                ? record.product.coatings
                                                : record.product.custom_coating),
                                        React.createElement("div", null,
                                            React.createElement("span", null, "Finish: "),
                                            record.product.finishes
                                                ? record.product.finishes
                                                : record.product.custom_finish))); },
                                },
                                {
                                    title: 'Unit Price',
                                    dataIndex: 'unit_price',
                                    render: function (unit_price) { return React.createElement(Price, { value: unit_price }); },
                                },
                                {
                                    title: 'Qty',
                                    dataIndex: 'quantity',
                                },
                                {
                                    title: 'Total Price',
                                    dataIndex: 'total_price',
                                    render: function (total_price) { return React.createElement(Price, { value: total_price }); },
                                },
                            ], loading: QuoteStore.loading_quote_items, dataSource: QuoteStore.previous_quoted_parts })))))) : null),
        React.createElement(Modal, { open: deletePartsModalVisible, centered: true, onCancel: function () { return setDeletePartsModalVisible(false); }, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a, selectedItems_1, selectedItems_1_1, line_item, e_1_1;
                var _b, e_1, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            if (!QuoteStore.quote) return [3 /*break*/, 12];
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 6, 7, 12]);
                            _a = true, selectedItems_1 = __asyncValues(selectedItems);
                            _e.label = 2;
                        case 2: return [4 /*yield*/, selectedItems_1.next()];
                        case 3:
                            if (!(selectedItems_1_1 = _e.sent(), _b = selectedItems_1_1.done, !_b)) return [3 /*break*/, 5];
                            _d = selectedItems_1_1.value;
                            _a = false;
                            try {
                                line_item = _d;
                                QuoteStore.deleteMasterProduct(line_item);
                            }
                            finally {
                                _a = true;
                            }
                            _e.label = 4;
                        case 4: return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 12];
                        case 6:
                            e_1_1 = _e.sent();
                            e_1 = { error: e_1_1 };
                            return [3 /*break*/, 12];
                        case 7:
                            _e.trys.push([7, , 10, 11]);
                            if (!(!_a && !_b && (_c = selectedItems_1.return))) return [3 /*break*/, 9];
                            return [4 /*yield*/, _c.call(selectedItems_1)];
                        case 8:
                            _e.sent();
                            _e.label = 9;
                        case 9: return [3 /*break*/, 11];
                        case 10:
                            if (e_1) throw e_1.error;
                            return [7 /*endfinally*/];
                        case 11: return [7 /*endfinally*/];
                        case 12:
                            setDeletePartsModalVisible(false);
                            return [2 /*return*/];
                    }
                });
            }); } },
            React.createElement(Title, { level: 3 }, "Delete selected parts from quote?"))));
});
export default QuoteOneTrueForm;
