var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Radio } from 'antd';
export var QuestionYesOrNo = function (props) {
    var _a = useState(undefined), value = _a[0], setValue = _a[1];
    var onChange = function (e) {
        setValue(e.target.value);
        props.setAnswer(e.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex justify-center p-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", null,
                    React.createElement("span", { className: 'font-bold' }, props.question)),
                React.createElement("div", { className: 'flex justify-center' },
                    React.createElement(Radio.Group, __assign({ className: 'flex flex-row', value: props.default !== undefined ? props.default : value }, props, { onChange: onChange }),
                        React.createElement(Radio, { value: true }, "Yes"),
                        React.createElement(Radio, { value: false }, "No")))))));
};
