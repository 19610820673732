import React, { useState } from 'react';
import { Checkbox, Col, Row, Spin } from 'antd';
import { Price, Percentage } from '@partsbadger/library';
import { observer } from 'mobx-react';
export var styleBody = {
    padding: '3px 0',
    textAlign: 'center',
};
var QuoteLineItemQuantityForm = function (_a) {
    var variation = _a.variation, isReadOnly = _a.isReadOnly, handleUpdate = _a.handleUpdate, uniPriceAdjustmentType = _a.uniPriceAdjustmentType;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    return (React.createElement(Col, { span: 24 },
        React.createElement(Spin, { spinning: loading },
            React.createElement(Row, null,
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Checkbox, { disabled: isReadOnly, checked: variation.displayed, onClick: function (e) {
                                var _a;
                                //@ts-ignore
                                setLoading(true);
                                handleUpdate({
                                    //@ts-ignore
                                    displayed: (_a = e.target) === null || _a === void 0 ? void 0 : _a.checked,
                                }).finally(function () { return setLoading(false); });
                            } }),
                        "\u2003",
                        React.createElement("label", { style: { fontWeight: 'bold' } }, variation.quantity))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody }, variation.lead_time)),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.vendor_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.material_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.shipping_cost }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        uniPriceAdjustmentType == '$' && React.createElement(Price, { value: variation.unit_price_adjustment }),
                        uniPriceAdjustmentType == '%' && React.createElement(Percentage, { value: variation.unit_price_adjustment }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement(Price, { value: variation.unit_price }))),
                React.createElement(Col, { span: 3 },
                    React.createElement("div", { style: styleBody },
                        React.createElement("label", { style: { fontWeight: 'bold' } },
                            React.createElement("span", null,
                                React.createElement(Price, { value: variation.total_price })))))))));
};
export default observer(QuoteLineItemQuantityForm);
