import * as ActionTypes from '../ActionTypes';

export const Deals = (
    state = {
        isLoading: false,
        error: null,
        showAddDealForm: false,
        entities: [],
        entity: {}
    },
    action) => {

    switch (action.type) {
        case ActionTypes.DEAL_LOADED:
            return {...state, isLoading: false, error: null, entity: action.payload};

        case ActionTypes.DEAL_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.DEALS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};

        case ActionTypes.DEALS_LOADING:
            return {...state, isLoading: true, error: null,}

        case ActionTypes.DEAL_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.DEALS_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.DEAL_SHOW_ADD_FORM:
            return {...state, showAddDealForm: true};

        case ActionTypes.DEAL_HIDE_ADD_FORM:
            return {...state, showAddDealForm: false};

        case ActionTypes.DEAL_ADDED:
            return {
                ...state,
                isLoading: false,
                showAddDealForm: false,
                entities: state.entities.concat(action.payload)
            };

        default:
            return state;
    }
};