import React from 'react';
import { observer } from 'mobx-react';
import { Card } from 'antd';
import moment from 'moment';
import IntakeStore from '../Stores/IntakeStore';
var SalesOrderInfo = observer(function (_a) {
    var _b, _c;
    var salesOrder = _a.salesOrder, contact = _a.contact, pdSalesOrder = _a.pdSalesOrder;
    return (React.createElement("div", { className: "text-lg flex flex-wrap flex-row justify-center" },
        React.createElement(Card, { title: "General", style: { minWidth: 300 }, className: "m-2" },
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Purchase Order:"),
                " ",
                salesOrder.purchase_order),
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Terms:"),
                " ",
                salesOrder.payment_terms),
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Required Ship Date:"),
                " ",
                React.createElement("br", null),
                moment(salesOrder.required_ship_date).format('ddd MMM DD, YYYY'))),
        salesOrder.hot_order || salesOrder.hot_ship_date ? (React.createElement(Card, { title: React.createElement("span", { "aria-label": "fire", role: "img" }, "Hot Order \uD83D\uDD25"), className: "m-2 font-bold", style: { minWidth: 300, backgroundColor: 'indianred' } },
            React.createElement("h1", { className: "font-bold" }, "SHIP ASAP, DO NOT HOLD"),
            React.createElement("p", { className: "font-bold" },
                "Hot Ship Date: ",
                React.createElement("br", null),
                moment(salesOrder.hot_ship_date).format('ddd MMM DD, YYYY')),
            React.createElement("p", { className: "font-bold" },
                "Hot Order Notes: ",
                React.createElement("br", null),
                salesOrder.hot_order_notes))) : null,
        React.createElement(Card, { title: 'Order Requirements', style: { minWidth: 300 }, className: "m-2" },
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "In House Requirements:"),
                " ",
                React.createElement("br", null),
                salesOrder.in_house_requirements === null ? 'None' : "".concat(salesOrder.in_house_requirements)),
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Additional Requirements:"),
                " ",
                React.createElement("br", null),
                (salesOrder === null || salesOrder === void 0 ? void 0 : salesOrder.additional_requirements) == null ? (React.createElement("span", null,
                    "None, but DOUBLE CHECK in ZOHO",
                    React.createElement("br", null),
                    "Tell the codemokeys this SO's additional_requirements are null")) : ((_b = salesOrder === null || salesOrder === void 0 ? void 0 : salesOrder.additional_requirements) === null || _b === void 0 ? void 0 : _b.map(function (req) { return (React.createElement("span", { key: req },
                    req,
                    React.createElement("br", null))); }))),
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Shipping Options:"),
                " ",
                React.createElement("br", null),
                pdSalesOrder !== null
                    ? pdSalesOrder.shipping_options != null
                        ? pdSalesOrder === null || pdSalesOrder === void 0 ? void 0 : pdSalesOrder.shipping_options
                        : ''
                    : ''),
            React.createElement("p", null,
                React.createElement("span", { className: "font-bold" }, "Shipping Requirements:"),
                React.createElement("br", null),
                pdSalesOrder !== null
                    ? (_c = pdSalesOrder === null || pdSalesOrder === void 0 ? void 0 : pdSalesOrder.shipping_requirements) === null || _c === void 0 ? void 0 : _c.map(function (item) { return (React.createElement("span", { key: item },
                        item,
                        React.createElement("br", null))); })
                    : '')),
        React.createElement(Card, { title: 'Shipping Info', style: { minWidth: 300 }, className: "m-2" },
            React.createElement("p", { className: "font-bold" }, "Shipping Address"),
            React.createElement("div", { className: "my-2" },
                salesOrder.shipping_name != null ? (React.createElement("div", null, salesOrder.shipping_name)) : (React.createElement("div", null,
                    contact['first_name'],
                    " ",
                    contact['last_name'])),
                salesOrder.shipping_street,
                " ",
                React.createElement("br", null),
                salesOrder.shipping_city,
                ",\u00A0",
                salesOrder.shipping_state,
                "\u00A0",
                salesOrder.shipping_code,
                " ",
                salesOrder.shipping_country,
                salesOrder.shipping_zip_code,
                " ",
                React.createElement("br", null)),
            React.createElement("div", { className: "my-2" },
                React.createElement("div", { className: "font-bold" }, "Carrier"),
                React.createElement("div", null, salesOrder.carrier)),
            React.createElement("div", { className: "my-2" },
                React.createElement("div", { className: "font-bold" }, "Shipping method"),
                React.createElement("div", null, salesOrder.shipping_method)),
            React.createElement("div", { className: "my-2" },
                React.createElement("div", { className: "font-bold" }, "Shipping account number"),
                React.createElement("div", null, salesOrder.shipping_account_number))),
        IntakeStore.contact ? (React.createElement(Card, { title: "Contact", style: { minWidth: 300 }, className: "mb-2" },
            React.createElement("p", null,
                IntakeStore.contact.first_name,
                "\u00A0",
                IntakeStore.contact.last_name),
            React.createElement("p", null,
                React.createElement("a", { href: "mailto:".concat(IntakeStore.contact.email) }, IntakeStore.contact.email),
                ' ',
                IntakeStore.contact.phone),
            IntakeStore.contact.shipping_street &&
                IntakeStore.contact.shipping_city &&
                IntakeStore.contact.shipping_state && (React.createElement("div", null,
                IntakeStore.contact.shipping_street,
                " ",
                React.createElement("br", null),
                IntakeStore.contact.shipping_city,
                "\u00A0",
                IntakeStore.contact.shipping_state,
                "\u00A0",
                IntakeStore.contact.shipping_code,
                " ",
                React.createElement("br", null),
                IntakeStore.contact.shipping_country,
                " ",
                React.createElement("br", null))))) : (React.createElement("div", { className: "m-2" }, "No Contact?"))));
});
export default SalesOrderInfo;
