import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input, InputNumber, Row } from 'antd';
import { Price } from "@partsbadger/library";
import { v4 } from 'uuid'


const FormAdditionalRequirement = Form.create()((props) => {

    const {item, onChange, form} = props;
    const {getFieldDecorator} = form;

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    id: item?.id || v4(),
                    additional_requirement: values.additional_requirement,
                    price: values.price
                };
                onChange(payload);
                form.resetFields();
            }
        })
    };


    const handleKeyEnter = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e)
        }
    };

    return (<Form onSubmit={handleSubmit}>
            <Row>
                <Col sm={8}>
                    <Form.Item>

                        {getFieldDecorator('additional_requirement', {
                            rules: [{required: true, message: 'Please select a value!'}],
                            initialValue: item?.additional_requirement
                        })(
                            <Input size={"small"} style={{width: '90%'}} placeholder={"Application"}
                                   onKeyPress={handleKeyEnter}/>
                        )}

                    </Form.Item>
                </Col>
                <Col sm={8}>

                    <Form.Item>
                        {getFieldDecorator('price', {
                            rules: [{
                                required: true
                            }],
                            initialValue: item?.price
                        })(
                            <InputNumber size={"small"} style={{width: '90%'}} placeholder={"0.00"}
                                         onPressEnter={handleSubmit}/>
                        )}
                    </Form.Item>
                </Col>
                <Col sm={8}>
                    <Form.Item>
                        <Button size={"small"} type="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Form.Item>
                </Col>

            </Row>


        </Form>
    )


});


export const SalesOrderRequirements = ({value, onChange}) => {


    const [editingKey, setEditingKey] = useState();

    return (
        <div className={"row-requirements"}>
            <Row>
                <Col sm={8}>
                    Application
                </Col>
                <Col sm={8}>
                    Application Cost
                </Col>
                <Col sm={8}>
                    Action
                </Col>
            </Row>

            {value?.map(item => {

                if (editingKey === item.id) {
                    return <Row>
                        <FormAdditionalRequirement item={item} onChange={v => {
                            setEditingKey();
                            const new_values = value.map(r => {
                                return r.id === item.id ? v : r;
                            });
                            onChange(new_values);
                        }}/>
                    </Row>
                }

                return (
                    <Row key={item.id}
                         style={{
                             borderBottom: '1px solid #d4d4d4',
                         }}
                    >
                        <Col sm={8}>
                            {item.additional_requirement}
                        </Col>
                        <Col sm={8}>
                            <Price value={item.price}/>
                        </Col>
                        <Col sm={8}>
                            <span className={'link'} key="remove" onClick={() => {
                                onChange(value.filter(r => r.id !== item.id))
                            }}><LegacyIcon type={"delete"}/>
                            </span>
                            <Divider type={"vertical"}/>

                            <span className={'link'} key="edit"
                                  onClick={() => setEditingKey(item.id)}>
                                <LegacyIcon type={'edit'}/>
                            </span>

                        </Col>
                    </Row>
                );
            })}
            <Row>
                <FormAdditionalRequirement onChange={v => {
                    const new_values = value ? [v, ...value] : [v];
                    onChange(new_values);
                }}/>
            </Row>
        </div>
    );
};

