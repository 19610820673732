import React, {FunctionComponent, useState} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import {RichTextEditor} from './RichTextEditor';


export const Compose: FunctionComponent = () => {
    const [fileList, setFileList] = useState<any[]>([]);

    const messageUploadProps = {
        name: 'file',
        multiple: true,

        onRemove: (file: any) => {
            setFileList(fileList.filter(f => f.uid !== file.uid));
        },

        beforeUpload: (file: any) => {
            setFileList([...fileList, file]);
            console.log(fileList);
            return false;
        },

        fileList,
    };

    return (
        <div className="p-8">
            <div>
                <div className="flex items-end">
                    <div className="mx-4">
                        <RichTextEditor/>
                    </div>

                    <div>
                        <Upload.Dragger className="px-4">
                            <span className="font-bold ant-upload-text">Attachments</span>
                            <div><PlusOutlined /></div>
                        </Upload.Dragger>
                        <Button type="primary" className="block mx-auto mt-4">Send Message</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
