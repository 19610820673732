import React from 'react';
import { notification, Popconfirm, Spin } from 'antd';
import { get } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';
import { SyncOutlined } from '@ant-design/icons';

interface Props {
    module: string;
    id: number | string;
    onFinish: () => void;
    title?: string;
}

const PullFromZoho = (props: Props) => {
    const [isSending, setIsSending] = React.useState(false);

    const handlePullFromZoho = () => {
        setIsSending(true);
        get(`/staff/${props.module}/${props.id}/pull-from-zoho/`)
            .then(() => {
                props.onFinish();
            })
            .catch(err => {
                notification.error({
                    duration: 10,
                    message: 'Error pulling from Zoho',
                    description: <ErrorRender error={err} />,
                    placement: 'top',
                });
            })
            .finally(() => setIsSending(false));
    };

    return (
        <Popconfirm
            placement="topLeft"
            title={'Are you sure to pull this record in Zoho?'}
            onConfirm={handlePullFromZoho}
            okText="Yes"
            cancelText="No"
        >
            <Spin spinning={isSending}>
                <SyncOutlined
                    title={'Click to pull the object from Zoho to PartsBadger'}
                    type="default"
                    style={{
                        color: 'grey',
                    }}
                >
                    Pull from Zoho
                </SyncOutlined>
            </Spin>
        </Popconfirm>
    );
};

export default PullFromZoho;
