var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
var semver = require('semver');
var APP_VERSION_KEY = 'app_version';
/**
 * Only use for new Features
 * @param props
 * @constructor
 */
var Tour = function (props) {
    var _a, _b;
    var _c = useState([]), steps = _c[0], setSteps = _c[1];
    var _d = useState(false), run = _d[0], setRun = _d[1];
    var TOUR_KEY = "tour-".concat(props.tourKey.toLowerCase());
    var tourDone = (_a = window.localStorage.getItem(TOUR_KEY)) !== null && _a !== void 0 ? _a : '0.0.1';
    var app_version = (_b = window.localStorage.getItem(APP_VERSION_KEY)) !== null && _b !== void 0 ? _b : '1.0';
    var setAsCompleted = function () {
        window.localStorage.setItem(TOUR_KEY, app_version);
        setRun(false);
    };
    useEffect(function () {
        //Check if the tour for the version was done
        try {
            if (semver.lt(tourDone, app_version)) {
                var _filtered_steps = props.steps
                    .filter(function (step) {
                    try {
                        return semver.lt(tourDone, step.version);
                    }
                    catch (e) {
                        console.error(e);
                        return false;
                    }
                })
                    .map(function (step) {
                    return __assign(__assign({}, step), { title: step.version });
                });
                setSteps(_filtered_steps);
                setRun(true);
            }
        }
        catch (e) {
            console.error(e);
        }
    }, [tourDone, app_version, TOUR_KEY]);
    var handleJoyrideCallback = function (_a) {
        var action = _a.action;
        if (action == 'close' || action == 'skip' || action == 'reset') {
            setAsCompleted();
        }
    };
    if (!steps.length) {
        return null;
    }
    return (React.createElement(Joyride, { callback: handleJoyrideCallback, run: run, debug: true, continuous: true, showSkipButton: true, steps: steps, showProgress: true, styles: {
            options: {
                primaryColor: 'green',
                zIndex: 1000,
            },
        } }));
};
export default Tour;
