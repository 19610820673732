import React, { useEffect, useState } from 'react';
import { post } from '../../shared';
import { SalesOrderOuttakesProps } from '@partsbadger/library/lib/Types/TypesSalesOrder';
import { Card, Table, Divider, Row, Col, Button } from 'antd';
import moment, { Moment } from 'moment';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { get } from '../../shared/api';
import { DateRangePicker } from '@partsbadger/library';

const FileDownload = require('js-file-download');

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const ZOuttakeReport = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SalesOrderOuttakesProps[]>([]);

    const { form } = props;

    const { getFieldDecorator } = form;

    const getData = async (params: { created_time_min: string; created_time_max: string; report_type: string }) => {
        setLoading(true);
        get(`staff/outtakes/z-outtakes-sales-order/`, params)
            .then((response: any) => {
                if (params.report_type === 'json') {
                    setData(response.data);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'z-outtakes.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                    report_type: 'json',
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                getData(params);
            }
        });
    };

    const handleDownload = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                    report_type: 'csv',
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                getData(params);
            }
        });
    };
    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'sales_order_name',
            key: 'sales_order_name',
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Qty. Ordered',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Qty. Out at Vendor',
            dataIndex: 'qty_out_at_vendor',
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Po Number',
            dataIndex: 'po_number',
        },
        {
            title: 'Due From Vendor',
            dataIndex: 'due_from_vendor',
        },
        {
            title: 'Date out to Z',
            dataIndex: 'outtake_date',
            render: (_: string, record: any) => {
                return moment(record.outtake_date).format('YYYY-MM-DD');
            },
        },
        // {
        //     title: 'Completed at',
        //     dataIndex: 'outtake_date',
        //     render: (_: string, record: any) => {
        //         return moment(record.outtake_date).format('YYYY-MM-DD');
        //     },
        // },
        {
            title: 'Days since completed',
            dataIndex: 'days_since_completed',
            render: (_: string, record: any) => {
                return Math.abs(moment(record.outtake_date, 'YYYY-MM-DD').diff(moment().startOf('day'), 'days'));
            },
        },
        {
            title: 'Zoho Link',
            dataIndex: 'po_zoho_link',
            key: 'po_zoho_link',
            render: (_: string, record: any) => {
                if (record.po_zoho_link) {
                    return (
                        <a href={record.po_zoho_link} target="_blank" rel="noreferrer">
                            <span>Purchase order</span>
                        </a>
                    );
                }

                return '';
            },
        },
    ];

    return (
        <>
            <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>

                        <Col>
                            <Button onClick={handleDownload}>Download CSV</Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <Divider>
                <div className={'title'}>Z Outtakes</div>
            </Divider>
            <Card>
                <div className={'w-full'}>
                    <Table key={'outtakes'} columns={[...columns]} loading={loading} dataSource={data} />
                </div>
            </Card>
        </>
    );
};

export const ZOuttakeForm = Form.create()(ZOuttakeReport);
