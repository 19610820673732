import { Divider } from "antd";
import Tag from "antd/es/tag";
import React from "react";


export const PartDescription = ({quote_has_product}) => {

    const product = quote_has_product?.product;

    return (<div>
            <span className="font-bold">{product?.name}</span> <Divider type={'vertical'}/>
            <span className="italic text-xs">{product?.dimensions}</span> <Divider type={'vertical'}/>
            {product?.has_file_3d ? (<Tag>3d</Tag>) : <span/>}
            {product?.has_file_2d ? (<Tag>2d</Tag>): <span/>}
            <div>
                <span>Material {product?.material?.name}</span> <Divider type={'vertical'}/>
                <span>Coating {product?.coatings && product.coatings[0]?.name}</span> <Divider type={'vertical'}/>
                <span>Finish {product?.finishes && product.finishes[0]?.name}</span> <Divider type={'vertical'}/>
            </div>
        </div>

    )

};