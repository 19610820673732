import React, { Fragment, useEffect, useState } from 'react';
import { Col, Divider, Row, Table, Typography, Tag, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import { get } from '../../shared';

import { PaginationComponent } from '../../components/Inputs/Pagination';

import { Price } from '@partsbadger/library';
import { QuickTurnSalesOrder } from '@partsbadger/types';
import { PaginationProps } from 'antd/es/pagination';
import { QuickTurnOrdersFilterForm } from '../../components/SalesOrder/QuickTurnOrdersFilter';
import { SorterResult } from 'antd/es/table/interface';
import { useQueryParameters } from '@partsbadger/utils';
import dayjs from 'dayjs';

const { Text } = Typography;

export interface OrderQuery {
    page?: number;
    search?: string;
    status?: string;
    ordering?: string;
    page_size?: number;
}

export const QuickTurnOrdersList = (props: any) => {
    const queryParams = useQueryParameters();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const currentPageUrl = Number(queryParams.get('page')) || '';
    const searchUrl = queryParams.get('search') || '';
    const ordering = queryParams.get('ordering') || '-created_time';
    const status = queryParams.get('status') || '';

    const [pagination, setPagination] = useState<PaginationProps>({});

    const orderQuery: OrderQuery = {};

    if (currentPageUrl !== '') {
        orderQuery.page = currentPageUrl;
    }

    if (searchUrl) {
        orderQuery.search = searchUrl;
    }

    if (ordering && ordering !== '') {
        orderQuery.ordering = ordering;
    }

    if (status) {
        orderQuery.status = status;
    }

    const columns: ColumnsType<QuickTurnSalesOrder> = [
        {
            title: 'Created',
            key: 'created_time',
            width: 150,
            render: (record: QuickTurnSalesOrder) => moment(record?.created_time).format('YYYY-MM-DD'),
            sorter: (a, b) => {
                const dateA = dayjs(a.created_time).format('YYYY-MM-DD') || '';
                const dateB = dayjs(b.created_time).format('YYYY-MM-DD') || '';
                return dateA.localeCompare(dateB);
            },
        },
        {
            title: 'Order Date',
            key: 'order_placed_time',
            width: 150,
            render: (record: QuickTurnSalesOrder) => moment(record?.order_placed_time).format('YYYY-MM-DD'),
            sorter: (a, b) => {
                const dateA = dayjs(a.order_placed_time).format('YYYY-MM-DD') || '';
                const dateB = dayjs(b.order_placed_time).format('YYYY-MM-DD') || '';
                return dateA.localeCompare(dateB);
            },
        },
        {
            title: 'Order Number',
            key: 'name',
            width: 250,
            render: (record: QuickTurnSalesOrder) => (
                <Link to={`/quickturn/sales-orders/${record.id}`}>{record.name}</Link>
            ),
            sorter: (a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            },
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: QuickTurnSalesOrder) => (
                <Tag color={record?.status === 'Order Received' ? 'blue' : 'default'}>{record?.status}</Tag>
            ),
            sorter: true,
        },
        {
            title: 'User ID',
            key: 'user_id',
            dataIndex: 'user_id',
            sorter: (a, b) => a.user_id - b.user_id,
        },
        {
            title: 'Billing Name',
            key: 'billing_name',
            dataIndex: 'billing_name',
            sorter: (a, b) => {
                if (a.billing_name < b.billing_name) return -1;
                if (a.billing_name > b.billing_name) return 1;
                return 0;
            },
        },
        {
            title: 'Line Items',
            key: 'line_items',
            render: (record: QuickTurnSalesOrder) => record?.line_items?.length,
        },
        {
            title: 'Subtotal',
            key: 'subtotal',
            width: 150,
            align: 'center',
            render: (record: QuickTurnSalesOrder) => <Price value={Number(record?.subtotal)} />,
            sorter: (a, b) => Number(a.subtotal) - Number(b.subtotal),
        },
        {
            title: 'Shipping',
            key: 'shipping',
            width: 150,
            align: 'center',
            render: (record: QuickTurnSalesOrder) => <Price value={Number(record?.shipping)} />,
            sorter: (a, b) => Number(a.shipping) - Number(b.shipping),
        },
        {
            title: 'Total',
            key: 'grand_total',
            width: 150,
            align: 'right',
            render: (record: QuickTurnSalesOrder) => (
                <Text strong>
                    <Price value={Number(record?.grand_total)} />
                </Text>
            ),
            sorter: (a, b) => Number(a.grand_total) - Number(b.grand_total),
        },
    ];

    const getOrders = () => {
        setLoading(true);

        get('quickturn/api/staff/sales-order', orderQuery)
            .then((response: any) => {
                console.log(response.data.results);
                console.log(response.data.count);
                setOrders(response.data.results);
                setPagination({
                    defaultPageSize: 30,
                    pageSize: 30,
                    showSizeChanger: false,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, [props.location.search]);

    const handleTableChange: TableProps<QuickTurnSalesOrder>['onChange'] = (_pagination, _filters, sorter, extra) => {
        if (extra.action === 'sort') {
            const orderType = (sorter as SorterResult<QuickTurnSalesOrder>)?.order;
            const columnKey = (sorter as SorterResult<QuickTurnSalesOrder>)?.columnKey;
            const sorterColumn = (sorter as SorterResult<QuickTurnSalesOrder>)?.column;

            const column = orderType === 'ascend' || orderType === undefined ? columnKey : '-' + columnKey;
            if (column !== undefined && sorterColumn !== undefined) {
                queryParams.set('ordering', column?.toString());
            } else {
                queryParams.delete('ordering');
            }
            history.replace({ search: queryParams.toString() });
        }
    };

    return (
        <Fragment>
            <QuickTurnOrdersFilterForm />

            <Divider>
                <div className={'title'}>QuickTurn Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table
                        rowKey={'id'}
                        columns={columns}
                        dataSource={orders}
                        loading={loading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};
