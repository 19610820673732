import { action, makeObservable, observable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
import { IBlock, IModelVariable, ITemplate } from '../types';

class TemplatesStore {
    isLoadingModelVariables = false;
    AllTemplates: ITemplate[] = [];
    template: ITemplate | null = null;
    blocks: IBlock[] = [];
    model_variables: IModelVariable[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            AllTemplates: observable,
            template: observable,
            blocks: observable,
            model_variables: observable,
            isLoadingModelVariables: observable,

            // Actions
            getAll: action,
            getById: action,
            setTemplate: action,
            getBlockByTemplate: action,
            getModelVariables: action,
        });
    }

    setTemplate = (template: ITemplate) => {
        this.template = template;
    };

    //Api
    /**
     * Get all templates
     * @param search
     */
    getAll = async (search?: '') => {
        this.AllTemplates = [];
        const data = await get(`/staff/templates/`, {
            params: {
                search: search,
            },
        });
        this.AllTemplates = data.results;
    };

    getById = async (id: string) => {
        this.template = await get(`/staff/templates/${id}/`);
    };

    create = async (data: ITemplate) => {
        this.template = await post(`/staff/templates/`, data);
        return this.template;
    };

    update = async (id: string, data: ITemplate) => {
        this.template = {
            ...this.template,
            ...data,
        };
        const r = await patch(`/staff/templates/${id}/`, data);
        this.getById(id);
        return r;
    };

    delete = async (id: string) => {
        await remove(`/staff/templates/${id}/`);
    };
    getBlockByTemplate = async (id: string) => {
        const data = await get(`/staff/templates/${id}/blocks/`);
        this.blocks = data.results;
    };

    preview = async (template_id: string | number, model_instance_pk?: string | number | null) => {
        return await post(`/staff/templates/${template_id}/preview/?model_pk=${model_instance_pk}`);
    };

    getModelVariables = async (model_name: string) => {
        this.isLoadingModelVariables = true;
        this.model_variables = await get(`/staff/templates/variables/${model_name}`);
        this.isLoadingModelVariables = false;
    };
}

export default new TemplatesStore();
