import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Divider } from 'antd';
import RequirementForm from '../../../components/Contact/OrderRequirements/Edit';
import { Contacts } from '@partsbadger/library';

export const ManageRequirements = () => {
    const [contact, setContact] = useState();

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}>Additional Requirements by Contact</div>
            </Divider>

            <div style={{ marginTop: 10 }}>
                <Form.Item label={'Contact'}>
                    <Contacts onSelect={contact => setContact(contact)} />
                </Form.Item>
            </div>

            <div style={{ marginTop: 10 }}>
                {contact?.key && <RequirementForm defaultValues={[]} contact_id={contact?.key} />}
            </div>
        </div>
    );
};
