import * as ActionTypes from '../ActionTypes';

export const Notifications = (
    state = {
        entities: []
    },
    action) => {
    switch (action.type) {
        case ActionTypes.NOTIFICATIONS_ADD:
            return {...state, entities: [...state.entities, action.payload]};

        case ActionTypes.NOTIFICATIONS_REMOVE:
            return {...state, entities: [state.entities.filter(r => r.id !== action.id)]};

        default:
            return state;
    }
};