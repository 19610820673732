import React from 'react';
import { ButtonProfileAntd, CardAntd } from '../../../pages/Orders/RedactionTool/styles';
import { CheckOutlined, CloudDownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Image, Tag } from 'antd';
interface PartCardProps {
    isSelected: boolean;
    isRedacted: string;
    thumbnail: string;
    onClick: () => void;
    onDownloadRedactedFile: () => void;
}

const PartCard = (props: PartCardProps) => {
    return (
        <>
            <ButtonProfileAntd
                styleProps={{
                    display: 'inline-block',
                    position: 'relative',
                    height: 'auto',
                    borderColor: props.isSelected ? '#10f341' : '#FFF',
                }}
            >
                <CardAntd
                    classNameProps="flex flex-row m-0 items-center"
                    bordered={false}
                    styleProps={{
                        height: 'auto',
                        fontSize: '20px',
                        color: '#198754',
                    }}
                    onClick={props.onClick}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                        }}
                    >
                        {['redacted', 'externally_redacted'].includes(props.isRedacted) ? (
                            <>
                                <a
                                    onClick={e => {
                                        e.stopPropagation();
                                        props.onDownloadRedactedFile();
                                    }}
                                >
                                    <CloudDownloadOutlined />
                                </a>
                                <CheckOutlined title={'The file is redacted'} />
                            </>
                        ) : props.isRedacted === 'partially_redacted' ? (
                            <>
                                <WarningOutlined
                                    className={'mr-1'}
                                    style={{ color: '#FFC107' }}
                                    title="There are still pages that have not yet been redacted."
                                />
                            </>
                        ) : (
                            <div
                                style={{
                                    color: 'red',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <WarningOutlined className={'mr-1'} />
                            </div>
                        )}
                    </div>

                    <Image
                        style={{
                            margin: 'auto',
                        }}
                        preview={false}
                        src={props.thumbnail ?? ''}
                        alt={'No Preview'}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                        }}
                    >
                        <Tag
                            color={
                                props.isRedacted === 'redacted'
                                    ? 'green'
                                    : props.isRedacted === 'externally_redacted'
                                    ? 'blue'
                                    : props.isRedacted === 'unmodified'
                                    ? 'red'
                                    : 'orange'
                            }
                        >
                            {props.isRedacted.replace(/_/g, ' ')}
                        </Tag>
                    </div>
                </CardAntd>
            </ButtonProfileAntd>
        </>
    );
};

export default PartCard;
