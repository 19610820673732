import React, { useEffect, useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, notification, Row, Spin } from "antd";
import { Accounts } from "@partsbadger/library";
import { get, post } from "shared/api";
import {ErrorRender} from "@partsbadger/library";
import { AccountForm } from "components/Contact/forms";


const FormItem = Form.Item;


const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};


export const LeadConvertForm = Form.create()((props) => {


    const [account, setAccount] = useState({});
    const [owner, setOwner] = useState({});
    const [loading, setLoading] = useState(true);
    const [converting, setConverting] = useState(false);
    const {record, form, handleConverted, handleCancel} = props;

    const [addAccount, setAddAccount] = useState(false);

    const {getFieldDecorator} = form;


    useEffect(() => {


        setLoading(true);


        get('staff/leads/info/', {zid: record.zid, email: record.email}).then(response => {
                if (response.data.account) {
                    setAccount(response.data.account)
                }

                if (response.data.owner) {
                    setOwner(response.data.owner);
                }

                setLoading(false);
            }
        ).catch(error => {

            const args = {
                message: <ErrorRender error={error}/>
            };

            notification.error(args);

            if (handleCancel) {
                handleCancel();
            }
        })

    }, [record.zid]);


    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {

                const params = {
                    zid: record.zid,
                    owner_email: record.owner.display
                };

                if (values.account) {
                    params['account'] = values.account.key
                }


                setConverting(true);

                post('staff/leads/convert/', params).then(response => {
                    handleConverted(response.data)
                    setConverting(false);
                }).catch(error => {
                    const args = {
                        message: <ErrorRender error={error}/>
                    };

                    notification.error(args);
                    setConverting(false);
                })
            }

        });

    };


    if (loading) {
        return <div style={{textAlign: 'center'}}>
            <Spin spinning tip={"Getting account and owner.."}/>
        </div>
    }


    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} style={{padding: "20px"}}>

                <FormItem label="Lead"{...formItemLayout}>
                    {getFieldDecorator('account')(
                        <span>{record.email}</span>
                    )}
                </FormItem>
                <FormItem label="Account"{...formItemLayout}
                          help={<span>
                              Select an account called <strong> {record?.company}</strong> or <span
                              className={"link"} onClick={() => setAddAccount(true)}> create a New Account </span>
                          </span>}>
                    {getFieldDecorator('account', {
                        initialValue: {
                            'key': account.id,
                            'label': account.name,
                        }
                    })(
                        <Accounts/>
                    )}
                </FormItem>


                {/*<FormItem label="Sales Rep"{...formItemLayout}>*/}
                {/*    {getFieldDecorator('owner', {*/}
                {/*        rules: [{required: true}],*/}
                {/*        initialValue: {*/}
                {/*            'key': owner.id,*/}
                {/*            'label': owner.fullname,*/}
                {/*        }*/}
                {/*    })(*/}
                {/*        <Users {...props}/>*/}
                {/*    )}*/}
                {/*</FormItem>*/}
                <div style={{textAlign: "center"}}>
                    <Button type="primary" loading={converting} onClick={(e) => handleSubmit(e)}>
                        Convert
                    </Button>
                </div>
            </Form>

            {addAccount && <Modal visible title={"Create Account"} onCancel={() => setAddAccount(false)} footer={[]}>
                <AccountForm handleCreated={() => {
                    setAddAccount(false)
                }}/>
            </Modal>}
        </Row>

    )
});



