import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, List, Upload } from 'antd';
import { Users } from '@partsbadger/library';
import { QuoteStore } from '../../Stores';
export var Chat = observer(Form.create()(function (_a) {
    var _b = _a.messages, messages = _b === void 0 ? [] : _b, onSendMessage = _a.onSendMessage, form = _a.form, height = _a.height;
    var ref = useRef(null);
    var _c = useState(true), visibleCarbonCopy = _c[0], setVisibleCarbonCopy = _c[1];
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue;
    useEffect(function () {
        var _a;
        if (ref.current) {
            ref.current.scrollTop = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollHeight;
        }
    }, [messages === null || messages === void 0 ? void 0 : messages.length]);
    var onSend = function () {
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                var payload = {
                    body: values.body,
                    attachments: values.attachment ? values.attachment.map(function (f) { return f.originFileObj; }) : [],
                    notify_owner: values.notify_owner,
                    carbon_copy: values.carbon_copy,
                };
                if (onSendMessage) {
                    onSendMessage(payload);
                    form.resetFields();
                }
            }
        });
    };
    // if (ChatStore.loading) {
    //     return <Spin spinning={true}/>
    // }
    var normFile = function (e) {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    var ordered_messages = useMemo(function () {
        return messages.reverse();
    }, [messages]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: ref, id: "messages", className: 'chat-list', style: {
                maxHeight: height || 600,
                height: height || 600,
                overflowY: 'auto',
                border: 'solid 1px #d4d4d4',
            } },
            React.createElement(List, { className: "comment-list px-4", itemLayout: "horizontal", dataSource: ordered_messages, renderItem: function (item) {
                    var _a, _b, _c, _d, _e, _f;
                    return (React.createElement("li", { className: "m-4 shadow p-4" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "mb-2 text-lg" }, !!(item === null || item === void 0 ? void 0 : item.body) && (React.createElement("div", { dangerouslySetInnerHTML: {
                                    __html: item === null || item === void 0 ? void 0 : item.body,
                                } }))),
                            item.attachment && (React.createElement("div", { className: 'mt-2' },
                                React.createElement("a", { target: '_blank', onClick: function () {
                                        if (item.attachment) {
                                            window.open(item.attachment, '', 'width=800,height=800,left=200,top=200');
                                        }
                                    } }, (_c = (_b = (_a = item.attachment) === null || _a === void 0 ? void 0 : _a.split('?')[0]) === null || _b === void 0 ? void 0 : _b.split('/')) === null || _c === void 0 ? void 0 :
                                    _c.pop(),
                                    React.createElement(LegacyIcon, { type: 'paper-clip' })))),
                            item.attachments.map(function (attachment) {
                                return (React.createElement("div", { key: attachment.filename, className: 'mt-2' },
                                    React.createElement("a", { target: '_blank', onClick: function () {
                                            window.open(attachment.file, '', 'width=800,height=800,left=200,top=200');
                                        } },
                                        attachment.filename,
                                        React.createElement(LegacyIcon, { type: 'paper-clip' }))));
                            })),
                        React.createElement("div", null, (_d = item.author) === null || _d === void 0 ? void 0 :
                            _d.fullname,
                            " at ",
                            dayjs(item.created_time).format('LLL'),
                            item.author && React.createElement(LegacyIcon, { style: { marginLeft: '1rem' }, type: 'mail' })),
                        (item === null || item === void 0 ? void 0 : item.carbon_copy) && ((_e = item === null || item === void 0 ? void 0 : item.carbon_copy) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement("div", null,
                            React.createElement("span", null, "Cc: "), (_f = item === null || item === void 0 ? void 0 : item.carbon_copy) === null || _f === void 0 ? void 0 :
                            _f.map(function (a) {
                                return React.createElement(React.Fragment, null, a === null || a === void 0 ? void 0 :
                                    a.fullname,
                                    " | ");
                            })))));
                } })),
        React.createElement("div", { className: 'chat-form  bottom-0 w-11/12 mb-2 mt-4', style: {
                left: 5,
            } },
            React.createElement(Form, { className: '' },
                React.createElement("div", { className: '' },
                    visibleCarbonCopy && (React.createElement(Form.Item, { label: 'Cc', className: 'm-0 text-center' }, getFieldDecorator('carbon_copy', {
                        rules: [
                            {
                                required: false,
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Users, { users: QuoteStore.users, mode: 'multiple' })))),
                    React.createElement(Form.Item, { className: 'm-0' }, getFieldDecorator('body', {
                        rules: [
                            {
                                required: !getFieldValue('attachment'),
                                message: 'Please input a value!',
                            },
                        ],
                    })(React.createElement(Input.TextArea, { onKeyPress: function (e) {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                onSend();
                            }
                        } }))),
                    React.createElement("div", { className: "flex items-center justify-around" },
                        React.createElement(Form.Item, { className: 'm-0 text-center' }, getFieldDecorator('attachment', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                        })(React.createElement(Upload, { multiple: true, name: "avatar", showUploadList: true, beforeUpload: function () { return false; } },
                            React.createElement(Button, null, "Add File")))),
                        React.createElement(Form.Item, { help: 'Notify Owner', className: 'm-0 text-center' }, getFieldDecorator('notify_owner', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please input a value!',
                                },
                            ],
                            valuePropName: 'checked',
                            initialValue: visibleCarbonCopy,
                        })(React.createElement(Checkbox, { onChange: function (e) { return setVisibleCarbonCopy(e.target.checked); } }))),
                        React.createElement(Button, { onClick: onSend, type: "primary", htmlType: "submit" }, "Send")))))));
}));
