var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { get } from '../utils/api';
import IntakeStore from '../Stores/IntakeStore';
import SalesOrderForm from './SalesOrderForm';
import RMA from './RMA';
import { useQueryParameters } from '@partsbadger/utils';
import { Button, DatePicker, message } from 'antd';
import InventoryStore from '../Stores/InventoryStore';
import PurchaseOrderStore from '../Stores/PurchaseOrderStore';
import PurchaseOrderLineItems from './PurchaseOrders/PurchaseOrderLineItems';
import { hasPermission } from 'quotes-frontend/src/components/User';
import { useHistory, useParams } from 'react-router-dom';
var RangePicker = DatePicker.RangePicker;
import HeadFilters from '../Componets/IntakeOuttake/HeadFilters';
import BarcodeScanner from './BarcodeScanner';
import SalesOrderStore from '../Stores/SalesOrderStore';
import VendorStore from '../Stores/VendorStore';
import fileDownload from 'js-file-download';
function dataURItoBlob(dataURI) {
    var byteString = window.atob(dataURI);
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var int8Array = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
}
export var IntakeOuttake = observer(function (props) {
    var _a;
    var _b = useState(false), ButtonKPI = _b[0], setButtonKPI = _b[1];
    var _c = useState(false), isGettingKPI = _c[0], setIsGettingKPI = _c[1];
    var _d = useState(false), ButtonBox = _d[0], setButtonBox = _d[1];
    var _e = useState(null), KPIRange = _e[0], setKPIRange = _e[1];
    var _f = useState(null), KPIs = _f[0], setKPIs = _f[1];
    var _g = useState(null), rmaData = _g[0], setRmaData = _g[1];
    var _h = useState(false), downloading = _h[0], setDownloading = _h[1];
    var history = useHistory();
    var params = useParams();
    var query_params = useQueryParameters();
    var vendorType = IntakeStore.vendorType;
    var getParamId = query_params.get('id');
    var getParamType = query_params.get('type');
    var switchVendorType = function (getVendorType) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, res, err_1, err_2;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!getParamId) return [3 /*break*/, 12];
                    _a = getVendorType;
                    switch (_a) {
                        case 'barcode-vendor': return [3 /*break*/, 1];
                        case 'rma': return [3 /*break*/, 3];
                        case 'purchase-orders': return [3 /*break*/, 7];
                    }
                    return [3 /*break*/, 11];
                case 1:
                    history.push("?id=".concat(getParamId, "&type=").concat(getVendorType));
                    return [4 /*yield*/, SalesOrderStore.getSalesOrder(getParamId)];
                case 2:
                    _c.sent();
                    (_b = SalesOrderStore.salesOrder) === null || _b === void 0 ? void 0 : _b.line_items.forEach(function (item) {
                        var data = __assign(__assign({}, SalesOrderStore.salesOrder), { id: item.id, vendorProductId: item.product_zid, line_items: [item] });
                        VendorStore.setProductData(data);
                    });
                    return [3 /*break*/, 12];
                case 3:
                    _c.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, InventoryStore.get(getParamId, 'RMA')];
                case 4:
                    res = _c.sent();
                    setRmaData(res);
                    history.push("?id=".concat(getParamId, "&type=").concat(getVendorType));
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _c.sent();
                    message.error("Error; ".concat(err_1));
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 12];
                case 7:
                    _c.trys.push([7, 9, , 10]);
                    PurchaseOrderStore.clearData();
                    return [4 /*yield*/, PurchaseOrderStore.get(getParamId)];
                case 8:
                    _c.sent();
                    history.push("?id=".concat(getParamId, "&type=").concat(getVendorType));
                    return [3 /*break*/, 10];
                case 9:
                    err_2 = _c.sent();
                    message.error("Error; ".concat(err_2));
                    return [3 /*break*/, 10];
                case 10: return [3 /*break*/, 12];
                case 11:
                    history.push("?id=".concat(getParamId, "&type=").concat(getVendorType));
                    IntakeStore.getSalesOrder(getParamId, IntakeStore.receiveOrShip);
                    IntakeStore.getPurchaseOrderForSalesOrder(getParamId);
                    return [3 /*break*/, 12];
                case 12: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var getVendorType = getParamType ? getParamType : vendorType;
        IntakeStore.setVendorType(getVendorType);
        switchVendorType(getVendorType);
    }, [getParamId]);
    return (React.createElement("div", { className: "container" },
        React.createElement(HeadFilters, null),
        React.createElement("div", { className: 'intake-outtake-filters flex flex-row items-center justify-center' }),
        React.createElement("div", { className: "forms" },
            hasPermission('intake.view_salesorderintake') && (React.createElement(React.Fragment, null,
                vendorType === 'barcode-vendor' && React.createElement(BarcodeScanner, { id: getParamId }),
                vendorType === 'sales-order' && (React.createElement(SalesOrderForm, { isTester: props.isTester, vendorType: vendorType, handleRefresh: function () {
                        if (getParamId) {
                            IntakeStore.getSalesOrder(getParamId, IntakeStore.receiveOrShip);
                        }
                    } })),
                vendorType === 'rma' && rmaData && (React.createElement(RMA, { isTester: props.isTester, rmaItem: rmaData, onUpdate: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var res;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!rmaData.id) return [3 /*break*/, 2];
                                    return [4 /*yield*/, InventoryStore.get(rmaData.id, 'RMA')];
                                case 1:
                                    res = _a.sent();
                                    setRmaData(res);
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); } })))),
            hasPermission('finance.add_purchaseorderintake') &&
                vendorType === 'purchase-orders' &&
                ((_a = PurchaseOrderStore.PurchaseOrder) === null || _a === void 0 ? void 0 : _a.id) && (React.createElement(PurchaseOrderLineItems, { vendorType: vendorType, onUpdate: function (po_id) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, PurchaseOrderStore.get(po_id)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); } }))),
        React.createElement("div", { className: 'kpis' },
            React.createElement("div", { className: "mt-4" },
                React.createElement(RangePicker, { onChange: function (dates, dateStrings) { return setKPIRange(dateStrings); } }),
                React.createElement(Button, { disabled: KPIRange === null, loading: isGettingKPI, onClick: function () {
                        setIsGettingKPI(true);
                        get("/staff/reports/sr_kpi/?from=".concat(KPIRange[0], "&to=").concat(KPIRange[1]))
                            .then(function (res) { return setKPIs(res); })
                            .finally(function () { return setIsGettingKPI(false); });
                    } }, "Get KPIs"),
                ' ',
                React.createElement(Button, { disabled: KPIRange === null, loading: isGettingKPI, onClick: function () {
                        setDownloading(true);
                        setIsGettingKPI(true);
                        get("/staff/reports/sr_kpi_detail/?from=".concat(KPIRange[0], "&to=").concat(KPIRange[1]))
                            .then(function (response) {
                            var blob = dataURItoBlob(response.data);
                            fileDownload(blob, "sr_kpi_details.xlsx");
                            setDownloading(false);
                        })
                            .catch(function (err) {
                            setIsGettingKPI(false);
                        })
                            .finally(function () { return setIsGettingKPI(false); });
                    } }, "Get KPI Detail"),
                KPIs && (React.createElement("div", null,
                    React.createElement("p", null,
                        "Items In: ",
                        KPIs.data.items_in),
                    React.createElement("p", null,
                        "Items Out: ",
                        KPIs.data.items_out),
                    React.createElement("p", null,
                        "Average Out to RSD: ",
                        KPIs.data.average_out_to_rsd),
                    React.createElement("p", null,
                        "Median Out: ",
                        KPIs.data.median_out),
                    React.createElement("p", null,
                        "Late Shipping Cost Sum: ",
                        KPIs.data.late_shipping_cost_sum.toLocaleString())))))));
});
