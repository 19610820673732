import React, { useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { post } from '../../shared';
import { ErrorRender } from '@partsbadger/library';

type Params = {
    zoho_id: string;
    process: string;
};

const OrdersRedirect = () => {
    const params = useParams<Params>();
    const history = useHistory();
    const [error, setError] = useState();

    const { zoho_id, process } = params;

    useEffect(() => {
        setError(undefined);
        if (process.toLowerCase() === 'redo') {
            post(`staff/sales-orders/${zoho_id}/redo/`)
                .then((r: any) => {
                    history.push(`/sales-order/${r.data.id}/`);
                })
                .catch((e: any) => {
                    setError(e);
                });
        }
        if (process.toLowerCase() === 'remake') {
            post(`staff/sales-orders/${zoho_id}/remake/`)
                .then((r: any) => {
                    history.push(`/sales-order/${r.data.id}/`);
                })
                .catch((e: any) => {
                    setError(e);
                });
        }
    }, []);

    return (
        <div className={'flex items-center justify-center w-full h-1/2'}>
            {error ? <ErrorRender error={error} /> : <Spin spinning tip={'Redirecting...'} />}
        </div>
    );
};

export default OrdersRedirect;
