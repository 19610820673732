import React from 'react';

const NotFoundComponent = () => {
    return (
        <div style={{ padding: '25px' }}>
            <h3> No Results Found</h3>
            <p>The page you requested could not be found.</p>
        </div>
    );
};

export default NotFoundComponent;
