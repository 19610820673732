import React from 'react';
import { QuoteTool } from '@partsbadger/quote-tool';
import { UserStore } from '../../stores/UserStore';
import { observer } from 'mobx-react';
import { Tour } from '@partsbadger/library';

const QuoteDetailV2 = () => {
    const user = UserStore.user;

    if (!user) {
        return null;
    }

    return (
        <div>
            <QuoteToolTour />
            <QuoteTool
                user={{
                    id: user?.id,
                    email: user?.email,
                    display_name: user?.fullname,
                    is_tester: user.is_tester,
                    group: user.group,
                }}
            />
        </div>
    );
};

const QuoteToolTour = () => <Tour tourKey={'quote-tool'} steps={[]} />;

export default observer(QuoteDetailV2);
