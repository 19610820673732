import React from 'react';
import { Divider } from 'antd';
import { in_to_mm, mm_to_in } from '@partsbadger/utils';
export var PartDimensions = function (_a) {
    var _b = _a.length, length = _b === void 0 ? 0 : _b, _c = _a.width, width = _c === void 0 ? 0 : _c, _d = _a.height, height = _d === void 0 ? 0 : _d, _e = _a.unit, unit = _e === void 0 ? 'MM' : _e;
    if (!length || !width || !height) {
        return null;
    }
    var original = "".concat(length).concat(unit, " x ").concat(width).concat(unit, " x ").concat(height).concat(unit);
    var converted = '';
    if (unit === 'MM') {
        converted = "".concat(mm_to_in(length), "IN x ").concat(mm_to_in(width), "IN x ").concat(mm_to_in(height), "IN");
    }
    else {
        converted = "".concat(in_to_mm(length), "MM x ").concat(in_to_mm(width), "MM x ").concat(in_to_mm(height), "MM");
    }
    return (React.createElement("span", { style: {
            fontSize: '12px',
            textTransform: 'lowercase',
            fontWeight: 400,
        } },
        original,
        React.createElement(Divider, { type: 'vertical' }),
        converted));
};
