import { BASE_URL_WS } from '@partsbadger/utils';
import React, { useEffect } from 'react';
import { SalesOrderStore } from '../stores/SalesOrderStore';
import { observer } from 'mobx-react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { action } from 'mobx';

const SocketConnection = () => {
    const { order, token, isConnectedToSocket } = SalesOrderStore;

    useEffect(() => {
        // Send the token on the cookie hackie option
        document.cookie = `X-Authorization=${token}; path=/`;

        const url = `${BASE_URL_WS}/ws/sales_order/${order?.id}/`;

        // Create a new ReconnectingWebSocket instance
        const ws = new ReconnectingWebSocket(url, [], {
            maxRetries: 50,
        });

        // Handle WebSocket connection open event
        ws.onopen = () => {
            SalesOrderStore.setConnectedSocket(true);
        };

        // Handle WebSocket message received event
        ws.onmessage = message => {
            const data = JSON.parse(message.data);
            action(() => {
                SalesOrderStore.changeOrderName(data.data.new_name);
            })();
        };

        // Handle WebSocket connection close event
        ws.onclose = () => {
            SalesOrderStore.setConnectedSocket(false);
        };

        // Handle WebSocket error event
        ws.onerror = function (error) {
            ws.close();
        };

        // Clean up function to close WebSocket connection when component unmounts
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [order?.id]);

    return (
        <div
            style={{
                fontSize: 18,
                marginRight: 10,
                color: isConnectedToSocket ? '#32CD32' : 'red',
            }}
        >
            •
        </div>
    );
};

export default observer(SocketConnection);
