import React, { Fragment, useState } from 'react';
import { UploadFile } from 'components/Inputs/UploadFile';
import '@ant-design/compatible/assets/index.css';
import { Divider, Spin, Card, notification, Button } from 'antd';
import { get } from '../../shared';
import { ThreeDViewerFromStepFile } from '../ThreeDViewer/';
import { post } from '@partsbadger/utils';

let pollingInterval;

export const PartViewer = () => {
    const [loading, setLoading] = useState(false);
    const [step_file, setStepFile] = useState();

    return (
        <div className="text-center">
            <Divider>
                <div className={'title'}> 3D Viewer</div>
            </Divider>

            <div>
                {!step_file ? (
                    <div
                        style={{
                            maxWidth: '400px',
                            margin: 'auto',
                        }}
                    >
                        <span>Select STEP, STP or IGES File</span>
                        <UploadFile
                            type={'3D'}
                            onUpload={async file => {
                                setStepFile();
                                setLoading(true);

                                await post(`/customer/step-files/${file.response.id}/analyze/`);

                                clearInterval(pollingInterval);

                                pollingInterval = setInterval(() => {
                                    get(`customer/step-files/${file.response.id}/status`)
                                        .then(results => {
                                            if (results.data.ready) {
                                                clearInterval(pollingInterval);
                                                setStepFile(results.data.step_file.id);
                                                setLoading(false);
                                            }
                                        })
                                        .catch(error => {
                                            notification.error({
                                                message: 'Error',
                                                description: error.message,
                                            });
                                            clearInterval(pollingInterval);
                                            setLoading(false);
                                        });
                                }, 2000);
                            }}
                        />

                        <Spin spinning={loading} tip={'Analyzing...'} />
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-end pr-8">
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    setStepFile();
                                }}
                            >
                                Close
                            </Button>
                        </div>
                        <div>
                            <ThreeDViewerFromStepFile id={step_file} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
