import React, { Fragment, useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Badge, Checkbox, Divider, Input, message, Modal, Table } from 'antd';
import { fetchAccounts, fetchContacts, fetchLeads } from "../../../redux/actions/accountActions";


import { useDispatch, useSelector } from "react-redux";


import { LeadList } from "../../Lead";
import { AccountForm, ContactForm } from "components/Contact/forms";

const ContactList = (props) => {

    const [showFormContact, setShowFormContact] = useState(false);
    const [contact, setContact] = useState(false);


    return (
        <div>
            <Divider>
                <div className={"title"}> Contacts</div>
            </Divider>

            {props.data.length > 0 && (
                <Table rowKey={"id"} dataSource={props.data} loading={props.loading}
                       pagination={{
                           defaultPageSize: 5
                       }}
                >
                    <Table.Column
                        title="First Name"
                        dataIndex="first_name"
                        key="first_name"
                    />


                    <Table.Column
                        title="Last Name"
                        dataIndex="last_name"
                        key="last_name"

                    />

                    <Table.Column
                        title="Email"
                        dataIndex="email"
                        key="email"
                    />

                    <Table.Column
                        title="Account"
                        dataIndex="account"
                        key="account"
                        render={(text, record) => {
                            return record.account ? record.account?.name : (
                                <Badge style={{backgroundColor: "red"}} count={"Account Required"}/>)

                        }}
                    />

                    <Table.Column
                        title="Owner"
                        dataIndex="owner"
                        key="owner"
                        render={(text, record) => {
                            return (
                                <span>
                                <p>{record.owner.fullname}</p>
                                    {!record?.owner?.is_active && (<p><Badge count={"Inactive"}/></p>)}
                            </span>
                            )
                        }}
                    />

                    <Table.Column
                        title="Edit"
                        render={(text, record) => {
                            return (
                                <div>
                                <span className={"link"} onClick={() => {
                                    setContact(record);
                                    setShowFormContact(true);
                                }}><LegacyIcon type={"edit"}/> </span>
                                </div>
                            );
                        }}
                    />
                    <Table.Column
                        title="Options"
                        render={(text, record) => {
                            return (
                                <Checkbox disabled={!record.account} checked={props.selected?.id === record.id}
                                          className={"link"}
                                          onClick={() => {
                                              props.onSelectContact(record)
                                          }}>
                                </Checkbox>
                            )
                        }}
                    />
                </Table>
            )}

            <div style={{textAlign: "center"}} onClick={() => {
                setContact({})
                setShowFormContact(true);
            }} className={"link"}><LegacyIcon type={"plus"}/> Add Contact
            </div>

            {showFormContact &&
            <Modal visible title={"Create Contact"} onCancel={() => {
                setShowFormContact(false);
                setContact({})
            }} footer={[]}>
                <ContactForm
                    defaultContact={contact}
                    handleCreated={contact => {
                        setShowFormContact(false);
                        if (props?.handleCreated) {
                            props.handleCreated(contact)
                        }
                    }}/>
            </Modal>
            }


        </div>
    );
}


const AccountList = (props) => {


    const [addAccount, setAddAccount] = useState(false);
    const [showFormContact, setShowFormContact] = useState(false);
    const [contact, setContact] = useState(false);


    return (
        <div>
            <Divider>
                <div className={"title"}> Accounts</div>
            </Divider>

            {props.data.length > 0 && (

                <Table rowKey={"id"} dataSource={props.data} loading={props.loading}
                       pagination={{
                           defaultPageSize: 5
                       }}


                >
                    <Table.Column
                        title="Name"
                        dataIndex="name"
                        key="name"
                    />

                    <Table.Column
                        title="Owner"
                        dataIndex="owner"
                        key="owner"
                        render={(text, record) => {
                            return record.owner.fullname
                        }}
                    />

                    <Table.Column
                        title="Actions"
                        render={(text, record) => {
                            return (
                                <span className={"link"} onClick={() => {
                                    const contact = {
                                        account: {
                                            'id': record.id,
                                            'name': record.name,
                                        }
                                    };
                                    setContact(contact);
                                    setShowFormContact(true);
                                }}>
                              <LegacyIcon type={"plus"}/>  Add Contact
                            </span>
                            );
                        }}
                    />

                </Table>

            )}


            <div style={{textAlign: "center"}} onClick={() => setAddAccount(true)} className={"link"}><LegacyIcon
                type={"plus"}/> Add Account
            </div>

            {addAccount && <Modal visible title={"Create Account"} onCancel={() => setAddAccount(false)} footer={[]}>
                <AccountForm handleCreated={account => {
                    setAddAccount(false);
                    if (props.handleCreated) {
                        props.handleCreated(account)
                    }
                }}/>
            </Modal>}

            {showFormContact &&
            <Modal visible title={"Create Contact"} onCancel={() => {
                setShowFormContact(false);
                setContact({})
            }} footer={[]}>
                <ContactForm
                    defaultContact={contact}
                    handleCreated={contact => {
                        setShowFormContact(false);
                        if (props?.handleCreated) {
                            props.handleCreated(contact)
                        }
                    }}/>
            </Modal>}

        </div>
    );
}


let busy_accounts = null;
let busy_contacts = null;
let busy_leads = null;

export const ContactAdvancedSearch = (props) => {

    const [converting, setConverting] = useState(false);


    const dispatch = useDispatch();
    const contacts = useSelector(state => state.contacts);
    const accounts = useSelector(state => state.accounts);
    const leads = useSelector(state => state.leads);

    const {value, onChange} = props;

    const showLeads = true;
    const showAccounts = true;


    useEffect(() => {
        document.addEventListener("keydown", (event) => {
            if (event.code === 'Enter')
                event.preventDefault();
        });
    });


    useEffect(() => {
        if (typeof value == "string") {
            handleSearch();
        }
    }, [value]);


    const handleSearch = () => {

        const params = {
            search: value,
        };

        if (busy_accounts) {
            clearTimeout(busy_accounts);
        }
        if (busy_contacts) {
            clearTimeout(busy_contacts);
        }

        if (busy_leads) {
            clearTimeout(busy_leads);
        }

        busy_accounts = setTimeout(() => dispatch(fetchAccounts(params)), 500);
        busy_contacts = setTimeout(() => dispatch(fetchContacts(params)), 500);
        busy_leads = setTimeout(() => dispatch(fetchLeads(params)), 500);
    };

    const handleConverted = contact => {
        message.success("Lead was converted successfully");
        handleSearch();
        // onChange(contact);
    };


    if (typeof value !== "string" && value?.id) {

        return (
            <div className={"ant-input"}>

                {value.first_name} &nbsp;
                {value.last_name} &nbsp;
                ({value.email})

                &nbsp;|||&nbsp;
                <b> {value.account?.name} </b>

                <span className={"link"} onClick={() => {
                    if (onChange) {
                        onChange(value.email);
                    }
                }}


                > <LegacyIcon type={"edit"}/> </span>

            </div>
        );
    }


    return (
        <Fragment>

            <Input.Search allowClear value={value}
                          placeholder={"Search contact name, email or account name"}
                          {...props}
                          onChange={event => {
                              if (onChange) {
                                  onChange(event.target.value);
                              }
                          }}
            />

            {value && (
                <div>
                    <ContactList data={contacts.entities}
                                 loading={contacts.isLoading}
                                 onSelectContact={onChange}
                                 selected={value}
                                 handleCreated={handleSearch}
                    />

                    {showLeads && (
                        <LeadList data={leads.entities}
                                  loading={leads.isLoading || converting}
                                  handleConverted={handleConverted}
                        />
                    )}

                    {showAccounts && (
                        <AccountList data={accounts.entities}
                                     loading={accounts.isLoading}
                                     handleCreated={handleSearch}
                        />

                    )}

                </div>
            )}


        </Fragment>
    )
};



