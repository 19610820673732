import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, InputNumber, notification, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { get, post } from '../../shared';
import { ErrorRender, UploadFile } from '@partsbadger/library';
import { useParams } from 'react-router-dom';
import { OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';

type IRequestDesignChangeParams = {
    id: string | undefined;
};

type ItemSelect = {
    key: string;
    label: string;
    quantity: number;
};

interface PropsSelectItems {
    line_items: OrderLineItemProps[];
    onSelect: (value: ItemSelect) => void;
    onDeselect: (value: ItemSelect) => void;
    selectAll?: (options: ItemSelect[]) => void;
    onChange?: (options: ItemSelect[]) => void;
}

const SalesOrdersLineItems = ({ line_items, onSelect, onDeselect, selectAll, ...props }: PropsSelectItems) => {
    return (
        <div>
            <Select
                showSearch
                allowClear
                labelInValue
                filterOption={false}
                mode="multiple"
                style={{ minWidth: 120 }}
                dropdownMatchSelectWidth={false}
                placeholder="Search and Select Sales Order Items"
                onSelect={(select: { key: string; label: string }, record: any) => {
                    onSelect({ key: select.key, label: select.label, quantity: record.props.option.quantity });
                }}
                onDeselect={(deselect: { key: string; label: string }) => {
                    onDeselect({ key: deselect.key, label: deselect.label, quantity: 0 });
                }}
                {...props}
            >
                {line_items.map((item: OrderLineItemProps) => (
                    <Select.Option
                        key={item.id}
                        label={item.name}
                        // @ts-ignore
                        option={item}
                    >
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
            <Button
                type={'link'}
                style={{
                    float: 'right',
                }}
                size={'small'}
                onClick={() => {
                    const options = line_items.map((item: OrderLineItemProps) => {
                        return { key: String(item.id), label: item.name, quantity: Number(item.quantity) };
                    });
                    if (selectAll && props.onChange) {
                        selectAll(options);
                        props.onChange(options);
                    }
                }}
            >
                Select All
            </Button>
        </div>
    );
};

const RequestDesignChange = (props: FormComponentProps) => {
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const [salesOrdersLineItems, setSalesOrdersLineItems] = useState<Array<OrderLineItemProps>>([]);
    const [selectSalesOrdersLineItems, setSelectSalesOrdersLineItems] = useState<Array<ItemSelect>>([]);
    const { getFieldDecorator } = form;
    const { id } = useParams<IRequestDesignChangeParams>();

    const getLineItems = (sales_order_id: number) => {
        const endpoint = `staff/sales-orders/${sales_order_id}/line-items/`;
        get(endpoint).then((resp: { data: React.SetStateAction<OrderLineItemProps[]> }) => {
            setSalesOrdersLineItems(resp.data);
        });
    };

    useEffect(() => {
        if (id) getLineItems(Number(id));
    }, [id]);

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                const keys = values.item ? Object.keys(values.item) : [];
                const filter_items = selectSalesOrdersLineItems.map((lineItem: ItemSelect) => {
                    return keys.filter(key => {
                        if (Number(key) === Number(lineItem.key)) return key;
                    });
                });

                try {
                    const payload = {
                        sales_order: Number(id),
                        items: filter_items.map(key => {
                            return {
                                id: key[0],
                                name: values.item[Number(key)],
                                quantity: values.quantity[Number(key)],
                            };
                        }),
                        customer_statement: values?.customer_statement,
                        documents: values?.documents
                            ? values.documents.map((doc: { response: { id: number } }) => doc.response.id)
                            : [],
                        hold_production: values?.hold_production,
                    };
                    if (keys.length > 0) {
                        post(`staff/sales-orders/${id}/request-design-change/`, payload)
                            .then(() => {
                                setLoading(false);
                                notification.success({
                                    message: 'Success',
                                    description: 'Request Design Change Submitted',
                                    placement: 'topRight',
                                });
                                setSelectSalesOrdersLineItems([]);
                                form.resetFields();
                            })
                            .catch((error: any) => {
                                notification.error({ message: <ErrorRender error={error} /> });
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                        notification.info({
                            message: 'Incomplete information.',
                            description: 'You must select one or more sales order items',
                            placement: 'topRight',
                        });
                    }
                } catch (error: any) {
                    notification.error({ message: <ErrorRender error={error} /> });
                    setLoading(false);
                }
            }
        });
    };

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}>Request Design Change</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>To request any changes while the order is in production.</div>

            <Form>
                <Form.Item label={'Sales Order Items'} help={'Please select one or more items from the sales order.'}>
                    {getFieldDecorator('sales_order_line_items')(
                        <SalesOrdersLineItems
                            line_items={salesOrdersLineItems}
                            onSelect={(sales_order_item: ItemSelect) => {
                                setSelectSalesOrdersLineItems([]);
                                setSelectSalesOrdersLineItems([...selectSalesOrdersLineItems, sales_order_item]);
                            }}
                            onDeselect={(sales_order_item: ItemSelect) => {
                                const filter_items = selectSalesOrdersLineItems.filter(
                                    (item: ItemSelect) => Number(item.key) !== Number(sales_order_item.key)
                                );
                                setSelectSalesOrdersLineItems([]);
                                setSelectSalesOrdersLineItems([...filter_items]);
                            }}
                            selectAll={options => setSelectSalesOrdersLineItems(options)}
                        />
                    )}
                </Form.Item>

                {selectSalesOrdersLineItems.length > 0 && (
                    <div className={'flex flex-col'}>
                        <div>
                            {selectSalesOrdersLineItems.map((item: ItemSelect, index: number) => {
                                return (
                                    <div className={'flex flex-row'} key={index}>
                                        <div className={'w-1/3'}>
                                            <Form.Item label={index === 0 ? 'Sales Order Items' : ''}>
                                                {getFieldDecorator(`item[${item.key}]`, {
                                                    initialValue: item.label,
                                                })(<Input readOnly={true} />)}
                                            </Form.Item>
                                        </div>
                                        <div className={'px-4'}>
                                            <Form.Item label={index === 0 ? 'Quantity' : ''}>
                                                {getFieldDecorator(`quantity[${item.key}]`, {
                                                    initialValue: item.quantity,
                                                    rules: [{ required: true, message: 'Field Required' }],
                                                })(<InputNumber />)}
                                            </Form.Item>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <Button
                                type="link"
                                size={'small'}
                                onClick={() => {
                                    setSelectSalesOrdersLineItems([]);
                                }}
                            >
                                Remove All
                            </Button>
                        </div>
                    </div>
                )}
                <Form.Item label={'Customer Statement'}>
                    {getFieldDecorator('customer_statement', {
                        rules: [{ required: true, message: 'Required' }],
                    })(<Input.TextArea autoSize={true} />)}
                </Form.Item>
                <Form.Item label={'Does Production have to hold production?'}>
                    {getFieldDecorator('hold_production', {
                        rules: [{ required: true, message: 'Required' }],
                    })(
                        <Select
                            style={{ width: 200 }}
                            options={[
                                {
                                    value: 'YES',
                                    label: 'YES',
                                },
                                {
                                    value: 'NO',
                                    label: 'NO',
                                },
                            ]}
                        />
                    )}
                </Form.Item>
                <Form.Item label={'Files'}>
                    <p>Please note that ANY design change requests will require a revised file from the customer.</p>
                    {getFieldDecorator('documents')(<UploadFile multiple={true} />)}
                </Form.Item>
                <div
                    style={{
                        marginTop: 20,
                        textAlign: 'center',
                    }}
                >
                    <Button type={'primary'} loading={loading} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Form.create()(RequestDesignChange);
