var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
//@ts-nocheck
import React from 'react';
import { Button, Col, Divider, Row, Select, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { QuoteStore } from '../Stores';
import { get } from '@partsbadger/utils';
var busy = null;
var AddExistingQuotedProduct = /** @class */ (function (_super) {
    __extends(AddExistingQuotedProduct, _super);
    function AddExistingQuotedProduct() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            loading: false,
            quoted_products: [],
            selectedItems: [],
        };
        _this.getQuotedProducts = function (name) {
            _this.setState({
                loading: true,
            });
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
                quote__account: QuoteStore.quote.account.id,
                exclude_quote: QuoteStore.quote.id,
                product__master_product__quotable: true,
            };
            busy = setTimeout(function () {
                get('/staff/quoted-products/', {
                    params: params,
                })
                    .then(function (response) {
                    _this.setState({
                        quoted_products: response.results,
                        loading: false,
                    });
                })
                    .catch(function (error) { return alert(error); });
            }, 300);
        };
        _this.handleChange = function (selectedItems) {
            _this.setState({ selectedItems: selectedItems });
        };
        return _this;
    }
    AddExistingQuotedProduct.prototype.componentDidMount = function () {
        this.getQuotedProducts('');
    };
    AddExistingQuotedProduct.prototype.render = function () {
        var _this = this;
        var selectedItems = this.state.selectedItems;
        var filteredOptions = this.state.quoted_products.filter(function (o) { return !_this.state.selectedItems.includes(o); });
        return (React.createElement(Row, { style: { minWidth: '200px', padding: 20 } },
            React.createElement(Col, { md: 20 },
                React.createElement(Select, { allowClear: true, placeholder: "Search products by name", notFoundContent: this.state.loading ? React.createElement(Spin, { size: "small" }) : React.createElement("span", null, "No results found"), filterOption: false, value: selectedItems, mode: "multiple", onSearch: function (name) {
                        _this.getQuotedProducts(name);
                    }, onChange: this.handleChange, style: { width: '100%' } }, filteredOptions.map(function (d) {
                    var _a, _b, _c;
                    return (React.createElement(Select.Option, { key: d.id, value: d.id },
                        React.createElement(Tooltip, { placement: "left", title: function () {
                                var _a, _b, _c, _d;
                                return (React.createElement("div", null,
                                    "Sides: ",
                                    d.product.sides,
                                    React.createElement("br", null),
                                    "Taps: ",
                                    d.product.taps,
                                    React.createElement("br", null),
                                    "Finish: ", (_b = (_a = d.product) === null || _a === void 0 ? void 0 : _a.finishes) === null || _b === void 0 ? void 0 :
                                    _b.map(function (r) { return r.name; }),
                                    React.createElement("br", null),
                                    "Coating: ", (_d = (_c = d.product) === null || _c === void 0 ? void 0 : _c.coatings) === null || _d === void 0 ? void 0 :
                                    _d.map(function (r) { return r.name; }),
                                    React.createElement("br", null),
                                    "LT: ",
                                    d.lead_time,
                                    React.createElement("br", null)));
                            } },
                            React.createElement("div", { style: { fontSize: '12px' } },
                                React.createElement("strong", null, (_b = (_a = d === null || d === void 0 ? void 0 : d.product) === null || _a === void 0 ? void 0 : _a.master_product) === null || _b === void 0 ? void 0 :
                                    _b.name,
                                    " "),
                                React.createElement("b", null, d.name),
                                React.createElement(Divider, { type: 'vertical' }), (_c = d.product.material) === null || _c === void 0 ? void 0 :
                                _c.name,
                                React.createElement(Divider, { type: 'vertical' }),
                                "Qty: ",
                                d.quantity,
                                React.createElement(Divider, { type: 'vertical' }),
                                "Adj: % ",
                                d.manual_adjustment,
                                React.createElement(Divider, { type: 'vertical' }),
                                moment([d.expiration_date]).fromNow()))));
                }))),
            React.createElement(Col, { md: 4 },
                React.createElement(Button, { type: 'primary', onClick: function () {
                        QuoteStore.createLineItemFromExistingLineItems(selectedItems);
                        _this.setState({
                            selectedItems: [],
                        });
                    } }, "Add"))));
    };
    return AddExistingQuotedProduct;
}(React.Component));
export default AddExistingQuotedProduct;
