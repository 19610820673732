import React from 'react';
import Humanize from 'humanize-plus';
export var Price = function (_a) {
    var value = _a.value, loading = _a.loading, style = _a.style;
    var val = typeof value === 'string' ? parseFloat(value) : value;
    if (value >= 0) {
        return React.createElement("span", { style: style },
            "$",
            Humanize.formatNumber(val, 2));
    }
    return React.createElement("span", { style: style }, "$0.00");
};
