import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Row, Col, Input, Select } from 'antd';
import { DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const PurchaseOrderFilters = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const params: any = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.sales_order?.key) {
                    params['sales_order'] = values.sales_order.key;
                }

                if (values.vendor?.key) {
                    params['vendor'] = values.vendor.key;
                }

                if (values.approved) {
                    params['approved'] = values.approved;
                }

                if (values.sent_to_vendor) {
                    params['sent_to_vendor'] = values.sent_to_vendor;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                if (values.category) {
                    params['category'] = values.category;
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');

                history.replace(`/purchase-orders/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Form onSubmit={handleSubmit} layout="horizontal">
            <Row>
                <Col {...cols}>
                    <FormItem label="Search" {...formItemLayout}>
                        {getFieldDecorator('search', {
                            initialValue: query.get('search'),
                        })(<Search placeholder="Search" style={{ width: '100%' }} allowClear size={'small'} />)}
                    </FormItem>
                </Col>

                <Col {...cols}>
                    <FormItem label="Sales Order" {...formItemLayout}>
                        {getFieldDecorator('sales_order', {
                            initialValue: query.get('sales_order')
                                ? {
                                      key: query.get('sales_order'),
                                  }
                                : {},
                        })(<GenericSelect recordType={'SalesOrders'} size={'small'} />)}
                    </FormItem>
                </Col>

                <Col {...cols}>
                    <FormItem label="Approved" {...formItemLayout}>
                        {getFieldDecorator('approved', {
                            valuePropName: 'checked',
                            initialValue: false,
                        })(<Checkbox />)}
                    </FormItem>
                </Col>

                <Col {...cols}>
                    <FormItem label="Vendor" {...formItemLayout}>
                        {getFieldDecorator('vendor', {
                            initialValue: query.get('vendor')
                                ? {
                                      key: query.get('vendor'),
                                  }
                                : {},
                        })(<GenericSelect recordType={'Vendors'} size={'small'} />)}
                    </FormItem>
                </Col>

                <Col {...cols}>
                    <FormItem label="Date" {...formItemLayout}>
                        {getFieldDecorator('created_time', {
                            initialValue:
                                query.get('created_time_min') && query.get('created_time_max')
                                    ? [
                                          moment(query.get('created_time_min'), 'YYYY-MM-DD'),
                                          moment(query.get('created_time_max'), 'YYYY-MM-DD'),
                                      ]
                                    : null,
                        })(<DateRangePicker size="small" />)}
                    </FormItem>
                </Col>
                <Col {...cols}>
                    <FormItem label="Sent to Vendor" {...formItemLayout}>
                        {getFieldDecorator('sent_to_vendor', {
                            valuePropName: 'checked',
                            initialValue: false,
                        })(<Checkbox />)}
                    </FormItem>
                </Col>
                <Col {...cols}>
                    <FormItem label="Category" {...formItemLayout}>
                        {getFieldDecorator('category')(
                            <Select allowClear size="small">
                                <Select.Option value="PartsBadger, LLC">PartsBadger, LLC</Select.Option>
                                <Select.Option value="PartsBadger Production">PartsBadger Production</Select.Option>
                                <Select.Option value="Yeti">Yeti</Select.Option>
                            </Select>
                        )}
                    </FormItem>
                </Col>
                <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                    <Form.Item>
                        <Button
                            onClick={() => {
                                history.push(`/purchase-orders/`);
                                form.resetFields();
                            }}
                        >
                            Reset
                        </Button>
                        <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export const PurchaseOrdersFilterForm = Form.create()(PurchaseOrderFilters);
