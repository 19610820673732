import React from 'react';
import { Divider, notification } from 'antd';
import { useHistory } from 'react-router-dom';

import { SalesOrderTool } from '@partsbadger/sales_order_tool';
import { getCurrentUser } from 'components/User';

const EditSalesOrder = (props: any) => {
    const sales_order_id = props.match.params.sales_order_id;
    const history = useHistory();

    const current_user: any = getCurrentUser()?.data;

    return (
        <div style={{ margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Review Sales Order</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>
                Use the form below to submit an Sales Order. This form ensures that we have all of the necessary
                information in one place to process your Order timely and efficiently. Thank you.
            </div>

            <SalesOrderTool
                current_user={current_user}
                sales_order_id={sales_order_id}
                onSubmitted={() => {
                    history.push(`/orders/redaction-tool/${sales_order_id}`);
                }}
            />

            <br />
            <p style={{ textAlign: 'center' }}>Need Help? Call (331) BADGER-8</p>
        </div>
    );
};

export default EditSalesOrder;
