import React, { useState } from 'react';
import { Card, Table, Button, notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';

import { DateRangePicker } from '@partsbadger/library';
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { get } from '@partsbadger/utils';
import { OrderTimeTrackingModel } from '../../../stores/types';

const FormItem = Form.Item;

export interface Params {
    start_date: string;
    end_date: string;
    page?: number;
    download_csv?: boolean;
}

interface Response {
    count: number;
    next: string;
    previous: string;
    results: OrderTimeTrackingModel[];
}

interface Pagination {
    current: number;
    pageSize: number;
    total: number;
}

interface AvgResponse {
    average_queue_time: number;
    average_processing_time: number;
    average_total_time: number;
}

const OrderTimeTrackingReport = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<OrderTimeTrackingModel[]>([]);
    const [avgData, setAvgData] = useState<AvgResponse>({
        average_queue_time: 0,
        average_processing_time: 0,
        average_total_time: 0,
    });
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const { form } = props;
    const { getFieldDecorator } = form;

    const handleSubmit = (page: number, downloadCsv = false) => {
        props.form.validateFields((err, values) => {
            if (!err) {
                const dates = values.dates;
                const start_date = moment(dates[0]).format('YYYY-MM-DD');
                const end_date = moment(dates[1]).format('YYYY-MM-DD');
                const params = {
                    start_date,
                    end_date,
                    page,
                    download_csv: downloadCsv,
                };
                getOrderTimeTracking(params);
            }
        });
    };

    const getOrderTimeTracking = (params: Params) => {
        setLoading(true);
        const url = `/staff/report-line-items-only-intake/order-time-tracking/`;
        if (params.download_csv === true) {
            const filename = `order-time-tracking-${Date.now()}.csv`;
            get(url, {
                params,
                responseType: 'blob',
            })
                .then(blob => {
                    // Create a temporary URL for the blob object
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    // Create a temporary link element to download the file
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    // Trigger the download by clicking the link element
                    document.body.appendChild(link);
                    link.click();
                    // Cleanup by removing the link element and revoking the URL object
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    notification.error({ message: 'Error getting csv file.', duration: 5 });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            get(url, {
                params,
            })
                .then((response: Response) => {
                    setData(response.results);
                    setPagination({
                        ...pagination,
                        current: params.page ?? 1,
                        total: response.count,
                    });
                    getAvgOrderTimeTracking(params);
                })
                .catch(error => {
                    notification.error({ message: 'Error getting data.', duration: 5 });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const getAvgOrderTimeTracking = (params: Params) => {
        get(`/staff/report-line-items-only-intake/avg-order-time-tracking/`, {
            params,
        })
            .then((response: AvgResponse) => {
                setAvgData(response);
            })
            .catch(error => {
                notification.error({ message: error.message, duration: 5 });
            })
            .finally(() => {});
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Submitted Date',
            key: 'order_submitted_time',
            render: (r: OrderTimeTrackingModel) => {
                return r.order_submitted_time ? dayjs(r.order_submitted_time).format('LLL') : '';
            },
        },
        {
            title: 'Start Order Date',
            key: 'start_processing_time',
            render: (r: OrderTimeTrackingModel) => {
                return r.start_processing_time ? dayjs(r.start_processing_time).format('LLL') : '';
            },
        },
        {
            title: 'Completed Date',
            key: 'completed_time',
            render: (r: OrderTimeTrackingModel) => {
                return r.completed_time ? dayjs(r.completed_time).format('LLL') : '';
            },
        },
        {
            title: 'Avg Queue Time',
            key: 'avg_queue_time',
            render: (r: OrderTimeTrackingModel) => {
                return (
                    <>
                        {renderAvgTimeColumn(r.avg_queue_time)}
                        {r.avg_queue_time ? ` (${parseFloat((r.avg_queue_time / 60).toString()).toFixed(1)})` : ''}
                    </>
                );
            },
        },
        {
            title: 'Avg Processing Time',
            key: 'avg_processing_time',
            render: (r: OrderTimeTrackingModel) => {
                return (
                    <>
                        {renderAvgTimeColumn(r.avg_processing_time)}
                        {r.avg_processing_time
                            ? ` (${parseFloat((r.avg_processing_time / 60).toString()).toFixed(1)})`
                            : ''}
                    </>
                );
            },
        },
        {
            title: 'Avg Total Time',
            key: 'avg_total_time',
            render: (r: OrderTimeTrackingModel) => {
                return (
                    <>
                        {renderAvgTimeColumn(r.avg_total_time)}
                        {r.avg_total_time ? ` (${parseFloat((r.avg_total_time / 60).toString()).toFixed(1)})` : ''}
                    </>
                );
            },
        },
    ];

    const renderAvgTime = (avgTime: number) => {
        if (avgTime > 0) {
            return moment().startOf('day').seconds(avgTime).format('HH:mm:ss') + ' (' + (avgTime / 60).toFixed(1) + ')';
        } else {
            return '';
        }
    };

    const renderAvgTimeColumn = (avgTime: number) => {
        if (avgTime) {
            const duration = moment.duration(avgTime, 'seconds');
            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const secondsLeft = duration.seconds();
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
            return formattedTime;
        } else {
            return '';
        }
    };

    return (
        <div className={'flex flex-col'}>
            <Card title={'Order Time Tracking'}>
                <Form
                    onSubmit={(e: any) => {
                        e.preventDefault();
                        handleSubmit(1);
                    }}
                >
                    <div className="flex lg:flex-row flex-col">
                        <FormItem label="" required>
                            {getFieldDecorator('dates', {
                                rules: [{ required: true, message: 'Dates is required' }],
                            })(<DateRangePicker />)}
                        </FormItem>

                        <FormItem>
                            <Button type="primary" htmlType={'submit'} className="lg:ml-2 md:ml-2 ml-0 w-full">
                                Filter
                            </Button>
                        </FormItem>
                    </div>
                </Form>
            </Card>

            <div className="flex md:flex-row md:justify-between items-center flex-col flex-col-reverse mt-4 lg:ml-2 ml-1">
                <div>
                    <div className="flex flex-row">
                        <p>Avg Queue Time: </p>
                        <p> {renderAvgTime(avgData.average_queue_time)}</p>
                    </div>
                    <div className="flex flex-row">
                        <p>Avg Processing Time: </p>
                        <p> {renderAvgTime(avgData.average_processing_time)}</p>
                    </div>
                    <div className="flex flex-row">
                        <p>Avg Total Time: </p>
                        <p> {renderAvgTime(avgData.average_total_time)}</p>
                    </div>
                </div>

                <div className="lg:mr-2 mb-2 md:mb-0">
                    <Button
                        onClick={() => {
                            handleSubmit(1, true);
                        }}
                    >
                        Export
                        <FileExcelOutlined />
                    </Button>
                </div>
            </div>

            <div className={'w-full overflow-auto'}>
                <Table
                    bordered
                    rowKey={'id'}
                    columns={columns}
                    loading={loading}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: false,
                        pageSizeOptions: [pagination.pageSize],
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        onChange: (page: number) => {
                            handleSubmit(page);
                        },
                    }}
                ></Table>
            </div>
        </div>
    );
};

export const OrderTimeTrackingReportForm = Form.create()(OrderTimeTrackingReport);
