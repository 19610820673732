import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, Row, Select } from 'antd';
import { Accounts, Contacts, DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const SalesOrderFilter = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const params: any = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.account?.key) {
                    params['account'] = values.account.key;
                }

                if (values.contact?.key) {
                    params['contact'] = values.contact.key;
                }

                if (values.created_by?.key) {
                    params['created_by'] = values.created_by.key;
                }

                if (values.owner?.key) {
                    params['owner'] = values.owner.key;
                }

                if (values.rfq?.key) {
                    params['rfq'] = values.rfq.key;
                }

                if (values.is_dynamic === true || values.is_dynamic === true) {
                    params['is_dynamic'] = values.is_dynamic;
                }

                if (values.status) {
                    params['status'] = values.status;
                }

                if (values.design_engineer?.key) {
                    params['design_engineer'] = values.design_engineer.key;
                }

                if (values.unassigned) {
                    params['design_engineer'] = 'Unassigned';
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');

                history.replace(`/sales-orders/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Row>
                    <Col {...cols}>
                        <FormItem label="Search" {...formItemLayout}>
                            {getFieldDecorator('search', {
                                initialValue: query.get('search'),
                            })(<Search placeholder="Search" style={{ width: '100%' }} allowClear size={'small'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Account" {...formItemLayout}>
                            {getFieldDecorator('account', {
                                initialValue: query.get('account')
                                    ? {
                                          key: query.get('account'),
                                      }
                                    : {},
                            })(<Accounts />)}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Contact" {...formItemLayout}>
                            {getFieldDecorator('contact', {
                                initialValue: query.get('contact')
                                    ? {
                                          key: query.get('contact'),
                                      }
                                    : {},
                            })(<Contacts />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Sales Rep" {...formItemLayout}>
                            {getFieldDecorator('owner', {
                                initialValue: query.get('owner')
                                    ? {
                                          key: query.get('owner'),
                                      }
                                    : {},
                            })(
                                <GenericSelect
                                    recordType={'User'}
                                    filters={{
                                        is_staff: true,
                                        is_active: true,
                                    }}
                                    size="small"
                                />
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Status" {...formItemLayout}>
                            {getFieldDecorator('status', {
                                initialValue: query.get('status'),
                            })(
                                <Select allowClear size="small" showSearch>
                                    <Select.Option value="Unresolved">All Unresolved</Select.Option>
                                    <Select.Option value="Draft">Draft</Select.Option>
                                    <Select.Option value="In Progress">In Progress</Select.Option>
                                    <Select.Option value="Waiting on Customer">Waiting on Customer</Select.Option>
                                    <Select.Option value="Waiting on 3d File">Waiting on 3d File</Select.Option>
                                    <Select.Option value="Needs Review">Needs Review</Select.Option>
                                    <Select.Option value="Ready for Production">Ready for Production</Select.Option>
                                    <Select.Option value="Production - Accepted">Production - Accepted</Select.Option>
                                    <Select.Option value="Received in Full">Received in Full</Select.Option>
                                    <Select.Option value="Received in Full - Domestic Finish">
                                        Received in Full - Domestic Finish
                                    </Select.Option>
                                    <Select.Option value="Received in Partial">Received in Partial</Select.Option>
                                    <Select.Option value="Received in Partial - Domestic Finish">
                                        Received in Partial - Domestic Finish
                                    </Select.Option>
                                    <Select.Option value="Shipped in Partial">Shipped in Partial</Select.Option>
                                    <Select.Option value="Shipped in Full">Shipped in Full</Select.Option>
                                    <Select.Option value="Shipped & Paid">Shipped & Paid</Select.Option>
                                    <Select.Option value="Cancelled">Cancelled</Select.Option>
                                    <Select.Option value="Cancelled - Parts Intaken">
                                        Cancelled - Parts Intaken
                                    </Select.Option>
                                    <Select.Option value="Completed">Completed</Select.Option>
                                    <Select.Option value="Hold">Hold</Select.Option>
                                    <Select.Option value="Pending">Pending</Select.Option>
                                    <Select.Option value="Received - Under Review (Von)">
                                        Received - Under Review (Von)
                                    </Select.Option>
                                    <Select.Option value="Received - Under Review (Jason)">
                                        Received - Under Review (Jason)
                                    </Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Date" {...formItemLayout}>
                            {getFieldDecorator('created_time', {
                                initialValue:
                                    query.get('created_time_min') && query.get('created_time_max')
                                        ? [
                                              moment(query.get('created_time_min'), 'YYYY-MM-DD'),
                                              moment(query.get('created_time_max'), 'YYYY-MM-DD'),
                                          ]
                                        : null,
                            })(<DateRangePicker size="small" />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Design Engineer" {...formItemLayout}>
                            {getFieldDecorator('design_engineer', {
                                initialValue: query.get('design_engineer')
                                    ? {
                                          key: query.get('design_engineer'),
                                      }
                                    : {},
                            })(
                                <GenericSelect
                                    recordType={'User'}
                                    filters={{
                                        is_staff: true,
                                        is_active: true,
                                    }}
                                    size="small"
                                />
                            )}
                        </FormItem>

                        <FormItem label="Unassigned" {...formItemLayout}>
                            {getFieldDecorator('unassigned', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(<Checkbox />)}
                        </FormItem>

                        <div className="flex flex-row">
                            <div className="font-bold">Predefined filters: </div>
                            <div
                                className={'px-2 link'}
                                onClick={() => {
                                    history.replace(`/sales-orders/?design_engineer=Unassigned&status=Unresolved`);
                                }}
                            >
                                UnResolved Orders
                            </div>
                        </div>
                    </Col>

                    <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    history.push(`/sales-orders/`);
                                    form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export const SalesOrderFilterForm = Form.create()(SalesOrderFilter);
