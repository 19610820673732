import * as ActionTypes from '../ActionTypes';

export const UI = (
    state = {
        networkError: false,
        showMasterProductForm: false,
        showBulEdit: false,
        master_id: 0,
        showEditVariation: false,
        showEditQuote: false,
        variation_id: 0,
        showSuppliedProduct: false,
        duplicateMasterProduct: false,
        showVariationsModal: false,
        showExistingVariationsModal: false,
        showMaterialModal: false,
        showCoatingModal: false,
        showFinishModal: false,


    },
    action) => {
    switch (action.type) {
        case ActionTypes.UI_OPEN_CREATE_MASTER_PRODUCT:
            return {...state, showMasterProductForm: true};

        case ActionTypes.UI_CLOSE_OPEN_CREATE_MASTER_PRODUCT:
            return {...state, showMasterProductForm: false};


        case ActionTypes.UI_SHOW_BULK_EDIT_MODAL:
            return {...state, showBulEdit: true};

        case ActionTypes.UI_HIDE_BULK_EDIT_MODAL:
            return {...state, showBulEdit: false};


        case ActionTypes.UI_SHOW_NEW_MATERIAL_MODAL:
            return {...state, showMaterialModal: true};

        case ActionTypes.UI_HIDE_NEW_MATERIAL_MODAL:
            return {...state, showMaterialModal: false};

        case ActionTypes.MATERIAL_SAVED:
            return {...state, showMaterialModal: false};

        case ActionTypes.UI_SHOW_NEW_COATING_MODAL:
            return {...state, showCoatingModal: true};

        case ActionTypes.UI_HIDE_NEW_COATING_MODAL:
            return {...state, showCoatingModal: false};

        case ActionTypes.FINISH_SAVED:
            return {...state, showCoatingModal: false};

        case ActionTypes.UI_SHOW_NEW_FINISH_MODAL:
            return {...state, showFinishModal: true};

        case ActionTypes.UI_HIDE_NEW_FINISH_MODAL:
            return {...state, showFinishModal: false};


        case ActionTypes.UI_SHOW_EDIT_QUOTE_MODAL:
            return {...state, showEditQuote: true};

        case ActionTypes.UI_HIDE_EDIT_QUOTE_MODAL:
            return {...state, showEditQuote: false};


        case ActionTypes.COATING_SAVED:
            return {...state, showFinishModal: false, error: action.error};

        case ActionTypes.NETWORK_ERROR:
            return {...state, networkError: true}

        default:
            return state;
    }
};