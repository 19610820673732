var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, computed, observable, makeObservable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
var PurchaseOrderStore = /** @class */ (function () {
    function PurchaseOrderStore() {
        this.purchase_orders = [];
        this.purchase_order = null;
        this.line_items = [];
        this.users = [];
        this.temp_line_items = [];
        this.sales_order = null;
        this.input_amount = 0;
        this.input_quantity = 0;
        this.input_list_price = 0;
        this.input_discount = 0;
        this.input_tax = 0;
        this.input_total = 0;
        this.sale_order_by_item = 0;
        this.products_by_item = [];
        this.combined_sales_orders_and_line_item = [];
        makeObservable(this, {
            // Observables
            purchase_orders: observable,
            purchase_order: observable,
            line_items: observable,
            users: observable,
            temp_line_items: observable,
            sales_order: observable,
            input_amount: observable,
            input_quantity: observable,
            input_list_price: observable,
            input_discount: observable,
            input_tax: observable,
            input_total: observable,
            sale_order_by_item: observable,
            products_by_item: observable,
            combined_sales_orders_and_line_item: observable,
            // Actions
            setPurchaseOrder: action,
            get: action,
            list: action,
            create: action,
            patch: action,
            getUsers: action,
            remove: action,
            sync_line_items: action,
            remove_line_item: action,
            update_line_item: action,
            recalculate_totals: action,
            calculate_input_amount: action,
            calculate_input_total: action,
            update_input_quantity: action,
            update_input_list_price: action,
            update_input_discount: action,
            update_input_tax: action,
            update_amount_total: action,
            update_input_total: action,
            update_po_field: action,
            update_purchase_order: action,
            approve_purchase_order: action,
            add_item: action,
            send_to_vendor: action,
            get_sales_order: action,
            removeLineItems: action,
            removeSalesOrder: action,
            set_zx_line_items: action,
            getSalesOrderItems: action,
            add_sales_order_line_item: action,
            remove_sales_order_line_item: action,
            add_sales_order_and_line_item: action,
            hasUniqueSalesOrderId: action,
            remove_sales_order_and_line_item: action,
            // computed
            getTotalDiscount: computed,
            is_sales_order_line_items_empty: computed,
        });
    }
    PurchaseOrderStore.prototype.setPurchaseOrder = function (po) {
        this.purchase_order = po;
    };
    PurchaseOrderStore.prototype.get = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/purchase-orders/".concat(id, "/"))];
                    case 1:
                        response = _a.sent();
                        // The server some values send as string, lets convert all to number to avoid Javascript
                        // string concatenations in the totals
                        this.purchase_order = __assign(__assign({}, response), { shipping_cost: Number(response.shipping_cost), discount: Number(response.discount), tax: Number(response.tax), sub_total: Number(response.sub_total), taxes_rate: Number(response.taxes_rate), adjustment: Number(response.adjustment), grand_total: Number(response.grand_total), grand_total_with_shipping: Number(response.grand_total_with_shipping) });
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.list = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/purchase-orders/", params)];
                    case 1:
                        response = _a.sent();
                        this.purchase_orders = response.results;
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.create = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/purchase-orders/", payload)];
                    case 1:
                        response = _a.sent();
                        this.purchase_orders.push(response.data);
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.patch = function (payload, id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, patch("/staff/purchase-orders/".concat(id, "/"), payload)];
                    case 1:
                        response = _a.sent();
                        this.purchase_order = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.getUsers = function (params) {
        var _this = this;
        get("/staff/users/", {
            params: params,
        }).then(function (data) {
            _this.users = data.results;
        });
    };
    PurchaseOrderStore.prototype.remove = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, remove("/staff/purchase-orders/".concat(id))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.sync_line_items = function (line_items) {
        var _this = this;
        if (line_items && line_items.length > 0) {
            this.line_items = line_items;
            this.line_items.forEach(function (l_item) {
                var _a;
                (_a = l_item.sales_order_line_items) === null || _a === void 0 ? void 0 : _a.forEach(function (s_item) {
                    var _a, _b;
                    if (s_item === null || s_item === void 0 ? void 0 : s_item.sales_order) {
                        _this.add_sales_order_and_line_item((_a = s_item === null || s_item === void 0 ? void 0 : s_item.sales_order) === null || _a === void 0 ? void 0 : _a.id, s_item.id, (_b = s_item === null || s_item === void 0 ? void 0 : s_item.sales_order) === null || _b === void 0 ? void 0 : _b.name);
                    }
                });
            });
        }
        else {
            this.line_items = [];
            this.update_input_quantity(0);
            this.update_input_list_price(0);
            this.update_input_discount(0);
            this.update_input_tax(0);
            this.update_amount_total(0);
            this.update_input_total(0);
        }
    };
    PurchaseOrderStore.prototype.add_line_item = function (line_item) {
        if (line_item) {
            line_item.line_number = this.line_items.length + 1;
            // if (this.purchase_order) {
            //     line_item.purchase_order = this.purchase_order.id;
            // }
            this.line_items.push(line_item);
            this.update_input_quantity(0);
            this.update_input_list_price(0);
            this.update_input_discount(0);
            this.update_input_tax(0);
            this.update_amount_total(0);
            this.update_input_total(0);
            // this.products_by_item = [];
            this.recalculate_totals();
        }
    };
    PurchaseOrderStore.prototype.remove_line_item = function (index) {
        var filter_line_items = this.line_items.filter(function (line_item, i) { return i !== index; });
        filter_line_items.map(function (line_item, index) {
            line_item.line_number = index + 1;
        });
        this.line_items = filter_line_items;
        this.recalculate_totals();
    };
    PurchaseOrderStore.prototype.add_sales_order_line_item = function (id, value) {
        this.line_items = this.line_items.map(function (item) {
            if (item.id == id) {
                return __assign(__assign({}, item), { sales_order_line_items: __spreadArray(__spreadArray([], item === null || item === void 0 ? void 0 : item.sales_order_line_items, true), [value], false) });
            }
            return item;
        });
    };
    PurchaseOrderStore.prototype.add_sales_order_and_line_item = function (soId, lineItemId, label) {
        this.combined_sales_orders_and_line_item = __spreadArray(__spreadArray([], this.combined_sales_orders_and_line_item, true), [
            { soId: soId, lineItemId: lineItemId, label: label },
        ], false);
    };
    PurchaseOrderStore.prototype.remove_sales_order_and_line_item = function (lineItemId) {
        this.combined_sales_orders_and_line_item = this.combined_sales_orders_and_line_item.filter(function (item) { return item.lineItemId !== lineItemId; });
    };
    Object.defineProperty(PurchaseOrderStore.prototype, "is_sales_order_line_items_empty", {
        get: function () {
            // returns true if at least one l_item has an empty sales_order_line_items array and at least one has a non-empty sales_order_line_items array.
            var hasSomeEmpty = this.line_items.some(function (item) { var _a; return !((_a = item.sales_order_line_items) === null || _a === void 0 ? void 0 : _a.length); });
            var hasSomeNonEmpty = this.line_items.some(function (item) { var _a; return ((_a = item.sales_order_line_items) === null || _a === void 0 ? void 0 : _a.length) > 0; });
            return hasSomeEmpty && hasSomeNonEmpty;
        },
        enumerable: false,
        configurable: true
    });
    PurchaseOrderStore.prototype.hasUniqueSalesOrderId = function () {
        var soIdSet = new Set(this.combined_sales_orders_and_line_item.map(function (obj) { return obj.soId; }));
        if (this.is_sales_order_line_items_empty == false && soIdSet.size === 1) {
            return this.combined_sales_orders_and_line_item[0];
        }
        else {
            return false;
        }
    };
    PurchaseOrderStore.prototype.remove_sales_order_line_item = function (POLineItemId, salesOrderItemId) {
        this.line_items = this.line_items.map(function (item) {
            if (item.id == POLineItemId) {
                return __assign(__assign({}, item), { sales_order_line_items: item.sales_order_line_items.filter(function (lineItem) { return lineItem.id !== salesOrderItemId; }) });
            }
            return item;
        });
    };
    PurchaseOrderStore.prototype.update_line_item = function (index, new_item) {
        this.line_items.map(function (item, i) {
            if (index === i) {
                var amount = Number(new_item.list_price) * Number(new_item.quantity);
                var total = Number(amount - Number(new_item.discount) + Number(new_item.tax));
                item.name = new_item.name;
                item.description = new_item.description;
                item.status = new_item.status;
                item.quickbooks_account = Number(new_item.quickbooks_account);
                item.quickbooks_account_related = new_item.quickbooks_account_related;
                item.list_price = Number(new_item.list_price);
                item.quantity = Number(new_item.quantity);
                item.amount = Number(amount.toFixed(2));
                item.discount = 0;
                item.tax = new_item.tax;
                item.sales_order = new_item.sales_order;
                item.sales_order_related = new_item.sales_order_related;
                item.sales_order_text = new_item.sales_order_text;
                item.product = new_item.product;
                item.product_related = new_item.product_related;
                item.product_text = new_item.product_text;
                item.sales_order_line_items = new_item.sales_order_line_items;
                item.total = Number(total.toFixed(2));
            }
        });
        this.products_by_item = [];
        this.recalculate_totals();
    };
    Object.defineProperty(PurchaseOrderStore.prototype, "getTotalDiscount", {
        get: function () {
            if (!this.purchase_order) {
                return 0;
            }
            if (this.purchase_order.discount_type === '%') {
                return (this.purchase_order.sub_total * this.purchase_order.discount) / 100;
            }
            return this.purchase_order.discount;
        },
        enumerable: false,
        configurable: true
    });
    PurchaseOrderStore.prototype.recalculate_totals = function () {
        if (!this.purchase_order) {
            return;
        }
        var subtotal = 0;
        this.line_items
            .filter(function (l) { return l.status !== 'Canceled'; })
            .map(function (line) {
            subtotal = Number(subtotal) + Number(line.total);
            return subtotal;
        });
        this.purchase_order.sub_total = subtotal;
        this.purchase_order.subtotal_without_taxes = this.line_items.reduce(function (total, item) { return total + Number(item.amount); }, 0);
        this.purchase_order.total_taxes = this.line_items.reduce(function (total, item) { return total + Number(item.tax); }, 0);
        var total_discount = this.getTotalDiscount;
        if (this.purchase_order.taxes_rate) {
            this.purchase_order.tax = ((subtotal - total_discount) * this.purchase_order.taxes_rate) / 100;
        }
        else {
            this.purchase_order.tax = 0;
        }
        this.purchase_order.sub_total = Number(subtotal);
        this.purchase_order.grand_total =
            this.purchase_order.sub_total - total_discount + this.purchase_order.tax + this.purchase_order.adjustment;
        this.purchase_order.grand_total_with_shipping =
            this.purchase_order.grand_total + this.purchase_order.shipping_cost;
    };
    PurchaseOrderStore.prototype.calculate_input_amount = function () {
        this.input_amount = this.input_quantity * this.input_list_price;
        this.calculate_input_total();
    };
    PurchaseOrderStore.prototype.calculate_input_total = function () {
        this.input_total = this.input_amount - this.input_discount + this.input_tax;
    };
    PurchaseOrderStore.prototype.update_input_quantity = function (value) {
        this.input_quantity = value;
        this.calculate_input_amount();
    };
    PurchaseOrderStore.prototype.update_input_list_price = function (value) {
        this.input_list_price = value;
        this.calculate_input_amount();
    };
    PurchaseOrderStore.prototype.update_input_discount = function (value) {
        this.input_discount = value;
        this.calculate_input_total();
    };
    PurchaseOrderStore.prototype.update_input_tax = function (value) {
        this.input_tax = value;
        this.calculate_input_total();
    };
    PurchaseOrderStore.prototype.update_amount_total = function (value) {
        this.input_amount = value;
    };
    PurchaseOrderStore.prototype.update_input_total = function (value) {
        this.input_total = value;
    };
    PurchaseOrderStore.prototype.update_po_field = function (key, value) {
        var _a;
        if (this.purchase_order != undefined) {
            this.purchase_order = __assign(__assign({}, this.purchase_order), (_a = {}, _a[key] = value, _a));
        }
    };
    PurchaseOrderStore.prototype.update_purchase_order = function (purchase_order) {
        if (purchase_order === null) {
            this.purchase_order = undefined;
        }
        else {
            this.purchase_order = purchase_order;
        }
    };
    PurchaseOrderStore.prototype.approve_purchase_order = function (purchase_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/purchase-orders/".concat(purchase_order_id, "/approve/"))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.add_item = function (payload, po_id) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/purchase-orders/".concat(po_id, "/add-item/"), payload)];
                    case 1:
                        response = _a.sent();
                        this.get(po_id);
                        this.sync_line_items(response);
                        return [2 /*return*/];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.send_to_vendor = function (purchase_order_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.purchase_orders.map(function (po) {
                            if (po.id === purchase_order_id) {
                                return __assign(__assign({}, po), { send_to_vendor: true });
                            }
                            return __assign({}, po);
                        });
                        return [4 /*yield*/, post("/staff/purchase-orders/".concat(purchase_order_id, "/sent-to-vendor/"))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.get_sales_order = function (id, in_item) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/sales-orders/".concat(id))];
                    case 1:
                        response = _a.sent();
                        if (in_item === true) {
                            this.sale_order_by_item = response.id;
                            this.products_by_item = [];
                            this.combined_sales_orders_and_line_item = [{ soId: response.id, lineItemId: 0, label: response.name }];
                        }
                        else {
                            this.products_by_item = [];
                            this.temp_line_items = [];
                            if (id) {
                                this.combined_sales_orders_and_line_item = [{ soId: response.id, lineItemId: 0, label: response.name }];
                            }
                            this.sales_order = undefined;
                            if (response.domestic_process_needed | response.hardware_required) {
                                {
                                    this.sales_order = response;
                                }
                            }
                        }
                        return [2 /*return*/, response];
                }
            });
        });
    };
    PurchaseOrderStore.prototype.removeLineItems = function () {
        this.combined_sales_orders_and_line_item = [];
        this.line_items = [];
        this.recalculate_totals();
    };
    PurchaseOrderStore.prototype.removeSalesOrder = function () {
        this.sales_order = undefined;
        this.recalculate_totals();
    };
    PurchaseOrderStore.prototype.set_zx_line_items = function () {
        var _this = this;
        var _a;
        if (!this.purchase_order) {
            return;
        }
        if (!this.sales_order) {
            return;
        }
        var notes = '';
        (_a = this.sales_order) === null || _a === void 0 ? void 0 : _a.line_items.map(function (li, index) {
            var _a, _b;
            if (li.hardware_required || li.domestic_process_needed) {
                var findIndex = _this.line_items.findIndex(function (obj) {
                    return obj.name === li.name && obj.quantity === li.quantity;
                });
                if (findIndex === -1) {
                    // save notes
                    notes += (_a = li.hardwares_required) === null || _a === void 0 ? void 0 : _a.join();
                    notes += (_b = li.domestic_processes) === null || _b === void 0 ? void 0 : _b.join();
                    // @ts-expect-error lacks of account
                    _this.add_line_item({
                        id: index,
                        description: li.name,
                        name: li.name,
                        list_price: 0,
                        quantity: li.quantity,
                        tax: Number(li.taxes),
                        total: 0,
                        amount: 0,
                        discount: Number(li.discount),
                    });
                }
            }
        });
        if (this.purchase_order) {
            this.purchase_order.notes = notes;
        }
    };
    PurchaseOrderStore.prototype.getSalesOrderItems = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/sales-order-line-items/", { params: params })];
                    case 1:
                        response = _a.sent();
                        this.products_by_item = response.results;
                        return [2 /*return*/];
                }
            });
        });
    };
    return PurchaseOrderStore;
}());
export default new PurchaseOrderStore();
