var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal, notification, Spin, message, Tag } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import { ErrorRender } from '@partsbadger/library';
import ShipmentEditForm from '../Form/ShipmentEditForm';
export var EditBox = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = React.useState(false), isPrinting = _j[0], setIsPrinting = _j[1];
    var _k = React.useState(false), isLoading = _k[0], setIsLoading = _k[1];
    var _l = React.useState(false), isCancelling = _l[0], setIsCancelling = _l[1];
    useEffect(function () { }, []);
    if (isLoading) {
        return (React.createElement("div", null,
            React.createElement(Spin, { tip: 'Loading' })));
    }
    if (!((_a = IntakeStore.outtake) === null || _a === void 0 ? void 0 : _a.box)) {
        return React.createElement("div", null, "No Shipment created yet");
    }
    var _m = (_b = IntakeStore.outtake) === null || _b === void 0 ? void 0 : _b.box, id = _m.id, carrier = _m.carrier, shipping_method = _m.shipping_method, shipping_account = _m.shipping_account, bill_to_customer = _m.bill_to_customer, notes = _m.notes, internal_notes = _m.internal_notes, tracking_number = _m.tracking_number, boxes_included = _m.boxes_included, carrier_shipping_cost = _m.carrier_shipping_cost, shipping_cost = _m.shipping_cost, late_shipping_cost = _m.late_shipping_cost, is_api_shipment = _m.is_api_shipment, packages = _m.packages;
    var outbound_destination = IntakeStore.outtake.outbound_destination;
    return (React.createElement(React.Fragment, null, IntakeStore.editBoxVisible && (React.createElement(Modal, { title: React.createElement(React.Fragment, null,
            "Edit Box",
            ' ',
            React.createElement(Tag, { color: ((_d = (_c = IntakeStore.outtake) === null || _c === void 0 ? void 0 : _c.box) === null || _d === void 0 ? void 0 : _d.status) == 'Shipped' ? 'green' : 'yellow' }, (_f = (_e = IntakeStore.outtake) === null || _e === void 0 ? void 0 : _e.box) === null || _f === void 0 ? void 0 : _f.status)), visible: true, width: 1200, onCancel: function () { return IntakeStore.setEditBoxVisible(false); }, footer: [] },
        React.createElement(ShipmentEditForm, { box: {
                id: id,
                carrier: carrier,
                shipping_method: shipping_method,
                shipping_account: shipping_account,
                bill_to_customer: bill_to_customer,
                notes: notes,
                internal_notes: internal_notes,
                tracking_number: tracking_number,
                boxes_included: boxes_included,
                shipping_cost: shipping_cost,
                carrier_shipping_cost: carrier_shipping_cost,
                late_shipping_cost: late_shipping_cost,
                is_api_shipment: is_api_shipment,
                packages: packages.map(function (p) {
                    var _a, _b;
                    return {
                        package_length: p.package_length,
                        package_width: p.package_width,
                        package_height: p.package_height,
                        package_weight: p.package_weight,
                        tracking_number: p.tracking_number,
                        shipping_cost: p.shipping_cost,
                        label: {
                            id: (_a = p.label) === null || _a === void 0 ? void 0 : _a.id,
                            file: (_b = p.label) === null || _b === void 0 ? void 0 : _b.file,
                        },
                    };
                }),
            }, handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        return [4 /*yield*/, IntakeStore.editBox(IntakeStore.outtake.id, payload)];
                        case 1:
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, handleClose: function () {
                IntakeStore.setEditBoxVisible(false);
            }, outbound_destination: outbound_destination ? outbound_destination : '' }),
        React.createElement("div", { className: "flex flex-row items-center justify-center mt-10" }, ((_h = (_g = IntakeStore.outtake) === null || _g === void 0 ? void 0 : _g.box) === null || _h === void 0 ? void 0 : _h.status) == 'Shipped' && (React.createElement(Button, { loading: isCancelling, type: "link", style: {
                color: 'red',
            }, onClick: function () {
                var _a, _b;
                if (!((_b = (_a = IntakeStore.outtake) === null || _a === void 0 ? void 0 : _a.box) === null || _b === void 0 ? void 0 : _b.tracking_number)) {
                    message.error('No tracking number provided');
                    return;
                }
                setIsCancelling(true);
                IntakeStore.cancelShipment(IntakeStore.outtake.id, IntakeStore.outtake.box.tracking_number)
                    .then(function (e) {
                    IntakeStore.setEditBoxVisible(false);
                })
                    .catch(function (e) {
                    var _a;
                    notification.error({
                        message: 'Error cancelling the shipment',
                        description: React.createElement(ErrorRender, { error: (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data }),
                    });
                })
                    .finally(function () { return setIsCancelling(false); });
            } }, "Cancel shipment")))))));
});
