import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
export var TableSalesOrderItems = observer(function (props) {
    return (React.createElement("div", { className: 'flex flex-row w-full' },
        React.createElement("div", { className: 'w-1/4' },
            React.createElement(Checkbox, { checked: props.isSelected, onChange: function (e) {
                    props.handleSelect(props.order_line_item.id, props.order_line_item.quantity, e.target.checked);
                } }, "Select Item")),
        React.createElement("div", { className: 'w-1/4' }, props.order_line_item.name),
        React.createElement("div", { className: 'w-1/4' }, props.order_line_item.awarded_vendor),
        React.createElement("div", { className: 'w-1/4' }, props.order_line_item.quantity)));
});
