import React, { useEffect, useState } from 'react';
import {
    Card,
    Table,
    Button,
    notification,
    Checkbox,
    Input,
    InputNumber,
    Form,
    Alert,
    Popconfirm,
    Tooltip,
} from 'antd';
import { InfoCircleFilled, WarningOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';

import { get, post } from '@partsbadger/utils';
import { ICancelProduct } from '../../../../../src/stores/types';

type Params = {
    sales_order_id: string;
};

type CancelData = {
    sales_order_line_item: number;
    cancelled_quantity: number | null | undefined | '';
    cancellation_fee?: number | null | undefined;
    isSelected?: boolean | null;
    quantity?: number;
};

const { TextArea } = Input;

interface CustomCheckboxEvent {
    target: {
        checked: boolean;
    };
}

import { UserStore } from '../../../../stores/UserStore';

const CancelItems = () => {
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [stateData, setStateData] = useState<ICancelProduct[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [stateSubject, setStateSubject] = useState('');
    // message for the validacions in the inputs from the table
    const [stateMessageInputsTable, setStateMessageInputsTable] = useState('');
    // error response from the backend
    const [stateErrorMessage, setStateErrorMessage] = useState('');

    // get sales_order_id from url
    const { sales_order_id } = useParams<Params>();

    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        if (!UserStore?.user?.group?.includes('Production')) {
            history.push('/forbidden');
        } else {
            getSalesOrderItems();
        }
    }, []);

    const getSalesOrderItems = () => {
        const url = `/staff/sales-orders/${sales_order_id}/line-items/`;
        setLoading(true);
        get(url)
            .then(response => {
                setStateData(response);
                if (response.length > 0) {
                    setStateSubject(response[0].sales_order.name);
                }
            })
            .catch(error => {
                notification.error({ message: 'Error getting data.', duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const saveCancelItems = (data: CancelData[], notes: string) => {
        const url = `/staff/sales-orders/${sales_order_id}/cancel-items/`;
        setLoadingSave(true);
        post(url, {
            cancellation_items: data,
            reason: notes,
        })
            .then(response => {
                notification.success({ message: 'The order items have been successfully canceled.', duration: 5 });
                // redirect to the sales order details page
                history.goBack();
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.status === 400) {
                    const errorMessage = formatErrorMessage(error.response.data);
                    setStateErrorMessage(errorMessage);
                } else {
                    setStateErrorMessage('Error cancelling items, internal server error.');
                }
            })
            .finally(() => {
                setLoadingSave(false);
            });
    };

    const formatErrorMessage = (errorData: any) => {
        let errorMessage = '';
        if (
            'cancellation_items' in errorData &&
            // check if all items are objects
            errorData.cancellation_items.every(
                (item: any) => typeof item === 'object' && item !== null && !Array.isArray(item)
            )
        ) {
            const cancellation_items = errorData.cancellation_items;
            if (cancellation_items.length > 0) {
                // search in cancellation_items where object has a keys
                const array_errors = cancellation_items.filter((item: any) => Object.keys(item).length > 0);
                if (array_errors.length > 0) {
                    // get errors one time only
                    const errors = array_errors[0];
                    for (const key in errors) {
                        errorMessage += `<b>${key}</b>: ${errors[key]}<br />`;
                    }
                } else {
                    errorMessage += 'Error saving data.';
                }
            }
        }

        if (
            'cancellation_items' in errorData &&
            // check if all items are strings
            errorData.cancellation_items.every((item: any) => typeof item === 'string')
        ) {
            const cancellation_items = errorData.cancellation_items;
            for (const key in cancellation_items) {
                errorMessage += `<b>Data to save</b>: ${cancellation_items[key]}<br />`;
            }
        }

        if ('reason' in errorData) {
            errorMessage += `<b>Reason</b>: ${errorData.reason}`;
        }
        return errorMessage;
    };

    const handleQuantityCanceledChange = (value: number | null, record: ICancelProduct, field: string) => {
        if (value === null || !isNaN(value)) {
            const newData = [...stateData];
            const index = newData.findIndex(item => record.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, [field]: value === 0 ? '' : value });
                setStateData(newData);
            }
        }
    };

    const handleRowSelectionChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const handleSelectAllChange = (e: CustomCheckboxEvent) => {
        const { checked } = e.target;
        setSelectAll(checked);
        const selectedKeys = checked
            ? stateData.filter(record => record.cancelled_quantity ?? 0 <= record.quantity).map(item => item.id)
            : [];
        setSelectedRowKeys(selectedKeys);
    };

    const getCancelData = (selectedRowKeys: React.Key[], orderData: ICancelProduct[]) => {
        const updatedCancelData: CancelData[] =
            orderData.map(item => ({
                sales_order_line_item: item.id,
                cancelled_quantity: item.cancelled_quantity,
                cancellation_fee: item.cancellation_fee,
                isSelected: selectedRowKeys.includes(item.id) ? true : null,
                quantity: item.quantity,
            })) ?? [];
        const selectedItems = updatedCancelData
            .filter(
                item =>
                    item.isSelected === true &&
                    item.cancelled_quantity !== undefined &&
                    item.cancelled_quantity !== null &&
                    item.cancelled_quantity !== ''
            ) // data to send to the backend
            .map(item => ({
                sales_order_line_item: item.sales_order_line_item,
                cancelled_quantity: item.cancelled_quantity,
                cancellation_fee: item.cancellation_fee,
            }));
        return selectedItems;
    };

    // check if any item is selected only
    const getSelectedOnly = (selectedRowKeys: React.Key[], orderData: ICancelProduct[]) => {
        const updatedCancelData: CancelData[] =
            orderData.map(item => ({
                sales_order_line_item: item.id,
                cancelled_quantity: item.cancelled_quantity,
                cancellation_fee: item.cancellation_fee,
                isSelected: selectedRowKeys.includes(item.id) ? true : null,
                quantity: item.quantity,
            })) ?? [];
        const selectedItems = updatedCancelData.filter(
            item =>
                item.isSelected === true &&
                (item.cancelled_quantity === undefined ||
                    item.cancelled_quantity === null ||
                    item.cancelled_quantity === '')
        );
        return selectedItems;
    };

    const everythingIsCancelled = (record: ICancelProduct) => {
        const original_quantity = record.original_quantity ?? 0;
        const total_cancelled_quantity = record.total_cancelled_quantity ?? 0;
        return original_quantity > 0 && total_cancelled_quantity >= original_quantity;
    };

    const columns: ColumnProps<ICancelProduct>[] = [
        {
            title: 'Part Name',
            render: (r: ICancelProduct) => {
                return (
                    <div>
                        <div>{r.name}</div>
                        {r.name === 'CNC Part' && <div>{r.product_description}</div>}
                    </div>
                );
            },
        },
        {
            title: 'Required Partner Ship Date ',
            render: (r: ICancelProduct) => {
                return r.required_partner_ship_date;
            },
        },
        {
            title: 'Required Part Ship Date',
            render: (r: ICancelProduct) => {
                return r.required_part_ship_date;
            },
        },
        {
            title: 'Current Quantity Ordered',
            render: (record: ICancelProduct) => <div className="flex flex-row items-center">{record.quantity}</div>,
        },
        {
            title: 'Quantity Canceled',
            render: (r: ICancelProduct) => {
                return r.total_cancelled_quantity;
            },
        },
        {
            title: 'Cancellation Fee',
            width: 200,
            render: (record: ICancelProduct) => (
                <div className="px-4">
                    <InputNumber
                        className="w-full"
                        disabled={everythingIsCancelled(record) || isDisabled(record.shipping_status)}
                        min={0.01}
                        step={0.01}
                        precision={2}
                        maxLength={10}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={value => {
                            handleQuantityCanceledChange(
                                value?.toString() === '' ? null : parseFloat(value ? value.toString() : '0'),
                                record,
                                'cancellation_fee'
                            );
                        }}
                    />
                </div>
            ),
        },
        {
            title: 'Quantity To be Canceled',
            width: 200,
            dataIndex: 'quantity_to_cancel',
            render: (_: any, record: ICancelProduct) => (
                <div className={`flex flex-row items-center pl-4 pr-4`}>
                    <Input
                        type="number"
                        step={'any'}
                        disabled={everythingIsCancelled(record) || isDisabled(record.shipping_status)}
                        value={
                            inputValues[record.id] ??
                            isShippedInPartial(
                                record.shipping_status,
                                record.quantity_remaining_to_ship,
                                record.cancelled_quantity ?? 0
                            )
                        }
                        onChange={e => handleInputChange(e.target.value.trim(), record.id)}
                    />
                    {everythingIsCancelled(record) && (
                        <Tooltip title="The product is already cancelled in its entirety.">
                            <WarningOutlined style={{ color: 'orange' }} className="ml-3 mb-1" />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: <Checkbox checked={selectAll} onChange={handleSelectAllChange} />,
            align: 'center',
            dataIndex: 'selected',
            render: (_: any, record: ICancelProduct) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.id)}
                    disabled={everythingIsCancelled(record) || isDisabled(record.shipping_status)}
                    onChange={() =>
                        handleRowSelectionChange(
                            selectedRowKeys.includes(record.id)
                                ? selectedRowKeys.filter(key => key !== record.id)
                                : [...selectedRowKeys, record.id]
                        )
                    }
                />
            ),
        },
    ];

    const onFinish = (values: any) => {
        setStateMessageInputsTable('');
        const onlySelected = getSelectedOnly(selectedRowKeys, stateData);
        if (onlySelected.length > 0) {
            setStateMessageInputsTable('Please fill in the Quantity To be Canceled column for the selected items.');
        } else {
            const dataToCancel = getCancelData(selectedRowKeys, stateData);
            if (dataToCancel.length > 0) {
                saveCancelItems(dataToCancel, values.notes);
            } else {
                setStateMessageInputsTable('Please select at least one item to proceed.');
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        const dataToCancel = getCancelData(selectedRowKeys, stateData);
        if (dataToCancel.length === 0) {
            setStateMessageInputsTable(
                `Please fill in and select the editable fields for the 'Quantity To be Canceled' column as needed.`
            );
        } else {
            setStateMessageInputsTable('');
        }
    };

    const isDisabled = (shipping_status: string) => {
        return shipping_status === 'Shipped in Full';
    };

    const [inputValues, setInputValues] = useState<any>({});

    const handleInputChange = (new_value: string, recordId: number) => {
        let value = new_value;
        const record = stateData.find(item => item.id === recordId);

        if (parseInt(value, 10) < 1) {
            value = '1';
        }
        const max = record ? record.quantity : 0;
        if (parseInt(value, 10) > max) {
            value = max.toString();
        }
        if (max < 1) {
            value = '0';
        }

        setInputValues((prevValues: any) => ({
            ...prevValues,
            [recordId]: value,
        }));

        if (record) {
            handleQuantityCanceledChange(value === '' ? null : parseInt(value, 10), record, 'cancelled_quantity');
        }
    };

    const isShippedInPartial = (
        shipping_status: string,
        quantity_remaining_to_ship: number,
        cancelled_quantity: number
    ) => {
        if (shipping_status === 'Shipped in Partial') {
            return quantity_remaining_to_ship;
        } else if (cancelled_quantity > 0) {
            return cancelled_quantity;
        } else {
            return undefined;
        }
    };

    return (
        <Card title={`Cancelation Items for ${stateSubject}`} loading={loading}>
            <div className="flex flex-col">
                <Alert
                    message={
                        <span>
                            Please fill in and select the necessary fields from the <b>Quantity To be Canceled</b>{' '}
                            column before proceeding. The fields from the <b>Cancellation Fee</b> column are optional.
                        </span>
                    }
                    type="info"
                    showIcon
                    className="bg-blue-100 border-blue-400 text-blue-700 mb-2"
                    icon={<InfoCircleFilled style={{ color: '#3498db' }} />}
                />
                <div className={'w-full overflow-auto'}>
                    <Table
                        bordered
                        rowKey="id"
                        columns={columns}
                        loading={false}
                        dataSource={stateData}
                        pagination={false}
                    />
                </div>
                <div className="flex flex-col items-center">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        onFinishFailed={onFinishFailed}
                        className="w-full"
                    >
                        <div className="flex flex-col items-center mt-4">
                            {stateMessageInputsTable && (
                                <Form.Item validateStatus="error" help={stateMessageInputsTable} />
                            )}
                            <Form.Item
                                label={'Notes'}
                                name="notes"
                                rules={[
                                    { required: true, message: 'Notes is required.' },
                                    { max: 255, message: 'Maximum 255 characters are allowed.' },
                                ]}
                                className="w-full"
                            >
                                <TextArea size="large" rows={4} />
                            </Form.Item>
                            <Form.Item>
                                <Popconfirm
                                    // disabled={true}
                                    placement="topLeft"
                                    title={'Are you sure you want to cancel the selected items?'}
                                    onConfirm={() => form.submit()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={loadingSave} className="" type="primary" htmlType="submit">
                                        Proceed with Cancellation
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                        </div>
                    </Form>
                    {stateErrorMessage && (
                        <Alert
                            message="Error Message"
                            description={<div dangerouslySetInnerHTML={{ __html: stateErrorMessage }} />}
                            showIcon
                            type="error"
                            closable
                            onClose={() => setStateErrorMessage('')}
                            className="w-full"
                        />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default CancelItems;
