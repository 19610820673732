import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FileAddOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Divider, Drawer, Input, Row, Select, Spin, Table } from 'antd';
import { fetchDeals, showAddDealForm } from '../../redux/actions/dealActions';
import { fetchAccounts, fetchContacts } from '../../redux/actions/accountActions';

import { connect } from 'react-redux';
import { DealNew } from './DealNew';
import { fetchUsers } from '../../redux/actions/userActions';
import moment from 'moment';
import Humanize from 'humanize-plus';

const { Column } = Table;
const Search = Input.Search;
const FormItem = Form.Item;

let busy = null;
const ButtonGroup = Button.Group;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

class DealFilter extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    search: values.search,
                };

                if (values.itar) {
                    params['itar'] = values.itar;
                }

                if (values.account) {
                    params['account'] = values.account.key;
                }

                if (values.contact) {
                    params['contact'] = values.contact.key;
                }

                if (values.created_by) {
                    params['created_by'] = values.created_by.key;
                }

                if (values.owner) {
                    params['owner'] = values.owner.key;
                }

                if (values.stage) {
                    params['stage'] = values.stage;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                this.props.fetchDeals(params);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row className={'row-filters'}>
                <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Search" {...formItemLayout}>
                                {getFieldDecorator(
                                    'search',
                                    {}
                                )(<Search placeholder="Search" style={{ width: '100%' }} />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="Account" {...formItemLayout}>
                                {getFieldDecorator(
                                    'account',
                                    {}
                                )(
                                    <Select
                                        allowClear
                                        showSearch
                                        labelInValue
                                        placeholder="Search by name"
                                        notFoundContent={this.props.accounts.isLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        optionKeyProp={'value'}
                                        onSearch={name => {
                                            if (busy) {
                                                clearTimeout(busy);
                                            }
                                            const params = {
                                                search: name,
                                            };
                                            busy = setTimeout(() => this.props.fetchAccounts(params), 500);
                                        }}
                                        style={{ width: '100%' }}
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {this.props.accounts.entities.map(d => (
                                            <Select.Option key={d.id}>{d.name}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col {...cols}>
                            <FormItem label="Contact" {...formItemLayout}>
                                {getFieldDecorator(
                                    'contact',
                                    {}
                                )(
                                    <Select
                                        allowClear
                                        showSearch
                                        labelInValue
                                        placeholder="Search by name or email"
                                        notFoundContent={this.props.contacts.isLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        optionKeyProp={'value'}
                                        onSearch={name => {
                                            if (busy) {
                                                clearTimeout(busy);
                                            }
                                            const params = {
                                                search: name,
                                            };
                                            busy = setTimeout(() => this.props.fetchContacts(params), 500);
                                        }}
                                        style={{ width: '100%' }}
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {this.props.contacts.entities.map(d => (
                                            <Select.Option key={d.id}>
                                                {d.fullname} {d.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="Created By" {...formItemLayout}>
                                {getFieldDecorator(
                                    'created_by',
                                    {}
                                )(
                                    <Select
                                        allowClear
                                        showSearch
                                        labelInValue
                                        placeholder="Search by name or email"
                                        notFoundContent={this.props.user.isLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        optionKeyProp={'value'}
                                        onSearch={name => {
                                            if (busy) {
                                                clearTimeout(busy);
                                            }
                                            const params = {
                                                search: name,
                                                is_staff: true,
                                            };
                                            busy = setTimeout(() => this.props.fetchUsers(params), 500);
                                        }}
                                        style={{ width: '100%' }}
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {this.props.user.entities.map(d => (
                                            <Select.Option key={d.id}>
                                                {d.fullname} {d.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="Sales Rep" {...formItemLayout}>
                                {getFieldDecorator(
                                    'owner',
                                    {}
                                )(
                                    <Select
                                        allowClear
                                        showSearch
                                        labelInValue
                                        placeholder="Search by name or email"
                                        notFoundContent={this.props.user.isLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        optionKeyProp={'value'}
                                        onSearch={name => {
                                            if (busy) {
                                                clearTimeout(busy);
                                            }
                                            const params = {
                                                search: name,
                                                is_staff: true,
                                            };
                                            busy = setTimeout(() => this.props.fetchUsers(params), 500);
                                        }}
                                        style={{ width: '100%' }}
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {this.props.user.entities.map(d => (
                                            <Select.Option key={d.id}>
                                                {d.fullname} {d.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DatePicker.RangePicker />)}
                            </FormItem>
                        </Col>
                        <Col sm={24}>
                            <div className={'flex justify-center mt-4'}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Row>
        );
    }
}

const DealFilterForm = Form.create()(DealFilter);

class DealListComponent extends React.Component {
    state = {
        visible: false,
        childrenDrawer: false,
    };

    componentDidMount() {
        this.props.fetchDeals({});
    }

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <Fragment>
                <Divider>
                    <div className={'title'}> Deals</div>
                </Divider>

                <DealNew {...this.props} />

                <DealFilterForm {...this.props} />

                <div className={'flex flex-row-reverse'}>
                    <ButtonGroup className={'add-button'}>
                        <Button
                            type="primary"
                            icon={<FileAddOutlined />}
                            onClick={() => {
                                this.props.showAddDealForm();
                            }}
                        >
                            Add Deal
                        </Button>
                    </ButtonGroup>
                </div>

                <Row>
                    <Col xs={24}>
                        <Table
                            rowKey={'id'}
                            dataSource={this.props.deals.entities}
                            loading={this.props.deals.isLoading}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.props.history.push('/deals/' + record.id);
                                    },
                                };
                            }}
                            scroll={{ x: 1500 }}
                        >
                            <Column title="Name" dataIndex="name" key="name" />
                            <Column
                                title="Created Time"
                                dataIndex="created_time"
                                key="created_time"
                                render={(text, record) => {
                                    return moment(record.created_time).format('LLL');
                                }}
                            />
                            <Column
                                title="Account"
                                dataIndex="account"
                                key="account"
                                render={(text, record) => <span>{record.account && record.account?.name}</span>}
                            />
                            <Column
                                title="Contact"
                                dataIndex="contact"
                                key="contact"
                                render={(text, record) => <span>{record.contact?.fullname}</span>}
                            />
                            <Column
                                title="Created By"
                                dataIndex="created_by"
                                key="created_by"
                                render={(text, record) => <span>{record.created_by?.fullname}</span>}
                            />
                            <Column
                                title="Owner"
                                dataIndex="owner"
                                key="owner"
                                render={(text, record) => <span>{record.owner?.fullname}</span>}
                            />
                            <Column title="Quotes" dataIndex="items" key="items" />
                            <Column
                                title="Amount"
                                dataIndex="amount"
                                key="amount"
                                render={(t, r) => {
                                    return <span>{Humanize.formatNumber(r.amount, 2)}</span>;
                                }}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <span>
                                        <Link to={'/deals/' + record.id}>View</Link>
                                    </span>
                                )}
                            />
                        </Table>
                    </Col>
                </Row>

                <Drawer title="Filter" width={520} closable={false} onClose={this.onClose} visible={this.state.visible}>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={this.onClose}
                        >
                            Cancel
                        </Button>
                        <Button onClick={this.onClose} type="primary">
                            Submit
                        </Button>
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        accounts: state.accounts,
        contacts: state.contacts,
        user: state.user,
        deals: state.deals,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDeals: params => dispatch(fetchDeals(params)),
        fetchContacts: name => dispatch(fetchContacts(name)),
        fetchAccounts: name => dispatch(fetchAccounts(name)),
        fetchUsers: search => dispatch(fetchUsers(search)),
        showAddDealForm: () => dispatch(showAddDealForm()),
    };
};

export const DealList = connect(mapStateToProps, mapDispatchToProps)(DealListComponent);
