import React from 'react';
import { Select } from 'antd';

import { observer } from 'mobx-react';
import { QuoteStore } from '@partsbadger/quote-tool/lib/Stores';

const MaterialsSelect = (props: any) => {
    const onSearch = (value: string) => {
        QuoteStore.getMaterials({ search: value });
    };
    return (
        <Select showSearch size="small" onSearch={onSearch} {...props} filterOption={false}>
            {QuoteStore.materials.map(m => {
                return (
                    <Select.Option key={m.id} value={m.name}>
                        {m.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default observer(MaterialsSelect);
