var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row, Spin, Upload } from 'antd';
import { observer } from 'mobx-react';
/**
 * Strip the name from url
 * @param filename
 */
var getFileName = function (filename) {
    var name = filename.split('?')[0].split('/');
    return name[name.length - 1];
};
var UploadRedactedFilesForm = function (_a) {
    var parts = _a.parts, onUploadRedactedFile = _a.onUploadRedactedFile;
    var content = parts
        .filter(function (item) { return item.name !== 'CNC Part'; })
        .map(function (item, index) {
        return (React.createElement(Row, { className: 'fex flex-row border my-4 p-2', key: index },
            React.createElement(Row, null,
                React.createElement("div", { className: 'text-center' },
                    React.createElement("span", { className: 'font-bold' }, item.name))),
            React.createElement(Row, null,
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" },
                        React.createElement("div", { className: 'p-4' },
                            React.createElement(UploadDragger, { file_type: '3d', uploaded: !!(item === null || item === void 0 ? void 0 : item.redacted_3d_file), handleUpload: function (file) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, onUploadRedactedFile(item.id, '3d', file)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); } })),
                        (item === null || item === void 0 ? void 0 : item.redacted_3d_file) && (React.createElement("a", { target: '_blank', rel: "noopener noreferrer", href: item.redacted_3d_file },
                            React.createElement(LegacyIcon, { type: 'cloud' }),
                            " ",
                            getFileName(item === null || item === void 0 ? void 0 : item.redacted_3d_file))))),
                React.createElement(Col, { md: 12 },
                    React.createElement("div", { className: "flex-1/2 text-center" },
                        React.createElement("div", { className: 'p-4' },
                            React.createElement(UploadDragger, { file_type: '2d', uploaded: !!(item === null || item === void 0 ? void 0 : item.redacted_2d_file), handleUpload: function (file) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, onUploadRedactedFile(item.id, '2d', file)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); } }),
                            (item === null || item === void 0 ? void 0 : item.redacted_2d_file) && (React.createElement("a", { target: '_blank', rel: "noopener noreferrer", href: item.redacted_2d_file },
                                React.createElement(LegacyIcon, { type: 'cloud' }),
                                " ",
                                getFileName(item === null || item === void 0 ? void 0 : item.redacted_2d_file)))))))));
    });
    return React.createElement("div", null, content);
};
var UploadDragger = function (props) {
    var _a = useState(false), uploading = _a[0], setUploading = _a[1];
    var _b = useState(false), isUploaded = _b[0], setUploaded = _b[1];
    useEffect(function () {
        setUploaded(props.uploaded);
    }, [props.uploaded]);
    return (React.createElement(Upload.Dragger, { style: {
            maxWidth: 300,
            margin: 'auto',
        }, accept: props.file_type === '3d' ? '.step, .STEP' : '.pdf', showUploadList: false, beforeUpload: function (file) {
            setUploading(true);
            props
                .handleUpload(file)
                .then(function () {
                setUploaded(true);
            })
                .finally(function () { return setUploading(false); });
            return false;
        } },
        React.createElement("p", null, isUploaded ? (React.createElement("div", null,
            React.createElement(LegacyIcon, { type: 'check', style: { color: 'green' } }),
            "Uploaded")) : (React.createElement("p", { className: "ant-upload-text", style: { color: 'red' } },
            "Drag a ",
            props.file_type,
            " file"))),
        React.createElement("div", null, uploading && React.createElement(Spin, { spinning: true }))));
};
export default observer(UploadRedactedFilesForm);
