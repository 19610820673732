import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import {Accounts} from "@partsbadger/library";
import {postContact, updateContact} from "../../../redux/actions/accountActions";
import {GenericSelect} from "@partsbadger/library";

const formItemLayout = {
    labelCol: {span: 12},
    wrapperCol: {span: 12},
};

const ButtonGroup = Button.Group;


export const ContactForm = Form.create()((props) => {


    const {form, defaultContact, handleCreated} = props;
    const {getFieldDecorator} = form;
    const dispatch = useDispatch();
    const contacts = useSelector(state => state.contacts);


    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    ...values,
                    account: values.account.key,
                    email: values.email,
                    owner: values.owner.key,
                };

                if (defaultContact?.id) {
                    dispatch(updateContact(defaultContact.id, payload, handleCreated));
                } else {
                    dispatch(postContact(payload, handleCreated))
                }
            }
        });
    };


    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <Form.Item label={"Account"} {...formItemLayout}>
                    {getFieldDecorator('account', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: defaultContact?.account ? {
                            'key': defaultContact.account.id,
                            'label': defaultContact.account.name,
                        } : {}
                    })(
                        <Accounts/>
                    )}
                </Form.Item>
                <Form.Item label={"Email"} {...formItemLayout} >
                    {getFieldDecorator('email', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: defaultContact?.email

                    })(
                        <Input autoComplete={"off"} type={"email"}/>
                    )}
                </Form.Item>
                <Form.Item label={"First Name"} {...formItemLayout} >
                    {getFieldDecorator('first_name', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: defaultContact?.first_name
                    })(
                        <Input autoComplete={"off"}/>
                    )}
                </Form.Item>
                <Form.Item label={"Last Name"} {...formItemLayout} >
                    {getFieldDecorator('last_name', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: defaultContact?.last_name
                    })(
                        <Input autoComplete={"off"}/>
                    )}
                </Form.Item>

                <Form.Item label={"Owner"} {...formItemLayout} >
                    {getFieldDecorator('owner', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: defaultContact?.owner ? {
                            'key': defaultContact.owner.id,
                            'label': defaultContact.owner.fullname,
                        } : {}
                    })(
                        <GenericSelect recordType={"User"} filters={{
                            is_staff: true,
                            is_active: true
                        }} size="small" />
                    )}
                </Form.Item>

                <ButtonGroup className={"add-button"}>
                    <Button type="primary" htmlType="submit" icon={<LegacyIcon type={"plus"} />}
                            loading={contacts.isLoading}>
                        Save Contact
                    </Button>
                </ButtonGroup>
            </Form>

            <br/>
            <br/>

        </Fragment>
    );
});
