import {applyMiddleware, combineReducers, createStore} from 'redux';
import {Materials} from './reducers/materials';
import {Finishes} from './reducers/finishes';
import {Products} from './reducers/products';
import {HoleTolerances} from './reducers/holeTolerances';
import {PartTolerances} from './reducers/partTolerances';
import {Quotes} from './reducers/quotes';
import {Accounts} from './reducers/accounts';
import {Contacts} from './reducers/contacts';
import {Leads} from './reducers/leads';
import {MasterProduct} from './reducers/masterProducts';
import {Coatings} from './reducers/coatings';
import {Notifications} from './reducers/notifications';
import {Orders} from './reducers/order';
import {Deals} from './reducers/deals';
import {User} from './reducers/user'
import {UI} from './reducers/ui'
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

export const ConfigureStore = () => {


    const reducers = {
        materials: Materials,
        finishes: Finishes,
        products: Products,
        masterProducts: MasterProduct,
        accounts: Accounts,
        contacts: Contacts,
        leads: Leads,
        quotes: Quotes,
        orders: Orders,
        coatings: Coatings,
        deals: Deals,
        user: User,
        HoleTolerances: HoleTolerances,
        PartTolerances: PartTolerances,
        Notifications: Notifications,
        UI: UI,
    }


    // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //     const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    //     return createStore(combineReducers(reducers), composeEnhancers(
    //         applyMiddleware(thunk, logger)
    //     ));
    // }

    return createStore(
        combineReducers(reducers),
        applyMiddleware(thunk)
    );


}