import React, { useEffect, useRef, useState } from 'react';

import {
    Divider,
    message,
    Modal,
    Radio,
    Select,
    Skeleton,
    Spin,
    Popconfirm,
    Button,
    notification,
    Popover,
    Tag,
} from 'antd';
import Icon, {
    EyeOutlined,
    SyncOutlined,
    UploadOutlined,
    TagOutlined,
    DeleteOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
} from '@ant-design/icons';

import { observer } from 'mobx-react';
import { IRedactedProfile, SalesOrderStore } from '../../../stores/SalesOrderStore';
import SocketConnection from '../../../utils/socket_connection';

import EditPdf from '../../../components/SalesOrder/EditPdf';

import { ErrorRender, UploadFile } from '@partsbadger/library';

import {
    AlignFields,
    AlignRadioGroup,
    ButtonAntd,
    ButtonProfileAntd,
    ButtonResetAntd,
    CardAntd,
    CheckboxDiv,
    DrawerDiv,
    FieldText,
    FieldTitle,
    FlexCol,
    FlexRow,
    Header,
    InputAntd,
    InputTextAntd,
    SelectAntd,
} from './styles';

import PartCard from '../../../components/SalesOrder/EditPdf/PartCard';
import { RedactionToolStore } from '../../../components/SalesOrder/EditPdf/RedactionToolStore';
import { DownloadFile, get } from '@partsbadger/utils';
import axios from 'axios';
import FontTools from '../../../components/SalesOrder/EditPdf/FontTools';
import RedactionViewer from '../../../components/SalesOrder/EditPdf/RedactionViewer';
import JSZip from 'jszip';
import { RichTextEditor } from '@partsbadger/library';
import { TravelerModal } from '../components/TravelerModal';

import Tolerance from './Tolerance';
import ProductTemplate from './ProductTemplate';

const { Option } = Select;

interface ITolDataProps {
    tolXS: { show: boolean; tol: number | string };
    tolX: { show: boolean; tol: number | string };
    tolXX: { show: boolean; tol: number | string };
    tolXXX: { show: boolean; tol: number | string };
    tolXXXX: { show: boolean; tol: number | string };
}

interface ITolDataISOProps {
    tol06: { show: boolean; tol: number | string };
    tol630: { show: boolean; tol: number | string };
    tol30120: { show: boolean; tol: number | string };
    tol120400: { show: boolean; tol: number | string };
    tol4001000: { show: boolean; tol: number | string };
    tol10002000: { show: boolean; tol: number | string };
}

interface IProductData {
    PART: string;
    QTY: number;
    REQUIRED_SHIP_DATE: string;
    MATERIAL_CERT_REQUIRED: boolean;
    MATERIAL: string;
    UNS: string;
    FINISH: string;
    COATING: string;
    DWG_UNITS: string;
    TOLERANCE: 'UNLESS OTHERWISE STATED' | 'None';
}

interface IProps {
    match: {
        params: {
            id: number;
        };
    };
    onUpdate2dFile: (id: number) => Promise<void>;
    onUpdate3dFile: (id: number) => Promise<void>;
}

interface I2DAnd3DFile {
    name: string;
    url?: string;
}

const RedactionTool: React.FC<IProps> = props => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [isLoadingProfiles, setIsLoadingProfiles] = useState<boolean>(true);
    const [openTravelerModal, setOpenTravelerModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderItemId, setOrderItemId] = useState<number>(0);

    const [unit, setUnit] = useState<string>('in');
    const [freeTextArea, setFreeTextArea] = useState<string>();
    const [toleranceType, setToleranceType] = useState<string>('STANDARD');
    const [tolXS, setTolXS] = useState<string>('');
    const [tolXSChecked, setTolXSChecked] = useState<boolean>(true);
    const [tolX, setTolX] = useState<string>('');
    const [tolXChecked, setTolXChecked] = useState<boolean>(true);
    const [tolXX, setTolXX] = useState<string>('');
    const [tolXXChecked, setTolXXChecked] = useState<boolean>(true);
    const [tolXXX, setTolXXX] = useState<string>('');
    const [tolXXXChecked, setTolXXXChecked] = useState<boolean>(true);
    const [tolXXXX, setTolXXXX] = useState<string>('');
    const [tolXXXXChecked, setTolXXXXChecked] = useState<boolean>(true);

    const [tol06, setTol06] = useState<string>('0.1');
    const [tol06Checked, setTol06Checked] = useState<boolean>(true);

    const [tol630, setTol630] = useState<string>('0.2');
    const [tol630Checked, setTol630Checked] = useState<boolean>(true);

    const [tol30120, setTol30120] = useState<string>('0.3');
    const [tol30120Checked, setTol30120Checked] = useState<boolean>(true);

    const [tol120400, setTol120400] = useState<string>('0.5');
    const [tol120400Checked, setTol120400Checked] = useState<boolean>(true);

    const [tol4001000, setTol4001000] = useState<string>('0.8');
    const [tol4001000Checked, setTol4001000Checked] = useState<boolean>(true);

    const [tol10002000, setTol10002000] = useState<string>('1.2');
    const [tol10002000Checked, setTol10002000Checked] = useState<boolean>(true);

    const [productsDataState, setProductsDataState] = useState<IProductData[]>([]);
    const [tolerancesState, setTolerancesState] = useState<ITolDataProps | null>(null);
    const [tolerancesISOState, setTolerancesISOState] = useState<ITolDataISOProps | null>(null);

    const [insertDWG, setInsertDWG] = useState<boolean>(false);
    const [insertFTA, setInsertFTA] = useState<boolean>(false);
    const [lineItemId, setLineItemId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [newProfileName, setNewProfileName] = useState<string>();
    const [selectProfileName, setSelectProfileName] = useState<string>();
    const [a2DFile, setA2DFile] = useState<I2DAnd3DFile>();
    const [a3DFile, setA3DFile] = useState<I2DAnd3DFile>();
    const [redactedUrlFile, setRedactedUrlFile] = useState<string | null>();
    const [showAddStepFile, setShowAddStepFile] = useState<boolean>(false);
    const [showAddDrawingFile, setShowAddDrawingFile] = useState<boolean>(false);
    const [showAddRedactedFile, setShowAddRedactedFile] = useState<boolean>(false);
    const [resetDWG, setresetDWG] = useState<boolean>(false);
    const [dataVendorQualifications, setDataVendorQualifications] = useState<any>(null);
    const [loadindQualifications, setLoadindQualifications] = useState(false);
    const [isSavingProfiles, setIsSavingProfiles] = useState<boolean>(false);
    const [productName, setProductName] = useState<string>('');
    const [isSendToVendors, setIsSendToVendors] = useState<boolean>(false);
    const [isDownloadAllFiles, setIsDownloadAllFiles] = useState<boolean>(false);

    const [fontColor, setFontColor] = useState<string>('000000');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [fontBold, setFontBold] = useState(false);
    const [fontItalic, setFontItalic] = useState(false);
    const [ftFontSize, setFtFontSize] = useState<number>(15);

    const textAreaRef = useRef<any>(null);
    const freeTextAreaRef = useRef<any>(null);
    const redactedFileNameRef = useRef<any>(null);

    const [sendSalesOrder, setSendSalesOrder] = useState(false);
    const [salesOrderNotes, setSalesOrderNotes] = useState('');
    const [sending, setSending] = useState(false);
    const [status, setStatus] = useState<string | null>();
    const [currentUploadId, setCurrentUploadId] = useState<number | null>(null);

    const { id } = props.match.params;

    const order = SalesOrderStore.order;

    const profiles = SalesOrderStore.redactedProfiles;

    const { redaction } = RedactionToolStore;
    const [redact_as_flat_file, setRedactAsFlatFile] = useState(false);

    useEffect(() => {
        if (SalesOrderStore.order?.id) {
            const products_data: IProductData[] = SalesOrderStore.order.line_items
                .filter(i => i.quote_has_product && i.production_2d_file?.id === lineItemId)
                .map(item => {
                    if (item?.production_2d_file) {
                        setA2DFile({ name: item?.production_2d_file?.filename, url: item?.production_2d_file?.file });
                        setProductName(item?.name);
                    }

                    if (item?.production_3d_file) {
                        setA3DFile({ name: item?.production_3d_file?.filename });
                    }

                    setRedactedUrlFile(item?.production_2d_file?.redacted_file);

                    setStatus(item?.production_2d_file?.redaction_status);

                    setRedactAsFlatFile(item?.production_2d_file?.redact_as_flat_file ?? false);

                    return {
                        PART: item.name ?? '',
                        REQUIRED_SHIP_DATE: item.required_partner_ship_date ?? '',
                        QTY: item.quantity,
                        MATERIAL: item.quote_has_product?.product?.material?.name ?? '',
                        UNS: item.quote_has_product?.product?.material?.uns ?? '',
                        MATERIAL_CERT_REQUIRED: true,
                        FINISH: item.quote_has_product?.product?.finish?.name ?? '',
                        DWG_UNITS: unit,
                        COATING: item.quote_has_product?.product?.coating?.name ?? '',
                        TOLERANCE: 'UNLESS OTHERWISE STATED',
                    };
                });

            const tolerances = {
                tolX: { show: tolXChecked, tol: tolX },
                tolXX: { show: tolXXChecked, tol: tolXX },
                tolXXX: { show: tolXXXChecked, tol: tolXXX },
                tolXXXX: { show: tolXXXXChecked, tol: tolXXXX },
                tolXS: { show: tolXSChecked, tol: tolXS },
            };

            const tolerances_ISO = {
                tol06: { show: tol06Checked, tol: tol06 },
                tol630: { show: tol630Checked, tol: tol630 },
                tol30120: { show: tol30120Checked, tol: tol30120 },
                tol120400: { show: tol120400Checked, tol: tol120400 },
                tol4001000: { show: tol4001000Checked, tol: tol4001000 },
                tol10002000: { show: tol10002000Checked, tol: tol10002000 },
            };

            setProductsDataState(products_data);
            setTolerancesState(tolerances);
            setTolerancesISOState(tolerances_ISO);
        }
    }, [
        SalesOrderStore.order?.id,
        SalesOrderStore.order?.name,
        unit,
        tolX,
        tolXS,
        tolXX,
        tolXXX,
        tolXXXX,
        tolXChecked,
        tolXSChecked,
        tolXXChecked,
        tolXXXChecked,
        tolXXXXChecked,
        tol06,
        tol06Checked,
        tol630,
        tol630Checked,
        tol30120,
        tol30120Checked,
        tol120400,
        tol120400Checked,
        tol4001000,
        tol4001000Checked,
        tol10002000,
        tol10002000Checked,
        lineItemId,
        resetDWG,
        toleranceType,
    ]);

    async function getOrder(id: number) {
        setInitialLoading(true);
        const response = await SalesOrderStore.getById(id);

        const line_items = response.line_items?.filter((item: { name: string; quote_has_product: any }) => {
            return item.name !== 'CNC Part' && item.quote_has_product;
        });

        const getDefaultLineItemId = line_items ? line_items[0]?.production_2d_file?.id : 0;

        setLineItemId(getDefaultLineItemId);

        setInitialLoading(false);

        vendorQualifications();

        return true;
    }

    async function getAccountProfiles() {
        if (order?.account.id) {
            setIsLoadingProfiles(true);
            await SalesOrderStore.getAccountRedactedProfiles(order.account.id);
            setIsLoadingProfiles(false);
        }
    }

    useEffect(() => {
        if (id) {
            getOrder(id);
        }
    }, [id]);

    useEffect(() => {
        if (SalesOrderStore.order?.id) {
            SalesOrderStore.order.line_items
                .filter(i => i.quote_has_product && i.production_2d_file?.id === lineItemId)
                .map(item => {
                    if (item.production_2d_file?.redaction_status) {
                        setStatus(item.production_2d_file?.redaction_status);
                    }
                });
        }
    }, [SalesOrderStore?.order?.line_items]);

    useEffect(() => {
        getAccountProfiles();
    }, [order?.account?.id]);

    const onChangeProfiles = async (value: any) => {
        setSelectProfileName(value);
    };

    const onClickProfileAndPDF = async (itemID: number, profile?: IRedactedProfile) => {
        RedactionToolStore.cleanUp();

        if (itemID) {
            setLineItemId(itemID);
            setSelectProfileName(profile?.profile_name);
        } else {
            message.error(`Error, production_2d_file does not have Id.`);
        }
    };

    const onChangeProfileName = async () => {
        if (newProfileName) {
            const params = {
                redactions: [],
                profile_name: newProfileName,
                account: SalesOrderStore.order?.account.id,
            };

            const getNewProfile = {
                name: newProfileName,
                url: '',
            };

            try {
                await SalesOrderStore.saveRedactionProfile(lineItemId, params);
                getAccountProfiles();

                message.success('Success, profile created successfully.');
            } catch (err) {
                message.error(`Error, unable to create profile. ${err}`);
            }
        }
    };

    const onClickSaveProfile = async (profileName: string) => {
        setIsSavingProfiles(true);
        try {
            const params = {
                redactions: SalesOrderStore.savedRedactions,
                profile_name: profileName,
                account: SalesOrderStore.order?.account.id,
            };

            await SalesOrderStore.saveRedactionProfile(lineItemId, params);
            getAccountProfiles();

            message.success('Success, profile saved successfully.');
        } catch (err) {
            message.error(`Error, unable to save profile. ${err}`);
        }
        setIsSavingProfiles(false);
    };

    const uploadFile = async (is3d: boolean, fileId: number, plineItemId: number) => {
        try {
            const getLineItemId = SalesOrderStore?.order?.line_items.find(item => {
                return item.production_2d_file?.id === plineItemId;
            });

            if (getLineItemId) {
                const params = is3d
                    ? {
                          id: getLineItemId.id,
                          production_3d_file: fileId,
                          required_partner_ship_date: getLineItemId.required_partner_ship_date,
                      }
                    : {
                          id: getLineItemId.id,
                          production_2d_file: fileId,
                          required_partner_ship_date: getLineItemId.required_partner_ship_date,
                      };
                await SalesOrderStore.updateItem(id, params);

                if (order) {
                    await SalesOrderStore.getById(id);
                }

                setLineItemId(fileId);

                message.success('Success, file saved successfully.');

                setShowAddDrawingFile(false);

                return true;
            } else {
                message.error(`Error, unable to find the line item id.`);
            }
        } catch (err) {
            message.error(`Error, unable to save file. ${err}`);
        }
    };

    const vendorQualifications = () => {
        setLoadindQualifications(true);
        const children: React.ReactNode[] = [];
        SalesOrderStore.getVendorQualificationsFromVendorApp(id)
            .then(qualifications => {
                qualifications.map((qualification: { id: React.Key; label: string }) => {
                    children.push(<Option key={qualification.id}>{qualification.label}</Option>);
                });
                setDataVendorQualifications(children);
            })

            .finally(() => {
                setLoadindQualifications(false);
            });
    };

    const downloadFiles = async () => {
        const lineItems = SalesOrderStore.order?.line_items || [];
        const responses = await Promise.all(
            lineItems.map(async item => {
                const fileUrl = item.production_2d_file?.redacted_file;
                if (!fileUrl) return null;
                const response = await fetch(fileUrl);
                if (!response.ok) return null;
                const buffer = await response.arrayBuffer();
                const filename = SalesOrderStore.order?.name + '_' + item.name + '.pdf';
                return {
                    buffer,
                    filename,
                };
            })
        );
        return responses.filter(response => response !== null);
    };

    const createZipFile = async (files: any[]) => {
        const zip = new JSZip();
        files.forEach(file => {
            let filename = file.filename;
            let extension = '';
            const lastDot = filename.lastIndexOf('.');
            if (lastDot !== -1) {
                extension = filename.slice(lastDot);
                filename = filename.slice(0, lastDot);
            }
            let counter = 1;
            while (zip.file(`${filename}_${counter}${extension}`)) {
                counter++;
            }
            zip.file(`${filename}_${counter}${extension}`, file.buffer);
        });

        const content = await zip.generateAsync({ type: 'blob' });
        return content;
    };

    const downloadAndZipFiles = async () => {
        const files = await downloadFiles();
        const zipFile = await createZipFile(files);
        DownloadFile(zipFile, `${SalesOrderStore.order?.name + '.zip'}`);
    };

    const handleClickOpen2dFile = (url: string | null | undefined) => {
        if (url) window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };

    if (initialLoading) {
        return (
            <div className={'w-full h-full flex items-center justify-content'}>
                <Skeleton />;
            </div>
        );
    }

    return (
        <div className={'redaction-tool'}>
            <div className={'side-left'}>
                <div className={'header-wrapper'}>
                    <Header>
                        <FlexCol>
                            <AlignFields>
                                <FlexCol>
                                    <FlexRow>
                                        <FieldTitle>Order:</FieldTitle>
                                        <FieldText>{SalesOrderStore.order?.name}</FieldText>
                                    </FlexRow>
                                    <FlexRow>
                                        <FieldTitle>Account:</FieldTitle>
                                        <FieldText>{SalesOrderStore.order?.account.name}</FieldText>
                                    </FlexRow>
                                    <FlexRow>
                                        <FieldTitle>2D:</FieldTitle>
                                        <FieldText>
                                            {a2DFile ? (
                                                <FlexRow>
                                                    <a
                                                        key={redactedUrlFile}
                                                        download={a2DFile.name}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() => handleClickOpen2dFile(a2DFile?.url)}
                                                    >
                                                        {a2DFile.name.length > 20
                                                            ? a2DFile.name.substring(0, 15) +
                                                              '...' +
                                                              a2DFile.name.substring(
                                                                  a2DFile.name.length - 5,
                                                                  a2DFile.name.length
                                                              )
                                                            : a2DFile.name}
                                                    </a>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() => handleClickOpen2dFile(a2DFile?.url)}
                                                    >
                                                        <EyeOutlined
                                                            title={'View 2D file'}
                                                            style={{ marginTop: '3px' }}
                                                            className={'link ml-2'}
                                                        />
                                                    </a>
                                                    <a>
                                                        <UploadOutlined
                                                            title={'Add 2D file'}
                                                            style={{ marginTop: '3px' }}
                                                            className={'link ml-2'}
                                                            onClick={() => {
                                                                setShowAddDrawingFile(true);
                                                            }}
                                                        />
                                                    </a>
                                                </FlexRow>
                                            ) : (
                                                '--'
                                            )}
                                            <Modal
                                                visible={showAddDrawingFile}
                                                centered
                                                title="Add or Replace Drawing File"
                                                onCancel={() => setShowAddDrawingFile(false)}
                                                footer={[]}
                                            >
                                                <UploadFile
                                                    fileType={'2D'}
                                                    onUpload={(file: any) => {
                                                        uploadFile(false, file.response.id, lineItemId);
                                                    }}
                                                />
                                            </Modal>
                                        </FieldText>
                                    </FlexRow>
                                    <FlexRow>
                                        <FieldTitle>3D:</FieldTitle>
                                        <FieldText>
                                            {a3DFile ? (
                                                <FlexRow>
                                                    <span>{a3DFile.name}</span>
                                                    <Icon
                                                        title={'Add 2D file'}
                                                        className={'link ml-2'}
                                                        style={{ marginTop: '2px' }}
                                                        type="upload"
                                                        onClick={() => {
                                                            setShowAddStepFile(true);
                                                        }}
                                                    />
                                                </FlexRow>
                                            ) : (
                                                '--'
                                            )}
                                            <Modal
                                                open={showAddStepFile}
                                                centered
                                                title="Add Step File"
                                                onCancel={() => setShowAddStepFile(false)}
                                                footer={[]}
                                            >
                                                <UploadFile
                                                    fileType={'3D'}
                                                    onUpload={(file: any) => {
                                                        uploadFile(true, file.response.id, lineItemId);
                                                    }}
                                                />
                                            </Modal>
                                        </FieldText>
                                    </FlexRow>
                                </FlexCol>
                                <FlexCol>
                                    {a2DFile ? (
                                        <>
                                            <FieldTitle>Files Rename:</FieldTitle>
                                            <InputAntd
                                                size="small"
                                                classNameProps=""
                                                defaultValue={SalesOrderStore.order?.name + '_' + productName + '.pdf'}
                                                ref={redactedFileNameRef}
                                                value={SalesOrderStore.order?.name + '_' + productName + '.pdf'}
                                            />

                                            <FlexCol>
                                                <FieldTitle>Qualifications:</FieldTitle>
                                                <Select
                                                    showArrow
                                                    style={{ width: '100%' }}
                                                    tokenSeparators={[',']}
                                                    mode="multiple"
                                                >
                                                    {dataVendorQualifications}
                                                </Select>
                                            </FlexCol>
                                        </>
                                    ) : (
                                        '--'
                                    )}
                                </FlexCol>

                                <FlexCol>
                                    <CheckboxDiv>
                                        <Tolerance
                                            toleranceType={toleranceType}
                                            setToleranceType={setToleranceType}
                                            tolProps={{
                                                tolX: {
                                                    checked: tolXChecked,
                                                    value: tolX,
                                                    setChecked: setTolXChecked,
                                                    setValue: setTolX,
                                                },
                                                tolXX: {
                                                    checked: tolXXChecked,
                                                    value: tolXX,
                                                    setChecked: setTolXXChecked,
                                                    setValue: setTolXX,
                                                },
                                                tolXXX: {
                                                    checked: tolXXXChecked,
                                                    value: tolXXX,
                                                    setChecked: setTolXXXChecked,
                                                    setValue: setTolXXX,
                                                },
                                                tolXXXX: {
                                                    checked: tolXXXXChecked,
                                                    value: tolXXXX,
                                                    setChecked: setTolXXXXChecked,
                                                    setValue: setTolXXXX,
                                                },
                                                tolXS: {
                                                    checked: tolXSChecked,
                                                    value: tolXS,
                                                    setChecked: setTolXSChecked,
                                                    setValue: setTolXS,
                                                },
                                                tol06: {
                                                    checked: tol06Checked,
                                                    value: tol06,
                                                    setChecked: setTol06Checked,
                                                    setValue: setTol06,
                                                },
                                                tol630: {
                                                    checked: tol630Checked,
                                                    value: tol630,
                                                    setChecked: setTol630Checked,
                                                    setValue: setTol630,
                                                },
                                                tol30120: {
                                                    checked: tol30120Checked,
                                                    value: tol30120,
                                                    setChecked: setTol30120Checked,
                                                    setValue: setTol30120,
                                                },
                                                tol120400: {
                                                    checked: tol120400Checked,
                                                    value: tol120400,
                                                    setChecked: setTol120400Checked,
                                                    setValue: setTol120400,
                                                },
                                                tol4001000: {
                                                    checked: tol4001000Checked,
                                                    value: tol4001000,
                                                    setChecked: setTol4001000Checked,
                                                    setValue: setTol4001000,
                                                },
                                                tol10002000: {
                                                    checked: tol10002000Checked,
                                                    value: tol10002000,
                                                    setChecked: setTol10002000Checked,
                                                    setValue: setTol10002000,
                                                },
                                            }}
                                        />
                                    </CheckboxDiv>
                                </FlexCol>
                            </AlignFields>

                            <AlignFields>
                                <FieldTitle> Profile:</FieldTitle>

                                <SelectAntd
                                    size="small"
                                    width={250}
                                    onChange={value => onChangeProfiles(value)}
                                    value={selectProfileName}
                                >
                                    {profiles.map((item, index) => {
                                        return (
                                            <>
                                                <Option key={index} value={item.profile_name}>
                                                    <Popover
                                                        trigger="hover"
                                                        placement="right"
                                                        content={
                                                            <>
                                                                <RedactionViewer
                                                                    pdfUrl={a2DFile?.url ? a2DFile?.url : ''}
                                                                    redaction={item.settings}
                                                                    pageNumber={0}
                                                                    scale={0.3}
                                                                />
                                                            </>
                                                        }
                                                    >
                                                        <div style={{ width: '50%', display: 'inline-block' }}>
                                                            {item.profile_name}
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                                width: '50%',
                                                                display: 'inline-block',
                                                            }}
                                                        >
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title={
                                                                    'Do you want to delete this profile permanently?'
                                                                }
                                                                onConfirm={evt => {
                                                                    evt?.stopPropagation();
                                                                    if (order?.account?.id) {
                                                                        SalesOrderStore.deleteAccountRedactedProfile(
                                                                            order?.account.id,
                                                                            item.id
                                                                        );
                                                                    }
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <DeleteOutlined
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={ev => {
                                                                        ev.stopPropagation();
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </div>
                                                    </Popover>
                                                </Option>
                                            </>
                                        );
                                    })}
                                </SelectAntd>
                                <ButtonAntd
                                    disabled={redaction.length > 0}
                                    textDecoration=""
                                    color=""
                                    border={1}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        setNewProfileName('');
                                        setShowModal(!showModal);
                                    }}
                                >
                                    Create New Profile
                                </ButtonAntd>
                                <Modal
                                    title="Create new Profile"
                                    visible={showModal}
                                    onOk={() => {
                                        onChangeProfileName();
                                        setShowModal(false);
                                    }}
                                    onCancel={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    <p className="font-bold">Profile Name:</p>
                                    <InputAntd classNameProps="" onChange={e => setNewProfileName(e.target.value)} />
                                </Modal>
                                <Spin spinning={isSavingProfiles}>
                                    <ButtonAntd
                                        textDecoration=""
                                        color=""
                                        border={1}
                                        type="primary"
                                        size="small"
                                        disabled={!selectProfileName}
                                        onClick={() => {
                                            if (selectProfileName) {
                                                onClickSaveProfile(selectProfileName);
                                            }
                                        }}
                                    >
                                        Save Profile
                                    </ButtonAntd>
                                </Spin>
                            </AlignFields>
                        </FlexCol>
                        <AlignRadioGroup>
                            <Radio.Group
                                onChange={e => {
                                    setUnit(e.target.value);
                                }}
                                value={unit}
                            >
                                <Radio value={'in'}>IN</Radio>
                                <Radio value={'mm'}>MM</Radio>
                            </Radio.Group>
                        </AlignRadioGroup>
                        <FlexCol>
                            <div className="px-10 pb-5">
                                {SalesOrderStore.order?.account?.quoting_notes && (
                                    <>
                                        <FieldTitle>Quoting Notes:</FieldTitle>
                                        <div style={{ width: '22vw', height: '60px', overflow: 'scroll' }}>
                                            {SalesOrderStore.order?.account?.quoting_notes}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="px-10 pb-2">
                                {SalesOrderStore.order?.account?.internal_notes && (
                                    <>
                                        <FieldTitle>Internal Notes:</FieldTitle>
                                        <div
                                            style={{ width: '22vw', height: '60px', overflow: 'scroll', marginTop: 0 }}
                                        >
                                            {SalesOrderStore.order?.account?.internal_notes}
                                        </div>
                                    </>
                                )}
                            </div>
                        </FlexCol>
                        <FlexCol>
                            <Popover
                                content={
                                    <FontTools
                                        displayColorPicker={displayColorPicker}
                                        ftFontSize={ftFontSize}
                                        fontColor={fontColor}
                                        fontBold={fontBold}
                                        fontItalic={fontItalic}
                                        setDisplayColorPicker={setDisplayColorPicker}
                                        setFtFontSize={setFtFontSize}
                                        setFontColor={setFontColor}
                                        setFontBold={setFontBold}
                                        setFontItalic={setFontItalic}
                                    />
                                }
                                trigger="hover"
                                placement="left"
                            >
                                <InputTextAntd
                                    ref={freeTextAreaRef}
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                    fontSize={ftFontSize}
                                    fontColor={fontColor}
                                    fontBold={fontBold}
                                    fontItalic={fontItalic}
                                    value={freeTextArea}
                                    placeholder={'Free Form TextBox...'}
                                    onChange={e => {
                                        setFreeTextArea(e.target.value);
                                    }}
                                />
                            </Popover>
                            <ButtonProfileAntd
                                danger={insertDWG}
                                onClick={() => {
                                    setInsertFTA(!insertFTA);
                                }}
                            >
                                <TagOutlined />
                                {'Insert Text'}
                            </ButtonProfileAntd>
                        </FlexCol>
                    </Header>
                </div>
                <div className={'body-wrapper'}>
                    <div className={'pdf-area-wrapper'}>
                        <EditPdf
                            textAreaRef={textAreaRef}
                            textFreeAreaRef={freeTextAreaRef}
                            insertDWG={insertDWG}
                            insertFTA={insertFTA}
                            line_item_id={lineItemId}
                            ProfileName={selectProfileName}
                            resetProfileName={value => setSelectProfileName(value)}
                            removeDWG={() => setInsertDWG(false)}
                            redactedFileNameRef={redactedFileNameRef}
                            FTAProperties={{
                                fontBold: fontBold,
                                fontColor: fontColor,
                                fontItalic: fontItalic,
                                fontSize: ftFontSize,
                            }}
                            redactAsFlatFile={redact_as_flat_file}
                        />
                    </div>
                </div>
            </div>
            <div className={'side-right'}>
                <DrawerDiv className={'part-files-wrapper'}>
                    <CardAntd>
                        <ButtonResetAntd
                            type="link"
                            onClick={() => {
                                setresetDWG(!resetDWG);
                            }}
                        >
                            <SyncOutlined />
                        </ButtonResetAntd>
                        <ProductTemplate
                            order={SalesOrderStore.order?.name ?? ''}
                            product_data={productsDataState[0]}
                            tolerances_data={tolerancesState}
                            tolerances_data_ISO={tolerancesISOState}
                            unit={unit}
                            toleranceType={toleranceType}
                            textAreaRef={textAreaRef}
                        />
                        <ButtonProfileAntd
                            // type={!insertDWG ? 'primary' : 'default'}
                            danger={insertDWG}
                            onClick={() => {
                                setInsertDWG(!insertDWG);
                            }}
                        >
                            {!insertDWG ? 'Insert DWG TXT Box' : 'Remove DWG TXT Box'}
                        </ButtonProfileAntd>
                    </CardAntd>

                    <div className="flex flex-col " style={{ height: '55vh', overflow: 'scroll' }}>
                        {SalesOrderStore.order?.line_items
                            ?.filter(item => {
                                return item.name !== 'CNC Part' && item.quote_has_product;
                            })
                            .map((item, index) => {
                                return (
                                    <div
                                        key={`${item.name} - ${item.id}`}
                                        style={{
                                            boxShadow: '0 4px 22px 0 rgba(71, 69, 69, 0.178)',
                                            border: '1px solid rgba(255, 255, 255, 0.18)',
                                        }}
                                    >
                                        <CardAntd
                                            bordered={false}
                                            classNameProps="flex flex-col m-auto"
                                            styleProps={{ borderRadius: '5px' }}
                                        >
                                            <div className={'flex flex-row'}>
                                                <div>
                                                    <FlexRow>
                                                        <FieldTitle>{index + 1}. Product:</FieldTitle>
                                                        <FieldText>{item?.name}</FieldText>
                                                    </FlexRow>
                                                    <FlexRow>
                                                        <FieldTitle>File Name:</FieldTitle>
                                                        <FieldText>
                                                            {item?.production_2d_file
                                                                ? item?.production_2d_file?.filename
                                                                : '--'}
                                                        </FieldText>
                                                    </FlexRow>
                                                </div>
                                                <div className="flex flex-col md:flex-row md:flex-wrap justify-end">
                                                    <div className="w-full md:w-auto mb-2 md:mb-0">
                                                        <Button
                                                            onClick={() => {
                                                                setOrderItemId(item.id);
                                                                setOpenTravelerModal(!openTravelerModal);
                                                            }}
                                                        >
                                                            Download Traveler Information
                                                        </Button>
                                                    </div>

                                                    <div>
                                                        {item.production_2d_file?.id !== undefined && (
                                                            <>
                                                                <Button
                                                                    size="small"
                                                                    style={{ marginBottom: '10px', width: '100%' }}
                                                                    icon={<CloudUploadOutlined />}
                                                                    onClick={() => {
                                                                        setCurrentUploadId(item.production_2d_file!.id);
                                                                        setShowAddRedactedFile(true);
                                                                    }}
                                                                >
                                                                    Upload Redacted File
                                                                </Button>
                                                                <Modal
                                                                    visible={showAddRedactedFile}
                                                                    centered
                                                                    title="Add or Replace Redacted File"
                                                                    onCancel={() => setShowAddRedactedFile(false)}
                                                                    footer={[]}
                                                                >
                                                                    <UploadFile
                                                                        fileType={'2D'}
                                                                        onUpload={(file: any) => {
                                                                            uploadFile(
                                                                                false,
                                                                                file.response.id,
                                                                                currentUploadId!
                                                                            );
                                                                            SalesOrderStore.uploadRedactedFile(
                                                                                file.response.id
                                                                            );
                                                                            setShowAddRedactedFile(false);
                                                                        }}
                                                                    />
                                                                </Modal>
                                                            </>
                                                        )}
                                                    </div>

                                                    {item.partsbadger_production && (
                                                        <div className="w-full md:w-auto md:mx-2">
                                                            <Tag className="mx-2" color={'blue'}>
                                                                {item.partsbadger_production_method}
                                                            </Tag>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <PartCard
                                                isSelected={item.production_2d_file?.id == lineItemId}
                                                isRedacted={item.production_2d_file?.redaction_status ?? 'unmodified'}
                                                thumbnail={
                                                    item.production_2d_file?.thumbnail_redacted ??
                                                    item.production_2d_file?.thumbnail ??
                                                    ''
                                                }
                                                onClick={() => {
                                                    if (RedactionToolStore.redaction.length > 0) {
                                                        const confirmationMessage =
                                                            'It looks like you have not saved your changes yet. ' +
                                                            'If you leave before saving, your changes will be lost.';

                                                        Modal.confirm({
                                                            title: 'Warning!',
                                                            okText: 'Continue',
                                                            content: confirmationMessage,
                                                            onOk: () => {
                                                                if (item.production_2d_file?.id) {
                                                                    onClickProfileAndPDF(item.production_2d_file.id);
                                                                }
                                                            },
                                                        });
                                                    } else {
                                                        if (status === 'partially_redacted') {
                                                            Modal.confirm({
                                                                title: 'Warning!',
                                                                okText: 'Continue',
                                                                content:
                                                                    'There are still pages that have not yet been redacted' +
                                                                    'Do you want to continue anyway?',
                                                                onOk: () => {
                                                                    if (item.production_2d_file?.id) {
                                                                        onClickProfileAndPDF(
                                                                            item.production_2d_file.id
                                                                        );
                                                                    }
                                                                },
                                                            });
                                                        } else {
                                                            if (item.production_2d_file?.id) {
                                                                onClickProfileAndPDF(item.production_2d_file.id);
                                                            }
                                                        }
                                                    }
                                                }}
                                                onDownloadRedactedFile={() => {
                                                    const confirmationMessage =
                                                        'There are still pages that have not yet been redacted.' +
                                                        'Do you want to continue downloading?';
                                                    if (item.production_2d_file?.redacted_file) {
                                                        axios
                                                            .get(item.production_2d_file?.redacted_file, {
                                                                responseType: 'blob',
                                                            })
                                                            .then(response => {
                                                                DownloadFile(
                                                                    response.data,
                                                                    `${
                                                                        SalesOrderStore.order?.name +
                                                                        '_' +
                                                                        item.name +
                                                                        '.pdf'
                                                                    }`
                                                                );
                                                            });
                                                    }
                                                }}
                                            />
                                        </CardAntd>
                                        <Divider />
                                    </div>
                                );
                            })}
                    </div>
                    <div className={'bottom-actions-wrapper mt-0'}>
                        <Spin spinning={isSendToVendors}>
                            <Button
                                className="mx-2"
                                onClick={async () => {
                                    const hasAllRedactedFiles =
                                        SalesOrderStore.order?.line_items?.filter(item => {
                                            const isFullyRedacted =
                                                (item.production_2d_file?.redaction_status === 'redacted' ||
                                                    item.production_2d_file?.redaction_status ===
                                                        'externally_redacted') &&
                                                item.production_2d_file?.redacted_file;
                                            return !isFullyRedacted && item.name !== 'CNC Part';
                                        })?.length === 0;
                                    if (hasAllRedactedFiles) {
                                        setIsSendToVendors(true);
                                        SalesOrderStore.sendRedactedFilesToVendorAPP(id)
                                            .then(() => {
                                                notification.success({
                                                    message: 'Done!',
                                                    description: 'All files were submitted to vendor app',
                                                    placement: 'bottomRight',
                                                    duration: 10,
                                                });
                                            })
                                            .catch(e => {
                                                notification.error({
                                                    message: 'We could not submit the files',
                                                    description: <ErrorRender error={e} />,
                                                    placement: 'bottomRight',
                                                    duration: 5,
                                                });
                                            })
                                            .finally(() => {
                                                setIsSendToVendors(false);
                                            });
                                    } else {
                                        message.error('Please complete all redacted files');
                                    }
                                }}
                                type="primary"
                            >
                                Send to Vendors
                            </Button>
                        </Spin>
                        <Spin spinning={isDownloadAllFiles}>
                            <Button
                                className="mx-2"
                                onClick={async () => {
                                    const hasAllRedactedFiles =
                                        SalesOrderStore.order?.line_items?.filter(item => {
                                            const isFullyRedacted =
                                                (item.production_2d_file?.redaction_status === 'redacted' ||
                                                    item.production_2d_file?.redaction_status ===
                                                        'externally_redacted') &&
                                                item.production_2d_file?.redacted_file;
                                            return !isFullyRedacted && item.name !== 'CNC Part';
                                        })?.length === 0;
                                    if (hasAllRedactedFiles) {
                                        setIsDownloadAllFiles(true);
                                        downloadAndZipFiles()
                                            .then(() => {
                                                notification.success({
                                                    message: 'Done!',
                                                    description: 'All files were downloaded successfully',
                                                    placement: 'bottomRight',
                                                    duration: 10,
                                                });
                                                setIsDownloadAllFiles(false);
                                            })
                                            .catch(e => {
                                                notification.error({
                                                    message: 'We could not download the files',
                                                    description: <ErrorRender error={e} />,
                                                    placement: 'bottomRight',
                                                    duration: 5,
                                                });
                                            })
                                            .finally(() => {
                                                setIsSendToVendors(false);
                                            });
                                    } else {
                                        message.error('Please complete all redacted files');
                                    }
                                }}
                                type="ghost"
                            >
                                Download All Files
                            </Button>
                        </Spin>

                        <Button className="mx-2" onClick={() => setSendSalesOrder(true)}>
                            Send to Rep
                        </Button>
                    </div>
                </DrawerDiv>
            </div>
            {sendSalesOrder && (
                <Modal
                    visible
                    centered
                    onCancel={() => setSendSalesOrder(false)}
                    footer={[
                        <Button
                            key="1"
                            className="mx-2"
                            loading={sending}
                            onClick={() => {
                                setSending(true);
                                SalesOrderStore.order &&
                                    SalesOrderStore.sendToSalesRepresentative(SalesOrderStore?.order.id, {
                                        notes: salesOrderNotes,
                                        with_file: true,
                                    }).then(() => {
                                        notification.success({
                                            message: 'Sales order was sent to the sales representative',
                                        });
                                        setSendSalesOrder(false);
                                        setSending(false);
                                    });
                            }}
                        >
                            Submit
                        </Button>,
                    ]}
                >
                    <RichTextEditor
                        initialValue={`<p>
                        Hi ${SalesOrderStore?.order?.owner?.fullname}, attached is your SalesOrder.
                        <br/><br/>
                        - ${SalesOrderStore?.order?.design_engineer?.fullname}
                        </p>`}
                        onChange={(html, text) => setSalesOrderNotes(html)}
                    />
                </Modal>
            )}

            {openTravelerModal && (
                <TravelerModal
                    visible={openTravelerModal}
                    onDownload={async (typeable_fields: {
                        revision: string;
                        quotedNote: string;
                        orderNote: string;
                        otherInformation: string;
                    }): Promise<void> => {
                        setIsLoading(true);
                        await SalesOrderStore.downloadTraveler(orderItemId, typeable_fields)
                            .then(() => {
                                notification.success({
                                    message: 'Traveler was downloaded successfully',
                                    placement: 'topRight',
                                    duration: 10,
                                });
                            })
                            .finally(() => {
                                setIsLoading(false);
                                setOpenTravelerModal(false);
                            });
                    }}
                    isLoading={isLoading}
                    onCancel={() => {
                        setOrderItemId(0);
                        setIsLoading(false);
                        setOpenTravelerModal(false);
                    }}
                    onSave={async (typeable_fields: {
                        revision: string;
                        quotedNote: string;
                        orderNote: string;
                        otherInformation: string;
                    }): Promise<void> => {
                        setIsLoading(true);
                        await SalesOrderStore.saveTraveler(orderItemId, typeable_fields)
                            .then(() => {
                                notification.success({
                                    message: 'Traveler was Saved.',
                                    placement: 'topRight',
                                    duration: 10,
                                });
                            })
                            .finally(() => {
                                setIsLoading(false);
                                setOpenTravelerModal(false);
                            });
                    }}
                />
            )}

            {SalesOrderStore.order?.id && (
                <div style={{ display: 'block' }}>
                    <SocketConnection />
                </div>
            )}
        </div>
    );
};

export default observer(RedactionTool);
