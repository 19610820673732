import React, { useState, useRef } from 'react';
import { Modal, Divider, Empty } from 'antd';
import Draggable from 'react-draggable';
import TicketView from './TicketView';
export default function TicketModal(_a) {
    var handleCloseTicketModal = _a.handleCloseTicketModal, product = _a.product;
    var _b = useState(false), disabled = _b[0], setDisabled = _b[1];
    var _c = useState({ left: 0, top: 0, bottom: 0, right: 0 }), bounds = _c[0], setBounds = _c[1];
    var draggleRef = useRef(null);
    var onStart = function (_event, uiData) {
        var _a;
        var _b = window.document.documentElement, clientWidth = _b.clientWidth, clientHeight = _b.clientHeight;
        var targetRect = (_a = draggleRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    return (React.createElement(Modal, { title: React.createElement("div", { style: {
                width: '100%',
                cursor: 'move',
            }, onMouseOver: function () {
                if (disabled) {
                    setDisabled(false);
                }
            }, onMouseOut: function () {
                setDisabled(true);
            }, onFocus: function () { }, onBlur: function () { } },
            "Product Tickets - ", product === null || product === void 0 ? void 0 :
            product.product_name), visible: true, footer: [], bodyStyle: { padding: '8px', borderColor: 'light-gray', borderWidth: '4px' }, onCancel: function () { return handleCloseTicketModal(); }, modalRender: function (modal) { return (React.createElement(Draggable, { disabled: disabled, bounds: bounds, onStart: function (event, uiData) { return onStart(event, uiData); } },
            React.createElement("div", { ref: draggleRef }, modal))); }, width: '40%' }, product === null || product === void 0 ? void 0 :
        product.ticket_products.map(function (item) {
            return (React.createElement("div", { key: item.id },
                React.createElement(Divider, null, "Chat Ticket"),
                React.createElement(TicketView, { arrayMessages: item.messages })));
        }),
        (product === null || product === void 0 ? void 0 : product.ticket_products.length) === 0 && React.createElement(Empty, { description: "No messages" })));
}
