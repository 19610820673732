import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';

import { DateRangePicker } from '@partsbadger/library';
import { Row, Col, Button, Divider, Card, Table } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import moment from 'moment';
import { get } from '../../shared/api';
import dayjs from 'dayjs';

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const FastLaneProductsReport = (props: FormComponentProps) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { form } = props;

    const { getFieldDecorator } = form;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                getData(params);
            }
        });
    };

    const getData = (params: { created_time_min: string; created_time_max: string }) => {
        setLoading(true);
        get(`staff/quoted-products/fast-lane-products/`, params)
            .then((response: any) => {
                setData(response.data);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: 'Quote',
            dataIndex: 'quote_name',
            key: 'quote_name',
        },
        {
            title: 'Quote Date',
            dataIndex: 'quote_date',
            key: 'quote_date',
            render: (_: string, record: any) => {
                return <div>{dayjs(record.quote_date).format('LLL')}</div>;
            },
        },
        {
            title: 'Account name',
            dataIndex: 'account_name',
            key: 'account_name',
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Quote Number',
            dataIndex: 'quote_number',
            key: 'quote_number',
        },
        {
            title: 'Deal Name',
            render: (_: string, record: any) => {
                return (
                    <a className="underline text-red-600" href={record.deal_zoho_link} target="_blank" rel="noreferrer">
                        {record.deal_name}
                    </a>
                );
            },
        },
        {
            title: 'Deal Stage',
            dataIndex: 'deal_stage',
            key: 'deal_stage',
        },
        {
            title: 'Quote Tool Link',
            render: (_: string, record: any) => {
                return (
                    <a
                        className="underline text-red-600"
                        href={record.quote_tool_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Link
                    </a>
                );
            },
        },
    ];

    return (
        <>
            <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Divider>
                <div className={'title'}> Fast Lane Products </div>
            </Divider>

            <Card>
                <div className={'w-full'}>
                    <Table key={'fastlane'} columns={columns} loading={loading} dataSource={data} pagination={false} />
                </div>
            </Card>
        </>
    );
};

export const FastLaneRerportForm = Form.create()(FastLaneProductsReport);
