import React from 'react';
import { Switch } from 'antd';
var QuoteToolVersionSwitcher = function (props) {
    return (React.createElement(Switch, { style: {
            width: 40,
        }, checkedChildren: 2, unCheckedChildren: 1, defaultChecked: props.version === '2', onChange: function (v) {
            props.handleChange(v ? 2 : 1);
        } }));
};
export default QuoteToolVersionSwitcher;
