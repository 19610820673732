import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { IStorageLocation } from '@partsbadger/types';
import { Select } from 'antd/es';

interface IProps {
    type: 'create' | 'update';
    storage?: IStorageLocation;
    handleCreate?: (payload: IStorageLocation) => void;
    handleUpdate?: (payload: IStorageLocation) => void;
}

export const StorageLocationForm = (props: IProps) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [props.storage]);

    const onFinish = (values: IStorageLocation) => {
        const payload: IStorageLocation = {
            name: values.name,
            initial: values.initial,
            numbers: values.numbers.map(value => Number(value)),
        };
        if (props.type === 'create' && props.handleCreate) {
            props.handleCreate(payload);
            form.resetFields();
        } else if (props.type === 'update' && props.handleUpdate) {
            const refactor_payload = {
                id: props.storage?.id,
                ...payload,
            };
            props.handleUpdate(refactor_payload);
        }
    };

    return (
        <Form
            form={form}
            initialValues={{
                name: props.storage?.name,
                initial: props.storage?.initial,
                numbers: props.storage?.numbers,
            }}
            name="add-update-storages"
            onFinish={onFinish}
            layout="vertical"
        >
            <div className="grid grid-cols-2 gap-4">
                <Form.Item label={'Location'} name="name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item label={'Location initial'} name="initial" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item label={'Defining number(s)'} name="numbers" rules={[{ required: true }]}>
                    <Select
                        mode="tags"
                        placeholder={'1,2,3,'}
                        tokenSeparators={[',']}
                        onInputKeyDown={evt => {
                            const charCode = evt.keyCode;
                            if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57) &&
                                (charCode < 96 || charCode > 105) &&
                                charCode !== 188
                            ) {
                                evt.preventDefault();
                            }
                        }}
                        notFoundContent={'Type a number'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </div>

            <Form.Item>
                <div className={'flex flex-row space-x-4'}>
                    <Button className="second" type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};
