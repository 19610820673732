import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Price } from '@partsbadger/library';
var QuoteLineQuantityItem = function (props) {
    var item = props.item;
    return (React.createElement("div", { className: 'flex flex-row' },
        React.createElement("div", { className: 'flex flex-row w-full' },
            React.createElement("div", { className: 'w-1/4 mr-4' },
                React.createElement("div", { className: 'flex justify-center' }, item.quantity)),
            React.createElement("div", { className: 'w-1/4 mr-4' },
                React.createElement("div", { className: 'flex justify-center' },
                    item.lead_time,
                    " days")),
            React.createElement("div", { className: 'w-1/4 mr-4' },
                React.createElement("div", { className: 'flex justify-center' }, item.safe_auto_quote ? React.createElement(Price, { value: item.unit_price }) : 'Need Review')),
            React.createElement("div", { className: 'w-1/4 mr-4' },
                React.createElement("div", { className: 'flex justify-center' }, item.safe_auto_quote ? React.createElement(Price, { value: item.total_price }) : 'Need review')))));
};
export default QuoteLineQuantityItem;
