import React from 'react';
import { Divider } from 'antd';
import { useParams } from 'react-router-dom';
import { CreditMemoTool } from '@partsbadger/sales_order_tool';

const CreditMemoPage = () => {
    let sales_order_id = '';
    ({ sales_order_id } = useParams());

    return (
        <div style={{ margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Credit Memos</div>
            </Divider>

            <CreditMemoTool sales_order_id={Number(sales_order_id)} />
        </div>
    );
};

export default CreditMemoPage;
