import React, { useState } from 'react';
import { get } from '../../shared';
import { SalesOrderProps } from '@partsbadger/sales_order_tool/lib/types/types';
import { Button, Card, DatePicker, Table } from 'antd';
import { Moment } from 'moment';
import { DateRangePicker } from '@partsbadger/library';

const CancelledIntakeReport = () => {
    const [loading, setLoading] = useState(false);

    const [salesOrdersCancelledIntake, setSalesOrdersCancelledIntake] = useState<Array<SalesOrderProps>>([]);

    const [selectedDate, setSelectedDate] = useState<Moment | null>();
    const [selectedDateTo, setSelectedDateTo] = useState<Moment | null>();

    const getSalesOrderCancelledIntake = async () => {
        setLoading(true);
        if (selectedDate && selectedDateTo) {
            const response = await get(`staff/report-cancelled-intakes/`, {
                required_partner_ship_date: selectedDate?.format('YYYY-MM-DD'),
                required_partner_ship_date_to: selectedDateTo?.format('YYYY-MM-DD'),
            });
            setSalesOrdersCancelledIntake(response.data.results);
        }
        setLoading(false);
    };

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Partner Ship Date',
            dataIndex: 'partner_ship_date',
            key: 'partner_ship_date',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            key: 'grand_total',
        },
    ];

    return (
        <>
            <div className={'flex'}>
                <div className="mr-5">
                    <DateRangePicker
                        onChange={value => {
                            setSelectedDate(value[0]);
                            setSelectedDateTo(value[1]);
                        }}
                    />
                </div>
                <div>
                    <Button
                        type="primary"
                        onClick={() => {
                            getSalesOrderCancelledIntake();
                        }}
                    >
                        Search
                    </Button>
                </div>
            </div>
            <Card title={'Cancelled Parts Intakes'}>
                <div className={'w-full'}>
                    <Table
                        key={'today'}
                        columns={columns}
                        loading={loading}
                        dataSource={salesOrdersCancelledIntake}
                        pagination={false}
                    />
                </div>
            </Card>
        </>
    );
};

export default CancelledIntakeReport;
