import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, remove, post } from '../shared';
import { AccountDetail } from './types';

class Account {
    isLoading = true;
    account?: AccountDetail | null = null;
    account_documents_related = [];

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            account: observable,
            account_documents_related: observable,

            // Actions
            getAccount: action,
            remove_account_document: action,
        });
    }

    async getAccount(account_id: number) {
        this.isLoading = true;
        return await get(`staff/accounts/${account_id}/`)
            .then((response: any) => {
                this.account = response.data;
                this.account_documents_related = response.data.account_documents_related;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    remove_account_document = async (index: number, document_id: number) => {
        this.account_documents_related = this.account_documents_related.filter((d, i: number) => i !== index);
        await remove(`staff/accounts/${this.account?.id}/remove-document/${document_id}`);
    };

    async existingAccount(account_name: string | null, email: string): Promise<void> {
        return await post(`staff/accounts/existing-account/`, { account_name, email });
    }

    async convertLeadToContact(payload = {}) {
        return await post(`staff/leads/convert-to-contact/`, payload);
    }
}

export const AccountStore = new Account();
