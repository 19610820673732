import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber } from 'antd';
import { QuoteStore } from '../../Stores';
var WholeQuoteAdjustmentForm = function (_a) {
    var _b;
    var quote = _a.quote;
    var _c = useState(100), value = _c[0], setValue = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var handleUpdate = function () {
        var _a;
        setLoading(true);
        //@ts-ignore
        QuoteStore.updateQuote((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.id, {
            whole_quote_adjust: value,
        })
            .then(function () {
            QuoteStore.getLineItems(quote === null || quote === void 0 ? void 0 : quote.id);
        })
            .finally(function () { return setLoading(false); });
    };
    useEffect(function () {
        if (parseFloat(quote.whole_quote_adjust) > 0) {
            setValue(parseFloat(quote.whole_quote_adjust));
        }
    }, [(_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.whole_quote_adjust]);
    return (React.createElement(Form, null,
        React.createElement(Form.Item, { label: 'Whole Quote Adjustment' },
            React.createElement(InputNumber, { placeholder: '100', value: value, onChange: function (v) { return v && setValue(v); }, onPressEnter: handleUpdate, disabled: !quote.editable }),
            React.createElement(Button, { loading: loading, onClick: handleUpdate, disabled: !quote.editable }, "Update"))));
};
export default WholeQuoteAdjustmentForm;
