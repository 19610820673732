import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Chat } from '@partsbadger/library';
var SalesOrderNotifications = observer(function (props) {
    return (React.createElement("div", null,
        React.createElement(Chat, { form: props.form, height: props.height, messages: SalesOrderStore === null || SalesOrderStore === void 0 ? void 0 : SalesOrderStore.sales_order_notifications, onSendMessage: function (values) {
                SalesOrderStore.sendNotification(props.sales_order_id, values);
            } })));
});
export default Form.create()(SalesOrderNotifications);
