import React, { Component } from 'react';
import { observer } from 'mobx-react';

import 'trix/dist/trix.css';
//@ts-ignore
import Trix from 'trix';
import { TrixEditor, MergeTag } from 'react-trix';
import './RichTextEditor.module.css';

interface RichTextProps {
    autoFocus?: boolean;
    placeholder?: string;
    uploadURL?: string;
    uploadData?: any;
    onChange?: (html: string, text: string) => void;
    mergeTags?: {
        trigger: string;
        tags: MergeTag[];
    }[];
    html?: string;
}

export class _RichTextEditor extends Component<RichTextProps, any> {
    constructor(props: RichTextProps) {
        super(props);

        this.state = {
            editor: null,
        };

        this.handleEditorReady = this.handleEditorReady.bind(this);
        this.handleChange = this.handleChange.bind(this);
        console.log(Trix.config);
    }

    handleEditorReady(editor: any) {
        this.setState({ editor: editor });
    }

    handleChange(html: string, text: string) {
        if (this.props.onChange) {
            this.props.onChange(html, text);
        }
    }

    render() {
        return (
            <TrixEditor
                autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                placeholder={this.props.placeholder && this.props.placeholder}
                uploadURL={this.props.uploadURL && this.props.uploadURL}
                mergeTags={this.props.mergeTags ? this.props.mergeTags : []}
                onChange={this.handleChange}
                onEditorReady={this.handleEditorReady}
                value={this.props.html && this.props.html}
            />
        );
    }
}

export const RichTextEditor = observer(_RichTextEditor);
/* uploadData={{ secret_key: `Token ${UserStore.restAuthToken}`}} */
