import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import SalesOrderEditForm from './Forms/SalesOrderEditForm/SalesOrderEditForm';
import SalesOrderCreateForm from './Forms/SalesOrderCreateForm/SalesOrderCreateForm';
import SalesOrderStore from './Stores/SalesOrderStore';
var SalesOrderForm = function (props) {
    useEffect(function () {
        SalesOrderStore.getAdditionalRequirements();
        SalesOrderStore.getUsers({
            is_staff: true,
        });
        SalesOrderStore.order_items = [];
        SalesOrderStore.quoted_line_items = [];
        SalesOrderStore.addresses = [];
        SalesOrderStore.contact = undefined;
    }, []);
    return (React.createElement(React.Fragment, null, props.sales_order_id ? (React.createElement(SalesOrderEditForm, { current_user: props.current_user, sales_order_id: props.sales_order_id, onSubmitted: function () {
            SalesOrderStore.setOrder();
            props.onSubmitted();
        } })) : (React.createElement(SalesOrderCreateForm, { current_user: props.current_user, onSubmitted: function () {
            SalesOrderStore.setOrder();
            props.onSubmitted();
        } }))));
};
export default observer(SalesOrderForm);
