import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, notification, Row, Select } from 'antd';
import { Accounts, Contacts, ErrorRender, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { SearchOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Search = Input.Search;
const { Option } = Select;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

function toBolOrNull(value: string | undefined | null) {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }

    return null;
}

const RfqFilterFormComponent = (props: any) => {
    const { form } = props;

    const { getFieldDecorator } = form;

    const query = useQueryParameters();
    const history = useHistory();
    let array_tags;

    if (query) {
        const tags = query.get('tags');
        if (tags) {
            array_tags = tags.split(',');
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                const params: any = {};
                try {
                    if (values.itar != undefined) {
                        params['itar'] = values.itar;
                    }

                    if (values.search) {
                        params['search'] = values.search;
                    }

                    if (values.account?.key) {
                        params['account'] = values.account.key;
                    }

                    if (values.contact?.key) {
                        params['contact'] = values.contact.key;
                    }

                    if (values.owner?.key) {
                        params['owner'] = values.owner.key;
                    }

                    if (values.design_engineer?.key) {
                        params['quote__de'] = values.design_engineer.key;
                    }

                    if (values.quote__stage) {
                        params['quote__stage'] = values.quote__stage;
                    }

                    if (values.unassigned !== null) {
                        params['unassigned'] = values.unassigned;
                    }

                    if (values.unresolved !== null) {
                        params['unresolved'] = values.unresolved;
                    }

                    if (values.resolved !== null) {
                        params['resolved'] = values.resolved;
                    }

                    if (values.requotes) {
                        params['requotes'] = values.requotes;
                    }

                    if (values.tags) {
                        params['tags'] = [values.tags];
                    }

                    const queryString = Object.keys(params)
                        .map(key => key + '=' + params[key])
                        .join('&');

                    history.replace(`/rfq/?${queryString}`);
                } catch (err: any) {
                    notification.error({
                        message: <ErrorRender error={e} />,
                    });
                }
            }
        });
    };

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Row>
                    <Col {...cols}>
                        <FormItem label="Search" {...formItemLayout}>
                            {getFieldDecorator('search', {
                                initialValue: query.get('search'),
                            })(
                                <Search
                                    placeholder="Search"
                                    style={{ width: '100%' }}
                                    allowClear
                                    enterButton={
                                        <Button type="primary" htmlType={'submit'}>
                                            <SearchOutlined />
                                        </Button>
                                    }
                                    size={'small'}
                                />
                            )}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Account" {...formItemLayout}>
                            {getFieldDecorator('account', {
                                initialValue: query.get('account')
                                    ? {
                                          key: query.get('account'),
                                      }
                                    : {},
                            })(<Accounts />)}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Contact" {...formItemLayout}>
                            {getFieldDecorator('contact', {
                                initialValue: query.get('contact')
                                    ? {
                                          key: query.get('contact'),
                                      }
                                    : {},
                            })(<Contacts />)}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <Col>
                            <FormItem label="Sales Rep" {...formItemLayout}>
                                {getFieldDecorator('owner', {
                                    initialValue: query.get('owner')
                                        ? {
                                              key: query.get('owner'),
                                          }
                                        : {},
                                })(
                                    <GenericSelect
                                        recordType={'User'}
                                        filters={{
                                            is_staff: true,
                                            is_active: true,
                                        }}
                                        size="small"
                                    />
                                )}
                            </FormItem>
                        </Col>

                        <Col>
                            <FormItem label="Design Engineer" {...formItemLayout}>
                                {getFieldDecorator('design_engineer', {
                                    initialValue: query.get('design_engineer')
                                        ? {
                                              key: query.get('design_engineer'),
                                          }
                                        : {},
                                })(
                                    <GenericSelect
                                        recordType={'User'}
                                        filters={{
                                            is_staff: true,
                                            is_active: true,
                                        }}
                                        size="small"
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Itar" {...formItemLayout}>
                            {getFieldDecorator('itar', {
                                initialValue: query.get('itar'),
                            })(
                                <Select size={'small'}>
                                    <Option key={1}>Yes</Option>
                                    <Option key={0}>No</Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Status" {...formItemLayout}>
                            {getFieldDecorator('quote__stage', {
                                initialValue: query.get('quote__stage'),
                            })(
                                <Select allowClear size="small">
                                    <Select.OptGroup key="Unresolved" label="Unresolved">
                                        <Select.Option value="In Queue">In Queue</Select.Option>
                                        <Select.Option value="In Progress">In Progress</Select.Option>
                                        <Select.Option value="Vendor Quoting">Vendor Quoting</Select.Option>
                                        <Select.Option value="Needs Review">Needs Review</Select.Option>
                                        <Select.Option value="Waiting Customer">Waiting Customer</Select.Option>
                                        <Select.Option value="Waiting on Go Team">Waiting on Go Team</Select.Option>
                                    </Select.OptGroup>

                                    <Select.OptGroup key="Resolved" label="Resolved">
                                        <Select.Option value="Completed">Completed</Select.Option>
                                        <Select.Option value="Closed Won">Closed Won</Select.Option>
                                        <Select.Option value="Closed Lost">Closed Lost</Select.Option>
                                        <Select.Option value="Cancelled">Cancelled</Select.Option>
                                    </Select.OptGroup>
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                    <Col {...cols}>
                        <FormItem label="Tags" {...formItemLayout}>
                            {getFieldDecorator('tags', {
                                initialValue: array_tags,
                            })(
                                <Select allowClear size="small" mode="multiple">
                                    <Select.Option value="ITAR">ITAR</Select.Option>
                                    <Select.Option value="Requote">Requote</Select.Option>
                                    <Select.Option value="Domestic">Domestic</Select.Option>
                                    <Select.Option value="Customer Material">Customer Material</Select.Option>
                                    <Select.Option value="sheet_metal">Sheet Metal</Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="ReQuotes" {...formItemLayout}>
                            {getFieldDecorator('requotes', {
                                valuePropName: 'checked',
                                initialValue: query.get('requote') || false,
                            })(<Checkbox />)}
                        </FormItem>
                        <FormItem label="Unassigned" {...formItemLayout}>
                            {getFieldDecorator('unassigned', {
                                valuePropName: 'checked',
                                initialValue: toBolOrNull(query?.get('unassigned')),
                            })(<Checkbox />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="All Unresolved" {...formItemLayout}>
                            {getFieldDecorator('unresolved', {
                                valuePropName: 'checked',
                                initialValue: toBolOrNull(query.get('unresolved')),
                            })(<Checkbox />)}
                        </FormItem>
                        <FormItem label="Resolved" {...formItemLayout}>
                            {getFieldDecorator('resolved', {
                                valuePropName: 'checked',
                                initialValue: toBolOrNull(query.get('resolved')),
                            })(<Checkbox />)}
                        </FormItem>
                    </Col>

                    <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    history.push(`/rfq/`);
                                    form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export const RfqFilterForm = Form.create()(RfqFilterFormComponent);
