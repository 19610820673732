import * as React from 'react';
import { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios';
import { API_ROOT } from '@partsbadger/utils';
var busy = null;
export var Industries = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), industries = _b[0], setIndustries = _b[1];
    var _c = useState([]), industry_use = _c[0], setIndustryUse = _c[1];
    var _d = useState([]), endUses = _d[0], setEndUses = _d[1];
    var _e = useState(null), selected_industry = _e[0], setSelectedIndustry = _e[1];
    var _f = useState(null), selected_industry_use = _f[0], setSelectedIndustryUse = _f[1];
    var _g = useState(null), selected_end_use = _g[0], setSelectedEndUse = _g[1];
    /**
     * Get all industries
     * @param search
     */
    var getIndustries = function (search) {
        setLoading(true);
        if (busy) {
            clearTimeout(busy);
        }
        busy = setTimeout(function () {
            axios.get("".concat(API_ROOT, "/staff/industries/?search=").concat(search)).then(function (response) {
                setIndustries(response.data.results);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * Get End Use for a specif industry
     * @param industry_id
     */
    var getEndUseByIndustry = function (industry_id) {
        setLoading(true);
        busy = setTimeout(function () {
            axios.get("".concat(API_ROOT, "/staff/industries/").concat(industry_id, "/")).then(function (response) {
                setEndUses(response.data.end_uses);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * Get uses by specific industry
     * @param industry_id
     */
    var getUses = function (industry_id) {
        setLoading(true);
        busy = setTimeout(function () {
            axios.get("".concat(API_ROOT, "/staff/industries/").concat(industry_id, "/uses/")).then(function (response) {
                setIndustryUse(response.data);
                setLoading(false);
            });
        }, 400);
    };
    /**
     * On load get industries
     */
    useEffect(function () {
        getIndustries('');
    }, []);
    /**
     * Preloading industry and use if is available
     */
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (((_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.industry) === null || _b === void 0 ? void 0 : _b.key) && props.value.industry.key !== (selected_industry === null || selected_industry === void 0 ? void 0 : selected_industry.key)) {
            setSelectedIndustry(props.value.industry);
        }
        if (((_c = props.value) === null || _c === void 0 ? void 0 : _c.industry_use) && props.value.industry_use.key !== (selected_industry_use === null || selected_industry_use === void 0 ? void 0 : selected_industry_use.key)) {
            setSelectedIndustryUse(props.value.industry_use);
        }
        // Usually end use dont comes with unique key
        if (((_e = (_d = props.value) === null || _d === void 0 ? void 0 : _d.end_use) === null || _e === void 0 ? void 0 : _e.label) && props.value.end_use.label !== (selected_end_use === null || selected_end_use === void 0 ? void 0 : selected_end_use.label)) {
            setSelectedEndUse(props.value.end_use);
        }
    }, [props.value]);
    useEffect(function () {
        if (selected_industry === null || selected_industry === void 0 ? void 0 : selected_industry.key) {
            getUses(selected_industry.key);
            getEndUseByIndustry(selected_industry.key);
            if (props.onChange) {
                // only send values if all three are selected
                if (selected_industry.key && (selected_industry_use === null || selected_industry_use === void 0 ? void 0 : selected_industry_use.key) && (selected_end_use === null || selected_end_use === void 0 ? void 0 : selected_end_use.key)) {
                    props.onChange({
                        industry: selected_industry,
                        industry_use: selected_industry_use,
                        end_use: selected_end_use,
                    });
                }
                else {
                    props.onChange(null);
                }
            }
        }
    }, [selected_industry, selected_industry_use, selected_end_use]);
    return (React.createElement("div", null,
        React.createElement(Select, { showSearch: true, labelInValue: true, filterOption: function (input, option) {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "Industry", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, 
            //@ts-ignore
            value: (selected_industry === null || selected_industry === void 0 ? void 0 : selected_industry.key)
                ? selected_industry
                : {
                    key: null,
                    label: null,
                }, onChange: function (item) {
                if (item === null || item === void 0 ? void 0 : item.key) {
                    setSelectedIndustry(item);
                    //Clear the uses and use
                    setSelectedIndustryUse(null);
                    setSelectedEndUse(null);
                }
                else {
                    // setUses([]);
                    setIndustryUse([]);
                    setEndUses([]);
                }
            } }, industries === null || industries === void 0 ? void 0 : industries.map(function (row) {
            return (React.createElement(Select.Option, { key: row.id, value: row.id }, row.name));
        })),
        React.createElement(Select, { showSearch: true, labelInValue: true, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "Use", filterOption: function (input, option) {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }, 
            //@ts-ignore
            value: (selected_industry_use === null || selected_industry_use === void 0 ? void 0 : selected_industry_use.key)
                ? selected_industry_use
                : {
                    key: null,
                    label: null,
                }, onChange: function (use) {
                setSelectedIndustryUse(use);
            } }, industry_use === null || industry_use === void 0 ? void 0 : industry_use.map(function (item) {
            return React.createElement(Select.Option, { key: item.id }, item.use.name);
        })),
        React.createElement(Select, { showSearch: true, labelInValue: true, size: 'small', style: { width: 300 }, dropdownMatchSelectWidth: false, placeholder: "End Use", filterOption: function (input, option) { return option.props.children.toLowerCase().indexOf(input.toLowerCase()); }, 
            //@ts-ignore
            value: (selected_end_use === null || selected_end_use === void 0 ? void 0 : selected_end_use.key)
                ? selected_end_use
                : {
                    key: null,
                    label: null,
                }, onChange: function (item) {
                setSelectedEndUse(item);
            } }, endUses === null || endUses === void 0 ? void 0 : endUses.map(function (item) {
            return React.createElement(Select.Option, { key: item.id }, item.description);
        }))));
};
