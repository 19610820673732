import { Col, Input, Row, Spin, Switch } from 'antd';
import { Price } from '@partsbadger/library';
import React, { useState } from 'react';
import { QuoteStore } from '../Stores/index';
export var QuoteTotals = function (_a) {
    var quote = _a.quote;
    var _b = useState(false), editDiscount = _b[0], setEditDiscount = _b[1];
    var _c = useState(false), editShippingCost = _c[0], setEditShippingCost = _c[1];
    if (!quote) {
        return React.createElement(Spin, { spinning: true });
    }
    return (React.createElement(Row, null,
        React.createElement(Col, { style: { textAlign: 'right', fontSize: '14px' } },
            React.createElement("div", { className: "grid gap-4 items-right text-base font-bold m-2" },
                "Pricing Groups:",
                React.createElement(Switch, { checked: quote.pricing_groups, className: "ml-10", onChange: function (value) {
                        var payload = {
                            pricing_groups: value,
                        };
                        if (quote === null || quote === void 0 ? void 0 : quote.id) {
                            QuoteStore.updateQuote(quote.id, payload);
                        }
                    } }))),
        React.createElement(Row, { style: { textAlign: 'right', fontSize: '14px', paddingRight: 30 } },
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Shipping Cost for China: ")),
            React.createElement(Col, { md: 6 },
                React.createElement(Price, { value: quote.manufactured_shipping_cost })),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Subtotal: ")),
            React.createElement(Col, { md: 6 },
                React.createElement(Price, { value: quote.total_price })),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Application Cost: ")),
            React.createElement(Col, { md: 6 },
                React.createElement(Price, { value: quote.application_cost })),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Discount:")),
            React.createElement(Col, { md: 6 }, editDiscount ? (React.createElement(Input, { type: 'number', placeholder: quote.discount, onPressEnter: function (e) {
                    QuoteStore.updateQuote(quote.id, {
                        //@ts-ignore
                        discount: e.target.value,
                    });
                    setEditDiscount(false);
                } })) : (React.createElement("span", { className: 'editable-row link', onClick: function () { return setEditDiscount(true); } },
                "$ ",
                React.createElement(Price, { value: quote.discount }),
                ' '))),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' },
                    ' ',
                    "Taxes ",
                    React.createElement(Price, { value: quote.taxes_rate }),
                    " %")),
            React.createElement(Col, { md: 6 },
                React.createElement(Price, { value: quote.taxes })),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Shipping Cost: ")),
            React.createElement(Col, { md: 6 }, editShippingCost === true ? (React.createElement(Input, { type: 'number', placeholder: quote.shipping_cost, onPressEnter: function (e) {
                    QuoteStore.updateQuote(quote.id, {
                        //@ts-ignore
                        shipping_cost: e.target.value,
                    });
                    setEditShippingCost(false);
                } })) : (React.createElement("span", { className: 'editable-row link', onClick: function (e) { return setEditShippingCost(true); } },
                "$ ",
                React.createElement(Price, { value: quote.shipping_cost })))),
            React.createElement(Col, { md: 18 },
                React.createElement("span", { className: 'title' }, "Total: ")),
            React.createElement(Col, { md: 6 },
                "$ ",
                React.createElement(Price, { value: quote.grand_total_with_shipping_cost })))));
};
