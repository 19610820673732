var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as THREE from 'three';
export function Edges(_a) {
    var userData = _a.userData, children = _a.children, geometry = _a.geometry, _b = _a.threshold, threshold = _b === void 0 ? 15 : _b, _c = _a.color, color = _c === void 0 ? 'black' : _c, props = __rest(_a, ["userData", "children", "geometry", "threshold", "color"]);
    var ref = React.useRef(null);
    React.useLayoutEffect(function () {
        var parent = ref.current.parent;
        if (parent) {
            var geom = geometry || parent.geometry;
            if (geom !== ref.current.userData.currentGeom || threshold !== ref.current.userData.currentThreshold) {
                ref.current.userData.currentGeom = geom;
                ref.current.userData.currentThreshold = threshold;
                ref.current.geometry = new THREE.EdgesGeometry(geom, threshold);
            }
        }
    });
    return (React.createElement("lineSegments", __assign({ ref: ref, raycast: function () { return null; } }, props), children ? children : React.createElement("lineBasicMaterial", { color: color })));
}
