import Draggable from 'react-draggable';
import { Document, Page } from 'react-pdf';
import { InputTextAreaAntd, RectangleDiv, DragRectangleDiv } from './styles';
import React from 'react';

interface ViewerProps {
    pdfUrl: string;
    redaction: any;
    pageNumber: number;
    scale: number;
}
const RedactionViwer: React.FC<ViewerProps> = ({ pdfUrl, redaction, pageNumber, scale }) => {
    const allowedRedactions = ['PatchCustomerInformation', 'RemoveCustomerInformation'];
    redaction = redaction.filter(
        (item: { redaction_type: string }) => allowedRedactions.includes(item.redaction_type)
    );
    return (
        <div className="app-wrapper">
            <div className={`App`} id="App">
                <Document file={pdfUrl}>
                    <Page pageNumber={pageNumber + 1} scale={scale}>
                        {redaction.map((item: any) =>
                            item.page_number === pageNumber ? (
                                <Draggable
                                    position={{
                                        x: item.corners[0].x * scale,
                                        y: item.corners[0].y * scale,
                                    }}
                                >
                                    {item.redaction_type === 'RemoveCustomerInformation' ||
                                    item.redaction_type === 'PatchCustomerInformation' ? (
                                        <RectangleDiv
                                            getWidth={Math.abs((item.corners[1].x - item.corners[0].x) * scale)}
                                            getHeight={Math.abs((item.corners[1].y - item.corners[0].y) * scale)}
                                            getKey={item.key}
                                            getModeEdition={true}
                                            id={`rectangleDiv${item.key}`}
                                        >
                                            <DragRectangleDiv
                                                id={`target${item.key}`}
                                                className={`redaction-item target${item.key}`}
                                                getKey={item.key}
                                            >
                                                <InputTextAreaAntd readOnly />
                                            </DragRectangleDiv>
                                        </RectangleDiv>
                                    ) : null}
                                </Draggable>
                            ) : null
                        )}
                    </Page>
                </Document>
            </div>
        </div>
    );
};
export default RedactionViwer;
