import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { get, post } from '@partsbadger/utils';
import { IDomesticVendor } from '@partsbadger/types/lib/staff/list/IDomesticVendor';

class DomesticVendor {
    isLoading = false;
    materialVendors: IDomesticVendor[] = [];
    materialVendor: IDomesticVendor | null = null;
    paginationCount = 0;

    constructor() {
        makeAutoObservable(this, {
            isLoading: observable,
            materialVendors: observable,
            materialVendor: observable,
            paginationCount: observable,
            getList: action,
            sendToFastlane: action,
        });
    }

    getList = async (params = {}) => {
        runInAction(() => {
            this.isLoading = true;
        });
        const data = await get(`/staff/vendors/`, {
            params: params,
        });
        runInAction(() => {
            this.paginationCount = data.count;
            this.materialVendors = data.results;
            this.isLoading = false;
        });
    };

    sendToFastlane = async (id: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        const data = await post(`/staff/vendors/${id}/send-to-fastlane/`);
        runInAction(() => {
            this.materialVendor = data;
            this.isLoading = false;
        });
    };

    sendToZoho = async (id: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        const data = await post(`/staff/vendors/${id}/send-to-zoho/`);
        runInAction(() => {
            this.materialVendor = data;
            this.isLoading = false;
        });
    };
}

export const DomesticVendorStore = new DomesticVendor();
