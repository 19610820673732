import * as ActionTypes from '../ActionTypes';

export const Coatings = (
    state = {
        isLoading: false,
        error: null,
        entities: []
    },
    action) => {
    switch (action.type) {
        case ActionTypes.COATINGS_LOADING:
            return {...state, isLoading: true};

        case ActionTypes.COATINGS_LOADED:
            return {...state, isLoading: false, entities: action.payload}

        default:
            return state;
    }
};