import React, { useMemo } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, InputNumber } from 'antd';
import SalesOrderBatchStore from '../Stores/SalesOrderBatchStore';
import { observer } from 'mobx-react';
import disabledDate from '../utils/utils';
import { SalesOrderStore } from '../Stores';
var SalesOrderAddBatchForm = observer(function (props) {
    var getFieldDecorator = props.form.getFieldDecorator;
    var order_items = SalesOrderStore.order_items;
    var line_item = order_items === null || order_items === void 0 ? void 0 : order_items.find(function (item) { return item.id === (props === null || props === void 0 ? void 0 : props.order_item_id); });
    var handleSubmitBatch = function (e) {
        e.preventDefault();
        props.form.validateFields(['batch_ship_part_date', 'batch_quantity'], function (err, values) {
            if (!err) {
                if (values.batch_quantity > 0) {
                    var batch_ship_part_date = values.batch_ship_part_date.format('MM-DD-YYYY');
                    var batch_quantity = values.batch_quantity;
                    props.onSubmitted({ batch_ship_part_date: batch_ship_part_date, batch_quantity: batch_quantity });
                    props.form.resetFields();
                    SalesOrderBatchStore.getQuantity(props.order_item_id);
                    SalesOrderBatchStore.getMissingQuantity(SalesOrderBatchStore.original_quantity, SalesOrderBatchStore.total_quantity, (line_item === null || line_item === void 0 ? void 0 : line_item.quantity_sample_required) ? line_item === null || line_item === void 0 ? void 0 : line_item.quantity_sample_required : 0);
                }
            }
        });
    };
    var getMaxQuantity = function () {
        if (!line_item || !(props === null || props === void 0 ? void 0 : props.order_item_id))
            return 0;
        var totalBatchQuantity = SalesOrderBatchStore.getQuantity(props.order_item_id);
        var sampleQuantity = line_item.quantity_sample_required || 0;
        var lineItemQuantity = line_item.quantity || 0;
        if (sampleQuantity > 0) {
            var totalAllocated = totalBatchQuantity + sampleQuantity;
            return Math.max(0, lineItemQuantity - totalAllocated);
        }
        return Math.max(0, lineItemQuantity - totalBatchQuantity);
    };
    var cachedMaxQty = useMemo(function () { return getMaxQuantity(); }, [
        line_item === null || line_item === void 0 ? void 0 : line_item.quantity,
        line_item === null || line_item === void 0 ? void 0 : line_item.quantity_sample_required,
        props === null || props === void 0 ? void 0 : props.order_item_id,
        SalesOrderBatchStore.total_quantity,
    ]);
    return (React.createElement("div", { style: { border: '1px solid #E7E6E6' }, className: 'p-4 mt-8' },
        React.createElement(Form, { layout: "inline", onSubmit: handleSubmitBatch },
            React.createElement(Form.Item, { label: "Estimated Ship Date" }, getFieldDecorator('batch_ship_part_date', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a date!',
                    },
                ],
            })(React.createElement(DatePicker
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            , { 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                disabledDate: disabledDate }))),
            React.createElement(Form.Item, { label: "Quantity", hasFeedback: true, validateStatus: cachedMaxQty == 0 ? 'error' : '', help: cachedMaxQty == 0 && 'No quantity remains' }, getFieldDecorator('batch_quantity', {
                rules: [
                    {
                        required: true,
                        message: 'Please input a quantity!',
                    },
                ],
            })(React.createElement(InputNumber, { max: cachedMaxQty, placeholder: String(SalesOrderBatchStore.missing_quantity), style: { width: '100px' } }))),
            React.createElement(Form.Item, null,
                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Add")))));
});
export default Form.create()(SalesOrderAddBatchForm);
