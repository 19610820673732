import * as ActionTypes from '../ActionTypes';

export const Accounts = (
    state = {
        isLoading: false,
        entity: {},
        entities: [],
        defaultAccount: {},
        showAccountForm: false,
        error: null,
        sites: [],
        masterProducts: [],
    },
    action) => {
    switch (action.type) {
        case ActionTypes.ACCOUNTS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};

        case ActionTypes.ACCOUNT_LOADED:
        case ActionTypes.ACCOUNT_CREATED:
            return {...state, isLoading: false, error: null, entity: action.payload};


        case ActionTypes.ACCOUNTS_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.ACCOUNTS_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.ACCOUNTS_MASTER_PRODUCT_LOADED:
            return {...state, isLoading: false, error: null, masterProducts: action.payload};

        case ActionTypes.ACCOUNTS_MASTER_PRODUCT_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.ACCOUNTS_MASTER_PRODUCT_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.SHOW_FORM_ACCCOUNT:
            return {
                ...state,
                isLoading: false,
                defaultAccount: action.payload,
                showAccountForm: true
            };


        case ActionTypes.HIDE_FORM_ACCOUNT:
            return {
                ...state,
                showAccountForm: false,
                defaultAccount: {},
                isLoading: false
            };

        case ActionTypes.SITES_LOADING:
            return {...state, isLoading: true, error: null}


        case ActionTypes.SITES_LOADED:
            return {...state, sites: action.payload};


        case ActionTypes.SITE_FAILED:
            return {...state, isLoading: false, error: action.error};

        default:
            return state;
    }
};