import React from 'react';
import { Select, Form } from 'antd';

import { observer } from 'mobx-react';
import { QuoteStore } from '@partsbadger/quote-tool/lib/Stores';

const CoatingsSelect = (props: any) => {
    const onSearch = (value: string) => {
        QuoteStore.getCoatings({ search: value });
    };
    return (
        <Select showSearch size="small" {...props} placeholder="Search" onSearch={onSearch} filterOption={false}>
            {QuoteStore.coatings.map(m => {
                return (
                    <Select.Option key={m.id} value={m.name}>
                        {m.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default observer(CoatingsSelect);
