//@ts-nocheck

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { get } from '../../shared';
import { ThreeDViewer as Viewer } from "@partsbadger/threedviewer";

export const ThreeDViewer = observer(() => {
    const { mpId } = useParams();
    const [url, setUrl] = useState();

    useEffect(() => {
        get(`staff/master-products/${mpId}/threed-signed-url/`).then(response => {
            console.log(response);
            setUrl(response.data.url);
        });
    }, [mpId]);

    if (url) {
        return <Viewer url={url} canvasStyle={{ margin: 'auto', width: '90vw', height: '90vh', }} />;
    }
    return <div></div>;
});

export const ThreeDViewerFromStepFileURL = observer(() => {

    const { id } = useParams();
    const [url, setUrl] = useState();

    useEffect(() => {
        get(`customer/step-files/${id}/threed-signed-url/`).then(response => {
            console.log(response);
            setUrl(response.data.url);
        });
    }, [id]);

    if (url) {
        return <Viewer url={url} canvasStyle={{ margin: 'auto', width: '90vw', height: '90vh' }} />;
    }
    return <div />;
});


export const ThreeDViewerFromStepFile = observer(({ id }) => {

    const [url, setUrl] = useState();

    useEffect(() => {
        get(`customer/step-files/${id}/threed-signed-url/`).then(response => {
            console.log(response);
            setUrl(response.data.url);
        });
    }, [id]);

    if (url) {
        return <Viewer url={url} canvasStyle={{ margin: 'auto', height: '600px' }} />;
    }
    return <div />;
});


export const StaffThreeDViewerFromStepFile = observer(() => {

    const { id } = useParams();
    const [url, setUrl] = useState();

    useEffect(() => {
        get(`staff/step-files/${id}/threed-signed-url/`).then(response => {
            setUrl(response.data.url);
        });
    }, [id]);

    if (url) {
        return <Viewer url={url} canvasStyle={{ margin: 'auto', width: '90vw', height: '90vh' }} />;
    }
    return <div />;
});


export const ThreeDViewerForInstantQuote = observer(() => {

    const { quote_uuid, item_uuid } = useParams();

    const [url, setUrl] = useState();

    useEffect(() => {
        get(`customer/quote/${quote_uuid}/threed-signed-url/${item_uuid}`).then(response => {
            setUrl(response.data.url);
        });
    }, [quote_uuid]);

    console.log(url)

    if (url) {
        return <Viewer url={url} canvasStyle={{ margin: 'auto', width: '100vw', height: '100vh', }} />;
    }
    return <div />;
});
