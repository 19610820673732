import React, { useState, useEffect } from 'react';
import { DateRangePicker } from '@partsbadger/library';

import { Card, Table, Row, Col, Button } from 'antd';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { get } from '../../shared/api';

import moment, { Moment } from 'moment';

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const YesterdayTransactionsReport = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [yesterdayIntakes, setYesterdayIntakes] = useState<Array<any>>([]);
    const [yesterdayOuttakes, setYesterdayOuttakes] = useState<Array<any>>([]);

    const { form } = props;

    const { getFieldDecorator } = form;

    useEffect(() => {
        setLoading(true);
        getSalesOrderOuttakesYesterday().finally(() => setLoading(false));
        getSalesOrderIntakesYesterday().finally(() => setLoading(false));
    }, []);

    // const handleSubmit = (e: any) => {
    //     e.preventDefault();
    //     props.form.validateFields((err, values) => {
    //         if (!err) {
    //             const params = {
    //                 created_time_min: '',
    //                 created_time_max: '',
    //             };

    //             if (values.created_time) {
    //                 params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
    //                 params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
    //             } else {
    //                 return;
    //             }

    //             getSalesOrderIntakesYesterday(params);
    //             getSalesOrderOuttakesYesterday(params);
    //         }
    //     });
    // };

    // const getSalesOrderIntakesYesterday = async (params: { created_time_min: string; created_time_max: string }) => {
    //     setLoading(true);
    //     get(`staff/report-intakes-yesterday/`)
    //         .then((response: any) => {
    //             setYesterdayIntakes(response.data.results);
    //         })
    //         .catch((error: any) => console.log(error))
    //         .finally(() => setLoading(false));
    // };

    // const getSalesOrderOuttakesYesterday = async (params: { created_time_min: string; created_time_max: string }) => {
    //     get(`staff/report-outtakes-yesterday/`)
    //         .then((response: any) => {
    //             setYesterdayOuttakes(response.data.results);
    //         })
    //         .catch((error: any) => console.log(error))
    //         .finally(() => setLoading(false));
    // };

    const getSalesOrderIntakesYesterday = async () => {
        const response = await get(`staff/report-intakes-yesterday/`);
        setYesterdayIntakes(response.data.results);
    };

    const getSalesOrderOuttakesYesterday = async () => {
        const response = await get(`staff/report-outtakes-yesterday/`);
        setYesterdayOuttakes(response.data.results);
    };

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_ship_date',
            key: 'required_ship_date',
        },
        {
            title: 'Hot Order',
            dataIndex: 'hot_order',
            key: 'hot_order',
            render: (t: string, r: any) => {
                return r.hot_order ? <CheckCircleOutlined /> : '';
            },
        },
    ];

    return (
        <>
            {/* <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>
                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row> */}

            <Card title={'Yesterday Intakes'}>
                <div className={'w-full'}>
                    <Table
                        key={'yesterday-outtake'}
                        loading={loading}
                        columns={columns}
                        dataSource={yesterdayIntakes}
                        pagination={false}
                    />
                </div>
            </Card>
            <Card title={'Yesterday Outtakes'} className={'mt-10'}>
                <div className={'w-full'}>
                    <Table
                        key={'yesterday-outtake'}
                        loading={loading}
                        columns={columns}
                        dataSource={yesterdayOuttakes}
                        pagination={false}
                    />
                </div>
            </Card>
        </>
    );
};

export const YesterdayTransactionsForm = Form.create()(YesterdayTransactionsReport);
