import * as ActionTypes from '../ActionTypes';


export const Quotes = (
    state = {
        isLoading: false,
        isLoadingAdditionalRequirements: false,
        error: null,
        showAddDealForm: false,
        showDetails: false,
        entities: [],
        pagination_quotes: {
            current: 1,
            defaultPageSize: 30,
        },
        pagination: {},
        instantQuotes: [],
        instantQuotesOrders: [],
        instantQuotesOrder: {
            created_by: {}
        },
        byAccount: [],
        reportByAuthor: [],
        entity: [],
        additional_requirements: [],
        quotedProduct: {},
        rfq: {
            account: null
        },
        rfqs: [],
        pagination_rfqs: {
            current: 1,
            defaultPageSize: 30,
        },
        rfqItems: sessionStorage.getItem('rfqItems') ? JSON.parse(sessionStorage.getItem('rfqItems')) : [],
    }, action) => {


        switch (action.type) {


            case ActionTypes.QUOTES_LOADING:
                return {...state, isLoading: true, error: null}

            case ActionTypes.QUOTES_LOADED:
                return {
                    ...state,
                    isLoading: false,
                    error: null,
                    entities: action.payload,
                    pagination_quotes: action.pagination
                };


            case ActionTypes.QUOTE_LOADING:
                return {...state, isLoading: true, error: null}

            case   ActionTypes.QUOTES_REPORT_LOADED:
                return {...state, isLoading: false, error: null, reportByAuthor: action.payload};


            case ActionTypes.QUOTES_BY_ACCOUNT_LOADED:
                return {...state, isLoading: false, error: null, byAccount: action.payload};

            case ActionTypes.QUOTE_LOADED:
                return {...state, isLoading: false, error: null, entity: action.payload};


            case ActionTypes.QUOTE_ADDED:
                return {
                    ...state,
                    isLoading: false,
                    showAddDealForm: false,
                    entities: state.entities.concat(action.payload)
                };

            case ActionTypes.QUOTE_ADD_FORM:
                return {...state, showAddDealForm: true};

            case ActionTypes.QUOTE_HIDE_ADD_FORM:
                return {...state, showAddDealForm: false};


            case ActionTypes.QUOTE_FAILED:
            case ActionTypes.QUOTES_FAILED:
            case ActionTypes.QUOTE_PRODUCT_ADDING_FAILED:
            case ActionTypes.RFQ_ERROR:
            case ActionTypes.INSTANT_QUOTES_ERROR:

                return {...state, isLoading: false, error: action.error};

            case ActionTypes.ADDITIONAL_REQUIREMENTS_LOADING:
                return {...state, isLoadingAdditionalRequirements: true, error: null}

            case ActionTypes.ADDITIONAL_REQUIREMENTS_LOADED:
                return {
                    ...state,
                    isLoadingAdditionalRequirements: false,
                    error: null,
                    additional_requirements: action.payload
                };

            case ActionTypes.ADDITIONAL_REQUIREMENTS_ERROR:
                return {...state, isLoadingAdditionalRequirements: false, error: action.error};


            case ActionTypes.QUOTED_PRODUCT_LOADED:
                return {...state, isLoading: false, error: null, quotedProduct: action.payload};


            case ActionTypes.RFQ_LOADING:
                return {...state, isLoading: true, error: null}

            case ActionTypes.RFQ_LOADED:
                return {...state, isLoading: false, error: null, rfq: action.payload, rfqItems: action.payload.rfq_items}

            case ActionTypes.RFQS_LOADED:
                return {...state, isLoading: false, error: null, rfqs: action.payload, pagination_rfqs: action.pagination}


            case ActionTypes.INSTANT_QUOTES_LOADING:
                return {...state, isLoading: true, error: null}

            case ActionTypes.INSTANT_QUOTES_LOADED:
                return {...state, isLoading: false, error: null, instantQuotes: action.payload}

            case ActionTypes.INSTANT_QUOTES_ORDERS_LOADING:
                return {...state, isLoading: true, error: null}

            case ActionTypes.INSTANT_QUOTES_ORDERS_LOADED:
                return {...state, isLoading: false, error: null, instantQuotesOrders: action.payload}

            case ActionTypes.INSTANT_QUOTES_ORDER_LOADED:
                return {...state, isLoading: false, error: null, instantQuotesOrder: action.payload}


            default:
                return state;
        }
    }
;