var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Suspense, useEffect, useState } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Center } from '@react-three/drei';
import { ContactShadows } from './ContactShadows';
import { Edges } from './Edges';
import { LoadingOutlined } from '@ant-design/icons';
import { get } from '@partsbadger/utils';
function Scene(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Center, null,
            React.createElement("group", null,
                React.createElement("mesh", { geometry: props === null || props === void 0 ? void 0 : props.geometry, castShadow: true },
                    React.createElement("meshPhysicalMaterial", { attach: "material", color: 0xffffff, metalness: 0.7, roughness: 0.5 }),
                    (props === null || props === void 0 ? void 0 : props.edges) ? (React.createElement(Edges, { scale: 1.002, threshold: 15, color: 0x444444 })) : null)))));
}
export var ThreeJSThumbnail = function (props) {
    var _a = useState(null), geometry = _a[0], setGeometry = _a[1];
    var _b = useState(null), part = _b[0], setPart = _b[1];
    var loader = new THREE.BufferGeometryLoader();
    var fetchPart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get("/quickturn/api/parts/".concat(props === null || props === void 0 ? void 0 : props.id, "/"))];
                case 1:
                    data = _a.sent();
                    setPart(data);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (props === null || props === void 0 ? void 0 : props.id) {
            fetchPart();
        }
    }, [props === null || props === void 0 ? void 0 : props.id, props === null || props === void 0 ? void 0 : props.seed]);
    useEffect(function () {
        if (part === null || part === void 0 ? void 0 : part.get_buffer_geometry) {
            var bufferGeo = loader.parse(part === null || part === void 0 ? void 0 : part.get_buffer_geometry);
            setGeometry(bufferGeo);
        }
    }, [part]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Suspense, { fallback: null },
            React.createElement("div", { style: { width: props === null || props === void 0 ? void 0 : props.size, height: props === null || props === void 0 ? void 0 : props.size } }, !(props === null || props === void 0 ? void 0 : props.id) || !part || !geometry || !part.material ? (React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center" },
                React.createElement(LoadingOutlined, { style: { fontSize: '2rem', color: 'rgba(0,0,0,0.5)' } }))) : (React.createElement(Canvas, { dpr: window.devicePixelRatio, camera: {
                    fov: Math.hypot(part.overallLength, part.hasFlange ? part.flangeDiameter : part.outerDiameter) * 2,
                    near: 0.1,
                    far: 1000,
                    position: [-25, 10, 25],
                }, shadows: true },
                React.createElement("color", { attach: "background", args: [props === null || props === void 0 ? void 0 : props.background] }),
                React.createElement("hemisphereLight", { intensity: 0.3, position: [0, 100, 0] }),
                React.createElement("spotLight", { color: 0xebf7f7, intensity: 0.7, position: [-5, 10, -12] }),
                React.createElement("spotLight", { color: 0xebf7f7, intensity: 0.2, position: [-10, -10, -5] }),
                React.createElement("pointLight", { position: [10, 10, 10], intensity: 1, color: 0xc8dee0 }),
                React.createElement(Scene, { geometry: geometry, edges: props === null || props === void 0 ? void 0 : props.edges, part: part }),
                React.createElement(ContactShadows, { position: [
                        0,
                        (part === null || part === void 0 ? void 0 : part.hasFlange) ? -((part === null || part === void 0 ? void 0 : part.flangeDiameter) / 2) : -((part === null || part === void 0 ? void 0 : part.outerDiameter) / 2),
                        0,
                    ], opacity: 0.3, scale: 10, blur: 3, far: 0.8 })))))));
};
