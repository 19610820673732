var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { observer } from 'mobx-react';
import { Modal, notification } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
import ShipmentForm from '../Form/ShipmentForm';
import { ErrorRender } from '@partsbadger/library';
export var CreateBox = observer(function (props) {
    var _a, _b, _c, _d;
    var _e = IntakeStore.boxInfo, carrier = _e.carrier, shipping_method = _e.shipping_method, shipping_account = _e.shipping_account, bill_to_customer = _e.bill_to_customer;
    var outtake = (_a = IntakeStore.previousOuttakes) === null || _a === void 0 ? void 0 : _a.find(function (obj) { return obj.id === IntakeStore.boxInfo['outtakeId']; });
    return (React.createElement(React.Fragment, null, IntakeStore.createBoxVisible && (React.createElement(Modal, { title: "Create Shipment", visible: true, width: 900, onCancel: function () { return IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']); }, cancelText: "Close", footer: [] },
        React.createElement(ShipmentForm, { isTester: props.isTester, box: {
                carrier: carrier !== null && carrier !== void 0 ? carrier : 'FedEX',
                shipping_method: shipping_method,
                shipping_account: shipping_account,
                bill_to_customer: bill_to_customer,
                // If customer pays for the shipment, default shipping cost to 0
                shipping_cost: shipping_account.toLowerCase() != 'pb' ? 0 : null,
                opt_out_packing_slip: (_b = outtake === null || outtake === void 0 ? void 0 : outtake.sales_order.notification_opt_out) !== null && _b !== void 0 ? _b : false,
            }, shipping_notes: (_c = outtake === null || outtake === void 0 ? void 0 : outtake.sales_order) === null || _c === void 0 ? void 0 : _c.shipping_notes, customer_shipping_notes: (_d = outtake === null || outtake === void 0 ? void 0 : outtake.sales_order) === null || _d === void 0 ? void 0 : _d.customer_shipping_notes, outbound_destination: (outtake === null || outtake === void 0 ? void 0 : outtake.outbound_destination) ? outtake.outbound_destination : '', handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, IntakeStore.createBox(payload)
                                .then(function () {
                                IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']);
                            })
                                .catch(function (e) {
                                notification.error({
                                    message: 'Error creating shipment',
                                    description: React.createElement(ErrorRender, { error: e }),
                                });
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, handleClose: function () {
                IntakeStore.setCreateBoxVisible(false, IntakeStore.boxInfo['outtakeId']);
            } })))));
});
