import React, { useEffect } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Badge, Button, Col, Divider, Popover, Row, Tag } from 'antd';
import { ThumbnailFile, VendorQuote } from '../MasterProduct';
import ThumbnailFileMcMaster from '../MasterProduct/ThumbnailFileMcMaster';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { mm_to_in } from '@partsbadger/utils';
import DynamicQuantities from './DynamicQuantities';
import LineItems from './LineItems';
var Dimensions = function (_a) {
    var row = _a.row;
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, row.unit === 'MM' ? parseFloat(mm_to_in(row.length)).toFixed(3) : parseFloat(row.length).toFixed(3)),
        ' ',
        "* \u00A0",
        React.createElement("span", null, row.unit === 'MM' ? parseFloat(mm_to_in(row.width)).toFixed(3) : parseFloat(row.width).toFixed(3)),
        ' ',
        "* \u00A0",
        React.createElement("span", null, row.unit === 'MM' ? parseFloat(mm_to_in(row.height)).toFixed(3) : parseFloat(row.height).toFixed(3)),
        ' ',
        "IN",
        React.createElement(Divider, { type: 'vertical' }),
        React.createElement("span", { title: row.total_volume.toString() },
            React.createElement("strong", null, "Total Volume"),
            " ",
            row.total_volume_inches.toFixed(3),
            " IN"),
        React.createElement(Divider, { type: 'vertical' }),
        React.createElement("span", { title: row.part_volume.toString() },
            React.createElement("strong", null, "Part Vol. "),
            row.part_volume_inches.toFixed(3),
            " IN"),
        React.createElement(Divider, { type: 'vertical' }),
        React.createElement("span", { title: row.removed_volume.toFixed() },
            React.createElement("strong", null, "Removed Vol."),
            " ",
            row.removed_volume_inches.toFixed(3),
            " IN"),
        React.createElement(Divider, { type: 'vertical' }),
        React.createElement("span", null,
            React.createElement("strong", null, "3d Print Vol. "),
            row.analyzed_3d ? parseFloat(row.volume_3d_printing).toFixed(3) : 'Analyzing...',
            " MM")));
};
export var LineItemInfo = observer(function (_a) {
    var line_item = _a.line_item;
    useEffect(function () {
        if (line_item)
            QuoteStore.getLineItems(line_item.product.quote);
        console.log(line_item);
    }, [line_item]);
    var dataSource = line_item &&
        QuoteStore.lineItems
            .filter(function (entity) { return entity.product.master_product === line_item.master_product.id; })
            .map(function (row) {
            return row;
        });
    var renderFile = function (id) {
        var url = "/3d-viewer/".concat(id, "/");
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    var duplicateMasterProduct = function (item) {
        var line_items = [];
        if (item.product.has_dynamic_quantities) {
            line_items.push(item.product.id);
        }
        else {
            dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (record) {
                line_items.push(record.id);
            });
        }
        if (line_items.length > 0) {
            QuoteStore.deleteMasterProduct(item.mp_replace_id);
            QuoteStore.duplicate_line_item(line_items, item.product.quote);
        }
    };
    return React.createElement(React.Fragment, null, line_item && (React.createElement("div", null,
        React.createElement(Row, { className: 'quote-line-item' },
            React.createElement(Row, { className: 'quote-master-product', style: { padding: '10px', cursor: 'pointer' } },
                React.createElement(Col, { xl: 2, xs: 24 },
                    React.createElement(ThumbnailFile, { master_product: line_item.master_product, handleClick: function () { return renderFile(line_item.master_product.id); } })),
                React.createElement(Col, { xl: 22, xs: 24 },
                    React.createElement("div", { className: 'flex flexRow itemCenter justifyContent' },
                        React.createElement("div", { title: line_item.master_product.id.toString() },
                            ' ',
                            line_item.master_product.name),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement(Dimensions, { row: line_item.master_product })),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement("strong", null, "3D File:"),
                            " \u00A0",
                            React.createElement("span", { title: 'Preview 3d file', className: "link", onClick: function () { return renderFile(line_item.master_product.id); } }, line_item.master_product.step_file && (React.createElement("span", null, line_item.master_product.step_file.filename)))),
                        React.createElement(Divider, { type: 'vertical' }),
                        React.createElement("div", null,
                            React.createElement("strong", null, "2D File:"),
                            " \u00A0",
                            React.createElement("span", { title: 'Preview 2d file', className: "link" }, line_item.master_product.drawing_file && (React.createElement("span", null, line_item.master_product.drawing_file.filename)))),
                        React.createElement(Divider, { type: 'vertical' }),
                        line_item.master_product.itar ? (React.createElement("div", { className: 'itar' },
                            React.createElement(Badge, { count: 'ITAR', style: { backgroundColor: '#4b5320' } }),
                            React.createElement(Divider, { type: 'vertical' }))) : (''),
                        React.createElement(Divider, { type: 'vertical' }),
                        line_item.master_product.vendor_quote_information && (React.createElement("div", null,
                            React.createElement(Popover, { title: "Vendor Quote", content: React.createElement(VendorQuote, { quotes: line_item.master_product.vendor_quote_information }) },
                                React.createElement(Tag, { color: 'blue' },
                                    "Quoted by a Vendor: ",
                                    line_item.master_product.vendor_name)),
                            React.createElement(Divider, { type: 'vertical' }))),
                        line_item.master_product.mcmaster_product ? (React.createElement("div", { className: 'flex flex-row items-center' },
                            React.createElement(ThumbnailFileMcMaster, { mcmaster_product: line_item.master_product.mcmaster_product }),
                            React.createElement(Divider, { type: 'vertical' }))) : (React.createElement("div", null)))))),
        React.createElement(Row, { className: 'quote-master-product-variations' },
            line_item.product.quote_item_quantities.length > 0 ? (React.createElement("div", null,
                React.createElement(DynamicQuantities, { editable: false, item: line_item.product, partVol: null, totalVol: null }))) : (React.createElement("div", null, dataSource && (React.createElement(LineItems, { editable: false, master_product_id: line_item.master_product.id, line_items: dataSource })))),
            React.createElement("div", { className: "flex flex-col py-4" },
                React.createElement("div", { className: "flex justify-center py-2" },
                    React.createElement("span", null, "When this item is duplicated, it is replaced by the item of the current quote.")),
                React.createElement("div", { className: "flex justify-center py-2" },
                    React.createElement(Button, { type: 'primary', icon: React.createElement(LegacyIcon, { type: 'check' }), onClick: function () { return duplicateMasterProduct(line_item); } }, "Use this item.")))))));
});
