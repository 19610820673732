import React, { useState } from 'react';
import {
    BoldOutlined,
    FontSizeOutlined,
    ItalicOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import {
    ButtonAntd,
    HeaderFlexRow,
    AlignFlexCol,
    Title,
    AlignFlexRow,
    InputNumberAntd,
    SwatchDivAntd,
    ColorDivAntd,
    CoverDivAntd,
    PopoverDivAntd,
} from './styles';

interface IProps {
    displayColorPicker: boolean;
    ftFontSize: number;
    fontColor: string;
    fontBold: boolean;
    fontItalic: boolean;
    setDisplayColorPicker: (displayColorPicker:boolean) => void;
    setFtFontSize: (ftFontSize: number) => void;
    setFontColor: (fontColor: string) => void;
    setFontBold: (fontBold: boolean) => void;
    setFontItalic: (fontItalic: boolean) => void;
}

const FontTools :React.FC<IProps> = ({
    displayColorPicker,
    ftFontSize,
    fontColor,
    fontBold,
    fontItalic,
    setDisplayColorPicker,
    setFtFontSize,
    setFontColor,
    setFontBold,
    setFontItalic,
}) => {

    const handleClickColorPicker = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const colorPicker = (
        <div>
            <SwatchDivAntd onClick={() => handleClickColorPicker()}>
                <ColorDivAntd getFontColor={fontColor} />
            </SwatchDivAntd>
            {displayColorPicker ? (
                <PopoverDivAntd>
                    <CoverDivAntd onClick={() => setDisplayColorPicker(false)} />
                    <SketchPicker
                        color={fontColor}
                        onChange={(color: any) => {
                            setFontColor(color.hex);
                        }}
                    />
                </PopoverDivAntd>
            ) : null}
        </div>
    );

    return (
        <HeaderFlexRow>
            <AlignFlexCol>
                <Title>Font Size:</Title>
                <AlignFlexRow>
                    <ButtonAntd
                        onClick={async () => {
                            setFtFontSize(ftFontSize - 1);
                        }}
                        size="large"
                    >
                        <MinusCircleOutlined />
                    </ButtonAntd>
                    <InputNumberAntd
                        value={Math.round(ftFontSize)}
                        max={200}
                        onChange={value => value && setFtFontSize(parseInt(value.toString()))}
                        size="large"
                        addonAfter={<FontSizeOutlined />}
                        style={{ width: 100 }}
                    />

                    <ButtonAntd
                        title={'Shit and +'}
                        onClick={async () => {
                            setFtFontSize(ftFontSize + 1);
                        }}
                        size="large"
                    >
                        <PlusCircleOutlined />
                    </ButtonAntd>
                </AlignFlexRow>
            </AlignFlexCol>
            <AlignFlexCol>
                <Title>Font Properties:</Title>
                <AlignFlexRow>
                    <ButtonAntd
                        onClick={async () => {
                            setFontBold(!fontBold);
                        }}
                        size="large"
                        type={fontBold ? 'primary' : 'default'}
                    >
                        <BoldOutlined />
                    </ButtonAntd>
                    <ButtonAntd
                        onClick={async () => {
                            setFontItalic(!fontItalic);
                        }}
                        size="large"
                        type={fontItalic ? 'primary' : 'default'}
                    >
                        <ItalicOutlined />
                    </ButtonAntd>
                    {colorPicker}
                </AlignFlexRow>
            </AlignFlexCol>
        </HeaderFlexRow>
    );
};

export default FontTools;
