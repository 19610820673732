import * as ActionTypes from '../ActionTypes';

export const PartTolerances = (
    state = {
        isLoading: false,
        error: null,
        entities: []
    },
    action) => {

    switch (action.type) {

        case ActionTypes.PART_TOLERANCES_LOADING:
            return {...state, isLoading: true};

        case ActionTypes.PART_TOLERANCES_LOADED:
            return {...state, isLoading: false, entities: action.payload};

        case ActionTypes.PART_TOLERANCES_FAILED:
            return {...state, isLoading: true, error: action.error};

        default:
            return state;
    }
};