import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, message, Table } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import { Price } from '@partsbadger/library';
var ApplicationCostItems = function (props) {
    var available_application_costs_items = SalesOrderStore.available_application_costs_items;
    var columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: 100,
            render: function (t, record) {
                return (React.createElement(Fragment, null,
                    React.createElement("span", null,
                        React.createElement(Price, { value: record.price }))));
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: function (t, record) {
                return (React.createElement(Button, { style: {
                        color: 'green',
                    }, onClick: function () {
                        return props.handleAdd(record).then(function () {
                            message.success('Item Added!');
                        });
                    } },
                    React.createElement(LegacyIcon, { type: 'plus' }),
                    "Add To Order"));
            },
            align: 'right',
        },
    ];
    return (React.createElement(Fragment, null,
        React.createElement(Table, { rowKey: 'id', className: 'variation', pagination: false, dataSource: available_application_costs_items, columns: columns })));
};
export default observer(ApplicationCostItems);
