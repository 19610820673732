var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
import { OrderedListOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Modal, notification, Radio, Row } from 'antd';
import ChangeRequiredQuantitiesForm from '../DynamicQuantities/ChangeRequiredQuantitiesForm';
import { ErrorRender, TablePrices } from '@partsbadger/library';
import { QuoteLineItemForm } from '../../Forms';
import { QuoteLineItemQuantityForm, styleBody } from '../DynamicQuantities';
import { ModalERPNotes } from '../ModalERPNotes';
var DynamicQuantities = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var item = _a.item, partVol = _a.partVol, totalVol = _a.totalVol, editable = _a.editable;
    var _v = useState(false), isEditingItem = _v[0], setIsEditingItem = _v[1];
    var _w = useState(false), loading = _w[0], setLoading = _w[1];
    var _x = useState(false), showPricesList = _x[0], setShowPricesList = _x[1];
    var _y = useState(false), isLoadingPricesList = _y[0], setIsLoadingPricesList = _y[1];
    var _z = useState(), pricesList = _z[0], setPricesList = _z[1];
    var _0 = useState({
        lower: item.lead_time_adjust_range_min,
        upper: item.lead_time_adjust_range_max,
    }), leadTimeRange = _0[0], setLeadTimeRange = _0[1];
    var _1 = useState({
        lower: item.unit_price_adjust_range_min,
        upper: item.unit_price_adjust_range_max,
    }), uniPriceRange = _1[0], setUnitPriceRange = _1[1];
    var _2 = useState((_b = item.unit_price_adjust_type) !== null && _b !== void 0 ? _b : '%'), uniPriceAdjustmentType = _2[0], setUnitPriceAdjustmentType = _2[1];
    var _3 = useState(false), showModalERPNote = _3[0], setShowModalERPNote = _3[1];
    var _4 = useState(), dataERPNote = _4[0], setDataERPNote = _4[1];
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = __assign({ id: item.id }, values);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, QuoteStore.saveItem(payload)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.recalculatePrices({
                            quoted_products: [item.id],
                        }).catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'One of the line items has an error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        })];
                case 3:
                    _a.sent();
                    QuoteStore.getQuote(item.quote);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    notification.error({
                        message: 'Error',
                        description: React.createElement(ErrorRender, { error: error_1 }),
                    });
                    return [3 /*break*/, 5];
                case 5:
                    setIsEditingItem(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getPricesList = function (page) {
        if (page === void 0) { page = 1; }
        return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoadingPricesList(true);
                        return [4 /*yield*/, QuoteStore.getDynamicPricesList(item.id, page)];
                    case 1:
                        response = _a.sent();
                        setPricesList(response);
                        setIsLoadingPricesList(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleSendLeadAdjustment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, QuoteStore.makeDynamicQuantityAdjustment(item.id, {
                        lead_time_adjust_range_min: leadTimeRange === null || leadTimeRange === void 0 ? void 0 : leadTimeRange.lower,
                        lead_time_adjust_range_max: leadTimeRange === null || leadTimeRange === void 0 ? void 0 : leadTimeRange.upper,
                    }).catch(function (error) {
                        notification.error({
                            placement: 'topRight',
                            message: 'Error, please verify your inputs',
                            description: React.createElement(ErrorRender, { error: error }),
                        });
                    })];
                case 1:
                    _a.sent();
                    QuoteStore.getQuote(item.quote);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSendUnitPriceAdjustment = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, QuoteStore.makeDynamicQuantityAdjustment(item.id, {
                        unit_price_adjust_type: uniPriceAdjustmentType,
                        unit_price_adjust_range_min: uniPriceRange === null || uniPriceRange === void 0 ? void 0 : uniPriceRange.lower,
                        unit_price_adjust_range_max: uniPriceRange === null || uniPriceRange === void 0 ? void 0 : uniPriceRange.upper,
                    }).catch(function (error) {
                        notification.error({
                            placement: 'topRight',
                            message: 'Error, please verify your inputs',
                            description: React.createElement(ErrorRender, { error: error }),
                        });
                    })];
                case 1:
                    _a.sent();
                    QuoteStore.getQuote(item.quote);
                    return [2 /*return*/];
            }
        });
    }); };
    var initialQuantities = ((_c = item.quantities) === null || _c === void 0 ? void 0 : _c.length) ? item.quantities : [item.quantity];
    useEffect(function () {
        setLeadTimeRange({
            lower: item.lead_time_adjust_range_min,
            upper: item.lead_time_adjust_range_max,
        });
    }, [item]);
    var handleAddERPQuotingNotes = function (quote_line_item_id, notes) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setDataERPNote({
                id: quote_line_item_id,
                partsbadger_production_quoting_notes: notes,
            });
            setShowModalERPNote(true);
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: 'shadow' },
            React.createElement(Col, { md: 24, className: 'row-quote-item ml-4' },
                React.createElement(ChangeRequiredQuantitiesForm, { item_id: item.id, isReadOnly: !editable, quantities: initialQuantities, handleChangeQuantities: function (quantities) { return __awaiter(void 0, void 0, void 0, function () {
                        var e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, QuoteStore.updateRequiredQuantities(item.id, {
                                            quantities: quantities,
                                        })];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _a.sent();
                                    notification.error({
                                        key: 'form-',
                                        duration: 8,
                                        description: 'One of the line items has an error',
                                        message: 'Error',
                                        placement: 'top',
                                        maxCount: 1,
                                    });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); } })),
            React.createElement(Col, { md: 24, className: 'row-quote-item ml-4 mt-1' },
                ((_d = item.debug) === null || _d === void 0 ? void 0 : _d.face_directions) ? 'FD' : 'BF:',
                ' ', (_g = (_f = (_e = item.debug) === null || _e === void 0 ? void 0 : _e.face_directions) === null || _f === void 0 ? void 0 : _f.toFixed(2)) !== null && _g !== void 0 ? _g : (_j = (_h = item.debug) === null || _h === void 0 ? void 0 : _h.badger) === null || _j === void 0 ? void 0 : _j.toFixed(2),
                React.createElement(QuoteLineItemForm, { isReadOnly: !editable, item: {
                        id: item.id,
                        drawing_file: item.product.drawing_file,
                        manufacturing_type: item.manufacturing_type,
                        reinforcement_percent: item.reinforcement_percent || 0,
                        formula: item.formula,
                        has_material_cert: item.has_material_cert,
                        batch_shipment: item.batch_shipment,
                        reinforcement: item.reinforcement || '',
                        design_time: item.product.design_time,
                        product__sides: item.product.sides,
                        is_fai_required: item.is_fai_required,
                        is_prod_candidate: item.is_prod_candidate,
                        manual_adjustment: item.manual_adjustment,
                        vendor_price_adjust: item.vendor_price_adjust,
                        vendor_price: item.vendor_price,
                        totalVol: totalVol,
                        partVol: partVol,
                        product__material: ((_k = item.product.material) === null || _k === void 0 ? void 0 : _k.id)
                            ? {
                                key: item.product.material.id.toString(),
                                label: item.product.material.name,
                                value: item.product.material.id.toString(),
                            }
                            : null,
                        product__finish: item.product.finish
                            ? {
                                key: item.product.finish.id.toString(),
                                label: item.product.finish.name,
                                value: item.product.finish.id.toString(),
                            }
                            : null,
                        product__coating: item.product.coating
                            ? {
                                key: item.product.coating.id.toString(),
                                label: item.product.coating.name,
                                value: item.product.coating.id.toString(),
                            }
                            : null,
                        has_file_3d: item.product.has_file_3d,
                        custom_material: (_l = item.product.custom_material) !== null && _l !== void 0 ? _l : '',
                        custom_finish: (_m = item.product.custom_finish) !== null && _m !== void 0 ? _m : '',
                        custom_coating: (_o = item.product.custom_coating) !== null && _o !== void 0 ? _o : '',
                        partsbadger_production_method: item.partsbadger_production_method,
                        partsbadger_production_quoting_notes: item.partsbadger_production_quoting_notes,
                    }, handleSubmit: handleSubmit, addPartsbadgerProductionQuotingNotes: handleAddERPQuotingNotes })),
            React.createElement(Col, { md: 24, className: 'row-quantities mt-1' },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Displayed on the quote?")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Lead Time"),
                        React.createElement("div", { className: 'adjustment-field', style: {
                                marginTop: 20,
                            } },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 100, placeholder: "Min", value: (leadTimeRange === null || leadTimeRange === void 0 ? void 0 : leadTimeRange.lower) || '', style: { width: '100%', textAlign: 'center' }, onChange: function (event) {
                                    setLeadTimeRange(__assign(__assign({}, leadTimeRange), { lower: event.target.value }));
                                }, onPressEnter: handleSendLeadAdjustment, onBlur: handleSendLeadAdjustment }))),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Vendor Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Material Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Shipping")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Unit Price Adjustment"),
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Radio.Group, { disabled: !editable, value: uniPriceAdjustmentType, onChange: function (event) {
                                    setUnitPriceAdjustmentType(event.target.value);
                                    setTimeout(handleSendUnitPriceAdjustment, 300);
                                }, className: 'flex flex-row items-end justify-center' },
                                React.createElement(Radio, { id: "action", value: '%' }, "%"),
                                React.createElement(Radio, { id: "action", value: '$' }, "$")),
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 201, placeholder: "Min", value: (uniPriceRange === null || uniPriceRange === void 0 ? void 0 : uniPriceRange.lower) || '', style: { width: '100%', textAlign: 'center' }, onChange: function (event) {
                                    setUnitPriceRange(__assign(__assign({}, uniPriceRange), { lower: event.target.value }));
                                }, onPressEnter: handleSendUnitPriceAdjustment, onBlur: handleSendUnitPriceAdjustment }))),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Unit Price")),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { style: styleBody }, "Total Price"))),
                React.createElement(Row, null, (_p = item.quote_item_quantities) === null || _p === void 0 ? void 0 : _p.map(function (quote_item_quantity) {
                    return (React.createElement(QuoteLineItemQuantityForm, { key: quote_item_quantity.id, isReadOnly: !editable, variation: quote_item_quantity, uniPriceAdjustmentType: uniPriceAdjustmentType, handleUpdate: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, QuoteStore.updateQuoteLineQuantityItem(item.id, quote_item_quantity.id, values)];
                                    case 1:
                                        _a.sent();
                                        QuoteStore.getQuote(item.quote);
                                        return [2 /*return*/];
                                }
                            });
                        }); } }));
                })),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 100, placeholder: "Max", value: (_q = leadTimeRange === null || leadTimeRange === void 0 ? void 0 : leadTimeRange.upper) !== null && _q !== void 0 ? _q : '', style: { width: '100%', textAlign: 'center' }, onChange: function (event) {
                                    setLeadTimeRange(__assign(__assign({}, leadTimeRange), { upper: event.target.value }));
                                }, onPressEnter: handleSendLeadAdjustment, onBlur: handleSendLeadAdjustment }))),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 },
                        React.createElement("div", { className: 'adjustment-field' },
                            React.createElement(Input, { disabled: !editable, type: "number", tabIndex: 202, placeholder: "Max", value: (uniPriceRange === null || uniPriceRange === void 0 ? void 0 : uniPriceRange.upper) || '', style: { width: '100%', textAlign: 'center' }, onChange: function (event) {
                                    setUnitPriceRange(__assign(__assign({}, uniPriceRange), { upper: event.target.value }));
                                }, onPressEnter: handleSendUnitPriceAdjustment, onBlur: handleSendUnitPriceAdjustment }))),
                    React.createElement(Col, { span: 3 }),
                    React.createElement(Col, { span: 3 })))),
        React.createElement(Modal, { title: 'Price per Quantity', centered: true, visible: showPricesList, onCancel: function () { return setShowPricesList(false); }, footer: [] },
            React.createElement(TablePrices, { loading: isLoadingPricesList, prices: (_r = pricesList === null || pricesList === void 0 ? void 0 : pricesList.results) !== null && _r !== void 0 ? _r : [], count: (_s = pricesList === null || pricesList === void 0 ? void 0 : pricesList.count) !== null && _s !== void 0 ? _s : 30, current: (_t = pricesList === null || pricesList === void 0 ? void 0 : pricesList.current) !== null && _t !== void 0 ? _t : 1, pageSize: (_u = pricesList === null || pricesList === void 0 ? void 0 : pricesList.pageSize) !== null && _u !== void 0 ? _u : 1, handleSelect: function (qty) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, QuoteStore.addQuoteItemQuantity(item.id, qty)];
                            case 1:
                                _a.sent();
                                QuoteStore.getQuote(item.quote);
                                return [2 /*return*/];
                        }
                    });
                }); }, handlePageChange: function (page) {
                    getPricesList(page);
                } })),
        React.createElement(Row, { className: 'flex flex-row items-end p-2' },
            React.createElement("div", { className: 'flex justify-center w-2/3' },
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: function () {
                        getPricesList(1);
                        setShowPricesList(true);
                    } },
                    React.createElement(OrderedListOutlined, null),
                    " More Prices"),
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: function () {
                        QuoteStore.duplicateLineItem(item.id);
                    }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation"),
                React.createElement(Button, { disabled: !editable, type: 'link', onClick: function () { return QuoteStore.deleteLineItem(item.id); }, icon: React.createElement(LegacyIcon, { type: 'delete' }) }, "Delete Line")),
            React.createElement("div", { className: 'flex flex-row-reverse w-1/3' },
                React.createElement(Button, { loading: loading, disabled: !editable, className: 'ml-2', size: 'small', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!item.quote) return [3 /*break*/, 2];
                                    setLoading(true);
                                    return [4 /*yield*/, QuoteStore.duplicateLineItem(item.id).finally(function () { return setLoading(false); })];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, icon: React.createElement(LegacyIcon, { type: 'plus' }) }, "Variation"),
                React.createElement(Button, { loading: loading, disabled: !editable, className: 'ml-2', size: 'small', onClick: function () {
                        if (item.quote) {
                            setLoading(true);
                            QuoteStore.duplicateQuotedProduct(item.product.master_product).finally(function () {
                                return setLoading(false);
                            });
                        }
                    }, icon: React.createElement(LegacyIcon, { type: 'copy' }) }, "Duplicate Product"))),
        dataERPNote && (React.createElement(ModalERPNotes, { quote_id: item.quote, showModalERPNote: showModalERPNote, quote_line_item_id: dataERPNote.id, notes: dataERPNote.partsbadger_production_quoting_notes, setShowModalERPNote: setShowModalERPNote, setDataERPNote: setDataERPNote }))));
});
export default Form.create()(DynamicQuantities);
