var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
import { QuoteStore } from '../../Stores';
import { observer } from 'mobx-react';
var HoleTolerances = function (props) {
    var holeTolerances = QuoteStore.holeTolerances;
    return (React.createElement(Select, __assign({ showSearch: true, labelInValue: true, optionKeyProp: 'value', size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search tolerance", filterOption: function (input, option) {
            var _a, _b, _c;
            //@ts-ignore
            return ((_c = (_b = (_a = option.props.children) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.slice(3)) === null || _c === void 0 ? void 0 : _c.indexOf(input.toLowerCase())) >= 0;
        } }, props), holeTolerances.map(function (entity) { return (React.createElement(Select.Option, { key: entity.id }, "".concat(entity.name, " (").concat(entity.value_in_mm, " mm)"))); })));
};
export default observer(HoleTolerances);
