import React, { useState, useEffect, useRef } from 'react';
import { Spin, message, Tooltip, Popover, Popconfirm, Switch } from 'antd';
import Draggable from 'react-draggable';
import {
    DeleteOutlined,
    RadiusBottomrightOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined,
    FontSizeOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    PercentageOutlined,
    RotateRightOutlined,
    RotateLeftOutlined,
    TagOutlined,
    FileAddOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import * as _ from 'lodash';

import {
    Container,
    ButtonAntd,
    HeaderFlexRow,
    AlignFlexCol,
    Title,
    AlignFlexRow,
    InputNumberAntd,
    PdfLayout,
    DwgTextBoxDiv,
    InputTextAreaAntd,
    DragDwgTextBox,
    RectangleDiv,
    DragRectangleDiv,
} from './styles';
import { RedactionToolStore } from './RedactionToolStore';
import { observer } from 'mobx-react';
import { post } from '@partsbadger/utils';
import FontTools from './FontTools';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface CIPoint {
    x: number;
    y: number;
}

interface textProperties {
    fontColor: string;
    fontSize: number;
    fontBold: boolean;
    fontItalic: boolean;
}

interface IProps {
    textAreaRef: any;
    textFreeAreaRef: any;
    insertDWG: boolean;
    insertFTA: boolean;
    line_item_id: number;
    ProfileName: string | undefined;
    resetProfileName: (value: string) => void;
    removeDWG: () => void;
    redactedFileNameRef: any;
    FTAProperties: textProperties;
    redactAsFlatFile: boolean;
}

const EditPdf: React.FC<IProps> = ({
    textAreaRef,
    textFreeAreaRef,
    insertDWG,
    insertFTA,
    line_item_id,
    ProfileName,
    resetProfileName,
    removeDWG,
    redactedFileNameRef,
    FTAProperties,
    redactAsFlatFile,
}) => {
    const [pdf, setPdf] = useState<any | null>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const [scale, setScale] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const { redaction, setRedaction } = RedactionToolStore;
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [target, setTarget] = useState<string>('target');
    const [eventPage, setEventPage] = useState<any | null>(null);
    const [dpi, setDpi] = useState<number>(72);
    const { redactionType, setRedactionType } = RedactionToolStore;
    const [borderRadius, setBorderRadius] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [showOriginalPDF, setShowOriginalPDF] = useState<boolean>(false);

    const refTarget = useRef<Array<HTMLDivElement | null>>([]);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const pdfContainer = useRef<HTMLDivElement | null>(null);

    const [trackMousePos, setTrackMousePos] = useState<boolean>(false);
    const [mousePosition, setMousePosition] = useState<CIPoint | undefined>(undefined);
    const [currentRedaction, setCurrentRedaction] = useState<CIPoint[]>([]);
    const [fontSize, setFontSize] = useState<number>(10);
    const [ftFontSize, setFtFontSize] = useState<number>(15);
    const [selectedRedactionKey, setSelectedRedactionKey] = useState<number | null>(null);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

    const [scrolLeft, setScrolLeft] = useState<number>(0);
    const [scrolTop, setScrolTop] = useState<number>(0);
    const [rotateLoading, setRotateLoading] = useState(false);
    const [fontColor, setFontColor] = useState<string>('000000');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [fontBold, setFontBold] = useState(false);
    const [fontItalic, setFontItalic] = useState(false);
    const [useFlatFile, setUseFlatFile] = useState(redactAsFlatFile);
    const hasMounted = useRef(false);

    const onDocumentLoadSuccess = (loadedPdf: any) => {
        setPdf(loadedPdf);
        setNumPages(loadedPdf.numPages);
    };

    const onKeyDownEvent = (event: any) => {
        if (event.shiftKey && event.key == '-') {
            setScale(scale - 0.1);
        }

        if (event.shiftKey && event.key == '+') {
            setScale(scale + 0.1);
        }

        if (event.key == 'Escape') {
            setCurrentRedaction([]);
            setRedactionType(null);
            setTarget('target');
        }

        if (event.ctrlKey && event.key.toLowerCase() == 'z') {
            redaction.pop();
            setRedaction(redaction);
        }
        if (event.key == 'Delete' && selectedRedactionKey) {
            setRedaction(redaction.filter(r => r.key != selectedRedactionKey));
            setSelectedRedactionKey(null);
        }
    };

    const handleScrollForLeft = (event: {
        currentTarget: { scrollLeft: React.SetStateAction<number>; scrollTop: React.SetStateAction<number> };
    }) => {
        setScrolLeft(event.currentTarget.scrollLeft);
        setScrolTop(event.currentTarget.scrollTop);
    };

    const handleResize = (width: number, height: number, resizeText: boolean) => {
        if (resizeText) {
            setFontSize(width * 0.04);
        }
        if (isMouseDown) {
            setRedaction(
                redaction.map(item => {
                    if (item.name === target) {
                        return {
                            ...item,
                            x1: item.x0 + width,
                            y1: item.y0 + height,
                        };
                    }
                    return item;
                })
            );
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownEvent);
        return () => {
            window.removeEventListener('keydown', onKeyDownEvent);
        };
    });

    useEffect(() => {
        setCurrentRedaction([]);
    }, [redactionType]);

    useEffect(() => {
        if (insertDWG) {
            setRedactionType(null);
            return setRedaction([
                ...redaction,
                {
                    key: redaction.length + 1,
                    name: `target${redaction.length + 1}`,
                    x0: 100,
                    y0: 300,
                    x1: 380,
                    y1: 600,
                    redaction_type: 'DrawingTextBox',
                    page: pageNumber,
                    scale: scale,
                    font_size: ftFontSize,
                    font_color: '#636369',
                },
            ]);
        }
        const redactionsFiltered = redaction.filter(item => {
            return item.redaction_type !== 'DrawingTextBox';
        });

        return setRedaction(redactionsFiltered);
    }, [insertDWG]);

    useEffect(() => {
        setRedactionType(null);
        setRedaction([
            ...redaction,
            {
                key: redaction.length + 1,
                name: `target${redaction.length + 1}`,
                x0: 100 + (redaction.length + 1) * 5,
                y0: 300 + (redaction.length + 1) * 5,
                x1: 380 + (redaction.length + 1) * 5,
                y1: 600 + (redaction.length + 1) * 5,
                redaction_type: 'FreeTextBox',
                page: pageNumber,
                scale: scale,
                text: textFreeAreaRef.current.resizableTextArea.props.value,
                font_bold: FTAProperties.fontBold,
                font_italic: FTAProperties.fontItalic,
                font_size: FTAProperties.fontSize,
                font_color: FTAProperties.fontColor,
            },
        ]);
    }, [insertFTA]);

    useEffect(() => {
        setTarget('');
        setRedaction(
            redaction.map(item => {
                return {
                    ...item,
                    x0: Math.abs((item.x0 / item.scale) * scale),
                    y0: Math.abs((item.y0 / item.scale) * scale),
                    x1: Math.abs((item.x1 / item.scale) * scale),
                    y1: Math.abs((item.y1 / item.scale) * scale),
                    font_size: Math.abs((item.font_size / item.scale) * scale),
                    scale: scale,
                };
            })
        );
    }, [scale]);

    useEffect(() => {
        const el = document.getElementById('App');
        const bounding = el?.getBoundingClientRect();
        const divx = bounding?.x || 0;
        const divy = bounding?.y || 0;

        function trackMouseMove(e: any) {
            setMousePosition({ x: e.pageX - divx, y: e.pageY - divy });
        }

        if (trackMouseMove) {
            window.addEventListener('mousemove', trackMouseMove);
        }

        return () => {
            window.removeEventListener('mousemove', trackMouseMove);
        };
    }, [trackMousePos]);

    useEffect(() => {
        setRedaction(
            redaction.map(item => {
                if (item.name === target) {
                    return {
                        ...item,
                        font_size: ftFontSize,
                    };
                }
                return item;
            })
        );
    }, [ftFontSize]);

    useEffect(() => {
        setRedaction(
            redaction.map(item => {
                if (item.name === target) {
                    return {
                        ...item,
                        font_color: fontColor,
                    };
                }
                return item;
            })
        );
    }, [fontColor]);

    useEffect(() => {
        setRedaction(
            redaction.map(item => {
                if (item.name === target) {
                    return {
                        ...item,
                        font_bold: fontBold,
                    };
                }
                return item;
            })
        );
    }, [fontBold]);

    useEffect(() => {
        setRedaction(
            redaction.map(item => {
                if (item.name === target) {
                    return {
                        ...item,
                        font_italic: fontItalic,
                    };
                }
                return item;
            })
        );
    }, [fontItalic]);

    async function fetchPdf(reset: boolean) {
        setLoading(true);

        if (line_item_id) {
            let newPdf = null;

            if (ProfileName && !reset) {
                const params = {
                    profile_name: ProfileName,
                    apply_redactions: true,
                    account: SalesOrderStore.order?.account.id,
                };

                newPdf = await SalesOrderStore.applyRedactionProfile(line_item_id, params);

                if (SalesOrderStore.order?.id) {
                    SalesOrderStore.getOrderById(SalesOrderStore.order.id);
                }
            } else {
                const params = {
                    account: SalesOrderStore.order?.account.id,
                    page_number: pageNumber,
                    dpi: dpi,
                    show_original_pdf: showOriginalPDF,
                    redactions: [],
                    part_name: null,
                    upload_pdf: false,
                    use_flat_file: useFlatFile,
                };

                newPdf = await SalesOrderStore.redactFile(line_item_id, params);
            }

            if (newPdf != null) {
                setPdfUrl(`data:application/pdf;base64,${newPdf['pdf']}`);
            }

            setScrolLeft(0);
            setScrolTop(0);

            setLoading(false);

            return true;
        }

        setLoading(false);

        return false;
    }

    useEffect(() => {
        (async () => {
            try {
                fetchPdf(false);
                setPageNumber(0);
            } catch (err) {
                setLoading(false);
                message.error('Error, unable to fetch the pdf.');
            }
        })();
    }, [line_item_id, dpi, ProfileName, showOriginalPDF]);

    useEffect(() => {
        const container = pdfContainer.current;
        if (container) {
            container.addEventListener('wheel', handleScroll, { passive: false });
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll);
            }
        };
    }, [pdfContainer, scale]);

    useEffect(() => {
        if (redaction.length > 0) {
            if (redaction[redaction.length - 1].redaction_type === 'AddPage') {
                handleSubmit(true);
                setPageNumber(numPages);
            } else if (redaction[redaction.length - 1].redaction_type === 'DeletePage') {
                handleSubmit(true);
                setPageNumber(pageNumber - 1);
            }
        }
    }, [redaction]);

    const onPageClick = async (e: any, index: number, action: string) => {
        if (e.target.id != '') {
            return;
        }
        if (redactionType != 'Edit') {
            const hand_position = getMousePos(e);
            const y = hand_position.y;
            const x = hand_position.x;

            const currentPage = await pdf.getPage(index);
            const viewport = await currentPage.getViewport({ scale: scale });

            if (currentRedaction.length === 0 && target === 'target') {
                setCurrentRedaction([{ x: x, y: y }]);
                setEventPage(e);
            } else if (currentRedaction.length === 1) {
                const [x0, y0] = viewport.convertToPdfPoint(currentRedaction[0].x, currentRedaction[0].y);
                const [x1, y1] = viewport.convertToPdfPoint(x, y);

                if (refTarget.current) {
                    refTarget.current.forEach(ref => {
                        if (ref && ref?.id === target) {
                            const refTargetTargetRect = ref.getBoundingClientRect();
                            setRedaction(
                                redaction.map(item => {
                                    if (item.name === target) {
                                        return {
                                            ...item,
                                            page: index,
                                            x: currentRedaction[0].x > x ? x : currentRedaction[0].x,
                                            y: currentRedaction[0].y > y ? y : currentRedaction[0].y,
                                            x1: currentRedaction[0].x > x ? currentRedaction[0].x : x,
                                            y1: currentRedaction[0].y > y ? currentRedaction[0].y : y,
                                        };
                                    }
                                    return item;
                                })
                            );
                            setEventPage(e);
                        }
                    });
                }
                setCurrentRedaction([]);
                setEventPage(e);
            } else {
                setCurrentRedaction([]);
            }
            setEventPage(e);
        }
    };

    useEffect(() => {
        async function handleMouseDown(event: any) {
            setIsMouseDown(true);
        }
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [redaction, redactionType, currentRedaction, pageNumber]);

    useEffect(() => {
        async function handleMouseUp(event: any) {
            setIsMouseDown(false);
        }
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [redaction, redactionType, currentRedaction, pageNumber]);

    useEffect(() => {
        async function handleClickOutside(event: any) {
            const hand_position = getMousePos(event);
            const y = hand_position.y;
            const x = hand_position.x;

            if (event.target?.className && event.target.className == 'react-pdf__Page__canvas') {
                setTarget('target');
            }

            if (redactionType && currentRedaction.length === 1 && redactionType != 'Edit') {
                setRedaction([
                    ...redaction,
                    {
                        key: redaction.length + 1,
                        name: `target${redaction.length + 1}`,
                        x0: currentRedaction[0].x > x ? x : currentRedaction[0].x,
                        y0: currentRedaction[0].y > y ? y : currentRedaction[0].y,
                        x1: currentRedaction[0].x > x ? currentRedaction[0].x : x,
                        y1: currentRedaction[0].y > y ? currentRedaction[0].y : y,
                        redaction_type: redactionType,
                        page: pageNumber,
                        scale: scale,
                        font_bold: false,
                        font_italic: false,
                        font_color: '000000',
                        font_size: 16,
                    },
                ]);
                setCurrentRedaction([]);
                setTarget(`target${redaction.length + 1}`);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [redaction, redactionType, currentRedaction, pageNumber]);

    const getCoord = async (event: any) => {
        if (refTarget.current && canvasRef.current) {
            const el = document.getElementById('App');
            const bounding = el?.getBoundingClientRect();
            const divx = bounding?.x || 0;
            const divy = bounding?.y || 0;

            refTarget.current.forEach(ref => {
                if (ref && ref?.id === target) {
                    const refTargetTargetRect = ref.getBoundingClientRect();
                    setRedaction(
                        redaction.map(item => {
                            if (item.name === target) {
                                return {
                                    ...item,
                                    x0: refTargetTargetRect.x - divx + scrolLeft - 4,
                                    y0: refTargetTargetRect.y - divy + scrolTop - 4,
                                    x1: refTargetTargetRect.x - divx + scrolLeft + item.x1 - item.x0 - 4,
                                    y1: refTargetTargetRect.y - divy + scrolTop + item.y1 - item.y0 - 4,
                                };
                            }
                            return item;
                        })
                    );
                }
            });
        }
    };

    const handleSubmit = async (uploadPdf: boolean) => {
        setLoading(true);
        setTarget('');
        RedactionToolStore.setRedactionType(null);
        const redactions = redaction.map(item => {
            if (item.redaction_type === 'DrawingTextBox' && textAreaRef.current) {
                return {
                    ...item,
                    text: textAreaRef.current.resizableTextArea.props.value,
                    page_number: item.page,
                    corners: [
                        { x: item.x0 / scale, y: item.y0 / scale },
                        { x: item.x1 / scale, y: item.y1 / scale },
                    ],
                    font_size: Math.abs(fontSize) / scale,
                };
            }

            return {
                ...item,
                page_number: item.page,
                text: item.text ? item.text : '_',
                corners: [
                    { x: item.x0 / scale, y: item.y0 / scale },
                    { x: item.x1 / scale, y: item.y1 / scale },
                ],
                font_size: Math.abs(ftFontSize) / scale,
            };
        });

        const params = {
            dpi: dpi,
            show_original_pdf: showOriginalPDF,
            redactions: redactions,
            apply_redactions: true,
            upload_pdf: uploadPdf,
            account: SalesOrderStore.order?.account.id,
            redacted_file_name: redactedFileNameRef.current.input.value,
            use_flat_file: useFlatFile,
        };

        if (line_item_id) {
            const newPdf = await SalesOrderStore.redactFile(line_item_id, params);

            if (SalesOrderStore.order?.id) {
                SalesOrderStore.getOrderById(SalesOrderStore.order.id);
            }

            setPdfUrl(`data:application/pdf;base64,${newPdf['pdf']}`);
            setLoading(false);
            setRedaction([]);

            if (insertDWG) {
                removeDWG();
            }
        }
    };

    const addPage = () => {
        if (line_item_id) {
            setRedaction([
                ...redaction,
                {
                    key: redaction.length + 1,
                    name: `target${redaction.length + 1}`,
                    x0: 0,
                    y0: 0,
                    x1: 0,
                    y1: 0,
                    redaction_type: 'AddPage',
                    page: 0,
                    scale: scale,
                    font_bold: false,
                    font_italic: false,
                    font_color: '000000',
                    font_size: 0,
                },
            ]);
        }
    };

    const deletePage = () => {
        if (line_item_id && numPages > 1) {
            setRedaction([
                ...redaction,
                {
                    key: redaction.length + 1,
                    name: `target${redaction.length + 1}`,
                    x0: 0,
                    y0: 0,
                    x1: 0,
                    y1: 0,
                    redaction_type: 'DeletePage',
                    page: pageNumber,
                    scale: scale,
                    font_bold: false,
                    font_italic: false,
                    font_color: '000000',
                    font_size: 0,
                },
            ]);
        } else {
            message.error('Error, Can`t delete the single page of the document.');
        }
    };

    const saveText = (text: string) => {
        setRedaction(
            redaction.map(item => {
                if (item.name === target) {
                    return {
                        ...item,
                        text: text,
                    };
                }
                return item;
            })
        );
        setRedactionType(null);
    };

    const handleDelete = async (name: string) => {
        const redactionsFiltered = redaction.filter(item => {
            return item.name !== name;
        });
        setRedaction(redactionsFiltered);
    };

    const handleChangePage = async (pageNumber: number) => {
        setPageNumber(pageNumber);
        setRedaction(redaction);
        setScrolLeft(0);
        setScrolTop(0);
    };

    const handleReset = async () => {
        try {
            await SalesOrderStore.resetRedactionDefaultProfile(line_item_id);
            await fetchPdf(true);
            setPageNumber(0);
            setRedaction([]);

            if (ProfileName) {
                return resetProfileName('');
            }
            message.success('Success, redactions are successfully reset.');
        } catch (err) {
            message.error(`Error, unable to reset redactions. ${err}`);
        }
    };

    useEffect(() => {
        if (hasMounted.current) {
            setLoading(true);
            handleSubmit(true);
        } else {
            hasMounted.current = true;
        }
    }, [useFlatFile]);

    const debouncedSearch = _.debounce(value => setDpi(value), 600);

    const getMousePos = (event: any) => {
        const el = document.getElementById('App');
        const bounding = el?.getBoundingClientRect();
        const divx = bounding?.x || 0;
        const divy = bounding?.y || 0;

        const e = event || window.event;
        const documentScrollY = document.documentElement.scrollTop || document.body.scrollTop;

        const x = e.pageX - divx + scrolLeft - 3 || e.scrolLeft + scrolLeft - divx;
        const y = e.pageY - divy - documentScrollY + scrolTop - 3 || e.clientY - documentScrollY + scrolTop - divy;
        return { x: x, y: y };
    };

    const rotateDrawingFile = (angleRotation: number) => {
        setRotateLoading(true);
        post('/staff/drawing-files/' + line_item_id + '/rotate-file/', {
            angle: angleRotation,
        })
            .then(response => {
                fetchPdf(true);
            })
            .finally(() => {
                setRotateLoading(false);
            });
    };

    const handleScroll = (event: any) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();

            const scaleFactor = 0.08;
            const delta = event.deltaY || event.detail || event.wheelDelta;
            const newScale = delta > 0 ? scale - scaleFactor : scale + scaleFactor;

            if (newScale >= 0.1) {
                setScale(newScale);
            }
        }
    };

    return (
        <Container className="container">
            <HeaderFlexRow>
                <AlignFlexCol>
                    <Title>Save / Reset:</Title>
                    <AlignFlexRow>
                        <ButtonAntd
                            // disabled={redaction.length == 0}
                            onClick={async () => {
                                handleSubmit(true);
                            }}
                            type="primary"
                            size="middle"
                        >
                            Apply
                        </ButtonAntd>
                        <ButtonAntd
                            onClick={async () => {
                                handleReset();
                            }}
                            type="default"
                            size="middle"
                        >
                            Reset
                        </ButtonAntd>
                    </AlignFlexRow>
                </AlignFlexCol>
                <AlignFlexCol>
                    <Title>Zoom:</Title>
                    <AlignFlexRow>
                        <ButtonAntd
                            title={'Shit and  -'}
                            onClick={async () => {
                                setScale(scale - 0.1);
                            }}
                            size="middle"
                        >
                            <ZoomOutOutlined />
                        </ButtonAntd>
                        <InputNumberAntd
                            value={Math.round(scale * 100)}
                            max={200}
                            onChange={value => value && debouncedSearch(value)}
                            size="middle"
                            addonAfter={<PercentageOutlined />}
                            style={{ width: 90 }}
                        />

                        <ButtonAntd
                            title={'Shit and +'}
                            onClick={async () => {
                                setScale(scale + 0.1);
                            }}
                            size="middle"
                        >
                            <ZoomInOutlined />
                        </ButtonAntd>
                    </AlignFlexRow>
                </AlignFlexCol>

                <AlignFlexCol>
                    <Title>Pages:</Title>
                    <AlignFlexRow>
                        <ButtonAntd
                            disabled={pageNumber === 0}
                            onClick={() => handleChangePage(pageNumber - 1)}
                            size="middle"
                        >
                            <LeftOutlined />
                        </ButtonAntd>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '10px',
                                margin: '10px 0',
                            }}
                        >
                            <b>{pageNumber + 1} &nbsp; </b> of <b> &nbsp; {numPages} </b>
                        </div>
                        <ButtonAntd
                            disabled={pageNumber === numPages - 1}
                            onClick={() => handleChangePage(pageNumber + 1)}
                            size="middle"
                        >
                            <RightOutlined />
                        </ButtonAntd>
                    </AlignFlexRow>

                    <AlignFlexRow style={{ marginTop: '5px' }}>
                        <Tooltip placement="topLeft" title="Add a page at the end of the document" arrowPointAtCenter>
                            <ButtonAntd
                                onClick={async () => {
                                    addPage();
                                }}
                                size="middle"
                            >
                                <FileAddOutlined />
                            </ButtonAntd>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Delete current page" arrowPointAtCenter>
                            <Popconfirm
                                placement="topRight"
                                title="Are you sure to delete the current page?"
                                onConfirm={deletePage}
                                okText="Yes"
                                cancelText="No"
                            >
                                <ButtonAntd size="middle">
                                    <DeleteOutlined />
                                </ButtonAntd>
                            </Popconfirm>
                        </Tooltip>
                    </AlignFlexRow>
                </AlignFlexCol>

                <AlignFlexCol>
                    <Title>Tools:</Title>
                    <AlignFlexRow style={{ marginBottom: '10px' }}>
                        <Tooltip placement="topLeft" title="Delete the bounded section." arrowPointAtCenter>
                            <ButtonAntd
                                onClick={async () => {
                                    !redactionType || !redactionType.includes('PatchCustomerInformation')
                                        ? setRedactionType('PatchCustomerInformation')
                                        : setRedactionType(null);
                                    setBorderRadius('0%');
                                }}
                                type={redactionType === 'PatchCustomerInformation' ? 'primary' : 'dashed'}
                                size="middle"
                            >
                                <RadiusBottomrightOutlined />
                            </ButtonAntd>
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Remove text only." arrowPointAtCenter>
                            <ButtonAntd
                                onClick={async () => {
                                    !redactionType || !redactionType.includes('RemoveCustomerInformation')
                                        ? setRedactionType('RemoveCustomerInformation')
                                        : setRedactionType(null);
                                    setBorderRadius('0%');
                                }}
                                type={redactionType === 'RemoveCustomerInformation' ? 'primary' : 'dashed'}
                                size="middle"
                            >
                                <FontSizeOutlined />
                            </ButtonAntd>
                        </Tooltip>
                    </AlignFlexRow>
                    <AlignFlexRow>
                        <Tooltip placement="topLeft" title="Add free text." arrowPointAtCenter>
                            <ButtonAntd
                                onClick={async () => {
                                    !redactionType || !redactionType.includes('FreeTextBox')
                                        ? setRedactionType('FreeTextBox')
                                        : setRedactionType(null);
                                    setBorderRadius('0%');
                                }}
                                type={redactionType === 'FreeTextBox' ? 'primary' : 'dashed'}
                                size="middle"
                            >
                                <TagOutlined />
                            </ButtonAntd>
                        </Tooltip>
                        <Tooltip
                            placement="topLeft"
                            title="This option allows you to edit or remove previously inserted objects (move, resize, edit, delete)"
                            arrowPointAtCenter
                        >
                            <ButtonAntd
                                onClick={async () => {
                                    !redactionType || !redactionType.includes('Edit')
                                        ? setRedactionType('Edit')
                                        : setRedactionType(null);
                                }}
                                type={redactionType === 'Edit' ? 'primary' : 'dashed'}
                                size="middle"
                            >
                                <EditOutlined />
                            </ButtonAntd>
                        </Tooltip>
                    </AlignFlexRow>
                </AlignFlexCol>

                <AlignFlexCol>
                    <Title>Rotation:</Title>
                    <Spin spinning={rotateLoading}>
                        <AlignFlexRow>
                            <Tooltip
                                placement="topLeft"
                                title="Allows you to rotate the document 90 degrees to the left"
                                arrowPointAtCenter
                            >
                                <ButtonAntd
                                    onClick={async () => {
                                        rotateDrawingFile(-90);
                                    }}
                                    size="middle"
                                >
                                    <RotateLeftOutlined />
                                </ButtonAntd>
                            </Tooltip>
                            <Tooltip
                                placement="topLeft"
                                title="Allows you to rotate the document 90 degrees to the right"
                                arrowPointAtCenter
                            >
                                <ButtonAntd
                                    onClick={async () => {
                                        rotateDrawingFile(90);
                                    }}
                                    size="middle"
                                >
                                    <RotateRightOutlined />
                                </ButtonAntd>
                            </Tooltip>
                        </AlignFlexRow>
                    </Spin>
                </AlignFlexCol>

                <AlignFlexCol>
                    <Title>Compatibility Mode:</Title>
                    <AlignFlexRow>
                        <Tooltip
                            placement="topLeft"
                            title={
                                <>
                                    Select this option to convert the PDF into a more compatible format. This will
                                    create a flat file, ensuring it can be easily opened and viewed across different
                                    devices and software versions.
                                </>
                            }
                            arrowPointAtCenter
                        >
                            <Switch checked={useFlatFile} onChange={checked => setUseFlatFile(checked)} />
                        </Tooltip>
                    </AlignFlexRow>
                </AlignFlexCol>
            </HeaderFlexRow>

            <div className="app-wrapper">
                <Spin spinning={loading}>
                    <div className={`App`} id="App" ref={pdfContainer}>
                        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            <PdfLayout
                                key={pageNumber}
                                getVisible={pageNumber === pageNumber}
                                onScroll={handleScrollForLeft}
                            >
                                <Page
                                    pageNumber={pageNumber + 1}
                                    scale={scale}
                                    canvasRef={canvasRef}
                                    onClick={(e: any) => {
                                        if (redactionType) {
                                            onPageClick(e, pageNumber + 1, 'drawing');
                                        }
                                    }}
                                >
                                    {redaction.map((item, index) =>
                                        item.redaction_type === 'DrawingTextBox' && item.page === pageNumber ? (
                                            <Draggable
                                                position={{
                                                    x: item.x0,
                                                    y: item.y0,
                                                }}
                                                handle={`.${target}`}
                                                onMouseDown={() => {
                                                    setTarget(`target${item.key}`);
                                                }}
                                                onStart={(event, data) => {
                                                    // console.log('onMouseDown', target);
                                                }}
                                                onDrag={(event, data) => {
                                                    //getCoord(event);
                                                }}
                                                onStop={(event, data) => {
                                                    // console.log(event, data);
                                                    getCoord(event);
                                                }}
                                                key={item.key}
                                                //bounds=".react-pdf__Document"
                                            >
                                                <DwgTextBoxDiv
                                                    getTarget={target}
                                                    getKey={item.key}
                                                    getWidth={Math.abs(item.x1 - item.x0)}
                                                    getHeight={Math.abs(item.y1 - item.y0)}
                                                    id={`target${item.key}`}
                                                    ref={el => (refTarget.current[index] = el)}
                                                >
                                                    <DragDwgTextBox
                                                        id={`target${item.key}`}
                                                        className={`target${item.key}`}
                                                        ref={el => (refTarget.current[index] = el)}
                                                    >
                                                        <InputTextAreaAntd
                                                            id={`target${item.key}`}
                                                            className={`target${item.key}`}
                                                            value={
                                                                textAreaRef.current
                                                                    ? textAreaRef.current.resizableTextArea.props.value
                                                                    : 'None'
                                                            }
                                                            onResize={({
                                                                width,
                                                                height,
                                                            }: {
                                                                width: number;
                                                                height: number;
                                                            }) => handleResize(width, height, true)}
                                                            getFontSize={fontSize}
                                                            getTransparent={true}
                                                            getFontColor={item.font_color ? item.font_color : '#636369'}
                                                        />
                                                    </DragDwgTextBox>
                                                    {redactionType == 'Edit' ? (
                                                        <Button
                                                            type="primary"
                                                            shape="circle"
                                                            icon={<DeleteOutlined />}
                                                            size={'small'}
                                                            onClick={() => {
                                                                handleDelete(item.name);
                                                                removeDWG();
                                                            }}
                                                            style={{ float: 'right', position: 'fixed' }}
                                                        />
                                                    ) : null}
                                                </DwgTextBoxDiv>
                                            </Draggable>
                                        ) : item.page === pageNumber ? (
                                            <Draggable
                                                handle={`.${target}`}
                                                position={{
                                                    x: item.x0,
                                                    y: item.y0,
                                                }}
                                                onMouseDown={() => {
                                                    setTarget(`target${item.key}`);
                                                }}
                                                onStart={(event, data) => {
                                                    // console.log(event, data);
                                                }}
                                                onDrag={(event, data) => {
                                                    //getCoord(event);
                                                }}
                                                onStop={(event, data) => {
                                                    // console.log(event, data);
                                                    getCoord(event);
                                                }}
                                                key={item.key}
                                                //bounds=".App"
                                            >
                                                {item.redaction_type === 'RemoveCustomerInformation' ||
                                                item.redaction_type === 'PatchCustomerInformation' ? (
                                                    <RectangleDiv
                                                        getWidth={Math.abs(item.x1 - item.x0)}
                                                        getHeight={Math.abs(item.y1 - item.y0)}
                                                        getTarget={target}
                                                        getKey={item.key}
                                                        getModeEdition={redactionType === 'Edit'}
                                                        id={`rectangleDiv${item.key}`}
                                                        onClick={() => {
                                                            if (redactionType != 'Edit') {
                                                                setRedactionType(null);
                                                            }
                                                            setSelectedRedactionKey(item.key);
                                                        }}
                                                        onDoubleClick={() => {
                                                            setRedactionType('Edit');
                                                        }}
                                                    >
                                                        <DragRectangleDiv
                                                            id={`target${item.key}`}
                                                            className={`redaction-item target${item.key}`}
                                                            ref={el => (refTarget.current[index] = el)}
                                                            getTarget={target}
                                                            getKey={item.key}
                                                        >
                                                            <InputTextAreaAntd
                                                                onResize={({
                                                                    width,
                                                                    height,
                                                                }: {
                                                                    width: number;
                                                                    height: number;
                                                                }) => handleResize(width, height, false)}
                                                                readOnly
                                                                getTransparent={false}
                                                            />
                                                        </DragRectangleDiv>
                                                        {redactionType == 'Edit' ? (
                                                            <Button
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<DeleteOutlined />}
                                                                size={'small'}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    handleDelete(item.name);
                                                                }}
                                                                style={{ float: 'right', position: 'fixed' }}
                                                            />
                                                        ) : null}
                                                    </RectangleDiv>
                                                ) : (
                                                    <RectangleDiv
                                                        getWidth={Math.abs(item.x1 - item.x0)}
                                                        getHeight={Math.abs(item.y1 - item.y0)}
                                                        getTarget={target}
                                                        getKey={item.key}
                                                        getModeEdition={redactionType === 'Edit'}
                                                        id={`rectangleDiv${item.key}`}
                                                        onClick={() => {
                                                            if (redactionType != 'Edit') {
                                                                setRedactionType(null);
                                                            }
                                                            setSelectedRedactionKey(item.key);
                                                        }}
                                                    >
                                                        {redactionType == 'Edit' ? (
                                                            <Button
                                                                type="primary"
                                                                shape="circle"
                                                                icon={<DeleteOutlined />}
                                                                size={'small'}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    handleDelete(item.name);
                                                                }}
                                                                style={{
                                                                    top: '0.3em',
                                                                    right: '0.3em',
                                                                    position: 'absolute',
                                                                    zIndex: 99,
                                                                }}
                                                            />
                                                        ) : null}
                                                        <Popover
                                                            content={
                                                                <FontTools
                                                                    displayColorPicker={displayColorPicker}
                                                                    ftFontSize={ftFontSize}
                                                                    fontColor={fontColor}
                                                                    fontBold={fontBold}
                                                                    fontItalic={fontItalic}
                                                                    setDisplayColorPicker={setDisplayColorPicker}
                                                                    setFtFontSize={setFtFontSize}
                                                                    setFontColor={setFontColor}
                                                                    setFontBold={setFontBold}
                                                                    setFontItalic={setFontItalic}
                                                                />
                                                            }
                                                            trigger="hover"
                                                            placement="topRight"
                                                        >
                                                            <DragRectangleDiv
                                                                id={`target${item.key}`}
                                                                className={`redaction-item target${item.key}`}
                                                                ref={el => (refTarget.current[index] = el)}
                                                                getTarget={target}
                                                                getKey={item.key}
                                                                onClick={() => {
                                                                    setFtFontSize(item.font_size ? item.font_size : 15);
                                                                    setFontBold(
                                                                        item.font_bold ? item.font_bold : false
                                                                    );
                                                                    setFontColor(
                                                                        item.font_color ? item.font_color : '000000'
                                                                    );
                                                                }}
                                                            >
                                                                <InputTextAreaAntd
                                                                    onChange={e => {
                                                                        saveText(e.target.value);
                                                                    }}
                                                                    onResize={({
                                                                        width,
                                                                        height,
                                                                    }: {
                                                                        width: number;
                                                                        height: number;
                                                                    }) => handleResize(width, height, false)}
                                                                    getFontSize={item.font_size ? item.font_size : 15}
                                                                    getFontColor={
                                                                        item.font_color ? item.font_color : 'black'
                                                                    }
                                                                    getFontBold={
                                                                        item.font_bold ? item.font_bold : false
                                                                    }
                                                                    getFontItalic={
                                                                        item.font_italic ? item.font_italic : false
                                                                    }
                                                                    value={item.text}
                                                                    placeholder={'Write text here...'}
                                                                    getTransparent={true}
                                                                />
                                                            </DragRectangleDiv>
                                                        </Popover>
                                                    </RectangleDiv>
                                                )}
                                            </Draggable>
                                        ) : null
                                    )}
                                    {currentRedaction[0] && redactionType && (
                                        <div
                                            onClick={event => false}
                                            style={{
                                                position: 'absolute',
                                                top:
                                                    getMousePos(event).y > currentRedaction[0].y
                                                        ? currentRedaction[0].y
                                                        : getMousePos(event).y,
                                                left:
                                                    getMousePos(event).x > currentRedaction[0].x
                                                        ? currentRedaction[0].x
                                                        : getMousePos(event).x,
                                                zIndex: 666,
                                                width:
                                                    getMousePos(event).x < currentRedaction[0].x
                                                        ? currentRedaction[0].x - getMousePos(event).x
                                                        : getMousePos(event).x - currentRedaction[0].x,
                                                height:
                                                    getMousePos(event).y < currentRedaction[0].y
                                                        ? currentRedaction[0].y - getMousePos(event).y
                                                        : getMousePos(event).y - currentRedaction[0].y,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    border: '2px red dashed',
                                                    borderRadius: borderRadius,
                                                }}
                                            />
                                        </div>
                                    )}
                                </Page>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div>
                                        {pageNumber > 0 ? (
                                            <button onClick={() => handleChangePage(pageNumber - 1)}>
                                                <LeftOutlined />
                                            </button>
                                        ) : null}
                                        Page {pageNumber + 1} of {numPages}
                                        {numPages > pageNumber + 1 ? (
                                            <button
                                                className="fa fa-cogs fa-4x"
                                                onClick={() => handleChangePage(pageNumber + 1)}
                                            >
                                                <RightOutlined />
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </PdfLayout>
                        </Document>
                    </div>
                </Spin>
            </div>
        </Container>
    );
};

export default observer(EditPdf);
