var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Select } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
export var Users = observer(function (props) {
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, size: 'small', placeholder: "Search user", dropdownMatchSelectWidth: false, style: { width: '100%' }, filterOption: function (input, option) {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        } }, props),
        props.group === 'Design Engineer' && React.createElement(Select.Option, { key: '' }, "None"),
        props.users.map(function (d) { return (React.createElement(Select.Option, { key: d.id }, d.fullname)); })));
});
