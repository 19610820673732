import { action, observable, makeObservable } from 'mobx';
import { get, patch } from '@partsbadger/utils';

class Report {
    quality_kpis: any = null;

    constructor() {
        makeObservable(this, {
            // Observables
            quality_kpis: observable,

            // Actions
            reasonsQualityKpis: action,
        });
    }

    async reasonsQualityKpis(params: any): Promise<any> {
        return await get(`/staff/report-quality-kpis/`, { params });
    }

    async updatePartQualityReport(id: number, params: any): Promise<any> {
        console.log(id);
        return await patch(`/staff/report-quality-kpis/${id}/`, params);
    }
}

export const ReportStore = new Report();
