var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
var AddItemsButton = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { loading: SalesOrderStore.loading, type: "primary", onClick: function () {
                var order_line_items = SalesOrderStore.quoted_line_items.map(function (item) {
                    var order_item = {
                        id: item.id,
                        name: item.name,
                        product_description: item.name,
                        quote_has_product: item,
                        quantity: item.quantity,
                        list_price: item.unit_price,
                        unit_price: item.unit_price,
                        total: item.total_price,
                        discount: 0,
                        total_after_discount: item.total_price,
                        taxes: 0,
                    };
                    return order_item;
                });
                var order_line_items_by_application_cost = SalesOrderStore.applicationCostItems.map(function (item) {
                    var order_item = {
                        id: item.id,
                        name: 'CNC Part',
                        product_description: item.name,
                        //@ts-ignore
                        quote_has_product: null,
                        quantity: 1,
                        list_price: parseFloat(item.price),
                        unit_price: parseFloat(item.price),
                        total: parseFloat(item.price),
                        discount: 0,
                        total_after_discount: parseFloat(item.price),
                        taxes: 0,
                    };
                    return order_item;
                });
                SalesOrderStore.addMultipleOrderItem(__spreadArray(__spreadArray([], order_line_items, true), order_line_items_by_application_cost, true));
            } },
            "Add all ( Currently added ",
            SalesOrderStore.order_items.length,
            " of ",
            SalesOrderStore.availableItemsCount,
            ' ',
            "items )"),
        React.createElement(Button, { type: "primary", onClick: function () { return props.setShowAddSpecificItems(true); } }, "Add specific items")));
};
export default observer(AddItemsButton);
