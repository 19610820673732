var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import TopicChecklist from './TopicChecklist';
import ChecklistQuestion from './ChecklistQuestion';
var PointPersonChecklistQuestion = function (_a) {
    var getFieldDecorator = _a.getFieldDecorator, question = _a.question, value = _a.value, onChange = _a.onChange, cols = _a.cols, checklistEdit = _a.checklistEdit, form = _a.form, checklistQuestions = _a.checklistQuestions, setChecklistQuestions = _a.setChecklistQuestions;
    return (React.createElement(Row, { gutter: 4 },
        React.createElement(Col, __assign({}, cols),
            React.createElement(TopicChecklist, { value: question.question })),
        React.createElement(Col, __assign({}, cols),
            React.createElement(ChecklistQuestion, { question: question, value: value, onChange: onChange, form: form, checklistQuestions: checklistQuestions, setChecklistQuestions: setChecklistQuestions, checklistEdit: checklistEdit, getFieldDecorator: getFieldDecorator }))));
};
export default PointPersonChecklistQuestion;
