import React from 'react';
import { observer } from 'mobx-react';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Chat } from '@partsbadger/library';
import { TicketStore } from '../../../stores/TicketStore';

interface MessageProps extends FormComponentProps {
    height: number;
    ticket: number; // ticket_id
    chatIsReadOnly?: boolean;
}

const TicketChat = observer((props: MessageProps) => {
    return (
        <div>
            <Chat
                form={props.form}
                height={props.height}
                messages={TicketStore.ticketMessages}
                onSendMessage={payload => {
                    TicketStore.sendMessage({
                        ...payload,
                        ticket: props.ticket,
                    });
                }}
                onlyOneAttachmentPerMessage={true}
                chatIsReadOnly={props.chatIsReadOnly}
                useCheckboxNotifyOwner={false}
            />
        </div>
    );
});

export default Form.create<MessageProps>()(TicketChat);
