import React from 'react';
import { Badge, Collapse } from 'antd';
import { QuoteStore } from '../../Stores';
export var AccountOverview = function (props) {
    var _a, _b;
    var dealStage = function () {
        var title = 'Deal Stage';
        if (props.deal_stage && props.deal_stage.includes('Closed')) {
            if (props.deal_stage === 'Closed Won') {
                title = 'Deal Won';
            }
            else {
                title = 'Deal Lost';
            }
        }
        return title;
    };
    return (React.createElement("div", { className: "p-4" },
        React.createElement(Collapse, null,
            React.createElement(Collapse.Panel, { header: React.createElement("div", null,
                    React.createElement("div", { className: "flex justify-between" },
                        React.createElement("div", { className: "flex flex-row" },
                            React.createElement("div", { className: 'font-bold' }, "Account Overview"),
                            props.flags > 0 && (React.createElement("div", { className: "flex ml-4" },
                                React.createElement(Badge, { count: props.flags })))))), key: "1" },
                React.createElement("table", { className: "border-collapse border border-gray-500 w-full" },
                    React.createElement("tbody", null,
                        ((_a = QuoteStore.quoteAccountOverview) === null || _a === void 0 ? void 0 : _a.is_first_quote_for_contact) && (React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center", colSpan: 2 }, ((_b = QuoteStore.quoteAccountOverview) === null || _b === void 0 ? void 0 : _b.is_first_quote_for_contact) && (React.createElement("div", null, "\uD83C\uDFC6 Contact's first Quote \uD83C\uDFC6"))))),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Account Name"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.account_name)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Sales Rep"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.sales_rep)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Contact Name"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.contact_name)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Contact Email"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.contact_email)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Close Rate"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                props.close_rate,
                                " %")),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Average Margin"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                props.average_margin,
                                "% (\u03C3: ",
                                props.margin_stddev,
                                ")")),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Lifetime Value"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" },
                                "$ ",
                                props.lifetime_value)),
                        React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Most Recent Deal Stage"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.most_recent_deal_stage)),
                        props.lost_reason !== '' && (React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, "Lost Reason"),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center text-red-500" }, props.lost_reason))),
                        props.deal_stage && (React.createElement("tr", null,
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 font-bold text-center" }, dealStage()),
                            React.createElement("td", { className: "border border-gray-300 py-2 px-4 text-center" }, props.deal_stage)))))))));
};
