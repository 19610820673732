import React from 'react';
import { Col, Row, Tooltip, Typography } from 'antd';
import { Price } from '@partsbadger/library';
import QuoteStore from '../../stores/QuoteStore';
var Title = Typography.Title, Text = Typography.Text;
export var CartItem = function (_a) {
    var _b, _c;
    var item = _a.item;
    var product = item.product;
    var material = item.material, finish = item.finish, coating = item.coating;
    var total_after_discount = item.total_after_discount, needs_manual_quote = item.needs_manual_quote;
    var quantities = QuoteStore.for_staff && (item === null || item === void 0 ? void 0 : item.quantities) && ((_b = item === null || item === void 0 ? void 0 : item.quantities) === null || _b === void 0 ? void 0 : _b.length) > 0
        ? (_c = item === null || item === void 0 ? void 0 : item.quantities) === null || _c === void 0 ? void 0 : _c.join(',')
        : item.quantity;
    return (React.createElement(Row, null,
        React.createElement(Col, { span: 16 },
            React.createElement(Text, { className: "font-size-md" },
                React.createElement("span", { className: "font-bold" }, product.name)),
            React.createElement("table", { className: "text-left" },
                React.createElement("tr", null,
                    React.createElement("th", { className: "pr-2" }, "Material:"),
                    React.createElement("td", null, material ? (React.createElement("span", null,
                        material.name,
                        " ",
                        material.name == 'Custom' && product.custom_material)) : (React.createElement("span", { className: "text-muted" }, "Not Selected")))),
                React.createElement("tr", null,
                    React.createElement("th", null, "Finish:"),
                    React.createElement("td", null, finish ? (React.createElement("span", null,
                        finish.name,
                        " ",
                        finish.name == 'Custom' && product.custom_finish)) : (React.createElement("span", { className: "text-muted" }, "Not Selected")))),
                React.createElement("tr", null,
                    React.createElement("th", null, "Coating:"),
                    React.createElement("td", null, coating ? (React.createElement("span", null,
                        coating.name,
                        " ",
                        coating.name == 'Custom' && product.custom_coating)) : (React.createElement("span", { className: "text-muted" }, "Not Selected")))))),
        React.createElement(Col, { span: 2, className: "text-base" },
            React.createElement(Text, { className: "font-size-md" }, quantities)),
        React.createElement(Col, { span: 6, style: {
                textAlign: 'right',
            } }, !needs_manual_quote ? (React.createElement(Text, { className: "font-size-md" },
            React.createElement(Price, { value: total_after_discount }))) : (React.createElement(Tooltip, { title: 'A PartsBadger representative will contact with you when your quote is ready' }, "Manual Quote Required")))));
};
