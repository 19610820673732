import React from 'react';
import { Button, Col, PageHeader, Popconfirm, Row, Skeleton, Spin, Typography } from 'antd';
import { observer } from 'mobx-react';

import BusinessRuleForm from '../../components/BusinessRules/BusinessRuleForm';
import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import { useHistory, useParams } from 'react-router-dom';
import TemplateVariablesView from '../../components/Templates/VariablesView';
import { ContentTypeStore } from '../../stores';

const BusinessRuleEditPage = () => {
    const { id } = useParams<{ id: string }>();

    const history = useHistory();

    const load = async (id: string) => {
        await BusinessRuleStore.getById(id);
    };

    React.useEffect(() => {
        load(id);
        ContentTypeStore.getAll();
    }, [id]);

    const rule = BusinessRuleStore.rule;

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    onBack={() => {
                        history.push('/business-rules/');
                    }}
                    title="Edit Rule"
                    extra={
                        <Popconfirm
                            placement="topLeft"
                            title={'Do you want to delete the rule permanently?'}
                            onConfirm={() => {
                                if (rule?.id) {
                                    BusinessRuleStore.delete(rule?.id);
                                    history.push('/business-rules/');
                                }
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete</Button>
                        </Popconfirm>
                    }
                />
            </Col>
            <Col span={24}>{rule ? <BusinessRuleForm rule={rule} /> : <Spin />}</Col>

            <Col md={12} offset={12} className={'mt-5'}>
                {rule?.model_name && (
                    <>
                        <Typography.Title level={2}>Variables</Typography.Title>
                        <TemplateVariablesView model_name={rule?.model_name ?? ''} />
                    </>
                )}
            </Col>
        </Row>
    );
};

export default observer(BusinessRuleEditPage);
