var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Col, Input, Row } from 'antd';
import SalesOrderStore from './Stores/SalesOrderStore';
import Part from './Part';
import _ from 'lodash';
import QuotedQuantity from './QuotedQuantity';
export var QuotedLineItems = function (props) {
    var _a = useState(''), query = _a[0], setQuery = _a[1];
    var available_quoted_line_items = SalesOrderStore.available_quoted_line_items;
    var filtered_quoted_line_items = query
        ? available_quoted_line_items.filter(function (item) {
            return item.name.toLowerCase().includes(query.toString());
        })
        : available_quoted_line_items;
    var grouped_items_by_master_product = _.groupBy(filtered_quoted_line_items, function (item) {
        return item.product.master_product;
    });
    return (React.createElement("div", null,
        React.createElement(Row, null,
            React.createElement(Col, { md: 12 },
                React.createElement(Input.Search, { placeholder: 'Search', onChange: function (e) {
                        setQuery(e.currentTarget.value);
                    } }))),
        Object.keys(grouped_items_by_master_product).map(function (key) {
            var _a, _b, _c, _d, _e, _f, _g;
            var quoted_line_items = grouped_items_by_master_product[key];
            // get the first element as header
            var fist_quoted_line_item = grouped_items_by_master_product[key][0];
            var formatted_quoted_items = quoted_line_items.map(function (quoted_item) {
                return {
                    id: quoted_item.id,
                    quote_has_product: quoted_item,
                    quantity: quoted_item.quantity,
                    unit_price: quoted_item.unit_price,
                    total_price: quoted_item.total_price,
                };
            });
            return (React.createElement("div", { className: 'mt-4', key: key },
                React.createElement("div", { className: "part" },
                    React.createElement(Part, { part: {
                            name: fist_quoted_line_item.name,
                            dimensions: (_a = fist_quoted_line_item.product) === null || _a === void 0 ? void 0 : _a.dimensions,
                            coating: (_c = (_b = fist_quoted_line_item.product) === null || _b === void 0 ? void 0 : _b.coating_related) === null || _c === void 0 ? void 0 : _c.name,
                            finish: (_e = (_d = fist_quoted_line_item.product) === null || _d === void 0 ? void 0 : _d.finish_related) === null || _e === void 0 ? void 0 : _e.name,
                            material: (_g = (_f = fist_quoted_line_item.product) === null || _f === void 0 ? void 0 : _f.material_related) === null || _g === void 0 ? void 0 : _g.name,
                        } })),
                React.createElement("div", { className: 'quotes mt-2' },
                    React.createElement("table", { className: "table-layout: auto; w-full" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { className: "ant-table-cell" }, "Qty"),
                                React.createElement("th", { className: "ant-table-cell" }, "Unit Price"),
                                React.createElement("th", { className: "ant-table-cell" }, "Total Price"),
                                React.createElement("th", { className: "ant-table-cell" }, "Actions"))),
                        React.createElement("tbody", { className: "ant-table-tbody" }, formatted_quoted_items.map(function (formatted_item) {
                            return (React.createElement("tr", { key: formatted_item.quantity },
                                React.createElement(QuotedQuantity, { quantity: formatted_item.quantity, unit_price: formatted_item.unit_price, total_price: formatted_item.total_price, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, props.handleAddItem({
                                                        id: formatted_item.id,
                                                        quote_has_product: formatted_item.quote_has_product,
                                                        quantity: formatted_item.quantity,
                                                        unit_price: formatted_item.unit_price,
                                                        total_price: formatted_item.total_price,
                                                    })];
                                                case 1:
                                                    _a.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } })));
                        }))))));
        })));
};
