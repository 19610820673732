import React, { useEffect, useState } from 'react';
import { IStepTour, Tour } from '@partsbadger/library';

/**
 * Define all steps in this array
 * Tour Key should be the name of the page in ``components/MainComponent.tsx``
 */
export const data: { [key: string]: IStepTour[] } = {
    RFQ: [
        {
            version: '1.0.5',
            target: '.step-one-true-form',
            content: 'Send a new quote!',
        },
        {
            version: '1.0.6',
            target: '.step-notifications',
            content: 'Check all your notifications in one place',
        },
    ],
    'Quote Tool': [
        {
            version: '1.0.7',
            target: '.step-reanalyze-all-files',
            content: 'Now you can reanalyze all 2d and 3d files',
        },
    ],
};

const GuidedTour = (props: { pageName: string }) => {
    const [steps, setSteps] = useState<Array<IStepTour>>([]);

    useEffect(() => {
        setSteps([]);
        setTimeout(() => {
            const found = data[props.pageName];
            if (found) {
                setSteps(found);
            }
        }, 3000);
    }, [props.pageName]);

    if (steps.length) {
        return <Tour tourKey={props.pageName} steps={steps} />;
    }
    return null;
};

export default GuidedTour;
