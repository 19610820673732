import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, Row, Select } from 'antd';
import { Accounts, Contacts, DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const QuickTurnOrdersFilter = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const params: any = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.status) {
                    params['status'] = values.status;
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');

                history.replace(`/quickturn/sales-orders/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Row>
                    <Col {...cols}>
                        <FormItem label="Search" {...formItemLayout}>
                            {getFieldDecorator('search', {
                                initialValue: query.get('search'),
                            })(<Search placeholder="Search" style={{ width: '100%' }} allowClear size={'small'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Status" {...formItemLayout}>
                            {getFieldDecorator('status', {
                                initialValue: query.get('status'),
                            })(
                                <Select allowClear size="small" showSearch>
                                    <Select.Option value="Order Received">Order Received</Select.Option>
                                    <Select.Option value="In Production">In Production</Select.Option>
                                    <Select.Option value="Shipped">Shipped</Select.Option>
                                    <Select.Option value="Cancelled">Cancelled</Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    history.push(`/quickturn/sales-orders/`);
                                    form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export const QuickTurnOrdersFilterForm = Form.create()(QuickTurnOrdersFilter);
