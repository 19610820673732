var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_ROOT } from '@partsbadger/utils';
import QuoteStore from '../../stores/QuoteStore';
export var UploadDrawing = function (_a) {
    var _b;
    var item = _a.item, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c;
    var files_2d = (((_b = item.product) === null || _b === void 0 ? void 0 : _b.attachments) || {}).files_2d;
    var defaultFileList = (files_2d === null || files_2d === void 0 ? void 0 : files_2d.id)
        ? [
            {
                uid: files_2d.id,
                name: files_2d.filename,
                status: 'done',
                response: {
                    id: files_2d.id,
                    file: files_2d.file,
                    filename: files_2d.filename,
                },
            },
        ]
        : [];
    var _d = useState(defaultFileList), fileList = _d[0], setFileList = _d[1];
    var headers = QuoteStore.token ? { Authorization: "Token ".concat(QuoteStore.token) } : {};
    var props = {
        name: 'file',
        multiple: false,
        accept: '.pdf, .dxf',
        action: "".concat(API_ROOT, "/customer/drawing-files/"),
        headers: headers,
        defaultFileList: defaultFileList,
        fileList: fileList,
        onChange: function (info) {
            var status = info.file.status;
            var fileList = info.fileList;
            fileList = fileList.slice(-1);
            //@ts-ignore
            setFileList(fileList);
            if (status === 'done') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_2d: info.file.response.id },
                });
            }
            if (status === 'removed') {
                QuoteStore.updateLineItem({
                    id: item.id,
                    product: { file_2d: null },
                });
            }
        },
    };
    if (!(item === null || item === void 0 ? void 0 : item.uid)) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Upload, __assign({ className: 'mb-2' }, props, { showUploadList: {
                showRemoveIcon: !readOnly,
            } }), !files_2d && !readOnly ? (React.createElement(Button, { className: "ant-btn-xs w-100", block: true, icon: React.createElement(UploadOutlined, null) }, "Add 2D Drawing File")) : (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
            React.createElement("span", { className: "font-weight-bold" }, "2D Drawing File:"))))));
};
