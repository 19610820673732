var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, InputNumber, Row } from 'antd';
import { ManufacturingTypes, SelectReinforcement, Formulas } from '../../components/Select';
import { GenericSelect } from '@partsbadger/library';
var ButtonGroup = Button.Group;
var formItemLayout = {
    labelCol: {
        xs: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 16 },
    },
};
var LineItemsBulkEditForm = function (props) {
    var form = props.form, handleSave = props.handleSave;
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue;
    var _a = useState(false), isSaving = _a[0], setIsSaving = _a[1];
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            var _a, _b, _c, _d;
            if (!err) {
                var payload = {
                    product: {},
                };
                try {
                    if (values.manual_adjustment_field) {
                        payload['manual_adjustment'] = values.manual_adjustment;
                    }
                    if (values.material_field) {
                        payload['product']['material'] = values.material ? values.material.key : null;
                    }
                    if (values.has_material_cert_field) {
                        payload['has_material_cert'] = values.has_material_cert === true;
                    }
                    if (values.formula_field) {
                        payload['formula'] = values.formula;
                    }
                    if (values.color_field) {
                        payload['product']['color'] = values.color ? values.color.key : null;
                    }
                    if (values.material_spec_field) {
                        payload['product']['material_spec'] = values.material_spec ? values.material_spec.key : null;
                    }
                    if (values.part_tolerance_field) {
                        payload['product']['part_tolerance'] = (_a = values.part_tolerance) === null || _a === void 0 ? void 0 : _a.key;
                    }
                    if (values.hole_tolerance_field) {
                        payload['product']['hole_tolerance'] = (_b = values.hole_tolerance) === null || _b === void 0 ? void 0 : _b.key;
                    }
                    if (values.coatings_field) {
                        payload['product']['coatings'] = [(_c = values.coatings) === null || _c === void 0 ? void 0 : _c.key];
                    }
                    if (values.finishes_field) {
                        payload['product']['finishes'] = [(_d = values.finishes) === null || _d === void 0 ? void 0 : _d.key];
                    }
                    if (values.in_house_manufacturing_field) {
                        payload['product']['in_house_manufacturing'] = values.in_house_manufacturing
                            ? values.in_house_manufacturing
                            : false;
                    }
                    if (values.sides_field) {
                        payload['product']['sides'] = values.sides ? values.sides : null;
                    }
                    if (values.design_time_field) {
                        payload['product']['design_time'] = values.design_time ? values.design_time : null;
                    }
                    if (values.quantity_field) {
                        payload['quantity'] = values.quantity ? values.quantity : null;
                    }
                    if (values.lead_time_field) {
                        payload['lead_time'] = values.lead_time ? values.lead_time : null;
                    }
                    if (values.fixed_unit_price_field) {
                        payload['fixed_unit_price'] = values.fixed_unit_price ? values.fixed_unit_price : false;
                    }
                    if (values.vendor_price_field) {
                        payload['vendor_price'] = values.vendor_price ? values.vendor_price : null;
                    }
                    if (values.discount_field) {
                        payload['discount'] = values.discount;
                    }
                    if (values.manufacturing_type_field) {
                        payload['manufacturing_type'] = values.manufacturing_type;
                    }
                    if (values.reinforcement_field) {
                        payload['reinforcement'] = values.reinforcement;
                    }
                    if (values.reinforcement_percent_field) {
                        payload['reinforcement_percent'] = values.reinforcement_percent;
                    }
                    setIsSaving(true);
                    handleSave(payload).finally(function () { return setIsSaving(false); });
                }
                catch (e) {
                    console.error(e);
                }
            }
        });
    };
    return (React.createElement(Form, { onSubmit: handleSubmit, style: { textAlign: 'center' } },
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('manufacturing_type_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Manufacturing Type' }, formItemLayout), getFieldDecorator('manufacturing_type', {
                    rules: [
                        {
                            required: getFieldValue('manufacturing_type_field'),
                            message: 'Please select a value!',
                        },
                    ],
                })(React.createElement(ManufacturingTypes, __assign({ disabled: !getFieldValue('manufacturing_type_field') }, props)))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('material_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Material' }, formItemLayout), getFieldDecorator('material', {
                    rules: [{ required: false, message: 'Please select a value!' }],
                })(React.createElement(GenericSelect, { recordType: "Material", disabled: !getFieldValue('material_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('has_material_cert_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Has Material Certification' }, formItemLayout), getFieldDecorator('has_material_cert', {
                    valuePropName: 'checked',
                    rules: [{ required: false, message: 'Please select a value!' }],
                })(React.createElement(Checkbox, { disabled: !getFieldValue('has_material_cert_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('sides_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Sides' }, formItemLayout), getFieldDecorator('sides', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(Input, { placeholder: "sides", maxLength: 1, type: 'number', disabled: !getFieldValue('sides_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('formula_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Formula' }, formItemLayout), getFieldDecorator('formula', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(Formulas, { disabled: !getFieldValue('formula_field'), item: {
                        partVol: null,
                        totalVol: null,
                        has_file_3d: undefined,
                    }, selectedMaterialName: null }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('part_tolerance_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Part Tol.' }, formItemLayout), getFieldDecorator('part_tolerance', {
                    rules: [{ required: false, message: 'Please select a value!' }],
                })(React.createElement(GenericSelect, { recordType: "PartTolerances", disabled: !getFieldValue('part_tolerance_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('hole_tolerance_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Hole Tol.' }, formItemLayout), getFieldDecorator('hole_tolerance', {
                    rules: [{ required: false, message: 'Please select a value!' }],
                })(React.createElement(GenericSelect, { recordType: "HoleTolerance", disabled: !getFieldValue('hole_tolerance_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('finishes_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Finishes' }, formItemLayout), getFieldDecorator('finishes', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(GenericSelect, { recordType: "Finish", disabled: !getFieldValue('finishes_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('coatings_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Coatings' }, formItemLayout), getFieldDecorator('coatings', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(GenericSelect, { recordType: "Coating", disabled: !getFieldValue('coatings_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('quantity_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Quantity' }, formItemLayout), getFieldDecorator('quantity', {
                    rules: [
                        {
                            required: false,
                            message: 'Please input a value!',
                        },
                    ],
                })(React.createElement(Input, { disabled: !getFieldValue('quantity_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('lead_time_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Lead Time' }, formItemLayout), getFieldDecorator('lead_time', {})(React.createElement(InputNumber, { disabled: !getFieldValue('lead_time_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('manual_adjustment_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Manual Adjustment' }, formItemLayout), getFieldDecorator('manual_adjustment', {})(React.createElement(InputNumber, { disabled: !getFieldValue('manual_adjustment_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('discount_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Discount' }, formItemLayout), getFieldDecorator('discount', {})(React.createElement(InputNumber, { disabled: !getFieldValue('discount_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('reinforcement_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Reinforcement' }, formItemLayout), getFieldDecorator('reinforcement', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(SelectReinforcement, { disabled: !getFieldValue('reinforcement_field') }))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 4 },
                React.createElement(Form.Item, null, getFieldDecorator('reinforcement_percent_field', {})(React.createElement(Checkbox, null)))),
            React.createElement(Col, { md: 20 },
                React.createElement(Form.Item, __assign({ label: 'Reinforcement Percent' }, formItemLayout), getFieldDecorator('reinforcement_percent', {
                    rules: [{ required: false, message: 'Please input a value!' }],
                })(React.createElement(Input, { disabled: !getFieldValue('reinforcement_percent_field') }))))),
        React.createElement(ButtonGroup, { className: 'right' },
            React.createElement(Button, { loading: isSaving, type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }) },
                React.createElement("span", null, " Save "))),
        React.createElement("br", null)));
};
export default Form.create()(LineItemsBulkEditForm);
