import React, { useEffect, useState } from "react";
import { Button, Col, Input, message, notification, Row, Select, Spin, Table } from "antd";
import axios from "axios"
import { fetchAccounts } from "redux/actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import {API_ROOT} from "@partsbadger/utils";



let busy = null;


const Accounts = (props) => {

    const dispatch = useDispatch();
    const accounts = useSelector(state => state.accounts);

    useEffect(() => {
        const {value} = props;
        if (value?.key && !value?.label) {
            dispatch(fetchAccounts({'search': value.key}))
        }
    }, [])

    return (
        <Select
            showSearch
            allowClear
            labelInValue
            filterOption={false}
            optionKeyProp={"value"}
            size={'small'}
            style={{width: '100%'}}
            dropdownMatchSelectWidth={false}
            placeholder="Search account by name"
            notFoundContent={accounts.isLoading ? <Spin size="small"/> : null}
            onSearch={(name) => {
                if (busy) {
                    clearTimeout(busy);
                }
                const params = {
                    'search': name,
                };

                busy = setTimeout(() => dispatch(fetchAccounts(params)), 400);
            }}
            {...props}
        >
            {accounts.entities.map(d => (
                <Select.Option key={d.id}> {d.id} || {d.name} || AE {d.account_executive} ||
                    AM {d.account_manager}</Select.Option>
            ))}
        </Select>
    )


}

export const MergeAccounts = () => {

    const [accounts, setAccounts] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [mergin, setMergin] = useState(false);
    const [account, setAccount] = useState([]);
    const [selectedAccounts, setSelectAccounts] = useState([]);

    const getAccounts = (search = "") => {

        setLoadingAccounts(true);

        axios.get(`${API_ROOT}/staff/accounts/`, {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`
            },
            params: {
                search: search
            }
        }).then(response => {
            setAccounts(response.data.results)
        }).catch((err) => {
            console.log(err);
            message.error("Error, please try again");
        }).finally(() => setLoadingAccounts(false))


    };

    const sendMergeRequest = () => {

        if (!selectedAccounts.length || !account?.key) {
            alert("Select an account");
            return
        }

        if (selectedAccounts.includes(parseInt(account?.key))) {
            alert("You select the same account")
            return;
        }
        setMergin(true);

        axios.post(`${API_ROOT}/staff/accounts/merge-accounts/`, {
            replace_accounts: selectedAccounts,
            with_account: account?.key
        }, {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`
            }
        }).then(response => {

            setAccount({});
            setAccounts([]);
            notification.success({
                message: 'The account migration request has been sent successfully, this may take a few minutes.'
            });
        }).catch((err) => {
            console.log(err);
            message.error("Error, please try again");
        }).finally(() => setMergin(false))
    }


    return (
        <div className={""}>

            <div className={"text-center"}>
                <h1>Merge Accounts </h1>
            </div>

            <Row>
                <Col xs={12}>
                    <div className={"p-5"}>
                        <h3>Accounts To be Replaced</h3>
                        <div className={"py-4"}>
                            <Input.Search placeholder={"Search"} style={{maxWidth: 320}} onSearch={(search) => {
                                getAccounts(search)
                            }}/>
                        </div>
                        <Table
                            loading={loadingAccounts}
                            columns={[
                                {
                                    title: 'Id',
                                    dataIndex: 'id',
                                    key: 'id',
                                },
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    key: 'name',
                                },
                                {
                                    title: 'Account Executive',
                                    dataIndex: 'account_executive',
                                    key: 'account_executive',
                                },
                                {
                                    title: 'Account Manager',
                                    dataIndex: 'account_manager',
                                    key: 'account_manager',
                                },
                            ]}
                            rowKey={"id"}
                            rowSelection={{
                                selectedRowKeys: selectedAccounts,
                                onChange: (keys) => {
                                    setSelectAccounts(keys)
                                }
                            }} dataSource={accounts}/>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className={"p-5"}>
                        <h3>With Account</h3>
                        <Accounts value={account} onChange={acc => setAccount(acc)}/>
                        <div className={"mt-5"}>
                            <Button loading={mergin} type={"primary"} onClick={sendMergeRequest}>Merge</Button>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )
}
