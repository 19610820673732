var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Row, message } from 'antd';
import { SaveFilled } from '@ant-design/icons';
var Option = Select.Option;
var TextArea = Input.TextArea;
var formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
export var NotesForm = function (props) {
    var _a = useState(''), title = _a[0], setTitle = _a[1];
    var _b = props.form, getFieldDecorator = _b.getFieldDecorator, getFieldValue = _b.getFieldValue, setFieldsValue = _b.setFieldsValue;
    var submitNote = function (e) {
        e.preventDefault();
        props.form.validateFields(function (err, values) {
            if (!err) {
                var payload = {
                    title: values.title,
                    content: values.content,
                };
                props.handleSubmit(payload);
            }
            else {
                message.error('Please input all required fields');
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { className: 'sales-order-form', onSubmit: submitNote },
            React.createElement(Form.Item, __assign({ label: 'Title' }, formItemLayout), getFieldDecorator('title', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a title',
                    },
                ],
            })(React.createElement(Select, { showSearch: true, placeholder: "Select a title", optionFilterProp: "children", style: { width: '100%' } },
                React.createElement(Option, { value: "Production Update" }, "Production Update"),
                React.createElement(Option, { value: "Rep Note" }, "Rep Note"),
                React.createElement(Option, { value: "General" }, "General")))),
            React.createElement(Form.Item, __assign({ label: 'Content' }, formItemLayout), getFieldDecorator('content', {
                rules: [
                    {
                        required: true,
                        message: 'Please insert a content',
                    },
                ],
            })(React.createElement(TextArea, { rows: 4 }))),
            React.createElement(Row, { className: 'row-order mt-5' },
                React.createElement("div", { style: { textAlign: 'center' } },
                    React.createElement(Button, { type: "primary", htmlType: 'submit' },
                        React.createElement(SaveFilled, null),
                        " Save Note"))))));
};
export default Form.create()(NotesForm);
