var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Collapse, Row, Col, Card, notification, Typography, Spin } from 'antd';
import { QuoteStore } from '../Stores';
import { ApplicationCostItems, QuoteActions, QuoteNotes, QuoteNotifications, QuoteStatus, QuoteTotals, RFQView, TooltipFlag, } from '../components';
import { MasterProductDetail } from '../components/MasterProduct/index';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
import dayjs from 'dayjs';
import { AccountOverview, RfqInformation, RfqOverview } from '../components/Collapses';
import DynamicQuantitiesSwitcher from '../components/DynamicQuantitiesSwitcher';
import { patch } from '@partsbadger/utils';
import { useLocation } from 'react-router-dom';
var Paragraph = Typography.Paragraph;
var ButtonGroup = Button.Group;
var LayoutV1 = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
    var location = useLocation();
    var _22 = useState(false), loading = _22[0], setLoading = _22[1];
    var _23 = useState(false), loadingAllDynamicQuatities = _23[0], setLoadingAllDynamicQuatities = _23[1];
    var quote = props.quote, selectedLineItems = props.selectedLineItems, setSelectedLineItems = props.setSelectedLineItems, setShowBulkEdit = props.setShowBulkEdit, line_items = props.line_items, account_detail = props.account_detail, setPreviewQuote = props.setPreviewQuote, additional_requirements = props.additional_requirements, setAdditionalRequirements = props.setAdditionalRequirements, setReorderingAppCost = props.setReorderingAppCost;
    var line_items_dynamic_quote = QuoteStore.lineItems.filter(function (item) { return item.has_dynamic_quantities; });
    var line_items_no_dynamic_quote = QuoteStore.lineItems.filter(function (item) { return !item.has_dynamic_quantities; });
    var onChangeDynamicQuantities = function (enabled, items) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingAllDynamicQuatities(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, patch("/staff/quotes/".concat(quote.id, "/update-multiple/"), {
                            quoted_products: items,
                            data: { has_dynamic_quantities: enabled },
                        })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.recalculatePrices({ quoted_products: items })];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.getLineItems(quote.id)];
                case 4:
                    _a.sent();
                    setLoadingAllDynamicQuatities(false);
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    QuoteStore.error = error_1;
                    setLoadingAllDynamicQuatities(false);
                    return [3 /*break*/, 6];
                case 6:
                    QuoteStore.getQuote(quote.id);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (location.hash) {
            var targetElement = document.getElementById(location.hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { justify: "center" },
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(RfqOverview, { quote_type: quote.type, rfq_submission_method: ((_a = QuoteStore.rfq) === null || _a === void 0 ? void 0 : _a.submission_method) || '', rfq_submission_date: QuoteStore.rfq ? dayjs(QuoteStore.rfq.created_time).format('LLL') : '', quote_number: quote.id, rfq_number: QuoteStore.rfq ? (_b = QuoteStore.rfq) === null || _b === void 0 ? void 0 : _b.id : null, rfq_status: ((_c = QuoteStore.rfq) === null || _c === void 0 ? void 0 : _c.status) || '', quote_design_engineer: ((_d = quote.design_engineer) === null || _d === void 0 ? void 0 : _d.fullname) || '', quote_custom_rfq_reference: ((_f = (_e = QuoteStore.rfq) === null || _e === void 0 ? void 0 : _e.quote) === null || _f === void 0 ? void 0 : _f.customer_rfq_reference) || '', rfq_shipping_name: ((_g = QuoteStore.rfq) === null || _g === void 0 ? void 0 : _g.shipping_name) || '', rfq_shipping_street: ((_h = QuoteStore.rfq) === null || _h === void 0 ? void 0 : _h.shipping_street) || '', rfq_shipping_state: ((_j = QuoteStore.rfq) === null || _j === void 0 ? void 0 : _j.shipping_state) || '', rfq_shipping_country: ((_k = QuoteStore.rfq) === null || _k === void 0 ? void 0 : _k.shipping_country) || '', rfq_shipping_zip_code: ((_l = QuoteStore.rfq) === null || _l === void 0 ? void 0 : _l.shipping_zip_code) || '' })),
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(AccountOverview, { flags: QuoteStore.flagsAccountOverview, account_name: (account_detail === null || account_detail === void 0 ? void 0 : account_detail.name) || '', sales_rep: ((_m = quote.owner) === null || _m === void 0 ? void 0 : _m.fullname) || '', contact_name: ((_o = quote.contact) === null || _o === void 0 ? void 0 : _o.fullname) || '', contact_email: ((_p = quote.contact) === null || _p === void 0 ? void 0 : _p.email) || '', close_rate: ((_q = QuoteStore.quoteAccountOverview) === null || _q === void 0 ? void 0 : _q.close_rate) || 0, average_margin: ((_r = QuoteStore.quoteAccountOverview) === null || _r === void 0 ? void 0 : _r.average_margin) || 0, margin_stddev: ((_s = QuoteStore.quoteAccountOverview) === null || _s === void 0 ? void 0 : _s.margin_stddev) || 0, lifetime_value: ((_u = (_t = QuoteStore.quoteAccountOverview) === null || _t === void 0 ? void 0 : _t.lifetime_value) === null || _u === void 0 ? void 0 : _u.toLocaleString()) || '', most_recent_deal_stage: ((_w = (_v = QuoteStore.quote) === null || _v === void 0 ? void 0 : _v.deal) === null || _w === void 0 ? void 0 : _w.stage) || '', lost_reason: ((_x = quote.deal) === null || _x === void 0 ? void 0 : _x.lost_reason) || '', deal_stage: ((_z = (_y = QuoteStore.quote) === null || _y === void 0 ? void 0 : _y.deal) === null || _z === void 0 ? void 0 : _z.stage) || null })),
            React.createElement(Col, { xs: 24, sm: 8 },
                React.createElement(RfqInformation, { flags: QuoteStore.flagsRfqInformation, rfq_account_name: ((_1 = (_0 = QuoteStore.rfq) === null || _0 === void 0 ? void 0 : _0.account) === null || _1 === void 0 ? void 0 : _1.name) || '', quote_has_batch_shipments: quote.has_batch_shipments, quote_whole_quote_adjust: quote.whole_quote_adjust, rfq_other_notes: ((_2 = QuoteStore.rfq) === null || _2 === void 0 ? void 0 : _2.other_notes) || '', quote_customer_statement: ((_4 = (_3 = QuoteStore.rfq) === null || _3 === void 0 ? void 0 : _3.quote) === null || _4 === void 0 ? void 0 : _4.customer_statement) || '', rfq_itar: ((_5 = QuoteStore.rfq) === null || _5 === void 0 ? void 0 : _5.itar) || false, rfq_needs_to_create_2d_files: ((_6 = QuoteStore.rfq) === null || _6 === void 0 ? void 0 : _6.needs_to_create_2d_files) || false, rfq_needs_to_create_3d_files: ((_7 = QuoteStore.rfq) === null || _7 === void 0 ? void 0 : _7.needs_to_create_3d_files) || false, quote_custom_requirements: ((_9 = (_8 = QuoteStore.rfq) === null || _8 === void 0 ? void 0 : _8.quote) === null || _9 === void 0 ? void 0 : _9.custom_requirements) || [], quote_customer_supplied_material: ((_11 = (_10 = QuoteStore.rfq) === null || _10 === void 0 ? void 0 : _10.quote) === null || _11 === void 0 ? void 0 : _11.customer_supplied_material) || false, quote_domestic_quoting: ((_13 = (_12 = QuoteStore.rfq) === null || _12 === void 0 ? void 0 : _12.quote) === null || _13 === void 0 ? void 0 : _13.domestic_quoting) || false, quote_requote_changes: ((_15 = (_14 = QuoteStore.rfq) === null || _14 === void 0 ? void 0 : _14.quote) === null || _15 === void 0 ? void 0 : _15.requote_changes) || [], rfq_custom_additional_requirements: ((_16 = QuoteStore.rfq) === null || _16 === void 0 ? void 0 : _16.custom_additional_requirements) || '', rfq_target_lead_time: ((_17 = QuoteStore.rfq) === null || _17 === void 0 ? void 0 : _17.target_lead_time) || '' }))),
        ((_18 = quote.rfq) === null || _18 === void 0 ? void 0 : _18.id) && (React.createElement("div", { className: 'm-4' },
            React.createElement(Collapse, { accordion: true },
                React.createElement(Collapse.Panel, { header: React.createElement("div", { className: 'font-bold' }, "RFQ Products"), key: "1" },
                    React.createElement("div", null,
                        React.createElement(RFQView, { rfq_id: (_19 = quote.rfq) === null || _19 === void 0 ? void 0 : _19.id })))))),
        React.createElement("div", { className: 'quote-actions flex justify-end' },
            React.createElement("div", { className: "flex items-center mr-6" }, line_items.length > 0 && (React.createElement(React.Fragment, null,
                loadingAllDynamicQuatities && (React.createElement(Spin, { tip: React.createElement("span", { className: "text-xs" }, "Dynamic Quantities For All..."), size: "small" })),
                React.createElement("div", { className: "col-dynamic-quantities ml-4" },
                    //if all line items have dynamic quantities, show the switcher enabled
                    line_items_dynamic_quote.length === line_items.length && (quote === null || quote === void 0 ? void 0 : quote.editable) && (React.createElement(DynamicQuantitiesSwitcher, { initialValue: true, label: 'Dynamic Quantities For All Products', handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                            var items;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        items = line_items_dynamic_quote.map(function (item) { return item.id; });
                                        return [4 /*yield*/, onChangeDynamicQuantities(enabled, items)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })),
                    // if any line item have not dynamic quantities, show the switcher disabled
                    line_items_dynamic_quote.length < line_items.length && (quote === null || quote === void 0 ? void 0 : quote.editable) && (React.createElement(DynamicQuantitiesSwitcher, { initialValue: false, label: 'Dynamic Quantities For All Products', handleUpdate: function (enabled) { return __awaiter(void 0, void 0, void 0, function () {
                            var items;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        items = line_items_no_dynamic_quote.map(function (item) { return item.id; });
                                        return [4 /*yield*/, onChangeDynamicQuantities(enabled, items)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } })))))),
            quote.editable && QuoteStore.inProgress && (React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'check-square' }), title: 'Shortcut + A', style: { display: selectedLineItems.length === 0 ? 'inline' : 'none' }, onClick: function () {
                        setSelectedLineItems(QuoteStore.lineItems.map(function (p) { return p.id; }));
                    } }, "Select All"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'form' }), title: 'Shortcut + E', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: setShowBulkEdit }, "Bulk Edit"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'paper-clip' }), title: 'Shift + R', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, loading: QuoteStore.loading, onClick: function () {
                        var payload = {
                            quoted_products: selectedLineItems,
                        };
                        QuoteStore.recalculatePrices(payload).catch(function (err) {
                            notification.error({
                                key: 'form-errors',
                                duration: 8,
                                message: 'One of the line items has an error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'top',
                                maxCount: 1,
                            });
                        });
                    } }, "Recalculate Prices"),
                React.createElement(Button, { icon: React.createElement(LegacyIcon, { type: 'minus-square' }), title: 'Escape', style: { display: selectedLineItems.length > 0 ? 'inline' : 'none' }, onClick: function () { return setSelectedLineItems([]); } }, "Clear Selection")))),
        React.createElement("div", { className: 'quote-line-items' }, line_items === null || line_items === void 0 ? void 0 : line_items.map(function (item, index) {
            return (React.createElement(Card, { id: "div-mp-".concat(item.master_product.id), key: "mp-".concat(item.master_product.name, "-").concat(index), style: {
                    padding: 0,
                }, bodyStyle: {
                    padding: 0,
                } },
                React.createElement(MasterProductDetail, { count: index, position: item.position, key: item.master_product.id, master_product: item.master_product, setSelectedLineItems: function (items) { return setSelectedLineItems(items); }, selectedLineItems: selectedLineItems })));
        })),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 24 },
                React.createElement("div", { className: 'my-2' }, account_detail && (React.createElement(ApplicationCostItems, { quote: quote, account: account_detail, setReorderingAppCost: setReorderingAppCost }))))),
        React.createElement(Row, { className: 'quote-additional-cost' },
            React.createElement(Col, { xs: 12 },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'font-bold' },
                        React.createElement("span", null, "Additional Requirements"),
                        React.createElement(TooltipFlag, { field_name: 'custom_requirements' })),
                    React.createElement("div", { className: 'mt-4', style: {
                            maxWidth: 800,
                        } },
                        React.createElement(AdditionalRequirements
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            requirements: QuoteStore.additional_requirements, onChange: function (values) {
                                if (quote.editable) {
                                    //@ts-ignore
                                    setAdditionalRequirements(values);
                                    QuoteStore.updateQuote(quote.id, {
                                        custom_requirements: values,
                                    });
                                }
                            }, 
                            //@ts-ignore
                            value: additional_requirements, hide_extras: false, disabled: !quote.editable })))),
            React.createElement(Col, { xs: 12 }, ((_20 = props.user) === null || _20 === void 0 ? void 0 : _20.group) && ((_21 = props.user) === null || _21 === void 0 ? void 0 : _21.group.includes('Design Engineers')) && (React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'font-bold' }, "Internal Quoting Notes"),
                React.createElement("div", { className: 'mt-4', style: {
                        maxWidth: 800,
                    } },
                    React.createElement(Paragraph, { className: 'text-left', style: {
                            margin: 0,
                        }, editable: {
                            onChange: function (notes) {
                                var payload = {
                                    internal_quoting_notes: notes,
                                };
                                setLoading(true);
                                QuoteStore.updateQuote(quote.id, payload)
                                    .then(function () {
                                    setLoading(false);
                                })
                                    .catch(function () {
                                    setLoading(false);
                                });
                            },
                        } }, quote === null || quote === void 0 ? void 0 : quote.internal_quoting_notes)))))),
        React.createElement(Row, { className: 'quote-notes p-4' },
            React.createElement(Col, { xs: 24 },
                React.createElement(QuoteNotes, null))),
        React.createElement(Row, { className: 'flex space-between flex-wrap quote-totals' },
            React.createElement("div", { className: "md:w-3/4" },
                React.createElement(QuoteNotifications, { height: 600 })),
            React.createElement("div", { className: "md:w-1/4" },
                React.createElement(QuoteTotals, { quote: quote }))),
        React.createElement(QuoteStatus, { quote: quote, user: props.user, isQuoteLoading: QuoteStore.loading }),
        React.createElement(QuoteActions, { loading: QuoteStore.loading, quote: quote, handlePreviewQuote: setPreviewQuote })));
};
export default observer(LayoutV1);
