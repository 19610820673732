import { Table } from 'antd';
import React from 'react';
import { DateRender } from '@partsbadger/library';
var VendorQuote = function (_a) {
    var quotes = _a.quotes;
    return (React.createElement(Table, { rowKey: 'id', dataSource: quotes, pagination: false },
        React.createElement(Table.Column, { title: "Vendor", dataIndex: "vendor_name", key: "vendor_name" }),
        React.createElement(Table.Column, { title: "Date", dataIndex: "acceptance_date", key: "acceptance_date", render: function (t) {
                return React.createElement(DateRender, { value: t });
            } }),
        React.createElement(Table.Column, { title: "Material", dataIndex: "material", key: "material" }),
        React.createElement(Table.Column, { title: "Surface Finish", dataIndex: "finish", key: "finish" }),
        React.createElement(Table.Column, { title: "Coating", dataIndex: "coating", key: "coating" }),
        React.createElement(Table.Column, { title: "Qty", dataIndex: "quantity", key: "quantity" }),
        React.createElement(Table.Column, { title: "Unit Price", dataIndex: "vendor_price", key: "vendor_price" }),
        React.createElement(Table.Column, { title: "Lead Time", dataIndex: "lead_time", key: "lead_time" }),
        React.createElement(Table.Column, { title: "Notes", dataIndex: "vendor_notes", key: "vendor_notes" }),
        React.createElement(Table.Column, { title: "Modified By", dataIndex: "modified_by", key: "modified_by" })));
};
export default VendorQuote;
