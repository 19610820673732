import React, {Fragment} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

interface Props {
    visible: boolean
}

const LoadingComponent = ({visible}: Props) => {
    return visible ? <Spin indicator={antIcon} size="large"/> : null
}

export default LoadingComponent;
