import React, {useState} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, message } from "antd";
import {post} from "shared";
import {GenericSelect, Industries} from "@partsbadger/library";

export const UpdateHTSOnOrder = Form.create()((props) => {

    const {form} = props;
    const [loading, setLoading] = useState(false);
    const {getFieldDecorator} = form;

    const handleSubmit = (e) => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                setLoading(true);

                const payload = {
                    sales_orders: values.sales_orders.map(item => item.key),
                    industry_use: values?.industry_use.use.key,
                    end_use: values?.industry_use.endUse,
                };

                post(`staff/sales-orders/hts/`, payload).then(() => {
                    setLoading(false);
                    message.success('Updated')
                    form.resetFields();
                }).catch(error => {
                    alert(error);
                    setLoading(false);
                });

            }
        });

    };


    return (

        <div style={{maxWidth: "800px", margin: "auto"}}>
            <Divider>
                <div className={"title"}> Update HTS Codes</div>
            </Divider>

            <div style={{textAlign: "center"}}>Use the form below to update the hts code of existing sales orders.
            </div>
            <Form>
                <Form.Item label={"Sales Orders"}>
                    {getFieldDecorator('sales_orders', {
                        rules: [
                            {
                                required: true,
                                message: "Required"
                            }
                        ]
                    })(
                        <GenericSelect recordType={"SalesOrders"} mode={"multiple"} filters={{
                            in_zoho: true
                        }} />
                    )}
                </Form.Item>

                <Form.Item label={"Industry/Use"}>
                    {getFieldDecorator('industry_use', {
                        rules: [
                            {
                                required: true,
                                message: "Industry and Use are Required"
                            }
                        ]
                    })(
                        <Industries />
                    )}
                </Form.Item>

                <div style={{
                    marginTop: 20,
                    textAlign: 'center'
                }}>
                    <Button type={"primary"} loading={loading} onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    )

});
