import styled from 'styled-components';
import { Input, Button, InputNumber } from 'antd';
import { getTwoToneColor, DownloadOutlined } from '@ant-design/icons';

interface IProps {
    getTarget?: string;
    getKey?: number;
    getWidth?: number;
    getHeight?: number;
    getFontSize?: number;
    getVisible?: boolean;
    getModeEdition?: boolean;
    getX?: number;
    getY?: number;
    getFontColor?: string;
    getFontBold?: boolean;
    getFontItalic?: boolean;
    getTransparent?: boolean;
}

export const Container = styled.div.attrs({})``;

export const ButtonAntd = styled(Button).attrs({
    className: 'mr-1 ml-1 align-middle',
})`
    box-shadow: 0 4px 22px 0 rgba(71, 69, 69, 0.178);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const HeaderFlexRow = styled.div.attrs({
    className: 'header flex flex-row',
})``;

export const AlignFlexCol = styled.div.attrs({
    className: 'flex flex-col items-center ml-5',
})``;

export const CenterFlexCol = styled.div.attrs({
    className: 'flex flex-col items-center ml-5',
})`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: transparent;
    padding: 10px;
    z-index: 1;
`;

export const Title = styled.p.attrs({
    className: 'text-base m-0 ml-5 font-bold',
})``;

export const AlignFlexRow = styled.div.attrs({
    className: 'flex flex-row items-center',
})``;

export const InputNumberAntd = styled(InputNumber).attrs({})``;

export const PdfLayout = styled.div.attrs((props: IProps) => ({
    className: `single_page mt-5 ${props.getKey}`,
    id: `single_page${props.getKey}`,
    style: {
        width: '100%',
        height: '100%',
        background: '#f0f0f0',
        overflow: 'auto',
        border: '4px solid red',
        borderRadius: '5px',
        display: props.getVisible ? 'block' : 'none',
    },
}))<IProps>``;

export const DwgTextBoxDiv = styled.div.attrs((props: IProps) => ({
    style: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: props.getWidth || '280px',
        height: props.getHeight || '300px',
        overflow: 'hidden',
        resize: 'both',
        textAlign: 'center',
        display: 'flex',
        padding: '0px',
        border: '1.5px #1282A2 dotted',
        borderRadius: '0px',
        borderColor: props.getTarget === `target${props.getKey}` ? '#1282A2' : '#aeb0b3',
        boxShadow: props.getTarget === `target${props.getKey}` ? '0 0 10px #787a77' : 'none',
        backgroundColor: 'transparent',
    },
    className: 'flex flex-col',
}))<IProps>``;

export const DragDwgTextBox = styled.div.attrs({
    style: {
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        cursor: 'move',
        outline: 'none',
        fontSize: '0.7rem',
        resize: 'none',
        border: '0px',
        padding: '0px',
    },
})``;

export const InputTextAreaAntd = styled(Input.TextArea).attrs((props: IProps) => ({
    style: {
        height: '100%',
        widh: '100%',
        cursor: 'auto',
        outline: 'none',
        fontSize: props.getFontSize || 'px',
        color: props.getFontColor,
        overflow: 'hidden',
        resize: 'none',
        fontWeight: props.getFontBold ? 'bold' : 'normal',
        fontStyle: props.getFontItalic ? 'italic' : 'normal',
        background: props.getTransparent ? 'transparent' : 'withe',
        border: '0px',
        borderTopStyle: 'hidden',
        padding: '0px',
    },
}))<IProps>``;

export const RectangleDiv = styled.div.attrs((props: IProps) => ({
    style: {
        width: props.getWidth || 'px',
        height: props.getHeight || 'px',
        position: 'absolute',
        top: props.getY || '0px',
        left: props.getX || '0px',
        overflow: 'hidden',
        resize: 'both',
        cursor: props.getTarget === `target${props.getKey}` ? 'move' : 'auto',
        outline: 'none',
        border: props.getModeEdition ? '2px red solid' : 'none',
        boxShadow: props.getTarget === `target${props.getKey}` ? '0 0 10px #020700' : 'none',
        backgroundColor: 'transparent',
    },
    className: 'flex flex-col',
}))<IProps>`
    box-shadow: 0 4px 22px 0 rgba(71, 69, 69, 0.178);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.18);
`;

export const DragRectangleDiv = styled.div.attrs((props: IProps) => ({
    style: {
        height: '100%',
        cursor: props.getTarget === `target${props.getKey}` ? 'move' : 'auto',
        border: 'none',
        background: 'transparent',
    },
}))<IProps>``;

export const DeleteButtonAntd = styled(Button).attrs({
    style: {
        position: 'absolute',
        top: 0,
        right: 1,
        fontSize: '18px',
    },
    shape: 'circle',
})``;

export const BalloonDiv = styled.div.attrs((props: IProps) => ({
    style: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: props.getWidth,
        height: props.getHeight,
        backgroundColor: 'white',
        border: '2px red solid',
        borderRadius: '100%',
        cursor: props.getTarget === `target${props.getKey}` ? 'move' : 'auto',
        outline: 'none',
        borderColor: props.getTarget === `target${props.getKey}` ? '#fa000034' : '#f8280371',
        boxShadow: props.getTarget === `target${props.getKey}` ? '0 0 10px #e73306' : 'none',
        overflow: 'hidden',
        resize: props.getTarget === `target${props.getKey}` ? 'both' : 'none',
        textAlign: 'center',
        display: 'flex',
        paddingTop: '5px',
    },
    className: 'flex flex-col',
}))<IProps>``;

export const DragBalloonDiv = styled.div.attrs((props: IProps) => ({
    style: {
        fontWeight: 'bold',
        cursor: props.getTarget === `target${props.getKey}` ? 'move' : 'auto',
        height: '99%',
        width: '99%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '5px',
        paddingLeft: '6px',
    },
}))<IProps>``;

export const BalloonInputAntd = styled(Input).attrs({
    className: 'w-full h-5 text-xs',
    outline: 'none',
    border: 'none',
})``;

export const BallonDeleteButtonAntd = styled(Button).attrs({
    style: {
        position: 'absolute',
        top: 1,
        background: 'transparent',
        borderColor: 'transparent',
    },
    className: 'flex justify-center items-center w-5 h-3',
})``;

export const ColorDivAntd = styled.div.attrs((props: IProps) => ({
    style: {
        className: 'mr-1 ml-1 align-middle',
        width: '35px',
        height: '26px',
        borderRadius: '2px',
        background: props.getFontColor ? props.getFontColor : 'withe',
    },
}))<IProps>``;

export const SwatchDivAntd = styled.div.attrs((props: IProps) => ({
    style: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        marginTop: '5px',
        marginLeft: '5px',
    },
}))<IProps>``;

export const PopoverDivAntd = styled.div.attrs((props: IProps) => ({
    style: {
        position: 'absolute',
        zIndex: '2',
    },
}))<IProps>``;

export const CoverDivAntd = styled.div.attrs((props: IProps) => ({
    style: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}))<IProps>``;
