import * as ActionTypes from "../ActionTypes";
import {get, post} from '../../shared/api'
import axios from 'axios';
import {BASE_URL} from "constants/app";
import {UserStore} from "../../stores/UserStore";


export const login = (username, password) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.USER_LOADING});
        return axios.post(BASE_URL + "/rest-auth/login/", {
            "email": username,
            "password": password
        }).then(response => {
            dispatch({type: ActionTypes.LOGIN_SUCCESSFUL, data: response.data});
            UserStore.loadUser()
        }).catch(error => dispatch({type: ActionTypes.LOGIN_FAILED, error: error}));
    }
}

export const loginWithGoogle = (payload) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.USER_LOADING});
        return axios.post(BASE_URL + "/auth/google", payload).then(response => {
            dispatch({type: ActionTypes.LOGIN_SUCCESSFUL, data: response.data});
            UserStore.loadUser()
        }).catch(error => dispatch({type: ActionTypes.LOGIN_FAILED, error: error}));
    }
}

export const sendResetPassword = (payload) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.USER_LOADING});
        return post("rest-auth/password/reset/", payload).then(response => {
            alert("We've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly")
        }).catch(error => alert("Error"));
    }
}

export const fetchUsers = (params) => {
    return (dispatch, getState) => {
        // dispatch({type: ActionTypes.USERS_LOADING});
        return get("staff/users/", params).then(response => {
            dispatch({type: ActionTypes.USERS_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.USERS_ERROR, error: error}));
    }
}


