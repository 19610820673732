var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Pill = function (_a) {
    var label = _a.label, checked = _a.checked, onClick = _a.onClick;
    var pillStyles = {
        border: '1px solid #1da1f2',
        borderRadius: '24px',
        color: '#1c94e0',
        padding: 10,
        margin: '5px',
        cursor: 'pointer',
        backgroundColor: '#fff',
    };
    if (checked) {
        pillStyles = __assign(__assign({}, pillStyles), { backgroundColor: '#1c94e0', color: '#fff' });
    }
    return (React.createElement("span", { style: pillStyles, onClick: onClick }, label));
};
export default Pill;
