var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
export var Processes = function (props) {
    return (React.createElement(Select, __assign({ size: (props === null || props === void 0 ? void 0 : props.for_staff) ? 'middle' : 'small', dropdownMatchSelectWidth: false, bordered: true }, props, { disabled: props.disabled ? true : false }),
        React.createElement(Select.Option, { value: "CNC" }, "CNC Machining"),
        React.createElement(Select.Option, { value: "3D PRINTING" }, "3D Printing"),
        React.createElement(Select.Option, { value: "Sheet Metal" }, "Sheet Metal")));
};
