var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import PartStore from '../stores/PartStore';
import { PDFDocument } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import { Row, Avatar, Card, Col, Button } from 'antd';
import { DeleteOutlined, BorderHorizontalOutlined, ScissorOutlined, ClearOutlined, CloudDownloadOutlined, CloseOutlined, } from '@ant-design/icons';
var SplitPDF = function (props) {
    var _a = React.useState(Number), totalPages = _a[0], setTotalPages = _a[1];
    var _b = React.useState(), urlFile = _b[0], setUrlFile = _b[1];
    var _c = React.useState(''), fileName = _c[0], setFileName = _c[1];
    var _d = React.useState([]), allPages = _d[0], setAllPages = _d[1];
    var pageRef = React.useRef();
    React.useEffect(function () {
        loadPDF();
    }, [props.pdfFile]);
    React.useEffect(function () {
        loadStructure();
    }, [totalPages]);
    function loadPDF() {
        return __awaiter(this, void 0, void 0, function () {
            var buffer, pdfDoc, _a, _b, pages_count;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        buffer = props.pdfFile.arrayBuffer();
                        _b = (_a = PDFDocument).load;
                        return [4 /*yield*/, buffer];
                    case 1: return [4 /*yield*/, _b.apply(_a, [_c.sent()])];
                    case 2:
                        pdfDoc = _c.sent();
                        pages_count = pdfDoc.getPageCount();
                        setUrlFile(URL.createObjectURL(props.pdfFile));
                        setTotalPages(pages_count);
                        setFileName(props.pdfFile.name);
                        return [2 /*return*/];
                }
            });
        });
    }
    function loadStructure() {
        var initial_pages = [];
        __spreadArray([], Array(totalPages), true).map(function (x, i) {
            return initial_pages.push({
                pageNumber: i + 1,
                status: 'active',
            });
        });
        setAllPages(initial_pages);
    }
    function updatePageStatus(page_number, status) {
        var updated_pages = allPages.map(function (obj) {
            if (obj.pageNumber === page_number) {
                var newStatus = status === obj.status ? 'active' : status;
                return __assign(__assign({}, obj), { status: newStatus });
            }
            return obj;
        });
        setAllPages(updated_pages);
    }
    function splitAllPages() {
        var updated_pages = allPages.map(function (obj) {
            return __assign(__assign({}, obj), { status: 'splitted' });
        });
        setAllPages(updated_pages);
    }
    function resetAllPages() {
        var updated_pages = allPages.map(function (obj) {
            return __assign(__assign({}, obj), { status: 'active' });
        });
        setAllPages(updated_pages);
    }
    function generateStructure() {
        var splitPages = [];
        var documents = [];
        allPages.map(function (page) {
            if (page.status != 'deleted') {
                splitPages.push(page.pageNumber - 1);
                if (page.status === 'splitted' || page.pageNumber === totalPages) {
                    var document_1 = {
                        idDocument: documents.length,
                        pages: splitPages,
                    };
                    documents.push(document_1);
                    splitPages = [];
                }
            }
        });
        return documents;
    }
    function exportDocuments() {
        return __awaiter(this, void 0, void 0, function () {
            var documents, srcDoc, _a, _b, count;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, generateStructure()];
                    case 1:
                        documents = _c.sent();
                        _b = (_a = PDFDocument).load;
                        return [4 /*yield*/, props.pdfFile.arrayBuffer()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [_c.sent()])];
                    case 3:
                        srcDoc = _c.sent();
                        count = 0;
                        documents.map(function (document) { return __awaiter(_this, void 0, void 0, function () {
                            var PDF, copiedPages, PDFFile;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, PDFDocument.create()];
                                    case 1:
                                        PDF = _a.sent();
                                        return [4 /*yield*/, PDF.copyPages(srcDoc, document.pages)];
                                    case 2:
                                        copiedPages = _a.sent();
                                        copiedPages.map(function (page) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                PDF.addPage(page);
                                                return [2 /*return*/];
                                            });
                                        }); });
                                        return [4 /*yield*/, PDF.save()];
                                    case 3:
                                        PDFFile = _a.sent();
                                        PartStore.addPart(new File([PDFFile], ++count + '-' + fileName, { type: 'application/pdf' }));
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        props.handleReset();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement("div", { className: "mb-2", style: {
            marginBottom: '5px',
            padding: '10px',
            border: '1px solid #e2e8f0',
        } },
        React.createElement("div", null,
            React.createElement(Button, { style: { float: 'right' }, onClick: function () { return props.handleReset(); }, icon: React.createElement(CloseOutlined, null), type: "link" }, ' '),
            React.createElement("p", { className: "pb-2", style: { textAlign: 'center', fontSize: '1.6rem', fontWeight: 600 } }, "Split PDF files"),
            React.createElement("p", { style: { textAlign: 'center', color: 'rgba(0, 0, 0, 0.45)' } }, "Easy tool to split PDF files by pages.")),
        React.createElement("div", { className: "mb-2", style: {
                marginBottom: '5px',
                padding: '10px',
            } },
            React.createElement(Button, { onClick: function () { return resetAllPages(); }, icon: React.createElement(ClearOutlined, null), type: "link" }, "Reset All"),
            React.createElement(Button, { onClick: function () { return splitAllPages(); }, icon: React.createElement(ScissorOutlined, null), type: "link" }, "Split All"),
            React.createElement(Button, { onClick: function () { return exportDocuments(); }, icon: React.createElement(CloudDownloadOutlined, null), type: "primary", style: { float: 'right' } }, "Add Parts")),
        React.createElement("div", { className: "main", style: {
                marginBottom: '5px',
                padding: '10px',
                border: '1px solid #e2e8f0',
            } },
            React.createElement(Document, { file: urlFile },
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { gutter: [16, 16] }, allPages.map(function (page) { return (React.createElement(React.Fragment, null,
                        React.createElement(Col, { className: "gutter-row", span: 5 },
                            React.createElement(Card, __assign({}, (page.status === 'splitted'
                                ? { width: 250, style: { borderRight: '5px dotted red' } }
                                : { width: 250 }), { cover: React.createElement("div", __assign({ ref: pageRef }, (page.status === 'deleted' ? { style: { opacity: '.2' } } : {})),
                                    React.createElement(Page, { width: pageRef.current ? pageRef.current.offsetWidth - 4 : 300, height: pageRef.current ? pageRef.current.offsetHeight : 300, pageNumber: page.pageNumber })), actions: [
                                    React.createElement(Avatar, __assign({}, (page.status != 'deleted'
                                        ? { style: { backgroundColor: '#c00' } }
                                        : {}), { gap: 1 }), page.pageNumber),
                                    React.createElement(DeleteOutlined, __assign({ key: "delete", onClick: function () { return updatePageStatus(page.pageNumber, 'deleted'); } }, (page.status === 'deleted' ? { style: { color: '#c00' } } : {}))),
                                    React.createElement(BorderHorizontalOutlined, __assign({ key: "split", onClick: function () { return updatePageStatus(page.pageNumber, 'splitted'); } }, (page.status === 'splitted'
                                        ? { style: { color: '#c00' } }
                                        : {}))),
                                ] }))))); })))))));
};
export default SplitPDF;
