import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const BusinessRuleFilter = () => {
    const query = useQueryParameters();
    const history = useHistory();
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = {};

        if (values.search) {
            params['search'] = values.search;
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        history.replace(`/business-rules/?${queryString}`);
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmit}
            layout="inline"
            initialValues={{
                search: query.get('search'),
            }}
        >
            <FormItem label="Search" {...formItemLayout} name={'search'}>
                <Search placeholder="Search" style={{ width: 200 }} allowClear size={'small'} />
            </FormItem>

            <Form.Item>
                <Button
                    onClick={() => {
                        form.resetFields();
                        history.push(`/business-rules/`);
                    }}
                >
                    Reset
                </Button>
                <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};

export default BusinessRuleFilter;
