import React, { useEffect } from 'react';
import TemplatesStore from '../../components/Templates/Stores/TemplatesStore';
import { useHistory } from 'react-router-dom';

import { observer } from 'mobx-react';
import TemplateForm from '../../components/Templates/TemplateForm';
import { Alert, Col, notification, Row, Typography } from 'antd';
import { ErrorRender } from '@partsbadger/library';
import RenderPage from '../RenderPage';
import ContentType from '../../stores/ContentType';
import TemplateVariablesView from '../../components/Templates/VariablesView';

const TemplateCreatePage = () => {
    const history = useHistory();

    useEffect(() => {
        ContentType.getAll();
        return () => {};
    }, []);

    const template = TemplatesStore.template;

    return (
        <RenderPage
            title={'Edit Template'}
            handleBack={() => {
                history.push('/templates/');
            }}
            breadcrumbs={[
                {
                    title: 'Home',
                    link: '/',
                },
                {
                    title: 'Templates',
                },
            ]}
        >
            <Row>
                <Col span={24}>
                    <TemplateForm
                        handleSave={async values => {
                            const template = await TemplatesStore.create(values).catch(err => {
                                notification.error({
                                    message: 'Error',
                                    description: <ErrorRender error={err} />,
                                });
                            });
                            if (template) {
                                history.push(`/templates/${template.id}/`);
                            }
                        }}
                    />
                </Col>
            </Row>
        </RenderPage>
    );
};
export default observer(TemplateCreatePage);
