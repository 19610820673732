import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, post } from '../shared';

import { INotification } from '../../utils/types';

class Notification {
    loading = false;
    notificationData: INotification[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            loading: observable,
            notificationData: observable,

            // Actions
            getNotifications: action,
            makeNotificationRead: action,
        });
    }

    getNotifications = async () => {
        runInAction(() => {
            this.loading = true;
        });

        try {
            const response = await get(`customer/notifications/`);

            runInAction(() => {
                this.notificationData = response.data;
                this.loading = false;
            });

            return response.data.results;
        } catch (err) {
            // console.log(err)
            runInAction(() => {
                this.loading = false;
            });

            return [];
        }
    };

    makeNotificationRead = async (id: number) => {
        runInAction(() => {
            this.loading = true;
        });

        try {
            const response = await post(`customer/notifications/${id}/read/`);

            runInAction(() => {
                this.loading = false;
            });
            return response.data;
        } catch (err) {
            // console.log(err)
            runInAction(() => {
                this.loading = false;
            });

            return [];
        }
    };
}
export const NotificationStore = new Notification();
