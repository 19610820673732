import { Popover } from 'antd';
import React from 'react';
var ThumbnailFileMcMaster = function (_a) {
    var _b, _c, _d;
    var mcmaster_product = _a.mcmaster_product;
    var quantity_per_package = null;
    var content = (_b = mcmaster_product === null || mcmaster_product === void 0 ? void 0 : mcmaster_product.Specifications) === null || _b === void 0 ? void 0 : _b.map(function (item) {
        var _a;
        if ((item === null || item === void 0 ? void 0 : item.Attribute) === 'Quantity per Package') {
            quantity_per_package = item.Values;
        }
        return (React.createElement("tr", { key: item },
            React.createElement("th", null, item.Attribute),
            React.createElement("td", { style: {
                    padding: '.75rem',
                    verticalAlign: 'top',
                    borderTop: '1px solid #dee2e6',
                    color: '#2a2a2a',
                } }, (_a = item.Values) === null || _a === void 0 ? void 0 : _a.toString())));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { title: "Mc Master", content: React.createElement("div", { style: {
                    height: 400,
                    overflowY: 'auto',
                } },
                React.createElement("table", null, content)) },
            React.createElement("div", { className: 'flex flex-row items-center' },
                React.createElement("div", { className: 'ml-2' }, (_d = (_c = mcmaster_product === null || mcmaster_product === void 0 ? void 0 : mcmaster_product.Links) === null || _c === void 0 ? void 0 : _c.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.Key) === 'Price'; })) === null || _d === void 0 ? void 0 : _d.map(function (item) {
                    return item.Value.map(function (p) {
                        return (React.createElement("div", { key: p, className: 'flex flexRow' },
                            "$", p === null || p === void 0 ? void 0 :
                            p.Amount,
                            " per ", p === null || p === void 0 ? void 0 :
                            p.UnitOfMeasure,
                            ' ',
                            quantity_per_package && "of ".concat(quantity_per_package),
                            " if ", p === null || p === void 0 ? void 0 :
                            p.MinimumQuantity,
                            ' ', p === null || p === void 0 ? void 0 :
                            p.UnitOfMeasure,
                            " is ordered"));
                    });
                }))))));
};
export default ThumbnailFileMcMaster;
