var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select, Upload } from 'antd';
import _ from 'lodash';
import { API_ROOT } from '@partsbadger/utils';
var FILE_URL = API_ROOT + "/customer/files/";
var Option = Select.Option, OptGroup = Select.OptGroup;
var TextArea = Input.TextArea;
export var AdditionalRequirements = function (_a) {
    var originalValue = _a.value, requirements = _a.requirements, onChange = _a.onChange, hide_extras = _a.hide_extras, disabled = _a.disabled;
    // Load default data
    var _b = useState(originalValue === null || originalValue === void 0 ? void 0 : originalValue.map(function (v) {
        var _a;
        return {
            label: v.label,
            value: parseInt(v.key),
            extra: (_a = v.value) !== null && _a !== void 0 ? _a : '',
        };
    })), selectedValue = _b[0], setSelectedValue = _b[1];
    var handleOnChange = function (values) {
        setSelectedValue(values);
        if (onChange) {
            var _translateToOriginal = values
                ? values.map(function (i) {
                    return {
                        key: i.value.toString(),
                        label: i.label,
                        value: i.extra,
                        files: i.files,
                    };
                })
                : [];
            onChange(_translateToOriginal);
        }
    };
    // Group by Type
    var options = _.groupBy(requirements, function (item) {
        return item.type;
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Select, { allowClear: true, mode: "multiple", placeholder: "Search", style: { width: '100%' }, labelInValue: true, optionFilterProp: "children", value: selectedValue !== null && selectedValue !== void 0 ? selectedValue : [], onChange: function (items) {
                    // Selected Item doesn't have the rest for fields (extra, files) so we need to append here
                    var _updated = items.map(function (i) {
                        var _found = selectedValue === null || selectedValue === void 0 ? void 0 : selectedValue.find(function (v) { return v.value == i.value; });
                        return {
                            label: i.label,
                            value: i.value,
                            extra: _found ? _found.extra : null,
                            files: _found ? _found.files : [],
                        };
                    });
                    handleOnChange(_updated);
                }, disabled: disabled }, Object.keys(options).map(function (key) {
                return (React.createElement(OptGroup, { key: key, label: key }, options[key].map(function (item) { return (React.createElement(Option, { key: item.id, value: item.id, extra: "test", files: [] }, item.name)); })));
            }))),
        React.createElement("div", { style: { padding: '10px 21px' } }, selectedValue && !hide_extras && (React.createElement(ExtraInputs, { requirements: requirements, value: selectedValue, handleChange: handleOnChange })))));
};
var ExtraInputs = function (_a) {
    var value = _a.value, requirements = _a.requirements, handleChange = _a.handleChange;
    if (!value) {
        return React.createElement("div", null);
    }
    return (React.createElement(React.Fragment, null, value.map(function (item) {
        var _a, _b, _c;
        var option = requirements.find(function (record) { return Number(record.id) === Number(item.value); });
        if (!option) {
            return null;
        }
        var field_type = option.field_type;
        if (field_type === 'input') {
            return (React.createElement(Form.Item, { key: "input-".concat(item.value), label: item.label },
                React.createElement(Input, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: (_a = item.extra) !== null && _a !== void 0 ? _a : undefined, onBlur: function (event) {
                        var new_data = value.map(function (old_record) {
                            return old_record.value === item.value
                                ? __assign(__assign({}, old_record), { extra: event.target.value }) : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'inputtext') {
            return (React.createElement(Form.Item, { key: "input-".concat(item.value), label: item.label },
                React.createElement(TextArea, { style: { maxWidth: 300 }, size: 'small', required: true, defaultValue: (_b = item.extra) !== null && _b !== void 0 ? _b : '', onBlur: function (event) {
                        var new_data = value.map(function (old_record) {
                            return old_record.value === item.value
                                ? __assign(__assign({}, old_record), { extra: event.target.value }) : old_record;
                        });
                        handleChange(new_data);
                    } })));
        }
        if (field_type === 'file') {
            var defaultFileList = (_c = item === null || item === void 0 ? void 0 : item.files) === null || _c === void 0 ? void 0 : _c.filter(function (f) { return f; }).map(function (file) {
                return {
                    uid: file.id,
                    name: file.filename,
                    status: 'done',
                    url: file.file,
                    response: {
                        id: file.id,
                    },
                };
            });
            return (React.createElement(Form.Item, { key: "upload-".concat(item.value), label: item.label },
                React.createElement(Upload.Dragger, { name: "file", action: FILE_URL, headers: {
                        Authorization: "Token ".concat(localStorage.getItem('token')),
                    }, 
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    defaultFileList: defaultFileList, onChange: function (info) {
                        var fileList = info.fileList;
                        var files = fileList === null || fileList === void 0 ? void 0 : fileList.filter(function (f) { return (f === null || f === void 0 ? void 0 : f.status) === 'done'; }).map(function (file) {
                            return {
                                id: file.response.id,
                                filename: file.response.filename,
                                file: file.response.file,
                            };
                        });
                        if (!files.length) {
                            return;
                        }
                        var items = value === null || value === void 0 ? void 0 : value.map(function (old_record) {
                            return old_record.value === item.value
                                ? __assign(__assign({}, old_record), { files: files }) : old_record;
                        });
                        handleChange(items);
                    }, multiple: true },
                    React.createElement(Button, null,
                        React.createElement(UploadOutlined, null),
                        " Click to upload"))));
        }
        return null;
    })));
};
