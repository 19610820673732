var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { get, patch, post, put, remove } from '@partsbadger/utils';
import { denormalize_data, denormalize_data_product, normalize_data } from '../components/LineItems/helpers';
import { message, notification } from 'antd';
var STATUS_IN_PROGRESS = ['In Progress', 'In Review'];
var Quote = /** @class */ (function () {
    function Quote() {
        var _this = this;
        this.quote = null;
        this.currentUser = null;
        this.quoteAccountOverview = null;
        this.rfq = null;
        this.loading = false;
        this.masterProductsByPosition = [];
        this.similarProducts = undefined;
        this.lineItems = [];
        this.unitPricesOriginals = [];
        this.item = {}; // Dynamic Quantities
        this.quote_notifications = [];
        this.error = null;
        this.mcMasterProductResponse = {
            message: '',
            status: '',
            minQuantity: 0,
            detail: '',
        };
        this.additional_requirements = [];
        this.selected_master_product = null;
        this.partTolerances = [];
        this.holeTolerances = [];
        this.materials = [];
        this.finishes = [];
        this.coatings = [];
        this.master_products_with_replace_files = [];
        this.users = [];
        this.createLineItemFrom3dFile = function (file_id) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, post("/staff/quotes/".concat((_b = this.quote) === null || _b === void 0 ? void 0 : _b.id, "/create-from-step-file/"), {
                                step_file: file_id,
                            }).then(function (response) {
                                var _a;
                                _this.masterProductsByPosition.push(response.quote_has_master_product);
                                _this.lineItems.push(normalize_data(response.quoted_product));
                                _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                            })];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        }); };
        this.createLineItemFrom2dFile = function (file_id) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/create-from-drawing-file/"), {
                            drawing_file: file_id,
                            duplicate: true,
                        }).then(function (response) {
                            var _a;
                            _this.masterProductsByPosition.push(response.quote_has_master_product);
                            _this.lineItems.push(normalize_data(response.quoted_product));
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        this.createLineItemFromExistingLineItems = function (line_items) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/create-from-exiting-quoted-product/"), {
                            quoted_products: line_items,
                        }).then(function (response) {
                            var _a;
                            response.quote_has_master_products.map(function (item) {
                                return _this.masterProductsByPosition.push(item);
                            });
                            response.quoted_products.map(function (item) { return _this.lineItems.push(normalize_data(item)); });
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        this.createLineItemWithDimensions = function (payload) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/create-with-dimensions/"), payload).then(function (response) {
                            var _a;
                            _this.masterProductsByPosition.push(response.quote_has_master_product);
                            _this.lineItems.push(normalize_data(response.quoted_product));
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        this.duplicate_line_item = function (line_items, quote_id) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat(quote_id, "/create-from-exiting-quoted-product/"), {
                            quoted_products: line_items,
                            new_quote: (_a = this.quote) === null || _a === void 0 ? void 0 : _a.id,
                        }).then(function (response) {
                            var _a;
                            response.quote_has_master_products.map(function (item) {
                                return _this.masterProductsByPosition.push(item);
                            });
                            if ((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id) {
                                _this.getMasterProducts(_this.quote.id);
                                _this.getLineItems(_this.quote.id);
                            }
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        makeObservable(this, {
            // Observables
            quote: observable,
            currentUser: observable,
            quoteAccountOverview: observable,
            rfq: observable,
            loading: observable,
            masterProductsByPosition: observable,
            similarProducts: observable,
            lineItems: observable,
            unitPricesOriginals: observable,
            item: observable,
            quote_notifications: observable,
            error: observable,
            mcMasterProductResponse: observable,
            additional_requirements: observable,
            selected_master_product: observable,
            partTolerances: observable,
            holeTolerances: observable,
            materials: observable,
            finishes: observable,
            coatings: observable,
            master_products_with_replace_files: observable,
            users: observable,
            // Actions
            setQuote: action,
            setQuoteEditable: action,
            setUpdatedMasterProduct: action,
            setSelectedMasterProduct: action,
            setCurrentUser: action,
            getQuote: action,
            getRFQ: action,
            getQuoteAccountOverview: action,
            getTolerances: action,
            getMaterials: action,
            getFinishes: action,
            getCoatings: action,
            updateQuote: action,
            updateAccountsInQuoteAndRelatedItems: action,
            sendToPartners: action,
            duplicateQuote: action,
            deleteQuote: action,
            getMasterProducts: action,
            getMasterProductSimilarProducts: action,
            duplicateMasterProduct: action,
            duplicateQuotedProduct: action,
            deleteMasterProduct: action,
            getLineItems: action,
            getAdditionalRequirements: action,
            createLineItems: action,
            createLineItemFrom3dFile: action,
            createLineItemFrom2dFile: action,
            createLineItemFromExistingLineItems: action,
            createLineItemWithDimensions: action,
            duplicateLineItem: action,
            updateMultipleLineItems: action,
            recalculatePrices: action,
            deleteLineItem: action,
            sendToSalesRepresentative: action,
            saveItem: action,
            getCost: action,
            updateLT: action,
            getNotifications: action,
            updateAllLeadTime: action,
            analyzeAllItemsFiles: action,
            sendNotification: action,
            getRequote: action,
            upload3dRedactedFile: action,
            upload2dRedactedFile: action,
            removeRedactedFile: action,
            getMcMasterProduct: action,
            dynamicQuantitiesLeadTime: action,
            updateRequiredQuantities: action,
            updateQuoteLineQuantityItem: action,
            makeDynamicQuantityAdjustment: action,
            getDynamicPricesList: action,
            addQuoteItemQuantity: action,
            replaceFilesMasterProduct: action,
            syncDataByReplaceMasterProductFiles: action,
            duplicate_line_item: action,
            updateMasterProduct: action,
            addVendorQuote: action,
            exportQuotedProductsAsCSV: action,
            addLeadTine: action,
            getUsers: action,
            //Computed
            inProgress: computed,
            flagsAccountOverview: computed,
            flagsRfqInformation: computed,
        });
    }
    Quote.prototype.clean = function () {
        this.quote = null;
        this.quoteAccountOverview = null;
        this.rfq = null;
        this.loading = false;
        this.masterProductsByPosition = [];
        this.similarProducts = undefined;
        this.lineItems = [];
        this.unitPricesOriginals = [];
        this.item = {};
        this.quote_notifications = [];
        this.error = null;
        this.mcMasterProductResponse = {
            message: '',
            status: '',
            minQuantity: 0,
            detail: '',
        };
        this.additional_requirements = [];
        this.selected_master_product = null;
    };
    Quote.prototype.setQuote = function (data) {
        this.quote = data;
    };
    Quote.prototype.setQuoteEditable = function (isEditable) {
        if (this.quote) {
            this.quote.editable = isEditable;
        }
    };
    Quote.prototype.setUpdatedMasterProduct = function (updated_master_product) {
        var _this = this;
        runInAction(function () {
            _this.masterProductsByPosition = _this.masterProductsByPosition.map(function (item) {
                if (item.master_product.id == updated_master_product.id) {
                    return __assign(__assign({}, item), { master_product: __assign({}, updated_master_product) });
                }
                return item;
            });
        });
    };
    Quote.prototype.setSelectedMasterProduct = function (master_product) {
        var _this = this;
        runInAction(function () {
            _this.selected_master_product = master_product;
        });
    };
    Object.defineProperty(Quote.prototype, "inProgress", {
        get: function () {
            if (this.quote) {
                return STATUS_IN_PROGRESS.includes(this.quote.stage);
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Quote.prototype.setCurrentUser = function (user) {
        this.currentUser = user;
    };
    Quote.prototype.getQuote = function (id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var quote_id;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        quote_id = id ? id : (_a = this.quote) === null || _a === void 0 ? void 0 : _a.id;
                        this.loading = true;
                        return [4 /*yield*/, get("/staff/quotes/".concat(quote_id, "/"))
                                .then(function (data) {
                                _this.quote = data;
                                _this.loading = false;
                            })
                                .catch(function (error) { return (_this.error = error); })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getRFQ = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/rfq/".concat(id, "/"))
                            .then(function (data) {
                            _this.rfq = data;
                        })
                            .catch(function (error) { return (_this.error = error); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getQuoteAccountOverview = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, get("/staff/quotes/".concat(id, "/account-overview/")).then(function (data) {
                                _this.quoteAccountOverview = data;
                                _this.loading = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getTolerances = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/part-tolerances/")
                            .then(function (data) {
                            _this.partTolerances = data.results;
                        })
                            .catch(function (error) {
                            console.error(error);
                        })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, get("/staff/hole-tolerances/")
                                .then(function (data) {
                                _this.holeTolerances = data.results;
                            })
                                .catch(function (error) {
                                console.error(error);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getMaterials = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/materials/", {
                            params: params,
                        })
                            .then(function (data) {
                            _this.materials = data.results;
                        })
                            .catch(function (error) {
                            console.error(error);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getFinishes = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/finishes/", {
                            params: params,
                        })
                            .then(function (data) {
                            _this.finishes = data.results;
                        })
                            .catch(function (error) {
                            console.error(error);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getCoatings = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/coatings/", {
                            params: params,
                        })
                            .then(function (data) {
                            _this.coatings = data.results;
                        })
                            .catch(function (error) {
                            console.error(error);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.updateQuote = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.error = null;
                        return [4 /*yield*/, patch("/staff/quotes/".concat(id, "/"), payload)
                                .then(function () {
                                _this.getQuote(id);
                                _this.getMasterProducts(id);
                            })
                                .catch(function (error) { return (_this.error = error); })
                                .finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.updateAccountsInQuoteAndRelatedItems = function (quote_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.error = null;
                        return [4 /*yield*/, patch("/staff/quotes/".concat(quote_id, "/update-related-data/"), payload)
                                .then(function (data) {
                                _this.loading = false;
                                _this.setQuote(data);
                            })
                                .catch(function (error) {
                                _this.error = error;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.sendToPartners = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        this.error = null;
                        return [4 /*yield*/, patch("/staff/quotes/".concat(id, "/sent-to-partners/"), payload)
                                .then(function () {
                                _this.loading = false;
                                _this.getQuote(id);
                                _this.getMasterProducts(id);
                            })
                                .catch(function (error) {
                                _this.error = error;
                            })
                                .finally(function () {
                                _this.loading = false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.duplicateQuote = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, post("/staff/quotes/".concat(id, "/duplicate/"))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.deleteQuote = function (id, history) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat(id, "/delete/"))
                            .then(function (response) {
                            history.push("/quotes/");
                        })
                            .catch(function (error) { return console.log(error); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.getMasterProducts = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/quotes/".concat(quote_id, "/master-products/")).then(function (response) {
                            _this.masterProductsByPosition = response;
                            _this.master_products_with_replace_files = response.map(function (resp) { return resp.master_product; });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.getMasterProductSimilarProducts = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/quotes/".concat(quote_id, "/similar-products/")).then(function (response) {
                            _this.similarProducts = response;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.duplicateMasterProduct = function (master_product_id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/duplicate-master-product/"), {
                            master_product: master_product_id,
                        }).then(function (response) {
                            var _a;
                            var new_item = response.quote_has_master_product;
                            _this.masterProductsByPosition = _this.masterProductsByPosition.map(function (item) {
                                return item.master_product.id === master_product_id ? new_item : item;
                            });
                            var data = response.quoted_products.map(function (r) { return normalize_data(r); });
                            _this.lineItems = _this.lineItems.map(function (previous_item) {
                                var found = data.find(function (new_item) { return new_item.id === previous_item.id; });
                                return found ? found : previous_item;
                            });
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.duplicateQuotedProduct = function (master_product_id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/duplicate-quoted-product/"), {
                            master_product: master_product_id,
                        }).then(function () {
                            if (_this.quote) {
                                _this.getQuote(_this.quote.id);
                                _this.getMasterProducts(_this.quote.id);
                                _this.getLineItems(_this.quote.id);
                            }
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.deleteMasterProduct = function (master_product_id) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.masterProductsByPosition = this.masterProductsByPosition.filter(function (item) { return item.master_product.id !== master_product_id; });
                        return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/delete-master-product/"), {
                                master_product: master_product_id,
                            })
                                .then(function () { var _a; return _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id); })
                                .catch(function (error) {
                                return notification.error({
                                    message: 'Error',
                                });
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getLineItems = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, get("/staff/quotes/".concat(quote_id, "/quoted-products/")).then(function (response) {
                                runInAction(function () {
                                    _this.lineItems = response.results.map(function (record) { return normalize_data(record); });
                                    _this.unitPricesOriginals = response.results.map(function (record) {
                                        return {
                                            line_item_id: record.id,
                                            unit_price: record.unit_price,
                                            manual_adjustment: Number(record.manual_adjustment),
                                        };
                                    });
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.log("GetLineItems Error: ".concat(err_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getAdditionalRequirements = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/customer/additional-requirements/").then(function (response) {
                            _this.additional_requirements = response.results;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.createLineItems = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat(id, "/create-variation/"), payload).then(function (response) {
                            var _a, _b;
                            _this.lineItems.push(normalize_data(response.quoted_product));
                            if ((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id) {
                                _this.getQuote((_b = _this.quote) === null || _b === void 0 ? void 0 : _b.id);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.addNoCoatingVariation = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quoted-products/".concat(id, "/add-no-coating-variation/"), []).then(function (response) {
                            var _a, _b;
                            _this.lineItems.push(normalize_data(response));
                            if ((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id) {
                                _this.getQuote((_b = _this.quote) === null || _b === void 0 ? void 0 : _b.id);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.duplicateLineItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post('/staff/quoted-products/' + id + '/duplicate/', [])
                            .then(function (response) {
                            var _a;
                            _this.lineItems.push(normalize_data(response));
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })
                            .catch(function (error) {
                            return notification.error({
                                message: 'Error',
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.updateMultipleLineItems = function (payload) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, patch("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/update-multiple/"), payload)
                            .then(function (response) {
                            var _a;
                            var data = response.map(function (r) { return normalize_data(r); });
                            _this.lineItems = _this.lineItems.map(function (previous_item) {
                                var found = data.find(function (new_item) { return new_item.id === previous_item.id; });
                                return found ? found : previous_item;
                            });
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        })
                            .catch(function (error) {
                            return notification.error({
                                message: 'Error',
                            });
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Quote.prototype.recalculatePrices = function (payload) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, patch("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/recalculate-prices/"), payload).then(function (response) {
                                var _a;
                                _this.loading = false;
                                var data = response.map(function (r) { return normalize_data(r); });
                                _this.lineItems = _this.lineItems.map(function (previous_item) {
                                    var found = data.find(function (new_item) { return new_item.id === previous_item.id; });
                                    return found ? found : previous_item;
                                });
                                _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                                message.success('Prices were updated');
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    Quote.prototype.deleteLineItem = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.lineItems = this.lineItems.filter(function (r) { return r.id !== id; });
                        return [4 /*yield*/, remove("/staff/quoted-products/".concat(id, "/"))
                                .then(function () { var _a; return _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id); })
                                .catch(function (error) { return notification.error({ message: 'Error' }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.sendToSalesRepresentative = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat(id, "/send-to-sales-representative/"), payload).catch(function () {
                            return notification.error({ message: 'Error' });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.saveItem = function (record) {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = denormalize_data_product(record);
                        return [4 /*yield*/, patch("/staff/quoted-products/".concat(record.id, "/"), payload).then(function (response) {
                                var _a;
                                var item = normalize_data(response);
                                _this.lineItems = _this.lineItems.map(function (r) { return (r.id === item.id ? item : r); });
                                _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.getCost = function (record) {
        return __awaiter(this, void 0, void 0, function () {
            var payload, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = denormalize_data(record);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, put("/staff/quoted-products/".concat(record.id, "/get-cost/"), payload).then(function (response) {
                                var updated_record = __assign(__assign({}, record), { safe_auto_quote: response.safe_auto_quote, setup_price: response.setup_price, vendor_price: response.vendor_price, work_price: response.work_price, unit_price: response.unit_price, total_price: response.total_price, shipping_cost: response.shipping_cost });
                                _this.lineItems = _this.lineItems.map(function (r) { return (r.id === record.id ? normalize_data(updated_record) : r); });
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        err_2 = _a.sent();
                        console.log("GetCost Error: ".concat(err_2));
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.updateLT = function (record) {
        return __awaiter(this, void 0, void 0, function () {
            var payload, response, updated_record;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        payload = denormalize_data(record);
                        return [4 /*yield*/, put("/staff/quoted-products/".concat(record.id, "/update-lt/"), payload)];
                    case 1:
                        response = _a.sent();
                        updated_record = __assign(__assign({}, record), { lead_time: response.lead_time });
                        this.lineItems = this.lineItems.map(function (r) { return (r.id === record.id ? updated_record : r); });
                        return [2 /*return*/, response.lead_time];
                }
            });
        });
    };
    Quote.prototype.getNotifications = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, get("/staff/quotes/".concat((_b = this.quote) === null || _b === void 0 ? void 0 : _b.id, "/notifications/")).then(function (response) {
                                _this.quote_notifications = response;
                            })];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.updateAllLeadTime = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, post("/staff/quotes/".concat((_b = this.quote) === null || _b === void 0 ? void 0 : _b.id, "/update-lt-in-all-items/"))];
                    case 1: return [2 /*return*/, _c.sent()];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.analyzeAllItemsFiles = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this.quote) {
                    post('/staff/master-products/' + id + '/analyze/').catch(function (e) { return (_this.error = e); });
                }
                return [2 /*return*/];
            });
        });
    };
    Quote.prototype.sendNotification = function (payload) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        if (payload.body) {
                            formData.append('body', payload.body);
                        }
                        payload.attachments.forEach(function (item) {
                            formData.append('attachments_files', item);
                        });
                        if (payload === null || payload === void 0 ? void 0 : payload.notify_owner) {
                            formData.append('notify_owner', String(payload.notify_owner));
                        }
                        if (payload.carbon_copy) {
                            payload.carbon_copy.forEach(function (item) {
                                formData.append('carbon_copy', item === null || item === void 0 ? void 0 : item.key);
                            });
                        }
                        return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/notifications/"), formData, {}, true)
                                .then(function (response) {
                                _this.quote_notifications = response;
                            })
                                .catch(function (error) { return (_this.error = error); })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getRequote = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat(id, "/requote/"))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.upload3dRedactedFile = function (step_id, file) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append('redacted_file', file);
                        return [4 /*yield*/, patch("/staff/step-files/".concat(step_id, "/"), formData, true)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.upload2dRedactedFile = function (drawing_id, file) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append('redacted_file', file);
                        return [4 /*yield*/, patch("/staff/drawing-files/".concat(drawing_id, "/"), formData, true)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.removeRedactedFile = function (drawing_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var formData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formData = new FormData();
                        formData.append('master-product', drawing_id + '');
                        return [4 /*yield*/, post("/staff/master-products/".concat(drawing_id, "/remove-redacted-file/"), payload)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.getMcMasterProduct = function (product) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, post("/staff/quotes/".concat((_a = this.quote) === null || _a === void 0 ? void 0 : _a.id, "/create-mcmaster-product/"), product).then(function (response) {
                            var _a;
                            if (response.status === 201) {
                                _this.masterProductsByPosition.push(response.quote_has_master_product);
                                _this.lineItems.push(normalize_data(response.quoted_product));
                                _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                                // var material = response.Specifications.filter(function(e){return e.Attribute == 'Material'})
                                var price = response.json.Links.filter(function (e) {
                                    return e.Key === 'Price';
                                });
                                _this.mcMasterProductResponse = {
                                    message: response.message,
                                    status: response.status,
                                    minQuantity: price[0].Value[0].MinimumQuantity,
                                    detail: response.json.DetailDescription,
                                };
                            }
                            else {
                                _this.mcMasterProductResponse = {
                                    message: response.message,
                                    status: response.status,
                                };
                            }
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    // Dynamic Quantities
    Quote.prototype.dynamicQuantitiesLeadTime = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/dynamic_quantities/lead_time/", payload).then(function (response) {
                            var item = normalize_data(response);
                            _this.lineItems = _this.lineItems.map(function (r) { return (r.id === item.id ? item : r); });
                            _this.item = item;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.updateRequiredQuantities = function (id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quoted-products/".concat(id, "/get_dynamic_quantities/"), payload)];
                    case 1:
                        response = _a.sent();
                        runInAction(function () {
                            var _a;
                            _this.getQuote((_a = _this.quote) === null || _a === void 0 ? void 0 : _a.id);
                        });
                        this.lineItems = this.lineItems.map(function (item) {
                            if (item.id === response.id) {
                                return normalize_data(response);
                            }
                            return item;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.updateQuoteLineQuantityItem = function (quote_item_id, quote_item_quantity_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, patch("/staff/quoted-products/".concat(quote_item_id, "/quote-item-quantity/").concat(quote_item_quantity_id, "/"), payload)];
                    case 1:
                        response = _a.sent();
                        this.lineItems = this.lineItems.map(function (item) {
                            if (item.id === quote_item_id) {
                                item.quote_item_quantities = item.quote_item_quantities.map(function (item_quantity) {
                                    if (item_quantity.id === response.id) {
                                        return response;
                                    }
                                    return item_quantity;
                                });
                                return item;
                            }
                            return item;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.makeDynamicQuantityAdjustment = function (quote_item_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quoted-products/".concat(quote_item_id, "/adjust_quote_item_quantities/"), payload)];
                    case 1:
                        response = _a.sent();
                        this.lineItems = this.lineItems.map(function (item) {
                            if (item.id === response.id) {
                                return normalize_data(response);
                            }
                            return item;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getDynamicPricesList = function (quote_item_id, page) {
        if (page === void 0) { page = 1; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/quoted-products/".concat(quote_item_id, "/all-dynamic-quantities/"), {
                            params: {
                                page: page,
                            },
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.addQuoteItemQuantity = function (quote_item_id, quantity) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post("/staff/quoted-products/".concat(quote_item_id, "/add_quantity/"), {
                            quantity: quantity,
                        })];
                    case 1:
                        response = _a.sent();
                        this.lineItems = this.lineItems.map(function (item) {
                            if (item.id === quote_item_id) {
                                item.quote_item_quantities.push(response);
                                item.quote_item_quantities = item.quote_item_quantities.sort(function (a, b) {
                                    return a.quantity > b.quantity ? 1 : -1;
                                });
                                return item;
                            }
                            return item;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.replaceFilesMasterProduct = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.master_products_with_replace_files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, post("/staff/quotes/".concat(quote_id, "/replace-files-master-product/"), this.master_products_with_replace_files)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.syncDataByReplaceMasterProductFiles = function (data) {
        this.master_products_with_replace_files = data;
    };
    Quote.prototype.updateMasterProduct = function (master_product_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, patch('/staff/master-products/' + master_product_id + '/', payload)];
                    case 1:
                        response = _a.sent();
                        if (!this.quote) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getLineItems(this.quote.id)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, response];
                }
            });
        });
    };
    Quote.prototype.addVendorQuote = function (quote_id, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, post('/staff/quotes/' + quote_id + '/vendor-quote/', payload)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.exportQuotedProductsAsCSV = function (quote_id) {
        return get("/staff/quoted-products/export-quoted-products-csv/?quote_id=".concat(quote_id));
    };
    Quote.prototype.addLeadTine = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.quote) return [3 /*break*/, 3];
                        return [4 /*yield*/, post('/staff/quotes/' + this.quote.id + '/add-lead-time-in-all-items/', payload)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getLineItems(this.quote.id)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Quote.prototype.getUsers = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get("/staff/users/", {
                            params: params,
                        }).then(function (data) {
                            _this.users = data.results;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Quote.prototype.checkIfExpiredQuote = function (quote_id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, get('/staff/quotes/' + quote_id + '/check-expired-price/')];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Object.defineProperty(Quote.prototype, "flagsAccountOverview", {
        get: function () {
            var flags = 0;
            if (this.quoteAccountOverview && this.quoteAccountOverview.lifetime_value > 10000) {
                flags++;
            }
            return flags;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Quote.prototype, "flagsRfqInformation", {
        get: function () {
            var flags = 0;
            if (this.quote && Number(this.quote.whole_quote_adjust) > 100) {
                flags++;
            }
            if (this.rfq && this.rfq.needs_to_create_3d_files) {
                flags++;
            }
            if (this.rfq && this.rfq.needs_to_create_2d_files) {
                flags++;
            }
            if (this.rfq && this.rfq.itar) {
                flags++;
            }
            if (this.rfq && this.rfq.other_notes && this.rfq.other_notes.length > 0) {
                flags++;
            }
            if (this.quote && this.quote.has_batch_shipments) {
                flags++;
            }
            return flags;
        },
        enumerable: false,
        configurable: true
    });
    return Quote;
}());
export var QuoteStore = new Quote();
