import React, { useEffect } from 'react';
import { Card, Divider, notification, Table, TablePaginationConfig, Tag } from 'antd';
import { PartsBadgerProductionFilter } from '../../components/QuoteTool';
import { ColumnsType, FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import Stages from '../../components/Stages';
import { IPartsBadgerProductionListStaff } from '../../../../types/src/staff/list/IPartsBadgerProductionListStaff';
import { ErrorRender, PaginationComponent } from '@partsbadger/library';
import { Link, useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { QuotedProductStore } from '../../stores';
import { observer } from 'mobx-react';

export const PartsBadgerProductionList = observer(() => {
    const history = useHistory();
    const queryParams = useQueryParameters();

    async function handleListRecords(params = {}): Promise<void> {
        try {
            await QuotedProductStore.getPartsBadgerProductionItems(params);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        handleListRecords(queryParams);
    }, [location.search]);

    const tableColumns: ColumnsType<IPartsBadgerProductionListStaff> = [
        {
            title: 'Part Thumbnail',
            key: 'part_thumbnail',
            render: (record: IPartsBadgerProductionListStaff) => (
                <>
                    {record.step_file ? (
                        <img
                            src={record.step_file.url}
                            alt="part_thumbnail"
                            style={{ width: '100px', height: '100px' }}
                        />
                    ) : (
                        <span>No image</span>
                    )}
                </>
            ),
        },
        {
            title: 'Quote Number',
            key: 'quote__name',
            render: (record: IPartsBadgerProductionListStaff) => (
                <Link to={`/quotes/${record.quote.id}#div-mp-${record.master_product_id}`}>{record.quote.name}</Link>
            ),
            sorter: (a, b) => a.quote.name.localeCompare(b.quote.name),
        },
        {
            title: 'Part Name',
            key: 'master_product__name',
            render: (record: IPartsBadgerProductionListStaff) => <span>{record.name}</span>,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Production Method',
            key: 'partsbadger_production_method',
            render: (record: IPartsBadgerProductionListStaff) => (
                <span>
                    <Tag color="blue">{record.partsbadger_production_method}</Tag>
                </span>
            ),
            sorter: (a, b) => a.partsbadger_production_method.localeCompare(b.partsbadger_production_method),
        },
        {
            title: 'Status',
            key: 'quote__stage',
            render: (record: IPartsBadgerProductionListStaff) => <Stages stage={record.quote.stage} />,
            sorter: (a, b) => a.quote.stage.localeCompare(b.quote.stage),
        },
        {
            title: 'Creation Date',
            key: 'created_time',
            render: (record: IPartsBadgerProductionListStaff) => <span>{dayjs(record.created_time).format('LL')}</span>,
            sorter: (a, b) => {
                const dateA = dayjs(a.created_time).format('YYYY-MM-DD') || '';
                const dateB = dayjs(b.created_time).format('YYYY-MM-DD') || '';
                return dateA.localeCompare(dateB);
            },
        },
        {
            title: 'Expiration Date',
            key: 'expiration_date',
            render: (record: IPartsBadgerProductionListStaff) => (
                <span>{dayjs(record.expiration_date).format('LL')}</span>
            ),
            sorter: (a, b) => {
                const dateA = dayjs(a.expiration_date).format('YYYY-MM-DD') || '';
                const dateB = dayjs(b.expiration_date).format('YYYY-MM-DD') || '';
                return dateA.localeCompare(dateB);
            },
        },
    ];

    const handleTableChange = (
        _pagination: TablePaginationConfig,
        _filters: Record<string, FilterValue | null>,
        sorter: { column: string; columnKey: string; order: string },
        extra: { action: string }
    ) => {
        if (extra.action === 'sort') {
            const orderType = sorter.order;
            const column =
                orderType === 'ascend' || orderType === undefined ? sorter.columnKey : '-' + sorter.columnKey;
            if (sorter.column !== undefined) {
                queryParams.set('ordering', column);
            } else {
                queryParams.delete('ordering');
            }
            history.replace(`?${queryParams.toString()}`);
        }
    };

    const tableFooter = () => {
        return (
            <div className="ml-auto">
                <PaginationComponent
                    defaultPageSize={30}
                    total={QuotedProductStore.total_results}
                    pageChange={page => {
                        queryParams.set('page', page.toString());
                        history.replace(`?${queryParams.toString()}`);
                    }}
                />
            </div>
        );
    };

    return (
        <div>
            <Divider>
                <div className={'title'}>PartsBadger Production</div>
            </Divider>
            <PartsBadgerProductionFilter />
            <Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Table
                        columns={tableColumns}
                        dataSource={QuotedProductStore.partsbadgerProductionItems}
                        pagination={false}
                        loading={QuotedProductStore.isLoading}
                        rowKey="id"
                        footer={tableFooter}
                        scroll={{ y: `calc(95vh - 250px)` }}
                        //@ts-ignore
                        onChange={handleTableChange}
                    />
                </div>
            </Card>
        </div>
    );
});
