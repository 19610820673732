import React, { Fragment, useEffect, useState } from 'react';
import { DownOutlined, LogoutOutlined, UserOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Menu, Input } from 'antd';
import MenuBar from './Menu';
import { useHistory } from 'react-router';

import { INotification } from '../../utils/types';
import { hasPermission } from './User';
import Notifications from './Notifications';
import { NotificationStore } from '../stores';

import { version } from '../../package.json';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import Logo from './Layouts/Logo';

interface Props {
    user: any;
    handleLogout: () => void;
    handleSideCollapsed: () => void;
    isCollapsed: boolean;
}

const DARK_MODE = 'dark-mode';

const Header: React.FC<Props> = ({ user, handleLogout, handleSideCollapsed, isCollapsed }) => {
    const [data, setData] = useState<INotification[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const username = user.fullname;
    const auth = !!user?.id;
    const darkMode = window.localStorage.getItem(DARK_MODE) == 'yes';

    const handleSwitchTheme = () => {
        window.localStorage.setItem(DARK_MODE, darkMode ? 'no' : 'yes');
        window.location.reload();
    };

    const menu = (
        <Menu style={{ width: '200px' }}>
            <Menu.Item key="theme-switcher" onClick={handleSwitchTheme}>
                <BgColorsOutlined /> Switch to {darkMode ? 'Light Mode' : 'Dark Mode'}
            </Menu.Item>
            <Menu.Divider />
            {auth && (
                <Menu.Item key="logout" onClick={handleLogout}>
                    <LogoutOutlined /> <span>Log Out</span>
                </Menu.Item>
            )}

            <div className="text-center mt-4">
                <small className="text-muted">v{version}</small>
            </div>
        </Menu>
    );

    useEffect(() => {
        async function getNotifications() {
            setLoading(true);

            try {
                const response = await NotificationStore.getNotifications();
                setData(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        }

        getNotifications();
    }, []);

    async function makeRead(id: number) {
        const response = await NotificationStore.makeNotificationRead(id);

        setData(
            data.map(item => {
                if (item.id === response.id) {
                    return { ...item, read: true };
                }
                return item;
            })
        );
    }

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxHeight: '65px',
                }}
                className="flex flow align-items-center justify-between w-full"
            >
                <Button
                    className={'sider mx-2'}
                    onClick={() => handleSideCollapsed()}
                    type="ghost"
                    icon={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                />
                <Logo
                    className="row-logo menu-header"
                    style={{
                        width: '10%',
                    }}
                />
                <div
                    className={'menu-header '}
                    style={{
                        width: '66%',
                    }}
                >
                    <MenuBar user={user} mode={'horizontal'} />
                </div>

                {hasPermission('quotes.add_quote') && (
                    <div className={'hidden xl:inline'}>
                        <Button
                            className={'step-one-true-form'}
                            onClick={() => {
                                history.push('/get-quote');
                            }}
                            style={{
                                color: '#fff',
                                backgroundColor: '#d92923',
                                borderColor: '#d92923',
                                marginLeft: 'auto',
                            }}
                        >
                            One True Form
                        </Button>
                    </div>
                )}

                <div
                    className={'flex flex-row items-center md:ml-1'}
                    style={{
                        background: 'beige',
                    }}
                >
                    <Input.Search
                        placeholder={'Search'}
                        style={{ width: '100%' }}
                        onSearch={v => {
                            history.push(`/search?query=${v}`);
                        }}
                    />
                </div>
                <div className="flex flow-row items-center">
                    <div className={'flex flex-row items-center'}>
                        <Notifications data={data} loading={loading} makeRead={makeRead} />
                    </div>

                    <div style={{ padding: ' 0 10px' }}>
                        <Avatar icon={<UserOutlined />} />
                    </div>

                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button type="link" className="ml-0 pl-0" style={{ color: '#737373' }}>
                            {username} <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
