export const NETWORK_ERROR = 'NETWORK_ERROR';

/**
 * Auth
 */
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const USER_LOADING_ERROR = 'USER_LOADING_ERROR';


/**
 * User
 */


export const USERS_LOADING = 'USERS_LOADING';
export const USERS_LOADED = 'USERS_LOADED';
export const USERS_ERROR = 'USERS_ERROR';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';

/**
 * Leads
 */

export const LEADS_LOADING = 'LEADS_LOADING';
export const LEADS_LOADED = 'LEADS_LOADED';
export const LEADS_FAILED = 'LEADS_FAILED';


/**
 * Accounts
 */

export const ACCOUNTS_LOADING = 'ACCOUNTS_LOADING';
export const ACCOUNTS_LOADED = 'ADD_ACCOUNTS';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNTS_FAILED = 'ACCOUNTS_FAILED';
export const ACCOUNT_CREATED = 'ACCOUNT_CREATED';

export const ACCOUNTS_MASTER_PRODUCT_LOADING = 'ACCOUNTS_MASTER_PRODUCT_LOADING';
export const ACCOUNTS_MASTER_PRODUCT_LOADED = 'ADD_MASTER_PRODUCT_ACCOUNTS';
export const ACCOUNTS_MASTER_PRODUCT_FAILED = 'ACCOUNTS_MASTER_PRODUCT_FAILED';


export const SHOW_FORM_ACCCOUNT = 'SHOW_FORM_ACCOUNT';
export const HIDE_FORM_ACCOUNT = 'HIDE_FORM_ACCOUNT';

/**
 * Contacts
 */

export const CONTACTS_LOADING = 'CONTACTS_LOADING';
export const CONTACTS_LOADED = 'CONTACTS_LOADED';
export const CONTACTS_FAILED = 'CONTACTS_FAILED';
export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';
export const CONTACT_CREATED = 'CONTACT_CREATED';

export const SHOW_FORM_CONTACT = 'SHOW_FORM_CONTACT';
export const HIDE_FORM_CONTACT = 'HIDE_FORM_CONTACT';


/**
 * Products Actions
 */

export const MASTER_PRODUCTS_LOADING = 'MASTER_PRODUCTS_LOADING'
export const MASTER_PRODUCTS_LOADED = 'MASTER_PRODUCTS__LOADED'

export const MASTER_PRODUCT_LOADED = 'MASTER_PRODUCT__LOADED'
export const MASTER_PRODUCTS_FAILED = 'MASTER_PRODUCTS__FAILED'

export const MASTER_PRODUCTS_BY_QUOTE_LOADING = 'MASTER_PRODUCTS_BY_QUOTE_LOADING'
export const MASTER_PRODUCTS_BY_QUOTE_LOADED = 'MASTER_PRODUCTS_BY_QUOTE_LOADED'

export const UI_OPEN_CREATE_MASTER_PRODUCT = 'UI_OPEN_CREATE_MASTER_PRODUCT';
export const UI_CLOSE_OPEN_CREATE_MASTER_PRODUCT = 'UI_CLOSE_OPEN_CREATE_MASTER_PRODUCT';


export const MASTER_PRODUCTS_BY_QUOTE_NEW = 'MASTER_PRODUCTS_BY_QUOTE_NEW'
export const MASTER_PRODUCTS_BY_QUOTE_UPDATED = 'MASTER_PRODUCTS_BY_QUOTE_UPDATED'
export const MASTER_PRODUCTS_ADDING_STEP_FILE = 'MASTER_PRODUCTS_ADDING_STEP_FILE';
export const MASTER_PRODUCTS_ADDED_STEP_FILE = 'MASTER_PRODUCTS_ADDED_STEP_FILE';


export const MASTER_PRODUCTS_ERROR_ADDING_STEP_FILE = 'MASTER_PRODUCTS_ERROR_ADDING_STEP_FILE';
export const MASTER_PRODUCTS_ADDING_DRAWING_FILE = 'MASTER_PRODUCTS_ADDING_DRAWING_FILE';
export const MASTER_PRODUCTS_ADDED_DRAWING_FILE = 'MASTER_PRODUCTS_ADDED_DRAWING_FILE';

export const MASTER_PRODUCTS_ERROR_ADDING_DRAWING_FILE = 'MASTER_PRODUCTS_ERROR_ADDING_DRAWING_FILE';
export const MASTER_PRODUCT_ERROR = 'MASTER_PRODUCT_ERROR';

export const MASTER_PRODUCTS_DELETE = 'MASTER_PRODUCTS_DELETE';

export const PRODUCTS_FAILED = 'PRODUCTS_FAILED';

/**
 * Quoted Products
 */

export const PRODUCTS_BY_QUOTE_LOADING = 'PRODUCTS_BY_QUOTE_LOADING';
export const PRODUCTS_BY_QUOTE_LOADED = 'PRODUCTS_BY_QUOTE_LOADED';


/**
 * Materials
 */

export const MATERIALS_LOADING = 'MATERIALS_LOADING';
export const MATERIALS_LOADED = 'MATERIALS_LOADED';
export const MATERIAL_SAVED = 'MATERIAL_SAVED';
export const MATERIALS_FAILED = 'MATERIALS_FAILED';

export const MATERIALS_COLORS_LOADING = 'MATERIALS_COLORS_LOADING';
export const MATERIALS_COLORS_LOADED = 'MATERIALS_COLORS_LOADED';
export const MATERIALS_COLORS_ERROR = 'MATERIALS_COLORS_ERROR';

export const MATERIALS_SPECIFICATIONS_LOADING = 'MATERIALS_SPECIFICATIONS_LOADING';
export const MATERIALS_SPECIFICATIONS_LOADED = 'MATERIALS_SPECIFICATIONS_LOADED';
export const MATERIALS_SPECIFICATIONS_ERROR = 'MATERIALS_SPECIFICATIONS_ERROR';

export const UI_SHOW_NEW_MATERIAL_MODAL = 'UI_SHOW_NEW_MATERIAL_MODAL';
export const UI_HIDE_NEW_MATERIAL_MODAL = 'UI_HIDE_NEW_MATERIAL_MODAL';

/**
 * Tolerances
 */

export const HOLE_TOLERANCES_LOADING = 'HOLE_TOLERANCES_LOADING';
export const HOLE_TOLERANCES_LOADED = 'HOLE_TOLERANCES_LOADED';
export const HOLE_TOLERANCES_FAILED = 'HOLE_TOLERANCES_FAILED';

export const PART_TOLERANCES_LOADING = 'PART_TOLERANCES_LOADING';
export const PART_TOLERANCES_LOADED = 'PART_TOLERANCES_LOADED';
export const PART_TOLERANCES_FAILED = 'PART_TOLERANCES_FAILED';

/**
 * Additional Requirements
 */
export const ADDITIONAL_REQUIREMENTS_LOADING = 'ADDITIONAL_REQUIREMENTS_LOADING';
export const ADDITIONAL_REQUIREMENTS_LOADED = 'ADDITIONAL_REQUIREMENTS_LOADED';
export const ADDITIONAL_REQUIREMENTS_ERROR = 'ADDITIONAL_REQUIREMENTS_ERROR';


/**
 * Coatings
 */

export const COATINGS_LOADING = 'COATINGS_LOADING'
export const COATINGS_LOADED = 'COATINGS_LOADED'
export const COATINGS_FAILED = 'COATINGS_FAILED'
export const COATING_SAVED = 'COATING_SAVED'


export var UI_SHOW_NEW_COATING_MODAL = "UI_SHOW_NEW_COATING_MODAL";
export var UI_HIDE_NEW_COATING_MODAL = "UI_HIDE_NEW_COATING_MODAL";


/**
 * Finishes
 */

export const FINISHES_LOADING = 'FINISHES_LOADING'
export const FINISHES_LOADED = 'FINISHES_LOADED'
export const FINISHES_FAILED = 'FINISHES_FAILED'
export const FINISH_SAVED = 'FINISHE_SAVED'

export var UI_SHOW_NEW_FINISH_MODAL = "UI_SHOW_NEW_FINISH_MODAL";
export var UI_HIDE_NEW_FINISH_MODAL = "UI_HIDE_NEW_FINISH_MODAL";

/**
 * Lead Times
 */

export const LEAD_TIMES_LOADED = 'LEAD_TIMES_LOADED';
export const LEAD_TIMES_ERROR = 'LEAD_TIMES_ERROR';


/**
 * Sites
 */

export const SITES_LOADING = 'SITES_LOADING'
export const SITES_LOADED = 'SITES_LOADED'
export const SITE_FAILED = 'SITES_FAILED'


/**
 * Notifications
 */

export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';

/**
 * Quote Actions
 *
 */
export const QUOTE_ADDED = 'QUOTE_ADDED';
export const QUOTE_LOADED = 'QUOTE_LOADED';
export const QUOTES_LOADING = 'QUOTES_LOADING';
export const QUOTES_LOADED = 'QUOTES_LOADED';
export const QUOTES_REPORT_LOADED = 'QUOTES_REPORT_LOADED';

export const INSTANT_QUOTES_LOADING = 'INSTANT_QUOTES_LOADING';
export const INSTANT_QUOTES_LOADED = 'INSTANT_QUOTES_LOADED';
export const INSTANT_QUOTES_ERROR = 'INSTANT_QUOTES_ERROR';

export const INSTANT_QUOTES_ORDERS_LOADING = 'INSTANT_QUOTES_ORDERS_LOADING';
export const INSTANT_QUOTES_ORDERS_LOADED = 'INSTANT_QUOTES_ORDERS_LOADED';
export const INSTANT_QUOTES_ORDERS_ERROR = 'INSTANT_QUOTES_ORDERS_ERROR';
export const INSTANT_QUOTES_ORDER_LOADED = 'INSTANT_QUOTES_ORDER_LOADED';

export const RFQ_LOADING = 'RFQ_LOADING';
export const RFQ_LOADED = 'RFQ_LOADED';
export const RFQS_LOADED = 'RFQS_LOADED';
export const RFQ_ERROR = 'RFQ_ERROR';


/**
 * Quoted Products
 * @type {string}
 */
export const QUOTED_PRODUCT_LOADING = 'QUOTED_PRODUCT_LOADING';
export const QUOTED_PRODUCT_LOADED = 'QUOTED_PRODUCT_LOADED';
export const QUOTED_PRODUCT_NEW_VARIATION = 'QUOTED_PRODUCT_NEW_VARIATION';
export const QUOTED_PRODUCT_DELETE = 'QUOTED_PRODUCT_DELETE';
export const QUOTED_PRODUCTS_UPDATED = 'QUOTED_PRODUCTS_UPDATED';
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM';

export const QUOTES_BY_ACCOUNT_LOADED = 'QUOTES_BY_ACCOUNT_LOADED';


export const QUOTE_FAILED = 'QUOTE_FAILED';
export const QUOTES_FAILED = 'QUOTES_FAILED';
export const QUOTE_LOADING = 'QUOTE_LOADING';

export const QUOTE_PRODUCT_ADDING = 'QUOTE_PRODUCT_ADDING';
export const QUOTE_PRODUCT_ADDING_FAILED = 'QUOTE_PRODUCT_ADDING_FAILED';

export const QUOTE_ADD_FORM = 'QUOTE_ADD_FORM';
export const QUOTE_HIDE_ADD_FORM = 'QUOTE_HIDE_ADD_FORM';


export var UI_SHOW_EDIT_QUOTE_MODAL = "UI_SHOW_EDIT_QUOTE_MODAL";
export var UI_HIDE_EDIT_QUOTE_MODAL = "UI_HIDE_EDIT_QUOTE_MODAL";


export var UI_SHOW_BULK_EDIT_MODAL = "UI_SHOW_BULK_EDIT_MODAL";
export var UI_HIDE_BULK_EDIT_MODAL = "UI_HIDE_BULK_EDIT_MODAL";


/**
 * Deals
 *
 */
export const DEAL_LOADING = 'DEAL_LOADING';
export const DEAL_LOADED = 'DEAL_LOADED';
export const DEAL_FAILED = 'DEAL_FAILED';

export const DEALS_LOADING = 'DEALS_LOADING';
export const DEALS_LOADED = 'DEALS_LOADED';
export const DEALS_FAILED = 'DEALS_FAILED';

export const DEAL_ADDED = 'DEAL_ADDED';
export const DEAL_SHOW_ADD_FORM = 'DEAL_SHOW_ADD_FORM';
export const DEAL_HIDE_ADD_FORM = 'DEAL_HIDE_ADD_FORM';


/**
 * Orders
 */


export const ORDERS_LOADING = 'ORDERS_LOADING';
export const ORDERS_LOADED = 'ORDERS_LOADED';

export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_LOADED = 'ORDER_LOADED';
export const ORDER_CREATED = 'ORDER_CREATED';
export const ORDER_FAILED = 'ORDER_FAILED';
export const SHOW_FORM_ADD_ORDER = 'SHOW_FORM_ADD_ORDER';
export const HIDE_FORM_ADD_ORDER = 'HIDE_FORM_ADD_ORDER';

export const ORDER_ADD_ITEMS = 'ORDER_ADD_ITEMS';
export const ORDER_ADD_ITEM = 'ORDER_ADD_ITEM';
export const ORDER_REMOVE_ITEM = 'ORDER_REMOVE_ITEM';

