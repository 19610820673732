import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ErrorRender } from '@partsbadger/library';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Tabs } from 'antd';
import GoogleLogin from 'react-google-login';
import { GOOGLE_CLIENT_ID } from 'constants/app';
import { UserStore } from '../stores/UserStore';

const { TabPane } = Tabs;

const Login = (props: any) => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const location = useLocation();

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err: any, values: any) => {
            if (!err) {
                UserStore.login(values.email, values.password);
            }
        });
    };

    const qs = require('query-string');

    const next = qs.parse(location.search, { ignoreQueryPrefix: true }).next;

    if (UserStore.isAuthenticated) {
        return <Redirect to={next ? next : '/'} />;
    }
    const { getFieldDecorator } = props.form;

    return (
        <div
            style={{
                textAlign: 'center',
                margin: 'auto',
                maxWidth: '300px',
            }}
        >
            <div className="login-logo-form" style={{ marginTop: '50px' }}>
                <img
                    src="https://partsbadger-static-files.s3.us-east-2.amazonaws.com/static/images/logo_full.svg"
                    className={'login-logo'}
                    alt=""
                    style={{ maxWidth: '250px', margin: 'auto' }}
                />
            </div>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Google" key="1">
                    <div className={'pt-6'}>
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            style={{ width: '100%' }}
                            buttonText="Sign in with Google"
                            onSuccess={(response: any) => {
                                const payload = {
                                    access_token: response?.accessToken,
                                };
                                UserStore.loginWithGoogle(payload);
                            }}
                            onFailure={error => {
                                console.error(error);
                            }}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </TabPane>
                <TabPane tab="Email" key="2">
                    <Form onSubmit={handleSubmit} className="login-form" style={{ maxWidth: 300, padding: 30 }}>
                        <ErrorRender error={UserStore.errors} />

                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Please input your email!' }],
                            })(
                                <Input
                                    type={'email'}
                                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Email"
                                    autoComplete="email"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                    name={'password'}
                                    autoComplete="current-password"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={UserStore.isLoading}
                            >
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>

            <Modal
                visible={showForgotPassword}
                title="Recovery Password"
                onCancel={() => {
                    setShowForgotPassword(false);
                }}
                footer={[]}
            >
                <Input
                    placeholder={'Please insert your email'}
                    onPressEnter={() => {
                        // const payload = {
                        //     "email": event.target.value
                        // }
                        // this.props.sendResetPassword(payload)
                        // this.setState({
                        //     showForgotPassword: false
                        // })
                    }}
                >
                    {/*{this.state.notes}*/}
                </Input>
            </Modal>
        </div>
    );
};

export default Form.create({ name: 'normal_login' })(Login);
