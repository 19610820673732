import React, { useEffect, useState } from 'react';
import { Checkbox, Input, Radio } from 'antd';
import { useParams } from 'react-router-dom';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { observer } from 'mobx-react';

interface ITolDataProps {
    tolX: { show: boolean; tol: number | string };
    tolXX: { show: boolean; tol: number | string };
    tolXXX: { show: boolean; tol: number | string };
    tolXXXX: { show: boolean; tol: number | string };
}

interface IProductData {
    PART: string;
    QTY: number;
    REQUIRED_SHIP_DATE: string;
    MATERIAL_CERT_REQUIRED: boolean;
    MATERIAL: string;
    UNS: string;
    FINISH: string;
    COATING: string;
    DWG_UNITS: string;
    TOLERANCE: 'UNLESS OTHERWISE STATED' | 'None';
}

const DrawingTextBox: React.FC = () => {
    const [unit, setUnit] = useState<string>('in');
    const [textArea, setTextArea] = useState<string>();
    const [tolX, setTolX] = useState<string>('');
    const [tolXChecked, setTolXChecked] = useState<boolean>(true);
    const [tolXX, setTolXX] = useState<string>('');
    const [tolXXChecked, setTolXXChecked] = useState<boolean>(true);
    const [tolXXX, setTolXXX] = useState<string>('');
    const [tolXXXChecked, setTolXXXChecked] = useState<boolean>(true);
    const [tolXXXX, setTolXXXX] = useState<string>('');
    const [tolXXXXChecked, setTolXXXXChecked] = useState<boolean>(true);

    const params = useParams<{
        id?: string;
    }>();

    /* YYYY-MM-DD to DD-MM-YYYY*/
    const rsdToDDMMYY = (rsd: string) => {
        const rsdSplit = rsd.split('-');
        return `${rsdSplit[2]}/${rsdSplit[1]}/${rsdSplit[0]}`;
    };

    const productTemplate = (order: string, product_data: IProductData, tolerances_data: ITolDataProps) => {
        let rendered_str = `ORDER: ${order}
PART: ${product_data.PART} 
QTY: ${product_data.QTY}
MATERIAL: ${product_data.MATERIAL} ${product_data.UNS ? `(UNS ${product_data.UNS})` : ''}`;
        if (product_data.MATERIAL_CERT_REQUIRED) {
            rendered_str = `${rendered_str}
        MATERIAL CERT REQUIRED`;
        }

        // Add finish
        rendered_str = `${rendered_str}
FINISH: ${product_data.FINISH} UNLESS OTHERWISE STATED`;
        //Add dynamic coatings

        if (product_data.COATING != 'N/A' && product_data.COATING != 'None' && product_data.COATING != 'NA') {
            if (product_data.COATING.includes('Heat Treat')) {
                rendered_str = `${rendered_str}
HEAT TREAT: ${''}`;
            }
            product_data.COATING = product_data.COATING.replace(' & Heat Treat & ', ' & ');
            product_data.COATING = product_data.COATING.replace('Heat Treat & ', '');
            product_data.COATING = product_data.COATING.replace(' & Heat Treat', '');
            product_data.COATING = product_data.COATING.replace('Heat Treat', '');
        }

        if (
            product_data.COATING != 'N/A' &&
            product_data.COATING != 'None' &&
            product_data.COATING != 'NA' &&
            product_data.COATING != ''
        ) {
            rendered_str = `${rendered_str}
COATING: ${product_data.COATING}`;
        }

        //Adding values after coating
        rendered_str = `${rendered_str}
DWG UNITS: ${unit === 'in' ? 'INCHES' : 'mm'}
TOLERANCE: UNLESS OTHERWISE STATED`;
        // Adding tolerances
        if (tolerances_data.tolX.show) {
            rendered_str = `${rendered_str}
X.X ± ${tolX}`;
        }
        if (tolerances_data.tolXX.show) {
            rendered_str = `${rendered_str}
X.XX ± ${tolXX}`;
        }

        if (tolerances_data.tolXXX.show) {
            rendered_str = `${rendered_str}
X.XXX ± ${tolXXX}`;
        }
        if (tolerances_data.tolXXXX.show) {
            rendered_str = `${rendered_str}
X.XXXX ± ${tolXXXX}`;
        }

        rendered_str = `${rendered_str}
REQUIRED SHIP DATE ${rsdToDDMMYY(product_data.REQUIRED_SHIP_DATE)}

`;

        return rendered_str;
    };

    useEffect(() => {
        if (SalesOrderStore.order?.id) {
            const products_data: IProductData[] = SalesOrderStore.order.line_items
                .filter(i => i.quote_has_product)
                .map(item => {
                    return {
                        PART: item.name ?? '',
                        REQUIRED_SHIP_DATE: item.required_partner_ship_date ?? '',
                        QTY: item.quantity,
                        MATERIAL: item.quote_has_product?.product?.material?.name ?? '',
                        UNS: item.quote_has_product?.product?.material?.uns ?? '',
                        MATERIAL_CERT_REQUIRED: true,
                        FINISH: item.quote_has_product?.product?.finish?.name ?? '',
                        DWG_UNITS: unit,
                        COATING: item.quote_has_product?.product?.coating?.name ?? '',
                        TOLERANCE: 'UNLESS OTHERWISE STATED',
                    };
                });

            const tolerances = {
                tolX: { show: tolXChecked, tol: tolX },
                tolXX: { show: tolXXChecked, tol: tolXX },
                tolXXX: { show: tolXXXChecked, tol: tolXXX },
                tolXXXX: { show: tolXXXXChecked, tol: tolXXXX },
            };

            let render_string = '';

            products_data.map(products_data => {
                render_string =
                    render_string + productTemplate(SalesOrderStore.order?.name ?? '', products_data, tolerances);
            });

            setTextArea(render_string);
        }
    }, [
        SalesOrderStore.order?.id,
        unit,
        tolX,
        tolXX,
        tolXXX,
        tolXXXX,
        tolXChecked,
        tolXXChecked,
        tolXXXChecked,
        tolXXXXChecked,
    ]);

    useEffect(() => {
        if (params?.id) {
            SalesOrderStore.getById(params?.id);
        }
    }, [params]);

    return (
        <div style={{ display: 'flex' }}>
            {/* <div> */}
            {/*     {JSON.stringify(Object.keys(dwgData))} */}
            {/* </div> */}

            <div style={{ maxWidth: '30rem' }}>
                <Checkbox checked={tolXChecked} onChange={e => setTolXChecked(e.target.checked)} />
                &nbsp; TolX{' '}
                <Input
                    onChange={e => {
                        setTolX(e.target.value);
                    }}
                />
                <Checkbox checked={tolXXChecked} onChange={e => setTolXXChecked(e.target.checked)} />
                &nbsp; TolXX{' '}
                <Input
                    onChange={e => {
                        setTolXX(e.target.value);
                    }}
                />
                <Checkbox checked={tolXXXChecked} onChange={e => setTolXXXChecked(e.target.checked)} />
                &nbsp; TolXXX{' '}
                <Input
                    onChange={e => {
                        setTolXXX(e.target.value);
                    }}
                />
                <Checkbox checked={tolXXXXChecked} onChange={e => setTolXXXXChecked(e.target.checked)} />
                &nbsp; TolXXXX{' '}
                <Input
                    onChange={e => {
                        setTolXXXX(e.target.value);
                    }}
                />
                <hr style={{ margin: '1rem' }} />
                <Input.TextArea
                    // ref={textAreaRef}
                    autoSize={{ minRows: 20, maxRows: 200 }}
                    value={textArea}
                    onChange={e => {
                        setTextArea(e.target.value);
                    }}
                />
            </div>

            <div style={{ flex: 1, padding: '1rem' }} className="text-lg">
                <div className="px-20 pb-10">
                    {SalesOrderStore.order?.account?.quoting_notes && (
                        <strong>
                            Quoting Notes: <span>{SalesOrderStore.order?.account?.quoting_notes}</span>
                        </strong>
                    )}
                </div>
                <div className="px-20">
                    {SalesOrderStore.order?.internal_notes_account && (
                        <strong>
                            Internal Notes: <span>{SalesOrderStore.order?.internal_notes_account}</span>
                        </strong>
                    )}
                </div>
                <Radio.Group
                    onChange={e => {
                        setUnit(e.target.value);
                    }}
                    value={unit}
                >
                    <Radio value={'in'}>IN</Radio>
                    <Radio value={'mm'}>MM</Radio>
                </Radio.Group>

                <hr style={{ margin: '1rem' }} />

                <Input.TextArea autoSize={{ minRows: 20, maxRows: 200 }} />
            </div>
        </div>
    );
};

export default observer(DrawingTextBox);
