import * as ActionTypes from '../ActionTypes';

export const Leads = (
    state = {
        isLoading: false,
        entities: [],
        entity: {},
        error: null,
    },
    action) => {
    switch (action.type) {
        case ActionTypes.LEADS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};


        case ActionTypes.LEADS_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.LEADS_FAILED:
            return {...state, isLoading: false, error: action.error, entities: []};


        default:
            return state;
    }
};