var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select, Spin } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
import { Popover } from 'antd';
var busy = null;
export var Quotes = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), quotes = _b[0], setQuotes = _b[1];
    var token = localStorage.getItem('token');
    var fetchQuotes = function (params) {
        if ('contact' in props) {
            params['contact'] = props.contact;
        }
        if ('account' in props) {
            params['account'] = props.account;
        }
        if ('stage' in props) {
            params['stage'] = props.stage;
        }
        if ('filters' in props) {
            params = __assign(__assign({}, params), props.filters);
        }
        axios
            .get("".concat(BASE_URL, "/staff/quotes/"), {
            headers: {
                Authorization: "Token ".concat(token),
            },
            params: params,
        })
            .then(function (response) {
            setQuotes(response.data.results);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    useEffect(function () {
        if (props.account) {
            fetchQuotes({
                account: props.account,
            });
        }
    }, [props.account]);
    return (React.createElement(Select, __assign({ showSearch: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search quotes", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : 'No results found', onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
            };
            setLoading(true);
            busy = setTimeout(function () { return fetchQuotes(params); }, 400);
        } }, props), quotes.map(function (quote) { return (React.createElement(Select.Option, { key: quote.id, value: quote.name, account: quote.account, contact: quote.contact, entity: quote },
        quote.name,
        quote.notes && (React.createElement(Popover, { overlayStyle: { maxWidth: '500px' }, content: quote.notes },
            React.createElement("a", null, " (Notes) "))),
        ' ',
        props.show_in_zoho_alert && !(quote === null || quote === void 0 ? void 0 : quote.zoho_id) && React.createElement("span", { style: { color: 'red' } }, " Not In Zoho"))); })));
};
