import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import moment from 'moment'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Input,
    InputNumber,
    notification,
    Radio,
    Row,
    Select,
    Spin,
} from 'antd';

import { get, patch, post } from '../../../shared/api'
import LineItems from './LineItems/LineItems'
import { normalize_files, UploadFile } from "../../Inputs/UploadFile";
import { Accounts, AdditionalRequirements, Contacts, Countries, Deals } from "@partsbadger/library";
import { hasPermission } from "../../User";
import {ErrorRender, GenericSelect} from "@partsbadger/library";
import { RichTextEditor } from "components/Inputs/RichTextEditor";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        md: {span: 9},

    },
    wrapperCol: {
        xs: {span: 24},
        md: {span: 12},
    },
};

let busy = null;


function normalize_requirements(r) {
    return {
        'key': r.requirement.id,
        'label': r.requirement.name,
        'value': r.value,
        'files': r.files
    };
}

export const format_date = (value) => {
    if (!value) {
        return null;
    }
    return moment(value).format('YYYY-MM-DD');
};


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Rfq = ({id, form, action = 'create'}) => {

    const [loadingDeal, setLoadingDeal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [rfq, setRfq] = useState({});
    const [needsTaxForm, setneedsTaxForm] = useState(false);
    // const [requirements, setRequirements] = useState([]);
    // const [contact, setContact] = useState({});


    const {getFieldDecorator, getFieldValue, setFieldsValue} = form;
    const account_id = getFieldValue('account')?.key;
    const contact_id = getFieldValue('contact')?.key;

    const params = useQuery();


    const Deal_ID = params.get("Deal_ID");

    hasPermission("quotes.can_change_the_rfq_owner");


    useEffect(() => {
        if (id) {
            setLoading(true);
            get(`staff/rfq/${id}/`).then(response => {
                setRfq(response.data);
                setLoading(false)
            }).catch(error => alert(error));
        }


    }, []);

    useEffect(() => {
        // Deal_Id comes from zoho id


        if (Deal_ID) {

            setLoadingDeal(true);

            get(`customer/deal-by-zid/${Deal_ID}/`).then(response => {
                setFieldsValue({
                    'deal': {
                        key: response.data.id,
                        label: response.data.name,
                    }
                })
            }).catch(error => alert(error)).finally(() => {
                setLoadingDeal(false);
            })
        }


    }, []);


    useEffect(() => {
        if (contact_id) {
            get(`staff/contacts/${contact_id}/`).then(response => {

                // setContact(response.data);

                setFieldsValue({
                    acquisition_source: 'Existing Contact'
                });

            }).catch(error => alert(error));

            get(`staff/contacts/${contact_id}/default-addresses/`).then(response => {
                if (response?.data?.shipping_address?.zip_code) {
                    setFieldsValue({
                        'shipping_zip_code': response?.data?.shipping_address?.zip_code,
                        'shipping_country': {
                            'key': response?.data?.shipping_address?.country?.code,
                            'label': response?.data?.shipping_address?.country?.name,
                        },
                    });
                }
            }).catch(error => alert(error));

            get(`customer/contacts/${contact_id}/additional-requirements/`).then(response => {

                const new_requirements = response.data.results.map(r => {
                    return normalize_requirements(r);
                });

                // setRequirements(new_requirements);

                if (!rfq.rfq_additional_requirements) {
                    setFieldsValue({'rfq_additional_requirements': new_requirements})
                }

            }).catch(error => notification.error(error))
        }

    }, [contact_id])


    const zip_code = getFieldValue('shipping_zip_code');

    useEffect(() => {

        if (zip_code) {

            const url = `staff/taxes/needs_tax_form/?account=${account_id}&zip_code=${zip_code}`

            if (busy) {
                clearTimeout(busy);
            }

            busy = setTimeout(() => {
                get(url).then(response => {
                    setneedsTaxForm(response.data)
                }).catch(error => console.error(error));
            }, 500);
        }


    }, [account_id, zip_code])

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {

                if (!err) {

                    let errors = []

                    values.rfq_items.map(r => {
                        if (r.quantities.length === 0) {
                            errors.push("A line item doesn't have Quantities")
                        }
                        return null;
                    });


                    if (errors.length > 0) {
                        notification.error({
                            message: "Error",
                            description: "A line item doesn't have Quantities"
                        })
                        return;
                    }

                    const payload = {
                        ...values,
                        deal: values.deal ? values.deal.key : null,
                        account: values.account ? values.account.key : null,
                        contact: values.contact ? values.contact.key : null,
                        shipping_country: values.shipping_country ? values.shipping_country.key : null,
                        rfq_deadline: format_date(values.rfq_deadline),
                        order_deadline: format_date(values.order_deadline),
                        additional_requirements: values?.additional_requirements?.map(r => r.key),
                        other_files: values?.other_files?.map(f => f.response.id),
                        tax_exempt_files: values.tax_exempt_files?.map(f => f.response.id),
                        rfq_items: values.rfq_items.map(r => {
                            return {
                                ...r,
                                "file3d": r.file3d?.length ? r.file3d[0].response.id : null,
                                "file2d": r.file2d?.length ? r.file2d[0].response.id : null,
                                "other_files": r.other_files?.map(f => f.response.id),
                            }
                        })
                    };

                    if (values.owner) {
                        payload['owner'] = values.owner.key
                    }

                    if (values.rfq_additional_requirements) {
                        payload['rfq_additional_requirements'] = values.rfq_additional_requirements.map(row => {
                            return {
                                'requirement': row.key,
                                'value': row.value,
                                'files': row.files?.map(f => f.id),
                            }
                        });
                    }

                    setSending(true);

                    if (action === 'create') {
                        post("staff/rfq/", payload).then(response => {
                            notification.success("RFQ was sent successfully");
                            sessionStorage.setItem('rfqItems', []);
                            window.location.href = "/";
                        }).catch(error => {
                            notification.error({
                                message: 'Error',
                                description: <ErrorRender error={error}/>,
                                style: {
                                    width: 600,
                                    marginLeft: 335 - 600,
                                },
                            });

                            setSending(false);
                        });
                    } else {
                        patch(`staff/rfq/${id}/`, payload).then(response => {
                            notification.success("RFQ was updated successfully");
                            sessionStorage.setItem('rfqItems', []);
                            setSending(false);
                            window.location.href = "/";
                        }).catch(error => {
                            notification.error({
                                message: 'Error',
                                description: <ErrorRender error={error}/>,
                                style: {
                                    width: 600,
                                    marginLeft: 335 - 600,
                                },
                            });

                            setSending(false);
                        });

                    }


                }
            }
        )

    }


    let rfq_items = [];

    if (rfq?.rfq_items) {
        rfq_items = rfq?.rfq_items?.map(item => {
            return {
                ...item,
                id: item.id,
                file3d: item?.file3d && [normalize_files(item.file3d)],
                file2d: item?.file2d && [normalize_files(item.file2d)],
                other_files: item?.other_files?.map(f => {
                    return normalize_files(f)
                })
            }
        });
    } else {
        rfq_items = sessionStorage.getItem('rfqItems') ? JSON.parse(sessionStorage.getItem('rfqItems')) : [];
    }

    if (loading) {
        return <Spin spinning/>
    }

    return (
        <Form onSubmit={handleSubmit} layout="horizontal" style={{margin: "auto"}}>
            <Row>
                <Col md={24}>
                    <Form.Item label={"Deal"} {...formItemLayout}  >
                        {getFieldDecorator('deal', {
                            rules: [{
                                required: false,
                            }],
                            ...(rfq.account && {
                                initialValue: {
                                    'key': rfq.account.id,
                                    'label': rfq.account.name
                                }
                            })
                        })(
                            <Deals/>
                        )}
                    </Form.Item>
                </Col>
                <Col md={12}>


                    {!getFieldValue('deal') && (
                        <React.Fragment>

                            <Form.Item label={"Existing Account"} {...formItemLayout}  >
                                {getFieldDecorator('account', {
                                    rules: [{
                                        required: false,
                                    }],
                                    ...(rfq.account && {
                                        initialValue: {
                                            'key': rfq.account.id,
                                            'label': rfq.account.name
                                        }
                                    })
                                })(
                                    <Accounts/>
                                )}
                            </Form.Item>

                            <Form.Item label={"Existing Contact"} {...formItemLayout}  >
                                {getFieldDecorator('contact', {
                                    rules: [{
                                        required: false,
                                        message: 'Please input a value!'
                                    }],

                                    ...(rfq.contact && {
                                        initialValue: {
                                            'key': rfq.contact.id,
                                            'label': `${rfq.contact.fullname} -  ${rfq.contact.email}`
                                        }
                                    })
                                })(
                                    <Contacts account={account_id}/>
                                )}
                            </Form.Item>
                        </React.Fragment>
                    )}


                </Col>

                <Col md={12}>
                    {!getFieldValue('deal') && (
                        <React.Fragment>
                            <Form.Item label={"Or New Account"} {...formItemLayout}  >
                                {getFieldDecorator('account_name', {
                                    rules: [{
                                        required: !getFieldValue('account'),
                                        message: 'Please input a value!'
                                    }],
                                    initialValue: rfq?.account_name
                                })(
                                    <Input size={'small'} disabled={!!getFieldValue('account')}/>
                                )}
                            </Form.Item>


                            <Form.Item label={"Or New Contact "} {...formItemLayout}  >
                                {getFieldDecorator('contact_first_name', {
                                    rules: [{
                                        required: !getFieldValue('contact'),
                                        message: 'Please input a value!'
                                    }],
                                    initialValue: rfq.contact_first_name
                                })(
                                    <Input autoComplete={"off"} size={'small'} placeholder={"First Name"}
                                           disabled={!!getFieldValue('contact')}/>
                                )}
                            </Form.Item>
                            <Form.Item label={"Last Name"} {...formItemLayout}  >
                                {getFieldDecorator('contact_last_name', {
                                    rules: [{
                                        required: !getFieldValue('contact'),
                                        message: 'Please input a value!'
                                    }],
                                    initialValue: rfq.contact_last_name
                                })(
                                    <Input autoComplete={"off"} size={'small'} placeholder={"Last Name"}
                                           disabled={!!getFieldValue('contact')}/>
                                )}
                            </Form.Item>
                            <Form.Item label={"Email"} {...formItemLayout}  >
                                {getFieldDecorator('contact_email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: !getFieldValue('contact'),
                                            message: 'Please input a value!'
                                        }],
                                    initialValue: rfq.contact_email
                                })(
                                    <Input autoComplete={"off"} size={'small'} type={"email"}
                                           placeholder={"email"}
                                           disabled={!!getFieldValue('contact')}/>
                                )}
                            </Form.Item>


                            <Form.Item label={"Acquisition Source"} {...formItemLayout}  >
                                {getFieldDecorator('acquisition_source', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input a value!'
                                    }],
                                    ...(rfq.acquisition_source && {
                                        initialValue: rfq.acquisition_source
                                    })
                                })(
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder=""
                                        size={"small"}
                                    >
                                        <Select.Option value="Existing Contact">Existing Contact</Select.Option>
                                        <Select.Option value="Cold Call - Outgoing">Cold Call -
                                            Outgoing</Select.Option>
                                        <Select.Option value="Initial Mailer">Initial Mailer</Select.Option>
                                        <Select.Option value="Remailer">Remailer</Select.Option>
                                        <Select.Option value="Personal Mail">Personal Mail</Select.Option>
                                        <Select.Option value="Referral">Referral</Select.Option>
                                        <Select.Option value="Web Search">Web Search</Select.Option>
                                        <Select.Option value="Web Ad">Web Ad</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </React.Fragment>
                    )}

                </Col>

            </Row>


            <Row>


                <Form.Item label={"Shipping Country"} {...formItemLayout}
                           help={"Taxes will be applied based on this location"}>
                    {getFieldDecorator('shipping_country', {
                        rules: [{
                            required: true,
                        }
                        ],

                        initialValue: rfq?.shipping_country ? {'key': rfq?.shipping_country} : {'key': 'US'}
                    })(
                        <Countries/>
                    )}
                </Form.Item>

                <Form.Item label={"Shipping Zip Code"} {...formItemLayout}>
                    {getFieldDecorator('shipping_zip_code', {
                        rules: [{
                            required: true,
                            message: "Insert a valid zip code"
                        }, {
                            // validator: (rule, value, callback) => {
                            //
                            //     const item = postal_codes.find(item => item.ISO === getFieldValue('shipping_country')?.key);
                            //
                            //     if (item && value.match(item.Regex)) {
                            //         return true
                            //     }
                            //
                            //     callback(`The zip code is not valid for ${getFieldValue('shipping_country')?.key}`);
                            // }
                        }

                        ],
                        ...(rfq.shipping_zip_code && {
                            initialValue: rfq.shipping_zip_code
                        })
                    })(
                        <Input size={"small"} autoComplete={"off"}/>
                    )}
                </Form.Item>


                {getFieldValue('shipping_zip_code') && needsTaxForm && (
                    <Form.Item label={"Do you have a Tax Exemption Form?"} {...formItemLayout}  >
                        {getFieldDecorator('tax_exempt', {
                            rules: [{
                                required: true,
                                message: 'Please input a value!'
                            }],
                            initialValue: rfq.tax_exempt || false

                        })(
                            <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                )}


                {getFieldValue('shipping_zip_code') && getFieldValue('tax_exempt') && needsTaxForm && (
                    <Form.Item label={"Tax Exempt files"} {...formItemLayout}  >
                        {getFieldDecorator('tax_exempt_files', {
                            rules: [{
                                required: true,
                                message: 'Please input a value!'
                            }],
                        })(
                            <UploadFile multiple/>
                        )}
                    </Form.Item>
                )}


                <Form.Item label={"Line Items"} style={{padding: '10px'}}>
                    {getFieldDecorator('rfq_items', {
                        rules: [{
                            required: true,
                            message: 'Please input a value!'
                        }],
                        ...(rfq_items && {
                            initialValue: rfq_items
                        })
                    })(
                        <LineItems onChange={value => {
                            sessionStorage.setItem('rfqItems', JSON.stringify(value))
                        }}/>
                    )}
                </Form.Item>

                <Form.Item label={" Other Files"} {...formItemLayout}  >
                    {getFieldDecorator('other_files', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.other_files && {
                            initialValue: rfq.other_files.map(file => normalize_files(file))
                        })
                    })(
                        <UploadFile multiple/>
                    )}

                </Form.Item>


                <Form.Item label={" Expedited"} {...formItemLayout}  >
                    {getFieldDecorator('expedited', {
                        rules: [{
                            required: true,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.expedited !== undefined && {
                            initialValue: rfq.expedited === true
                        })

                    })(
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    )}

                </Form.Item>

                {getFieldValue('expedited') === false && (
                    <Form.Item label={"Lead Time"} {...formItemLayout}  >
                        {getFieldDecorator('lead_time', {
                            rules: [{
                                required: false,
                                message: 'Please input a value!'
                            }],
                            ...(rfq.lead_time && {
                                initialValue: rfq.lead_time
                            })
                        })(
                            <InputNumber/>
                        )}
                    </Form.Item>
                )}

                <Form.Item label={"Potential Order Deadline If provided"} {...formItemLayout}>
                    {getFieldDecorator('order_deadline', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.order_deadline && {
                            initialValue: moment(rfq.order_deadline)
                        })
                    })(
                        <DatePicker ssize={"small"}/>
                    )}
                </Form.Item>


                <Form.Item label={"Customer #RFQ"} {...formItemLayout}  >
                    {getFieldDecorator('rfq', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.rfq && {
                            initialValue: rfq.rfq
                        })
                    })(
                        <Input size={"small"}/>
                    )}
                </Form.Item>


                <Form.Item label={"RFQ Deadline If provided"} {...formItemLayout}  >
                    {getFieldDecorator('rfq_deadline', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.rfq_deadline && {
                            initialValue: moment(rfq.rfq_deadline)
                        })
                    })(
                        <DatePicker size={"small"}/>
                    )}
                </Form.Item>


                <Form.Item label={"Can the RFQ be SPLIT"} {...formItemLayout}  >
                    {getFieldDecorator('split', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.split !== undefined && {
                            initialValue: rfq.split
                        })
                    })(
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>

                <Form.Item label={"Additional Requirements"} {...formItemLayout}>
                    {getFieldDecorator('rfq_additional_requirements', {
                        rules: [{required: false, message: 'Please select a value!'}],
                        ...(rfq.rfq_additional_requirements && {
                            initialValue: rfq.rfq_additional_requirements?.map(r => normalize_requirements(r))
                        })
                    })(
                        <AdditionalRequirements/>
                    )}

                </Form.Item>


                <Form.Item label={"European Customer"} {...formItemLayout}  >
                    {getFieldDecorator('european_customer', {
                        valuePropName: "checked",
                        ...(rfq.european_customer !== undefined && {
                            initialValue: rfq.european_customer
                        })

                    })(
                        <Checkbox/>
                    )}

                </Form.Item>

                <Form.Item label={"Itar"} {...formItemLayout}  >
                    {getFieldDecorator('itar', {
                        valuePropName: "checked",
                        ...(rfq.itar !== undefined && {
                            initialValue: rfq.itar
                        })

                    })(
                        <Checkbox/>
                    )}

                </Form.Item>


                <Form.Item label={"PartsBadger creating 3D Files?"} {...formItemLayout}  >
                    {getFieldDecorator('create_3d_files', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.create_3d_files !== undefined && {
                            initialValue: rfq.create_3d_files
                        })
                    })(
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>


                <Form.Item label={"Other Notes"} {...formItemLayout}  >
                    {getFieldDecorator('other_notes', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        initialValue: rfq?.other_notes
                    })(
                        <RichTextEditor/>
                    )}
                </Form.Item>


                <Form.Item label={"Target Price"} {...formItemLayout}  >
                    {getFieldDecorator('target_price', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.target_price && {
                            initialValue: rfq.target_price
                        })
                    })(
                        <Input/>
                    )}
                </Form.Item>

                <Form.Item label={"Target Lead Time"} {...formItemLayout}  >
                    {getFieldDecorator('target_lead_time', {
                        rules: [{
                            required: false,
                            message: 'Please input a value!'
                        }],
                        ...(rfq.target_lead_time && {
                            initialValue: rfq.target_lead_time
                        })
                    })(
                        <Input/>
                    )}
                </Form.Item>

                {hasPermission("quotes.can_change_the_rfq_owner") && (
                    <Form.Item label={"Sales Rep."}
                               help={"Leave blank if your are the owner"} {...formItemLayout}>
                        {getFieldDecorator('owner', {
                            rules: [{required: false, message: 'Please select a value!'}],
                        })(
                            <GenericSelect recordType={"User"} filters={{
                                is_staff: true,
                                is_active: true
                            }} size="small" />
                        )}

                    </Form.Item>
                )}


                {action !== "update" && (
                    <div style={{textAlign: "center", marginTop: 10}}>
                        <Button htmlType={"submit"} type="primary" loading={loading | sending}>
                            {action === 'create' ? 'Submit' : 'Update'}
                        </Button>
                    </div>

                )}

            </Row>

        </Form>
    );


}

export const RfqForm = (Form.create()(Rfq));



