import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, InputNumber, Row } from 'antd';
export var ApplicationCostForm = function (props) {
    var _a = useState(''), name = _a[0], setName = _a[1];
    var _b = useState(0.0), price = _b[0], setPrice = _b[1];
    var _c = useState(false), saving = _c[0], setSaving = _c[1];
    var handleSubmitEvent = function () {
        if (name && price >= 0) {
            setSaving(true);
            props
                .onSubmit({
                name: name,
                price: price,
            })
                .then(function () {
                setName('');
                setPrice(0);
            })
                .finally(function () { return setSaving(false); });
        }
        else {
            alert('Insert Name');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { md: 24, lg: 12 },
                React.createElement(Form.Item, null,
                    React.createElement(Input, { size: 'small', placeholder: 'Application', value: name, onChange: function (e) { return setName(e.currentTarget.value); } }))),
            React.createElement(Col, { md: 24, lg: 8 },
                React.createElement(Form.Item, null,
                    React.createElement(InputNumber, { value: price, size: 'small', placeholder: '0.00', onPressEnter: handleSubmitEvent, onChange: function (v) { return v && setPrice(v); } }))),
            React.createElement(Col, { md: 24, lg: 4 },
                React.createElement(Form.Item, null,
                    React.createElement(Button, { size: 'small', type: "primary", loading: saving, onClick: handleSubmitEvent }, "Add"))))));
};
