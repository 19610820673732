import React, {useState} from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Divider, Modal, Table } from "antd";
import {LeadConvertForm} from "components/Lead/LeadConvertForm";

export const LeadList = (props) => {


    const {handleConverted} = props;

    const [convert, setConvert] = useState();


    if (!props.data.length & !props.loading) {
        return null
    }


    return (
        <div>
            <Divider>
                <div className={"title"}> Leads</div>
            </Divider>

            <Table rowKey={"id"} dataSource={props.data} loading={props.loading}
                   pagination={{
                       defaultPageSize: 5
                   }}
            >
                <Table.Column
                    title="First Name"
                    dataIndex="first_name"
                    key="first_name"
                />


                <Table.Column
                    title="Last Name"
                    dataIndex="last_name"
                    key="last_name"
                />

                <Table.Column
                    title="Email"
                    dataIndex="email"
                    key="email"
                />

                <Table.Column
                    title="Owner"
                    dataIndex="owner"
                    key="owner"
                    render={(text, record) => {
                        return record.owner?.display
                    }}
                />


                <Table.Column
                    title="Actions"
                    render={(text, record) => {
                        if (!record?.converted) {
                            return (
                                <span className={"link"}
                                      onClick={() => setConvert(record)}>convert</span>)
                        } else {
                            return <LegacyIcon type={'check'}/>;
                        }
                    }}
                />
            </Table>

            {convert && <Modal title={"Lead Convert Form"} onCancel={() => setConvert(null)} visible footer={[]}>
                <LeadConvertForm record={convert} handleConverted={contact => {
                    handleConverted(contact);
                    setConvert(null);
                }} handleCancel={() => setConvert(null)}/>
            </Modal>}
        </div>
    );
}
