var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Select } from 'antd';
import React from 'react';
var Option = Select.Option;
export var SelectReinforcement = function (_a) {
    var props = __rest(_a, []);
    return (React.createElement(Select, __assign({ showSearch: true, filterOption: true, size: "small" }, props),
        React.createElement(Option, { value: "carbon_fiber" }, " Carbon Fiber "),
        React.createElement(Option, { value: "kevlar" }, " Kevlar"),
        React.createElement(Option, { value: "fiberglass" }, " Fiberglass "),
        React.createElement(Option, { value: "hsht_fiberglass" }, " HSHT fiberglass "),
        React.createElement(Option, { value: "none" }, " None ")));
};
