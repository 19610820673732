import * as ActionTypes from '../ActionTypes';

export const Products = (
    state = {
        isLoading: false,
        lead_times: []
    },
    action) => {

    switch (action.type) {


        case ActionTypes.LEAD_TIMES_LOADED:
            return {...state, isLoading: false, lead_times: action.payload};

        default:
            return state;
    }
};


