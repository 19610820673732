var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { get, post } from '@partsbadger/utils';
import { UploadFile } from '@partsbadger/library';
import { QuoteStore } from '../../../Stores';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Modal, message } from 'antd';
var Paragraph = Typography.Paragraph;
var InternalFiles = function (_a) {
    var _b;
    var master_product = _a.master_product, quote_id = _a.quote_id;
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    return (React.createElement("div", { className: "flex items-center justify-center" },
        React.createElement("strong", null, "Internal Files:"),
        React.createElement(Paragraph, { ellipsis: { rows: 1, expandable: true }, style: { display: 'inline' }, className: "m-0 ml-1" }, (_b = master_product === null || master_product === void 0 ? void 0 : master_product.internal_files) === null || _b === void 0 ? void 0 : _b.map(function (file) {
            return (React.createElement("div", { key: 'main' },
                React.createElement("div", { key: file.id, className: "link inline-block", 
                    // style={{ display: 'inline' }}
                    onClick: function (e) {
                        e.stopPropagation();
                        get("/staff/files/".concat(file.id, "/internal-file-url/")).then(function (response) {
                            window.open(response.url, '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=800');
                        });
                    } },
                    file.filename,
                    "\u00A0"),
                React.createElement(LegacyIcon, { title: 'Remove file', type: 'cross', className: 'link ml-1 text-red-600', onClick: function (e) {
                        e.stopPropagation();
                        post("/staff/files/".concat(file.id, "/remove-internal-file/")).then(function () {
                            QuoteStore.getMasterProducts(quote_id);
                        });
                    } })));
        })),
        master_product.editable ? (React.createElement(React.Fragment, null,
            React.createElement(LegacyIcon, { title: 'Add files', type: 'plus', className: 'link ml-1', onClick: function () { return setShowModal(!showModal); } }),
            showModal && (React.createElement(Modal, { visible: showModal, centered: true, title: "Internal Files", onCancel: function () { return setShowModal(false); }, footer: [] },
                React.createElement(UploadFile, { onUpload: function (file) {
                        var _a;
                        var current_files = (master_product === null || master_product === void 0 ? void 0 : master_product.internal_files)
                            ? (_a = master_product === null || master_product === void 0 ? void 0 : master_product.internal_files) === null || _a === void 0 ? void 0 : _a.map(function (r) { return r.id; })
                            : [{}];
                        post('/staff/master-products/' + master_product.id + '/upload-internal-file/', {
                            internal_files: __spreadArray(__spreadArray([], current_files, true), [file.response.id], false),
                        })
                            .then(function (response) {
                            setShowModal(false);
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .catch(function () { return message.error('Error, try again'); });
                    } }))))) : (React.createElement(LegacyIcon, { title: 'This master product is used in other quotes', type: 'lock', style: { fontSize: '10px' }, className: "link mr-2 mt-1" }))));
};
export default InternalFiles;
