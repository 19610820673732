var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber, message, Select } from 'antd';
import { Coatings, Finishes, Formulas, HoleTolerances, ManufacturingTypes, PartTolerances, SelectReinforcement, } from '../Select';
import React, { useState } from 'react';
import { QuoteStore } from '../../Stores';
import { Materials } from '../Select/Materials';
import { observer } from 'mobx-react';
var Option = Select.Option;
var InputType = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var dataIndex = props.dataIndex;
    var user = QuoteStore.currentUser;
    var part_vol = 0;
    var total_vol = 0;
    var isATesterUser = user === null || user === void 0 ? void 0 : user.is_tester;
    var _k = useState(false), boolean = _k[0], setBoolean = _k[1];
    var payload = {
        product: {},
    };
    var updateLineItems = function () {
        var items = QuoteStore.lineItems.filter(function (item) { return item.product.master_product === props.record.product.master_product; });
        QuoteStore.updateMultipleLineItems({
            quoted_products: items.map(function (item) { return item.id; }),
            data: payload,
        });
    };
    switch (dataIndex) {
        case 'manufacturing_type':
            return (React.createElement(React.Fragment, null,
                React.createElement(ManufacturingTypes, __assign({}, props, { onSelect: function (item) {
                        QuoteStore.getMaterials({
                            manufacturing_type: item,
                        });
                    } })),
                React.createElement("a", { onClick: function () {
                        payload['manufacturing_type'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'reinforcement':
            return React.createElement(SelectReinforcement, __assign({}, props));
        case 'product__material':
            return (React.createElement(React.Fragment, null,
                React.createElement(Materials, __assign({ materials: QuoteStore.materials.map(function (m) {
                        return {
                            id: m.id,
                            name: m.name,
                        };
                    }), handleSearch: function (params) { return QuoteStore.getMaterials(params); }, style: { margin: '20px' }, suggestions: ((_a = props.record.product.drawing_file) === null || _a === void 0 ? void 0 : _a.suggestions)
                        ? props.record.product.drawing_file.suggestions
                            .filter(function (s) { var _a; return (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id; })
                            .map(function (s) {
                            var _a, _b, _c;
                            return {
                                id: (_a = s.suggested_material) === null || _a === void 0 ? void 0 : _a.id,
                                name: (_b = s.suggested_material) === null || _b === void 0 ? void 0 : _b.name,
                                thumbnail: (_c = s.cropped_image) === null || _c === void 0 ? void 0 : _c.file,
                            };
                        })
                        : [] }, props)),
                ((_b = props.record.product.material) === null || _b === void 0 ? void 0 : _b.name.toLowerCase()) === 'custom' ? (React.createElement("div", null, props.record.product.custom_material)) : (''),
                React.createElement("a", { onClick: function () {
                        payload['product']['material'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'in_house_manufacturing':
            return React.createElement(Checkbox, __assign({ style: { margin: 'auto' } }, props));
        case 'fixed_unit_price':
            return React.createElement(Checkbox, __assign({}, props));
        case 'product__hole_tolerance':
            return (React.createElement(React.Fragment, null,
                React.createElement(HoleTolerances, __assign({}, props)),
                React.createElement("a", { onClick: function () {
                        payload['product']['hole_tolerance'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__part_tolerance':
            return (React.createElement(React.Fragment, null,
                React.createElement(PartTolerances, __assign({}, props)),
                React.createElement("a", { onClick: function () {
                        payload['product']['part_tolerance'] = props.defaultValue ? props.defaultValue.key : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__coatings':
            return (React.createElement(React.Fragment, null,
                React.createElement(Coatings, __assign({ coatings: QuoteStore.coatings.map(function (m) {
                        return {
                            id: m.id,
                            name: m.name,
                            needs_manual_adjust: m.needs_manual_adjust,
                        };
                    }), handleSearch: function (params) { return QuoteStore.getCoatings(params); }, suggestions: ((_c = props.record.product.drawing_file) === null || _c === void 0 ? void 0 : _c.suggestions)
                        ? props.record.product.drawing_file.suggestions
                            .filter(function (s) { var _a; return (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id; })
                            .map(function (s) {
                            var _a, _b, _c;
                            return {
                                id: (_a = s.suggested_coating) === null || _a === void 0 ? void 0 : _a.id,
                                name: (_b = s.suggested_coating) === null || _b === void 0 ? void 0 : _b.name,
                                thumbnail: (_c = s.cropped_image) === null || _c === void 0 ? void 0 : _c.file,
                            };
                        })
                        : [] }, props)),
                ((_d = props.record.product.coating) === null || _d === void 0 ? void 0 : _d.name.toLowerCase()) === '* custom' ? (React.createElement("div", null, props.record.product.custom_coating)) : (''),
                React.createElement("a", { onClick: function () {
                        payload['product']['coatings'] = props.defaultValue ? [props.defaultValue.key] : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'product__finishes':
            return (React.createElement(React.Fragment, null,
                React.createElement(Finishes, __assign({ finishes: QuoteStore.finishes.map(function (m) {
                        return {
                            id: m.id,
                            name: m.name,
                            metric_name: m.metric_name,
                            needs_manual_adjust: m.needs_manual_adjust,
                        };
                    }), handleSearch: function (params) { return QuoteStore.getFinishes(params); }, suggestions: ((_e = props.record.product.drawing_file) === null || _e === void 0 ? void 0 : _e.suggestions)
                        ? props.record.product.drawing_file.suggestions
                            .filter(function (s) { var _a; return (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id; })
                            .map(function (s) {
                            var _a, _b, _c;
                            return {
                                id: (_a = s.suggested_finish) === null || _a === void 0 ? void 0 : _a.id,
                                name: (_b = s.suggested_finish) === null || _b === void 0 ? void 0 : _b.name,
                                thumbnail: (_c = s.cropped_image) === null || _c === void 0 ? void 0 : _c.file,
                            };
                        })
                        : [] }, props)),
                ((_f = props.record.product.finish) === null || _f === void 0 ? void 0 : _f.name.toLowerCase()) === '* custom' ? (React.createElement("div", null, props.record.product.custom_finish)) : (''),
                React.createElement("a", { onClick: function () {
                        payload['product']['finishes'] = props.defaultValue ? [props.defaultValue.key] : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'formula':
            if (props.record) {
                var mp = (_g = QuoteStore.masterProductsByPosition) === null || _g === void 0 ? void 0 : _g.find(function (mp) { var _a, _b, _c; return ((_a = mp === null || mp === void 0 ? void 0 : mp.master_product) === null || _a === void 0 ? void 0 : _a.id) === ((_c = (_b = props.record) === null || _b === void 0 ? void 0 : _b.product) === null || _c === void 0 ? void 0 : _c.master_product); });
                if (mp) {
                    total_vol = mp.master_product.total_volume_inches;
                    part_vol = mp.master_product.part_volume_inches;
                }
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Formulas, __assign({ item: {
                        totalVol: total_vol,
                        partVol: part_vol,
                        has_file_3d: props.record.product.master_product.has_file_3d,
                        analysis_status_v2: (_h = props.record.product.step_file) === null || _h === void 0 ? void 0 : _h.analysis_status_v2,
                    }, selectedMaterialName: (_j = props.record.product.material) === null || _j === void 0 ? void 0 : _j.name }, props)),
                React.createElement("a", { onClick: function () {
                        payload['formula'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")));
        case 'has_material_cert':
            return (React.createElement(React.Fragment, null,
                React.createElement(Checkbox, __assign({ style: { margin: 'auto' } }, props, { onChange: function (event) {
                        props === null || props === void 0 ? void 0 : props.onChange(event.target.checked);
                        setBoolean(event.target.checked);
                    } })),
                React.createElement("a", { onClick: function () {
                        payload['has_material_cert'] = boolean;
                        updateLineItems();
                    } }, "Save for all")));
        case 'is_fai_required':
            return (React.createElement(React.Fragment, null,
                React.createElement(Checkbox, __assign({ style: { margin: 'auto' } }, props, { onChange: function (event) {
                        props.onChange(event.target.checked);
                        setBoolean(event.target.checked);
                    } })),
                React.createElement("a", { onClick: function () {
                        payload['is_fai_required'] = boolean;
                        updateLineItems();
                    } }, "Save for all")));
        case 'fast_lane':
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, __assign({}, props),
                    React.createElement(Select.Option, { value: 'Yes' }, "Yes"),
                    React.createElement(Select.Option, { value: 'No' }, "No"),
                    React.createElement(Select.Option, { value: 'Considered' }, "Considered")),
                React.createElement("a", { onClick: function () {
                        payload['fast_lane'] = props.defaultValue;
                        updateLineItems();
                    } }, "Save for all")));
        case 'partsbadger_production_method':
            return (React.createElement(React.Fragment, null,
                React.createElement(Select, __assign({}, props),
                    React.createElement(Select.Option, { value: 'Fastlane' }, "Fastlane"),
                    React.createElement(Select.Option, { value: 'QuickTurn' }, "QuickTurn"),
                    React.createElement(Select.Option, { value: 'Swiss' }, "Swiss"),
                    React.createElement(Select.Option, { value: 'Traditional' }, "Traditional"),
                    React.createElement(Select.Option, { value: 'No' }, "No")),
                React.createElement("a", { onClick: function () {
                        payload['partsbadger_production_method'] = props.defaultValue;
                        payload['partsbadger_production'] = false;
                        if (props.defaultValue === 'Fastlane') {
                            payload['partsbadger_production'] = true;
                            payload['partsbadger_production_method'] = props.defaultValue;
                        }
                        updateLineItems();
                    } }, "Save for all")));
        case 'lead_time':
            return (React.createElement("div", { className: "input-container", style: { display: 'flex', alignItems: 'center' } },
                React.createElement(InputNumber, __assign({}, props, { value: props.record.lead_time })),
                React.createElement(Button, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var lead_time, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, QuoteStore.updateLT(props.record)];
                                case 1:
                                    lead_time = _a.sent();
                                    props.onChange(lead_time);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _a.sent();
                                    message.error('Error updating lead time');
                                    console.error(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, size: "small" },
                    React.createElement(CaretRightOutlined, null))));
        default:
            return (React.createElement("div", { className: "input-container" },
                React.createElement(InputNumber, __assign({}, props, { onChange: function () { }, onBlur: function (e) {
                        props.onChange(e.target.value);
                    } })),
                props.className === 'product__sides' && (React.createElement("a", { onClick: function () {
                        payload['product']['sides'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'setup_hours' && (React.createElement("a", { onClick: function () {
                        payload['setup_hours'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'programming_hours' && (React.createElement("a", { onClick: function () {
                        payload['programming_hours'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'runtime' && (React.createElement("a", { onClick: function () {
                        payload['runtime'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'total_material' && (React.createElement("a", { onClick: function () {
                        payload['total_material'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'coating1' && (React.createElement("a", { onClick: function () {
                        payload['coating1'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'coating2' && (React.createElement("a", { onClick: function () {
                        payload['coating2'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'extra1' && (React.createElement("a", { onClick: function () {
                        payload['extra1'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all")),
                props.className === 'extra2' && (React.createElement("a", { onClick: function () {
                        payload['extra2'] = props.defaultValue ? props.defaultValue : null;
                        updateLineItems();
                    } }, "Save for all"))));
    }
};
export default observer(InputType);
