var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Table, Empty, InputNumber, Button, Descriptions, notification, message } from 'antd';
var W_SETTINGS = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes';
import IntakeStore from '../../Stores/IntakeStore';
import VendorStore from '../../Stores/VendorStore';
import SalesOrderStore from '../../Stores/SalesOrderStore';
import { observer } from 'mobx-react';
import { ErrorRender } from '@partsbadger/library';
var BarcodeScanner = function (_a) {
    var id = _a.id;
    var _b = useState(false), reading = _b[0], setReading = _b[1];
    var _c = useState(false), loadingData = _c[0], setLoadingData = _c[1];
    var _d = useState([]), selectedItems = _d[0], setSelectedItems = _d[1];
    var _e = useState({ current: 1, pageSize: 40 }), pagination = _e[0], setPagination = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var handleChangeInput = function (vendorProductId, quantity) {
        VendorStore.updateTheProductFromTheList(vendorProductId, Number(quantity));
    };
    var columns = [
        {
            title: 'Box Name',
            // width: 220,
            key: 'box_name',
            render: function (record) { return (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.name))); },
        },
        {
            title: 'Purchase Order',
            render: function (record) { return (React.createElement(React.Fragment, null,
                React.createElement("span", null, record.purchase_order))); },
        },
        {
            title: 'Required Ship Date',
            // width: 240,
            render: function (record) { return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("span", { className: "font-bold" }, "Partner Ship Date:"),
                    React.createElement("span", null, record.partner_ship_date)),
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("span", { className: "font-bold" }, "Req. Ship Date:"),
                    React.createElement("span", null, record.required_ship_date)))); },
        },
        {
            title: 'Line Item',
            // width: 220,
            // dataIndex: 'line_items__name',
            render: function (record) {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null, (_b = (_a = record.line_items[0]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '-')));
            },
        },
        {
            title: 'Awarded Vendor',
            // dataIndex: 'awardedVendor',
            render: function (record) {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null, (_b = (_a = record.line_items[0]) === null || _a === void 0 ? void 0 : _a.awarded_vendor) !== null && _b !== void 0 ? _b : '-')));
            },
        },
        {
            title: 'Quantity Ordered',
            // dataIndex: 'salesOrderLineItemQuantity',
            render: function (record) {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null, (_b = (_a = record.line_items[0]) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : '-')));
            },
        },
        {
            title: 'Quantity Remaining',
            // dataIndex: 'quantityRemainingToReceive',
            render: function (record) {
                var _a, _b;
                return (React.createElement(React.Fragment, null,
                    React.createElement("span", null, (_b = (_a = record.line_items[0]) === null || _a === void 0 ? void 0 : _a.quantity_remaining_to_receive) !== null && _b !== void 0 ? _b : '-')));
            },
        },
        {
            title: 'Quantity Received',
            fixed: 'right',
            render: function (record) {
                return (React.createElement(InputNumber, { min: 1, max: 9999, value: record.quantity_shipped, onChange: function (value) {
                        if (value) {
                            handleChangeInput(record.vendorProductZId, value);
                        }
                    } }));
            },
        },
        {
            title: 'Action',
            render: function (record) {
                return React.createElement("a", { onClick: function () { return VendorStore.RemoveProduct(record.vendorProductZId); } }, "Remove");
            },
        },
    ];
    function getTheProduct(product, salesOrderData) {
        return __awaiter(this, void 0, void 0, function () {
            var filteredLineItems, filteredSalesOrderData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, salesOrderData.line_items.filter(function (item) { return item.product_zid == product.product; })];
                    case 1:
                        filteredLineItems = _a.sent();
                        filteredSalesOrderData = __assign(__assign({}, salesOrderData), { vendorProductId: product.id, vendorProductZId: product.product, line_items: filteredLineItems, quantity_shipped: product.quantity_shipped });
                        VendorStore.setProductData(filteredSalesOrderData);
                        return [2 /*return*/];
                }
            });
        });
    }
    function checkIfThisProductExists(productData) {
        return __awaiter(this, void 0, void 0, function () {
            var productDuplicated, productExistsButWithDifferentProductId, quantity;
            return __generator(this, function (_a) {
                productDuplicated = VendorStore.scannedProducts.find(function (item) { return item.product === productData.product && item.id === productData.id; });
                if (productDuplicated) {
                    return [2 /*return*/, { status: 'Duplicated', product: productDuplicated }];
                }
                productExistsButWithDifferentProductId = VendorStore.scannedProducts.find(function (item) { return item.product === productData.product && item.id !== productData.id; });
                if (productExistsButWithDifferentProductId) {
                    quantity = productData.quantity_shipped + productExistsButWithDifferentProductId.quantity_shipped;
                    VendorStore.updateTheProductFromTheList(productData.product, quantity);
                    VendorStore.setScannedProducts(productData);
                    return [2 /*return*/, { status: 'ProductToSum' }];
                }
                VendorStore.setScannedProducts(productData);
                return [2 /*return*/, { status: 'Not Found' }];
            });
        });
    }
    var processBarcodeStartingWith1 = function (barcode) { return __awaiter(void 0, void 0, void 0, function () {
        var removeFirstDigit, shipmentItem, doesThisProductExists, salesOrderData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    removeFirstDigit = barcode.slice(1);
                    return [4 /*yield*/, VendorStore.getShipmentItemInfoFromVendorApp(removeFirstDigit)
                            .then(function (res) {
                            return res;
                        })
                            .catch(function (err) {
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                        })];
                case 1:
                    shipmentItem = _a.sent();
                    return [4 /*yield*/, checkIfThisProductExists(shipmentItem)];
                case 2:
                    doesThisProductExists = _a.sent();
                    if (!((doesThisProductExists === null || doesThisProductExists === void 0 ? void 0 : doesThisProductExists.status) === 'Not Found')) return [3 /*break*/, 4];
                    return [4 /*yield*/, SalesOrderStore.checkIfThereIsSalesOrder(shipmentItem.sales_order)];
                case 3:
                    salesOrderData = _a.sent();
                    return [2 /*return*/, getTheProduct(shipmentItem, salesOrderData)];
                case 4:
                    if ((doesThisProductExists === null || doesThisProductExists === void 0 ? void 0 : doesThisProductExists.status) === 'Duplicated') {
                        throw new Error('This product has already been added to your list.');
                    }
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    notification.error({
                        duration: 10,
                        message: 'Error',
                        description: React.createElement(ErrorRender, { error: error_1 }),
                        placement: 'topRight',
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var processBarcodeStartingWith0 = function (barcode) { return __awaiter(void 0, void 0, void 0, function () {
        var intakeRep, groupedSalesOrderItems, salesOrders, _i, salesOrders_1, salesOrder, salesOrderData, products, showNotificationError, _a, products_1, product_item, doesThisProductExists, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 12, , 13]);
                    return [4 /*yield*/, VendorStore.getShipmentInfoFromVendorApp(barcode)
                            .then(function (res) {
                            return res;
                        })
                            .catch(function (err) {
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                        })];
                case 1:
                    intakeRep = _b.sent();
                    groupedSalesOrderItems = intakeRep === null || intakeRep === void 0 ? void 0 : intakeRep.product_outtakes.reduce(function (result, item) {
                        if (!result[item.sales_order]) {
                            result[item.sales_order] = [];
                        }
                        result[item.sales_order].push(item);
                        return result;
                    }, {});
                    if (!groupedSalesOrderItems) return [3 /*break*/, 11];
                    salesOrders = Object.keys(groupedSalesOrderItems);
                    _i = 0, salesOrders_1 = salesOrders;
                    _b.label = 2;
                case 2:
                    if (!(_i < salesOrders_1.length)) return [3 /*break*/, 11];
                    salesOrder = salesOrders_1[_i];
                    return [4 /*yield*/, SalesOrderStore.checkIfThereIsSalesOrder(salesOrder)];
                case 3:
                    salesOrderData = _b.sent();
                    products = groupedSalesOrderItems[salesOrder];
                    showNotificationError = false;
                    _a = 0, products_1 = products;
                    _b.label = 4;
                case 4:
                    if (!(_a < products_1.length)) return [3 /*break*/, 9];
                    product_item = products_1[_a];
                    return [4 /*yield*/, checkIfThisProductExists(product_item)];
                case 5:
                    doesThisProductExists = _b.sent();
                    if (!((doesThisProductExists === null || doesThisProductExists === void 0 ? void 0 : doesThisProductExists.status) === 'Not Found')) return [3 /*break*/, 7];
                    return [4 /*yield*/, getTheProduct(product_item, salesOrderData)];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7:
                    if ((doesThisProductExists === null || doesThisProductExists === void 0 ? void 0 : doesThisProductExists.status) === 'Duplicated') {
                        showNotificationError = true;
                    }
                    _b.label = 8;
                case 8:
                    _a++;
                    return [3 /*break*/, 4];
                case 9:
                    if (showNotificationError) {
                        throw new Error('Some products were not added because they are already in your list.');
                    }
                    _b.label = 10;
                case 10:
                    _i++;
                    return [3 /*break*/, 2];
                case 11: return [3 /*break*/, 13];
                case 12:
                    error_2 = _b.sent();
                    notification.error({
                        duration: 10,
                        message: 'Error',
                        description: React.createElement(ErrorRender, { error: error_2 }),
                        placement: 'topRight',
                    });
                    return [3 /*break*/, 13];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    var fetchBarcode = function (fullBarcode) { return __awaiter(void 0, void 0, void 0, function () {
        var firstDigit;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    firstDigit = fullBarcode.charAt(0);
                    setLoadingData(true);
                    if (fullBarcode.length < 12) {
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Barcode does not have 12 digits.',
                            placement: 'topRight',
                        });
                        return [2 /*return*/];
                    }
                    if (!(firstDigit === '1')) return [3 /*break*/, 2];
                    return [4 /*yield*/, processBarcodeStartingWith1(fullBarcode)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, processBarcodeStartingWith0(fullBarcode)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setLoadingData(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var barcode = '';
    function handleBarcodeScanner(e) {
        if (e.key == 'Enter') {
            fetchBarcode(barcode);
            barcode = '';
        }
        else if (e.key !== 'Shift') {
            barcode += e.key;
        }
        if (!reading) {
            setReading(true);
            setTimeout(function () {
                barcode = '';
                setReading(false);
            }, 200);
        }
    }
    useEffect(function () {
        document.addEventListener('keydown', handleBarcodeScanner, false);
        return function () {
            VendorStore.cleanProductData();
            VendorStore.cleanScannedProducts();
            SalesOrderStore.cleanSalesOrderList();
            document.removeEventListener('keydown', handleBarcodeScanner, false);
        };
    }, []);
    var rowSelection = {
        onChange: function (selectedRowKeys, selectedRows) {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: function (record) {
            var _a;
            return ({
                // id: record.vendorProductId,
                disabled: ((_a = record.line_items[0]) === null || _a === void 0 ? void 0 : _a.quantity_remaining_to_receive) === 0,
            });
        },
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    payload = selectedItems.map(function (item) {
                        var _a;
                        return {
                            id: item.id,
                            // vendorProductId: item.vendorProductId,
                            line_items: [
                                {
                                    id: (_a = item.line_items[0]) === null || _a === void 0 ? void 0 : _a.id,
                                    quantity: item.quantity_shipped,
                                },
                            ],
                        };
                    });
                    return [4 /*yield*/, IntakeStore.multipleOrdersIntake(payload, true)
                            .then(function (res) {
                            selectedItems.forEach(function (item) {
                                VendorStore.RemoveProduct(item.vendorProductZId);
                            });
                            SalesOrderStore.cleanSalesOrderList();
                            message.success("This product has been sucessfully intaked");
                            setLoading(false);
                        })
                            .catch(function (err) {
                            notification.error({
                                duration: 10,
                                message: 'Error',
                                description: React.createElement(ErrorRender, { error: err }),
                                placement: 'topRight',
                            });
                            setLoading(false);
                        })];
                case 1:
                    _a.sent();
                    setSelectedItems([]);
                    return [2 /*return*/];
            }
        });
    }); };
    var emptyTable = function () {
        return (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: React.createElement("span", { style: { color: '#000' } }, "The table is currently empty. Please, scan the barcode or manually enter the sales order name.") }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex justify-end mt-5 mb-5" },
            React.createElement(Button, { className: "font-bold", type: "primary", disabled: selectedItems.length === 0 ? true : false, onClick: function () { return handleSubmit(); }, loading: loading }, "Submit and Print")),
        React.createElement(Table, { rowSelection: __assign({ type: 'checkbox' }, rowSelection), 
            // className="records-list"
            columns: columns, rowKey: function (record) { return (record.vendorProductZId ? record.vendorProductZId : record.id); }, loading: loadingData, dataSource: VendorStore.productsData, locale: { emptyText: emptyTable }, pagination: pagination, scroll: window.innerWidth <= 1160 ? { x: 1160 } : { x: true }, 
            // tableLayout="fixed"
            expandable: {
                expandedRowRender: function (record) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                    return (React.createElement("div", { className: "flex flex-row justify-around" },
                        React.createElement("div", null,
                            React.createElement(Descriptions, { title: "Order Info:", column: 2, className: "descriptions-antd" },
                                React.createElement(Descriptions.Item, { label: "Status" }, record.status),
                                React.createElement(Descriptions.Item, { label: "Shipping Status" }, record.shipping_status),
                                React.createElement(Descriptions.Item, { label: "Terms", span: 2 }, record.payment_terms ? record.payment_terms : '-'),
                                React.createElement(Descriptions.Item, { label: "Customer Po Files", contentStyle: { display: 'flex', flexDirection: 'column' }, span: 2 }, record.po_files.map(function (_a) {
                                    var id = _a.id, filename = _a.filename, file = _a.file;
                                    return (React.createElement("a", { key: "pf-".concat(id), onClick: function () { return window.open(file, filename, W_SETTINGS); } }, filename));
                                })))),
                        React.createElement("div", null,
                            React.createElement(Descriptions, { title: "Order Requirements:", column: 1, className: "descriptions-antd" },
                                React.createElement(Descriptions.Item, { label: "In House Requirements" }, (_a = record.in_house_requirements) !== null && _a !== void 0 ? _a : '-'),
                                React.createElement(Descriptions.Item, { label: "Additional Requirements" }, (_c = (_b = record.additional_requirements) === null || _b === void 0 ? void 0 : _b.join(', ')) !== null && _c !== void 0 ? _c : '-'),
                                React.createElement(Descriptions.Item, { label: "Shipping Options" }, (_d = record.shipping_options) !== null && _d !== void 0 ? _d : '-'),
                                React.createElement(Descriptions.Item, { label: "Shipping Requirements" }, (_f = (_e = record.shipping_requirements) === null || _e === void 0 ? void 0 : _e.join(', ')) !== null && _f !== void 0 ? _f : '-'))),
                        React.createElement("div", null,
                            React.createElement(Descriptions, { title: "Shipping Information:", column: 1, className: "descriptions-antd " },
                                React.createElement(Descriptions.Item, { label: "Shipping Info" }, (_g = record.shipping_name) !== null && _g !== void 0 ? _g : '-'),
                                React.createElement(Descriptions.Item, { label: "Carrier" }, (_h = record.account.carrier) !== null && _h !== void 0 ? _h : '-'),
                                React.createElement(Descriptions.Item, { label: "Shipping Method" }, (_k = (_j = record.account) === null || _j === void 0 ? void 0 : _j.shipping_method) !== null && _k !== void 0 ? _k : '-'),
                                React.createElement(Descriptions.Item, { label: "Shipping Account Number" }, (_m = (_l = record.account) === null || _l === void 0 ? void 0 : _l.shipping_account_number) !== null && _m !== void 0 ? _m : '-'))),
                        React.createElement("div", null,
                            React.createElement(Descriptions, { title: "Contact:", column: 1, className: "descriptions-antd" },
                                React.createElement(Descriptions.Item, { label: "Name" }, (_o = record.contact) === null || _o === void 0 ? void 0 : _o.fullname),
                                React.createElement(Descriptions.Item, { label: "Email" }, (_q = (_p = record.contact) === null || _p === void 0 ? void 0 : _p.email) !== null && _q !== void 0 ? _q : '-')))));
                },
                // rowExpandable: record => record.title,
            } })));
};
export default observer(BarcodeScanner);
