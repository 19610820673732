import React from 'react';

const RfqTicker = (props: any) => {
    if (!props?.ticker?.current) {
        return null;
    }
    return (
        <div className="flex justify-between w-full">
            <div>
                Total:
                <strong
                    style={{
                        fontSize: '20px',
                    }}
                >
                    {' '}
                    #{props?.ticker?.total}{' '}
                </strong>
            </div>
            <div>
                Next RFQ to quote:
                <strong
                    style={{
                        fontSize: '20px',
                    }}
                >
                    {' '}
                    #{props?.ticker?.current}{' '}
                </strong>
            </div>
        </div>
    );
};

export default RfqTicker;
