import * as React from 'react';
import { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Row } from 'antd';
import './styles.css';
import { TaxExemptForm, TaxExemptList } from '../../../components/Contact/taxes';
import { Accounts } from '@partsbadger/library';

export const ManageTaxForms = () => {
    const [account, setAccount] = useState();
    const [addNew, setAddNew] = useState();
    const [taxForm, setTaxForm] = useState({});

    const editAddress = record => {
        setTaxForm(record);
        setAddNew(false);
    };

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}> Tax Exemption Forms</div>
            </Divider>

            <div style={{ marginTop: 10 }}>
                <Form.Item label={'Account'}>
                    <Accounts
                        onSelect={account => {
                            setAccount(account);
                        }}
                    />
                </Form.Item>
            </div>

            {account?.key && (
                <>
                    {addNew || taxForm?.id ? (
                        <div style={{ marginTop: 10 }}>
                            <TaxExemptForm
                                tax_form={taxForm}
                                account={account?.key}
                                callback={() => {
                                    setAddNew(false);
                                    setTaxForm({});
                                }}
                            />
                        </div>
                    ) : (
                        <div style={{ marginTop: 10 }}>
                            <TaxExemptList account_id={account?.key} handleEdit={editAddress} />

                            <div className={'flex flex-row-reverse pt-4'}>
                                <Button type={'default'} onClick={() => setAddNew(true)}>
                                    Add New{' '}
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
