import React, { Fragment, useEffect, useState } from 'react';

import { PurchaseOderTool } from '@partsbadger/purchase_order_tool';
import { getCurrentUser } from '../../components/User';
import { get } from '../../shared';
import FileDownload from 'js-file-download';
import { Button, Divider, notification, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { PurchaseOrdersFilterForm } from './PurchaseOrdersFilter';
import { PurchaseOrderProps } from '@partsbadger/types';
import { PaginationProps } from 'antd/es/pagination';
import { ErrorRender } from '@partsbadger/library';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PurchaseOrder = (props: any) => {
    const current_user: any = getCurrentUser()?.data;
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<PaginationProps>({});
    const [purchase_orders, setPurchaseOrders] = useState<Array<PurchaseOrderProps>>([]);
    const searchParams = useQuery();
    const history = useHistory();

    const getPurchaseOrders = (params = {}) => {
        console.log(params);
        get('staff/purchase-orders/', params)
            .then((response: any) => {
                setPurchaseOrders(response.data.results);
                setPagination({
                    pageSize: 30,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    const downloadPdf = (order_id: number, order_name: string, template_name: string) => {
        setLoading(true);
        get(`staff/purchase-orders/${order_id}/download-pdf/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;
                FileDownload(data, `${order_name}.pdf`);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error))
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownloadAllPdf = async (value: React.Key[]) => {
        setLoading(true);
        notification.info({
            message: 'Info',
            description: 'The download will take some time, please wait.',
        });
        try {
            await get(
                `staff/purchase-orders/download-all-pfd/?purchase_orders=${value}`,
                {},
                {
                    responseType: 'blob',
                }
            ).then((response: { data: string | ArrayBuffer | ArrayBufferView | Blob }) => {
                FileDownload(response.data, `PDF-Purchase-Orders.zip`);
            });
            setLoading(false);
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: <ErrorRender error={error} />,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        getPurchaseOrders(searchParams);
    }, [props.location.search]);

    return (
        <Fragment>
            <PurchaseOrdersFilterForm />

            <div className={'right'}>
                <Button
                    type={'primary'}
                    className={'ant-btn'}
                    onClick={() => {
                        history.push('/purchase-orders/new');
                    }}
                >
                    New Purchase Order
                </Button>
            </div>
            <Divider>
                <div className={'title'}> Purchase Orders</div>
            </Divider>

            <Spin spinning={loading}>
                <PurchaseOderTool
                    current_user={current_user}
                    downloadPdf={downloadPdf}
                    purchase_orders={purchase_orders}
                    pagination={pagination}
                    onReload={() => {
                        getPurchaseOrders();
                    }}
                    downloadAllPdf={handleDownloadAllPdf}
                />
            </Spin>
        </Fragment>
    );
};

export default PurchaseOrder;
