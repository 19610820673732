import { action, makeObservable, observable } from 'mobx';
import { get, patch, post, remove } from '@partsbadger/utils';
import {
    IAction,
    IBusinessRulesStructure,
    IContentType,
    IRule,
    IRuleConfiguration,
    IVariable,
    IVariableTypeOperator,
    ModuleField,
} from '../types';

class BusinessRule {
    rules: IRule[] = [];
    rule: IRule | null = null;
    actions: IAction[] = [];
    model_fields: ModuleField[] = [];
    variables: IVariable[] = [];
    variable_type_operators: IVariableTypeOperator | null = null;

    ContentTypes: IContentType[] = [];

    constructor() {
        makeObservable(this, {
            // Observables
            rules: observable,
            rule: observable,
            actions: observable,
            variables: observable,
            variable_type_operators: observable,

            ContentTypes: observable,

            // Actions
            getAllRules: action,
            getById: action,
            saveRule: action,
            delete: action,
            getStructure: action,
            setRule: action,
            updateRuleConfigurationField: action,
        });
    }

    // Api Actions
    getAllRules = async (params: any) => {
        this.rules = [];
        const data = await get(`/staff/business-rules/`, {
            params: params,
        });
        this.rules = data.results;
    };

    getById = async (rule_id: string) => {
        this.rule = await get(`/staff/business-rules/${rule_id}/`);
    };

    saveRule = async (payload: {
        id: number | null;
        name: string;
        model: string;
        configuration: IRuleConfiguration;
        active: boolean;
    }) => {
        if (payload.id) {
            const data = await patch(`/staff/business-rules/${payload.id}/`, payload);
            this.rule = null;
            // this.rules = [...this.rules, data];
        } else {
            const data = await post(`/staff/business-rules/`, payload);
            this.rule = null;
            this.rules = [...this.rules, data];
        }
    };

    delete = async (rule_id: number) => {
        await remove(`/staff/business-rules/${rule_id}/`);
        this.rules = this.rules.filter(r => r.id != rule_id);
    };

    getStructure = async (module_name: string) => {
        this.variable_type_operators = null;
        const r = await get(`/staff/business-rules/describe/${module_name}/`);
        const data: IBusinessRulesStructure = r.structure;
        this.model_fields = r.fields;
        this.variables = data.variables;
        this.actions = data.actions;
        this.variable_type_operators = data.variable_type_operators;
    };

    // Module Store Actions

    setRule(rule: IRule | null) {
        this.rule = rule;
    }

    updateRuleField<K extends keyof IRule, V extends IRule[K]>(key: K, value: V) {
        if (this.rule != undefined) {
            this.rule = {
                ...this.rule,
                [key]: value,
            };
        }
    }

    updateRuleConfigurationField<K extends keyof IRuleConfiguration, V extends IRuleConfiguration[K]>(
        key: K,
        value: V
    ) {
        if (this.rule != undefined) {
            this.rule.configuration = {
                ...this.rule.configuration,
                [key]: value,
            };
        }
    }
}

export const BusinessRuleStore = new BusinessRule();
