import { observable, runInAction, makeObservable, action } from 'mobx';
import { get, post, patch, remove } from '@partsbadger/utils';
import { IStorageLocation } from '@partsbadger/types';

class StorageLocationStore {
    storages: IStorageLocation[] = [];
    storage: IStorageLocation | null = null;

    constructor() {
        makeObservable(this, {
            // Observables
            storages: observable,
            storage: observable,

            // Actions
            get: action,
            list: action,
            create: action,
            patch: action,
            remove: action,
        });
    }

    async get(id: number) {
        const response = await get(`/staff/storage-location/${id}`);
        runInAction(() => {
            this.storage = response;
        });
    }

    async list(params = {}) {
        const response = await get(`/staff/storage-location/`, params);
        runInAction(() => {
            this.storages = response.results;
        });
    }

    async create(payload: IStorageLocation) {
        const response = await post(`/staff/storage-location/`, payload);
        runInAction(() => {
            this.storages.push(response);
        });
    }

    async patch(payload = {}, id: number) {
        const response = await patch(`/staff/storage-location/${id}/`, payload);
        runInAction(() => {
            this.storage = response;
            this.list();
        });
    }

    async remove(id: number) {
        return await remove(`/staff/storage-location/${id}`).then(() => {
            this.list();
        });
    }
}

export default new StorageLocationStore();
