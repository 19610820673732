import * as ActionTypes from '../ActionTypes';

export const Materials = (
    state = {
        isLoading: false,
        error: null,
        entities: [],
        colors: [],
        specs: [],
    },
    action) => {
    switch (action.type) {
        case ActionTypes.MATERIALS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};

        case ActionTypes.MATERIALS_COLORS_LOADED:
            return {...state, isLoading: false, error: null, colors: action.payload};

        case ActionTypes.MATERIALS_SPECIFICATIONS_LOADED:
            return {...state, isLoading: false, error: null, specs: action.payload};


        case ActionTypes.MATERIALS_LOADING:
        case ActionTypes.MATERIALS_COLORS_LOADING:
        case ActionTypes.MATERIALS_SPECIFICATIONS_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.MATERIALS_FAILED:
        case ActionTypes.MATERIALS_COLORS_ERROR:
        case ActionTypes.MATERIALS_SPECIFICATIONS_ERROR:
            return {...state, isLoading: false, error: action.error};

        default:
            return state;
    }
};