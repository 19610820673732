import React from 'react';
import { Menu, Typography } from 'antd';

import { data } from '../../components/GuidedTour/index';
import { version } from '../../../package.json';
import { UserStore } from '../../stores/UserStore';
import SalesTicker from '../SalesTicker';
import { hasPermission } from '../../components/User';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const DashboardPage = () => {
    const changelog = Object.keys(data).map(key => {
        return (
            <div key={key}>
                <Title
                    key={key}
                    level={5}
                    style={{
                        borderBottom: ' 1px solid var(--color-border-muted)',
                    }}
                >
                    {key}
                </Title>
                <ul>
                    {data[key].map((change, index) => {
                        return (
                            <li style={{}} key={index}>
                                {change.content} ({change.version})
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    });
    return (
        <div style={{ padding: '25px' }}>
            {UserStore.isAuthenticated &&
            (hasPermission('salesorders.view_salesorder') || hasPermission('salesorders.view_own_salesorder')) ? (
                <div>
                    <SalesTicker />
                </div>
            ) : (
                <div>
                    <h3 className={'step-home'}> PartsBadger, Your Online CNC Machine Shop</h3>
                    <p className={'step-description'}>
                        We believe ordering custom parts ought to be simple, fast, and straightforward. When we designed
                        and created our Instant Quote system, we began with the goal of making prices available right
                        away. We know that getting a price on your part without having to wait allows you to move
                        faster, which means your ideas move forward. Let PartsBadger become a part of your supply chain
                        as Your Online CNC Machine Shop
                    </p>
                    <Title level={3} className={'step-change-logs'}>
                        Change Log (Current {version})
                    </Title>

                    <div>{changelog}</div>
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
