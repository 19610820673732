import React, { useState, useCallback } from 'react';

import {
    Modal,
    Button,
    Form,
    FormItemProps,
    Input,
    notification,
    Select,
    Typography,
    Upload,
    Checkbox,
    message,
} from 'antd';

const { Title } = Typography;

import { ErrorRender, FormErrorParser } from '@partsbadger/library';

import { TicketStore } from '../../../stores/TicketStore';

import { SalesOrderProps, OrderLineItemProps } from '@partsbadger/types';

import { UploadOutlined } from '@ant-design/icons';
import { UserStore } from 'stores/UserStore';
const CheckboxGroup = Checkbox.Group;

interface IProps {
    showRequestOrderAdjustment: boolean;
    onClose: () => void;
    order: SalesOrderProps;
}

interface IProductList {
    value: string;
    label: string;
    first_article_approval: boolean | undefined;
}

const RequestOrderAdjustment: React.FC<IProps> = props => {
    const [loading, setLoading] = useState(false);

    const { showRequestOrderAdjustment, onClose, order } = props;

    const [form] = Form.useForm();

    const formItemLayout: FormItemProps = {
        labelCol: { span: 24 },
        wrapperCol: { span: 22 },
        labelAlign: 'left',
    };

    const selectOptions = [
        {
            value: 'Design Change Requests',
            label: 'Design Change Requests',
        },
        {
            value: 'Hold/Cancellation Requests',
            label: 'Hold/Cancellation Requests',
        },
        {
            value: 'Submit Sample Rejection or Approval',
            label: 'Submit Sample Rejection or Approval',
        },
    ];

    const productsList: IProductList[] = order?.line_items
        .filter(product => product.product_zid !== null)
        .map((item: any) => {
            return {
                value: item?.product_zid,
                label: item.name,
                first_article_approval: item.first_article_approval,
            };
        });

    if (order?.line_items.length >= 1 && productsList.length === 0) {
        notification.error({
            key: 'form-errors',
            duration: 8,
            message: 'Products Not Linked to Zoho ID',
            description: "We couldn't find products associated with a Zoho ID.",
            placement: 'top',
            maxCount: 1,
        });
    }

    const handleSubmit = () => {
        setLoading(true);

        form.validateFields()
            .then(async values => {
                const getConfirmationStatus = () => {
                    switch (values.reason) {
                        case 'Design Change Requests':
                            return `Does Production have to hold production?: ${values.confirmation_status}`;
                        case 'Hold/Cancellation Requests':
                            return `Select either “Cancel” or “Hold”: ${values.confirmation_status}`;
                        case 'Submit Sample Rejection or Approval':
                            return `Select either “Approved” or “Rejected”: ${values.confirmation_status}`;
                        default:
                            return `Confirmation Status: ${values.confirmation_status}`;
                    }
                };

                const body_message = `Reason: ${values.reason}, ${getConfirmationStatus()}, Order: ${
                    order?.name
                }, User submitted: ${UserStore?.user?.email}, Details: ${values.details}`;
                const formData = new FormData();

                if (values?.files?.fileList?.length > 1) {
                    values?.files?.fileList?.forEach((item: any) => {
                        formData.append('attachments[]', item?.xhr);
                    });
                } else if (values?.files?.fileList?.length === 1) {
                    formData.append('attachment', values?.files?.file?.xhr);
                }

                values.line_items.forEach((item: string) => {
                    formData.append('products[]', item);
                });

                formData.append('body', body_message);

                if (values?.details?.length < 5 || values?.details?.length > 200) {
                    notification.error({
                        message:
                            'Please note that the minimum character limit is 5, while the maximum allowed is 200 characters.',
                        placement: 'topRight',
                    });
                    setLoading(false);
                    return true;
                }

                if (order?.zoho_id) {
                    await TicketStore.createTicket(formData, order?.zoho_id)
                        .then(() => {
                            notification.success({
                                message: 'The team will reach out shortly with an update on your request.',
                                placement: 'topRight',
                            });

                            if (order?.zoho_id) {
                                const params = {
                                    zoho_id: order?.zoho_id,
                                    message_type: 'production-sales',
                                    status: 'OPEN',
                                    search: '',
                                };

                                TicketStore.getAll(params).catch(err => {
                                    notification.error({
                                        message: <ErrorRender error={err} />,
                                        placement: 'topRight',
                                    });
                                });
                            }

                            onClose();
                        })
                        .catch((err: any) => {
                            notification.error({
                                message: <ErrorRender error={err} />,
                                placement: 'topRight',
                            });
                        });
                }

                setLoading(false);
            })
            .catch(errorInfo => {
                setLoading(false);

                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: <FormErrorParser isAntd4 errors={errorInfo?.errorFields} />,
                    placement: 'top',
                    maxCount: 1,
                });
            });
    };

    const productsListValidator = useCallback(
        (reason: string) => {
            if (reason === 'Submit Sample Rejection or Approval') {
                const products = productsList.filter(i => i.first_article_approval === true);

                if (productsList.length >= 1 && products.length === 0) {
                    form.setFields([
                        {
                            name: 'line_items',
                            value: products,
                            errors: ['First Article Approval Required for Products'],
                        },
                    ]);

                    notification.error({
                        key: 'form-errors',
                        duration: 8,
                        message: 'Products List Unavailable:',
                        description: 'First Article Approval Required for Products',
                        placement: 'top',
                        maxCount: 1,
                    });
                }
                return products;
            }
            return productsList;
        },
        [productsList]
    );

    const customRequest = (options: any) => {
        const { file, onSuccess } = options;

        if (onSuccess) {
            const reader = new window.FileReader();

            if (file) {
                reader.readAsDataURL(file);
                reader.onload = (...args) => {
                    onSuccess('done', file);
                };
            }
        }
    };

    return (
        <>
            <Modal
                open={showRequestOrderAdjustment}
                title="Request Order Adjustment"
                width={600}
                destroyOnClose={true}
                onCancel={() => onClose()}
                // bodyStyle={{ overflowY: 'scroll', overflowX: 'auto', height: '60vh' }}
                footer={[
                    <>
                        <div key={1} className="flex justify-between">
                            <Button
                                onClick={() => {
                                    onClose();
                                }}
                                size="large"
                                loading={loading}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                loading={loading}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </>,
                ]}
            >
                <Form form={form}>
                    <div className="flex flex-col">
                        <p>
                            Please use this form to create a Order Adjustment Request. Your submission will be sent to
                            the Sales Representative Team.
                        </p>

                        <div>
                            <Form.Item
                                name="reason"
                                label="Reason for Order Adjustment Request"
                                {...formItemLayout}
                                rules={[{ required: true, message: 'Please, select one Reason' }]}
                            >
                                <Select
                                    placeholder="Select one reason"
                                    options={selectOptions}
                                    onChange={() => {
                                        form.resetFields(['confirmation_status']);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item noStyle dependencies={['reason']}>
                                {({ getFieldValue }) => {
                                    return (
                                        <Form.Item
                                            name="line_items"
                                            label="Products"
                                            rules={[{ required: true, message: 'Select the products' }]}
                                            style={
                                                productsListValidator(getFieldValue('reason')).length >= 1
                                                    ? { maxHeight: '90px', overflow: 'auto' }
                                                    : {}
                                            }
                                        >
                                            <CheckboxGroup
                                                options={productsListValidator(getFieldValue('reason'))}
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Form.Item noStyle dependencies={['reason']}>
                                {({ getFieldValue }) => {
                                    return getFieldValue('reason') === 'Design Change Requests' ? (
                                        <Form.Item
                                            name="confirmation_status"
                                            label={'Does Production have to hold production?'}
                                            rules={[{ required: true, message: 'Hold production option is required.' }]}
                                            {...formItemLayout}
                                        >
                                            <Select
                                                // style={{ width: 200 }}
                                                options={[
                                                    {
                                                        value: 'Yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'No',
                                                        label: 'No',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    ) : getFieldValue('reason') === 'Submit Sample Rejection or Approval' ? (
                                        <Form.Item
                                            name="confirmation_status"
                                            label={'Select either “Approved” or “Rejected”'}
                                            rules={[{ required: true, message: 'Approval status is required.' }]}
                                            {...formItemLayout}
                                        >
                                            <Select
                                                // style={{ width: 200 }}
                                                options={[
                                                    {
                                                        value: 'Approved',
                                                        label: 'Approved',
                                                    },
                                                    {
                                                        value: 'Rejected',
                                                        label: 'Rejected',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    ) : (
                                        getFieldValue('reason') === 'Hold/Cancellation Requests' && (
                                            <Form.Item
                                                name="confirmation_status"
                                                label={'Select either “Cancel” or “Hold”'}
                                                rules={[
                                                    { required: true, message: 'Confirmation option is required.' },
                                                ]}
                                                {...formItemLayout}
                                            >
                                                <Select
                                                    // style={{ width: 200 }}
                                                    options={[
                                                        {
                                                            value: 'Cancel',
                                                            label: 'Cancel',
                                                        },
                                                        {
                                                            value: 'Hold',
                                                            label: 'Hold',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        )
                                    );
                                }}
                            </Form.Item>
                            <Form.Item
                                label="Details"
                                {...formItemLayout}
                                name="details"
                                rules={[{ required: true, message: 'Please, input a note' }]}
                                className="w-full"
                            >
                                <Input.TextArea
                                    className="w-full"
                                    minLength={5}
                                    maxLength={200}
                                    placeholder="Please enter your order adjustment requests"
                                />
                            </Form.Item>

                            <Form.Item noStyle dependencies={['reason']}>
                                {({ getFieldValue }) => {
                                    return (
                                        getFieldValue('reason') !== 'Hold/Cancellation Requests' && (
                                            <Form.Item
                                                label="Files"
                                                {...formItemLayout}
                                                name="files"
                                                rules={[{ required: false, message: 'Please input a file.' }]}
                                            >
                                                <Upload.Dragger
                                                    multiple={true}
                                                    showUploadList={true}
                                                    customRequest={customRequest}
                                                    beforeUpload={file => {
                                                        const isLt2M = file.size / 1024 / 1024 <= 100;

                                                        if (!isLt2M) {
                                                            message.error('File must be smaller than 100MB!', 10);
                                                            return false;
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="flex justify-center items-center align-middle"
                                                        style={{ height: '87px' }}
                                                    >
                                                        <UploadOutlined
                                                            style={{ fontSize: '34px', color: '#405764' }}
                                                        />
                                                        <span className="text-base ml-1">Click or drag files</span>
                                                    </div>
                                                </Upload.Dragger>
                                            </Form.Item>
                                        )
                                    );
                                }}
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default RequestOrderAdjustment;
