import * as ActionTypes from '../ActionTypes';

export const Orders = (
    state = {
        isLoading: false,
        error: null,
        showAddOrderForm: false,
        entities: [],
        entity: {},
        products: []
    },
    action) => {

    switch (action.type) {
        case ActionTypes.ORDER_LOADED:
            return {...state, isLoading: false, error: null, entity: action.payload};

        case ActionTypes.ORDERS_LOADED:
            return {...state, isLoading: false, error: null, entities: action.payload};


        case ActionTypes.ORDER_CREATED:
            return {...state, isLoading: false, error: null};

        case ActionTypes.ORDER_LOADING:
            return {...state, isLoading: true, error: null}

        case ActionTypes.ORDER_FAILED:
            return {...state, isLoading: false, error: action.error};

        case ActionTypes.SHOW_FORM_ADD_ORDER:
            return {...state, showAddOrderForm: true};

        case ActionTypes.HIDE_FORM_ADD_ORDER:
            return {...state, showAddOrderForm: false};

        case ActionTypes.ORDER_ADD_ITEMS:
            return {...state, isLoading: false, error: null, products: action.payload};

        case ActionTypes.ORDER_ADD_ITEM:
            return {...state, isLoading: false, error: null, products: [...state.products, action.payload]};

        case ActionTypes.ORDER_REMOVE_ITEM:
            const products = state.products.filter(product => product.id !== action.id)
            return {...state, isLoading: false, error: null, products: products};


        default:
            return state;
    }
};