import React, { Fragment } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Divider, Row, Table, Select } from 'antd';
import moment from 'moment';
import { get } from '../../shared';
import dayjs from 'dayjs';
import { DateRangePicker } from '@partsbadger/library';

const { Column } = Table;
const { Option } = Select;
const FormItem = Form.Item;

const { RangePicker } = DatePicker;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ',';
            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

function fileDownload(data, filename, mime, bom) {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 0);
    }
}

/**
 * @param value in Seconds
 * @returns {string|null} as hour format
 * @constructor
 */
const RenderAsTime = ({ value }) => {
    if (!value) {
        return null;
    }

    try {
        //Seconds should be a integer and for avg could be decimals
        const rounded = Math.round(value / 10) * 10;

        const hour_format = new Date(rounded * 1000).toISOString().substr(11, 8);
        const minutes = rounded / 60;

        return `${hour_format} (${minutes.toFixed(1)})`;
    } catch (e) {
        return <div>{value}</div>;
    }
};

class ReportFilter extends React.Component {
    state = {
        datesSelected: false, //Status to check whether dates have been selected
    };
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = {};
                if (values.vendor_rfq_type) {
                    params['vendor_rfq_type'] = values.vendor_rfq_type;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                this.props.getData(params);
            }
        });
    };

    handleSelectChange = value => {
        this.props.form.setFieldsValue({ vendor_rfq_type: value });
        this.handleSubmit({ preventDefault: () => {} }); // To invoke handleSubmit without actual event
    };

    handleDateChange = (value, dateString) => {
        this.setState({ datesSelected: !!dateString[0] }); // Activate the field if dates are selected

        if (dateString && dateString.length > 0 && dateString[0] !== undefined) {
            this.props.form.setFieldsValue({ created_time: value });
            this.handleSubmit({ preventDefault: () => {} }); // To invoke handleSubmit without actual event
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { datesSelected } = this.state;

        return (
            <Row className={'row-filters'}>
                <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col md={4}>
                            <FormItem label="Date">
                                {getFieldDecorator(
                                    'created_time',
                                    {}
                                )(<DateRangePicker onChange={this.handleDateChange} />)}
                            </FormItem>
                        </Col>
                        <Col md={5}>
                            <FormItem label="Filter By Vendor RFQ">
                                {getFieldDecorator('vendor_rfq_type', {
                                    initialValue: '',
                                })(
                                    <Select allowClear onChange={this.handleSelectChange} disabled={!datesSelected}>
                                        <Option value="domestic">Domestic</Option>
                                        <Option value="foreign">Overseas</Option>
                                        <Option value="all">All (Domestic and Overseas)</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col md={8}>
                            <FormItem label="">
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>
        );
    }
}

const ReportFilterForm = Form.create()(ReportFilter);

const ListReport = ({ data, loading }) => {
    return (
        <div>
            <Table dataSource={data} loading={loading} pagination={false}>
                <Column
                    title="#"
                    dataIndex="subject"
                    key="subject"
                    render={(t, r) => {
                        return (
                            <div>
                                <div>{t}</div>
                                <div>{r.type}</div>
                            </div>
                        );
                    }}
                />

                <Column title="Submitted Date" render={(t, r) => dayjs(r?.submitted_date).format('LLL')} />

                <Column title="Start Quoting Date" render={(t, r) => dayjs(r?.start_quoting_date).format('LLL')} />

                <Column title="Completed Date" render={(t, r) => dayjs(r?.completed_date).format('LLL')} />

                <Column
                    title="Avg Queue Time"
                    dataIndex="avg_queue_time"
                    key="avg_queue_time"
                    render={(t, r) => <RenderAsTime value={Number(r.avg_queue_time)} />}
                />
                <Column
                    title="Vendor RFQ Type"
                    dataIndex="vendor_rfq_type"
                    key="vendor_rfq_type"
                    render={(t, r) => {
                        return (
                            <>
                                {r.vendor_rfq_type === 'domestic'
                                    ? 'Domestic'
                                    : r.vendor_rfq_type === 'foreign'
                                    ? 'Overseas'
                                    : 'N/A'}
                            </>
                        );
                    }}
                />
                <Column
                    title="Avg Vendor RFQ Time"
                    dataIndex="avg_vendor_rfq_time"
                    key="avg_vendor_rfq_time"
                    render={(t, r) => <RenderAsTime value={r.avg_vendor_rfq_time} />}
                />

                <Column
                    title="Avg Processing Time"
                    dataIndex="avg_processing_time"
                    key="avg_processing_time"
                    render={(t, r) => <RenderAsTime value={Number(r.avg_processing_time)} />}
                />

                <Column
                    title="Avg Total Time"
                    dataIndex="avg_total_time"
                    key="avg_total_time"
                    render={(t, r) => <RenderAsTime value={r.avg_total_time} />}
                />
            </Table>
        </div>
    );
};

class ReportQuoteTimeMetrics extends React.Component {
    state = {
        data: [],
        loading: false,
    };

    getData = params => {
        this.setState({
            loading: true,
        });

        get(`staff/quoted-products/report-avg-times/`, params)
            .then(response => {
                this.setState({
                    data: response.data,
                    loading: false,
                });
            })
            .catch(error => console.error(error));
    };

    componentDidMount() {
        this.getData({});
    }

    render() {
        const avg_queue_time_items = this.state.data.filter(item => Number(item.avg_queue_time) > 0);
        const avg_processing_time_items = this.state.data.filter(item => Number(item.avg_processing_time) > 0);
        const avg_total_time_items = this.state.data.filter(item => Number(item.avg_total_time) > 0);
        const avg_vendor_rfq_time_items = this.state.data.filter(item => Number(item.avg_vendor_rfq_time) > 0);

        const avg_queue_time =
            avg_queue_time_items.reduce((total, item) => total + Number(item.avg_queue_time), 0) /
            avg_queue_time_items.length;
        const avg_processing_time =
            avg_processing_time_items.reduce((total, item) => total + Number(item.avg_processing_time), 0) /
            avg_processing_time_items.length;
        const avg_total_time =
            avg_total_time_items.reduce((total, item) => total + Number(item.avg_total_time), 0) /
            avg_total_time_items.length;
        const avg_vendor_rfq_time =
            avg_vendor_rfq_time_items.reduce((total, item) => total + Number(item.avg_vendor_rfq_time), 0) /
            avg_vendor_rfq_time_items.length;

        return (
            <Fragment>
                <ReportFilterForm getData={this.getData} />

                <Divider>
                    <div className={'title'}> Quote Time Metrics</div>
                </Divider>

                <div className={'my-5'}>
                    <div>
                        Avg. Queue Time: <RenderAsTime value={avg_queue_time} />
                    </div>
                    <div>
                        Avg. Processing Time: <RenderAsTime value={avg_processing_time} />
                    </div>
                    <div>
                        Avg. Total Time: <RenderAsTime value={avg_total_time} />
                    </div>
                    <div>
                        Avg. Vendor RFQ Time: <RenderAsTime value={avg_vendor_rfq_time} />
                    </div>
                    <div>#Quotes: {this.state.data.length}</div>
                </div>

                <Row>
                    <Col span={24}>
                        <ListReport data={this.state.data} loading={this.state.loading} />
                    </Col>
                </Row>

                <div className={'my-5'}>
                    <Button
                        onClick={() => {
                            const csvStr = convertToCSV(this.state.data);
                            const csv = new Blob([csvStr], { type: 'text/csv;charset=utf-8;' });
                            fileDownload(csv, 'report-quote-metrics.csv', 'application/csv');
                        }}
                    >
                        Export <FileExcelOutlined />
                    </Button>
                </div>
            </Fragment>
        );
    }
}

export default ReportQuoteTimeMetrics;
