var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { useDrag } from 'react-dnd';
var style = {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
    height: 'auto',
    border: '1px #000 dashed',
};
export var PartItem = function (_a) {
    var index = _a.index, file = _a.file, type = _a.type, isDropped = _a.isDropped, handleRemove = _a.handleRemove;
    var _b = useDrag(function () { return ({
        type: type,
        item: { index: index, file: file, type: type },
    }); }), collected = _b[0], drag = _b[1];
    return (React.createElement("div", { className: 'draggable-item', ref: drag, style: __assign({}, style) }, isDropped ? (React.createElement("span", null)) : (React.createElement("div", { className: "flex flex-row" },
        React.createElement(DragOutlined, { className: "ml-auto" }),
        React.createElement("div", { className: "mx-2" }, file === null || file === void 0 ? void 0 : file.name),
        React.createElement(LegacyIcon, { title: 'Remove file', className: "mr-auto cursor-pointer", onClick: handleRemove && handleRemove, type: 'delete' })))));
};
