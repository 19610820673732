import React, { useState } from 'react';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Select } from 'antd';
import { default as AddressItem } from './AddressItem';
var Option = Select.Option;
var Addresses = React.forwardRef(function (props, ref) {
    var _a, _b;
    var _c = props.addresses, addresses = _c === void 0 ? [] : _c, value = props.value, onChange = props.onChange, onAddNewAddress = props.onAddNewAddress, _d = props.loading, loading = _d === void 0 ? false : _d;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    if (open) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Select, { loading: loading, allowClear: true, showSearch: true, style: { width: 250 }, placeholder: "Select an Address", dropdownMatchSelectWidth: false, open: open, value: value === null || value === void 0 ? void 0 : value.id, notFoundContent: null, optionFilterProp: "children", filterOption: function (input, option) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                    return (((_e = (_d = (_c = (_b = (_a = option === null || option === void 0 ? void 0 : option.props) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.props) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.name) === null || _e === void 0 ? void 0 : _e.toLowerCase().indexOf(input.toLowerCase())) >=
                        0 ||
                        ((_k = (_j = (_h = (_g = (_f = option === null || option === void 0 ? void 0 : option.props) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g.props) === null || _h === void 0 ? void 0 : _h.address) === null || _j === void 0 ? void 0 : _j.street) === null || _k === void 0 ? void 0 : _k.toLowerCase().indexOf(input.toLowerCase())) >= 0);
                }, dropdownRender: function (menu) { return (React.createElement("div", { className: 'shadow p-2' },
                    menu,
                    React.createElement(Divider, { style: { margin: '4px 0' } }),
                    React.createElement("div", { style: { padding: '6px 8px', cursor: 'pointer' }, onMouseDown: function (e) { return e.preventDefault(); }, onClick: function () {
                            if (onAddNewAddress) {
                                onAddNewAddress();
                                setOpen(false);
                            }
                        } },
                        React.createElement("span", { className: "link" },
                            React.createElement(PlusOutlined, null),
                            " New Address")))); }, onSelect: function (key) {
                    var address = addresses === null || addresses === void 0 ? void 0 : addresses.find(function (address) { return address.id === parseInt(key); });
                    if (onChange && address) {
                        onChange(address);
                    }
                    setOpen(false);
                } }, addresses === null || addresses === void 0 ? void 0 : addresses.map(function (address) { return (React.createElement(Option, { key: address.id },
                React.createElement(AddressItem, { address: address }))); })),
            React.createElement(Button, { type: 'primary', style: { marginLeft: '2px' }, onMouseDown: function (e) { return e.preventDefault(); }, onClick: function () {
                    setOpen(false);
                } },
                React.createElement(CloseOutlined, null),
                " Cancel")));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { "data-testid": "".concat((_b = (_a = value === null || value === void 0 ? void 0 : value.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : 'billing-and-shipping', "-address") }, value && React.createElement(AddressItem, { address: value })),
            React.createElement("span", { className: 'link', style: { marginLeft: '2px' }, onMouseDown: function (e) { return e.preventDefault(); }, onClick: function () {
                    if (addresses === null || addresses === void 0 ? void 0 : addresses.length) {
                        setOpen(true);
                    }
                    else {
                        onAddNewAddress && onAddNewAddress();
                    }
                } },
                React.createElement(EditOutlined, null),
                " ",
                (addresses === null || addresses === void 0 ? void 0 : addresses.length) ? 'Change' : 'Add New Address')));
    }
});
Addresses.displayName = 'Addresses';
export default Addresses;
