import { Switch } from 'antd';
import React, { useState } from 'react';
var BatchReleasesSwitcher = function (props) {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (React.createElement("div", null,
        React.createElement("label", { className: 'font-bold' }, "Batch Releases"),
        React.createElement(Switch, { className: 'ml-2', defaultChecked: props.initialValue, size: "small", loading: isLoading, onChange: function (checked) {
                setIsLoading(true);
                props.handleUpdate(checked).finally(function () {
                    setIsLoading(false);
                });
            } })));
};
export default BatchReleasesSwitcher;
