import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popconfirm, Upload } from 'antd';
import { ALLOWED_FILES } from '../Inputs/UploadFile/FileExtensions';
import { observer } from 'mobx-react';
import { getToken } from '../User';
import { BASE_URL } from '../../constants';
import { DocumentsProps } from '../../../utils/types';
import { DeleteOutlined } from '@ant-design/icons';

interface Props {
    documents?: Array<DocumentsProps>;
    remove_document?: (index: number, document_id: number) => void;
    multiple: boolean;
    handleChange: (value: any) => void;
    model_id: number;
    model: string;
}

export const UploadDocument = observer((props: Props) => {
    const token = getToken();

    const popup = (url: string) => {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };

    const url = `staff/${props.model}/${props.model_id}/upload_docs/`;
    const allowed_files = ALLOWED_FILES.allowed_2d.map(r => `.${r.toLowerCase()}`).toString();

    return (
        <>
            <Upload.Dragger
                name="file"
                action={BASE_URL + '/' + url}
                headers={{
                    Authorization: `Token ${token}`,
                }}
                accept={allowed_files}
                onChange={props.handleChange}
                multiple={props.multiple}
            >
                <p className="ant-upload-text">
                    <LegacyIcon type={'upload'} />
                    Click or drag file to this area to upload
                </p>
            </Upload.Dragger>

            <hr />
            {props.documents &&
                props.documents.map((document: DocumentsProps, index: number) => {
                    return (
                        <div key={document.id} className={'flex flex-row'}>
                            <div className={'w-3/4'}>
                                <a onClick={() => popup(document.file)} rel="noreferrer">
                                    {document.filename}
                                </a>
                            </div>
                            <div className={'w-1/4'}>
                                <Popconfirm
                                    title="Are you sure delete this document?"
                                    onConfirm={() => props.remove_document && props.remove_document(index, document.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <span className={'link'} style={{ float: 'right' }}>
                                        <DeleteOutlined />
                                    </span>
                                </Popconfirm>
                            </div>
                            <br />
                        </div>
                    );
                })}
        </>
    );
});
