import React from 'react';
import { Comment, Typography } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
var Text = Typography.Text;
export var NotesListCard = function (_a) {
    var sales_order_notes = _a.sales_order_notes;
    return (React.createElement(React.Fragment, null, sales_order_notes === null || sales_order_notes === void 0 ? void 0 : sales_order_notes.map(function (note) {
        var _a;
        return (React.createElement(React.Fragment, null,
            React.createElement(Comment, { key: note.id, author: (_a = note.created_by) === null || _a === void 0 ? void 0 : _a.fullname, avatar: React.createElement(FileSearchOutlined, null), content: React.createElement("p", null,
                    React.createElement("b", null,
                        note.title,
                        ": "),
                    note.content), datetime: React.createElement(Text, null, dayjs(note.created_time).format('LLLL')) })));
    })));
};
