import * as ActionTypes from "../ActionTypes";
import { get, patch, post, remove } from '../../shared/api'

import { showNotification } from "./notificationsActions";
import { fetchProductsByQuote } from "./productActions";


export const fetchQuotes = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTES_LOADING});
        return get(`staff/quotes/`, params).then(response => {
            const new_pagination = {
                defaultPageSize: 30,
                total: response.data.count,
            };

            dispatch({type: ActionTypes.QUOTES_LOADED, payload: response.data.results, pagination: new_pagination});
        }).catch(error => dispatch({type: ActionTypes.QUOTES_FAILED, error: error}));
    }
}


export const fetchQuotedProducts = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTES_LOADING});
        return get("staff/quoted-products/", params).then(response => {

            dispatch({type: ActionTypes.QUOTES_BY_ACCOUNT_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.QUOTES_FAILED, error: error}));
    }
}


export const postQuote = (quote, ownsProps) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTE_LOADING});
        return post("staff/quotes/", quote).then(response => {
            dispatch({type: ActionTypes.QUOTE_ADDED, payload: response.data});
            ownsProps.history.push(`/quotes/${response.data.id}`)
        }).catch(error => dispatch({type: ActionTypes.QUOTE_FAILED, error: error}));
    }
}


export const removeProductAdditionalRequirement = (id) => {
    return (dispatch, getState) => {
        return remove("staff/product-additional-requirements/" + id).then(response => {
            const quote = getState()?.quotes?.entity?.id
            dispatch(fetchProductsByQuote(quote))
            dispatch(fetchQuoteById(quote))
        }).catch(error => {
                const quote = getState()?.quotes?.entity?.id
                dispatch(fetchProductsByQuote(quote))
                dispatch({type: ActionTypes.QUOTE_FAILED, error: error});
            }
        );
    }
}
//todo remove quote param
export const AddProductAdditionalRequirement = (quote, payload) => {
    return (dispatch, getState) => {
        return post("staff/quoted-products/additional-requirements/", payload).then(response => {
            const quote = getState()?.quotes?.entity?.id
            dispatch(fetchProductsByQuote(quote))
            dispatch(fetchQuoteById(quote))
        }).catch(error => {
            dispatch({type: ActionTypes.QUOTE_FAILED, error: error});
        });
    }
}


/**
 * Delete Quote
 * @param {*} values
 */


/**
 * Fetch Quotes by Deal
 * @param id
 */
export const fetchQuotesByDeal = (id) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTE_LOADING});
        return get("staff/quotes/?deal=" + id).then(response => {
            dispatch({type: ActionTypes.QUOTE_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.QUOTE_FAILED, error: error}));
    }
}


/**
 * Fetch Quote by Id
 * @param id
 */
export const fetchQuoteById = (id) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTE_LOADING});
        return get("staff/quotes/" + id + "/").then(response => {
            dispatch({type: ActionTypes.QUOTE_LOADED, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.QUOTE_FAILED, error: error}));
    }
}


export const fetchAdditionalRequirements = (params) => {

    return (dispatch) => {
        dispatch({type: ActionTypes.ADDITIONAL_REQUIREMENTS_LOADING});
        return get("staff/additional-requirements/", params)
            .then(response => {
                dispatch({type: ActionTypes.ADDITIONAL_REQUIREMENTS_LOADED, payload: response.data.results})
            }).catch(error => dispatch({type: ActionTypes.ADDITIONAL_REQUIREMENTS_ERROR, error: error}))

    }
}


/**
 * Show the Modal Form to Add Quote
 * @returns {{type: string}}
 */
export const showQuoteForm = () => ({
    type: ActionTypes.QUOTE_ADD_FORM
});

/**
 * Hide the Modal Form to Add Quote
 * @returns {{type: string}}
 */
export const hideQuoteForm = () => ({
    type: ActionTypes.QUOTE_HIDE_ADD_FORM
});


export const fetchQuotesByAuthorGrouped = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTES_LOADING});
        return get("staff/quotes-summary/", params).then(response => {

            dispatch({type: ActionTypes.QUOTES_REPORT_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.QUOTES_FAILED, error: error}));
    }
}

export const fetchRFQById = (id, ownsProps, convert) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.RFQ_LOADING});
        return get(`staff/rfq/${id}/`).then(response => {
            dispatch({type: ActionTypes.RFQ_LOADED, payload: response.data});

            if (convert === true) {


                if (response.data.ready_to_quote) {
                    const payload = {
                        rfq: id
                    };

                    dispatch(RfqToQuote(payload, ownsProps))
                } else {
                    dispatch(showNotification("RFQ is not completed yet"))
                }


            }


        }).catch(error => dispatch({type: ActionTypes.RFQ_ERROR, error: error}));
    }
}


export const updateRFQAndQuote = (id, payload, ownsProps) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.QUOTE_LOADING});
        return patch("staff/rfq/" + id + "/", payload).then(response => {
            dispatch(fetchRFQById(response.data.id, ownsProps, true));
        }).catch(error => dispatch({type: ActionTypes.QUOTE_FAILED, error: error}));
    }
}


export const RfqToQuote = (payload, ownsProps) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.RFQ_LOADING});
        return post("staff/quotes/rfq-to-quote/", payload).then(response => {
            ownsProps.history.push(`/quotes/` + response.data.id)
        }).catch(error => dispatch({type: ActionTypes.RFQ_ERROR, error: error}));
    }
}

export const IqToQuote = (payload, ownsProps) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.INSTANT_QUOTES_LOADING});
        return post("staff/quotes/iq-to-quote/", payload).then(response => {
            ownsProps.history.push(`/quotes/` + response.data.id)
        }).catch(error => {
            dispatch({type: ActionTypes.INSTANT_QUOTES_ERROR, error: error})
        });
    }
}


export const fetchRFQS = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.RFQ_LOADING});
        return get(`staff/rfq/`, params).then(response => {

            const new_pagination = {
                defaultPageSize: 30,
                total: response.data.count,
            };

            dispatch({
                type: ActionTypes.RFQS_LOADED, payload: response.data.results, pagination: new_pagination
            });
            sessionStorage.setItem('drawingFiles', null)
            sessionStorage.setItem('stepFiles', null)
            sessionStorage.removeItem('otherFiles')


        }).catch(error => dispatch({type: ActionTypes.RFQ_ERROR, error: error}));
    }
}


export const fetchInstantQuoteOrderById = (id) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.INSTANT_QUOTES_LOADING});
        return get("staff/instant-quote-orders/" + id).then(response => {

            dispatch({type: ActionTypes.INSTANT_QUOTES_ORDER_LOADED, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.INSTANT_QUOTES_ERROR, error: error}));
    }
}


export const fetchInstantQuotes = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.INSTANT_QUOTES_LOADING});
        return get("staff/instant-quotes/", params).then(response => {

            dispatch({type: ActionTypes.INSTANT_QUOTES_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.INSTANT_QUOTES_ERROR, error: error}));
    }
}

export const fetchInstantQuotesOrders = (params) => {
    return (dispatch) => {
        dispatch({type: ActionTypes.INSTANT_QUOTES_ORDERS_LOADING});
        return get("staff/instant-quote-orders/", params).then(response => {

            dispatch({type: ActionTypes.INSTANT_QUOTES_ORDERS_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.INSTANT_QUOTES_ORDERS_ERROR, error: error}));
    }
}
