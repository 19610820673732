import React from 'react';
import { Button, Checkbox, Col, Form, Input, notification, Select, Switch, Typography } from 'antd';
import { observer } from 'mobx-react';
import BusinessVariableForm from './BusinessVariableForm';
import BusinessActionsForm from './BusinessActionsForm';
import { BusinessRuleStore } from './Stores/BusinessRuleStore';
import { ICondition, ICurrentActions, IRule, IRuleConfiguration } from './types';
import { useHistory } from 'react-router-dom';
import { FormErrorParser } from '@partsbadger/library';
import { ContentTypeStore } from '../../stores';

const Option = Select.Option;

interface IBusinessForm {
    rule: IRule;
}

const BusinessRuleForm = (props: IBusinessForm) => {
    const [form] = Form.useForm();
    const history = useHistory();

    React.useEffect(() => {
        // BusinessRuleStore.getAllContentTypes();
        if (props.rule.model_name) BusinessRuleStore.getStructure(props.rule.model_name);
    }, [props.rule.model_name]);

    const handleClickAddNewCondition = () => {
        const new_condition: ICondition = {
            name: '',
            operator: '',
            value: '',
        };

        BusinessRuleStore.updateRuleConfigurationField('conditions', {
            all: [...(props.rule.configuration.conditions.all ?? []), new_condition],
        });
    };
    const handleClickAddNewAction = () => {
        const actions: ICurrentActions[] = [
            ...(rule_configuration?.actions ?? []),
            {
                name: '',
                params: [],
            },
        ];
        BusinessRuleStore.updateRuleConfigurationField('actions', actions);
    };

    const onFinish = async (values: {
        model: {
            label: string;
            key: string;
        };
        name: string;
        active: boolean;
    }) => {
        await BusinessRuleStore.saveRule({
            id: entity.id,
            model: values.model.key,
            name: values.name,
            configuration: rule_configuration,
            active: values.active,
        });
        history.push('/business-rules/');
    };

    const entity = props.rule;

    const rule_configuration = entity.configuration;

    return (
        <Form
            layout={'inline'}
            labelAlign={'left'}
            labelCol={{
                md: 24,
            }}
            wrapperCol={{
                md: 24,
            }}
            form={form}
            onFinishFailed={errorInfo => {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: <FormErrorParser isAntd4 errors={errorInfo.errorFields} />,
                    placement: 'top',
                    maxCount: 1,
                });
            }}
            onFinish={onFinish}
        >
            <Col span={24}>
                <Form.Item label="Name" name="name" initialValue={entity.name} rules={[{ required: true }]}>
                    <Input placeholder="Insert Rule Name" allowClear />
                </Form.Item>
                <Form.Item
                    label="Module"
                    name="model"
                    initialValue={{
                        key: entity.model,
                        label: entity.model_name,
                    }}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a option"
                        onChange={value => {
                            BusinessRuleStore.getStructure(value.label);
                            BusinessRuleStore.getAllRules(value.label);
                            BusinessRuleStore.updateRuleField('model_name', value.label);
                        }}
                        allowClear
                        dropdownMatchSelectWidth={false}
                        labelInValue
                    >
                        {ContentTypeStore.contentTypes.map(c => (
                            <Option key={c.id} value={c.id}>
                                {c.model}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Active" name="active" initialValue={entity.active} valuePropName={'checked'}>
                    <Switch />
                </Form.Item>
            </Col>

            <Col span={12}>
                {rule_configuration.conditions.all.map((c, condition_index) => {
                    return (
                        <BusinessVariableForm
                            key={`${c.name}-${condition_index}`}
                            row={condition_index}
                            name={c.name}
                            operator={c.operator}
                            value={c.value}
                            handleChange={values => {
                                const _rule: IRuleConfiguration = {
                                    ...rule_configuration,
                                };

                                _rule.conditions.all[condition_index] = {
                                    ...rule_configuration.conditions.all[condition_index],
                                    ...values,
                                };

                                BusinessRuleStore.updateRuleConfigurationField('conditions', _rule.conditions);
                            }}
                            handleClickDelete={() => {
                                const _new_conditions = {
                                    all:
                                        rule_configuration?.conditions.all.filter(
                                            (current, index) => index != condition_index
                                        ) ?? [],
                                };

                                BusinessRuleStore.updateRuleConfigurationField('conditions', _new_conditions);
                            }}
                        />
                    );
                })}
                <Button className={'mt-2'} type={'link'} onClick={handleClickAddNewCondition}>
                    Add Condition
                </Button>
            </Col>
            <Col span={12}>
                {/*<pre>*/}
                {/*    {JSON.stringify(rule_configuration, null, 3)}*/}
                {/*</pre>*/}

                {rule_configuration.actions.map((a, action_index) => {
                    return (
                        <BusinessActionsForm
                            key={action_index}
                            row={action_index}
                            name={a.name}
                            currentParams={a.params}
                            handleChange={values => {
                                const _rule: IRuleConfiguration = {
                                    ...rule_configuration,
                                };

                                _rule.actions[action_index] = {
                                    ..._rule.actions[action_index],
                                    ...values,
                                };

                                BusinessRuleStore.updateRuleConfigurationField('actions', _rule.actions);
                            }}
                            handleClickDelete={() => {
                                BusinessRuleStore.updateRuleConfigurationField(
                                    'actions',
                                    rule_configuration?.actions.filter((current, index) => index != action_index) ?? []
                                );
                            }}
                        />
                    );
                })}
                <Button className={'mt-2'} type={'link'} onClick={handleClickAddNewAction}>
                    Add Action
                </Button>
            </Col>

            <Col
                span={24}
                style={{
                    marginTop: 30,
                    textAlign: 'center',
                }}
            >
                <Button type={'primary'} size={'large'} htmlType="submit">
                    Save Changes
                </Button>
            </Col>
        </Form>
    );
};

export default observer(BusinessRuleForm);
