import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, Modal, Select } from 'antd';
import { Accounts, Contacts, Deals } from "@partsbadger/library";
import { QuoteStore } from "../../../stores";

const ButtonGroup = Button.Group;
const Option = Select.Option;

const FormEditModal = (props) => {

    const {form, handleClose} = props;
    const [loading, setLoading] = useState(false);

    const {getFieldDecorator} = form;

    const quote = QuoteStore.quote;


    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const payload = {
                    ...values,
                    deal: values.deal.key,
                    contact: values.contact.key,
                    account: values.account.key,
                    owner: values.owner.key,
                    design_engineer: values?.design_engineer?.key || null,
                };

                setLoading(true)

                QuoteStore.updateQuote(quote.id, payload).then(() => {
                    setLoading(false);
                    handleClose();
                })
            }
        });
    }


    const formItemLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 14},
    };


    return (
        <Modal
            visible
            title="Edit Quote"
            onCancel={handleClose}
            footer={null}
        >
            <Form onSubmit={handleSubmit}>

                <Form.Item
                    labelCol={{span: 6}}
                    wrapperCol={{span: 16}}
                    label="Deal"
                >
                    {getFieldDecorator('deal', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: quote?.deal ? {
                            'key': quote.deal.id,
                            'label': quote.deal.name,
                        } : {}

                    })(
                        <Deals/>
                    )}


                </Form.Item>


                <Form.Item label={"Account"} {...formItemLayout}  >
                    {getFieldDecorator('account', {
                        rules: [{
                            required: true, message: 'Please select a value!',
                        }],
                        initialValue: quote?.account ? {
                            'key': quote?.account.id,
                            'label': quote?.account.name,
                        } : {}
                    })(
                        <Accounts/>
                    )}
                </Form.Item>

                <Form.Item label={"Contact"} {...formItemLayout}  >
                    {getFieldDecorator('contact', {
                        rules: [{
                            required: true, message: 'Please select a value!',
                        }], initialValue: {
                            'key': quote?.contact?.id,
                            'label': quote?.contact?.fullname
                        }
                    })(
                        <Contacts/>
                    )}
                </Form.Item>







                <Form.Item label={"Type"} {...formItemLayout}  >
                    {getFieldDecorator('type', {
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: quote?.type

                    })(
                        <Select dropdownMatchSelectWidth={false}>
                            <Option value="Standard"> Standard </Option>
                            <Option value="Requote"> Requote </Option>
                            <Option value="Rapid Quote"> Rapid Quote</Option>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label={"Name."} {...formItemLayout}  >
                    {getFieldDecorator('name', {
                        rules: [{
                            required: false, message: 'Please select a value!',
                        }],
                        initialValue: quote?.name

                    })(
                        <Input/>
                    )}
                </Form.Item>

                <Form.Item label={"High Risk"} {...formItemLayout}  >
                    {getFieldDecorator('high_risk', {
                        valuePropName: "checked",
                        rules: [{required: true, message: 'Please select a value!'}],
                        initialValue: quote?.high_risk

                    })(
                        <Checkbox/>
                    )}
                </Form.Item>


                <Form.Item label={"European"} {...formItemLayout}  >
                    {getFieldDecorator('european_customer', {
                        valuePropName: "checked",
                        initialValue: quote?.european_customer

                    })(
                        <Checkbox/>
                    )}
                </Form.Item>

                <Form.Item label={"Shipping Zip Code"} {...formItemLayout}  >
                    {getFieldDecorator('shipping_zip_code', {
                        initialValue: quote?.shipping_zip_code

                    })(
                        <Input/>
                    )}
                </Form.Item>

                <Form.Item label={"Order of Items"} {...formItemLayout}  >
                    {getFieldDecorator('ordering_line_items', {
                        initialValue: quote?.ordering_line_items

                    })(
                        <Select dropdownMatchSelectWidth={false}>
                            <Option value="created_time"> Created Time </Option>
                            <Option value="quantity"> Quantity </Option>
                        </Select>
                    )}
                </Form.Item>

                {quote.editable && (
                    <ButtonGroup className={"add-button"}>
                        <Button type="primary" htmlType="submit" icon={<LegacyIcon type={"plus"} />}
                                loading={loading}>
                            Save
                        </Button>
                    </ButtonGroup>
                )}

            </Form>
            <br/>
            <br/>
            <br/>
        </Modal>
    );
}


export const QuoteEditModal = Form.create()(FormEditModal);
