import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { Edit3DFilesApproval } from './Edit3DFilesApproval';
import { UploadFileInfo } from '../UploadFileInfo';
import { ALLOWED_FILES } from '@partsbadger/utils';
export var Edit3DPartsFilesApproval = function (props) {
    var record = props.record;
    var _a = useState(false), showAddStepFile = _a[0], setShowAddStepFile = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var renderStepFile = function (id) {
        var url = "/viewer/".concat(id, "/");
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    return (React.createElement(Spin, { spinning: loading, tip: 'Analyzing file, please wait.' },
        React.createElement("div", { className: 'flex flex-col itemsCenter' },
            React.createElement("div", { className: 'flex flex lg:flex-row md:flex-col sm:flex-col itemsCenter' },
                React.createElement(Edit3DFilesApproval, { record: record, setShowAddStepFile: setShowAddStepFile, renderStepFile: renderStepFile }),
                React.createElement(Modal, { visible: showAddStepFile, centered: true, title: "Add Step File", onCancel: function () { return setShowAddStepFile(false); }, footer: false },
                    React.createElement("div", { className: 'flex justify-center' }, ALLOWED_FILES.Files3D.map(function (file) {
                        return file.toUpperCase() + ' | ';
                    })),
                    React.createElement(UploadFileInfo, { file3DApproval: true, typeFile: '3D', accept: ALLOWED_FILES.Files3D, setShowDragger: setShowAddStepFile, record: record }))))));
};
