import React, { useState } from 'react';
import { Modal, Select, Button } from 'antd';
var Option = Select.Option;
var DuplicationReasonPopup = function (_a) {
    var isOpen = _a.isOpen, partName = _a.partName, type = _a.type, onChangeReason = _a.onChangeReason, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var _b = useState(''), selectedReason = _b[0], setSelectedReason = _b[1];
    var handleConfirm = function () {
        onConfirm(selectedReason);
    };
    var handleChange = function (value) {
        setSelectedReason(value);
        if (onChangeReason)
            onChangeReason(value);
    };
    var modalTitle = type === 'request_requote' ? 'Request for Requote' : 'Select a reason for the new file';
    var modalMessage = type === 'request_requote'
        ? "Please select a reason for the change related to the part ".concat(partName, " not being quoted and your request for a new quote.")
        : "It appears that you\u2019re updating a file for ".concat(partName, ". Please select a reason for the change.");
    return (React.createElement(Modal, { title: modalTitle, visible: isOpen, onOk: handleConfirm, onCancel: onCancel, footer: [
            React.createElement(Button, { key: "back", onClick: onCancel }, "Cancel"),
            React.createElement(Button, { key: "submit", type: "primary", onClick: handleConfirm, disabled: !selectedReason }, "Confirm"),
        ] },
        React.createElement("p", null, modalMessage),
        React.createElement(Select, { value: selectedReason, onChange: handleChange, style: { width: '100%' } },
            React.createElement(Option, { value: "" }, "Please select..."),
            React.createElement(Option, { value: "To Correct or Provide Additional Information" }, "To Correct or Provide Additional Information"),
            React.createElement(Option, { value: "To Update the Revision" }, "To Update the Revision"))));
};
export default DuplicationReasonPopup;
