import React, { useEffect, useState } from 'react';
import { post } from '../../shared';
import { DateRangePicker } from '@partsbadger/library';

import { SalesOrderOuttakesProps } from '@partsbadger/library/lib/Types/TypesSalesOrder';
import { Card, DatePicker, Table, Row, Col, Button } from 'antd';
import { Form } from '@ant-design/compatible';
import { get } from '../../shared/api';

import { FormComponentProps } from '@ant-design/compatible/es/form';

import moment, { Moment } from 'moment';

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

const OuttakeAuditReport = (props: FormComponentProps) => {
    const [loading, setLoading] = useState(false);
    const [outtakesWithOutBoxes, setOuttakesWithOutBoxes] = useState<SalesOrderOuttakesProps[]>([]);

    const { form } = props;

    const { getFieldDecorator } = form;

    const getOuttakesWithoutBoxes = async (params: { created_time_min: string; created_time_max: string }) => {
        setLoading(true);
        get(`staff/outtakes/outtakes-without-boxes/`, params)
            .then((response: any) => {
                setOuttakesWithOutBoxes(response.data);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    created_time_min: '',
                    created_time_max: '',
                };

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                } else {
                    return;
                }

                getOuttakesWithoutBoxes(params);
            }
        });
    };

    const columns = [
        {
            title: 'Sales Order',
            dataIndex: 'sales_order_name',
            key: 'sales_order_name',
        },
        {
            title: 'Status',
            dataIndex: 'sales_order_status',
            key: 'status',
        },
        {
            title: 'Outtake Date',
            dataIndex: 'sales_order_date',
            render: (_: string, record: SalesOrderOuttakesProps) => {
                return moment(record.sales_order_date).format('YYYY-MM-DD');
            },
        },
    ];

    return (
        <>
            <Row onSubmit={handleSubmit} className={'row-filters'}>
                <Form layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>

            <Card title={'Outtake Records Missing a Box'}>
                <div className={'w-full'}>
                    <Table
                        key={'outtakes'}
                        columns={[...columns]}
                        loading={loading}
                        dataSource={outtakesWithOutBoxes}
                        pagination={false}
                    />
                </div>
            </Card>
        </>
    );
};

export const OuttakeAuditForm = Form.create()(OuttakeAuditReport);
