var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
var ButtonGroup = Button.Group;
var VendorQuotedPrice = function (props) {
    var form = props.form;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var getFieldDecorator = form.getFieldDecorator;
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                var payload = __assign({}, values);
                setLoading(true);
                props
                    .handleSave({
                    vendor_name: values.vendor_name,
                    vendor_quoted_unit_price: values.vendor_quoted_unit_price,
                    vendor_notes: values.vendor_notes,
                })
                    .then(function () {
                    setLoading(false);
                });
            }
        });
    };
    return (React.createElement("div", { className: 'vendor-form' },
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(Form.Item, { label: "Vendor Name" }, getFieldDecorator('vendor_name', {
                rules: [{ required: true, message: 'Please insert value!' }],
                initialValue: props.vendor_name,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: "Vendor Unit Price" }, getFieldDecorator('vendor_quoted_unit_price', {
                rules: [{ required: true, message: 'Please insert unit price!' }],
                initialValue: props.vendor_quoted_unit_price,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, { label: "Vendor Notes" }, getFieldDecorator('vendor_notes', {
                rules: [{ required: false, message: 'Please insert unit price!' }],
                initialValue: props.vendor_notes,
            })(React.createElement(Input.TextArea, null))),
            React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save")))));
};
export default Form.create()(VendorQuotedPrice);
