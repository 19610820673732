import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Divider, notification, Row } from 'antd';
import { NDAFormList } from '../../../components/Contact/NDAForm';
import { Contacts, ErrorRender } from '@partsbadger/library';

import { get } from '@partsbadger/utils';
import { NDAForm } from '../../../stores/types';

export interface RelatedFile {
    id: number;
    file: string;
    filename: string;
}

const NDAForms = () => {
    const [loading, setLoading] = useState(false);
    const [contact, setContact] = useState<string | null>(null);
    const [ndaData, setNdaData] = useState<NDAForm[]>([]);

    const getForms = (contact_id: string) => {
        setLoading(true);
        get(`/staff/contacts/${contact_id}/nda-forms/`)
            .then(data => {
                setNdaData(data);
            })
            .catch(err => {
                notification.error({ message: 'Error', description: <ErrorRender error={err} /> });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card style={{ maxWidth: '80vw', margin: 'auto' }}>
            <Divider>
                <div className={'title'}> NDA Forms</div>
            </Divider>

            <div style={{ width: '77vw' }}>
                <Form.Item label={'Account'}>
                    <Contacts
                        onSelect={contact => {
                            //@ts-ignore
                            const contact_id = contact.key;

                            setContact(contact_id);
                            getForms(contact_id);
                        }}
                        style={{ width: '25vw' }}
                        size="large"
                    />
                </Form.Item>
                {/*<Row style={{ textAlign: 'right', margin: '5px' }}>*/}
                {/*    <AddNDAForm*/}
                {/*        //@ts-ignore*/}
                {/*        myStyle={{ textAlign: 'right', margin: '5px' }}*/}
                {/*        isDisabled={account?.key ? false : true}*/}
                {/*        account={account?.key}*/}
                {/*        callback={() => {*/}
                {/*            setRefresh(!refresh);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Row>*/}
            </div>

            <div style={{ marginTop: 5 }}>
                <Row>
                    <NDAFormList
                        isLoading={loading}
                        records={ndaData}
                        handleRefresh={() => {
                            if (contact) {
                                getForms(contact);
                            }
                        }}
                    />
                </Row>
            </div>
        </Card>
    );
};

export default NDAForms;
