import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { get } from '../utils/api';
import _ from 'lodash';
export var AutoCompletedRecord = function (_a) {
    var handleSelect = _a.handleSelect, isVendorNumber = _a.isVendorNumber;
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState([]), orders = _c[0], setOrders = _c[1];
    var _d = useState(true), collapsed = _d[0], setCollapsed = _d[1];
    var debouncedSearch = useCallback(_.debounce(function (search) {
        getOrders({
            search: search,
        });
    }, 400), [isVendorNumber]);
    useEffect(function () {
        setOrders([]);
    }, [isVendorNumber]);
    var getEndpoint = function () {
        switch (isVendorNumber) {
            case 'barcode-vendor':
            case 'Vendor box':
                return '/staff/sales-orders/';
            case 'rma':
            case 'RMA':
                return '/staff/inventory/';
            case 'purchase-orders':
            case 'Purchase Orders':
                return '/staff/purchase-orders/';
            default:
                return '/staff/sales-orders/';
        }
    };
    var getOrders = function (params) {
        if (params === void 0) { params = {}; }
        setLoading(true);
        var endpoint = getEndpoint();
        get("".concat(endpoint, "?limit=5&offset=0"), params)
            .then(function (response) {
            setOrders(response.data.results);
            setLoading(false);
        })
            .catch(function (err) {
            console.error(err);
            setLoading(false);
        });
    };
    return (React.createElement(Select, { showSearch: true, defaultActiveFirstOption: true, onFocus: function () { return setCollapsed(false); }, onBlur: function () { return setCollapsed(true); }, filterOption: false, style: { width: '100%', margin: '1rem auto' }, notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, showArrow: false, placeholder: React.createElement("span", null,
            React.createElement(SearchOutlined, null),
            "Search record"), allowClear: true, labelInValue: true, onSearch: function (name) {
            if (name.length > 2) {
                setLoading(true);
                setOrders([]);
                debouncedSearch(name);
            }
        }, onSelect: function (pk, option) {
            setOrders([]);
            handleSelect(pk);
        } }, orders.map(function (sales_order) {
        return React.createElement(Select.Option, { key: sales_order.id }, sales_order.name);
    })));
};
