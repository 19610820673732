import React from 'react';
import { postQuote } from '../../../redux/actions/quoteActions';
import { connect } from 'react-redux';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Select } from 'antd';
import { ErrorRender, GenericSelect } from '@partsbadger/library';
import Checkbox from 'antd/lib/checkbox';
import { ContactAdvancedSearch } from '../../../components/Inputs/Select';

const ButtonGroup = Button.Group;
const Option = Select.Option;

class FormNew extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    ...values,
                    rfq: values.rfq?.key,
                    contact: values.contact.id,
                    account: values.contact.account.id,
                    owner: values.owner.key,
                };

                this.props.postQuote(payload);
            }
        });
    };

    render() {
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };

        return (
            <Form onSubmit={this.handleSubmit} layout={'horizontal'}>
                <ErrorRender error={this.props.quotes.error} />

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Contact'} {...formItemLayout}>
                            {getFieldDecorator('contact', {
                                rules: [{ required: true, message: 'Please select a value!' }],
                            })(
                                <ContactAdvancedSearch
                                    onChange={value => {
                                        setFieldsValue({
                                            owner: value.owner
                                                ? {
                                                      key: value?.owner.id,
                                                      label: value?.owner.fullname,
                                                  }
                                                : {},
                                        });
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Sales Rep.'} {...formItemLayout}>
                            {getFieldDecorator('owner', {
                                rules: [{ required: true, message: 'Please select a value!' }],
                            })(
                                <GenericSelect
                                    recordType={'User'}
                                    filters={{
                                        is_staff: true,
                                        is_active: true,
                                    }}
                                    size="small"
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'RFQ'} {...formItemLayout}>
                            {getFieldDecorator('rfq', {
                                rules: [{ required: false, message: 'Please select a value!' }],
                            })(<GenericSelect recordType={'RFQ'} />)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Type'} {...formItemLayout} hasFeedback>
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Please select a value!' }],
                                initialValue: 'Standard',
                            })(
                                <Select dropdownMatchSelectWidth={false}>
                                    <Option value="Standard"> Standard </Option>
                                    <Option value="Requote"> Requote </Option>
                                    <Option value="Rapid Quote"> Rapid Quote</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Deal'} {...formItemLayout} hasFeedback>
                            {getFieldDecorator('deal', {})(<span>The Deal will be generated automatically</span>)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'High Risk'} {...formItemLayout}>
                            {getFieldDecorator('high_risk', {
                                valuePropName: 'checked',
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'European'} {...formItemLayout}>
                            {getFieldDecorator('european_customer', {
                                valuePropName: 'checked',
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Shipping Zip Code'} {...formItemLayout}>
                            {getFieldDecorator('shipping_zip_code', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Field required',
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col sm={22}>
                        <Form.Item label={'Acquisition Source'} {...formItemLayout}>
                            {getFieldDecorator('acquisition_source', {
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please input a value!',
                                    },
                                ],
                            })(
                                <Select showSearch allowClear>
                                    <Select.Option value="Existing Contact">Existing Contact</Select.Option>
                                    <Select.Option value="Cold Call - Outgoing">Cold Call - Outgoing</Select.Option>
                                    <Select.Option value="Initial Mailer">Initial Mailer</Select.Option>
                                    <Select.Option value="Remailer">Remailer</Select.Option>
                                    <Select.Option value="Personal Mail">Personal Mail</Select.Option>
                                    <Select.Option value="Referral">Referral</Select.Option>
                                    <Select.Option value="Web Search">Web Search</Select.Option>
                                    <Select.Option value="Web Ad">Web Ad</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <ButtonGroup className={'add-button'}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<LegacyIcon type={'plus'} />}
                        loading={this.props.quotes.isLoading}
                    >
                        Save
                    </Button>
                </ButtonGroup>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        quotes: state.quotes,
    };
};

const mapDispatchToProps = (dispatch, ownsProps) => {
    return {
        postQuote: payload => dispatch(postQuote(payload, ownsProps)),
    };
};

export const QuoteForm = connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'form-quote' })(FormNew));
