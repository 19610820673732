import { action, observable, makeObservable } from 'mobx';
var SalesOrderBatchStore = /** @class */ (function () {
    function SalesOrderBatchStore() {
        this.batches = [];
        this.total_quantity = 0;
        this.missing_quantity = 0;
        this.original_quantity = 0;
        makeObservable(this, {
            // Observables
            batches: observable,
            total_quantity: observable,
            missing_quantity: observable,
            original_quantity: observable,
            // Actions
            setBatches: action,
            setBatch: action,
            removeBatch: action,
            resetBatches: action,
            resetTotalQuantity: action,
            getQuantity: action,
            getMissingQuantity: action,
            getOriginalQuantity: action,
        });
    }
    SalesOrderBatchStore.prototype.setBatches = function (batches) {
        this.batches = batches;
    };
    SalesOrderBatchStore.prototype.setBatch = function (order_item, batch) {
        this.batches.push(batch);
        order_item.batches = this.batches;
    };
    SalesOrderBatchStore.prototype.removeBatch = function (order_item, index_batch) {
        this.batches.splice(index_batch, 1);
        this.batches = this.batches.map(function (bt) {
            return bt;
        });
        var batches = this.batches.filter(function (batch) { return batch.order_item === order_item.id; });
        if (batches.length > 0) {
            order_item.batches = this.batches;
        }
        else {
            order_item.batches = [];
        }
    };
    SalesOrderBatchStore.prototype.resetBatches = function () {
        this.batches = [];
    };
    SalesOrderBatchStore.prototype.resetTotalQuantity = function () {
        this.total_quantity = 0;
    };
    SalesOrderBatchStore.prototype.getQuantity = function (order_item_id) {
        var quantity = 0;
        this.batches.map(function (batch) {
            if (batch.order_item == order_item_id) {
                quantity = quantity + batch.quantity;
            }
        });
        this.total_quantity = quantity;
        return quantity;
    };
    SalesOrderBatchStore.prototype.getMissingQuantity = function (quantity_quote_item, total_quantity, quantity_sample) {
        if (quantity_sample === void 0) { quantity_sample = 0; }
        this.missing_quantity = quantity_quote_item - total_quantity - quantity_sample;
    };
    SalesOrderBatchStore.prototype.getOriginalQuantity = function (quantity) {
        this.original_quantity = quantity;
    };
    return SalesOrderBatchStore;
}());
export default new SalesOrderBatchStore();
