import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Divider, Input, message, notification, Radio, RadioChangeEvent, Row } from 'antd';

import { QuoteOneTrueForm } from '@partsbadger/one-true-form';

import { Contacts, Deals } from '@partsbadger/library';
import { Leads } from '../../components/Inputs/Select';

import { getCurrentUser, getToken } from 'components/User';
import { get } from 'shared';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { QuoteStore } from 'stores';
import { UserStore } from '../../stores/UserStore';
import { LeadStore } from '@partsbadger/one-true-form/lib/stores/LeadStore';

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface NewLeadFormProps extends FormComponentProps {
    handleSubmit: (values: any) => void;
}

const NewLeadForm = Form.create<NewLeadFormProps>()((props: NewLeadFormProps) => {
    const form = props.form;
    const { getFieldDecorator } = form;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                props.handleSubmit(values);
            }
        });
    };

    return (
        <Form>
            <Row>
                <Col sm={24}>
                    <Form.Item label={'Email'} {...layout}>
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input a value!',
                                },
                            ],
                        })(<Input autoComplete={'off'} size={'small'} type={'email'} />)}
                    </Form.Item>
                </Col>
            </Row>

            <Col sm={24} className={'text-center'}>
                <Button type="primary" onClick={handleSubmit}>
                    Start
                </Button>
            </Col>
        </Form>
    );
});

export const GetQuote = observer(() => {
    const history = useHistory();

    const [loadingQuote, setLoadingQuote] = useState(false);

    const [startFrom, setStartFrom] = useState('contact');
    const [deal, setDeal] = useState<{ key: string; label: string }>();
    const [contact, setContact] = useState<{
        key: string;
        label: string;
        fullname?: string;
        email?: string;
    }>();
    const [lead, setLead] = useState<{ key: string; email: string }>();

    const [token, setToken] = useState<string | null>('');
    const [is_new_contact, setIsNewContact] = useState(false);
    const [acquisition_source, setAcquisitionSource] = useState<'NC' | 'EC' | 'LC'>('EC');

    const [quote, setQuote] = useState<any>();

    const params = useQuery();

    const Deal_ID = params.get('Deal_ID');
    const uid = params.get('uid');
    const email = params.get('email');
    const zoho_id = params.get('zoho_id');

    useEffect(() => {
        setToken(getToken());

        // Deal_Id comes from zoho id

        if (Deal_ID) {
            setLoadingQuote(true);

            get(`customer/deal-by-zid/${Deal_ID}/`)
                .then((response: any) => {
                    setStartFrom('deal');

                    setDeal({
                        key: response?.data?.id,
                        label: response?.data?.name,
                    });

                    getQuoteByDeal(response.data.id);
                })
                .catch((error: any) => alert(error))
                .finally(() => setLoadingQuote(false));
        }

        if (uid) {
            get(`customer/quote/${uid}/`)
                .then((response: any) => {
                    setQuote(response.data);
                    setIsNewContact(false);
                })
                .catch((error: any) => alert(error))
                .finally(() => setLoadingQuote(false));
        }

        if (email) {
            get(`staff/contacts/?email=${email}&exclude_account_payable=true`, {})
                .then((response: any) => {
                    if (response.data.count > 0) {
                        setContact(response.data.results[0]);
                        getQuoteByContact(response.data.results[0].id);
                    } else {
                        message.error(`This email contact is payable ${email}; try another contact from zoho.`, 3);
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                    message.error('Error');
                })
                .finally(() => setLoadingQuote(false));
        }

        if (zoho_id) {
            get(`staff/quotes/?zoho_id=${zoho_id}&stage=Completed`)
                .then((response: any) => {
                    if (response.data.results[0].zoho_id) {
                        QuoteStore.getRequote(response.data.results[0].id).then((r: any) => {
                            get(`customer/quote/${r.data.uid}/`)
                                .then((res: any) => {
                                    setQuote(res.data);
                                    setIsNewContact(false);
                                })
                                .catch((error: any) => alert(error))
                                .finally(() => setLoadingQuote(false));
                        });
                    } else {
                        message.warn('This Quote not is Completed to the created a Requote_', 3);
                    }
                })
                .catch((error: any) => message.warn('This Quote not is Completed to the created a Requote', 3))
                .finally(() => setLoadingQuote(false));
        }
    }, []);

    const getQuoteByDeal = (deal_id: string) => {
        setLoadingQuote(true);

        get(`customer/quote/last-by-deal/`, {
            deal: deal_id,
        })
            .then((response: any) => {
                setQuote(response.data);
            })
            .catch((err: any) => {
                console.log(err);
                message.error('Error');
            })
            .finally(() => setLoadingQuote(false));
    };

    const getQuoteByContact = (contact_id: string) => {
        setLoadingQuote(true);

        get(`customer/quote/last-by-contact/`, {
            contact: contact_id,
        })
            .then((response: any) => {
                if (response.data) {
                    setQuote(response.data);
                }
            })
            .catch((err: any) => {
                console.log(err);
                message.error('Error');
            })
            .finally(() => setLoadingQuote(false));
    };

    const getQuoteByLead = (lead: any) => {
        setLoadingQuote(true);
        get(`customer/quote/last/`, {
            email: lead?.email,
        })
            .then((response: any) => {
                setQuote(response.data);
            })
            .catch((err: any) => {
                console.log(err);
                message.error('Error');
            })
            .finally(() => setLoadingQuote(false));
    };

    return (
        <div>
            <Divider>
                <div className={'title'}> Welcome PartsBadger Representatives.</div>
            </Divider>

            <div style={{ textAlign: 'center' }}>
                Use the form below to get a Instant Quote or submit an RFQ. This form ensures that we have all of the
                necessary information in one place to process your RFQs timely and efficiently. Thank you.
            </div>

            {!uid && !zoho_id && (
                <Row style={{ maxWidth: 900, margin: 'auto', marginTop: 20 }}>
                    <Col md={10}>
                        <Form.Item
                            label={'Quote with:'}
                            style={{
                                maxWidth: 900,
                            }}
                        >
                            <Radio.Group
                                value={startFrom}
                                onChange={(e: RadioChangeEvent): void => {
                                    const value = e.target.value;
                                    LeadStore.clearLead();
                                    setStartFrom(value);

                                    if (value === 'new_lead') {
                                        setIsNewContact(true);
                                    }

                                    if (value === 'lead' || value === 'new_lead') {
                                        if (value === 'lead') {
                                            setAcquisitionSource('NC');
                                        }
                                    } else if (value === 'deal' || value === 'contact') {
                                        setAcquisitionSource('EC');
                                    }
                                }}
                            >
                                {(UserStore?.user?.group.includes('All Sales Representatives') ||
                                    UserStore?.user?.group.includes('Lead Department')) && (
                                    <>
                                        <Radio value={'deal'}>Deal</Radio>
                                        <Radio value={'contact'}>Existing Contact</Radio>
                                    </>
                                )}
                                {UserStore?.user?.group.includes('Lead Department') && (
                                    <>
                                        <Radio value={'lead'}>
                                            Existing Lead
                                        </Radio>
                                        <Radio value={'new_lead'}>New Lead</Radio>
                                    </>
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col
                        md={14}
                        style={{
                            minHeight: 120,
                        }}
                    >
                        {startFrom === 'deal' && (
                            <div>
                                <Form.Item
                                    label={'Deal'}
                                    style={{
                                        maxWidth: 600,
                                    }}
                                    {...layout}
                                >
                                    <Deals
                                        value={deal}
                                        onChange={(entity: any) => {
                                            setDeal(entity);
                                        }}
                                    />
                                </Form.Item>

                                {deal?.key && (
                                    <Button
                                        loading={loadingQuote}
                                        type="primary"
                                        style={{ marginLeft: 101 }}
                                        onClick={() => {
                                            getQuoteByDeal(deal.key);
                                        }}
                                    >
                                        Start
                                    </Button>
                                )}
                            </div>
                        )}

                        {startFrom === 'contact' &&
                            !email &&
                            (UserStore?.user?.group.includes('All Sales Representatives') ||
                                UserStore?.user?.group.includes('Lead Department')) && (
                                <div>
                                    <Form.Item
                                        label={'Contact'}
                                        {...layout}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                    >
                                        <Contacts
                                            value={contact}
                                            onChange={(entity: any) => {
                                                setContact(entity);
                                            }}
                                            exclude_account_payable={true}
                                        />
                                    </Form.Item>

                                    {contact?.key && (
                                        <Button
                                            loading={loadingQuote}
                                            type="primary"
                                            style={{ marginLeft: 101 }}
                                            onClick={() => {
                                                getQuoteByContact(contact.key);
                                            }}
                                        >
                                            Start
                                        </Button>
                                    )}
                                </div>
                            )}

                        {startFrom === 'contact' && email && (
                            <div>
                                <Form.Item
                                    label={'Contact'}
                                    {...layout}
                                    style={{
                                        maxWidth: 600,
                                    }}
                                >
                                    <Input value={contact?.fullname + ' || ' + contact?.email} disabled />
                                </Form.Item>
                            </div>
                        )}

                        {startFrom === 'lead' && (
                            <div>
                                <Leads
                                    onSelectLead={(value: any) => {
                                        LeadStore.setLead(value);
                                    }}
                                />
                                {LeadStore.lead?.email && (
                                    <Button
                                        loading={loadingQuote}
                                        type="primary"
                                        style={{ marginLeft: 101 }}
                                        onClick={() => {
                                            getQuoteByLead(LeadStore.lead);
                                        }}
                                    >
                                        Start
                                    </Button>
                                )}
                            </div>
                        )}

                        {startFrom === 'new_lead' && (
                            <Form.Item
                                style={{
                                    maxWidth: 600,
                                }}
                            >
                                <NewLeadForm
                                    handleSubmit={(values: any) => {
                                        getQuoteByLead(values);
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            )}
            {quote && (
                <div style={{ marginTop: 10 }}>
                    <QuoteOneTrueForm
                        quote={quote}
                        token={token ?? ''}
                        acquisition_source={acquisition_source}
                        //@ts-ignore
                        current_user={getCurrentUser()?.data}
                        is_new_contact={is_new_contact}
                        for_staff={true}
                        onQuoteSubmitted={() => {
                            notification.success({
                                message: 'Quote sent!',
                            });

                            history.push('/rfq');
                        }}
                    />
                </div>
            )}
        </div>
    );
});
