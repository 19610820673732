import { UserStore } from '../../stores/UserStore';

/**
 * Check if the permission is in the user permission list
 * @param permission_name
 * @returns {boolean}
 */
export const hasPermission = (permission_name: string) => {
    const { user } = UserStore;

    return user?.permissions?.includes(permission_name);
};

export const hasGroup = (group_name: string) => {
    const { user } = UserStore;

    return user?.group?.includes(group_name);
};

export const getCurrentUser = () => {
    const { user } = UserStore;

    return {
        data: user,
    };
};

/**
 * Should be return the a token the user store
 * @returns Token {string | undefined}
 */
export const getToken = () => {
    return localStorage.getItem('token');
};
