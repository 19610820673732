import * as ActionTypes from '../ActionTypes';

const TOKEN = "token";
export const User = (
    state = {
        token: localStorage.getItem(TOKEN),
        isAuthenticated: null,
        isLoading: true,
        errors: null,
        data: [],
        fullname: "",
        permissions: [],
        entities: [],
    },
    action) => {
    switch (action.type) {
        case ActionTypes.USER_LOADING:
            return {...state, isLoading: true};

        case ActionTypes.USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                data: action.payload,
                permissions: action.permissions,
                fullname: action.fullname
            };

        case ActionTypes.USERS_LOADING:
            return {...state};

        case ActionTypes.USERS_LOADED:
            return {...state, entities: action.payload};

        case ActionTypes.LOGIN_SUCCESSFUL:
            localStorage.setItem(TOKEN, action.data.token);
            return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};

        case ActionTypes.USER_LOADING_ERROR:
            return {...state, isLoading: false}
        case ActionTypes.AUTHENTICATION_ERROR:
        case ActionTypes.LOGIN_FAILED:
        case ActionTypes.LOGOUT_SUCCESSFUL:
            localStorage.removeItem(TOKEN);
            return {
                ...state, errors: action.error, TOKEN: null, user: null,
                isAuthenticated: false, isLoading: false
            };


        default:
            return state;
    }
};