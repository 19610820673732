var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, Select } from 'antd';
import { GenericSelect } from '@partsbadger/library';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
var ButtonGroup = Button.Group;
var Option = Select.Option;
var QuoteEditForm = function (props) {
    var _a, _b;
    var form = props.form;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var getFieldDecorator = form.getFieldDecorator;
    var quote = props.quote;
    var handleSubmit = function (e) {
        e.preventDefault();
        form.validateFieldsAndScroll(function (err, values) {
            var _a;
            if (!err) {
                var payload = __assign(__assign({}, values), { deal: values.deal.key, contact: values.contact.key, account: values.account.key, owner: values.owner.key, design_engineer: ((_a = values === null || values === void 0 ? void 0 : values.design_engineer) === null || _a === void 0 ? void 0 : _a.key) || null });
                setLoading(true);
                props.handleSave(payload).then(function () {
                    setLoading(false);
                });
            }
        });
    };
    var formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
    };
    return (React.createElement("div", { className: 'quote-form' },
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(Form.Item, { labelCol: { span: 6 }, wrapperCol: { span: 16 }, label: "Deal" }, getFieldDecorator('deal', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: (quote === null || quote === void 0 ? void 0 : quote.deal)
                    ? {
                        key: quote.deal.id,
                        label: quote.deal.name,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "Deal" }))),
            React.createElement(Form.Item, __assign({ label: 'Account' }, formItemLayout, { help: React.createElement("div", null,
                    "If you don't see the account?\u00A0",
                    React.createElement("a", { target: "_blank", href: "".concat(ZOHO_BASE_URL, "/Accounts/create"), rel: "noreferrer" }, "Create here")) }), getFieldDecorator('account', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: (quote === null || quote === void 0 ? void 0 : quote.account)
                    ? {
                        key: quote === null || quote === void 0 ? void 0 : quote.account.id,
                        label: quote === null || quote === void 0 ? void 0 : quote.account.name,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "Account" }))),
            React.createElement(Form.Item, __assign({ label: 'Contact' }, formItemLayout), getFieldDecorator('contact', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: {
                    key: (_a = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _a === void 0 ? void 0 : _a.id,
                    label: (_b = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _b === void 0 ? void 0 : _b.fullname,
                },
            })(React.createElement(GenericSelect, { recordType: "Contact" }))),
            React.createElement(Form.Item, __assign({ label: 'Sales Rep.' }, formItemLayout), getFieldDecorator('owner', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: (quote === null || quote === void 0 ? void 0 : quote.owner)
                    ? {
                        key: quote === null || quote === void 0 ? void 0 : quote.owner.id,
                        label: quote === null || quote === void 0 ? void 0 : quote.owner.fullname,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "User" }))),
            React.createElement(Form.Item, __assign({ label: 'Design Engineer.' }, formItemLayout), getFieldDecorator('design_engineer', {
                initialValue: (quote === null || quote === void 0 ? void 0 : quote.design_engineer)
                    ? {
                        key: quote === null || quote === void 0 ? void 0 : quote.design_engineer.id,
                        label: quote === null || quote === void 0 ? void 0 : quote.design_engineer.fullname,
                    }
                    : {},
            })(React.createElement(GenericSelect, { recordType: "User" }))),
            React.createElement(Form.Item, __assign({ label: 'Type' }, formItemLayout), getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote === null || quote === void 0 ? void 0 : quote.type,
            })(React.createElement(Select, { dropdownMatchSelectWidth: false },
                React.createElement(Option, { value: "Standard" }, " Standard "),
                React.createElement(Option, { value: "Requote" }, " Requote "),
                React.createElement(Option, { value: "Rapid Quote" }, " Rapid Quote")))),
            React.createElement(Form.Item, __assign({ label: 'Name.' }, formItemLayout), getFieldDecorator('name', {
                rules: [
                    {
                        required: false,
                        message: 'Please select a value!',
                    },
                ],
                initialValue: quote === null || quote === void 0 ? void 0 : quote.name,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, __assign({ label: 'High Risk' }, formItemLayout), getFieldDecorator('high_risk', {
                valuePropName: 'checked',
                rules: [{ required: true, message: 'Please select a value!' }],
                initialValue: quote === null || quote === void 0 ? void 0 : quote.high_risk,
            })(React.createElement(Checkbox, null))),
            React.createElement(Form.Item, __assign({ label: 'European' }, formItemLayout), getFieldDecorator('european_customer', {
                valuePropName: 'checked',
                initialValue: quote === null || quote === void 0 ? void 0 : quote.european_customer,
            })(React.createElement(Checkbox, null))),
            React.createElement(Form.Item, __assign({ label: 'Shipping Zip Code' }, formItemLayout), getFieldDecorator('shipping_zip_code', {
                initialValue: quote === null || quote === void 0 ? void 0 : quote.shipping_zip_code,
            })(React.createElement(Input, null))),
            React.createElement(Form.Item, __assign({ label: 'Order of Items' }, formItemLayout), getFieldDecorator('ordering_line_items', {
                initialValue: quote === null || quote === void 0 ? void 0 : quote.ordering_line_items,
            })(React.createElement(Select, { dropdownMatchSelectWidth: false },
                React.createElement(Option, { value: "created_time" }, " Created Time "),
                React.createElement(Option, { value: "quantity" }, " Quantity ")))),
            quote.editable && (React.createElement(ButtonGroup, { className: 'add-button' },
                React.createElement(Button, { type: "primary", htmlType: "submit", icon: React.createElement(LegacyIcon, { type: 'plus' }), loading: loading }, "Save"))))));
};
export default Form.create()(QuoteEditForm);
