import { action, observable, runInAction, makeObservable } from 'mobx';
import { get, remove } from '@partsbadger/utils';
import { IContactListStaff, IContactDetailStaff, IVendorListStaff } from '@partsbadger/types';

class Vendor {
    isLoading = true;
    vendors: IVendorListStaff[] = [];
    vendor: IVendorListStaff | null = null;

    pagination: {
        current: number;
        defaultPageSize: number;
        pageSize: number;
        total: number;
    } = {
        current: 1,
        defaultPageSize: 30,
        pageSize: 30,
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            vendors: observable,
            pagination: observable,

            vendor: observable,

            // Actions
            getAll: action,
            getById: action,
        });
    }

    getAll(params: any) {
        this.isLoading = true;

        return get(`/vendor/v1/users/`, {
            params: params,
        })
            .then(data => {
                runInAction(() => {
                    this.vendors = data.results;
                    this.pagination.current = params.page;
                    this.pagination.total = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    async getById(id: number) {
        this.isLoading = true;
        this.vendor = await get(`/vendor/v1/users/${id}/`);
        this.isLoading = false;
    }
}

export const VendorStore = new Vendor();
