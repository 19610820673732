import { Button, Input, Modal } from 'antd';
import React, { useState } from 'react';
var ModalNotesForm = function (props) {
    var modalTitle = props.modalTitle, buttonLabel = props.buttonLabel, inputPlaceHolder = props.inputPlaceHolder, defaultValue = props.defaultValue, handleSave = props.handleSave, handleCancel = props.handleCancel;
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(defaultValue), notes = _b[0], setNotes = _b[1];
    return (React.createElement(Modal, { visible: true, centered: true, title: modalTitle, onCancel: handleCancel, footer: [
            React.createElement(Button, { key: "Add", type: "primary", loading: isLoading, onClick: function () {
                    setIsLoading(true);
                    handleSave(notes).finally(function () { return setIsLoading(false); });
                } }, buttonLabel),
        ] },
        React.createElement(Input.TextArea, { placeholder: inputPlaceHolder, value: notes, rows: 4, onChange: function (event) {
                setNotes(event.target.value);
            } })));
};
export default ModalNotesForm;
