import React from 'react';
var FormErrorParser = function (_a) {
    var errors = _a.errors, _b = _a.isAntd4, isAntd4 = _b === void 0 ? false : _b;
    var items = Object.keys(errors).map(function (key) {
        var error_field = errors[key];
        if (isAntd4) {
            return error_field.errors.map(function (e, index) { return React.createElement("li", { key: key }, e); });
        }
        return error_field.errors.map(function (e) { return React.createElement("li", { key: e.field }, e.message); });
    });
    return React.createElement("ul", null, items);
};
export default FormErrorParser;
