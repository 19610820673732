var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
var RadioChecklist = function (props) {
    var renderComponent = function () {
        var _a, _b, _c;
        if (props.question.visible) {
            return (React.createElement(Form.Item, null, props.getFieldDecorator("confirm-".concat(props.question.question), {
                rules: [{ required: props.question.required, message: 'Required' }],
                initialValue: (_c = (_b = (_a = props.question) === null || _a === void 0 ? void 0 : _a.answer) === null || _b === void 0 ? void 0 : _b.confirm) !== null && _c !== void 0 ? _c : '',
            })(React.createElement(Radio.Group, { className: "flex mt-2", onChange: function (e) {
                    var _a;
                    var value = e.target.value;
                    var question_id = props.question.question_id;
                    var arrayQuestion = props.checklistQuestions.filter(function (q) { return q.depends_on === question_id && q.question_id !== null; });
                    if (arrayQuestion.length > 0) {
                        var questionF = arrayQuestion[0];
                        value === 'Yes' ? (questionF.visible = true) : (questionF.visible = false);
                        props.setChecklistQuestions(__spreadArray([], props.checklistQuestions, true));
                    }
                    var key = "confirm-".concat(props.question.question);
                    props.form.setFieldsValue((_a = {},
                        _a[key] = value,
                        _a));
                }, defaultValue: props.form.getFieldValue("confirm-".concat(props.question.question)) },
                React.createElement(Radio, { value: 'Yes' }, "Yes"),
                React.createElement(Radio, { value: 'No' }, "No")))));
        }
        else {
            return React.createElement(React.Fragment, null);
        }
    };
    return renderComponent();
};
export default RadioChecklist;
