var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Dropdown, Menu, message, Table, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CheckOutlined, DownOutlined, FilePdfOutlined } from '@ant-design/icons';
import { PaginationComponent } from 'quotes-frontend/src/components/Inputs/Pagination';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import PurchaseOrderStore from '../stores/PurchaseOrderStore';
export var OrdersTable = function (props) {
    var history = useHistory();
    var _a = useState([]), selectedRowKeys = _a[0], setSelectedRowKeys = _a[1];
    var onSelectChange = function (newSelectedRowKeys) {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    var rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };
    var columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total_with_shipping',
            key: 'grand_total_with_shipping',
        },
        {
            title: 'Zoho',
            dataIndex: 'zoho_id',
            key: 'zoho_id',
            render: function (value, record) { return (React.createElement(React.Fragment, null, record.zoho_id && (React.createElement("a", { style: {
                    fontSize: 12,
                    marginLeft: 4,
                }, target: '_blank', rel: "noreferrer", href: "".concat(ZOHO_BASE_URL, "/PurchaseOrders/").concat(record.zoho_id) }, "View In Zoho")))); },
        },
        {
            title: 'Receive status',
            dataIndex: 'receive_status',
        },
        {
            title: 'Status',
            dataIndex: 'approved',
            key: 'approved',
            render: function (value, record) { return (React.createElement(React.Fragment, null, record.approved ? (React.createElement(Tag, { color: 'green' }, 'Approved')) : (React.createElement(Tag, { color: 'red' }, 'Not Approved')))); },
        },
        {
            title: 'Sent',
            dataIndex: 'sent',
            key: 'sent',
            render: function (value, record) { return (React.createElement(React.Fragment, null, record.send_to_vendor ? React.createElement(CheckOutlined, null) : 'No')); },
        },
        {
            title: 'Actions',
            key: 'action',
            render: function (value, record) {
                var menu = (React.createElement(Menu, null,
                    React.createElement(Menu.Item, { onClick: function () {
                            history.push("/purchase-orders/".concat(record.id, "/"));
                        } },
                        ' ',
                        React.createElement(CheckCircleOutlined, null),
                        " Review Purchase Order"),
                    record.approved && (React.createElement(Menu.Item, { onClick: function () {
                            if (record.id && record.category) {
                                var template_name = record.category == 'PartsBadger, LLC'
                                    ? 'finance/po_pb.html'
                                    : 'finance/po_pb.html';
                                props.downloadPdf(record.id, record.name, template_name);
                            }
                        } },
                        React.createElement(FilePdfOutlined, null),
                        "Download PDF")),
                    record.approved && (React.createElement(Menu.Item, { onClick: function () {
                            if (record.id) {
                                try {
                                    PurchaseOrderStore.send_to_vendor(record.id).then(function () {
                                        props.onReload();
                                    });
                                }
                                catch (e) {
                                    message.error('Error sending to vendor');
                                }
                            }
                        } },
                        React.createElement(CheckOutlined, null),
                        "Mark as Sent"))));
                return (React.createElement(Dropdown, { overlay: menu },
                    React.createElement("span", { className: 'link' },
                        "Actions ",
                        React.createElement(DownOutlined, null))));
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-row p-4' },
            React.createElement(Button, { type: 'link', disabled: selectedRowKeys.length <= 0, onClick: function () { return props.downloadAllPdF(selectedRowKeys); } }, "Download PDFs")),
        React.createElement(Table, { rowKey: function (record) { return String(record.id); }, rowSelection: rowSelection, columns: columns, pagination: false, footer: function () {
                return React.createElement(PaginationComponent, __assign({}, props.pagination));
            }, dataSource: props.purchase_orders })));
};
