var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
var InputCalculator = function (props) {
    var _a;
    var _b = useState((_a = props.value) !== null && _a !== void 0 ? _a : ''), value = _b[0], setValue = _b[1];
    return (React.createElement(Input, __assign({}, props, { size: "small", className: "w-16", value: value, onChange: function (e) {
            setValue(e.target.value);
            if (props.onChange) {
                props.onChange(parseFloat(e.target.value));
            }
        }, onKeyPress: function (event) {
            if (event.key === 'Enter' && value) {
                var total = eval(value).toFixed(3);
                event.preventDefault();
                setValue(total);
                if (props.onChange) {
                    props.onChange(total);
                }
            }
        } })));
};
export default InputCalculator;
