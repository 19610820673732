import { notification, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { NDAForm } from '../../../../stores/types';
import AddNDAForm from '../Add';
import { patch } from '@partsbadger/utils';
import { ErrorRender } from '@partsbadger/library';

export interface INDAFormList {
    isLoading: boolean;
    records: Array<NDAForm>;
    handleRefresh: () => void;
}

export const NDAFormList = ({ isLoading, records, handleRefresh }: INDAFormList) => {
    return (
        <div>
            <Table
                style={{ width: '77vw' }}
                scroll={{ y: '80vh' }}
                bordered
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Company name',
                        dataIndex: 'company_name',
                        key: 'company_name',
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'signed',
                        key: 'signed',
                        render: (t, record) => (
                            <>
                                {record.signed ? (
                                    <Tooltip title={'Signed by our team.'}>
                                        <Tag color={'#06d6a0'}>Signed</Tag>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={'The changes made in this form need to be verified by our team.'}>
                                        <Tag color={'#ffb703'}>Pending</Tag>
                                    </Tooltip>
                                )}
                            </>
                        ),
                    },
                    {
                        title: 'Download',
                        dataIndex: 'files',
                        key: 'files',
                        render: (t, record) => {
                            return record.related_files.map(f => (
                                <a key={f.id} href={f.file} target="_blank" rel="noopener noreferrer">
                                    {f.filename}
                                </a>
                            ));
                        },
                    },
                    {
                        title: 'Edit',
                        render: (t, record) => (
                            <>
                                <AddNDAForm
                                    key="list-edit"
                                    record={record}
                                    handleSave={async payload => {
                                        await patch(`/staff/contacts/${record.contact}/nda-forms/`, payload)
                                            .then(() => {
                                                notification.success({
                                                    message: 'Form Updated!',
                                                });
                                                handleRefresh();
                                            })
                                            .catch(err => {
                                                notification.error({
                                                    message: 'Error',
                                                    description: <ErrorRender error={err} />,
                                                });
                                            });
                                    }}
                                />
                            </>
                        ),
                    },
                ]}
                dataSource={records}
                rowKey={'id'}
                loading={isLoading}
            />
        </div>
    );
};
