import React, { Fragment } from 'react';
import { hideAddDealForm, postDeal } from '../../redux/actions/dealActions';
import { fetchAccounts, fetchContacts, showAccountForm, showContactForm } from '../../redux/actions/accountActions';
import { connect } from 'react-redux';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Col, Modal, Row, Select, Spin } from 'antd';
import { fetchUsers } from '../../redux/actions/userActions';

const ButtonGroup = Button.Group;

let busy;

class DealNewComponent extends React.Component {
    state = {
        name: '',
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const payload = {
                    contact: values.contact.key,
                    account: values.account.key,
                    // name: this.state.name,
                    // stage: values.stage,
                    // probability: values.probability,
                    acquisition_source: values.acquisition_source,
                    initial_or_repeat: 'First Order',
                    expected_revenue: 0,
                    owner: values.owner.key,
                };

                this.props.postDeal(payload);
            }
        });
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };

        return (
            <Fragment>
                <Modal
                    visible={this.props.deals.showAddDealForm}
                    title="Add Deal"
                    onCancel={this.props.hideQuoteForm}
                    footer={[]}
                >
                    <Form onSubmit={this.handleSubmit} layout={'vertical'}>
                        <Row>
                            <Col sm={22}>
                                <Form.Item label={'Account'} {...formItemLayout}>
                                    {getFieldDecorator('account', {
                                        rules: [{ required: true, message: 'Please select a value!' }],
                                    })(
                                        <Select
                                            showSearch
                                            labelInValue
                                            placeholder="Search by name or email"
                                            notFoundContent={
                                                this.props.accounts.isLoading ? <Spin size="small" /> : null
                                            }
                                            filterOption={false}
                                            optionKeyProp={'value'}
                                            onSearch={name => {
                                                if (busy) {
                                                    clearTimeout(busy);
                                                }
                                                const params = {
                                                    search: name,
                                                };
                                                busy = setTimeout(() => this.props.fetchAccounts(params), 200);
                                            }}
                                            style={{ width: '100%' }}
                                            dropdownMatchSelectWidth={false}
                                        >
                                            {this.props.accounts.entities.map(d => (
                                                <Select.Option key={d.id}>{d.name}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col
                                sm={2}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        this.props.showAccountForm();
                                    }}
                                    icon={<LegacyIcon type={'plus'} />}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={22}>
                                <Form.Item label={'Contact'} {...formItemLayout}>
                                    {getFieldDecorator('contact', {
                                        rules: [{ required: true, message: 'Please select a value!' }],
                                    })(
                                        <Select
                                            allowClear
                                            showSearch
                                            labelInValue
                                            placeholder="Search contact by name or email"
                                            notFoundContent={
                                                this.props.contacts.isLoading ? <Spin size="small" /> : null
                                            }
                                            filterOption={false}
                                            optionKeyProp={'value'}
                                            onSearch={name => {
                                                if (busy) {
                                                    clearTimeout(busy);
                                                }
                                                const params = {
                                                    search: name,
                                                    account: getFieldValue('account')
                                                        ? getFieldValue('account').key
                                                        : '',
                                                };
                                                busy = setTimeout(() => this.props.fetchContacts(params), 500);
                                            }}
                                            onSelect={value => {
                                                const contact = this.props.contacts.entities.find(
                                                    r => (r.id = value?.key)
                                                );

                                                setFieldsValue({
                                                    owner: {
                                                        key: contact?.owner.id,
                                                        label: contact?.owner.fullname,
                                                    },
                                                });

                                                if (!contact.owner.is_active) {
                                                    this.setState({
                                                        error: `The contact owner ${contact.owner.email} is not an active user, please fix this before to continue`,
                                                    });
                                                }
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {this.props.contacts.entities.map(d => (
                                                <Select.Option key={d.id}>
                                                    {d.fullname} {d.email}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col
                                sm={2}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        if (getFieldValue('account')) {
                                            this.props.showContactForm({
                                                id: getFieldValue('account').key,
                                                name: getFieldValue('account').label,
                                            });
                                        } else {
                                            this.props.showContactForm({});
                                        }
                                    }}
                                    icon={<LegacyIcon type={'plus'} />}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={22}>
                                <Form.Item label={'Acquisition Source'} {...formItemLayout}>
                                    {getFieldDecorator('acquisition_source', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input a value!',
                                            },
                                        ],
                                    })(
                                        <Select placeholder="">
                                            <Select.Option value="Existing Contact">Existing Contact</Select.Option>
                                            <Select.Option value="Cold Call - Outgoing">
                                                Cold Call - Outgoing
                                            </Select.Option>
                                            <Select.Option value="Initial Mailer">Initial Mailer</Select.Option>
                                            <Select.Option value="Remailer">Remailer</Select.Option>
                                            <Select.Option value="Personal Mail">Personal Mail</Select.Option>
                                            <Select.Option value="Referral">Referral</Select.Option>
                                            <Select.Option value="Web Search">Web Search</Select.Option>
                                            <Select.Option value="Web Ad">Web Ad</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={22}>
                                <Form.Item label={'Sales Rep.'} {...formItemLayout}>
                                    {getFieldDecorator('owner', {
                                        rules: [{ required: true, message: 'Please select a value!' }],
                                    })(
                                        <Select
                                            showSearch
                                            labelInValue
                                            placeholder="Search by name or email"
                                            notFoundContent={this.props.user.isLoading ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            optionKeyProp={'value'}
                                            style={{ width: '100%' }}
                                            dropdownMatchSelectWidth={false}
                                            onSearch={name => {
                                                if (busy) {
                                                    clearTimeout(busy);
                                                }
                                                const params = {
                                                    search: name,
                                                    is_staff: true,
                                                };
                                                busy = setTimeout(() => this.props.fetchUsers(params), 500);
                                            }}
                                        >
                                            {this.props.user.entities.map(d => (
                                                <Select.Option key={d.id}>{d.fullname}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.state.error && (
                            <div style={{ margin: '5px' }}>
                                <Alert type={'error'} message={this.state.error} />
                            </div>
                        )}

                        <ButtonGroup className={'add-button'}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<LegacyIcon type={'plus'} />}
                                loading={this.props.deals.isLoading}
                            >
                                Save Deal
                            </Button>
                        </ButtonGroup>

                        <br />
                        <br />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        deals: state.deals,
        accounts: state.accounts,
        contacts: state.contacts,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch, ownsProps) => {
    return {
        postDeal: payload => dispatch(postDeal(payload, ownsProps)),
        fetchAccounts: name => dispatch(fetchAccounts(name)),
        fetchContacts: (name, account) => dispatch(fetchContacts(name, account)),
        hideQuoteForm: () => dispatch(hideAddDealForm()),
        fetchUsers: params => dispatch(fetchUsers(params)),
        showAccountForm: () => dispatch(showAccountForm()),
        showContactForm: (defaultAccount, defaultFirstName, defaultLastName, defaultEmail, defaultOwner) =>
            dispatch(showContactForm(defaultAccount, defaultFirstName, defaultLastName, defaultEmail, defaultOwner)),
    };
};

export const DealNew = connect(mapStateToProps, mapDispatchToProps)(Form.create()(DealNewComponent));
