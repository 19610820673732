var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
var Paragraph = Typography.Paragraph;
export var PartName = function (_a) {
    var name = _a.name, editable = _a.editable, handleUpdate = _a.handleUpdate;
    var _b = useState(name), partName = _b[0], setPartName = _b[1];
    useEffect(function () {
        setPartName(name);
    }, [name]);
    var rest = editable
        ? {
            editable: {
                onChange: function (value) {
                    setPartName(value);
                    handleUpdate(value);
                },
            },
        }
        : {};
    return (React.createElement(Paragraph, __assign({ style: {
            fontWeight: 'bold',
            fontSize: 20,
            margin: 0,
        } }, rest), partName.toUpperCase()));
};
