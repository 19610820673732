import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Col, Divider, Form, Input, notification, Row, Select, Spin } from 'antd';
import { AccountStore } from '../../stores/AccountStore';
import { ErrorRender } from '@partsbadger/library';
import { post } from '@partsbadger/utils';
import { IZohoLeadListStaff as IZohoLead } from '@partsbadger/types';
import { ZOHO_BASE_URL } from '@partsbadger/utils';

export const LeadConversionTool = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [email_lead_referral, setEmailLeadReferral] = useState<boolean>(false);

    // Stores the account info, if it exists
    const [account, setAccount] = useState<{ name: string; account_zoho_id: string; owner_zoho_id: string } | null>(
        null
    );

    // Stores the lead selected from found_leads
    const [lead_selected, setLeadSelected] = useState<IZohoLead | undefined>(undefined);

    // Stores the leads found when searching by email
    const [found_leads, setFoundLeads] = useState<IZohoLead[] | []>([]);

    // Stores a message when there is already a contact with the same email of the lead
    const [sms_warning, setSmsWarning] = useState<string | null>(null);

    useEffect(() => {
        form.resetFields();
        setLoading(false);
        setEmailLeadReferral(false);
        setAccount(null);
        setLeadSelected(undefined);
        setFoundLeads([]);
        setSmsWarning(null);
    }, []);

    const onFinish = async (values: any): Promise<void> => {
        if (lead_selected) {
            const payload: {
                lead_zoho_id: string | null;
                account_zoho_id: string | null;
                referral: boolean;
                referral_name: string | null;
                account_id: string | null;
                acquisition_source: string | null;
                phone: string | null;
                title: string | null;
            } = {
                lead_zoho_id: lead_selected.id,
                account_zoho_id: null,
                referral: email_lead_referral,
                referral_name: email_lead_referral ? values.referral_name : null,
                account_id: null,
                acquisition_source: values.contact_aq_source,
                phone: lead_selected.Phone,
                title: lead_selected.Designation,
            };

            if (account) {
                payload.account_zoho_id = account.account_zoho_id;
            }

            setLoading(true);

            await AccountStore.convertLeadToContact(payload)
                .then(response => {
                    form.resetFields();
                    setAccount(null);
                    setLeadSelected(undefined);
                    setFoundLeads([]);
                    setSmsWarning(null);

                    notification.success({
                        message: 'Info!',
                        description: response.data.message,
                        placement: 'top',
                    });
                })
                .catch(error => {
                    notification.error({
                        message: 'Conversion to Lead Failed. Internal Error: ' + error.response.data.message,
                        placement: 'topRight',
                    });
                })
                .finally(() => {
                    setEmailLeadReferral(false);
                    setLoading(false);
                });
        }
    };

    const verifyAccountAndContact = async (new_lead: IZohoLead): Promise<void> => {
        // Check if the account and contact already exist in local database
        await AccountStore.existingAccount(new_lead.Company, new_lead.Email)
            .then((response: any): void => {
                if (response.data.account.zoho_id !== null) {
                    form.setFieldsValue({
                        account_type: `Existing Account - ${response.data.account.name}`,
                        account_owner: response.data.account.account_owner,
                        email_lead_gen_name: new_lead?.First_Name + ' ' + new_lead?.Last_Name,
                        email_lead_gen_email: new_lead?.Email,
                        contact_owner: response.data.account.account_owner,
                    });

                    setAccount({
                        name: response.data.account.name,
                        account_zoho_id: response.data.account.zoho_id,
                        owner_zoho_id: response.data.account.owner_zoho_id,
                    });
                }

                if (response.data.contact.exists === true) {
                    setSmsWarning(
                        'This lead already exists in Zoho as a contact. Please opt out the lead by clicking the following link:'
                    );
                }

                if (response.data.account.zoho_id === null && response.data.contact.zoho_id === null) {
                    form.setFieldsValue({
                        account_type: 'New Account',
                        account_owner: 'PartsBadger',
                        email_lead_gen_name: new_lead?.First_Name + ' ' + new_lead?.Last_Name,
                        email_lead_gen_email: new_lead?.Email,
                        contact_owner: 'PartsBadger',
                    });
                }
            })
            .catch((error): void => {
                notification.error({
                    message: <ErrorRender error={error} />,
                    placement: 'topRight',
                });
            });
    };

    async function searchLeads(params: { search: string }) {
        // Search for leads by email
        const data = await post('/staff/leads/search/', {
            email: params.search,
        });

        setLoading(false);

        return data;
    }

    const SearchLeadInput = () => {
        const [loading, setLoading] = useState<boolean>(false);
        return (
            <Spin spinning={loading} tip={'Searching Leads'}>
                <Form.Item
                    label="Search Lead To Convert"
                    name={'search_email'}
                    help={'Input an email and press enter to search for a lead'}
                >
                    <Input
                        type={'email'}
                        width={400}
                        placeholder={'Input an email and press enter'}
                        onPressEnter={e => {
                            setLoading(true);
                            const email = e.currentTarget.value;
                            setSmsWarning(null);
                            setLeadSelected(undefined);

                            searchLeads({ search: email })
                                .then(response => {
                                    setFoundLeads(response);
                                    if (response.length === 0) {
                                        notification.warning({
                                            message: 'Warning!',
                                            description: 'No leads found with the email: ' + email,
                                            placement: 'top',
                                        });
                                    }
                                })
                                .catch(error => {
                                    notification.error({
                                        message: <ErrorRender error={error} />,
                                        placement: 'bottom',
                                    });
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                    />
                </Form.Item>
            </Spin>
        );
    };

    return (
        <div>
            <Divider>
                <div className={'title'}>Lead Conversion Tool</div>
                <div>
                    Use the form below to convert a lead into a Contact.
                    <br />
                    This form ensures that we have all of the necessary information in order to assign the your lead...
                </div>
            </Divider>

            <div className="flex justify-center items-center">
                <Form layout={'horizontal'} onFinish={onFinish} form={form}>
                    <Row style={{ maxWidth: '800px', margin: 'auto' }}>
                        <Col span={24}>
                            <SearchLeadInput />
                        </Col>
                        {found_leads.length > 0 && (
                            <Col span={24}>
                                <Form.Item label="Select Lead To Convert" name={'lead'}>
                                    <Select
                                        showSearch
                                        allowClear
                                        filterOption={false}
                                        style={{ width: '100%' }}
                                        notFoundContent={loading ? <Spin size="small" /> : null}
                                        onSelect={async value => {
                                            const new_lead: IZohoLead | undefined = found_leads.find(
                                                (lead: IZohoLead): boolean => lead.id.toString() === value.toString()
                                            );
                                            setLeadSelected(new_lead);
                                            if (new_lead) {
                                                await verifyAccountAndContact(new_lead);
                                            }
                                        }}
                                    >
                                        {found_leads.map((lead: IZohoLead) => (
                                            <Select.Option key={lead.id} value={lead.id}>
                                                <div>
                                                    {lead.Company} - {lead.First_Name} {lead.Last_Name}
                                                </div>
                                                <div className={'text-xs'}>{lead.Email}</div>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}

                        {sms_warning ? (
                            <Col span={24}>
                                <Alert
                                    message={
                                        <span>
                                            {sms_warning}{' '}
                                            <a
                                                target={'_blank'}
                                                href={`${ZOHO_BASE_URL}/Leads/${lead_selected?.id}`}
                                                rel="noreferrer"
                                            >
                                                View Lead in Zoho
                                            </a>
                                        </span>
                                    }
                                    type="error"
                                    showIcon
                                    style={{ marginBottom: '1rem' }}
                                />
                                <Button
                                    className={'mr-2'}
                                    type="default"
                                    onClick={() => {
                                        form.resetFields();
                                        setLoading(false);
                                        setEmailLeadReferral(false);
                                        setAccount(null);
                                        setLeadSelected(undefined);
                                        setFoundLeads([]);
                                        setSmsWarning(null);
                                    }}
                                >
                                    Cancel Conversion
                                </Button>
                            </Col>
                        ) : (
                            lead_selected?.id && (
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Account Type</span>}
                                            name={'account_type'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Account Owner</span>}
                                            name={'account_owner'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Account AQ Source</span>}
                                            name={'account_aq_source'}
                                            initialValue={'Email Lead Gen'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Email Lead Gen Name</span>}
                                            name={'email_lead_gen_name'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Email Lead Gen Email</span>}
                                            name={'email_lead_gen_email'}
                                            help={
                                                sms_warning && (
                                                    <span className={'text-red-500 text-xs'}>{sms_warning}</span>
                                                )
                                            }
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Contact Owner</span>}
                                            name={'contact_owner'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span className={'font-bold'}>Contact AC Source</span>}
                                            name={'contact_aq_source'}
                                            initialValue={'Email Lead Gen'}
                                        >
                                            <Input
                                                type="text"
                                                readOnly
                                                style={{ outline: 'none', border: 'none', boxShadow: 'none' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <span>
                                            Email Lead Gen Referral{' '}
                                            <Checkbox
                                                onChange={e => {
                                                    form.resetFields(['referral_name']);
                                                    setEmailLeadReferral(e.target.checked);
                                                }}
                                            />
                                        </span>
                                        <Form.Item
                                            label="Referral Name"
                                            name={'referral_name'}
                                            rules={[
                                                { required: email_lead_referral, message: 'This field is required!' },
                                            ]}
                                        >
                                            <Input type="text" disabled={!email_lead_referral} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} className="flex justify-start">
                                        {sms_warning === null && (
                                            <Button
                                                className={'mr-2'}
                                                type="primary"
                                                loading={loading}
                                                onClick={form.submit}
                                                disabled={!lead_selected}
                                            >
                                                Convert Lead
                                            </Button>
                                        )}
                                        <Button
                                            className={'mr-2'}
                                            type="default"
                                            onClick={() => {
                                                form.resetFields();
                                                setLoading(false);
                                                setEmailLeadReferral(false);
                                                setAccount(null);
                                                setLeadSelected(undefined);
                                                setFoundLeads([]);
                                                setSmsWarning(null);
                                            }}
                                        >
                                            Cancel Conversion
                                        </Button>
                                    </Col>
                                </>
                            )
                        )}
                    </Row>
                </Form>
            </div>
        </div>
    );
};
