import React, { useEffect } from 'react';
import { Button, Col, PageHeader, Row, Typography } from 'antd';
import { observer } from 'mobx-react';

import { BusinessRuleStore } from '../../components/BusinessRules/Stores/BusinessRuleStore';
import TableBusinessRule from '../../components/BusinessRules/TableBusinessRule';
import { useHistory, useLocation } from 'react-router-dom';
import BusinessRuleFilter from '../../components/BusinessRules/BusinesRulesFilter';
import { useQueryParameters } from '@partsbadger/utils';

const BusinessRuleIndexPage = () => {
    const history = useHistory();

    const location = useLocation();

    const searchParams = useQueryParameters();

    const getBusinessRules = async () => {
        console.log(searchParams);
        await BusinessRuleStore.getAllRules(searchParams);
    };

    useEffect(() => {
        getBusinessRules();
    }, [location.search]);

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    title="Business Rules"
                    extra={
                        <Button
                            type={'primary'}
                            onClick={() => {
                                BusinessRuleStore.setRule(null);
                                history.push('/business-rules/new/');
                            }}
                        >
                            Add Rule
                        </Button>
                    }
                />
            </Col>
            <Col>
                <BusinessRuleFilter />
            </Col>
            <Col span={24}>
                <TableBusinessRule />
            </Col>
        </Row>
    );
};

export default observer(BusinessRuleIndexPage);
