var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
export var Purchasing = function (props) {
    var purchasing_type_options = ['End User', 'Broker'];
    var options = purchasing_type_options.map(function (o, index) {
        return (React.createElement(Select.Option, { key: index, value: o }, o));
    });
    return (React.createElement(Select, __assign({ showSearch: true, optionFilterProp: "children", dropdownMatchSelectWidth: false, filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.props) === null || _a === void 0 ? void 0 : _a.children.toLowerCase().indexOf(input.toLowerCase())) >= 0; } }, props), options));
};
