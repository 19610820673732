import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DomesticVendorStore } from '../../../stores/DomesticVendorStore';
import { Button, Col, Divider, notification, Row, Table } from 'antd';
import { PaginationComponent } from '../../../components/Inputs/Pagination';
import { IDomesticVendor } from '@partsbadger/types/lib/staff/list/IDomesticVendor';
import { PaginationProps } from 'antd/es/pagination';
import { useQueryParameters } from '@partsbadger/utils';
import DomesticVendorsFilter from './DomesticVendorsFilter';

const DomesticVendorsPage = () => {
    const params = useQueryParameters();
    const [pagination, setPagination] = useState<PaginationProps>({});

    const getLists = async (params = {}) => {
        await DomesticVendorStore.getList(params);
        setPagination({
            pageSize: 30,
            total: DomesticVendorStore.paginationCount,
        });
    };

    useEffect(() => {
        getLists(params);
    }, [location.search]);

    const handleSendToFastlane = async (id: number) => {
        try {
            await DomesticVendorStore.sendToFastlane(id);
            const args = {
                message: 'Success!',
                description: 'Material Vendor has been sent to Fastlane.',
                duration: 20,
            };
            notification.success(args);
        } catch (error) {
            const args = {
                message: 'Error!',
                description: 'There was an error sending the Material Vendor to Fastlane.',
                duration: 20,
            };
            notification.error(args);
        }
    };

    const handleSendToZoho = async (id: number) => {
        try {
            await DomesticVendorStore.sendToZoho(id);
            const args = {
                message: 'Success!',
                description: 'Material Vendor has been sent to Zoho.',
                duration: 20,
            };
            notification.success(args);
            await getLists(params);
        } catch (error) {
            const args = {
                message: 'Error!',
                description: 'There was an error sending the Material Vendor to Zoho.',
                duration: 20,
            };
            notification.error(args);
        }
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: IDomesticVendor) => <span>{text}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Street',
            dataIndex: 'street',
            key: 'street',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            render: (text: string, record: IDomesticVendor) => (
                <a href={text} target={'_blank'} rel="noreferrer">
                    {text}
                </a>
            ),
        },
        {
            title: 'Quickbooks ID',
            dataIndex: 'quickbooks_id',
            key: 'quickbooks_id',
        },
        {
            title: 'Zoho ID',
            dataIndex: 'zoho_id',
            key: 'zoho_id',
            render: (text: string, record: IDomesticVendor) => (
                <div>
                    <Button type={'link'} onClick={() => handleSendToZoho(record.id)}>
                        {text ? 'Resend' : 'Send'} To Zoho
                    </Button>
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, record: IDomesticVendor) => (
                <div>
                    <Button type={'link'} onClick={() => handleSendToFastlane(record.id)}>
                        Send To Fastlane
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <DomesticVendorsFilter />
            <Divider>
                <div className={'title'}>Domestic Vendors</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IDomesticVendor>
                        rowKey={'id'}
                        columns={tableColumns}
                        dataSource={DomesticVendorStore.materialVendors}
                        loading={DomesticVendorStore.isLoading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default observer(DomesticVendorsPage);
