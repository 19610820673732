import React from 'react';
import { notification, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

import { getCurrentUser } from 'components/User';
import { PurchaseOrder } from '@partsbadger/purchase_order_tool';

const PurchaseOrderEdit = (props: any) => {
    const purchase_order_id = props.match.params.purchase_order_id;
    const history = useHistory();

    const current_user: any = getCurrentUser()?.data;

    return (
        <div style={{ margin: 'auto' }}>
            <PageHeader title={'Edit Purchase Order'} onBack={() => history.push('/purchase-orders/')} />

            <PurchaseOrder
                current_user={current_user}
                purchase_order_id={purchase_order_id}
                onSubmitted={() => {
                    history.push('/purchase-orders/');
                    notification.success({
                        message: 'The purchase order was updated',
                    });
                }}
            />

            <br />
            <p style={{ textAlign: 'center' }}>Need Help? Call (331) BADGER-8</p>
        </div>
    );
};

export default PurchaseOrderEdit;
