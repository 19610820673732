import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ThreeDViewer } from '@partsbadger/threedviewer';
// Custom hook to get the size of the container
var useContainerSize = function (ref, modalVisible) {
    var _a = useState({ width: 0, height: 0 }), size = _a[0], setSize = _a[1];
    // Function to update the size of the container
    var updateSize = useCallback(function () {
        if (ref.current) {
            var width = ref.current.clientWidth;
            var height = ref.current.clientHeight;
            if (width !== size.width || height !== size.height) {
                setSize({ width: width, height: height });
            }
        }
    }, [ref, size]);
    // Update the size when the modal becomes visible
    useEffect(function () {
        if (modalVisible) {
            // Use requestAnimationFrame to ensure the size calculation happens after the render
            var handle_1 = requestAnimationFrame(updateSize);
            return function () { return cancelAnimationFrame(handle_1); };
        }
    }, [modalVisible, updateSize]);
    // Return the current size
    return size;
};
// Main component for the ThreeDViewerModal
var ThreeDViewerModal = function (_a) {
    var url = _a.url, visible = _a.visible, onClose = _a.onClose, loading = _a.loading;
    var modalRef = useRef(null); // Ref for the modal container
    var size = useContainerSize(modalRef, visible); // Get the size using the custom hook
    return (React.createElement(Modal, { open: visible, footer: null, centered: true, width: "90vw", maskClosable: true, onCancel: onClose, bodyStyle: { height: '720px', margin: 0, padding: 0 }, style: { maxWidth: '960px' } },
        React.createElement("div", { className: "w-100 h-100 d-flex align-items-center justify-content-center", ref: modalRef, style: { minHeight: '720px', backgroundColor: '#f0f0f0' } }, loading ? (React.createElement(Spin, { indicator: React.createElement(LoadingOutlined, null), tip: "Loading 3D Model..." })) : (url &&
            size.width > 0 &&
            size.height > 0 && (React.createElement(ThreeDViewer, { url: url, canvasStyle: { width: "".concat(size.width, "px"), height: "".concat(size.height, "px") } }))))));
};
export default ThreeDViewerModal;
