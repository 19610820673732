import React, { useEffect, useState } from 'react';

import { Form, message, Input, Radio, Space, Select, Button, notification } from 'antd';

import type { RadioChangeEvent } from 'antd';

import { FormErrorParser } from '@partsbadger/library';

import { post } from '../../../shared';

interface IProps {
    orderData: { id: string; line_items: [] } | null;
    onCancel: () => void;
}

const RequestCancelationHold: React.FC<IProps> = ({ orderData, onCancel }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const handleSubmit = async (values: any) => {
        setLoading(true);

        const getLine_items = orderData?.line_items.filter((item: any) => {
            return values.line_items.includes(item.id);
        });

        const payload = {
            line_items: getLine_items,
            customer_statement: values.customer_statement,
            status: values.status,
        };

        await post(`staff/sales-orders/${orderData?.id}/cancel-or-hold/`, payload)
            .then((r: any) => {
                onCancel();
            })
            .catch((err: any) => {
                setLoading(false);
                message.error(`Error: ${err}`);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Form
            form={form}
            onFinish={values => handleSubmit(values)}
            layout="vertical"
            className="md:mx-5"
            onFinishFailed={errorInfo => {
                notification.error({
                    key: 'form-errors',
                    duration: 8,
                    message: 'Please input all required fields',
                    description: <FormErrorParser isAntd4 errors={errorInfo.errorFields} />,
                    placement: 'top',
                    maxCount: 1,
                });
            }}
        >
            <Form.Item
                name="line_items"
                label="Select Sales Order Items"
                rules={[{ required: true, message: 'Please input a name' }]}
            >
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    options={orderData?.line_items.map((item: any) => {
                        return { label: item.name, value: item.id };
                    })}
                />
            </Form.Item>
            <Form.Item
                name="customer_statement"
                label="Customer Statement"
                rules={[{ required: true, message: 'Customer Statement is required.' }]}
            >
                <Input.TextArea autoSize={true} />
            </Form.Item>
            <Form.Item
                name="status"
                label="Select either “Cancel” or “Hold”"
                rules={[{ required: true, message: 'Customer Statement is required.' }]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value="Cancel">Cancel</Radio>
                        <Radio value="Hold">Hold</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Form.Item noStyle className="w-full">
                <div className="flex justify-between ">
                    <Button
                        loading={loading}
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default RequestCancelationHold;
