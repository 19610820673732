import {get, post} from '../../shared/api'

import * as ActionTypes from "../ActionTypes";


export const fetchDealById = (id) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.DEAL_LOADING});
        return get("staff/deals/" + id + "/",).then(response => {
            if (response.status === 200)
                dispatch({type: ActionTypes.DEAL_LOADED, payload: response.data});
        }).catch(error => dispatch({type: ActionTypes.DEAL_FAILED, error: error}));
    }
}


export const fetchDeals = (params) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.DEALS_LOADING});
        return get("staff/deals/", params).then(response => {
            if (response.status === 200)
                dispatch({type: ActionTypes.DEALS_LOADED, payload: response.data.results});
        }).catch(error => dispatch({type: ActionTypes.DEALS_FAILED, error: error}));
    }
}


export const postDeal = (payload, ownsProps) => {
    return (dispatch, getState) => {
        dispatch({type: ActionTypes.DEAL_LOADING});
        return post("staff/deals/", payload).then(response => {
            dispatch({type: ActionTypes.DEAL_ADDED, payload: response.data});
            dispatch(fetchDeals([]));
            ownsProps.history.push(`/deals/${response.data.id}`)
        }).catch(error => dispatch({type: ActionTypes.DEAL_FAILED, error: error}));
    }
}


export const showAddDealForm = () => ({
    type: ActionTypes.DEAL_SHOW_ADD_FORM
});


export const hideAddDealForm = () => ({
    type: ActionTypes.DEAL_HIDE_ADD_FORM
});



