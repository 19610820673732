import React from 'react';
import { Col, Row } from 'antd';
import { styleBody } from '../Forms/SalesOrderCreateForm/SalesOrderCreateForm';
import SalesOrderBatchStore from '../Stores/SalesOrderBatchStore';
import SalesOrderStore from '../Stores/SalesOrderStore';
export var SalesOrderListBatches = function (props) {
    return (React.createElement("div", { className: 'w-full' },
        React.createElement("div", { className: 'flex' },
            React.createElement(Row, { style: { margin: '0 auto', width: '75%' } },
                React.createElement(Col, { span: 4 }),
                React.createElement(Col, { span: 6 },
                    React.createElement("div", { style: styleBody, className: 'flex justify-start font-bold' }, "Part Name")),
                React.createElement(Col, { span: 2 },
                    React.createElement("div", { style: styleBody, className: 'flex justify-start font-bold' }, "Qty")),
                React.createElement(Col, { span: 12 },
                    React.createElement("div", { style: styleBody, className: 'flex justify-start font-bold' }, "Estimated Ship Dates")))),
        SalesOrderStore.order_items.map(function (item) {
            return (React.createElement(React.Fragment, null, item.quote_has_product && (React.createElement(React.Fragment, null,
                React.createElement(Col, { key: SalesOrderStore.order_items.indexOf(item) },
                    React.createElement("div", { className: 'flex justify-center' },
                        React.createElement(Row, { style: { margin: '0 auto', width: '75%' } },
                            React.createElement(Col, { span: 4 }),
                            React.createElement(Col, { span: 6 },
                                React.createElement("div", { className: 'flex justify-start', style: styleBody }, item.name)),
                            React.createElement(Col, { span: 2 },
                                React.createElement("div", { className: 'flex justify-start', style: styleBody }, item.quantity)),
                            React.createElement(Col, { span: 12 },
                                React.createElement("div", { style: styleBody, className: 'flex justify-start' },
                                    SalesOrderBatchStore.batches.map(function (batch) {
                                        if (batch.order_item === item.id) {
                                            SalesOrderBatchStore.resetTotalQuantity();
                                            SalesOrderBatchStore.getQuantity(batch.order_item);
                                            SalesOrderBatchStore.getOriginalQuantity(item.quantity);
                                            return (React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: 'px-2' }, batch === null || batch === void 0 ? void 0 :
                                                    batch.required_part_ship_date,
                                                    " / (",
                                                    batch.quantity,
                                                    ")")));
                                        }
                                    }),
                                    item.batches &&
                                        item.batches.length > 0 &&
                                        SalesOrderBatchStore.total_quantity !==
                                            SalesOrderBatchStore.original_quantity && (React.createElement("div", { style: { color: 'red' } }, "Quantities do not match.")),
                                    React.createElement("div", { className: 'px-2' },
                                        React.createElement("a", { className: "link", onClick: function () {
                                                props.setOrderItem(item);
                                                props.setShowModalBatches(!props.showModalBatches);
                                            } }, "Add/Remove Dates")))))))))));
        })));
};
