import React from 'react';
import moment from 'moment';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { Accounts, Contacts, DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';

const Search = Input.Search;

const FormItem = Form.Item;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const InvoiceFilter = () => {
    const query = useQueryParameters();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        const params: any = {};

        if (values.search) {
            params['search'] = values.search;
        }

        // if (values.created_time) {
        //     params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
        //     params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
        // }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        history.replace(`/invoices/?${queryString}`);
    };

    return (
        <Form form={form} layout="inline" onFinish={handleSubmit}>
            <Form.Item label="Search" name="search">
                <Input />
            </Form.Item>

            {/*<Form.Item*/}
            {/*    label={'Created Time'}*/}
            {/*    initialValue={*/}
            {/*        query.get('created_time_min') && query.get('created_time_max')*/}
            {/*            ? [*/}
            {/*                  moment(query.get('created_time_min'), 'YYYY-MM-DD'),*/}
            {/*                  moment(query.get('created_time_max'), 'YYYY-MM-DD'),*/}
            {/*              ]*/}
            {/*            : null*/}
            {/*    }*/}
            {/*>*/}
            {/*    <DateRangePicker size="small" />*/}
            {/*</Form.Item>*/}

            <Form.Item>
                <Button
                    onClick={() => {
                        history.push(`/invoices/`);
                        form.resetFields();
                    }}
                >
                    Reset
                </Button>
                <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};

export default InvoiceFilter;
