import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportStore } from '../../stores';
import { DateRangePicker } from '@partsbadger/library';

const { RangePicker } = DatePicker;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;
const { Option } = Select;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const QualityKpisReportFilter = (props: { form: any }) => {
    const query = useQuery();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        form.validateFields((err: any, values: { reason: string; created_time: moment.MomentInput[] }) => {
            if (!err) {
                const params: any = [];

                if (values.reason) {
                    params['reason'] = values.reason;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');
                history.replace(`/reports/quality-kpis/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Col {...cols}>
                    <FormItem label="Date" {...formItemLayout}>
                        {getFieldDecorator('created_time', {
                            initialValue:
                                query.get('created_time_min') && query.get('created_time_max')
                                    ? [
                                          moment(query.get('created_time_min'), 'YYYY-MM-DD'),
                                          moment(query.get('created_time_max'), 'YYYY-MM-DD'),
                                      ]
                                    : null,
                        })(<DateRangePicker size="small" />)}
                    </FormItem>
                </Col>

                <Col {...cols}>
                    <Form.Item>
                        <Button
                            onClick={() => {
                                ReportStore.quality_kpis = null;
                                history.push(`/reports/quality-kpis/`);
                                form.resetFields();
                            }}
                        >
                            Reset
                        </Button>
                        <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                            Filter
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
        </Row>
    );
};

export const QualityKpisReportFilterForm = Form.create()(QualityKpisReportFilter);
