var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
var style = {
    minHeight: '4rem',
    width: '100%',
    marginRight: '1.5rem',
    padding: '1rem',
    fontSize: '1rem',
    lineHeight: 'normal',
    color: 'rgba(0, 0, 0, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
export var PartSlot = function (_a) {
    var accept = _a.accept, newRecords = _a.newRecords, lastDroppedItem = _a.lastDroppedItem, onDrop = _a.onDrop, onDropNew = _a.onDropNew;
    var _b = useDrop(function () { return ({
        accept: __spreadArray(__spreadArray([], accept, true), [NativeTypes.FILE], false),
        drop: function (item) {
            var _a;
            if ((item === null || item === void 0 ? void 0 : item.index) >= 0) {
                onDrop(item);
            }
            else {
                if ((_a = item['files']) === null || _a === void 0 ? void 0 : _a.length) {
                    onDropNew(item['files']);
                }
            }
            return null;
        },
        collect: function (monitor) { return ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }); },
    }); }, [newRecords]), _c = _b[0], isOver = _c.isOver, canDrop = _c.canDrop, drop = _b[1];
    var isActive = isOver && canDrop;
    var activeStyles = isActive
        ? {
            border: '1px #d92923 dashed',
        }
        : {
            border: '1px #d9d9d9 solid',
        };
    return (React.createElement("div", { ref: drop, style: __assign(__assign({}, style), activeStyles) }, lastDroppedItem ? (React.createElement("div", { title: 'Drag and Drop to reorder' }, lastDroppedItem)) : (React.createElement("div", { title: 'Drop a file here ', style: {
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.24)',
        } }, "No files"))));
};
