import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Table, Spin, Empty, message } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { IShipmentProps, ISalesOrderProps } from '../../../../utils/types';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { DownloadFile } from '@partsbadger/utils';
import { SalesOrderProps } from '@partsbadger/types';

interface IProps {
    order: SalesOrderProps;
}

const Shipments: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false);

    const getPackList = async (id: number, box_id: number) => {
        setLoading(true);
        SalesOrderStore.getPackListBySalesOrderId(id, box_id)
            .then(data => {
                DownloadFile(data, `${props.order?.carrier ? props.order?.carrier + '-' + box_id : 'pack-' + id}.pdf`);
                setLoading(false);
                message.success('Document downloaded!');
            })
            .catch(error => message.error(`Error loading PackList: ${error}`));
    };

    const columns: ColumnProps<IShipmentProps>[] = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            // width: '10%',
            render: (text, record) => {
                if (record.created_time != null) {
                    return <span>{dayjs(record.created_time).add(7, 'day').format('YYYY-MM-DD')}</span>;
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Carrier',
            dataIndex: 'carrier',
            key: 'carrier',
            // width: '20%',
        },
        {
            title: 'Tracking',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
            // align: 'center',
        },
        {
            title: 'Download',
            dataIndex: 'download',
            key: 'download',
            // align: 'right',
            render: (text, record) =>
                loading ? (
                    <Spin spinning={loading} className="ml-5" />
                ) : (
                    <a
                        className="underline"
                        onClick={() => {
                            getPackList(props.order?.id, record.id);
                        }}
                    >
                        Download
                    </a>
                ),
        },
    ];

    return (
        <Table<IShipmentProps>
            rowKey={record => record.id.toString()}
            pagination={false}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.shipments}
            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span style={{ color: '#000' }}>Sorry... This order does not have shipments.</span>
                        }
                    />
                ),
            }}
        />
    );
};

export default Shipments;
