import React from 'react';
import { Button, Col, Form, Input, Select } from 'antd';
import { useQueryParameters } from '@partsbadger/utils';
import { useHistory } from 'react-router-dom';
import { QuotedProductStore } from '../../../stores';

const FormItem = Form.Item;

export const PartsBadgerProductionFilter = () => {
    const queryParams = useQueryParameters();
    const history = useHistory();
    const [form] = Form.useForm();
    const onFinish = (values: {
        search: string;
        quote_status: string;
        partsbadger_production_method: string;
    }): void => {
        if (values.search) {
            queryParams.set('search', values.search);
        }
        if (values.quote_status) {
            queryParams.set('quote_status', values.quote_status);
        }
        if (values.partsbadger_production_method) {
            queryParams.set('partsbadger_production_method', values.partsbadger_production_method);
        }
        history.push('/partsbadger-production?' + queryParams.toString());
    };

    return (
        <div>
            <Form
                name="partsbadger_production_filter"
                form={form}
                layout={'horizontal'}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <div className="flex flex-wrap justify-center">
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
                        <FormItem name={'search'} initialValue={queryParams.get('search')}>
                            <Input.Search placeholder="Search" allowClear size={'small'} />
                        </FormItem>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
                        <FormItem
                            label="Quote Status"
                            name={'quote_status'}
                            initialValue={queryParams.get('quote_status')}
                        >
                            <Select style={{ width: '100%' }} size={'small'}>
                                <Select.Option value="In Queue">In Queue</Select.Option>
                                <Select.Option value="In Progress">In Progress</Select.Option>
                                <Select.Option value="Waiting Customer">Waiting Customer</Select.Option>
                                <Select.Option value="Vendor Quoting">Vendor Quoting</Select.Option>
                                <Select.Option value="Needs Review">Needs Review</Select.Option>
                                <Select.Option value="Reviewed">Reviewed</Select.Option>
                                <Select.Option value="Completed">Completed</Select.Option>
                                <Select.Option value="Cancelled">Cancelled</Select.Option>
                                <Select.Option value="Closed Won">Closed Won</Select.Option>
                                <Select.Option value="Closed Lost">Closed Lost</Select.Option>
                            </Select>
                        </FormItem>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4">
                        <FormItem
                            label="Production Method"
                            name={'partsbadger_production_method'}
                            initialValue={queryParams.get('partsbadger_production_method')}
                        >
                            <Select style={{ width: '100%' }} size={'small'}>
                                <Select.Option value="Fastlane">Fastlane</Select.Option>
                                <Select.Option value="QuickTurn">QuickTurn</Select.Option>
                                <Select.Option value="Swiss">Swiss</Select.Option>
                                <Select.Option value="Traditional">Traditional</Select.Option>
                            </Select>
                        </FormItem>
                    </div>
                    <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    form.setFieldValue('search', '');
                                    form.setFieldValue('quote_status', '');
                                    form.setFieldValue('partsbadger_production_method', '');
                                    history.replace('/partsbadger-production');
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </div>
            </Form>
        </div>
    );
};
