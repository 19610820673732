import React, { useEffect } from 'react';
import { Col, Divider, notification, Row, Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { QuotedProductFilter } from '../../components/QuoteTool';
import { QuotedProductStore } from '../../stores';
import { observer } from 'mobx-react';
import { DateRender, ErrorRender, PaginationComponent, Price } from '@partsbadger/library';
import { OrderQuotedProductProps } from '@partsbadger/types';

const { Column } = Table;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const QuotedProductList = observer(() => {
    const history = useHistory();
    const searchParams = useQuery();

    async function handleListQuotedProducts(params = {}): Promise<void> {
        try {
            await QuotedProductStore.getVendorQuotedProducts(params);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        handleListQuotedProducts(searchParams);
    }, [location.search]);

    useEffect(() => {
        QuotedProductStore.getFormulas(true);
    }, []);

    return (
        <div>
            <Divider>
                <div className={'title'}>Quote Pricing Feedback Dashboard</div>
            </Divider>

            <QuotedProductFilter />

            <Row className={'mt-8'}>
                <Col span={24}>
                    <Table
                        rowKey={'id'}
                        dataSource={QuotedProductStore.quotedProducts}
                        loading={QuotedProductStore.isLoading}
                        pagination={false}
                        onRow={record => {
                            return {
                                onClick: () => history.push('/quoted-product/' + record.id),
                            };
                        }}
                        footer={() => {
                            return (
                                <PaginationComponent
                                    defaultPageSize={QuotedProductStore.pagination.defaultPageSize}
                                    total={QuotedProductStore.pagination.total}
                                    pageChange={page => {
                                        searchParams.set('page', page.toString());
                                        history.replace(`?${searchParams.toString()}`);
                                    }}
                                />
                            );
                        }}
                        scroll={{ x: 1500 }}
                    >
                        <Column title="Order Number" dataIndex="order_number" key="order_number" />
                        <Column title="Account Name" dataIndex="account_name" key="account_name" />
                        <Column title="Part Name" dataIndex="part_name" key="part_name" />
                        <Column
                            title="Acceptance Date"
                            dataIndex="award_date"
                            key="award_date"
                            render={(_, record: OrderQuotedProductProps) => {
                                return record.award_date ? (
                                    <div>
                                        <DateRender value={record.award_date} format={'MMMM D, YYYY'} />
                                    </div>
                                ) : (
                                    <div>No awarded yet!</div>
                                );
                            }}
                        />
                        <Column title="Formula" dataIndex="formula" key="formula" />
                        <Column title="Manual Adjust" dataIndex="manual_adjust" key="manual_adjust" />
                        <Column title="Vendor Adjust" dataIndex="vendor_adjust" key="vendor_adjust" />
                        <Column title="Sides/FD" dataIndex="sides" key="sides" />
                        <Column
                            title="Awarded Vendor Total Price"
                            dataIndex="vendor_total_price"
                            key="vendor_total_price"
                            render={(_, record: OrderQuotedProductProps) => {
                                return <Price value={record.vendor_total_price} />;
                            }}
                        />
                        <Column
                            title="Estimated Vendor Total Cost"
                            dataIndex="vendor_estimated_price"
                            key="vendor_estimated_price"
                            render={(_, record: OrderQuotedProductProps) => {
                                return <Price value={record.vendor_estimated_price} />;
                            }}
                        />
                        <Column
                            title="DE Adjust Error"
                            dataIndex="de_adj_error"
                            key="de_adj_error"
                            render={(_, record: OrderQuotedProductProps) => {
                                return <Price value={record.de_adj_error} />;
                            }}
                        />
                    </Table>
                </Col>
            </Row>
        </div>
    );
});
