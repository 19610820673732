var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Select, Spin, Input } from 'antd';
import { get } from '@partsbadger/utils';
var busy = null;
export var Accounts = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), accounts = _b[0], setAccounts = _b[1];
    var _c = useState(0), selectValue = _c[0], setSelectValue = _c[1];
    var account = props.account;
    var fetchRecords = function (params) {
        get("/staff/accounts/", {
            params: params,
        })
            .then(function (data) {
            setAccounts(data.results);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    if (props.zoho_id) {
        useEffect(function () {
            get("/staff/accounts/", {
                params: {
                    zoho_id: props.zoho_id,
                },
            })
                .then(function (data) {
                setTimeout(function () {
                    if (props.setAccount) {
                        props.setAccount(data.results[0]);
                    }
                    setSelectValue(data.results[0].name);
                }, 100);
            })
                .finally(function () {
                setLoading(false);
            });
        }, [props.zoho_id]);
    }
    return props.zoho_id && selectValue ? (React.createElement(Input, { disabled: true, size: 'small', style: { width: '100%' }, defaultValue: selectValue })) : (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search account by  name or email", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : null, onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
            };
            if (account) {
                params['account'] = props.account;
            }
            setLoading(true);
            busy = setTimeout(function () { return fetchRecords(params); }, 400);
        } }, props), accounts.map(function (entity) { return (React.createElement(Select.Option, { key: entity.id, value: entity.id }, entity.name)); })));
};
