import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Row, Select } from 'antd';

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 6,
    },
};

const { TextArea } = Input;

const UpdateMultipleLineItems = ({ records, handleChange }) => {
    const [quantities, setQuantities] = useState([]);
    const [materials, setMaterials] = useState();
    const [notes, setNotes] = useState();

    return (
        <Row
            style={{
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                padding: '5px',
            }}
        >
            <Form.Item label="Quantities" {...formItemLayout}>
                <Select
                    required={true}
                    mode="tags"
                    style={{ minWidth: '200px' }}
                    tokenSeparators={[',']}
                    onInputKeyDown={evt => {
                        const charCode = evt.keyCode;
                        if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57) &&
                            (charCode < 96 || charCode > 105) &&
                            charCode !== 188
                        ) {
                            evt.preventDefault();
                        }
                    }}
                    onChange={values => {
                        setQuantities(values.filter(v => !isNaN(v)));
                    }}
                    value={quantities}
                ></Select>
            </Form.Item>

            <Form.Item label="Material" {...formItemLayout}>
                <Input
                    style={{ minWidth: '200px' }}
                    onChange={event => {
                        setMaterials(event.target.value);
                    }}
                    value={materials}
                ></Input>
            </Form.Item>

            <Form.Item label="Notes" {...formItemLayout}>
                <TextArea
                    style={{ minWidth: '200px' }}
                    onChange={event => {
                        setNotes(event.target.value);
                    }}
                    value={notes}
                ></TextArea>
            </Form.Item>

            <Form.Item label="" {...formItemLayout}>
                <Button
                    onClick={() => {
                        records.map(payload => {
                            if (quantities?.length) {
                                payload['quantities'] = quantities;
                            }

                            if (notes) {
                                payload['notes'] = notes;
                            }

                            if (materials) {
                                payload['materials'] = materials;
                            }

                            handleChange(payload);

                            setQuantities();
                            setMaterials();
                            setNotes();

                            return null;
                        });
                    }}
                >
                    Update Selected Rows
                </Button>
            </Form.Item>
        </Row>
    );
};

export default UpdateMultipleLineItems;
