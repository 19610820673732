import React, { useEffect } from 'react';
import { Select, message } from 'antd';
import SalesOrderStore from '../../Stores/SalesOrderStore';
var AccountManager = function (props) {
    useEffect(function () {
        SalesOrderStore.getUsers({ search: 'Account Managers' });
    }, []);
    return (React.createElement(Select, { size: "small", className: "w-64", defaultValue: props.account_manager, onChange: function (value) {
            SalesOrderStore.updateAccountManager(props.sales_order_id, value)
                .then(function (response) {
                message.success('Account Manager updated successfully', 3);
            })
                .catch(function (error) {
                message.error(error.message || 'Error updating account manager');
            });
        } },
        React.createElement(Select.Option, { key: null }, "None"),
        SalesOrderStore.account_manager.map(function (user) {
            var key = "".concat(user.id);
            return (React.createElement(Select.Option, { key: key, value: user.first_name }, user.first_name));
        })));
};
export default AccountManager;
