var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, message, Modal, Table } from 'antd';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { post } from '@partsbadger/utils';
import { QuoteStore } from '../../Stores/QuoteStore';
import SortAscendingOutlined from '@ant-design/icons/lib/icons/SortAscendingOutlined';
var type = 'DraggableBodyRow';
var DraggableBodyRow = function (_a) {
    var index = _a.index, moveRow = _a.moveRow, className = _a.className, style = _a.style, restProps = __rest(_a, ["index", "moveRow", "className", "style"]);
    var ref = useRef(null);
    var _b = useDrop({
        accept: type,
        collect: function (monitor) {
            // @ts-ignore:next-line
            var dragIndex = (monitor.getItem() || {}).index;
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: function (item) {
            moveRow(item.index, index);
        },
    }), _c = _b[0], isOver = _c.isOver, dropClassName = _c.dropClassName, drop = _b[1];
    var _d = useDrag({
        type: type,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), drag = _d[1];
    drop(drag(ref));
    return (React.createElement("tr", __assign({ ref: ref, className: "".concat(className).concat(isOver ? dropClassName : ''), style: __assign({ cursor: 'move' }, style) }, restProps)));
};
var columns = [
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity'
    },
    // {
    //     title: 'Position',
    //     dataIndex: 'position'
    // }
];
var SortDrag = function (props) {
    useEffect(function () {
        setData(props.lineItems.sort(function (a, b) { return a.position - b.position; }));
    }, [props.open]);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    var moveRow = useCallback(function (dragIndex, hoverIndex) {
        var dragRow = data[dragIndex];
        setData(update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        }));
    }, [data]);
    var sortRows = function () {
        var newData = JSON.parse(JSON.stringify(data));
        newData.sort(function (a, b) { return a.quantity - b.quantity; });
        setData(newData);
    };
    return (React.createElement(Modal, { title: "Sort Line Items", open: props.open, onOk: function () {
            if (data) {
                var payload = data.map(function (item, index) {
                    return {
                        id: item.id,
                        position: index,
                    };
                });
                post("/staff/quotes/".concat(props.quoteId, "/quote-has-product-position/"), payload)
                    .then(function () {
                    QuoteStore.getLineItems(props.quoteId);
                    props.onCancel();
                })
                    .catch(function () {
                    message.error('Error, please try again');
                });
            }
        }, onCancel: function () {
            props.onCancel();
        } },
        React.createElement("div", { className: "text-right", style: { paddingBottom: '10px' } },
            React.createElement(Button, { onClick: function () {
                    sortRows();
                } },
                React.createElement(SortAscendingOutlined, { className: "" }))),
        React.createElement(DndProvider, { backend: HTML5Backend },
            React.createElement(Table, { columns: columns, rowKey: 'id', dataSource: data, components: components, onRow: function (_, index) {
                    var attr = {
                        index: index,
                        moveRow: moveRow,
                    };
                    return attr;
                }, pagination: false }))));
};
export default SortDrag;
