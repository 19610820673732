import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { IContactListStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { Col, Row, Table, Typography } from 'antd';
import { DateRender } from '@partsbadger/library';
import { observer } from 'mobx-react';
import ContactFilter from '../../components/Contact/forms/ContactFilter';
import { ContactStore } from '../../stores/ContacStore';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import { PaginationComponent } from '../../components/Inputs/Pagination';

const ContactListPage = () => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
        };
        ContactStore.getAll(params);
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Account',
            dataIndex: 'account',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.account?.name}</div>
                    </div>
                );
            },
        },

        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'Phone / Mobile',
            dataIndex: 'phone',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.phone}</div>
                        <div>{record.mobile}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Zoho',
            dataIndex: 'zoho_id',
            render: (text, r) => {
                return (
                    <div>
                        {r.zoho_id ? (
                            <a
                                style={{
                                    fontSize: 12,
                                    marginLeft: 4,
                                }}
                                target={'_blank'}
                                rel="noreferrer"
                                href={`${ZOHO_BASE_URL}/Contacts/${r.zoho_id}`}
                            >
                                View In Zoho
                            </a>
                        ) : (
                            <SendToZohoButton
                                id={r.id}
                                module={'contacts'}
                                onFinish={() => {
                                    loadData();
                                }}
                            />
                        )}
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/contacts/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];

    const data = ContactStore.contacts;
    const loading = ContactStore.isLoading;

    return (
        <div
            style={{
                maxWidth: 1800,
                margin: 'auto',
            }}
        >
            <Row>
                <Col span={24}>
                    <Typography.Title level={2}> Contacts</Typography.Title>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <ContactFilter />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Table<IContactListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        footer={() => {
                            return (
                                <PaginationComponent
                                    current={ContactStore.pagination.current}
                                    defaultPageSize={ContactStore.pagination.defaultPageSize}
                                    pageSize={ContactStore.pagination.pageSize}
                                    total={ContactStore.pagination.total}
                                />
                            );
                        }}
                    ></Table>
                </Col>
            </Row>
        </div>
    );
};

export default observer(ContactListPage);
