import React, { useEffect, useState } from 'react';
import { Card, Table, Button, notification, Form, TreeSelect, Input } from 'antd';
import moment from 'moment';
import { DateRangePicker } from '@partsbadger/library';

import { post, get } from '@partsbadger/utils';
import MaterialsSelect from './MaterialsSelect';
import CoatingsSelect from './CoatingsSelect';
import { Formulas } from '@partsbadger/quote-tool/lib/components/Select';
import { LeadTimeTracking, SettingModel, VendorTreeDataModel } from '../../../stores/types';

export interface Params {
    start_date: string;
    end_date: string;
    material: string;
    coating: string;
    formula: string;
}
let arraySelectedVendors: string[] = [];

export default function LeadTimeTrackingReport() {
    const [loading, setLoading] = useState(false);
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [data, setData] = useState<LeadTimeTracking[]>([]);
    const [settingsData, setSettingsData] = useState<SettingModel[]>([]);
    const [treeVendorData, setTreeVendorData] = useState<VendorTreeDataModel[]>([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const [form] = Form.useForm();
    const { SHOW_PARENT } = TreeSelect;

    // push into arraySelectedVendors selected vendors
    const handleChange = (values: string[], v: any, item: any) => {
        setSelectedValues(values);
        const currentValue = item.triggerValue;
        const vendor = settingsData.filter((setting: SettingModel) => setting.user.username === currentValue);
        if (vendor.length > 0) {
            if (item.checked) {
                arraySelectedVendors.push(currentValue);
            } else {
                arraySelectedVendors = arraySelectedVendors.filter(user => user !== currentValue);
            }
        } else {
            const children = findSelectedChildren(currentValue);
            if (item.checked) {
                arraySelectedVendors = arraySelectedVendors.concat(Array.from(new Set(children)));
            } else {
                children.forEach(element => {
                    arraySelectedVendors = arraySelectedVendors.filter(user => user !== element);
                });
            }
        }
    };

    function findSelectedChildren(selectedValue: string) {
        const parent = treeVendorData.find(item => item.value === selectedValue);
        if (!parent) return [];
        return parent.children ? parent.children.map(child => child.value) : [];
    }

    const tProps = {
        treeData: treeVendorData,
        value: selectedValues,
        onChange: handleChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Please select',
        loading: loadingCompanies,
    };

    useEffect(() => {
        getVendorCompanies();
    }, []);

    const getVendorCompanies = () => {
        setLoadingCompanies(true);
        get(`/staff/report-line-items-only-intake/vendor-company/`, {})
            .then((response: SettingModel[]) => {
                setSettingsData(response);
                buildTreeData(response);
            })
            .catch(error => {
                notification.error({ message: 'Error getting companies from Vendor App.', duration: 5 });
            })
            .finally(() => {
                setLoadingCompanies(false);
            });
    };

    // build tree data to load into TreeSelect component
    const buildTreeData = (data: SettingModel[]) => {
        // select distinct countries
        const countriesArray: VendorTreeDataModel[] = data
            .filter(
                (object, index, self) =>
                    index === self.findIndex(obj => obj.company.location === object.company.location)
            )
            .map(setting => {
                const item = {
                    title: setting.company.country,
                    value: setting.company.location,
                    key: setting.company.location,
                    children: [],
                };
                return item;
            });
        // get children to assign by country
        const arrayDataTree = countriesArray.map(country => {
            const users = data
                .filter((object, index, self) => object.company.location === country.key)
                .map((setting: SettingModel) => {
                    const userData = {
                        title: setting.user.username,
                        value: setting.user.username,
                        key: setting.user.username,
                    };
                    return userData;
                });
            country.children = users;
            return country;
        });
        setTreeVendorData(arrayDataTree);
    };

    const handleSubmit = (values: any) => {
        const vendors = Array.from(new Set(arraySelectedVendors));
        const companies = getCompanyNames(vendors);

        const dates = values.dates;
        const material = values?.material;
        const coating = values?.coating;
        const formula = values?.formula;
        const search = values?.search;

        const start_date = moment(dates[0]).format('YYYY-MM-DD');
        const end_date = moment(dates[1]).format('YYYY-MM-DD');
        const params = {
            start_date,
            end_date,
            material,
            coating,
            formula,
            search,
            companies,
        };
        getLeadTimeTracking(params);
    };

    const getCompanyNames = (arrayVendors: string[]) => {
        const arrayResult: string[] = [];
        arrayVendors.forEach(vendor => {
            const find = settingsData.find(setting => setting.user.username === vendor);
            if (find) {
                arrayResult.push(find.company.name);
            }
        });
        return Array.from(new Set(arrayResult));
    };

    const getLeadTimeTracking = (params: Params) => {
        setLoading(true);
        post(`/staff/report-line-items-only-intake/lead-time-tracking/`, params)
            .then((response: LeadTimeTracking[]) => {
                setData(response);
            })
            .catch(error => {
                notification.error({ message: error.message, duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns = [
        {
            title: 'Quoting LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'quoting_min',
                    key: 'quoting_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'quoting_max',
                    key: 'quoting_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'quoting_avg',
                    key: 'quoting_avg',
                },
            ],
        },
        {
            title: 'Processing LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'processing_min',
                    key: 'processing_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'processing_max',
                    key: 'processing_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'processing_avg',
                    key: 'processing_avg',
                },
            ],
        },
        {
            title: 'Observed LT',
            children: [
                {
                    title: 'Absolute Minimum',
                    dataIndex: 'observed_min',
                    key: 'observed_min',
                },
                {
                    title: 'Absolute Maximum',
                    dataIndex: 'observed_max',
                    key: 'observed_max',
                },
                {
                    title: 'Average',
                    dataIndex: 'observed_avg',
                    key: 'observed_avg',
                },
            ],
        },
    ];

    return (
        <div className={'flex flex-col'}>
            <Card title={'Quoted and Processed Lead Time Tracking'}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{
                        dates: [],
                        material: null,
                        coating: null,
                        formula: null,
                        companies: [],
                    }}
                    className="flex flex-col"
                >
                    <div className="flex lg:flex-row flex-col ml-0 w-full">
                        <Form.Item
                            label="Search"
                            name="search"
                            rules={[{ required: false, message: '' }]}
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <Input size="small" placeholder="Enter order, part or contact names" />
                        </Form.Item>

                        <Form.Item
                            label="Dates"
                            name="dates"
                            rules={[{ required: true, message: 'Required' }]}
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <DateRangePicker size="small" className="w-full" />
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: 'Required' }]}
                            name="companies"
                            label="Country"
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <TreeSelect {...tProps} />
                        </Form.Item>
                    </div>

                    <div className="flex lg:flex-row flex-col ml-0 w-full">
                        <Form.Item
                            label="Formula"
                            name="formula"
                            rules={[{ required: false }]}
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <Formulas
                                placeholder="Select"
                                item={{
                                    partVol: null,
                                    totalVol: null,
                                    has_file_3d: undefined,
                                }}
                                selectedMaterialName={null}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Material"
                            name="material"
                            rules={[{ required: false }]}
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <MaterialsSelect placeholder="Search" />
                        </Form.Item>

                        <Form.Item
                            label="Coating"
                            name="coating"
                            rules={[{ required: false }]}
                            className="lg:mx-4 mx:0 lg:w-1/3 w-full"
                        >
                            <CoatingsSelect />
                        </Form.Item>
                    </div>

                    <div className="flex lg:flex-row flex-col ml-0 w-full">
                        <Form.Item className="lg:mx-4 mx:0 lg:w-1/3 text-left w-full">
                            <Button type="primary" htmlType={'submit'} size="small" className="md:w-32 w-full">
                                Filter
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Card>

            <div className={'w-full overflow-auto'}>
                <Table
                    bordered
                    rowKey={'id'}
                    columns={columns}
                    loading={loading}
                    dataSource={data}
                    pagination={false}
                ></Table>
            </div>
        </div>
    );
}
