import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get, patch, post } from '../../shared';
import { AddressProps, NoteCreateProps, SalesOrderCard } from '@partsbadger/library';
import { hasPermission } from 'quotes-frontend/src/components/User';
import { Spin } from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { observer } from 'mobx-react';

export const OrderDetail = observer(() => {
    let sales_order_id = '';
    ({ sales_order_id } = useParams());
    const [loading, setLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<SalesOrderProps | null>(null);
    const [notes_order, setNotesOrder] = useState([]);

    const getOrderNotes = () => {
        get(`staff/sales-orders/${sales_order_id}/notes`).then((response: any) => {
            const list = response.data;
            list.sort((a: { id: number }, b: { id: number }) => (a.id < b.id ? 1 : -1));
            setNotesOrder(list);
        });
    };

    const getOrder = () => {
        get(`staff/sales-orders/${sales_order_id}/`)
            .then((response: any) => {
                const sales_order: SalesOrderProps = response.data;
                setOrder(sales_order);
                SalesOrderStore.getContactById(sales_order.contact.id);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        getOrder();
        getOrderNotes();
    }, [sales_order_id]);

    const createNote = (payload: NoteCreateProps) => {
        post(`staff/sales-orders/${sales_order_id}/notes/`, payload).then((response: any) => {
            getOrderNotes();
        });
    };

    const editOrder = (payload: SalesOrderProps) => {
        patch(`staff/sales-orders/${sales_order_id}/`, payload).then((response: any) => {
            getOrder();
        });
    };

    return (
        <>
            {loading && (
                <div
                    style={{
                        margin: '20px 0',
                        marginBottom: '20px',
                        padding: '30px 50px',
                        textAlign: 'center',
                    }}
                >
                    <Spin tip={'Loading Sales Order...'} />
                </div>
            )}

            {order && (
                <SalesOrderCard
                    sales_order={order}
                    sales_order_notes={notes_order}
                    createNote={createNote}
                    editOrder={editOrder}
                    permission={hasPermission('salesorders.change_salesorder')}
                    addresses={SalesOrderStore.addresses!}
                    handleSaveAddress={(payload: AddressProps) => {
                        return SalesOrderStore.saveAddress(order.contact.id, payload).then(() => {
                            SalesOrderStore.getContactById(order.contact.id);
                        });
                    }}
                />
            )}
        </>
    );
});
