import * as ActionTypes from '../ActionTypes';

export const MasterProduct = (
    state = {
        isLoading: false,
        isAddingStepFile: false,
        isAddingStepDrawingFile: false,
        error: null,
        entity: {},
        entities: [],
        byQuote: [],
        duplicateMasterProduct: false,
        showExistingMasterProduct: false,
    },
    action) => {

    switch (action.type) {

        case ActionTypes.MASTER_PRODUCTS_LOADING:
            return {...state, isLoading: true}

        case ActionTypes.MASTER_PRODUCTS_LOADED:
            return {...state, isLoading: false, entities: action.payload};

        case ActionTypes.MASTER_PRODUCTS_BY_QUOTE_LOADING:
            return {...state, isLoading: true}

        case ActionTypes.MASTER_PRODUCTS_BY_QUOTE_LOADED:
            return {...state, isLoading: false, byQuote: action.payload};

        case ActionTypes.MASTER_PRODUCTS_BY_QUOTE_NEW:
            return {...state, isLoading: false, byQuote: [...state.byQuote, action.payload]};


        case ActionTypes.MASTER_PRODUCTS_BY_QUOTE_UPDATED:
            return {
                ...state,
                isLoading: false,
                byQuote: state.byQuote.filter(r => {
                    return r.master_product.id === action.payload.master_product.id ? action.payload : r
                })
            };

        case ActionTypes.MASTER_PRODUCT_LOADED:
            return {...state, isLoading: false, entity: action.payload, error: null};

        case ActionTypes.MASTER_PRODUCTS_DELETE:
            return {
                ...state,
                isLoading: false,
                byQuote: state.byQuote.filter(r => r.master_product.id !== action.id),
                error: null
            };

        case ActionTypes.MASTER_PRODUCTS_FAILED:
            return {...state, isLoading: false, error: action.error}

        case ActionTypes.MASTER_PRODUCTS_ADDING_STEP_FILE:
            return {...state, isAddingStepFile: true}

        case ActionTypes.MASTER_PRODUCTS_ADDED_STEP_FILE:
            return {...state, isAddingStepFile: false}

        case ActionTypes.MASTER_PRODUCTS_ERROR_ADDING_STEP_FILE:
            return {...state, isAddingStepFile: false}

        case ActionTypes.MASTER_PRODUCTS_ADDING_DRAWING_FILE:
            return {...state, isAddingStepDrawingFile: true}

        case ActionTypes.MASTER_PRODUCTS_ADDED_DRAWING_FILE:
            let entity = state.entity
            entity.drawing_file = action.payload
            return {...state, isAddingStepDrawingFile: false}

        case ActionTypes.MASTER_PRODUCTS_ERROR_ADDING_DRAWING_FILE:
            return {...state, isAddingStepDrawingFile: false}


        case ActionTypes.MASTER_PRODUCT_ERROR:
            return {...state, error: action.error, isLoading: false};


        default:
            return state;
    }
};
