import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Table, notification, Input } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useQueryParameters } from '@partsbadger/utils';

import { get } from '../../shared';
import { LineItemsOnlyIntakeProps } from '@partsbadger/sales_order_tool/lib/types/types';

const { Search } = Input;
const FormItem = Form.Item;

const LineItemsOnlyIntakeReport = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const { form } = props;
    const [loading, setLoading] = useState(false);
    const [dataLineItemsIntake, setDataLineItemsIntake] = useState<Array<LineItemsOnlyIntakeProps>>([]);
    const [stateCurrentPage, setStateCurrentPage] = useState(1);
    const [stateTotalData, setStateTotalData] = useState(0);
    const [statePageSize, setStatePageSize] = useState(10);
    const [searchParam, setSearchParam] = useState('');

    useEffect(() => {
        getLineItemsOnlyIntake();
    }, []);

    const getLineItemsOnlyIntake = (params = {}) => {
        setLoading(true);
        get(`staff/report-line-items-only-intake/`, params).then((response: any) => {
            try {
                setDataLineItemsIntake(response.data.results);
                setStateTotalData(response.data.count);
                setLoading(false);
            } catch (erro: any) {
                notification.error({ message: erro.message });
            } finally {
                () => setLoading(false);
            }
        });
    };

    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Sales Order',
            render: (_: string, record: LineItemsOnlyIntakeProps) => {
                return record.sales_order.name;
            },
        },
        {
            title: 'Req. Ship Date',
            render: (_: string, record: LineItemsOnlyIntakeProps) => {
                return record.sales_order.required_ship_date;
            },
        },
        {
            title: 'Hot Ship Date',
            render: (_: string, record: LineItemsOnlyIntakeProps) => {
                return record.sales_order.hot_ship_date;
            },
        },
        {
            title: 'Shipping Method',
            render: (_: string, record: LineItemsOnlyIntakeProps) => {
                return record.sales_order.shipping_method;
            },
        },
    ];

    const handleSubmit = (e: any) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                getLineItemsOnlyIntake(values);
            }
        });
    };
    const { getFieldDecorator } = form;

    return (
        <>
            <div className={'row'}>
                <Form onSubmit={handleSubmit} className="login-form">
                    <FormItem label="">
                        {getFieldDecorator('search', {
                            initialValue: query.get('search'),
                        })(
                            <Search
                                placeholder="Search"
                                style={{ width: '100%' }}
                                allowClear
                                size={'small'}
                                onSearch={search => {
                                    getLineItemsOnlyIntake({ search });
                                }}
                            />
                        )}
                    </FormItem>
                </Form>
            </div>
            <Card title={'Line Items Only Intake'}>
                <div className={'w-full overflow-auto'}>
                    <Table
                        rowKey={'id'}
                        columns={columns}
                        loading={loading}
                        dataSource={dataLineItemsIntake}
                        pagination={{
                            defaultPageSize: statePageSize,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50'],
                            position: 'bottom',
                            onChange(currentPage, pageSize) {
                                pageSize = pageSize || statePageSize;
                                setStatePageSize(pageSize);
                                setStateCurrentPage(currentPage);
                                getLineItemsOnlyIntake({
                                    page: currentPage,
                                    page_size: pageSize,
                                    ...form.getFieldsValue(),
                                });
                            },
                            onShowSizeChange(currentPage, pageSize) {
                                pageSize = pageSize || statePageSize;
                                setStatePageSize(pageSize);
                                setStateCurrentPage(currentPage);
                                getLineItemsOnlyIntake({
                                    page: currentPage,
                                    page_size: pageSize,
                                    ...form.getFieldsValue(),
                                });
                            },
                            showTotal: () => `Total ${stateTotalData}`,
                            total: stateTotalData,
                            current: stateCurrentPage,
                        }}
                    ></Table>
                </div>
            </Card>
        </>
    );
};
export const LineItemsOnlyIntakeReportForm = Form.create()(LineItemsOnlyIntakeReport);
