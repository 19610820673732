import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { get } from '@partsbadger/utils';

import { Table, Tabs, DatePicker, Spin, Col, Row } from 'antd';

const { TabPane } = Tabs;
const { MonthPicker } = DatePicker;

function intComma(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export interface ISalesTicker {
    year: Month[];
    month: Month[];
    month_total: number;
    year_total: number;
}

export interface Month {
    rep: string;
    sos: string[];
    leads?: number;
    gt_sum: number;
}

interface State {
    loading: boolean;

    sales_ticker: {
        year: number;
        month: number;
        reps: ISalesTicker | null;
        repsYear: [] | null;
    };
    quotes_ticker: {
        year: number;
        month: number;
        authors: Array<string> | null;
        authorsYear: [] | null;
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

class SalesTickers extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        const today = new Date();

        let month = 0;
        let year = 0;

        if (today.getMonth() === 11) {
            month = 12;
            year = today.getFullYear();
        } else {
            month = today.getMonth() + 1;
            year = today.getFullYear();
        }

        this.state = {
            sales_ticker: {
                // for monthly
                year: year,
                month: month,

                reps: null,
                repsYear: null,
            },

            quotes_ticker: {
                // for monthly
                year: year,
                month: month,

                authors: null,
                authorsYear: null,
            },
            loading: false,
        };
    }

    componentDidMount() {
        // monthly sales ticker
        this.setState({ loading: true });

        const { year, month } = this.state.sales_ticker;

        if (year && month) {
            get(`/staff/reports/ticker/${year}/${month}/`).then((data: ISalesTicker) => {
                this.setState({
                    sales_ticker: {
                        ...this.state.sales_ticker,
                        reps: data,
                    },
                    loading: false,
                });
            });
        }
    }

    selectMonth = (d: any, dS: any) => {
        const dsSplit = dS.split('-');
        this.setState({ loading: true });

        get(`/staff/reports/ticker/${dsSplit[0]}/${dsSplit[1]}/`).then((data: ISalesTicker) => {
            this.setState({
                sales_ticker: {
                    ...this.state.sales_ticker,
                    reps: data,
                },
                loading: false,
            });
        });
    };

    render() {
        return (
            <div
                style={{
                    padding: '1rem',
                    maxWidth: '95vw',
                    margin: 'auto',
                }}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Sales Ticker" key="1">
                        <MonthPicker
                            style={{ display: 'block', maxWidth: '350px', margin: 'auto' }}
                            placeholder="Select Year/Month"
                            onChange={this.selectMonth}
                        />

                        <br />
                        <br />

                        <div className="flex flex-row ">
                            <div style={{ margin: '0 2.5%', width: '45%' }}>
                                <div className="flex flex-row justify-between">
                                    <div className={'font-bold text-2xl'}>Month</div>
                                    <div className={'font-bold text-2xl'}>
                                        ${' '}
                                        {this.state.sales_ticker.reps &&
                                            intComma(this.state.sales_ticker.reps.month_total.toFixed(2))}
                                    </div>
                                </div>
                                <hr />
                                <Spin spinning={this.state.loading}>
                                    {this.state.sales_ticker.reps && (
                                        <Table
                                            pagination={false}
                                            rowKey="rep"
                                            columns={[
                                                {
                                                    title: 'Rep',
                                                    dataIndex: 'rep',
                                                },
                                                // {
                                                //     title: 'Leads Generated',
                                                //     dataIndex: 'leads',
                                                // },
                                                {
                                                    title: '# Sales',
                                                    key: 'sos.0.name',
                                                    dataIndex: 'sos.length',
                                                    render: (text, record) => {
                                                        return record.sos.length;
                                                    },
                                                },
                                                {
                                                    title: 'Revenue',
                                                    key: 'sos.1.name',
                                                    dataIndex: 'gt_sum',
                                                    defaultSortOrder: 'descend',
                                                    sorter: (a, b) => a.gt_sum - b.gt_sum,
                                                    render: (text, record, somethingElse) => intComma(text),
                                                },
                                            ]}
                                            expandedRowRender={record => (
                                                <Row style={{ justifyContent: 'space-around' }}>
                                                    {record.sos.map(so => (
                                                        <Col md={6} key={so}>
                                                            {so}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            )}
                                            dataSource={this.state.sales_ticker.reps.month}
                                        />
                                    )}
                                </Spin>
                            </div>

                            <div style={{ margin: '0 2.5%', width: '45%' }}>
                                <div className="flex flex-row justify-between">
                                    <div className={'font-bold text-2xl'}>Year</div>
                                    <div className={'font-bold text-2xl'}>
                                        ${' '}
                                        {this.state.sales_ticker.reps &&
                                            intComma(this.state.sales_ticker.reps.year_total.toFixed(2))}
                                    </div>
                                </div>
                                <hr />
                                <Spin spinning={this.state.loading}>
                                    {this.state.sales_ticker.reps && (
                                        <Table
                                            pagination={false}
                                            rowKey="rep"
                                            columns={[
                                                {
                                                    title: 'Rep',
                                                    dataIndex: 'rep',
                                                },
                                                {
                                                    title: '# Sales',
                                                    key: 'sos.0.name',
                                                    dataIndex: 'sos.length',
                                                    render: (text, record, somethingElse) => {
                                                        return record.sos.length;
                                                    },
                                                },
                                                {
                                                    title: 'Revenue',
                                                    key: 'sos.1.name',
                                                    dataIndex: 'gt_sum',
                                                    render: (text, record, somethingElse) => intComma(text),
                                                    defaultSortOrder: 'descend',
                                                    sorter: (a, b) => a.gt_sum - b.gt_sum,
                                                },
                                            ]}
                                            expandedRowRender={record => (
                                                <Row style={{ justifyContent: 'space-around' }}>
                                                    {record.sos.map(so => (
                                                        <Col md={6} key={so}>
                                                            {so}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            )}
                                            dataSource={[...this.state.sales_ticker.reps.year]}
                                        />
                                    )}
                                </Spin>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default observer(SalesTickers);
