var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select, Spin } from 'antd';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROOT as BASE_URL } from '@partsbadger/utils';
var busy = null;
var Deals = function (props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), entities = _b[0], setEntities = _b[1];
    var token = localStorage.getItem('token');
    var fetchData = function (params) {
        if ('account' in props) {
            params['account'] = props.account;
        }
        if ('filters' in props) {
            params = __assign(__assign({}, params), props.filters);
        }
        axios
            .get("".concat(BASE_URL, "/staff/deals/"), {
            headers: {
                Authorization: "Token ".concat(token),
            },
            params: params,
        })
            .then(function (response) {
            setEntities(response.data.results);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    useEffect(function () {
        if (props.account) {
            fetchData({
                account: props.account,
            });
        }
        else {
            fetchData({});
        }
    }, [props.account]);
    return (React.createElement(Select, __assign({ showSearch: true, allowClear: true, labelInValue: true, filterOption: false, size: 'small', style: { width: '100%' }, dropdownMatchSelectWidth: false, placeholder: "Search Deal", notFoundContent: loading ? React.createElement(Spin, { size: "small" }) : 'No results found', onSearch: function (name) {
            if (busy) {
                clearTimeout(busy);
            }
            var params = {
                search: name,
            };
            setLoading(true);
            busy = setTimeout(function () { return fetchData(params); }, 400);
        } }, props), entities.map(function (deal) { return (React.createElement(Select.Option, { key: deal.id },
        deal.name,
        ' ',
        (props === null || props === void 0 ? void 0 : props.show_in_zoho_alert) && !(deal === null || deal === void 0 ? void 0 : deal.zoho_id) && React.createElement("span", { style: { color: 'red' } }, " Not In Zoho"))); })));
};
export default Deals;
