import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Card, Divider, Modal, notification, Select, Spin } from 'antd';
import { AutoCompleteRecord, ContactProps, Contacts, ErrorRender } from '@partsbadger/library';

import { get, post } from '@partsbadger/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LabeledValue, SelectValue } from 'antd/es/select';

export interface RelatedFile {
    id: number;
    file: string;
    filename: string;
}

const MergeContacts = () => {
    const [loading, setLoading] = useState(false);
    const [allContacts, setAllContacts] = useState<ContactProps[]>([]);
    const [contacts, setContacts] = useState<ContactProps[]>([]);

    const [selectedOldContacts, setSelectedOldContacts] = useState<LabeledValue[]>([]);
    const [selectedNewContact, setSelectedNewContact] = useState<LabeledValue | null>(null);

    const getAllContacts = async (search: string) => {
        await get(`/staff/contacts/all-contacts/`, {
            params: {
                search: search,
            },
        })
            .then(data => {
                setAllContacts(data);
            })
            .catch(err => {
                notification.error({ message: 'Error', description: <ErrorRender error={err} /> });
            });
    };

    const getContacts = async (search: string) => {
        await get(`/staff/contacts/`, {
            params: {
                search: search,
            },
        })
            .then(data => {
                setContacts(data.results);
            })
            .catch(err => {
                notification.error({ message: 'Error', description: <ErrorRender error={err} /> });
            });
    };

    const confirm = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Do you want to move all Quotes, RFQs, Deals, Sales Orders, Invoices to ${selectedNewContact?.label}?`,
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                migrateContacts();
            },
        });
    };

    const migrateContacts = () => {
        setLoading(true);
        post(`/staff/contacts/${selectedNewContact?.key}/migrate-contacts/`, {
            old_contacts: selectedOldContacts.map(c => c.key),
        })
            .then(data => {
                notification.success({ message: 'Success' });
            })
            .catch(err => {
                notification.error({ message: 'Error' });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card style={{ maxWidth: '80vw', margin: 'auto' }}>
            <Spin spinning={loading}>
                <Divider>
                    <div className={'title'}> Merge Contacts</div>
                </Divider>

                <Alert
                    type="error"
                    message={'Warning: The migration should be done first in zoho and then in this tool! '}
                />

                <div style={{ width: '77vw' }}>
                    <Form>
                        <Form.Item help={'This select only returns deleted items'}>
                            Select all deleted contacts to be replaced
                            <AutoCompleteRecord
                                placeholder={'Search a deleted contact in zoho'}
                                mode="multiple"
                                handleFetch={async search => {
                                    await getAllContacts(search);
                                }}
                                onChange={(values: any) => {
                                    setSelectedOldContacts(values);
                                }}
                                records={allContacts
                                    .filter(c => c.deleted)
                                    .map(c => {
                                        return {
                                            id: c.id,
                                            label: `${c.first_name} ${c.last_name} - ${c.email} (${c.zoho_id})`,
                                        };
                                    })}
                            />
                        </Form.Item>
                        <Form.Item>
                            Replace with:
                            <AutoCompleteRecord
                                handleFetch={async search => {
                                    await getContacts(search);
                                }}
                                onSelect={(values: any) => {
                                    setSelectedNewContact(values);
                                }}
                                records={contacts.map(c => {
                                    return {
                                        id: c.id,
                                        label: `${c.first_name} ${c.last_name} - ${c.email} (${c.zoho_id})`,
                                    };
                                })}
                            />
                        </Form.Item>
                        <br></br>
                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!selectedNewContact?.key || selectedOldContacts.length == 0}
                                onClick={() => {
                                    confirm();
                                }}
                                style={{ float: 'right' }}
                            >
                                Merge Contacts
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Card>
    );
};

export default MergeContacts;
