import React from 'react';
import { notification, PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';
import { PurchaseOrder } from '@partsbadger/purchase_order_tool';
import { getCurrentUser } from '../../components/User';

const PurchaseOrderCreate = () => {
    const history = useHistory();
    const current_user: any = getCurrentUser()?.data;
    return (
        <div style={{ margin: 'auto' }}>
            <PageHeader title={'Create Purchase Order'} onBack={() => history.push('/purchase-orders/')} />

            <PurchaseOrder
                current_user={current_user}
                onSubmitted={() => {
                    // location.replace('/purchase-orders/');
                    notification.success({
                        message: 'The purchase order was created',
                    });
                    history.push('/purchase-orders/');
                }}
                onAccountError={() => {
                    notification.error({
                        message: 'Line items need an account',
                    });
                }}
            />

            <br />
            <p style={{ textAlign: 'center' }}>Need Help? Call (331) BADGER-8</p>
        </div>
    );
};

export default PurchaseOrderCreate;
