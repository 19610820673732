import React from 'react';
import { observer } from 'mobx-react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, InputNumber } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ShippingMethodsPurchasesOrders } from '@partsbadger/library';
var ShipmentForm = observer(function (props) {
    var _a;
    var _b = React.useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = props.form, getFieldDecorator = _c.getFieldDecorator, getFieldValue = _c.getFieldValue, setFieldsValue = _c.setFieldsValue;
    var box = props.box;
    var handleSubmit = function (e) {
        e.preventDefault();
        props.form.validateFieldsAndScroll(function (err, values) {
            if (!err) {
                var payload = {
                    notes: values.notes,
                    internal_notes: values.internal_notes,
                    shipping_cost: values._shipping_cost,
                    late_shipping_cost: values.late_shipping_cost,
                    tracking_number: values._tracking_number,
                    bill_to_customer: values.bill_to_customer,
                    boxes_included: values.boxes_included,
                    shipping_method: values.shipping_method,
                    resendNotification: values.resendNotification,
                };
                setIsLoading(true);
                props.handleSave(payload).finally(function () { return setIsLoading(false); });
            }
        });
    };
    return (React.createElement(Form, { className: 'box-form' },
        React.createElement("div", { className: "flex flex-row" },
            React.createElement(Form.Item, { label: 'Carrier' },
                React.createElement("div", null, box.carrier)),
            React.createElement(Form.Item, { label: "Shipping Method" }, getFieldDecorator('shipping_method', {
                initialValue: box.shipping_method,
            })(React.createElement(ShippingMethodsPurchasesOrders, null))),
            React.createElement(Form.Item, { label: "Shipping Account Number" },
                React.createElement("div", null, box.shipping_account))),
        box.packages.length > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'flex flex-col md:flex-row w-full justify-around mt-2' },
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Length (IN)"),
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Width (IN)"),
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Height (IN)"),
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Weight (LB)"),
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Tracking Number"),
                React.createElement("div", { className: "w-32 text-center font-bold" }, "Shipping Cost"),
                React.createElement("div", { className: "w-32 text-center font-bold" })),
            box.packages.map(function (p, index) {
                return (React.createElement("div", { key: index, className: 'flex flex-col md:flex-row w-full justify-around' },
                    React.createElement("div", { className: "w-32 text-center" },
                        p.package_length,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" },
                        p.package_width,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" },
                        p.package_height,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" },
                        p.package_weight,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" },
                        p.tracking_number,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" },
                        p.shipping_cost,
                        " "),
                    React.createElement("div", { className: "w-32 text-center" }, p.label.file ? (React.createElement("a", { href: p.label.file, target: "_blank", rel: "noreferrer" },
                        React.createElement(CheckOutlined, null))) : (React.createElement(CloseOutlined, null)))));
            }))),
        React.createElement("div", { className: 'flex flex-row mt-2' },
            React.createElement(Form.Item, { label: "Shipping Cost ($)" },
                getFieldDecorator('_shipping_cost', {
                    initialValue: box.shipping_cost,
                })(React.createElement(InputNumber, { min: 0, step: 0.01, style: { width: 250 } })),
                props.box.carrier_shipping_cost ? (React.createElement("div", null,
                    "Carrier Shipping Cost ", (_a = props === null || props === void 0 ? void 0 : props.box) === null || _a === void 0 ? void 0 :
                    _a.carrier_shipping_cost)) : null),
            React.createElement(Form.Item, { label: "Master Tracking Number" }, getFieldDecorator('_tracking_number', {
                initialValue: box.tracking_number,
            })(box.is_api_shipment ? React.createElement("div", null, box.tracking_number) : React.createElement(Input, { style: { width: 250 } }))),
            React.createElement(Form.Item, { label: "Boxes included" }, getFieldDecorator('boxes_included', {
                rules: [{ required: true }],
                initialValue: box.boxes_included != null ? box.boxes_included : 1,
            })(React.createElement(Input, { style: { width: 250 } })))),
        React.createElement("div", null,
            React.createElement(Form.Item, { label: "Customer Shipping Notes" }, getFieldDecorator('notes', {
                initialValue: box.notes,
            })(React.createElement(Input.TextArea, null))),
            React.createElement(Form.Item, { label: "Internal Shipping Notes" }, getFieldDecorator('internal_notes', {
                initialValue: box.internal_notes,
            })(React.createElement(Input.TextArea, null)))),
        props.outbound_destination !== 'Z' && (React.createElement("div", { className: "flex flex-row" },
            React.createElement(Form.Item, { label: 'Bill to Customer?' }, getFieldDecorator('bill_to_customer', {
                valuePropName: 'checked',
                initialValue: box.bill_to_customer,
            })(React.createElement(Checkbox, null))),
            React.createElement(Form.Item, { label: "LATE Shipping Cost" }, getFieldDecorator('late_shipping_cost', {
                initialValue: box.late_shipping_cost,
            })(React.createElement(InputNumber, { style: { width: '95%' } }))))),
        props.box.id && props.outbound_destination !== 'Z' && (React.createElement("div", { className: " flex flex-row justify-center mt-4" },
            React.createElement(Form.Item, null, getFieldDecorator('resendNotification', {
                valuePropName: 'checked',
                initialValue: false,
            })(React.createElement(Checkbox, null, "Resend Shipment Notification?"))))),
        React.createElement("div", { className: "flex flex-col items-center justify-center mt-10" },
            React.createElement(Button, { type: "primary", loading: isLoading, onClick: handleSubmit }, props.box.id ? 'Update' : 'Create'))));
});
export default Form.create()(ShipmentForm);
