var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Select } from 'antd';
import React from 'react';
export var ManufacturingTypes = function (props) {
    return (React.createElement(Select, __assign({ showSearch: true, dropdownMatchSelectWidth: false, optionFilterProp: "children", size: 'small', filterOption: function (input, option) {
            //@ts-ignore
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        } }, props),
        React.createElement(Select.Option, { value: "CNC" }, " CNC "),
        React.createElement(Select.Option, { value: "3D PRINTING" }, " 3D "),
        React.createElement(Select.Option, { value: "Sheet Metal" }, " Sheet Metal ")));
};
