import React from 'react';
import { observer } from 'mobx-react';
import { Card, Tag, Button } from 'antd';
import IntakeStore from '../Stores/IntakeStore';
var SalesOrderData = function () {
    if (IntakeStore.salesOrder === null)
        return null;
    return (React.createElement("div", { className: "flex flex-col mr-4" },
        React.createElement("div", { className: "flex flex-col p-4 border-2 border-solid border-gray-300" },
            React.createElement("h1", null, IntakeStore.salesOrder.name),
            IntakeStore.domesticFinish && (React.createElement(Tag, { color: "orange", className: "text-center mb-2" }, "Domestic Finish")),
            IntakeStore.hardwareNeeded && (React.createElement(Tag, { color: "volcano", className: "text-center" }, "Hardware Needed"))),
        React.createElement(Card, { title: IntakeStore.salesOrder.subject, style: { borderTopLeftRadius: 0, borderTopRightRadius: 0 } },
            React.createElement("p", { className: "ml-2" },
                "- ",
                IntakeStore.salesOrder.status,
                React.createElement("br", null),
                "- Req Partner Ship Date ",
                IntakeStore.salesOrder.partner_ship_date,
                React.createElement("br", null),
                "- ",
                IntakeStore.salesOrder.intake_status,
                React.createElement("br", null),
                "- ",
                IntakeStore.salesOrder.shipping_status,
                React.createElement("br", null),
                "- Next Batch Release Date:",
                ' ',
                IntakeStore.pdSalesOrder !== null && IntakeStore.pdSalesOrder.revised_ship_date !== null
                    ? IntakeStore.pdSalesOrder.revised_ship_date
                    : ''),
            React.createElement("div", null, IntakeStore.salesOrderDocuments !== null ? React.createElement(CustomerPoFiles, null) : React.createElement("div", null)),
            React.createElement("div", null,
                React.createElement(PrintIventory, null)))));
};
var CustomerPoFiles = observer(function () {
    var _a;
    var wsettings = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes';
    var poFiles = (_a = IntakeStore.salesOrderDocuments) === null || _a === void 0 ? void 0 : _a.map(function (pofile) { return (React.createElement("li", { key: pofile.id },
        React.createElement("a", { onClick: function () {
                window.open(pofile.file, pofile.filename, wsettings);
            } }, pofile.filename))); });
    return (React.createElement("div", null,
        React.createElement("h1", null, "Customer Po Files"),
        React.createElement("ul", null, poFiles)));
});
var PrintIventory = function () {
    var soHasLineItemsWithStock = function () {
        if (IntakeStore.salesOrder && IntakeStore.salesOrder.line_items) {
            var liWithStock = IntakeStore.salesOrder.line_items.filter(function (item) { return item.stock > 0; });
            if (liWithStock.length > 0 && liWithStock !== undefined) {
                return false;
            }
        }
        return true;
    };
    return (React.createElement(Button, { type: "primary", disabled: soHasLineItemsWithStock(), onClick: function () { return IntakeStore.printIventory(IntakeStore.salesOrder.id); } }, "Print Inventory"));
};
export default observer(SalesOrderData);
