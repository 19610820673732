import React, { useState } from 'react';
import { Popover, Tag, Button } from 'antd';
import TicketModal from './TicketHistory/TicketModal';
var Property = function (props) {
    var label = props.label, value = props.value;
    return (React.createElement("div", { className: 'flex flex-row' },
        React.createElement("div", { className: 'font-bold' },
            label,
            ":"),
        React.createElement("div", { className: 'font' }, value)));
};
var Part = function (props) {
    var _a = useState(false), isVisibleTicketModal = _a[0], setIsVisibleTicketModal = _a[1];
    var _b = props.part, name = _b.name, dimensions = _b.dimensions, material = _b.material, finish = _b.finish, coating = _b.coating, customer_notes = _b.customer_notes, first_article_approval = _b.first_article_approval, is_batch_shipment = _b.is_batch_shipment, previously_exists = _b.previously_exists, domestic_quoting = _b.domestic_quoting;
    var handleCloseTicketModal = function () {
        setIsVisibleTicketModal(false);
    };
    return (React.createElement("div", { className: 'flex flex-col itemsCenter' },
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: "font-bold" }, name),
            React.createElement("div", { className: "italic text-xs" }, dimensions),
            customer_notes && (React.createElement("div", { className: "italic text-xs xl:ml-2" },
                React.createElement(Popover, { overlayStyle: { maxWidth: '500px' }, content: customer_notes },
                    React.createElement("a", null, " (Notes) ")),
                ' '))),
        React.createElement("div", { className: 'flex flex flex-col' },
            material && React.createElement(Property, { label: "Material", value: material }),
            React.createElement("div", { className: 'flex  flex-col' },
                coating && (React.createElement("div", null,
                    React.createElement(Property, { label: "Coating", value: coating }))),
                finish && (React.createElement("div", null,
                    React.createElement(Property, { label: "Finish", value: finish })))),
            first_article_approval !== undefined && (React.createElement("div", { className: 'flex flex-row' }, first_article_approval && React.createElement(Tag, { color: "green" }, "First Article Approval"))),
            is_batch_shipment !== undefined && (React.createElement("div", { className: 'flex flex-row' }, is_batch_shipment && React.createElement(Tag, { color: "blue" }, "Batch Shipment"))),
            previously_exists !== undefined && (React.createElement("div", { className: "flex flex-col bg-orange-300 items-center border-2 w-40 border-orange-400 rounded-md" },
                React.createElement("span", null, "Previously Exists"),
                previously_exists.ticket_products.length > 0 && (React.createElement(Button, { type: "link", size: "small", onClick: function () {
                        setIsVisibleTicketModal(true);
                    } }, "View Tickets History")))),
            domestic_quoting && (React.createElement("div", { className: 'flex flex-row mt-1' },
                React.createElement(Tag, { color: "green" }, "Domestic Quoting"))),
            isVisibleTicketModal && (React.createElement(TicketModal, { handleCloseTicketModal: handleCloseTicketModal, product: previously_exists })))));
};
export default Part;
