var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Select } from 'antd';
import { QuoteStore } from '../../Stores';
import { SAFE_MATERIALS } from '../Select/Materials';
import { observer } from 'mobx-react';
import { get } from '@partsbadger/utils';
var Option = Select.Option;
var Formulas = function (props) {
    var user = QuoteStore.currentUser;
    var _a = React.useState([]), formulas = _a[0], setFormulas = _a[1];
    var isATesterUser = user === null || user === void 0 ? void 0 : user.is_tester;
    var formula26Safe = false;
    if (props.item.totalVol && props.item.partVol) {
        formula26Safe =
            props.item.totalVol < 90 &&
                props.item.partVol < 30 &&
                !!props.selectedMaterialName &&
                SAFE_MATERIALS.includes(props.selectedMaterialName);
    }
    React.useEffect(function () {
        get('/formulas/').then(function (data) {
            setFormulas(data);
        });
    }, []);
    return (React.createElement(Select, __assign({ allowClear: true, optionFilterProp: "children", dropdownMatchSelectWidth: false, style: { width: '100%' }, size: "small" }, props), formulas
        .filter(function (f) {
        var _a;
        //  Check if is safe to use formula 2.6
        if (f.version == '2.6') {
            if (!formula26Safe) {
                return false;
            }
        }
        // Exclude formula 4 if analysis_status_v2 is not 'completed'
        if (parseFloat(f.version) >= 4.0 && ((_a = props.item.analysis_status_v2) !== null && _a !== void 0 ? _a : '') !== 'completed') {
            return false;
        }
        return true;
    })
        .filter(function (f) {
        // Remove testing formulas if the current user is not a tester
        if (!isATesterUser) {
            return !f.is_testing;
        }
        return true;
    })
        .map(function (f) {
        return (React.createElement(Option, { key: f.id, value: f.version },
            f.version,
            f.version == '2.6' && formula26Safe && props.item.has_file_3d && (React.createElement("span", { className: 'dropdown-item-helper', style: { color: 'green' } }, "\u00A0\u2713"))));
    })));
};
export default observer(Formulas);
