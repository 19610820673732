import React from 'react';
import { message, notification, Spin, Steps } from 'antd';
import { observer } from 'mobx-react';
import SalesOrderStore from '../Stores/SalesOrderStore';
var SalesOrderStatus = function (_a) {
    var sales_order = _a.sales_order, user = _a.user, handleSubmit = _a.handleSubmit, loading = _a.loading;
    var changeStage = function (stage) {
        var _a;
        var payload = {
            status: stage,
        };
        if (stage === 'Completed' && !((_a = sales_order.design_engineer) === null || _a === void 0 ? void 0 : _a.id)) {
            var res = window.confirm("Set ".concat(user.email, " as the Design Engineer?"));
            if (res) {
                payload['design_engineer'] = user.id;
                SalesOrderStore.update(sales_order.id, payload).then(function () {
                    return notification.success({ message: 'Updated sales order status.' });
                });
            }
            else {
                message.warning('Please set the design Engineer before complete the sales order', 8);
            }
        }
        else {
            SalesOrderStore.update(sales_order.id, payload).then(function () {
                return notification.success({ message: 'Updated sales order status.', placement: 'bottomRight', duration: 3 });
            });
        }
    };
    var getStage = function () {
        var stage = sales_order.status;
        var stages_in_zoho = [
            'Ready for Production',
            'Pending',
            'Hold',
            'Production - Accepted',
            'Received in Full',
            'Received in Full - Domestic Finish',
            'Received in Partial',
            'Received in Partial - Domestic Finish',
            'Shipped in Partial',
            'Shipped in Full',
            'Shipped & Paid',
            'Cancelled',
            'Cancelled - Parts Intaken',
            'Completed',
        ];
        var stages = [
            'In Progress',
            'Needs Review',
            'Waiting on Customer',
            'Waiting on 3d File',
            'Big Order Under Production Review',
            'Big Order Under Point Person Review',
            'Big Order Approved',
        ];
        if (stage === 'Cancelled') {
            return 5;
        }
        if (stages_in_zoho.indexOf(stage) >= 0) {
            return SalesOrderStore.isBigOrder() ? 7 : 4;
        }
        else {
            return stages.indexOf(stage);
        }
    };
    var showStatusComplete = function () {
        if (SalesOrderStore.isBigOrder() && getStage() === 7)
            return true;
        if (getStage() === 4 && !SalesOrderStore.isBigOrder())
            return true;
        else
            return false;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Spin, { tip: "Saving changes...", spinning: loading },
            React.createElement(Steps, { current: getStage() },
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "In Progress", description: "", onClick: function () {
                        changeStage('In Progress');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Needs Review", description: "", onClick: function () {
                        changeStage('Needs Review');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Waiting on Customer", description: "", onClick: function () {
                        changeStage('Waiting on Customer');
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Waiting on 3d File", description: "", onClick: function () {
                        changeStage('Waiting on 3d File');
                    } }),
                SalesOrderStore.isBigOrder() && (React.createElement(Steps.Step, { className: 'cursor-pointer', title: React.createElement("span", null,
                        "Big Order Under ",
                        React.createElement("br", null),
                        "Production Review"), description: "", onClick: function () {
                        changeStage('Big Order Under Production Review');
                    } })),
                SalesOrderStore.isBigOrder() && (React.createElement(Steps.Step, { className: 'cursor-pointer', title: React.createElement("span", null,
                        "Big Order Under ",
                        React.createElement("br", null),
                        "Point Person Review"), description: "", onClick: function () {
                        changeStage('Big Order Under Point Person Review');
                    } })),
                SalesOrderStore.isBigOrder() && (React.createElement(Steps.Step, { className: 'cursor-pointer', title: React.createElement("span", null, "Big Order Approved"), description: "", onClick: function () {
                        changeStage('Big Order Approved');
                    } })),
                React.createElement(Steps.Step, { disabled: true, className: 'cursor-pointer', title: "Complete", description: showStatusComplete() && sales_order.status, onClick: function () {
                        if (sales_order.status === 'Ready for Production' && sales_order.zoho_id !== null) {
                            notification.info({
                                message: 'Sales Order was already sent to production.',
                                placement: 'bottomRight',
                                duration: 3,
                            });
                        }
                        handleSubmit();
                    } }),
                React.createElement(Steps.Step, { className: 'cursor-pointer', title: "Cancelled", description: "", onClick: function () {
                        changeStage('Cancelled');
                    } })))));
};
export default observer(SalesOrderStatus);
