import React, {useEffect} from 'react';
import {Spin} from "antd";
import {useHistory, useParams} from "react-router";
import {QuoteStore} from "stores";


export const RequoteLinkRedirector = (props: any) => {

    const history = useHistory();

    const params = useParams();

    //@ts-ignore
    const {id} = params;

    useEffect(() => {

        if (id) {
            QuoteStore.getRequote(id).then((r: any) => {
                history.push('/get-quote/?uid=' + r.data.uid)
            })
        }


    }, [id]);


    return (
        <div className={"flex flex-col itemsCenter justifyContent"} style={{margin: "auto", width: "100%"}}>

            <Spin spinning={true} tip={"Loading quote.."}/>

        </div>
    )
};
