import * as ActionTypes from "../ActionTypes";

import {v4} from "uuid";


export const showNotification = (message, level = 'success') => {
    return {type: ActionTypes.NOTIFICATIONS_ADD, payload: {id: v4(), message: message, level: level}}
}

export const removeNotification = (id) => {
    return {type: ActionTypes.NOTIFICATIONS_REMOVE, id: id}
}
