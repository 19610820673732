import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Divider, Row, Select, Table } from 'antd';
import { fetchQuotesByAuthorGrouped } from '../../redux/actions/quoteActions';

import { connect } from 'react-redux';
import { DateRangePicker, ErrorRender } from '@partsbadger/library';
import moment from 'moment';
import { get } from '../../shared/api';

const { Column } = Table;
const FormItem = Form.Item;

const { RangePicker } = DatePicker;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};

class ReportFilter extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = {};

                if (values.is_itar) {
                    params['is_itar'] = values.is_itar;
                }

                if (values.stages) {
                    params['stages'] = values.stages;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                this.props.getData(params);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row className={'row-filters'}>
                <Form onSubmit={this.handleSubmit} layout="horizontal" style={{ padding: '20px' }}>
                    <Row>
                        <Col {...cols}>
                            <FormItem label="Status" {...formItemLayout}>
                                {getFieldDecorator(
                                    'stages',
                                    {}
                                )(
                                    <Select mode={'multiple'} placeholder={'ALL'}>
                                        <Select.Option value="Pending">Pending</Select.Option>
                                        <Select.Option value="Vendor Quoting">Vendor Quoting</Select.Option>
                                        <Select.Option value="In Progress">In Progress</Select.Option>
                                        <Select.Option value="Needs Review">Needs Review</Select.Option>
                                        <Select.Option value="Completed">Completed</Select.Option>
                                        <Select.Option value="Closed Won">Closed Won</Select.Option>
                                        <Select.Option value="Closed Lost">Closed Lost</Select.Option>
                                        <Select.Option value="Cancelled">Cancelled</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col {...cols}>
                            <FormItem label="Date" {...formItemLayout}>
                                {getFieldDecorator('created_time', {})(<DateRangePicker />)}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="Itar" {...formItemLayout}>
                                {getFieldDecorator(
                                    'is_itar',
                                    {}
                                )(
                                    <Select allowClear placeholder={'ALL'}>
                                        <Select.Option value={1}>Yes</Select.Option>
                                        <Select.Option value={0}>No</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>

                        <Col {...cols}>
                            <FormItem label="" {...formItemLayout}>
                                <Button type="default" htmlType={'submit'}>
                                    Filter
                                </Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Row>
        );
    }
}

const ReportFilterForm = Form.create()(ReportFilter);

const ListReport = ({ data, loading, dynamic }) => {
    const aggregated_quotes = data.reduce((total, row) => {
        return total + row.quotes;
    }, 0);
    const aggregated_line_items = data.reduce((total, row) => total + row.line_items, 0);
    const aggregated_quoted_products = data.reduce((total, row) => total + row.quoted_products, 0);
    const aggregated_no_quoted_products = data.reduce((total, row) => total + row.no_quoted_products, 0);
    const aggregated_dynamic_quotes_created = dynamic.dynamic_quotes_created;
    const aggregated_dynamic_quotes_completed = dynamic.dynamic_quotes_completed;
    const aggregated_products_dynamic_quotes = data.reduce((total, row) => total + row.products_dynamic_quotes, 0);

    return (
        <Table
            dataSource={data}
            loading={loading}
            footer={() => {
                return (
                    <div>
                        <div>
                            <strong>#Quotes:</strong> {aggregated_quotes}
                        </div>
                        <div>
                            <strong>#Line Items:</strong> {aggregated_line_items}
                        </div>
                        <div>
                            <strong>#Quoted Products:</strong> {aggregated_quoted_products}
                        </div>
                        <div>
                            <strong>#No Quoted Products:</strong> {aggregated_no_quoted_products}
                        </div>
                        <div>
                            <strong>#Dynamic Quotes Created:</strong> {aggregated_dynamic_quotes_created}
                        </div>
                        <div>
                            <strong>#Dynamic Quotes Completed:</strong> {aggregated_dynamic_quotes_completed}
                        </div>
                        <div>
                            <strong>#Products on Dynamic Quotes:</strong> {aggregated_products_dynamic_quotes}
                        </div>
                    </div>
                );
            }}
        >
            <Column title="Author" dataIndex="quote__design_engineer__first_name" key="design_engineer__first_name" />
            <Column title="Quotes" dataIndex="quotes" key="quotes" />
            <Column title="Quoted Line Items" dataIndex="line_items" key="line_items" />

            <Column title="Quoted Products" dataIndex="quoted_products" key="quoted_products" />
            <Column title="No Quoted Products" dataIndex="no_quoted_products" key="no_quoted_products" />
        </Table>
    );
};

class ReportQuoteByAuthor extends React.Component {
    state = {
        data: [],
        dynamic: [],
        loading: false,
    };

    getData = params => {
        this.setState({
            loading: true,
        });

        get(`staff/quoted-products/report/`, params)
            .then(response => {
                this.setState({
                    data: response.data.quoted_items,
                    dynamic: response.data.quotes,
                    loading: false,
                });
            })
            .catch(error => console.error(error));
    };

    componentDidMount() {
        this.getData({});
    }

    render() {
        return (
            <Fragment>
                {/* <ErrorRender error={this.props.quotes.error} /> */}

                <ReportFilterForm getData={this.getData} />

                <Divider>
                    <div className={'title'}> Quotes Report</div>
                </Divider>

                <Row>
                    <Col span={24}>
                        <ListReport data={this.state.data} dynamic={this.state.dynamic} loading={this.state.loading} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        dynamic: state.quotes,
        quotes: state.quoted_items,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchQuotes: params => dispatch(fetchQuotesByAuthorGrouped(params)),
    };
};

export const QuoteReport = connect(mapStateToProps, mapDispatchToProps)(ReportQuoteByAuthor);
