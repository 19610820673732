var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
//@ts-nocheck
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import { NodeIndexOutlined, LoadingOutlined } from '@ant-design/icons';
import * as THREE from 'three';
import { Canvas, createPortal, useFrame, useLoader, useThree } from '@react-three/fiber';
import { Html, OrbitControls, OrthographicCamera, useCamera } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
export function httpFetch(endpoint, options, addHeaders) {
    if (options === void 0) { options = {}; }
    if (addHeaders === void 0) { addHeaders = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // setup default headers
            return [2 /*return*/, fetch("".concat(endpoint), __assign(__assign({}, options), { headers: __assign({}, addHeaders) })).then(function (res) {
                    if (res.ok)
                        return res.json();
                    return Promise.reject(res);
                })];
        });
    });
}
export function get(endpoint, fetchOptions, addHeaders) {
    if (fetchOptions === void 0) { fetchOptions = {}; }
    if (addHeaders === void 0) { addHeaders = {}; }
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, httpFetch(endpoint, fetchOptions, addHeaders)];
        });
    });
}
var TOP = {
    offsetFactor: {
        x: 0,
        y: 0,
        z: 1,
    },
    axisAngle: {
        x: 0,
        y: 0,
        z: 0,
    },
};
var BOTTOM = {
    offsetFactor: {
        x: 0,
        y: 0,
        z: -1,
    },
    axisAngle: {
        x: Math.PI,
        y: 0,
        z: 0,
    },
};
var FRONT = {
    offsetFactor: {
        x: 0,
        y: -1,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: 0,
        z: 0,
    },
};
var BACK = {
    offsetFactor: {
        x: 0,
        y: 1,
        z: 0,
    },
    axisAngle: {
        x: -(Math.PI / 2),
        y: 0,
        z: Math.PI,
    },
};
var LEFT = {
    offsetFactor: {
        x: -1,
        y: 0,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: -(Math.PI / 2),
        z: 0,
    },
};
var RIGHT = {
    offsetFactor: {
        x: 1,
        y: 0,
        z: 0,
    },
    axisAngle: {
        x: Math.PI / 2,
        y: Math.PI / 2,
        z: 0,
    },
};
export function Edges(_a) {
    var userData = _a.userData, children = _a.children, geometry = _a.geometry, _b = _a.threshold, threshold = _b === void 0 ? 15 : _b, _c = _a.color, color = _c === void 0 ? 'black' : _c, props = __rest(_a, ["userData", "children", "geometry", "threshold", "color"]);
    var ref = React.useRef(null);
    React.useLayoutEffect(function () {
        var parent = ref.current.parent;
        if (parent) {
            var geom = geometry || parent.geometry;
            if (geom !== ref.current.userData.currentGeom || threshold !== ref.current.userData.currentThreshold) {
                ref.current.userData.currentGeom = geom;
                ref.current.userData.currentThreshold = threshold;
                ref.current.geometry = new THREE.EdgesGeometry(geom, threshold);
            }
        }
    });
    return (React.createElement("lineSegments", __assign({ ref: ref, raycast: function () { return null; } }, props), children ? children : React.createElement("lineBasicMaterial", { color: color })));
}
export var cadShaderFragment = "\nuniform float opacity;\nuniform float ambientBrightness;     // the brightness of edge lighting (suggested default: 0.1, prefer 0.0 to 1.0)\nuniform float directFactor;  // the brightness of front lighting (suggested default: 1.0, prefer 0.0 to 1.0)\nuniform vec4 tint;\n\nvarying vec3 fPosition;\nvarying vec3 fNormal;\nvarying vec3 fColor;\n\nvoid main()\n{\n    float normalDot = abs(dot(fNormal, normalize(-fPosition)));\n    float lightAmount = mix(ambientBrightness, 1.0, normalDot);\n    vec3 color = fColor * lightAmount;\n    if (tint.w > 0.) {\n        float tintAmount = mix(tint.w*0.1, tint.w, normalDot);\n        color = mix(color, tint.xyz, tintAmount);\n    }\n    gl_FragColor = vec4(color, opacity);\n}";
export var cadShaderVertex = "\nvarying vec3 fNormal;\nvarying vec3 fPosition;\nvarying vec3 fColor;\n\nconst float lumaMin = 0.15;\nconst float lumaRange = 1. - lumaMin;\n\nfloat getLumaFromColor(const in vec3 color) {\n    return color.x * 0.299 + color.y * 0.587 + color.z * 0.114;\n}\n\nvec3 compressColor(vec3 color)\n{\n    float luma = getLumaFromColor(color);\n    return color * lumaRange + lumaMin;\n}\n\nvoid main()\n{\n    vec4 pos = modelViewMatrix * vec4(position, 1.0);\n    fNormal = normalize(normalMatrix * normal);\n    fColor = compressColor(color);\n    fPosition = pos.xyz;\n    gl_Position = projectionMatrix * pos;\n}";
export var Shape = function (_a) {
    var shapeId = _a.shapeId, shape = _a.shape;
    var _b = useState(false), hovered = _b[0], setHovered = _b[1];
    return (React.createElement("mesh", { onPointerOver: function () { return setHovered(true); }, onPointerOut: function () { return setHovered(false); }, geometry: shape },
        React.createElement("shaderMaterial", { attach: "material", args: [
                {
                    side: THREE.DoubleSide,
                    vertexColors: THREE.VertexColors,
                    uniforms: {
                        opacity: { type: 'f', value: 1.0 },
                        ambientBrightness: { type: 'f', value: 0.3 },
                        tint: { type: 'v4', value: new THREE.Vector4(0, 0, 0, 0) },
                    },
                    vertexShader: cadShaderVertex,
                    fragmentShader: cadShaderFragment,
                },
            ] })));
};
export var Edge = function (_a) {
    var edgeId = _a.edgeId, edge = _a.edge;
    var _b = useState(false), hovered = _b[0], setHovered = _b[1];
    //Todo fix geometry  prop
    //@ts-ignore
    return (React.createElement("line", { geometry: edge, onPointerOver: function () { return setHovered(true); }, onPointerOut: function () { return setHovered(false); } },
        React.createElement("lineBasicMaterial", { linewidth: 1.5, attach: "material", color: hovered ? 'blue' : 'black' })));
};
function Box(_a) {
    var position = _a.position, color = _a.color, onClick = _a.onClick;
    return (React.createElement("mesh", { position: position, onClick: function () { return onClick(); } },
        React.createElement("boxBufferGeometry", { args: [10, 10, 10], attach: "geometry" }),
        React.createElement("meshPhongMaterial", { color: color, attach: "material" })));
}
var Viewcube = function () {
    var _a = useThree(), gl = _a.gl, scene = _a.scene, camera = _a.camera, size = _a.size;
    var virtualScene = useMemo(function () { return new THREE.Scene(); }, []);
    var virtualCam = useRef();
    var ref = useRef();
    var _b = useState(null), hover = _b[0], set = _b[1];
    var matrix = new THREE.Matrix4();
    var texture_front = useLoader(TextureLoader, '/textures/front.jpg');
    var texture_front_hover = useLoader(TextureLoader, '/textures/front-hover.jpg');
    var texture_back = useLoader(TextureLoader, '/textures/back.jpg');
    var texture_back_hover = useLoader(TextureLoader, '/textures/back-hover.jpg');
    var texture_top = useLoader(TextureLoader, '/textures/top.jpg');
    var texture_top_hover = useLoader(TextureLoader, '/textures/top-hover.jpg');
    var texture_bottom = useLoader(TextureLoader, '/textures/bottom.jpg');
    var texture_bottom_hover = useLoader(TextureLoader, '/textures/bottom-hover.jpg');
    var texture_left = useLoader(TextureLoader, '/textures/left.jpg');
    var texture_left_hover = useLoader(TextureLoader, '/textures/left-hover.jpg');
    var texture_right = useLoader(TextureLoader, '/textures/right.jpg');
    var texture_right_hover = useLoader(TextureLoader, '/textures/right-hover.jpg');
    useFrame(function () {
        matrix.copy(camera.matrix).invert();
        ref.current.quaternion.setFromRotationMatrix(matrix);
        gl.autoClear = true;
        gl.render(scene, camera);
        gl.autoClear = false;
        gl.clearDepth();
        gl.render(virtualScene, virtualCam.current);
    }, 1);
    var top_ = function () {
        var offsetFactor = TOP.offsetFactor, axisAngle = TOP.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        // const positionTween = new TWEEN.Tween(cameraRef.current.position)
        //   .to(finishPosition, 300)
        //   .easing(TWEEN.Easing.Circular.Out);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        // rotate camera too!
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
        // const quaternionTween = new TWEEN.Tween(cameraRef.current.quaternion)
        //   .to(finishQuaternion, 300)
        //   .easing(TWEEN.Easing.Circular.Out);
        // positionTween.start();
        // quaternionTween.start();
    };
    var bottom = function () {
        var offsetFactor = BOTTOM.offsetFactor, axisAngle = BOTTOM.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };
    var front = function () {
        var offsetFactor = FRONT.offsetFactor, axisAngle = FRONT.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };
    var back = function () {
        var offsetFactor = BACK.offsetFactor, axisAngle = BACK.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };
    var left = function () {
        var offsetFactor = LEFT.offsetFactor, axisAngle = LEFT.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };
    var right = function () {
        var offsetFactor = RIGHT.offsetFactor, axisAngle = RIGHT.axisAngle;
        var offsetUnit = camera.position.length();
        var offset = new THREE.Vector3(offsetUnit * offsetFactor.x, offsetUnit * offsetFactor.y, offsetUnit * offsetFactor.z);
        var center = new THREE.Vector3();
        var finishPosition = center.add(offset);
        camera.position.set(finishPosition.x, finishPosition.y, finishPosition.z);
        var euler = new THREE.Euler(axisAngle.x, axisAngle.y, axisAngle.z);
        var finishQuaternion = new THREE.Quaternion().copy(camera.quaternion).setFromEuler(euler);
        camera.quaternion.set(finishQuaternion.x, finishQuaternion.y, finishQuaternion.z, finishQuaternion.w);
    };
    return createPortal(React.createElement(React.Fragment, null,
        React.createElement(OrthographicCamera, { ref: virtualCam, makeDefault: false, position: [0, 0, 100] }),
        React.createElement("mesh", { ref: ref, raycast: useCamera(virtualCam), position: [size.width / 2 - 80, size.height / 2 - 80, 0], onClick: function (e) {
                var face = Math.floor(e.faceIndex / 2);
                switch (face) {
                    case 0:
                        right();
                        break;
                    case 1:
                        left();
                        break;
                    case 2:
                        back();
                        break;
                    case 3:
                        front();
                        break;
                    case 4:
                        top_();
                        break;
                    case 5:
                        bottom();
                        break;
                }
            }, onPointerOut: function (e) { return set(null); }, onPointerMove: function (e) { return set(Math.floor(e.faceIndex / 2)); } },
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 0 ? texture_front_hover : texture_front }),
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 1 ? texture_back_hover : texture_back }),
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 2 ? texture_top_hover : texture_top }),
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 3 ? texture_bottom_hover : texture_bottom }),
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 4 ? texture_left_hover : texture_left }),
            React.createElement("meshLambertMaterial", { attachArray: "material", map: hover === 5 ? texture_right_hover : texture_right }),
            React.createElement("boxGeometry", { args: [60, 60, 60] }),
            React.createElement(Edges, { color: 0x555555, threshold: 15, scale: 1.002 })),
        React.createElement("ambientLight", { intensity: 0.5 }),
        React.createElement("pointLight", { position: [10, 10, 10], intensity: 0.5 })), virtualScene);
};
var Controls = function (_a) {
    var partRef = _a.partRef, modelShapes = _a.modelShapes;
    var _b = useThree(), camera = _b.camera, gl = _b.gl;
    var controlsRef = useRef(null);
    var _c = useState({
        boundingSphere: {
            center: new THREE.Vector3(0, 0, 0),
            radius: 0,
        },
        boundingBox: {
            min: new THREE.Vector3(0, 0, 0),
            max: new THREE.Vector3(0, 0, 0),
        },
    }), modelMeta = _c[0], setModelMeta = _c[1];
    useEffect(function () {
        if (partRef.current) {
            var box = new THREE.Box3().setFromObject(partRef.current);
            var sphere = new THREE.Sphere();
            console.log('computed');
            box.getBoundingSphere(sphere);
            setModelMeta({
                boundingSphere: sphere,
                boundingBox: box,
            });
        }
    }, [modelShapes, partRef]);
    useEffect(function () {
        if (modelMeta.boundingSphere.radius > 0) {
            var offset = 4;
            var boundingBox = modelMeta.boundingBox;
            var center = boundingBox.getCenter();
            var size = boundingBox.getSize();
            // get the max side of the bounding box (fits to width OR height as needed )
            var maxDim = Math.max(size.x, size.y, size.z);
            camera.fov = 24; // Set camera fov to something closer to orthographic
            var fov = camera.fov * (Math.PI / 180);
            var cameraZ = Math.abs(maxDim * Math.tan(fov * 2));
            cameraZ *= offset; // zoom out a little so that objects don't fill the screen
            // Plce initial camera view at a three-quaters position
            camera.position.y = cameraZ;
            camera.position.x = cameraZ;
            camera.position.z = cameraZ;
            var minZ = boundingBox.min.z;
            var cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;
            camera.far = cameraToFarEdge * 10;
            camera.updateProjectionMatrix();
            if (controlsRef.current !== null) {
                // set camera to rotate around center of loaded object
                controlsRef.current.target = center;
                // prevent camera from zooming out far enough to create far plane cutoff
                // controlsRef.current.maxDistance = cameraToFarEdge * 2;
                controlsRef.current.saveState();
            }
            else {
                camera.lookAt(center);
            }
        }
    }, [partRef, modelMeta.boundingSphere.radius]);
    return (React.createElement("group", null,
        React.createElement(OrbitControls, { ref: controlsRef, enablePan: true, enableZoom: true, enableDamping: true, dampingFactor: 0.5, target: modelMeta.boundingSphere.center, minDistance: modelMeta.boundingSphere.radius / 5, maxDistance: modelMeta.boundingSphere.radius * 5, args: [camera, gl.domElement] })));
};
var MeasureTool = function (props) {
    var isMeasuring = props.isMeasuring, measureLine = props.measureLine, distance = props.distance, midpoint = props.midpoint, pointOne = props.pointOne, pointTwo = props.pointTwo;
    if (isMeasuring) {
        return (React.createElement("group", null,
            pointOne && (React.createElement("mesh", { position: pointOne },
                React.createElement("sphereBufferGeometry", { attach: "geometry", args: [1, 1, 1] }),
                React.createElement("meshStandardMaterial", { attach: "material", color: 'black' }))),
            pointTwo && (React.createElement("mesh", { position: pointTwo },
                React.createElement("sphereBufferGeometry", { attach: "geometry", args: [1, 1, 1] }),
                React.createElement("meshStandardMaterial", { attach: "material", color: 'black' }))),
            pointOne && pointTwo && measureLine && (
            //@ts-ignore
            React.createElement("line", { geometry: measureLine },
                React.createElement("lineBasicMaterial", { linewidth: 1, attach: "material", color: "black" }))),
            distance && midpoint && (
            //@ts-ignore
            React.createElement(Html, { position: midpoint },
                React.createElement("span", { style: { backgroundColor: '#ffffffaa' } },
                    "\uD83D\uDCCF ",
                    distance.toFixed(4),
                    "mm")))));
    }
    else {
        return null;
    }
};
var ThreeDViewer = function (_a) {
    var url = _a.url, canvasStyle = _a.canvasStyle;
    var _b = useState(false), modelLoading = _b[0], setModelLoading = _b[1];
    var _c = useState([]), modelShapes = _c[0], setModelShapes = _c[1];
    var _d = useState([]), modelEdges = _d[0], setModelEdges = _d[1];
    var partRef = useRef(null);
    var _e = useState(false), isMeasuring = _e[0], setIsMeasuring = _e[1];
    var _f = useState(null), measureLine = _f[0], setMeasureLine = _f[1];
    var _g = useState(null), pointOne = _g[0], setPointOne = _g[1];
    var _h = useState(null), pointTwo = _h[0], setPointTwo = _h[1];
    var _j = useState(null), distance = _j[0], setDistance = _j[1];
    var _k = useState(null), midpoint = _k[0], setMidpoint = _k[1];
    var measureClick = function (e) {
        if (!isMeasuring) {
            console.log(e.point);
            return false;
        }
        // third click clears measure tool points
        if (pointOne && pointTwo) {
            setPointOne(null);
            setPointTwo(null);
            setDistance(null);
            setMidpoint(null);
            setMeasureLine(null);
            // second click sets point two and computes midpoint (for text display location)
        }
        else if (pointOne) {
            var _a = e.point, x = _a.x, y = _a.y, z = _a.z;
            var p = new THREE.Vector3(x, y, z);
            var l = new THREE.BufferGeometry().setFromPoints([pointOne, p]);
            var l3 = new THREE.Line3(pointOne, p);
            var mp = new THREE.Vector3();
            setPointTwo(p);
            setMeasureLine(l);
            setDistance(pointOne.distanceTo(p));
            l3.getCenter(mp);
            setMidpoint(mp);
            // first click starts measuring
        }
        else {
            var _b = e.point, x = _b.x, y = _b.y, z = _b.z;
            setPointOne(new THREE.Vector3(x, y, z));
        }
    };
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var loader, modelJSON, model, shapes, edges;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setModelLoading(true);
                        loader = new THREE.BufferGeometryLoader();
                        return [4 /*yield*/, get(url)];
                    case 1:
                        modelJSON = _a.sent();
                        model = modelJSON.model;
                        shapes = [];
                        edges = [];
                        Object.keys(model.shapes).forEach(function (shpId) {
                            var bufferGeometry = loader.parse(model.shapes[shpId]);
                            bufferGeometry.computeBoundingBox();
                            bufferGeometry.computeBoundingSphere();
                            shapes.push(bufferGeometry);
                        });
                        Object.keys(model.edges).forEach(function (edgId) {
                            var bufferGeometry = loader.parse(model.edges[edgId]);
                            edges.push(bufferGeometry);
                        });
                        setModelShapes(shapes);
                        setModelEdges(edges);
                        setModelLoading(false);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [url]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { position: 'relative', width: '100%', height: '100%', overflow: 'hidden' } },
            modelLoading ? (React.createElement("div", { style: {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                } },
                React.createElement("div", { style: { position: 'absolute', display: 'flex', flexDirection: 'column' } },
                    React.createElement(LoadingOutlined, { style: { fontSize: '20px' } }),
                    "Loading 3D Model..."))) : null,
            React.createElement("div", { style: { position: 'absolute', top: 0, left: 0, zIndex: 1, padding: '2rem' } },
                React.createElement(Button, { style: { height: '4rem', marginBottom: '1rem' }, type: isMeasuring ? 'primary' : 'default', onClick: function () { return setIsMeasuring(!isMeasuring); } },
                    React.createElement(NodeIndexOutlined, null),
                    React.createElement("br", null),
                    React.createElement("span", null, "Ruler"))),
            React.createElement(Canvas, { style: canvasStyle, dpr: window.devicePixelRatio },
                React.createElement(Suspense, { fallback: null },
                    React.createElement(Viewcube, null)),
                React.createElement(Controls, { partRef: partRef, modelShapes: modelShapes }),
                React.createElement(MeasureTool, { isMeasuring: isMeasuring, pointOne: pointOne, pointTwo: pointTwo, measureLine: measureLine, distance: distance, midpoint: midpoint }),
                React.createElement("ambientLight", null),
                React.createElement("group", { ref: partRef, onClick: function (e) { return measureClick(e); } },
                    modelShapes.map(function (shp) { return (React.createElement(Shape, { key: shp.uuid, shapeId: shp.uuid, shape: shp })); }),
                    modelEdges.map(function (edg) { return (React.createElement(Edge, { key: edg.uuid, edgeId: edg.uuid, edge: edg })); }))))));
};
export default ThreeDViewer;
