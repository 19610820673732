import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Card, Divider, Modal, notification, Spin } from 'antd';
import { AutoCompleteRecord, AccountProps, ErrorRender } from '@partsbadger/library';

import { get, post } from '@partsbadger/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LabeledValue } from 'antd/es/select';

export interface RelatedFile {
    id: number;
    file: string;
    filename: string;
}

const MergeAccounts = () => {
    const [loading, setLoading] = useState(false);
    const [allAccounts, setAllAccounts] = useState<AccountProps[]>([]);
    const [accounts, setAccounts] = useState<AccountProps[]>([]);

    const [selectedOldAccounts, setSelectedOldAccounts] = useState<LabeledValue[]>([]);
    const [selectedNewAccount, setSelectedNewAccount] = useState<LabeledValue | null>(null);

    const getAllAccounts = async (search: string) => {
        await get(`/staff/accounts/all-accounts/`, {
            params: {
                search: search,
            },
        })
            .then(data => {
                setAllAccounts(data);
            })
            .catch(err => {
                notification.error({ message: 'Error', description: <ErrorRender error={err} /> });
            });
    };

    const getAccounts = async (search: string) => {
        await get(`/staff/accounts/`, {
            params: {
                search: search,
            },
        })
            .then(data => {
                setAccounts(data.results);
            })
            .catch(err => {
                notification.error({ message: 'Error', description: <ErrorRender error={err} /> });
            });
    };

    const confirm = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Do you want to move all Quotes, RFQs, Deals, Sales Orders, Invoices to ${selectedNewAccount?.label}?`,
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk: () => {
                migrateAccounts();
            },
        });
    };

    const migrateAccounts = () => {
        setLoading(true);
        post(`/staff/accounts/${selectedNewAccount?.key}/migrate-accounts/`, {
            old_accounts: selectedOldAccounts.map(c => c.key),
        })
            .then(data => {
                notification.success({ message: 'Success' });
            })
            .catch(err => {
                notification.error({ message: 'Error' });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card style={{ maxWidth: '80vw', margin: 'auto' }}>
            <Spin spinning={loading}>
                <Divider>
                    <div className={'title'}> Merge Accounts</div>
                </Divider>

                <Alert
                    type="error"
                    message={'Warning: The migration should be done first in zoho and then in this tool! '}
                />

                <div style={{ width: '77vw' }}>
                    <Form>
                        <Form.Item help={'This select only returns deleted items'}>
                            Select all deleted accounts to be replaced
                            <AutoCompleteRecord
                                placeholder={'Search a deleted account in zoho'}
                                mode="multiple"
                                handleFetch={async search => {
                                    await getAllAccounts(search);
                                }}
                                onChange={(values: any) => {
                                    setSelectedOldAccounts(values);
                                }}
                                records={allAccounts
                                    .filter(c => c.deleted)
                                    .map(c => {
                                        return {
                                            id: c.id,
                                            label: `${c.name} - ${c.zoho_id}`,
                                        };
                                    })}
                            />
                        </Form.Item>
                        <Form.Item>
                            Replace with:
                            <AutoCompleteRecord
                                handleFetch={async search => {
                                    await getAccounts(search);
                                }}
                                onSelect={(values: any) => {
                                    setSelectedNewAccount(values);
                                }}
                                records={accounts.map(c => {
                                    return {
                                        id: c.id,
                                        label: `${c.name} - ${c.zoho_id}`,
                                    };
                                })}
                            />
                        </Form.Item>
                        <br></br>
                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!selectedNewAccount?.key || selectedOldAccounts.length == 0}
                                onClick={() => {
                                    confirm();
                                }}
                                style={{ float: 'right' }}
                            >
                                Merge Accounts
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Card>
    );
};

export default MergeAccounts;
