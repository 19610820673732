import React, { useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber, Modal, Table, DatePicker } from "antd";
import { Price } from '@partsbadger/library';
import { PartDescription } from "components/SalesOrder";
import { AdditionalRequirements } from "@partsbadger/library";


const BulkUpdateForm = Form.create()(({form, onSubmitValues}) => {

    const {getFieldDecorator} = form;

    return (
        <Form onSubmit={e => {
            e.preventDefault();
            form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    onSubmitValues(values);
                }
            });

        }}>
            <Form.Item label={"Vendor Requirements"}>
                {getFieldDecorator('vendor_requirements')(
                    <AdditionalRequirements/>
                )}
            </Form.Item>

            <Form.Item label={"Vendor Requirement Notes"}>
                {getFieldDecorator('vendor_requirement_notes')(
                    <Input.TextArea/>
                )}
            </Form.Item>

            <Form.Item label={"In House Requirements"}>
                {getFieldDecorator('in_house_requirements')(
                    <AdditionalRequirements/>
                )}
            </Form.Item>

            <Form.Item label={"In House Requirement Notes"}>
                {getFieldDecorator('in_house_requirement_notes')(
                    <Input.TextArea/>
                )}
            </Form.Item>

            <Form.Item label={"Quantity"}>
                {getFieldDecorator('quantity')(
                    <InputNumber precision={0}/>
                )}

            </Form.Item>
            <Form.Item label={"Lead Time"}>
                {getFieldDecorator('lead_time')(
                    <InputNumber precision={0}/>
                )}
            </Form.Item>

            <Form.Item label={"Required Partner Ship Date"}>
                {getFieldDecorator('required_partner_ship_date')(
                    <DatePicker/>
                )}
            </Form.Item>

            <Form.Item>
                <Button type={"primary"} htmlType={"submit"}> Update </Button>
            </Form.Item>
        </Form>
    )
});

export const RequestForOrderLineItems = Form.create()(({value, onChange}) => {

        const [selectedItems, setSelectedItems] = useState([]);
        const [bulkUpdate, showBulkUpdate] = useState(false);


        const columns = [
            {
                title: 'Part Information',
                dataIndex: 'name',
                render: (text, record) =>
                    (
                        <PartDescription quote_has_product={record?.quote_has_product}/>
                    )
            },

            {
                title: 'Vendor Requirements',
                dataIndex: 'vendor_requirements',
                render: (text, record) =>
                    (
                        <React.Fragment>
                            <div>
                                <AdditionalRequirements value={record?.vendor_requirements} onChange={v => {

                                    const updated_items = value?.map(r => {
                                        return r.id === record.id ? {
                                            ...r,
                                            vendor_requirements: v
                                        } : r;
                                    });
                                    onChange(updated_items);
                                }}/>
                            </div>

                            <div className={"mt-1"}>
                                <Input.TextArea placeholder={"Notes"} value={record?.vendor_requirement_notes}
                                                onChange={event => {

                                                    const updated_items = value?.map(r => {
                                                        return r.id === record.id ? {
                                                            ...r,
                                                            vendor_requirement_notes: event.target.value
                                                        } : r;
                                                    });
                                                    onChange(updated_items);
                                                }}/>
                            </div>
                        </React.Fragment>
                    )
            },

            {
                title: 'In House Requirements',
                dataIndex: 'in_house_requirements',
                render: (text, record) =>
                    (
                        <React.Fragment>
                            <div>
                                <AdditionalRequirements value={record?.in_house_requirements} onChange={v => {

                                    const updated_items = value?.map(r => {
                                        return r.id === record.id ? {
                                            ...r,
                                            in_house_requirements: v
                                        } : r;
                                    });
                                    onChange(updated_items);
                                }}/>
                            </div>

                            <div className={"mt-1"}>
                                <Input.TextArea placeholder={"Notes"} value={record?.in_house_requirement_notes}
                                                onChange={event => {

                                                    const updated_items = value?.map(r => {
                                                        return r.id === record.id ? {
                                                            ...r,
                                                            in_house_requirement_notes: event.target.value
                                                        } : r;
                                                    });
                                                    onChange(updated_items);
                                                }}/>
                            </div>
                        </React.Fragment>
                    )
            },
            {
                title: 'Required Part Ship Date',
                dataIndex: 'required_partner_ship_date',
                render: (text, record) => {
                    return <DatePicker value={record?.required_partner_ship_date}
                                       onChange={v => {
                                           const updated_items = value?.map(r => {
                                               return r.id === record.id ? {
                                                   ...r,
                                                   required_partner_ship_date: v
                                               } : r;
                                           });
                                           onChange(updated_items);
                                       }}/>
                },
            },

            {
                title: 'LT',
                dataIndex: 'lead_time',
                render: (text, record) => {
                    return <InputNumber precision={0} value={record?.quote_has_product?.lead_time}
                                        onChange={v => {
                                            const updated_items = value?.map(r => {
                                                return r.id === record.id ? {
                                                    ...r,
                                                    quote_has_product: {...r.quote_has_product, lead_time: v}
                                                } : r;
                                            });
                                            onChange(updated_items);
                                        }}/>
                },
            },

            {
                title: 'Qty',
                dataIndex: 'quantity',
                align: 'center',
                render: (text, record) => {
                    return <InputNumber value={record.quantity}
                                        onChange={v => {
                                            const updated_items = value?.map(r => {
                                                return r.id === record.id ? {
                                                    ...r,
                                                    quantity: v,
                                                    total_price: r.unit_price * v
                                                } : r;
                                            });
                                            onChange(updated_items);
                                        }}/>
                },
            },
            {
                title: 'Unit Price',
                dataIndex: 'unit_price',
                render: (text, record) => {
                    return <InputNumber defaultValue={record.unit_price}
                                        onChange={v => {
                                            const updated_items = value?.map(r => {
                                                return r.id === record.id ? {
                                                    ...r,
                                                    unit_price: v,
                                                    total_price: r.quantity * v
                                                } : r;
                                            });
                                            onChange(updated_items);
                                        }}/>
                },
                align: 'right'
            },

            {
                title: 'Total Price',
                dataIndex: 'total_price',
                render: (text, record) => {
                    return <Price value={record.total_price}/>
                },
                align: 'right'
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            <span className={"link"} onClick={() => {
                                const updated_items = value?.filter(r => {
                                    return r.id !== record.id && r;
                                });
                                onChange(updated_items);
                            }}>Remove</span>
                        </React.Fragment>
                    )
                },
                align: 'right'
            }
        ];


        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedItems(selectedRows);
            }
        };

        return (<div>

            <div>
                <Button type={"primary"} onClick={() => showBulkUpdate(true)}>Bulk Update</Button>
            </div>

            <div className={"mt-5"}>
                <Table
                    rowKey={"id"}
                    rowSelection={{
                        ...rowSelection,
                        selections: [
                            ...selectedItems
                        ],
                    }}
                    columns={columns}
                    dataSource={value}
                    pagination={false}
                    scroll={window.innerWidth <= 600 ? {x: 600} : undefined}
                />
            </div>

            {bulkUpdate && (
                <Modal title={"Bulk Update"} visible={true} footer={[]} onCancel={() => showBulkUpdate(false)} bodyStyle={{
                    height: "auto"
                }}>
                    <BulkUpdateForm onSubmitValues={values => {

                        const updated_items = value?.map(r => {

                            const {vendor_requirements, vendor_requirement_notes, in_house_requirements, in_house_requirement_notes, quantity, unit_price, lead_time, required_partner_ship_date} = values;


                            if (vendor_requirements) {

                                r = {
                                    ...r,
                                    vendor_requirements: [...vendor_requirements]
                                }

                            }


                            if (vendor_requirement_notes) {

                                r = {
                                    ...r,
                                    vendor_requirement_notes: vendor_requirement_notes
                                }

                            }

                            if (in_house_requirements) {

                                r = {
                                    ...r,
                                    in_house_requirements: [...in_house_requirements]
                                }

                            }


                            if (in_house_requirement_notes) {

                                r = {
                                    ...r,
                                    in_house_requirement_notes: in_house_requirement_notes
                                }

                            }

                            if (quantity) {

                                r = {
                                    ...r,
                                    quantity: quantity,
                                    total_price: r.unit_price * quantity
                                }

                            }


                            if (unit_price) {

                                r = {
                                    ...r,
                                    unit_price: unit_price,
                                    total_price: r.unit_price * quantity
                                }

                            }

                            if (lead_time) {

                                r = {
                                    ...r,
                                    quote_has_product: {...r.quote_has_product, lead_time: lead_time}
                                }

                            }

                            if (required_partner_ship_date) {

                                r = {
                                    ...r,
                                    required_partner_ship_date: required_partner_ship_date
                                }

                            }

                            return {...r}
                        });
                        onChange(updated_items);


                        showBulkUpdate(false);
                    }}/>
                </Modal>
            )}
        </div>)

    }
);

