import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { REACT_APP_ENVIRONMENT } from '@partsbadger/utils';

const LuckyOrangeScript = () => {
    const location = useLocation();

    useEffect(() => {
        const pathsToIncludeScript = ['/sales-order/new/', '/get-quote'];
        const currentPath = location.pathname;

        if (pathsToIncludeScript.includes(currentPath) && REACT_APP_ENVIRONMENT === 'production') {
            const loScriptConfig = document.createElement('script');
            loScriptConfig.type = 'text/javascript';
            loScriptConfig.textContent = `window.__lo_site_id = "320886";`;

            const loScript = document.createElement('script');
            loScript.type = 'text/javascript';
            loScript.async = true;
            loScript.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';

            document.head.appendChild(loScriptConfig);
            document.head.appendChild(loScript);

            return () => {
                document.head.removeChild(loScriptConfig);
                document.head.removeChild(loScript);
            };
        }
    }, [location]);

    return null;
};

export default LuckyOrangeScript;
