import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Select } from 'antd';
import { Accounts, DateRangePicker, GenericSelect } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';
import { useQueryParameters } from '@partsbadger/utils';
import { FormComponentProps } from '@ant-design/compatible/es/form';

const Search = Input.Search;

const cols = {
    xs: 24,
    sm: 12,
    md: 8,
};

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 12,
    },
};

const FormItem = Form.Item;

const SalesOrderFilter = (props: FormComponentProps) => {
    const query = useQueryParameters();
    const history = useHistory();

    const { form } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                const params: any = {};

                if (values.search) {
                    params['search'] = values.search;
                }

                if (values.account?.key) {
                    params['account'] = values.account.key;
                }

                if (values.status) {
                    params['status'] = values.status;
                }

                if (values.sales_order?.key) {
                    params['sales_order'] = values.sales_order.key;
                }

                if (values.created_time) {
                    params['created_time_min'] = moment(values.created_time[0]).format('YYYY-MM-DD');
                    params['created_time_max'] = moment(values.created_time[1]).format('YYYY-MM-DD');
                }

                const queryString = Object.keys(params)
                    .map(key => key + '=' + params[key])
                    .join('&');

                history.replace(`/inventory/?${queryString}`);
            }
        });
    };

    const { getFieldDecorator } = form;

    return (
        <Row className={'row-filters'}>
            <Form onSubmit={handleSubmit} layout="horizontal">
                <Row>
                    <Col {...cols}>
                        <FormItem label="Search" {...formItemLayout}>
                            {getFieldDecorator('search', {
                                initialValue: query.get('search'),
                            })(<Search placeholder="Search" style={{ width: '100%' }} allowClear size={'small'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Account" {...formItemLayout}>
                            {getFieldDecorator('account', {
                                initialValue: query.get('account')
                                    ? {
                                          key: query.get('account'),
                                      }
                                    : {},
                            })(<Accounts />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Sales Orders" {...formItemLayout}>
                            {getFieldDecorator('sales_order', {
                                initialValue: query.get('sales_order')
                                    ? {
                                          key: query.get('sales_order'),
                                      }
                                    : {},
                            })(<GenericSelect recordType={'SalesOrders'} />)}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Status" {...formItemLayout}>
                            {getFieldDecorator('status', {
                                initialValue: query.get('status'),
                            })(
                                <Select allowClear size="small">
                                    <Select.Option value="pending">Pending</Select.Option>
                                    <Select.Option value="PB Scrapped">PB Scrapped</Select.Option>
                                    <Select.Option value="Customer Scrapped">Customer Scrapped</Select.Option>
                                    <Select.Option value="Fixed">Fixed</Select.Option>
                                    <Select.Option value="CS Inventory">CS Inventory</Select.Option>
                                    <Select.Option value="CS Inventory Sold">CS Inventory Sold</Select.Option>
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col {...cols}>
                        <FormItem label="Created Time" {...formItemLayout}>
                            {getFieldDecorator('created_time', {
                                initialValue:
                                    query.get('created_time_min') && query.get('created_time_max')
                                        ? [
                                              moment(query.get('created_time_min'), 'YYYY-MM-DD'),
                                              moment(query.get('created_time_max'), 'YYYY-MM-DD'),
                                          ]
                                        : null,
                            })(<DateRangePicker size="small" />)}
                        </FormItem>
                    </Col>

                    <Col sm={24} style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Form.Item>
                            <Button
                                onClick={() => {
                                    history.push(`/inventory/`);
                                    form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button type="primary" htmlType={'submit'} style={{ marginLeft: 8 }}>
                                Filter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};

export default Form.create()(SalesOrderFilter);
