import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import QuoteStore from '../stores/QuoteStore';
import { Price } from '@partsbadger/library';
export var BulkPrices = function (_a) {
    var item = _a.item, handleSelect = _a.handleSelect;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState([]), data = _c[0], setData = _c[1];
    var columns = [
        {
            title: 'Lead Time',
            dataIndex: 'lead_time',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unit_price',
            align: 'center',
            className: 'ten-units',
            render: function (t, r) {
                return React.createElement(Price, { value: r.unit_price });
            },
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            align: 'center',
            className: 'one-unit',
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            align: 'center',
            className: 'twentyfive-units',
            render: function (t, r) {
                return React.createElement(Price, { value: r.total_price });
            },
        },
        {
            title: 'Actions',
            align: 'center',
            render: function (t, r) {
                return (React.createElement("span", { className: 'link', onClick: function () {
                        handleSelect(r.quantity);
                    } }, "Select"));
            },
        },
    ];
    useEffect(function () {
        setLoading(true);
        QuoteStore.getBulkPrices(item.id, [1, 25, 50, 100])
            .then(function (data) { return setData(data); })
            .finally(function () { return setLoading(false); });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, "Your per unit price:"),
        React.createElement(Spin, { spinning: loading },
            React.createElement(Table, { rowKey: 'quantity', bordered: true, columns: columns, dataSource: data, size: "middle", pagination: { position: undefined } }))));
};
