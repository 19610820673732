var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Col, Divider, notification, Row, Typography } from 'antd';
import { patch, post, remove } from '@partsbadger/utils';
import { ContactStore, QuoteStore } from '../Stores';
import { Price } from '@partsbadger/library';
import { ApplicationCostForm } from '../Forms';
import { observer } from 'mobx-react';
import { requirements_for_app_costs } from 'quotes-frontend/src/shared';
var Text = Typography.Text, Paragraph = Typography.Paragraph;
var ApplicationCostItems = observer(function (_a) {
    var _b, _c;
    var quote = _a.quote, account = _a.account, setReorderingAppCost = _a.setReorderingAppCost;
    useEffect(function () {
        var _a;
        if ((_a = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _a === void 0 ? void 0 : _a.id) {
            ContactStore.getAdditionalRequirementsContactAccount(quote.contact.id);
        }
    }, [(_b = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _b === void 0 ? void 0 : _b.id]);
    var _d = useState(), editingKey = _d[0], setEditingKey = _d[1];
    var duplicateAdditionalCost = function (item) {
        var payload = {
            quote: item.quote,
            is_fai: false,
            additional_requirement: item.additional_requirement,
            price: item.price,
        };
        post('/staff/quote-additional-requirements/duplicate/', payload).then(function () {
            QuoteStore.getQuote(quote.id);
        });
    };
    var popup = function (url) {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var needAppCosts = useMemo(function () {
        if (quote.custom_requirements) {
            return quote.custom_requirements.filter(function (x) { return requirements_for_app_costs.includes(x.label); });
        }
        return [];
    }, [quote.custom_requirements]);
    return (React.createElement("div", { style: { margin: '0px 0' } },
        React.createElement(Row, { className: 'center' },
            React.createElement(Col, { md: 12, sm: 24 },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 16 },
                        React.createElement("span", { className: 'font-bold' }, "Application")),
                    React.createElement(Col, { sm: 4 },
                        React.createElement("span", { className: 'font-bold' }, "Cost")),
                    React.createElement(Col, { sm: 4, className: 'font-bold' }, "Action")),
                React.createElement(Col, null,
                    React.createElement(Divider, { className: 'line', style: { margin: '2px' } })), (_c = quote === null || quote === void 0 ? void 0 : quote.additional_requirements) === null || _c === void 0 ? void 0 :
                _c.map(function (item) {
                    if (editingKey === item.id) {
                        return (React.createElement(ApplicationCostForm, { item: {
                                id: item.id,
                                additional_requirement: item.additional_requirement,
                                price: item.price,
                            }, handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, patch("/staff/quote-additional-requirements/".concat(item.id, "/"), payload)];
                                        case 1:
                                            _a.sent();
                                            QuoteStore.getQuote(quote === null || quote === void 0 ? void 0 : quote.id);
                                            setEditingKey(undefined);
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }));
                    }
                    return (React.createElement(React.Fragment, null,
                        React.createElement(React.Fragment, { key: item.id },
                            React.createElement(Row, null,
                                React.createElement(Col, { sm: 16 }, item.additional_requirement),
                                React.createElement(Col, { sm: 4 },
                                    React.createElement(Price, { value: item.price })),
                                React.createElement(Col, { sm: 4 },
                                    React.createElement(React.Fragment, null,
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(DeleteOutlined, null), onClick: function () {
                                                remove("/staff/quote-additional-requirements/".concat(item.id, "/"))
                                                    .then(function () {
                                                    QuoteStore.getQuote(quote.id);
                                                    QuoteStore.getMasterProducts(quote.id);
                                                })
                                                    .catch(function (error) {
                                                    notification.error({
                                                        message: 'Error',
                                                        description: error,
                                                    });
                                                });
                                            } }),
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(EditOutlined, null), onClick: function () { return setEditingKey(item.id); } }),
                                        React.createElement(Button, { disabled: !quote.editable, icon: React.createElement(LegacyIcon, { type: 'copy' }), onClick: function () { return duplicateAdditionalCost(item); } })))),
                            React.createElement(Row, null,
                                React.createElement(Divider, { className: 'line', style: { margin: '2px' } })))));
                }),
                React.createElement("br", null),
                quote.editable && (React.createElement(React.Fragment, null,
                    needAppCosts.length > 0 && (React.createElement("div", { className: 'flex flex-col pb-2' },
                        React.createElement("div", { className: 'flex justify-start' },
                            React.createElement("span", null, "Additional requirements that should include application costs.")),
                        React.createElement("div", { className: 'flex flex-col text-red-700 px-2' }, needAppCosts.map(function (x, i) {
                            return (React.createElement("div", { key: i, className: 'flex' },
                                React.createElement("span", { className: 'px-2' }, "*"),
                                React.createElement("span", null, x.label)));
                        })))),
                    React.createElement(ApplicationCostForm, { handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, post('/staff/quote-additional-requirements/', payload)];
                                    case 1:
                                        _a.sent();
                                        QuoteStore.getQuote(quote === null || quote === void 0 ? void 0 : quote.id);
                                        return [2 /*return*/];
                                }
                            });
                        }); }, setReorderingAppCost: setReorderingAppCost })))),
            React.createElement(Col, { md: 12, sm: 24, style: { textAlign: 'center' } },
                ContactStore.requirements && (React.createElement(Row, null,
                    React.createElement(Col, { md: 12, xs: 24 },
                        React.createElement("strong", { style: { textAlign: 'right' } }, "Contact/Account Requirements:")),
                    React.createElement(Col, { md: 12, xs: 24 },
                        React.createElement("ul", { style: { textAlign: 'left' } }, ContactStore.requirements.map(function (r) { return (React.createElement("li", { key: r.id }, r.requirement.name)); }))))),
                ContactStore.documents.length && (React.createElement(React.Fragment, null,
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 12, xs: 24 },
                            React.createElement("strong", { style: { textAlign: 'right' } }, "Contact/Account Documents:")),
                        React.createElement(Col, { md: 12, xs: 24 },
                            React.createElement("ul", { style: { textAlign: 'left' } }, ContactStore.documents.map(function (d) { return (React.createElement("li", { key: d.id },
                                React.createElement("a", { onClick: function () { return popup(d.file); }, target: "_blank", rel: "noreferrer" }, d.filename))); }))))))))));
});
export default ApplicationCostItems;
