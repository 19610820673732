/**
 *
 */
var precision = 12;
function count_sig_figs() {
    return 12;
}
export var in3_to_mm3 = function (value_inches) {
    var precision = count_sig_figs();
    var result = (value_inches * 16387.064).toPrecision(precision);
    return normalize_number(result);
};
export var mm3_to_in3 = function (value_mm) {
    var precision = count_sig_figs();
    var result = (value_mm / 16387.064).toPrecision(precision);
    return normalize_number(result);
};
export var in_to_mm = function (value_inches) {
    var result = (value_inches * 25.4).toPrecision(precision);
    return normalize_number(result);
};
export var mm_to_in = function (value_mm) {
    var result = (value_mm * 0.0393701).toPrecision(precision);
    return normalize_number(result);
};
export var normalize_number = function (value) {
    return parseFloat(value).toFixed(3);
};
