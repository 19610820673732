import React from 'react';
import { Tag } from 'antd';

interface Props {
    status: string | null;
}

const InvoiceStatus = (props: Props) => {
    const status = props.status;

    if (status == 'draft') {
        return <Tag> Draft </Tag>;
    }
    if (status == 'sent') {
        return <Tag color={'#268ddd'}> Sent </Tag>;
    }

    if (status == 'paid') {
        return <Tag color={'#1fcd6d'}> Paid </Tag>;
    }
    if (status == 'partially_paid') {
        return <Tag color={'#1fcd6d'}> Partially Paid </Tag>;
    }

    if (status == 'overdue') {
        return (
            <Tag
                color={'#fff'}
                style={{
                    background: '#f59d00',
                }}
            >
                {' '}
                Overdue{' '}
            </Tag>
        );
    }

    return <Tag> {status} </Tag>;
};

export default InvoiceStatus;
