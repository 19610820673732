import React from 'react';
import {QuoteForm} from "../../components/QuoteTool";
import {Divider} from "antd";

export const QuoteNew = (props) => {


    return (
        <div style={{margin: "auto"}}>

            <Divider>
                <div className={"title"}> New Quote
                </div>
            </Divider>


            <QuoteForm {...props}/>

            <br/>
            <p style={{textAlign: 'center'}}>
                Need Help? Call (331) BADGER-8
            </p>

        </div>
    )
};
