import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, notification, PageHeader, Row } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CloudDownloadOutlined, SendOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { OrderQuotedProductProps } from '@partsbadger/types';
import { QuotedProductStore } from '../../stores';
import { ThreeDViewer } from '@partsbadger/threedviewer';
import { DateRender, ErrorRender, Price } from '@partsbadger/library';
import { UserStore } from '../../stores/UserStore';
import { getCurrentUser } from '../../components/User';
import { URL_FRONTEND } from '@partsbadger/utils';

export const QuotedProductDetail = observer(() => {
    const history = useHistory();
    let id = '';
    ({ id } = useParams());

    const user = UserStore.user;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [orderQuotedProduct, setOrderQuotedProduct] = useState<OrderQuotedProductProps | null>(null);
    const [checkPricingReviewed, setCheckPricingReviewed] = useState<boolean>(false);

    const arrayData = QuotedProductStore.orderItemIds;
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex(prevIndex => prevIndex + 1);
        history.replace(`/quoted-product/${arrayData[currentIndex + 1]}`);
    };

    const handlePreview = () => {
        setCurrentIndex(prevIndex => prevIndex - 1);
        history.replace(`/quoted-product/${arrayData[currentIndex - 1]}`);
    };

    const getQuotedProduct = async (id: number) => {
        await QuotedProductStore.getVendorQuotedProduct(id).catch(err => {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        });
        if (QuotedProductStore.quotedProduct) {
            setOrderQuotedProduct(QuotedProductStore.quotedProduct);
        }
        if (QuotedProductStore.quotedProduct && QuotedProductStore.quotedProduct.user_item_vendor) {
            form.setFieldValue(
                'questions_or_comments',
                QuotedProductStore.quotedProduct.user_item_vendor.questions_or_comments
            );
            setCheckPricingReviewed(QuotedProductStore.quotedProduct.user_item_vendor.pricing_reviewed);
        } else {
            form.setFieldValue('questions_or_comments', '');
        }
    };

    useEffect(() => {
        if (id) {
            getQuotedProduct(Number(id));
            setCheckPricingReviewed(false);
        }
    }, [id]);

    const updatePricingReviewed = async (id: number, pricing_reviewed: boolean) => {
        if (orderQuotedProduct) {
            await QuotedProductStore.saveUserItemVendor(id, {
                user: user?.id,
                sales_order_item: orderQuotedProduct.id,
                pricing_reviewed: pricing_reviewed,
            });
        }
    };

    const onFinish = async (values: { questions_or_comments: string }) => {
        if (orderQuotedProduct) {
            const current_user = getCurrentUser().data;

            const payload = {
                content: `DE: ${current_user?.fullname}: ${current_user?.email}, sent a comment: \n\n ${values.questions_or_comments}`,
                title: 'Quote Pricing Feedback Dashboard, new message',
                types: ['email'],
                url: `${URL_FRONTEND}/quoted-product/${orderQuotedProduct?.id}`,
                email_to: 'nick@parts-badger.com',
            };

            await QuotedProductStore.createNotification(payload)
                .then(() => {
                    notification.success({
                        message: 'Questions or Comments submitted successfully!',
                        placement: 'topRight',
                    });
                })
                .catch(err => {
                    notification.error({
                        message: <ErrorRender error={err} />,
                        placement: 'topRight',
                    });
                });

            await QuotedProductStore.saveUserItemVendor(orderQuotedProduct.id, {
                user: user?.id,
                sales_order_item: orderQuotedProduct.id,
                questions_or_comments: values.questions_or_comments,
            });
        }
    };
    const onFinishFailed = async (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const downloadFiles = async (order_item_id: number, part_name: string): Promise<void> => {
        setLoading(true);
        await QuotedProductStore.downloadAllFiles(order_item_id, part_name).finally(() => setLoading(false));
    };

    return (
        <Row>
            {orderQuotedProduct && (
                <>
                    <Col span={24}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => history.replace('/quoted-products')}
                            title={orderQuotedProduct.part_name}
                            style={{
                                borderBottom: '1px solid #C7C7C7',
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <div>
                                        <span className="font-bold mx-2">Part Number:</span>
                                        <span>{orderQuotedProduct.part_number}</span>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Quantity:</span>
                                        <span>{orderQuotedProduct.quantity}</span>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Acceptance Date:</span>
                                        <span>
                                            <DateRender value={orderQuotedProduct.award_date} format={'MMMM D, YYYY'} />
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div>
                                        <span className="font-bold mx-2">Account Name:</span>
                                        <span>{orderQuotedProduct.account_name}</span>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Order Number:</span>
                                        <Link
                                            to={`/sales-orders/?search=${orderQuotedProduct.order_number}`}
                                            target={'_blank'}
                                        >
                                            {orderQuotedProduct.order_number}
                                        </Link>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Quote Number:</span>
                                        <span>
                                            <Link to={`/quotes/${orderQuotedProduct.quote_id}`} target={'_blank'}>
                                                {orderQuotedProduct.quote_number}
                                            </Link>
                                        </span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div>
                                        <span className="font-bold mx-2">Quote Date:</span>
                                        <span>
                                            <DateRender value={orderQuotedProduct.quote_date} />
                                        </span>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Requote:</span>
                                        <span>{orderQuotedProduct.quote_type === 'Requote' ? 'Yes' : 'No'}</span>
                                    </div>
                                    <div>
                                        <span className="font-bold mx-2">Original Quote:</span>
                                        <span>
                                            {orderQuotedProduct.duplicated_from_id && (
                                                <Link
                                                    to={`/quotes/${orderQuotedProduct.duplicated_from_id}/`}
                                                    target={'_blank'}
                                                >
                                                    {orderQuotedProduct.duplicated_from_name}
                                                </Link>
                                            )}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </PageHeader>
                    </Col>

                    <Col span={24}>
                        <Row>
                            {orderQuotedProduct.miss_match_quoted_files && (
                                <Col span={24}>
                                    <Alert
                                        message="The order item contains files that could not match the quote files, please review the files to make sure they are correct."
                                        type="warning"
                                        showIcon
                                        closable
                                        style={{ marginBottom: '20px' }}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row>
                            {orderQuotedProduct.attachments.file3d && (
                                <Col span={orderQuotedProduct.attachments.files_2d ? 12 : 24}>
                                    {orderQuotedProduct.attachments.file3d && (
                                        <ThreeDViewer
                                            url={orderQuotedProduct.attachments.file3d.file_3djs}
                                            canvasStyle={{ height: '500px' }}
                                        />
                                    )}
                                </Col>
                            )}
                            {orderQuotedProduct.attachments.files_2d && (
                                <Col span={orderQuotedProduct.attachments.file3d ? 12 : 24}>
                                    <>
                                        {orderQuotedProduct.attachments.files_2d.file
                                            ?.split('.')
                                            ?.pop()
                                            ?.split('?')[0]
                                            .toLowerCase() == 'pdf' ? (
                                            <embed
                                                src={orderQuotedProduct.attachments.files_2d.file}
                                                type="application/pdf"
                                                width="100%"
                                                height={`500px`}
                                            />
                                        ) : (
                                            <div className={'flex items-center justify-center h-full'}>
                                                This file is not a pdf
                                                <a
                                                    className={'ml-2'}
                                                    href={orderQuotedProduct.attachments.files_2d.file}
                                                >
                                                    {orderQuotedProduct.attachments.files_2d.filename}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                </Col>
                            )}
                            <Col span={24} className={'py-4'}>
                                <Row className="justify-center">
                                    <Col span={24}>
                                        <hr />
                                        <div className="flex flex-row justify-content-center">
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Formula:</span>
                                                <span>{orderQuotedProduct.formula}</span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Sides:</span>
                                                <span>{orderQuotedProduct.sides}</span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Manual Adjust:</span>
                                                <span>{orderQuotedProduct.manual_adjust}</span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Vendor Adjust:</span>
                                                <span>{orderQuotedProduct.vendor_adjust}</span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Awarded Vendor Total Price:</span>
                                                <span>
                                                    <Price value={orderQuotedProduct.vendor_total_price} />
                                                </span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Est. Vendor Total Cost:</span>
                                                <span>
                                                    <Price value={orderQuotedProduct.vendor_estimated_price} />
                                                </span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Awarded Vendor Unit Price:</span>
                                                <span>
                                                    <Price
                                                        value={
                                                            orderQuotedProduct.vendor_total_price /
                                                            orderQuotedProduct.quantity
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Est. Vendor Unit Price:</span>
                                                <span>
                                                    <Price
                                                        value={
                                                            orderQuotedProduct.vendor_estimated_price /
                                                            orderQuotedProduct.quantity
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div className="p-4">
                                                <span className="font-bold mx-2">Adjust Error:</span>
                                                <span>
                                                    <Price value={orderQuotedProduct.de_adj_error} />
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <div className="flex justify-center my-4">
                                    <Form
                                        form={form}
                                        name="comment-or-questions"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        layout="vertical"
                                    >
                                        <div className="grid grid-cols-3 gap-4">
                                            <Form.Item
                                                label={'Questions or Comments'}
                                                name="questions_or_comments"
                                                rules={[{ required: true, message: 'This field is required!' }]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>
                                        </div>
                                        <Form.Item>
                                            <div className={'flex flex-row space-x-4'}>
                                                <Button
                                                    loading={QuotedProductStore.isLoading}
                                                    className={'mx-2'}
                                                    type="primary"
                                                    size={'large'}
                                                    htmlType="submit"
                                                    icon={<SendOutlined />}
                                                >
                                                    Submit Questions or Comments
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="flex justify-center">
                                    <div className="grid grid-cols-3 gap-4">
                                        <Form.Item label={'Pricing Reviewed'} name="pricing_reviewed">
                                            <Checkbox
                                                checked={checkPricingReviewed}
                                                onChange={async e => {
                                                    setCheckPricingReviewed(e.target.checked);
                                                    await updatePricingReviewed(
                                                        orderQuotedProduct.id,
                                                        e.target.checked
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="flex justify-center my-4">
                                    <Button
                                        size={'large'}
                                        icon={<CloudDownloadOutlined />}
                                        onClick={() =>
                                            orderQuotedProduct &&
                                            downloadFiles(orderQuotedProduct.id, orderQuotedProduct.part_name)
                                        }
                                        loading={loading}
                                    >
                                        Download 3D and 2D Files
                                    </Button>
                                </div>
                                {arrayData.length > 0 && (
                                    <div className="flex justify-center">
                                        <Button
                                            className={'mx-2'}
                                            size={'large'}
                                            type={'primary'}
                                            icon={<StepBackwardOutlined />}
                                            onClick={handlePreview}
                                            disabled={currentIndex === 0}
                                        >
                                            Prev Part
                                        </Button>
                                        <Button
                                            className={'mx-2'}
                                            size={'large'}
                                            type={'primary'}
                                            icon={<StepForwardOutlined />}
                                            onClick={handleNext}
                                            disabled={currentIndex === arrayData.length - 1}
                                        >
                                            <span>Next Part</span>
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    );
});
