import React, {Fragment} from 'react';
import {hideQuoteForm, postQuote, showQuoteForm} from "../../../redux/actions/quoteActions";
import {connect} from "react-redux";
import { FileAddOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Select } from 'antd';
import LoadingComponent from "../../LoadingComponent";
import {ErrorRender} from "@partsbadger/library";
import Checkbox from "antd/lib/checkbox";

const ButtonGroup = Button.Group;

class QuoteNewModalComponent extends React.Component {


    state = {
        name: ""
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const deal = this.props.deals.entity;
                const payload = {
                    deal: deal.id,
                    account: deal.account ? deal.account.id : null,
                    contact: deal.contact.id,
                    sales_representative: deal.owner.id,
                    type: values.type,
                    high_risk: values.high_risk,
                    european_customer: values.european_customer,
                    shipping_zip_code: values.shipping_zip_code,
                }

                this.props.postQuote(payload);
            }
        });
    }


    render() {

        const {getFieldDecorator} = this.props.form

        const formItemLayout = {
            labelCol: {span: 6},
            wrapperCol: {span: 14},
        };


        if (!this.props.deals.entity.id) {
            return (<LoadingComponent/>)
        }

        const deal = this.props.deals.entity;


        return (
            <Fragment>

                <ButtonGroup className={"add-button"}>
                    <Button type="primary" icon={<FileAddOutlined />} onClick={this.props.showAddDealForm}>
                        Add
                        Quote
                    </Button>
                </ButtonGroup>

                <Modal
                    visible={this.props.quotes.showAddDealForm}
                    title="Add Quote"
                    onOk={this.sendQuote}
                    onCancel={this.props.hideQuoteForm}
                    footer={[]}
                >
                    <ErrorRender error={this.props.quotes.error}/>

                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item
                            {...formItemLayout}
                            label="Deal"
                        >
                            <span className="ant-form-text">{deal.name}</span>
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            label="Contact"
                        >
                            <span className="ant-form-text">{deal.contact.fullname}</span>
                        </Form.Item>


                        <Form.Item label={"Type"} {...formItemLayout}  >
                            {getFieldDecorator('type', {
                                rules: [{required: true, message: 'Please select a value!'}],
                                initialValue: "Standard"
                            })(
                                <Select
                                    dropdownMatchSelectWidth={false}
                                >
                                    <Select.Option
                                        value="Standard"> Standard </Select.Option>
                                    <Select.Option
                                        value="Requote"> Requote </Select.Option>
                                    <Select.Option
                                        value="Rapid Quote"> Rapid Quote</Select.Option>

                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label={"High Risk"} {...formItemLayout}  >
                            {getFieldDecorator('high_risk', {
                                valuePropName: "checked",
                            })(
                                <Checkbox/>
                            )}
                        </Form.Item>

                        <Form.Item label={"European"} {...formItemLayout}  >
                            {getFieldDecorator('european_customer', {
                                valuePropName: "checked",
                            })(
                                <Checkbox/>
                            )}
                        </Form.Item>


                        <Form.Item label={"Shipping Zip Code"} {...formItemLayout}  >
                            {getFieldDecorator('shipping_zip_code', {
                                rules: [{
                                    required: true,
                                    message: 'Field required'
                                }]
                            })(
                                <Input/>
                            )}
                        </Form.Item>


                        <ButtonGroup className={"add-button"}>
                            <Button type="primary" htmlType="submit" icon={<LegacyIcon type={"plus"} />}
                                    loading={this.props.quotes.isLoading}>
                                Save
                            </Button>
                        </ButtonGroup>

                        <br/>
                        <br/>


                    </Form>
                </Modal>

            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        deals: state.deals,
        quotes: state.quotes,
    };
}

const mapDispatchToProps = (dispatch, ownsProps) => {
    return {
        postQuote: (payload) => dispatch(postQuote(payload, ownsProps)),
        showAddDealForm: () => dispatch(showQuoteForm()),
        hideQuoteForm: () => dispatch(hideQuoteForm()),
    };
}

const Wrapped = Form.create({name: 'customized_form_controls'})(QuoteNewModalComponent);


export const FormNewModal = connect(mapStateToProps, mapDispatchToProps)(Wrapped);
