import React from 'react';

interface Props {
    user: any;
    handleLogout: () => void;
}

const Logout = ({ user, handleLogout }: Props) => {
    return (
        <div>
            <div>
                <h2>Logout?</h2>
                <hr />
                <div>
                    {user.username}
                    <div onClick={handleLogout} />
                </div>
            </div>
        </div>
    );
};

export default Logout;
