import React from 'react';
import { Skeleton, Typography, Input, List, Collapse } from 'antd';
import { observer } from 'mobx-react';

// Stores
import TemplatesStore from './Stores/TemplatesStore';
import { ArrowDownOutlined, ArrowRightOutlined, CaretRightOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { get } from '@partsbadger/utils';
import { IModelVariable } from './types';

interface Props {
    model_name: string;
}

const SubModelView = (props: { model_name: string; parent_name: string | null }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [variables, setVariables] = React.useState<IModelVariable[]>([]);
    const [search, setSearch] = React.useState<string>('');

    React.useEffect(() => {
        if (props.model_name) {
            setLoading(true);
            get(`/staff/templates/variables/${props.model_name}/`)
                .then(data => {
                    setVariables(data);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.model_name]);

    const _filtered_variables = React.useMemo(() => {
        return variables.filter(v => {
            return v.name.toLowerCase().includes(search.toLowerCase());
        });
    }, [variables, search]);

    if (loading) return <Skeleton loading={true} active={true} />;

    return (
        <div
            style={{
                // backgroundColor: '#f0f2f5',
                width: '100%',
            }}
        >
            <div>
                <Input.Search
                    placeholder="Type a value and Press Enter"
                    defaultValue={search}
                    onChange={e => {
                        setSearch(e.target.value);
                    }}
                />
            </div>

            <div
                style={{
                    width: '100%',
                    marginTop: 10,
                }}
            >
                <List
                    size="small"
                    bordered
                    dataSource={_filtered_variables}
                    renderItem={v => <RenderField field={v} parent_name={props.parent_name} />}
                />
            </div>
        </div>
    );
};

const RenderField = (props: { field: IModelVariable; parent_name: string | null }) => {
    const v = props.field;

    const [showRelated, setShowRelated] = React.useState(false);

    const code = props.parent_name ? `${props.parent_name}.${v.code}` : v.code;

    const ArrowRender = showRelated ? (
        <ArrowUpOutlined onClick={() => setShowRelated(false)} />
    ) : (
        <ArrowDownOutlined onClick={() => setShowRelated(true)} />
    );

    return (
        <div>
            <List.Item
                actions={[
                    <Typography.Text key={'copy'} code copyable={{ text: `{{instance.${code}}}` }}>
                        instance.{code}
                    </Typography.Text>,
                    v.model_name && ArrowRender,
                ]}
            >
                <Skeleton avatar title={false} loading={TemplatesStore.isLoadingModelVariables} active>
                    <List.Item.Meta
                        title={
                            <a>
                                <Typography.Text strong>{v.name} </Typography.Text>( {v.type})
                            </a>
                        }
                        description={v.help_text}
                    />
                </Skeleton>
            </List.Item>

            {v.model_name && showRelated && (
                <List.Item>
                    <SubModelView model_name={v.model_name} parent_name={code} />
                </List.Item>
            )}
        </div>
    );
};

const VariablesView = (props: Props) => {
    return (
        <div className={'flex flex-col flex-wrap'}>
            <SubModelView model_name={props.model_name} parent_name={null} />
        </div>
    );
};

export default observer(VariablesView);
