import React, { useEffect, useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, message, Spin, Typography } from 'antd';
import LoadingComponent from "../../components/LoadingComponent";
import { Link, useHistory, useParams } from "react-router-dom";
import { ContactAdvancedSearch } from "../../components/Inputs/Select";
import { get, patch, post } from "shared";
import {ErrorRender} from "@partsbadger/library";

const {Paragraph} = Typography;

export const RfqToQuote = Form.create()((props) => {


    const [loading, setLoading] = useState(true);
    const [rfq, setRfq] = useState({});
    const {form} = props;
    const {getFieldDecorator, setFieldsValue} = form;

    const history = useHistory();
    const params = useParams();

    const {id} = params;


    const fetchRFQ = () => {

        setLoading(true);

        get(`staff/rfq/${id}/`).then(response => {

            setRfq(response.data);
            setLoading(false);

            if (response.data.ready_to_quote) {
                const payload = {
                    rfq: id
                };

                rfqToQuote(payload);

            } else {
                message.warning("RFQ is not completed yet")
            }

        }).catch(error => <ErrorRender error={error}/>);
    };

    const rfqToQuote = (payload) => {

        setLoading(true);

        post("staff/quotes/rfq-to-quote/", payload).then(response => {
            setLoading(false);
            history.push(`/quotes/` + response.data.id)
        }).catch(error => <ErrorRender error={error}/>)
    };

    useEffect(() => {
        if (id) {
            fetchRFQ();

        }
    }, [id]);


    useEffect(() => {
        if (rfq?.contact_email) {
            setFieldsValue({
                'contact': rfq.contact_email
            });
        }
    }, [rfq?.contact_email])


    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                const payload = {
                    contact: values.contact?.id,
                    account: values.contact.account?.id,
                };

                patch("staff/rfq/" + rfq.id + "/", payload).then(() => {
                    fetchRFQ();
                }).catch(error => <ErrorRender error={error}/>)
            }
        });
    }


    if (!rfq?.id) {
        return <LoadingComponent visible/>
    }


    return (
        <div className={'center'}>
            <Spin spinning={loading}>

                <Divider>
                    <div className={"title"}> RFQ #{rfq.id} to Quote
                    </div>
                </Divider>

                <p>Use the form below to assign an Account and Contact to start quoting.</p>


                <div style={{margin: "auto", maxWidth: "1200px"}}>

                    <div>
                        <p>
                            <strong>Account:</strong> {rfq ? rfq.account_name : ""}
                        </p>
                        <p>
                            <strong>Contact:</strong> {rfq.contact_first_name} &nbsp; {rfq.contact_last_name} -
                            <Paragraph style={{display: 'inline'}} copyable>{rfq.contact_email}</Paragraph>
                        </p>
                    </div>

                    <p> {rfq.quote ? <Link to={"/quotes/" + rfq.quote.id}>View Quote</Link> : ""}</p>


                    <Form onSubmit={handleSubmit} sm={20} style={{
                        maxWidth: "800px",
                        margin: 'auto'
                    }}>
                        <Form.Item>
                            {getFieldDecorator('contact', {
                                rules: [{required: true, message: 'Please select a value!'}],
                            })(
                                <ContactAdvancedSearch/>
                            )}
                        </Form.Item>

                        <Button htmlType={"submit"} type="primary" icon={<LegacyIcon type={"plus"} />} loading={loading}>
                            Assign and start Quoting
                        </Button>

                    </Form>


                </div>
            </Spin>
        </div>
    );


});
