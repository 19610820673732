/**
 *  https://api.parts-badger.com/allowed-files?all=True
 */
export const ALLOWED_FILES = {
    allowed_3d: [
        'catia',
        'ZT',
        'Z3D',
        'YDL',
        'YAODL',
        'XV0',
        'XSI',
        'XSF',
        'XRF',
        'XPR',
        'XOF',
        'XMM',
        'XMF',
        'XAF',
        'X3G',
        'X3D',
        'X',
        'WRZ',
        'WRP',
        'WRL',
        'WOW',
        'WFT',
        'W3D',
        'VVD',
        'VUE',
        'VTX',
        'VSO',
        'VSH',
        'VS',
        'VRL',
        'VPD',
        'VP',
        'VOX',
        'VOB',
        'VMO',
        'VMD',
        'VISUAL_PROCESSED',
        'VISUAL',
        'VERT',
        'VEG',
        'VAC',
        'V3V',
        'V3O',
        'V3D',
        'UMS',
        'U3D',
        'TVM',
        'TS1',
        'TRUCK',
        'TRI',
        'TPS',
        'TMO',
        'TME',
        'TMD',
        'THL',
        'THING',
        'TGO',
        'TDDD',
        'TCN',
        'T3D',
        'STO',
        'STC',
        'SRF',
        'SMD',
        'SM',
        'SKP',
        'SKL',
        'SIS',
        'SI',
        'SHP',
        'SH3F',
        'SH3D',
        'SGN',
        'SESSION',
        'SDB',
        'SC4MODEL',
        'SBSAR',
        'S3G',
        'S',
        'RFT',
        'RDS',
        'RCS',
        'RAY',
        'RAD',
        'R3D',
        'QC',
        'PZZ',
        'PZ3',
        'PZ2',
        'PSK',
        'PSA',
        'PRV',
        'PRM',
        'PRIMITIVES_PROCESSED',
        'PRIMITIVES',
        'PREFAB',
        'PRC',
        'PPZ',
        'PP2',
        'PMX',
        'PMD',
        'PLY',
        'PL2',
        'PL1',
        'PL0',
        'PKG',
        'PIGS',
        'PIGM',
        'PHY',
        'PGAL',
        'PAR',
        'P5D',
        'P4D',
        'P3R',
        'P3M',
        'P3L',
        'P3D',
        'P2Z',
        'P21',
        'OL',
        'OGF',
        'OFF',
        'OCT',
        'OBZ',
        'OBP',
        'NSBTA',
        'NM',
        'NIF',
        'NFF',
        'N3D',
        'N2',
        'MXS',
        'MXM',
        'MUD',
        'MU',
        'MTZ',
        'MTX',
        'MTL',
        'MSH',
        'MS3D',
        'MRML',
        'MQO',
        'MPJ',
        'MP',
        'MOT',
        'MNM',
        'MIX',
        'MGF',
        'MESH',
        'MEB',
        'MDX',
        'MDD',
        'MD5MESH',
        'MD5CAMERA',
        'MD5ANIM',
        'MCZ',
        'MC6',
        'MC5',
        'MB',
        'MAXC',
        'MAX',
        'MAT',
        'MAKERBOT',
        'MA',
        'M3D',
        'M3',
        'LXO',
        'LXF',
        'LWS',
        'LWO',
        'LTZ',
        'LT2',
        'LPS',
        'LP',
        'LND',
        'LLM',
        'LDM',
        'KTZ',
        'KMCOBJ',
        'KMC',
        'KFM',
        'JAS',
        'J3O',
        'IVE',
        'IV',
        'IRRMESH',
        'IRR',
        'IK',
        'IGM',
        'IGI',
        'IFC',
        'HXN',
        'HRZ',
        'HR2',
        'HLSL',
        'HIPNC',
        'HIP',
        'HDZ',
        'HD2',
        'GRN',
        'GMT',
        'GMMOD',
        'GMF',
        'GLTF',
        'GLM',
        'GLF',
        'GLB',
        'FXT',
        'FXS',
        'FXM',
        'FXL',
        'FXA',
        'FX',
        'FUSE',
        'FUN',
        'FSQ',
        'FSH',
        'FRY',
        'FPJ',
        'FPF',
        'FPE',
        'FP3',
        'FP',
        'FLT',
        'FIG',
        'FG',
        'FCZ',
        'FCP',
        'FC2',
        'FBX',
        'FBM',
        'FACEFX_INGAME',
        'FACEFX',
        'F3D',
        'EXP',
        'EGG',
        'E57',
        'DWF',
        'DUF',
        'DSV',
        'DSO',
        'DSI',
        'DSF',
        'DSE',
        'DSD',
        'DSB',
        'DSA',
        'DS',
        'DRF',
        'DMC',
        'DIF',
        'DFS',
        'DFF',
        'DDD',
        'DBS',
        'DBM',
        'DBL',
        'DBC',
        'DAZ',
        'DAE',
        'D3D',
        'CSO',
        'CSM',
        'CSF',
        'CSD',
        'CRZ',
        'CRF',
        'CR2',
        'CPY',
        'CMZ',
        'CMS',
        'CMOD',
        'CMF',
        'CM2',
        'CHRPARAMS',
        'CHR',
        'CGFX',
        'CGA',
        'CG3',
        'CG',
        'CFG',
        'CCP',
        'CCB',
        'CAS',
        'CAL',
        'CAF',
        'C4D',
        'C3Z',
        'C3D',
        'BVH',
        'BTO',
        'BSK',
        'BRO',
        'BRG',
        'BR7',
        'BR6',
        'BR5',
        'BR4',
        'BR3',
        'BLEND',
        'BLD',
        'BIP',
        'BIO',
        'B3D',
        'ATM',
        'ATL',
        'ATF',
        'ASAT',
        'AOI',
        'AOF',
        'ANM',
        'ANIMSET_INGAME',
        'ANIMSET',
        'ANIM',
        'AN8',
        'AMF',
        'ALBUM',
        'A8S',
        'A3D',
        'A2C',
        '3MF',
        '3DXML',
        '3DX',
        '3DW',
        '3DV',
        '3DS',
        '3DP',
        '3DON',
        '3DMK',
        '3DMF',
        '3DM',
        '3DF',
        '3DA',
        '3D4',
        '3D2',
        '3D',
        'XV3',
        'XNC',
        'XISE',
        'X_B',
        'WDP',
        'WDF',
        'VWX',
        'VTF',
        'VND',
        'VET',
        'UPF',
        'UNT',
        'ULD',
        'TSF',
        'TSC',
        'TOPVIW',
        'TOPPRJ',
        'TOP',
        'TCX',
        'TCW',
        'TCT',
        'TCP',
        'TCM',
        'TCD',
        'TC3',
        'TC2',
        'TBP',
        'TAK',
        'T3001',
        'SZA',
        'SYM',
        'SVD',
        'SPT',
        'SMG',
        'SKF',
        'SHX',
        'SEW',
        'SDG',
        'SCH',
        'SCDOC',
        'SCAD',
        'SBP',
        'SAT',
        'SAB',
        'RTD',
        'RSM',
        'RSG',
        'RS',
        'RRA',
        'RML',
        'RIG',
        'RED',
        'RDF',
        'RCV',
        'RCD',
        'QPM',
        'PWT',
        'PWD',
        'PSV',
        'PSU',
        'PSS',
        'PSM',
        'PSF',
        'PRO',
        'PRG',
        'PM3',
        'PLN',
        'PLA',
        'PIPE',
        'PIPD',
        'PHO',
        'PHJ',
        'PCS',
        'PC7',
        'PC6',
        'PAT',
        'OPT',
        'OPJ',
        'OLB',
        'NWF',
        'NWD',
        'NWC',
        'NPL',
        'NGD',
        'NGC',
        'NEU',
        'NC1',
        'NC',
        'MVS',
        'MTO',
        'MSM',
        'MS9',
        'MS7',
        'MS14',
        'MS13',
        'MS12',
        'MS11',
        'MP9',
        'MP8',
        'MP7',
        'MP14',
        'MP13',
        'MP12',
        'MP11',
        'MP10',
        'MODFEM',
        'MOD',
        'MMG',
        'MIN',
        'MHS',
        'MDL',
        'MCX',
        'MCD',
        'MC9',
        'LYR',
        'LYC',
        'LTL',
        'LOGICLY',
        'LIZD',
        'LIN',
        'LIA',
        'LI3D',
        'LDT',
        'LDR',
        'LCF',
        'L3B',
        'KIT',
        'JVSGZ',
        'JVSG',
        'JT',
        'JOB',
        'JBC',
        'JAM',
        'ISOZ',
        'ISO',
        'ISE',
        'IPT',
        'IPN',
        'IPJ',
        'IPF',
        'IFCZIP',
        'IFCXML',
        'IF',
        'IDW',
        'IDV',
        'IDE',
        'ICS',
        'ICD',
        'IC3D',
        'IBA',
        'IAM',
        'HUS',
        'HSF',
        'HSC',
        'HCP',
        'GXM',
        'GXH',
        'GXD',
        'GXC',
        'GSM',
        'GINSPECT_PRJ',
        'GEO',
        'GDS',
        'GCODE',
        'GCD',
        'GBX',
        'G3D',
        'G',
        'FZZ',
        'FZP',
        'FZM',
        'FZB',
        'FZ',
        'FPP',
        'FPD',
        'FNC',
        'FMZ',
        'FLX',
        'FCW',
        'FCSTD1',
        'FCSTD',
        'FCD',
        'FAN',
        'EZP',
        'EZC',
        'EXB',
        'EWD',
        'EWB',
        'EQN',
        'EPRT',
        'EPF',
        'ELD',
        'EDRW',
        'EDN',
        'EDF',
        'EASM',
        'DXX',
        'DXE',
        'DWT',
        'DWS',
        'DWFX',
        'DVG',
        'DST',
        'DSN',
        'DSG',
        'DRWDOT',
        'DRW',
        'DRU',
        'DRG',
        'DRA',
        'DLX',
        'DLV',
        'DGN',
        'DGK',
        'DGB',
        'DFX',
        'DFT',
        'DES',
        'DCD',
        'DC3',
        'DC2',
        'DC1',
        'DC',
        'DBQ',
        'DB1',
        'CZD',
        'CYP',
        'CRV',
        'CPA',
        'CND',
        'CNC',
        'CMP',
        'CIRCUIT',
        'CIB',
        'CGR',
        'CFF',
        'CF2',
        'CEL',
        'CDW',
        'CDL',
        'CDDX',
        'CATPRODUCT',
        'CATPART',
        'CATDRAWING',
        'CAM',
        'BXL',
        'BSWX',
        'BSW',
        'BRD',
        'BPZ',
        'BPMC',
        'BPM',
        'BMF',
        'BLK',
        'BIT',
        'BIMX',
        'BDL',
        'BDC',
        'BCD',
        'BBCD',
        'ATT',
        'ASY',
        'ASM',
        'ASC',
        'ART',
        'ARD',
        'ANY',
        'AFS',
        'AFD',
        'ADT',
        'ADI',
        'ACT',
        'A2L',
        '3W',
        '3DL',
        '3DC',
        '2D',
        '123DX',
        '123D',
        '123C',
        '123',
        'STL',
        'OBJ',
        // 'DWG',
        // 'DXF',
        'CAM360',
        'MODEL',
        'CKD',
        'PRT',
        'CAD',
        'SLDASM',
        'SLDPRT',
        // 'SLDDRW',
        'IGES',
        'IGS',
        'STEP',
        'STP',
        'X_T',
    ],
    allowed_2d: ['slddrw', 'png', 'png', 'tif', 'dwg', 'DXF', 'JPG', 'PNG', 'PDF'],
};

export const allowed3d = ALLOWED_FILES['allowed_3d'].map(f => f.toLowerCase());
export const allowed2d = ALLOWED_FILES['allowed_2d'].map(f => f.toLowerCase());
