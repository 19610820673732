// The author of the original code is @mrdoob https://twitter.com/mrdoob
// https://threejs.org/examples/?q=con#webgl_shadow_contact
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { HorizontalBlurShader, VerticalBlurShader } from 'three-stdlib';
export var ContactShadows = React.forwardRef(function (_a, ref) {
    var _b = _a.scale, scale = _b === void 0 ? 10 : _b, _c = _a.frames, frames = _c === void 0 ? Infinity : _c, _d = _a.opacity, opacity = _d === void 0 ? 1 : _d, _e = _a.width, width = _e === void 0 ? 1 : _e, _f = _a.height, height = _f === void 0 ? 1 : _f, _g = _a.blur, blur = _g === void 0 ? 1 : _g, _h = _a.far, far = _h === void 0 ? 10 : _h, _j = _a.resolution, resolution = _j === void 0 ? 512 : _j, _k = _a.smooth, smooth = _k === void 0 ? true : _k, _l = _a.color, color = _l === void 0 ? '#000000' : _l, _m = _a.depthWrite, depthWrite = _m === void 0 ? false : _m, renderOrder = _a.renderOrder, props = __rest(_a, ["scale", "frames", "opacity", "width", "height", "blur", "far", "resolution", "smooth", "color", "depthWrite", "renderOrder"]);
    var scene = useThree(function (state) { return state.scene; });
    var gl = useThree(function (state) { return state.gl; });
    var shadowCamera = React.useRef(null);
    width = width * (Array.isArray(scale) ? scale[0] : scale || 1);
    height = height * (Array.isArray(scale) ? scale[1] : scale || 1);
    var _o = React.useMemo(function () {
        var renderTarget = new THREE.WebGLRenderTarget(resolution, resolution);
        var renderTargetBlur = new THREE.WebGLRenderTarget(resolution, resolution);
        renderTargetBlur.texture.generateMipmaps = renderTarget.texture.generateMipmaps = false;
        var planeGeometry = new THREE.PlaneGeometry(width, height).rotateX(Math.PI / 2);
        var blurPlane = new THREE.Mesh(planeGeometry);
        var depthMaterial = new THREE.MeshDepthMaterial();
        depthMaterial.depthTest = depthMaterial.depthWrite = false;
        depthMaterial.onBeforeCompile = function (shader) {
            shader.uniforms = __assign(__assign({}, shader.uniforms), { ucolor: { value: new THREE.Color(color) } });
            shader.fragmentShader = shader.fragmentShader.replace("void main() {", //
            "uniform vec3 ucolor;\n           void main() {\n          ");
            shader.fragmentShader = shader.fragmentShader.replace('vec4( vec3( 1.0 - fragCoordZ ), opacity );', 
            // Colorize the shadow, multiply by the falloff so that the center can remain darker
            'vec4( ucolor * fragCoordZ * 2.0, ( 1.0 - fragCoordZ ) * 1.0 );');
        };
        var horizontalBlurMaterial = new THREE.ShaderMaterial(HorizontalBlurShader);
        var verticalBlurMaterial = new THREE.ShaderMaterial(VerticalBlurShader);
        verticalBlurMaterial.depthTest = horizontalBlurMaterial.depthTest = false;
        return [
            renderTarget,
            planeGeometry,
            depthMaterial,
            blurPlane,
            horizontalBlurMaterial,
            verticalBlurMaterial,
            renderTargetBlur,
        ];
    }, [resolution, width, height, scale, color]), renderTarget = _o[0], planeGeometry = _o[1], depthMaterial = _o[2], blurPlane = _o[3], horizontalBlurMaterial = _o[4], verticalBlurMaterial = _o[5], renderTargetBlur = _o[6];
    var blurShadows = function (blur) {
        blurPlane.visible = true;
        blurPlane.material = horizontalBlurMaterial;
        horizontalBlurMaterial.uniforms.tDiffuse.value = renderTarget.texture;
        horizontalBlurMaterial.uniforms.h.value = (blur * 1) / 256;
        gl.setRenderTarget(renderTargetBlur);
        gl.render(blurPlane, shadowCamera.current);
        blurPlane.material = verticalBlurMaterial;
        verticalBlurMaterial.uniforms.tDiffuse.value = renderTargetBlur.texture;
        verticalBlurMaterial.uniforms.v.value = (blur * 1) / 256;
        gl.setRenderTarget(renderTarget);
        gl.render(blurPlane, shadowCamera.current);
        blurPlane.visible = false;
    };
    var count = 0;
    useFrame(function () {
        if (shadowCamera.current && (frames === Infinity || count < frames)) {
            var initialBackground = scene.background;
            scene.background = null;
            var initialOverrideMaterial = scene.overrideMaterial;
            scene.overrideMaterial = depthMaterial;
            gl.setRenderTarget(renderTarget);
            gl.render(scene, shadowCamera.current);
            scene.overrideMaterial = initialOverrideMaterial;
            blurShadows(blur);
            if (smooth)
                blurShadows(blur * 0.4);
            gl.setRenderTarget(null);
            scene.background = initialBackground;
            count++;
        }
    });
    return (React.createElement("group", __assign({ "rotation-x": Math.PI / 2 }, props, { ref: ref }),
        React.createElement("mesh", { renderOrder: renderOrder, geometry: planeGeometry, scale: [1, -1, 1], rotation: [-Math.PI / 2, 0, 0] },
            React.createElement("meshBasicMaterial", { map: renderTarget.texture, "map-encoding": gl.outputEncoding, transparent: true, opacity: opacity, depthWrite: depthWrite })),
        React.createElement("orthographicCamera", { ref: shadowCamera, args: [-width / 2, width / 2, height / 2, -height / 2, 0, far] })));
});
ContactShadows.displayName = 'ContactShadows';
