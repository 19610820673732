var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { message, Upload, Tabs, Button, Divider, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import QuoteStore from '../../stores/QuoteStore';
import PartStore from '../../stores/PartStore';
import { AssociateParts } from './AssociateParts';
import SplitPDF from '../SplitPDF';
import { observer } from 'mobx-react';
//Copied from zip.js
import { allowed_2d, allowed_3d, getMimeType } from '@partsbadger/utils';
import { FolderOutlined, LoadingOutlined, FilePdfOutlined, } from '@ant-design/icons';
import JSZip from 'jszip';
import ITARNotice from '../ITARNotice';
var LIMIT_LINE_ITEMS = 100;
export var UploadPartFilesMultiple = observer(function (props) {
    var _a;
    var _b = useState('file'), uploadType = _b[0], setUploadType = _b[1];
    var _c = useState(), PDFFile = _c[0], setPDFFile = _c[1];
    var UnZipFiles = function (zip_file) { return __awaiter(void 0, void 0, void 0, function () {
        var new_zip, zip;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    new_zip = new JSZip();
                    return [4 /*yield*/, new_zip.loadAsync(zip_file)];
                case 1:
                    zip = _a.sent();
                    zip.forEach(function (relativePath) { return __awaiter(void 0, void 0, void 0, function () {
                        var blob, name, fileType, file, is_a_hidden_document;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, zip.files[relativePath].async('blob')];
                                case 1:
                                    blob = _a.sent();
                                    name = relativePath.split('/').pop();
                                    if (!name) {
                                        return [2 /*return*/];
                                    }
                                    fileType = getMimeType(name);
                                    file = new File([blob], name, {
                                        type: fileType,
                                    });
                                    is_a_hidden_document = name.indexOf('._') !== -1 ? true : false;
                                    if (PartStore.count() < LIMIT_LINE_ITEMS && !is_a_hidden_document) {
                                        PartStore.addPart(file);
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [2 /*return*/];
            }
        });
    }); };
    var uploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        beforeUpload: function (file) {
            var isLt2M = file.size / 1024 / 1024 < 100;
            if (!isLt2M) {
                message.error('File must smaller than 100MB!', 10);
                return false;
            }
            if (PartStore.count() > LIMIT_LINE_ITEMS) {
                message.error('Limit is 200');
                return false;
            }
            if (PartStore.isZipFile(file.name)) {
                UnZipFiles(file);
            }
            else if (uploadType !== 'split') {
                PartStore.addPart(file);
            }
            if (uploadType === 'split') {
                setPDFFile(file);
                handleClickScroll('splitPDF');
            }
            return false;
        },
    };
    var tabs = [
        {
            label: 'Upload Files',
            key: 'file',
        },
        {
            label: (React.createElement(React.Fragment, null,
                React.createElement(FolderOutlined, null),
                "Upload Folder")),
            key: 'directory',
        },
    ];
    useEffect(function () {
        var timeout = setTimeout(function () {
            if (PartStore.fileParts.length == 1) {
                PartStore.fileParts.forEach(function (item) {
                    var _a;
                    if ((item['3D File'] || item['2D File'] || ((_a = item['Additional File']) === null || _a === void 0 ? void 0 : _a.length)) && !item.uploading) {
                        PartStore.uploadPart(item);
                    }
                });
            }
        }, 500);
        return function () {
            clearTimeout(timeout);
        };
    }, [PartStore.fileParts]);
    function resetSplitFunction() {
        setUploadType('file');
        setPDFFile(undefined);
    }
    var handleClickScroll = function (id) {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    var splitPDF = (React.createElement("div", { id: "splitPDF" }, PDFFile && props.is_staff ? React.createElement(SplitPDF, { handleReset: function () { return resetSplitFunction(); }, pdfFile: PDFFile }) : null));
    var splitPDFButton = (React.createElement(React.Fragment, null, props.is_staff ? (React.createElement(Button, { type: 'ghost', className: "d-flex flex-row align-items-center mx-auto mb-2 shadow-light", icon: React.createElement(FilePdfOutlined, null), onClick: function (e) { return setUploadType('split'); } }, 'Split PDF File')) : null));
    return (React.createElement("div", { className: "mb-2" },
        props.showITARNotice ? React.createElement(ITARNotice, null) : null,
        props.showUploadTypeTabs ? (React.createElement("div", { className: "mb-2" },
            React.createElement(Tabs, { centered: true, defaultActiveKey: uploadType, onChange: function (e) { return setUploadType(e); }, items: tabs }))) : null,
        props.showExtraUploadInfo ? (React.createElement("div", { className: "d-flex align-items-center text-muted" },
            React.createElement("div", { className: "d-flex align-items-center" },
                React.createElement(FeatherIcon, { icon: "lock", size: 14, className: "mr-1" }),
                React.createElement("span", null, "File uploads are secure and confidential")),
            React.createElement("div", { className: "d-flex align-items-center ml-auto" },
                React.createElement(FeatherIcon, { icon: "award", size: 14, className: "mr-1" }),
                React.createElement("span", null, "ISO 9001:2015 and AS 9100 Certified")))) : null,
        React.createElement(Upload.Dragger, __assign({}, uploadProps, { directory: uploadType === 'directory', className: "w-100", disabled: !!PartStore.fileParts.find(function (filePart) { return filePart.uploading; }), style: PDFFile ? { display: 'none' } : {} }),
            React.createElement(React.Fragment, null, PartStore.fileParts.find(function (filePart) { return filePart.uploading; }) ? (React.createElement(Spin, { spinning: true, tip: PartStore.fileParts.find(function (filePart) { return filePart.uploading; })
                    ? 'Uploading Files...'
                    : 'Uploading File...', indicator: React.createElement(LoadingOutlined, null), className: "my-4" })) : (React.createElement(React.Fragment, null, ((_a = QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.line_items.length) === 0 ? (React.createElement("div", { className: "py-4" },
                React.createElement("p", { className: "ant-upload-text pb-2", style: { fontSize: '1.6rem', fontWeight: 600 } }, "Select your part files"),
                React.createElement("p", { className: "ant-upload-hint w-full m-auto" }, uploadType === 'directory' ? (React.createElement("span", null, "You can select and upload a whole folder.")) : (React.createElement("span", null, "Drag and drop your 2D and 3D files or zip files."))),
                React.createElement("div", { style: { width: '6rem', margin: '0 auto' } },
                    React.createElement(Divider, null, "or")),
                React.createElement(Button, { type: 'primary', className: "d-flex flex-row align-items-center mx-auto mb-2 shadow-light", icon: React.createElement(FeatherIcon, { icon: "upload-cloud", size: "22", className: "mr-2" }), onClick: function () { return setUploadType('file'); } }, uploadType === 'directory' ? 'Upload Folder' : 'Upload Files'),
                splitPDFButton,
                React.createElement("p", { className: "pt-3" },
                    React.createElement("span", { className: "text-uppercase" },
                        allowed_3d.replace(/,/g, ',  '),
                        ',  ',
                        allowed_2d.replace(/,/g, ',  ')),
                    React.createElement("br", null),
                    React.createElement("span", null, "100 MB Max File Size")))) : (React.createElement("div", { className: "d-flex flex-column mb-3 align-items-center justify-content-center ant-upload-text" },
                React.createElement("br", null),
                React.createElement(FeatherIcon, { icon: "upload-cloud", size: "24", className: "mr-2 text-primary" }),
                React.createElement("span", { className: "text-primary mr-1" }, "Click to add another part"),
                React.createElement("span", null, "or drag a file here"),
                props.is_staff ? (React.createElement("div", { style: { width: '6rem', margin: '0 auto' } },
                    React.createElement(Divider, null, "or"))) : null,
                splitPDFButton,
                React.createElement("p", { className: "pt-3" },
                    React.createElement("span", { className: "text-uppercase" },
                        allowed_3d.replace(/,/g, ',  '),
                        ',  ',
                        allowed_2d.replace(/,/g, ',  ')),
                    React.createElement("br", null),
                    React.createElement("span", null, "100 MB Max File Size")))))))),
        React.createElement(AssociateParts, { is_staff: props.is_staff }),
        splitPDF));
});
