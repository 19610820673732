import React, { useState } from 'react';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Divider } from 'antd';
import { Accounts } from '@partsbadger/library';
import RequirementForm from '../../../components/Account/OrderRequirements/AccountRequirementForm';
import { useParams } from 'react-router-dom';

export const AccountRequirements = () => {
    const [account, setAccount] = useState();
    const { zoho_id } = useParams();

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Divider>
                <div className={'title'}>Additional Requirements by Account</div>
            </Divider>

            <div style={{ marginTop: 10 }}>
                <Form.Item label={'Account'}>
                    <Accounts onSelect={account => setAccount(account)} zoho_id={zoho_id} setAccount={setAccount} />
                </Form.Item>
            </div>

            <div style={{ marginTop: 10 }}>
                {account?.key && !zoho_id && <RequirementForm defaultValues={[]} account_id={account?.key} />}
                {account?.id && zoho_id && <RequirementForm defaultValues={[]} account_id={account?.id} />}
            </div>
        </div>
    );
};
