import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DownloadFile, get, patch, post } from '@partsbadger/utils';
import { Price } from '@partsbadger/library';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    List,
    message,
    Modal,
    notification,
    Row,
    Select,
    Spin,
    Table,
    Tag,
    Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuickTurnSalesOrder } from '@partsbadger/types';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
    QuickTurnPart,
    QuickTurnSalesOrderLineItem,
    QuickTurnShipmentItem,
} from '@partsbadger/types/lib/QuickTurnInterfaces';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { ThreeJSThumbnail } from '@partsbadger/library';
import axios from 'axios';

const { Title } = Typography;

export const QuickTurnOrderDetail = observer(() => {
    let quickturn_sales_order_id = '';
    ({ quickturn_sales_order_id } = useParams());
    const [loading, setLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<QuickTurnSalesOrder | null>(null);
    const [selectedLineItems, setSelectedLineItems] = useState<QuickTurnSalesOrderLineItem[]>([]);
    const [shipmentModalVisible, setShipmentModalVisible] = useState<boolean>(false);

    const carriers = [
        { label: 'USPS', value: 'USPS' },
        { label: 'UPS', value: 'UPS' },
        { label: 'FedEx', value: 'FedEx' },
        { label: 'DHL', value: 'DHL' },
    ];

    const getOrder = async () => {
        await get(`/quickturn/api/staff/sales-order/${quickturn_sales_order_id}/`)
            .then(async (data: QuickTurnSalesOrder) => {
                await get(`/staff/users/${data.user_id}/`)
                    .then(user => {
                        data.user_data = user;
                    })
                    .catch(err => {
                        data.user_data = null;
                    });
                setOrder(data);
            })
            .finally(() => setLoading(false));
    };

    const updateOrder = async (payload: { status: string }) => {
        setLoading(true);
        await patch(`/quickturn/api/staff/sales-order/${quickturn_sales_order_id}/`, payload)
            .then(() => {
                getOrder();
                message.success('Order updated!');
            })
            .finally(() => setLoading(false));
    };

    const rebuildStepFile = (part_id: string) => {
        get(`/quickturn/core/staff/parts/${part_id}/rebuild-step-file/`)
            .then(() => {
                getOrder();
            })
            .finally(() => setLoading(false));
    };

    const downloadPackingSlip = (shipment_id: string, ship_date: string) => {
        setLoading(true);
        get(`/quickturn/api/staff/shipment/${shipment_id}/download-packing-slip`, {
            responseType: 'blob',
        })
            .then(data => {
                DownloadFile(data, `${order?.name}-${ship_date}-packing-slip.pdf`);
                setLoading(false);
                notification.success({
                    message: 'Document downloaded!',
                });
            })
            .finally(() => setLoading(false));
    };

    const downloadDrawing = (part_id: string) => {
        setLoading(true);
        get(`/quickturn/api/staff/parts/${part_id}/drawing/`, {
            responseType: 'blob',
        })
            .then(data => {
                DownloadFile(data, `${part_id}-drawing.pdf`);
                setLoading(false);
                notification.success({
                    message: '2D Drawing downloaded!',
                });
            })
            .finally(() => setLoading(false));
    };

    const addShipment = (line_items: string[], ship_date: string, carrier: string, tracking_number: string) => {
        const payload = {
            line_items: line_items,
            ship_date: moment(ship_date).format('YYYY-MM-DD'),
            carrier: carrier,
            tracking_number: tracking_number,
        };

        post(`/quickturn/api/staff/sales-order/${quickturn_sales_order_id}/add-shipment/`, payload)
            .then(() => {
                getOrder();
                setShipmentModalVisible(false);
            })
            .finally(() => setLoading(false));
    };
    const expandedRowRender = (lineitem: QuickTurnSalesOrderLineItem) => {
        const columns = [
            {
                title: 'Overall Length',
                key: 'overall-length',
                render: (record: QuickTurnSalesOrderLineItem) => <>{`${record?.part?.overallLength}"`}</>,
            },
            {
                title: 'Outer Diameter',
                key: 'outer-diameter',
                render: (record: QuickTurnSalesOrderLineItem) => <>{`${record?.part?.outerDiameter}"`}</>,
            },
            {
                title: 'Flange',
                key: 'outer-diameter',
                render: (record: QuickTurnSalesOrderLineItem) => <>{record?.part?.hasFlange ? 'Yes' : 'No'}</>,
            },
            {
                title: 'Flange Diameter',
                key: 'flange-diameter',
                render: (record: QuickTurnSalesOrderLineItem) => (
                    <>{record?.part?.hasFlange ? `${record?.part?.flangeDiameter}"` : null}</>
                ),
            },
            {
                title: 'Flange Length',
                key: 'flange-length',
                render: (record: QuickTurnSalesOrderLineItem) => (
                    <>{record?.part?.hasFlange ? `${record?.part?.flangeThickness}"` : null}</>
                ),
            },
            {
                title: 'Thru-Hole',
                key: 'thru-hole',
                render: (record: QuickTurnSalesOrderLineItem) => <>{record?.part?.hasThruHole ? 'Yes' : 'No'}</>,
            },
            {
                title: 'Inner Diameter',
                key: 'thru-hole',
                render: (record: QuickTurnSalesOrderLineItem) => (
                    <>{record?.part?.hasThruHole ? `${record?.part?.innerDiameter}"` : null}</>
                ),
            },
            {
                title: 'Chamfer',
                key: 'chamfer',
                render: (record: QuickTurnSalesOrderLineItem) => <>{`${record?.part?.chamferWidth}"`}</>,
            },
        ];

        return <Table columns={columns} dataSource={[lineitem]} pagination={false} size="small" className="mb-4" />;
    };

    function downloadJSON(args: { data: QuickTurnPart; filename: string }) {
        let link = null;
        const stockData = {
            Id: args.data.id,
            OrderName: order?.name,
            PartName: args.data.name,
            Material: {
                Type: args.data.material.type,
                Alloy: args.data.material.alloy,
            },
            Stock: {
                Diameter: args.data.stock_dimensions.diameter,
            },
            OverallLength: args.data.overallLength,
            OuterDiameter: args.data.outerDiameter,
            HasFlange: args.data.hasFlange,
            FlangeDiameter: args.data.flangeDiameter,
            FlangeThickness: args.data.flangeThickness,
            HasThruHole: args.data.hasThruHole,
            InnerDiameter: args.data.innerDiameter,
            HasFrontCountersink: args.data.hasFrontCountersink,
            FrontCountersinkAngle: args.data.frontCountersinkAngle,
            FrontCountersinkDepth: args.data.frontCountersinkDepth,
            ChamferWidth: args.data.chamferWidth,
        };

        const json = 'data:text/json;charset=utf-8,' + JSON.stringify(stockData);
        const filename = args.filename + '.json';
        const data = encodeURI(json);

        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    }

    const downloadStepFile = (url_file: string, filename: string) => {
        setLoading(true);
        axios
            .get(url_file, {
                responseType: 'blob',
            })
            .then(res => {
                DownloadFile(res.data, `${filename}.stp`);
            })
            .finally(() => {
                setLoading(false);
                notification.success({
                    message: 'File downloaded!',
                });
            });
    };

    const columns: ColumnsType<QuickTurnSalesOrderLineItem> = [
        {
            title: 'Thumbnail',
            key: 'thumbnail',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <>
                    <ThreeJSThumbnail id={record.part.id} size="100px" edges={false} background="#ffffff" />
                </>
            ),
        },
        {
            title: 'Part',
            key: 'part',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => <>{record?.part?.name}</>,
        },
        {
            title: 'Material',
            key: 'material',
            width: 250,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <>{`${record?.part?.material.type} ${record?.part?.material.alloy}`}</>
            ),
        },
        // {
        //     title: 'Dimensions',
        //     key: 'material',
        //     width: 250,
        //     render: (record: QuickTurnSalesOrderLineItem) => {
        //         return (
        //             <div>
        //                 <Descriptions>
        //                     <Descriptions.Item label="Outer Diameter">{`${record?.part?.outerDiameter}`}</Descriptions.Item>
        //                 </Descriptions>
        //             </div>
        //         );
        //     },
        // },
        {
            title: 'STEP File',
            key: 'step-file',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <div>
                    {record?.part?.step_file?.file && (
                        <div
                            onClick={() => downloadStepFile(record.part.step_file.file, record.part.id)}
                            className="ant-btn ant-btn-default mr-2 mb-2"
                        >
                            <DownloadOutlined /> Download
                        </div>
                    )}

                    <Button size="small" type="default" onClick={() => rebuildStepFile(record.part.id)}>
                        Rebuild
                    </Button>
                </div>
            ),
        },
        {
            title: 'Data File',
            key: 'data-file',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <div>
                    <Button
                        type="default"
                        onClick={() =>
                            downloadJSON({
                                filename: record.part.id,
                                data: record.part,
                            })
                        }
                    >
                        <DownloadOutlined /> Download
                    </Button>
                </div>
            ),
        },
        {
            title: 'Drawing',
            key: 'drawing-file',
            width: 150,
            render: (record: QuickTurnSalesOrderLineItem) => (
                <div>
                    <Button type="default" onClick={() => downloadDrawing(record.part.id)}>
                        <DownloadOutlined /> Download
                    </Button>
                </div>
            ),
        },
        {
            title: 'Qty',
            key: 'quantity',
            width: 100,
            render: (record: QuickTurnSalesOrderLineItem) => record?.part?.quantity,
        },
        {
            title: 'Unit',
            key: 'unit_price',
            width: 125,
            align: 'right',
            render: (record: QuickTurnSalesOrderLineItem) => <Price value={Number(record?.unit_price)} />,
        },
        {
            title: 'Total',
            key: 'total_price',
            width: 150,
            align: 'right',
            render: (record: QuickTurnSalesOrderLineItem) => <Price value={Number(record?.total_price)} />,
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: QuickTurnSalesOrderLineItem[]) => {
            setSelectedLineItems(selectedRows);
        },
        getCheckboxProps: (record: QuickTurnSalesOrderLineItem) => ({
            name: record.name,
        }),
    };

    useEffect(() => {
        setLoading(true);
        getOrder();
    }, [quickturn_sales_order_id]);

    return (
        <>
            {loading && (
                <div
                    style={{
                        margin: '20px 0',
                        marginBottom: '20px',
                        padding: '30px 50px',
                        textAlign: 'center',
                    }}
                >
                    <Spin tip={'Loading Sales Order...'} />
                </div>
            )}

            {order && (
                <Row gutter={[12, 12]} className="p-3">
                    <Col span={20}>
                        <Link to="/quickturn/sales-orders" className="block mb-2">
                            <ArrowLeftOutlined /> Back to Order List
                        </Link>
                        <Title level={3}>{order.name}</Title>{' '}
                        <Tag color={order?.status === 'Order Received' ? 'blue' : 'default'}>{order?.status}</Tag>
                        <span>
                            Created {moment(order?.created_time).format('YYYY-MM-DD')} at{' '}
                            {moment(order?.created_time).format('hh:mm a')}
                        </span>
                    </Col>
                    <Col span={4}>
                        {order.status !== 'Draft' && (
                            <div className="flex mt-10 justify-end">
                                <Select
                                    className="ml-2"
                                    defaultValue={order.status}
                                    style={{ width: 200 }}
                                    onChange={value => {
                                        const payload = {
                                            status: value,
                                        };
                                        updateOrder(payload);
                                    }}
                                >
                                    <Select.Option value="Order Received">Order Received</Select.Option>
                                    <Select.Option value="In Production">In Production</Select.Option>
                                    <Select.Option value="Shipped">Shipped</Select.Option>
                                    <Select.Option value="Cancelled">Cancelled</Select.Option>
                                </Select>
                            </div>
                        )}
                    </Col>
                    <Col md={16} span={24}>
                        <Card
                            title="Line Items"
                            className="mb-3"
                            bodyStyle={{ padding: 0 }}
                            extra={[
                                <Button
                                    key="1"
                                    onClick={() => setShipmentModalVisible(true)}
                                    disabled={!selectedLineItems.length}
                                >
                                    Add Shipment
                                </Button>,
                            ]}
                        >
                            <Table
                                rowKey={'id'}
                                columns={columns}
                                dataSource={order?.line_items}
                                loading={loading}
                                pagination={false}
                                scroll={{ x: 'auto' }}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                expandable={{
                                    expandedRowRender: record => expandedRowRender(record),
                                }}
                            />
                            <div className="flex justify-end p-5">
                                <table width={200}>
                                    <tr>
                                        <th align="left" className="p-0">
                                            Subtotal:
                                        </th>
                                        <td align="right">
                                            <Price value={order?.subtotal} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th align="left" className="p-0">
                                            Shipping:
                                        </th>
                                        <td align="right">
                                            <Price value={order?.shipping} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th align="left" className="p-0">
                                            Tax:
                                        </th>
                                        <td align="right">
                                            <Price value={order?.tax} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th align="left" className="text-lg p-0">
                                            Total:
                                        </th>
                                        <td align="right" className="text-lg">
                                            <Price value={order?.grand_total} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Card>
                        <Card title="Shipments" bodyStyle={{ padding: 0 }}>
                            <Table
                                loading={loading}
                                columns={[
                                    { title: 'Ship Date', dataIndex: 'ship_date' },
                                    { title: 'Carrier', dataIndex: 'carrier' },
                                    { title: 'Tracking Number', dataIndex: 'tracking_number' },
                                    {
                                        title: 'Line Items',
                                        render: record => {
                                            return (
                                                <div className={'flex flex-row'}>
                                                    <div className={'flex flex-col'}>
                                                        {record.shipment_items?.map(
                                                            (li: QuickTurnShipmentItem, index: number) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {li.sales_order_line_item?.part?.name}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    <Button
                                                        loading={loading}
                                                        className={'ml-5'}
                                                        onClick={() => downloadPackingSlip(record.id, record.ship_date)}
                                                    >
                                                        <DownloadOutlined /> Packing Slip
                                                    </Button>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                pagination={false}
                                dataSource={order?.shipments}
                            />
                        </Card>
                    </Col>
                    <Col md={8} span={24}>
                        <Card title="User Details" className="mb-3">
                            <div className="flex flex-column">
                                {order.user_data ? (
                                    <>
                                        <span>{order?.user_data?.fullname}</span>
                                        <span>{`${order?.user_data?.email}`}</span>
                                        <span>
                                            Account created on{' '}
                                            {moment(order?.user_data?.date_joined).format('MM-DD-YYYY')} at{' '}
                                            {moment(order?.user_data?.date_joined).format('hh:mm a')}
                                        </span>
                                    </>
                                ) : (
                                    "User doesn't exist"
                                )}
                            </div>
                        </Card>
                        <Card title="Billing Details" className="mb-3">
                            <div className="flex flex-column">
                                <span>{order?.billing_name}</span>
                                <span>{`${order?.billing_street}`}</span>
                                <span>
                                    {`${order?.billing_city}, ${order?.billing_state}, ${order?.billing_zip_code}`}
                                </span>
                            </div>
                        </Card>
                        <Card title="Shipping Details">
                            <div className="flex flex-column">
                                <span>{order?.shipping_name}</span>
                                <span>{`${order?.shipping_street}`}</span>
                                <span>
                                    {`${order?.shipping_city}, ${order?.shipping_state}, ${order?.shipping_zip_code}`}
                                </span>
                            </div>
                        </Card>
                    </Col>
                </Row>
            )}

            <Modal
                title="Add Shipment"
                open={shipmentModalVisible}
                onCancel={() => setShipmentModalVisible(false)}
                footer={null}
            >
                <>
                    This Shipment includes the following parts:
                    <List
                        itemLayout="horizontal"
                        dataSource={selectedLineItems}
                        renderItem={line_item => (
                            <List.Item>
                                <List.Item.Meta title={`${line_item.part.name}, QTY ${line_item.part.quantity}`} />
                            </List.Item>
                        )}
                    />
                    <Form
                        layout="vertical"
                        onFinish={values => {
                            const line_item_ids = selectedLineItems.map(li => li.id);
                            addShipment(line_item_ids, values.ship_date, values.carrier, values.tracking_number);
                        }}
                    >
                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <Form.Item name="ship_date" label="Ship Date">
                                    <DatePicker className="w-full" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="carrier" label="Carrier">
                                    <Select options={carriers} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="tracking_number" label="Tracking Number">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Button htmlType="submit" type="primary">
                                Submit
                            </Button>
                        </Row>
                    </Form>
                </>
            </Modal>
        </>
    );
});
